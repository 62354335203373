<template>
  <div ref="comments">
    <div v-if="$hasPermission('add_comments')" class="flex">
      <app-textbox v-model="text" :enter="true" @enter="sendComment" :label="$t('workorders.writeYourComment')" :focus="focus">
        <button class="font-nhu-500 fs-14 text-primary-500 pt-2 rounded-[8px] border-primary-500" @click="sendPhoto">
          <CameraIcon class="w-7 h-7" />
        </button>
        <button class="font-nhu-500 pl-4 fs-14 text-primary-500 pt-2 rounded-[8px] border-primary-500" @click="sendComment">
          <PaperAirplaneIcon class="w-7 h-7" />
        </button>
      </app-textbox>
    </div>
    <div class="text-left pl-4 mt-5 flex flex-col">
      <div v-for="(comments, date) in groupedComments" :key="date">
        <div class="flex justify-center">
          <div class="bg-blue-50 px-2 py-[2px] rounded-full border border-blue-100 my-[10px]">
            <span class="font-nhu-regular text-blue-800 text-xs">{{ formatDate(date) }}</span>
          </div>
        </div>
        <div v-for="(comment, index) in comments" :key="comment.id">
          <div v-if="!isCurrentUser(comment)" class="flex items-center">
            <user-avatar :user="getUser(comment.senderId)" :hide-image="!isCommentFromSameUser(index, comments)" width="w-[42px]" height="h-[42px]" class="pr-2"/>
            <div class="flex flex-col justify-between min-w-[250px] max-w-xl">
              <div class="flex items-center justify-between flex-grow">
                <span class="font-nhu-semi-bold fs-14 mb-1" v-if="showUserName(comment, index, comments)">
                  {{ getUserName(comment) }}
                </span>
                <span :class="{ 'ml-auto': !showUserName(comment, index, comments) }" class="text-xs font-nhu-regular text-gray-900 mb-2 mt-3 mx-1">
                  {{ formatTime(comment.sentDate) }}
                </span>
              </div>
              <div class="bg-gray-50 rounded-none rounded-tr-lg rounded-br-lg rounded-bl-lg p-2">
                <span class="font-nhu-regular text-gray-900 fs-14 mt-1">{{ getCommentBody(comment) }}</span>
                <div v-if="comment.images">
                  <div v-for="image in comment.images" :key="image">
                    <app-image :src="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-end items-center">
            <div class="flex flex-col justify-between min-w-[250px] max-w-xl">
              <div class="flex items-center justify-end flex-grow">
                <span class="text-xs font-nhu-regular text-gray-900 mb-2 mt-3 mx-1">
                  {{ formatTime(comment.sentDate) }}
                </span>
              </div>
              <div class="bg-primary-50 rounded-none rounded-tl-xl rounded-br-xl rounded-bl-xl p-2">
                <span class="font-nhu-regular text-gray-900 fs-14 mt-1">{{ getCommentBody(comment) }}</span>
                <div v-if="comment.images">
                  <div v-for="image in comment.images" :key="image">
                    <app-image :src="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input id="file_input" class="hidden" type="file" ref="file"
                   accept="image/*"
                   @change="showUploadFilePopUp($event.target)"/>
</template>

<script>
import emitter from 'tiny-emitter/instance'
import {mapActions, mapGetters} from "vuex";
import UserAvatar from "@/components/UserAvatar.vue";
import { PaperAirplaneIcon, CameraIcon } from '@heroicons/vue/24/outline'
import Images from "@/services/images";


export default {
  name: "WorkOrderComments",
  components: { UserAvatar, PaperAirplaneIcon, CameraIcon },
  emits: ['divReady', 'sendComment'],
  props: {
    focus: {type: Boolean, default: false},
  },
  data() {
    return {
      text: '',
      saving: false,
      imageUploading: false,
      localComments: [],
      previousWorkOrder: null,

    }
  },
  watch: {
    workOrder: {
      handler(newWorkOrder) {
        if (this.previousWorkOrder && this.previousWorkOrder.id !== newWorkOrder.id) {
          this.localComments = [];
        }
        this.updateComments();
        this.previousWorkOrder = newWorkOrder;
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({workOrder: 'GET_WORKORDER', users: 'GET_ALL_USERS', user: 'GET_CURRENT_USER'}),

    groupedComments() {
      if (!this.localComments || this.localComments.length === 0) {
        return {};
      }
      const commentsWithId = this.localComments.filter(comment => comment.id);

      return commentsWithId.reduce((grouped, comment) => {
        const date = comment.sentDate?.split('T')[0];
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(comment);
        return grouped;
      }, {});
    },
    lang() {
      return this.$i18n.locale
    },
  },
  methods: {
    ...mapActions(['READ_ALL_USERS', 'COMMENT_WORKORDER']),
    getUser(senderId) {
      const user = this.users.find(x => x.id === senderId);
      return user || {};
    },
    formatDate(inputDate) {
      if (!inputDate) {
        return '';
      }

      let date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        return this.$t('invalidDate');
      }

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

      const todayString = today.toISOString().split('T')[0];
      const yesterdayString = yesterday.toISOString().split('T')[0];
      const dateString = date.toISOString().split('T')[0];

      let formattedDate = '';

      if (dateString === todayString) {
        return this.$t('today');
      } else if (dateString === yesterdayString) {
        return this.$t('yesterday');
      } else {
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        if (this.lang === 'en') {
          formattedDate = date.toLocaleDateString('en-US', options);
        } else if (this.lang === 'pt') {
          formattedDate = date.toLocaleDateString('pt-PT', options);
        } else {
          formattedDate = date.toLocaleDateString('es-ES', options);
          formattedDate = formattedDate.replace(',', '').substring(0, formattedDate.lastIndexOf('de') - 1);
        }
      }

      return formattedDate;
    },
    formatTime(inputDate) {
      let date = new Date(inputDate);

      let hour = date.getHours();
      let minutes = date.getMinutes();

      if (hour < 10) hour = '0' + hour;
      if (minutes < 10) minutes = '0' + minutes;

      return `${hour}:${minutes}`;
    },

    compressImage(file, maxWidth, maxHeight, quality = 0.95) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')

          if (img.width > maxWidth || img.height > maxHeight) {
            if (img.width > img.height) {
              canvas.width = maxWidth
              canvas.height = maxWidth * (img.height / img.width)
            } else {
              canvas.height = maxHeight
              canvas.width = maxHeight * (img.width / img.height)
            }
          } else {
            canvas.width = img.width
            canvas.height = img.height
          }

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => {
            let compressedFile = new File([blob], file.name, {type: 'image/webp', lastModified: Date.now()})
            resolve(compressedFile)
          }, 'image/webp', quality)
        }
        img.onerror = reject
      })
    },

    showUploadFilePopUp(target) {
      this.imageUploading = true;

      this.compressImage(target.files[0], 800, 600)
          .then((compressedFile) => {
            Images.Upload([compressedFile])
                .then((names) => {
                    this.COMMENT_WORKORDER( { id: this.workOrder.id, images: names });
                    this.imageUploading = false;
                });
          })
          .catch((error) => {
            this.imageUploading = false;

            console.error('Error during image compression:', error)
          })
    },


    async sendPhoto() {
      this.$refs.file.click()
    },
    async sendComment() {
      if (this.text && this.text.trim() !== '') {
        this.saving = true;
        await this.COMMENT_WORKORDER( { id: this.workOrder.id, text: this.text });
        this.text = '';
        this.saving = false;
      }
    },
    isCurrentUser(comment) {
      return comment.senderId === this.user.id;
    },
    updateComments() {
      const newComments = this.workOrder.comments?.filter(
        comment => !this.localComments?.some(localComment => localComment.id === comment.id)
      ) || [];
      this.localComments = [...newComments, ...this.localComments];
    },
    scrollToNewMessage() {
      const comments = this.$refs.comments;
      comments.scrollIntoView({
        behavior: 'smooth'
      });
    },
    isCommentFromSameUser(index, comments) {
      if (index === 0) return false;
      return comments[index].senderId === comments[index - 1].senderId;
    },
    getCommentBody(comment) {
      if (comment.commentType === 'text') {
        return comment.body;
      } else {
        let userName = this.getUserName(comment)
        return `${userName} ${this.$t('users.changeStatus.' + comment.change) + this.$t('theWorkOrder')}`;
      }
    },
    showUserName(comment, index, comments) {
      return this.getUser(comment.senderId) && !this.isCommentFromSameUser(index, comments);
    },
    getUserName(comment) {
      return this.getUser(comment.senderId)?.name || '';
    },
  },
  async mounted() {
    this.$emit('divReady', this.$refs.comments);
    this.localComments = this.workOrder.comments;
    emitter.on('scrollToNewMessage', this.scrollToNewMessage)
  },
  unmounted() {
    emitter.off('scrollToNewMessage', this.scrollToNewMessage)
  }
}
</script>
