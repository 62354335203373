<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden" @mousedown.self="mouseDown">
          <div class="fixed inset-y-0 right-0 flex max-w-full pointer-events-none md:pl-10 sm:pl-16">
            <TransitionChild @beforeEnter="beforeEnter" as="template"
                             enter="transform transition ease-in-out duration-500 sm:duration-100"
                             enter-from="md:translate-x-full md:translate-y-0 translate-y-full"
                             enter-to="md:translate-x-0 md:translate-y-0 translate-y-0"
                             leave="transform transition ease-in-out duration-500 sm:duration-100"
                             leave-from="md:translate-x-0 md:translate-y-0 translate-y-0"
                             leave-to="md:translate-x-full md:translate-y-0 translate-y-full">
                             
              <DialogPanel class="w-screen max-w-xl pointer-events-auto">
                <div class="flex flex-col pt-6 m-4 overflow-y-scroll bg-white rounded shadow-xl sm:m-0 sm:h-full" 
                    :class="{'h-[calc(100vh-26px)]': getOS() !== 'iOS','h-[calc(100vh-130px)]': getOS() === 'iOS'}">
                  <div class="px-4 border-b sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="mb-4 text-lg font-medium text-gray-900">
                        <slot name="title">
                          {{ title }}
                        </slot>
                      </DialogTitle>
                      <div class="flex items-center ml-3 h-7">
                        <button @click="$emit('update:modelValue', false)" type="button"
                                class="text-gray-400 bg-white rounded hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative flex-1 overflow-y-auto">
                    <div class="relative flex-1 px-4 mb-4 min-h-[75vh] overflow-y-auto sm:px-6">
                      <app-loading :loading="loading">
                        <slot></slot>
                      </app-loading>
                    </div>
                  </div>
                  <div class="w-full border-t border-gray-200 bg-purple" v-show="!loading">
                    <app-loading :loading="saving">
                      <div class="flex gap-2 px-4 py-2 space-x-4">
                        <div class="flex-1"></div>
                        <slot name="footer"></slot>
                      </div>
                    </app-loading>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'

export default {
  name: "AppSidebar",
    components: { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XMarkIcon },
  emits: ['update:modelValue', 'beforeEnter'],
  props: {
    title: {
      type: String,
      default: null
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
  },
  computed: {
  },
  methods: {
    mouseDown() {
      this.$emit('update:modelValue', false)
    },
    getOS(){
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    beforeEnter() {
      this.$emit("beforeEnter");
    }
  }
};
</script>
