import API from '@/api'

const state = {
    DASHBOARD: {
        id: '',
        companyId: '',
        elements: [],
    },
    DASHBOARD_ELEMENT: {
        name: '',
        description: '',
        filter: '',
        icon: '',
        color: '',
    },
    ALL_DASHBOARDS: {
        data: [],
    },
    DASHBOARD_EDITABLE: false,
}
const getters = {
    GET_DASHBOARD_EDITABLE: state => state.DASHBOARD_EDITABLE,
    GET_DASHBOARD: state => state.DASHBOARD,
    GET_DASHBOARD_ELEMENT: state => state.DASHBOARD_ELEMENT,
    GET_ALL_DASHBOARDS: state => state.ALL_DASHBOARDS,
}
const mutations = {
    SET_DASHBOARD_EDITABLE: (state, payload) => {
        state.DASHBOARD_EDITABLE = payload
    },
    SET_DASHBOARD: (state, payload) => {
        state.DASHBOARD = payload
    },
    SET_DASHBOARD_ELEMENT: (state, payload) => {
        state.DASHBOARD_ELEMENT = payload
    },
    SET_ALL_DASHBOARDS: (state, payload) => {
        state.ALL_DASHBOARDS = payload
    },
}
const actions = {
    async DELETE_DASHBOARD_ELEMENT (context, payload) {
        const response = await API.Delete('/api/dashboard', payload)
        if (response.type === 'Success') {
            context.commit('SET_DASHBOARD', {})
            return response.res.data
        }
        context.commit('SET_DASHBOARD', {})
    },
    async SAVE_DASHBOARD_ELEMENT (context, payload) {
        let element = context.getters.GET_DASHBOARD_ELEMENT
        if(element.id){
            payload.elements.splice(payload.elements.findIndex(e => e.id === element.id), 1, element)
        }else{
            payload.elements.push(element)
        }
        context.commit('SET_DASHBOARD', payload)
        await context.dispatch('SAVE_DASHBOARD', payload)
    },
    REMOVE_DASHBOARD_ELEMENT (context, payload) {
        let dashboard = context.getters.GET_DASHBOARD
        dashboard.elements.splice(dashboard.elements.findIndex(e => e.id === payload.id), 1)
        context.commit('SET_DASHBOARD', dashboard)
    },
    async CREATE_DASHBOARD_ELEMENT (context){
        context.commit('SET_DASHBOARD_ELEMENT', { name: '', description: '', filter: '', icon: '', color: '', recordId: ''})
    },
    async READ_DASHBOARD(context, payload) {
        const response = await API.Get('/api/dashboard', {filters: payload.filters})
        if (response.type === 'Success') {
            context.commit('SET_DASHBOARD', response.res.data)
            return response.res.data
        }
        context.commit('SET_DASHBOARD', {})
    },
    async READ_DASHBOARD_ELEMENT(context, payload) {
        const response = await API.Get('/api/dashboard/element', payload.id)
        if (response.type === 'Success') {
            context.commit('SET_DASHBOARD_ELEMENT', response.res.data)
            return response.res.data
        }
        context.commit('SET_DASHBOARD_ELEMENT', {})
    },
    async SAVE_DASHBOARD (context, payload) {
        const response = await API.Put('/api/dashboard', payload)
        if (response.type === 'Success') {
            context.commit('SET_DASHBOARD', response.res.data)
            return response.res.data
        }
        context.commit('SET_DASHBOARD', {})
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}
