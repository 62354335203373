<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit"
          @mousedown.self="closePopUp">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative overflow-hidden text-left transition-all transform bg-white rounded shadow-xl w-11/12 w-[500px]">
              <div class="flex px-2 pt-2 border-b-[1px]">
                <div class="text-primary-500 font-nhu-semi-bold fs-18 w-full">
                  {{ $t('export_option.export_as_PDF') }}:
                </div>
                <div class="h-8 flex justify-end">
                  <div class="w-fit h-fit hover:fill-gray-500" @click="closePopUp">
                    <app-icon icon="xmark_regular" class="cursor-pointer" :width="20" :height="20"></app-icon>
                  </div>
                </div>
              </div>
              <div class="pt-2">
                <div v-for="option in Object.keys(getOptions)" :key="option">
                  <div class="flex justify-between">
                    <div class="w-full flex" @click="changeValue(option)">
                      <div class="flex items-center w-full mx-2 py-1">
                        <input type="checkbox" class="hidden" :value="this.options[option]" :name="option">
                        <span class="w-4 h-4 border border-primary-500 mr-2 rounded-sm flex items-center justify-center"
                          :class="{ 'bg-primary-25': this.options[option] }">
                          <span v-if="this.options[option]" class="text-primary-500">
                            <app-icon icon="check_solid" :width="10" :height="10" class="fill-primary-500"></app-icon>
                          </span>
                        </span>
                        <span class="pl-1 font-nhu-regular text-gray-900 fs-14">{{ $t("export_option.include") + " " +$t("export_option."+record.name+"."+option) }}</span>
                        <div class="flex-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end w-full gap-2 px-4 py-2 space-x-4 font-nhu-500 fs-14">
                <app-button @click="closePopUp" :clean="true" background="light"
                  extra-class="border-primary-500 text-primary-500 border-[1px] !px-3 shadow-sm shadow-inner rounded-lg">
                  {{ $t('cancel') }}
                </app-button>
                <app-button @click="doExec" type="primary" background="light">
                  {{ $t('records.workorders.actions.exportToPDF') }}
                </app-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { mapGetters } from "vuex";
export default {
  name: "ExportOptionsDialog",
  components: { Dialog, DialogPanel, TransitionChild, TransitionRoot },
  emits: ["close", "export"],
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({ options: 'GET_EXPORT_OPTIONS', record: 'GET_RECORD' }),
    getOptions() {
      return this.options;
    }
  },
  methods: {
    doExec() {
      this.$emit("export");
    },
    changeValue(option) {
      this.$store.commit('SET_EXPORT_OPTIONS_ITEM_BY_INDEX', option);
    },
    closePopUp() {
      this.$emit("close");
    }
  }
}
</script>

<style scoped></style>
