<template>
    <transition name="slide-fade">
        <div v-if="isVisible" class="border-l-4 alert"
             :class="getClass(type)">
            <div class="flex h-[56px]">
                <div class="ml-3 p-3 flex items-center">
                    <span :class="getClass(type)">{{ title }}</span>
                    <div class="mt-2">
                        <span class="text-sm font-nhu-regular text-gray-900">{{ description }}</span>
                    </div>
                </div>
                <div v-if="closable" class="ml-auto">
                    <div class="m-3 cursor-pointer" @click="isVisible = false">
                        <XMarkIcon class="h-5 w-5" aria-hidden="true"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {XMarkIcon} from "@heroicons/vue/24/outline"

export default {
  name: "AppAlert",
  components: {XMarkIcon},
  emits: ['close'],
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    type: {type: String, default: "success"},
    showAlert: {type: Boolean, default: false},
    closable: {type: Boolean, default: false}
  },
  data() {
    return {
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.showAlert;
      },
      set() {
        this.$emit('close');
      }
    }
  },
  methods: {
    getClass(type) {
      let baseClass = 'text-sm font-medium font-nhu-regular';
      let typeClass = '';

      switch (type) {
        case 'success':
          typeClass = 'border-primary-500 bg-primary-50 text-primary-500';
          break;
        case 'warning':
          typeClass = 'border-yellow-500 bg-yellow-50 text-yellow-500';
          break;
        case 'error':
          typeClass = 'border-red-500 bg-red-50 text-red-500';
          break;
        case 'info':
          typeClass = 'border-blue-500 bg-blue-50 text-blue-500';
          break;
      }

      return `${baseClass} ${typeClass}`;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.alert {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    margin-top: 6rem;
}

.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .1s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(2rem);
    opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave {
    transform: translateX(0);
    opacity: 1;
}
</style>
