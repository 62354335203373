<template>
  <TransitionRoot as="template" :show="openPopUp">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit" @mousedown.self="closePopUp">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100"
                           leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded shadow-xl">

              <div @click="closePopUp" class="absolute top-0 right-0 z-10 cursor-pointer">
                <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray"/>
              </div>

              <Cropper ref="cropper"
                  class="h-[350px] w-[300px]"
                  :stencil-props="stencilProps"
                  :src="file"
                  :stencil-component="rounded ? $options.components.CircleStencil : $options.components.RectangleStencil"
              />

              <div class="flex justify-end mt-5">

                <app-button
                    @click="closePopUp"
                    :default="true"
                    class="m-2">
                  <span class="font-nhu-regular text-[16px]">{{$t('close')}}</span>
                </app-button>

                <app-button
                    @click="saveImage"
                    :default="true"
                    class="bg-[#EDFB4F] border-[#EDFB4F] text-black hover:border-purple-light m-2">
                  <span class="font-nhu-regular text-[16px]">{{$t('save')}}</span>
                </app-button>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XCircleIcon} from '@heroicons/vue/24/outline'
import AppButton from "@/components/AppButton";
import {CircleStencil, Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "AppEditImage",
  components: {
    AppButton,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil
  },
  props: {
    file: {
      type: String,
      default: null
    },
    openPopUp: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: ""
    },
    rounded:{
      type: Boolean,
      default: false
    },
    maxSize:{
      type: Number,
      default: 0
    },
    loader:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stencilProps: {
        //aspectRatio: this.rounded ? 1 : 6/5
      },
      imageInBase64: '',
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      image: null,
    }
  },
  methods: {
    closePopUp() {
      this.$emit('update:openPopUp', false)
      this.$emit('clearInput')
    },

    saveImage() {
      this.$emit('update:loader',true)
      const { coordinates, canvas, } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL();

      let dataURL = canvas.toDataURL()
      let blobBin = atob(dataURL.split(',')[1]);

      if(this.maxSize == 0 || blobBin.length < this.maxSize)
      {
        let array = [];
        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        let file = new File([new Uint8Array(array)], 'image.png', {type: 'image/png'});
        this.$emit("uploadImage", file);
      }
      else
        this.$emit("uploadImage", null);
    },
  },

  mounted() {

  }
}
</script>

