import {
    createRouter,
    createWebHistory
} from 'vue-router'
import store from '../store'

import TheTemplate from '@/core/TheTemplate.vue'
//import AppCrudList from '@/crud/AppCrudList.vue'

//import DashboardPage from '../pages/admin/dashboard/DashboardPage'
import DashboardListPage from '../pages/dashboard/DashboardListPage'
import DashboardCreatePage from '../pages/dashboard/DashboardCreatePage'
import DashboardEditPage from '../pages/dashboard/DashboardEditPage'
import DashboardPage from '../pages/dashboard/DashboardPage'

import NotificationsPage from '../pages/admin/notifications/NotificationsPage'


import WorkOrderListPage from '@/pages/workorders/WorkOrderListPage'
import WorkOrderCreatePage from '@/pages/workorders/WorkOrderCreatePage'
import WorkOrderEditPage from '@/pages/workorders/WorkOrderEditPage'
import WorkOrderEditResourcesPage from '@/pages/workorders/WorkOrderEditResourcesPage'
import WorkOrderAddMetricPage from '@/pages/workorders/WorkOrderAddMetricPage'
import WorkOrderViewPage from "@/pages/workorders/WorkOrderViewPage";

import GroupListPage from "@/pages/admin/groups/GroupListPage";
import GroupPage from "@/pages/admin/groups/GroupPage";

import SiteListPage from '@/pages/sites/SiteListPage'
import SiteCreatePage from '@/pages/sites/SiteCreatePage'
import SiteEditPage from '@/pages/sites/SiteEditPage'
import SiteViewPage from '@/pages/sites/SiteViewPage'

import ResultsPage from "@/pages/admin/results/ResultsPage";

import UserListPage from "@/pages/users/UserListPage";
import UserEditPage from "@/pages/users/UserEditPage";
import UserCreatePage from "@/pages/users/UserCreatePage";
import UserViewPage from "@/pages/users/UserViewPage";

import CreateCompanyPage from '../pages/auth/CreateCompanyPage.vue'
import MailValidationPage from '../pages/auth/MailValidationPage.vue'

import LoginPage from '../pages/auth/LoginPage.vue'
import LogoutPage from '../pages/auth/LogoutPage.vue'
import ForgotPasswordPageVue from '@/pages/auth/ForgotPasswordPage.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'

import OptionGeneralPage from '../pages/admin/options/OptionGeneralPage.vue'
import OptionAccountPage from '../pages/admin/options/OptionAccountPage.vue'
import IntegrationsPage from '../pages/admin/integrations/IntegrationsPage.vue'
import RegisterPage from '../pages/admin/integrations/RegisterPage.vue'

import AssetListPage from "@/pages/assets/AssetListPage.vue";
import AssetCreatePage from "@/pages/assets/AssetCreatePage.vue";
import AssetEditPage from "@/pages/assets/AssetEditPage.vue";
import AssetViewPage from "@/pages/assets/AssetViewPage.vue";

import TypeList from "@/pages/assets/types/TypeList.vue";

import NotFoundPage from '../pages/NotFoundPage'

import ActionListPage from "@/pages/admin/actions/ActionListPage.vue";
import ActionPage from "@/pages/admin/actions/ActionPage.vue";

import TagList from "@/pages/admin/actions/tags/TagList.vue";
import IssueListPage from "@/pages/admin/issues/IssueListPage.vue";


import EditIssue from "@/pages/admin/issues/EditIssue.vue";

import CategoryListPage from "@/pages/categories/CategoryListPage.vue";
import CategoryCreatePage from "@/pages/categories/CategoryCreatePage.vue";
import CategoryViewPage from "@/pages/categories/CategoryViewPage.vue";
import CategoryEditPage from "@/pages/categories/CategoryEditPage.vue";

import MessagePage from "@/pages/messages/MessagePage.vue";

import RequestListPage from "@/pages/requests/RequestListPage.vue";
import RequestCreatePage from "@/pages/requests/RequestCreatePage.vue";
import RequestViewPage from "@/pages/requests/RequestViewPage.vue";
import RequestEditPage from "@/pages/requests/RequestEditPage.vue";

import RequestLandingListPage from "@/pages/requestLandings/RequestLandingListPage.vue";
import RequestLandingCreatePage from "@/pages/requestLandings/RequestLandingCreatePage.vue";
import RequestLandingViewPage from "@/pages/requestLandings/RequestLandingViewPage.vue";
import RequestLandingEditPage from "@/pages/requestLandings/RequestLandingEditPage.vue";

import RequestLandingPortal from "@/public/requestLanding/RequestLandingPortal.vue";
import TeamListPage from "@/pages/teams/TeamListPage.vue";
import TeamCreatePage from "@/pages/teams/TeamCreatePage.vue";
import TeamEditPage from "@/pages/teams/TeamEditPage.vue";
import TeamViewPage from "@/pages/teams/TeamViewPage.vue";

import FeaturesPage from "@/pages/admin/features/FeaturesPage.vue"

import PartListPage from "@/pages/parts/PartListPage.vue";
import PartCreatePage from "@/pages/parts/PartCreatePage.vue";
import PartEditPage from "@/pages/parts/PartEditPage.vue";
import PartViewPage from "@/pages/parts/PartViewPage.vue";

import MetricListPage from "@/pages/metrics/MetricListPage.vue";
import MetricCreatePage from "@/pages/metrics/MetricCreatePage.vue";
import MetricEditPage from "@/pages/metrics/MetricEditPage.vue";
import MetricViewPage from "@/pages/metrics/MetricViewPage.vue";
import MetricEditMeasuresPage from "@/pages/metrics/MetricEditMeasuresPage.vue";
import MetricViewMeasuresPage from "@/pages/metrics/MetricViewMeasuresPage.vue";


import RoleListPage from "@/pages/roles/RoleListPage.vue";
import RoleCreatePage from "@/pages/roles/RoleCreatePage.vue";
import RoleEditPage from "@/pages/roles/RoleEditPage.vue";
import RoleViewPage from "@/pages/roles/RoleViewPage.vue";

import TemplateListPage from "@/pages/templates/TemplateListPage.vue";
import TemplateCreatePage from "@/pages/templates/TemplateCreatePage.vue";
import TemplateFullEditorPage from "@/pages/templates/editor/TemplateFullEditorPage.vue";
import TemplateViewPage from "@/pages/templates/TemplateViewPage.vue";
import TemplateEditorPage from "@/pages/templates/TemplateEditorPage.vue";

import ProcedureListPage from "@/pages/procedures/ProcedureListPage.vue";
import ProcedureCreatePage from "@/pages/procedures/ProcedureCreatePage.vue";
import ProcedureEditPage from "@/pages/procedures/ProcedureEditPage.vue";
import ProcedureViewPage from "@/pages/procedures/ProcedureViewPage.vue";

import CompanyListPage from "@/pages/companies/CompanyListPage.vue";
import CompanyCreatePage from "@/pages/companies/CompanyCreatePage.vue";
import CompanyEditPage from "@/pages/companies/CompanyEditPage.vue";
import CompanyViewPage from "@/pages/companies/CompanyViewPage.vue";

import OptionWorkOrderSettings from '@/pages/admin/options/OptionWorkOrderSettings.vue'
import OptionRecordLabels from "@/pages/admin/options/OptionRecordLabels.vue";
import OptionCustomFields from "@/pages/admin/options/OptionCustomFields.vue";
import LoginRequest from "@/pages/loginRequest/loginRequest.vue";
import RequestCreate from "@/public/request/RequestCreate.vue";
import RequestView from "@/public/request/RequestView.vue";
import InspectionListPage from "@/pages/inspections/InspectionListPage.vue";
import InspectionCreatePage from "@/pages/inspections/InspectionCreatePage.vue";
import InspectionEditPage from "@/pages/inspections/InspectionEditPage.vue";
import InspectionViewPage from "@/pages/inspections/InspectionViewPage.vue";
import EmailPage from "@/pages/admin/emails/EmailPage.vue";

const routes = [
    { path: '/login', name: 'LoginPage', component: LoginPage, },
    { path: '/logout', name: 'LogoutPage', component: LogoutPage,},

    { path: '/admin/company/create', name: 'CreateCompanyPage', component: CreateCompanyPage, },
    { path: '/admin/validate/:code', name: 'MailValidationPage', component: MailValidationPage, meta: { requiresAuth: false } },

    { path: '/public-requests/create/:id', name: 'PublicRequestCreatePage', component: RequestCreate, meta: { requiresAuth: false } },
    { path: '/public-requests/view/:id', name: 'PublicRequestViewPage', component: RequestView, meta: { requiresAuth: false } },
    { path: '/admin/forgot_password', name: 'ForgotPasswordPageVue', component: ForgotPasswordPageVue, },
    { path: '/admin/reset_password/:code', name: 'ResetPassword', component: ResetPassword, },

    { path: '/', name: 'TemplateApp', component: TheTemplate,
        children: [
            { path: '/', name: 'DefaultAdminPage', beforeEnter: () => { window.location.href = '/workorders'; } },
            { path: '/admin/', name: 'AdminPage', beforeEnter: () => { window.location.href = '/workorders'; } },
            { path: '/notifications', name: 'NotificationsPage', component: NotificationsPage, meta: { requiresAuth: true, } },

            { path: '/dashboard', name: 'Dashboard3Page', component: DashboardListPage, meta: { requiresAuth: true, record: 'dashboard', action: 'dashboard'}, },

            /* DASHBOARD */
            { path: '/dashboard2', name: 'DashboardPage', component: DashboardListPage, meta: { requiresAuth: true, record: 'dashboard', action: 'dashboard'},
                children: [
                    { path: '/dashboard2/create', name: 'DashboardCreatePage', component: DashboardCreatePage, meta: { action: 'create' } },
                    { path: '/dashboard2/:id/edit', name: 'DashboardEditPage', component: DashboardEditPage, meta: { action: 'edit' }},
                    { path: '/dashboard2/:id', name: 'DashboardPage', component: DashboardPage, meta: { action: 'view' }},
                ]
            },

            /* WORKORDERS */
            { path: '/workorders', name: 'WorkOrderListPage', component: WorkOrderListPage, meta: { requiresAuth: true, record: 'workorders', action: 'list'},
                children: [
                    { path: '/workorders/create', name: 'WorkOrderCreatePage', component: WorkOrderCreatePage, meta: { action: 'create' } },
                    { path: '/workorders/:id/edit', name: 'WorkOrderEditPage', component: WorkOrderEditPage, meta: { action: 'edit' }},
                    { path: '/workorders/:id/resources', name: 'WorkOrderEditResourcesPage', component: WorkOrderEditResourcesPage, meta: { action: 'edit' }},
                    { path: '/workorders/:id/metrics', name: 'WorkOrderAddMetricPage', component: WorkOrderAddMetricPage, meta: { action: 'edit' }},
                    { path: '/workorders/:id', name: 'WorkOrderPage', component: WorkOrderViewPage, meta: { action: 'view' }},
                ]
            },
            /* ASSETS */
            { path: '/assets', name: 'AssetListPage', component: AssetListPage, meta: { requiresAuth: true, record: 'assets', action: 'list'},
                children: [
                    { path: '/assets/create', name: 'AssetCreatePage', component: AssetCreatePage, meta: { action: 'create' } },
                    { path: '/assets/:id/edit', name: 'AssetEditPage', component: AssetEditPage, meta: { action: 'edit' }},
                    { path: '/assets/:id', name: 'AssetPage', component: AssetViewPage, meta: { action: 'view' }},
                ]
            },
            /* SITES */
            { path: '/sites', name: 'SiteListPage', component: SiteListPage, meta: { requiresAuth: true, record: 'sites', action: 'list'},
                children: [
                    { path: '/sites/create', name: 'SiteCreatePage', component: SiteCreatePage, meta: { action: 'create' } },
                    { path: '/sites/:id/edit', name: 'SiteEditPage', component: SiteEditPage, meta: { action: 'edit' }},
                    { path: '/sites/:id', name: 'SitePage', component: SiteViewPage, meta: { action: 'view' }},
                ]
            },
            /* CATEGORIES */
            { path: '/categories', name: 'CategoryListPage', component: CategoryListPage, meta: { requiresAuth: true, record: 'categories', action: 'list' },
                children: [
                    { path: '/categories/create', name: 'CategoryCreatePage', component: CategoryCreatePage, meta: { action: 'create' }},
                    { path: '/categories/:id/edit', name: 'CategoryEditPage', component: CategoryEditPage, meta: { action: 'edit' }},
                    { path: '/categories/:id', name: 'CategoryPage', component: CategoryViewPage, meta: { action: 'view' }},
                ]
            },
            /* USERS */
            { path: '/users', name: 'PeopleListPage', component: UserListPage, meta: { requiresAuth: true, record: 'users', action: 'list'},
                children: [
                    { path: '/users/create', name: 'UserCreatePage', component: UserCreatePage, meta: { action: 'create' } },
                    { path: '/users/:id/edit', name: 'UsersEditPage', component: UserEditPage, meta: { action: 'edit' }},
                    { path: '/users/:id', name: 'UsersPage', component: UserViewPage, meta: { action: 'view' }},
                ]
            },
            /* TEAMS */
            { path: '/teams', name: 'TeamListPage', component: TeamListPage, meta: { requiresAuth: true, record: 'teams', action: 'list'},
                children: [
                    { path: '/teams/create', name: 'TeamCreatePage', component: TeamCreatePage, meta: { action: 'create' } },
                    { path: '/teams/:id/edit', name: 'TeamEditPage', component: TeamEditPage, meta: { action: 'edit' }},
                    { path: '/teams/:id', name: 'TeamPage', component: TeamViewPage, meta: { action: 'view' }},
                ]
            },
            /* REQUESTS */
            { path: '/requests', name: 'RequestListPage', component: RequestListPage, meta: { requiresAuth: true, record: 'requests', action: 'list'},
                children: [
                    { path: '/requests/create', name: 'RequestCreatePage', component: RequestCreatePage, meta: { action: 'create' } },
                    { path: '/requests/:id/edit', name: 'RequestsEditPage', component: RequestEditPage, meta: { action: 'edit' }},
                    { path: '/requests/:id', name: 'RequestsPage', component: RequestViewPage, meta: { action: 'view' }},
                ]
            },
            /* REQUESTS LANDINGS */
            { path: '/request-landings', name: 'RequestLandingListPage', component: RequestLandingListPage, meta: { requiresAuth: true, record: 'requestLandings', action: 'list'},
                children: [
                    { path: '/request-landings/create', name: 'RequestLandingsCreatePage', component: RequestLandingCreatePage, meta: { action: 'create' } },
                    { path: '/request-landings/:id/edit', name: 'RequestLandingsEditPage', component: RequestLandingEditPage, meta: { action: 'edit' }},
                    { path: '/request-landings/:id', name: 'RequestLandingsPage', component: RequestLandingViewPage, meta: { action: 'view' } },
                ]
            },
            { path: '/parts', name: 'PartListPage', component: PartListPage, meta: { requiresAuth: true, record: 'parts', action: 'list'},
                children: [
                    { path: '/parts/create', name: 'PartCreatePage', component: PartCreatePage, meta: { action: 'create' } },
                    { path: '/parts/:id/edit', name: 'PartEditPage', component: PartEditPage, meta: { action: 'edit' }},
                    { path: '/parts/:id', name: 'PartPage', component: PartViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/metrics', name: 'MetricsListPage', component: MetricListPage, meta: { requiresAuth: true, record: 'metrics', action: 'list'},
                children: [
                    { path: '/metrics/create', name: 'MetricCreatePage', component: MetricCreatePage, meta: { action: 'create' } },
                    { path: '/metrics/:id/edit', name: 'MetricEditPage', component: MetricEditPage, meta: { action: 'edit' }},

                    { path: '/metrics/:id/measures', name: 'MetricEditMeasuresPage', component: MetricEditMeasuresPage, meta: { action: 'edit' }},
                    { path: '/metrics/:id/view-measures', name: 'MetricViewMeasuresPage', component: MetricViewMeasuresPage, meta: { action: 'view' }},
                    { path: '/metrics/:id', name: 'MetricPage', component: MetricViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/roles', name: 'RoleListPage', component: RoleListPage, meta: { requiresAuth: true, record: 'roles', action: 'list'},
                children: [
                    { path: '/roles/create', name: 'RoleCreatePage', component: RoleCreatePage, meta: { action: 'create' } },
                    { path: '/roles/:id/edit', name: 'RoleEditPage', component: RoleEditPage, meta: { action: 'edit' }},
                    { path: '/roles/:id', name: 'RolePage', component: RoleViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/templates/:id/editor', name: 'TemplateEditorPage', component: TemplateFullEditorPage, meta: { requiresAuth: true, isFullScreen: true } },
            { path: '/templates', name: 'TemplateListPage', component: TemplateListPage, meta: { requiresAuth: true, record: 'templates', action: 'list'},
                children: [
                    { path: '/templates/create', name: 'TemplateCreatePage', component: TemplateCreatePage, meta: { action: 'create', isFullScreen: true } },
                    { path: '/templates/:id/edit', name: 'TemplateEditPage', component: TemplateEditorPage, meta: { action: 'edit', isFullScreen: true }},
                    { path: '/templates/:id', name: 'TemplatePage', component: TemplateViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/procedures', name: 'ProcedureListPage', component: ProcedureListPage, meta: { requiresAuth: true, record: 'procedures', action: 'list'},
                children: [
                    { path: '/procedures/create', name: 'ProcedureCreatePage', component: ProcedureCreatePage, meta: { action: 'create', isFullScreen: false } },
                    { path: '/procedures/:id/edit', name: 'ProcedureEditPage', component: ProcedureEditPage, meta: { action: 'edit', isFullScreen: false }},
                    { path: '/procedures/:id', name: 'ProcedurePage', component: ProcedureViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/inspections', name: 'InspectionListPage', component: InspectionListPage, meta: { requiresAuth: true, record: 'inspections', action: 'list'},
                children: [
                    { path: '/inspections/create', name: 'InspectionCreatePage', component: InspectionCreatePage, meta: { action: 'create' } },
                    { path: '/inspections/:id/edit', name: 'InspectionEditPage', component: InspectionEditPage, meta: { action: 'edit' }},
                    { path: '/inspections/:id', name: 'InspectionPage', component: InspectionViewPage, meta: { action: 'view' }},
                ]
            },
            { path: '/companies', name: 'CompanyListPage', component: CompanyListPage, meta: { requiresAuth: true, record: 'companies', action: 'list'},
                children: [
                    { path: '/companies/create', name: 'CompanyCreatePage', component: CompanyCreatePage, meta: { action: 'create' } },
                    { path: '/companies/:id/edit', name: 'CompanyEditPage', component: CompanyEditPage, meta: { action: 'edit' }},
                    { path: '/companies/:id', name: 'CompanyPage', component: CompanyViewPage, meta: { action: 'view' }},
                ]
            },



            // { path: '/messages', name: 'MessagePage', component: AppCrudList, meta: { record: 'messages'},
            //     children: [
            //         { path: '/messages/:id', name: 'MessagePage', component: MessagePage, },
            //         { path: '/messages/:id/edit', name: 'MessageEditPage', component: MessagePage, },
            //     ]
            // },

            { path: '/messages', name: 'MessagePage', component: MessagePage, meta: { requiresAuth: true } },
            { path: '/messages/:id', name: 'MessagePageView', component: MessagePage, meta: { requiresAuth: true }  },

            { path: '/actions', name: 'ActionPage', component: ActionListPage, meta: { requiresAuth: true, record: 'actions'},
                children: [
                    { path: '/actions/:id', name: 'ActionPage', component: ActionPage, },
                    { path: '/actions/:id/edit', name: 'ActionEditPage', component: ActionPage, },
                ]
            },

            { path: '/actions/edit', name: 'TagList', component: TagList, meta: { requiresAuth: true }  },

            { path: '/issues', name: 'IssueListPage', component: IssueListPage, meta: { requiresAuth: true }  },
            { path: '/issue/:id/edit', name: 'EditIssue', component: EditIssue, meta: { requiresAuth: true }  },

            { path: '/admin/options', name: 'OptionGeneralPage', component: OptionGeneralPage, meta: { requiresAuth: true }  },
            { path: '/admin/options/account', name: 'OptionAccountPage', component: OptionAccountPage, meta: { requiresAuth: true }  },
            { path: '/admin/options/account/edit', name: 'OptionAccountEditPage', component: OptionAccountPage, meta: { requiresAuth: true }  },
            { path: '/admin/options/workorder-settings', name: 'OptionWorkOrderSettings', component: OptionWorkOrderSettings, meta: { requiresAuth: true }  },
            { path: '/admin/options/workorder-settings/custom-fields', name: 'CustomFields', component: OptionCustomFields, meta: { requiresAuth: true }  },
            { path: '/admin/features/custom-fields', name: 'CustomUserFields', component: OptionCustomFields, meta: { requiresAuth: true }  },
            { path: '/admin/options/option-record-labels', name: 'OptionRecordLabels', component: OptionRecordLabels, meta: { requiresAuth: true }  },

            { path: '/admin/reports', name: 'ResultsPage', component: ResultsPage, meta: { requiresAuth: true }  },

            { path: '/admin/groups', name: 'GroupListPage', component: GroupListPage, meta: { requiresAuth: true, record: 'groups'},
                children: [
                    { path: '/admin/groups/:id', name: 'GroupPage', component: GroupPage, },
                    { path: '/admin/groups/:id/edit', name: 'GroupEditPage', component: GroupPage, },
                ]
            },

            { path: '/assets/types', name: 'TypeList', component: TypeList, meta: { requiresAuth: true }  },

            { path: '/admin/integrations', name: 'IntegrationsPage', component: IntegrationsPage, },
            { path: '/admin/integrations/:type', name: 'FacebookAuth', component: RegisterPage, },
            { path: '/admin/features', name: 'FeaturesPage', component: FeaturesPage, },
            { path: '/admin/emails', name: 'EmailPage', component: EmailPage, meta: { requiresAuth: true }},
        ]
    },

    { path: '/login_request/:id', name: 'LoginRequest', component: LoginRequest },
    { path: '/request-landing/:id', name: 'RequestLandingPortal', component: RequestLandingPortal },

    { path: '/:pathMatch(.*)*', name: 'NotFoundPage', component: NotFoundPage, },
]

const router = new createRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    history: createWebHistory(),
    routes
})

router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const user = store.state.auth.USER
        if (!user) {
            store.commit('SET_REDIRECT_AFTER_LOGIN', window.location.href)
            next({ path: '/login' })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
