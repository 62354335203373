import API from '@/api'

const state = {
  USER: {},
  USERS: [],
  ALL_USERS: []
}

const getters = {
  GET_USER: state => state.USER,
  GET_USERS: state => state.USERS,
  GET_ALL_USERS: state => state.ALL_USERS,
  GET_ALL_ONLY_USERS: state => state.ALL_USERS.filter(u => u.type === 'user'),
  GET_USER_BY_ID: state => id => {
    if (state.ALL_USERS.length === 0)
      return null
    return state.ALL_USERS.find(e => e.id === id)
  },
  GET_USERS_BY_IDS: state => ids => {
    return state.ALL_USERS.filter(e => ids.includes(e.id))
  },
}

const mutations = {
  // USERS
  SET_USER(state, payload) {
    state.USER = payload
  },
  SET_USERS(state, payload) {
    state.USERS = payload
  },
  SET_ALL_USERS(state, payload) {
    state.ALL_USERS = payload
  },
  CREATE_USER(state, payload) {
    state.USER = payload
    state.USERS.data.push(payload)
  },
  UPDATE_USER(state, payload) {
    const index = state.USERS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.USERS.data[index], payload)

    if (state.USER !== null && state.USER.id === payload.id)
      state.USER = payload;
  },
  DELETE_USER(state, payload) {
    if (state.USER?.id === payload.id)
      state.USER = {}
    state.USERS = state.USERS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD USERS
  CREATE_USER(context) {
    context.commit('SET_USER', {
      name: '',
      email: '',
      roleId: '',
      props: {}
    })
  },
  async READ_USERS(context, payload) {
    const response = await API.Get('/api/users', payload)
    if (response.type === 'Success') {
      context.commit('SET_USERS', response.res.data)
      return response.res.data
    }
    context.commit('SET_USERS', [])
  },
  async READ_ALL_USERS(context) {
    const response = await API.Get('/api/options/users')
    if (response.type === 'Success')
      context.commit('SET_ALL_USERS', response.res.data)
    else
      context.commit('SET_ALL_USERS', [])
  },
  async INVITE_USER(context, payload) {
    const response = await API.Post(`/api/users/${payload.id}/invite`)
    if (response.type === 'Success') {
      // context.commit('SET_USER', response.res.data)
      return response.res.data
    }
  },
  async SAVE_USER(context, payload) {
    if (payload.id !== undefined) {
      const response = await API.Put(`/api/users/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_USER', response.res.data)
        return response.res.data
      }
    }
    else {
      const response = await API.Post('/api/users', payload)
      if (response.type === 'Success') {
        context.commit('CREATE_USER', response.res.data)
        return response.res.data;
      }
    }
  },
  async SAVE_USER_TIMEZONE(context, payload) {
    if (payload.id !== undefined) {
      const response = await API.Patch(`/api/users/${payload.id}`, payload)
      if (response.type === 'Success') {
        return response.res.data
      }
    }
  },
  async READ_USER(context, payload) {
    const response = await API.Get(`/api/users/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_USER', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_USER(context, payload) {
    const response = await API.Put(`/api/users/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_USER', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_USER_NOTIFICATIONS(context, payload) {
    const response = await API.Put(`/api/users/${payload.id}/notifications`, payload)
    if (response.type === 'Success') {
      context.commit('SET_USER', response.res.data)
      return response.res.data
    }
  },
  async DELETE_USER(context, payload) {
    const response = await API.Delete(`/api/users/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_USER', payload)
      return
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
