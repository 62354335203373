<template>
  <template v-if="clean">
    <vSelect :modelValue="internalValue" @update:modelValue="selectOption" :taggable="taggable" ref="select"
      :class="{ 'multiple-selector': hasMultipleOptions }" :clearable="false" :append-to-body="true"
      :multiple="hasMultipleOptions" :reduce="(o) => value !== null ? o[value] : o" :inputId="value"
      :label="valueCaption" :disabled="disabled" :options="getOptions" />
  </template>
  <div v-else @click="labelClick">
    <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled" :errors="errors"
      v-slot="{ focusin, focusout }" :expand="hasMultipleOptions" :has-multiple-options="hasMultipleOptions">
      
      <div class="fill-primary-500" :class="hasMultipleOptions ? '-mt-2' : 'mt-4'">
        <app-icon v-if="showIconLabel" :icon="iconLabel" :width="14" :height="16" />
      </div>
      <div class="flex-grow" :class="hasMultipleOptions ? '-mt-[28px]' : ''">
        <vSelect :modelValue="internalValue" @update:modelValue="selectOption" :taggable="taggable" ref="select"
          @focusin="focusin" @focusout="focusout"
          class="block mt-4 w-full border-0  !p-0 text-black !bg-transparent focus:ring-0 sm:text-sm sm:leading-6"
          :create-option="inputText => (getNewOption(inputText))" :class="hasMultipleOptions ? '' : '-top-1'"
          :clearable="false" :append-to-body="true" :multiple="hasMultipleOptions"
          :reduce="(o) => value !== null ? o[value] : o" :inputId="id" :label="valueCaption" :disabled="disabled"
          :options="getOptions">

          <template #open-indicator="{ attributes }">
            <div :class="hasMultipleOptions ? '-mt-4' : internalValue ? '-mt-3' : ''">
              <span v-bind="attributes">
                <app-icon icon="chevron_down_regular" extra-class="fill-gray-900" :width="16" :height="16" />
              </span>
            </div>
          </template>
          <template v-slot:option="option" v-if="hasAvatar">
            <div class="flex items-center">
              <div class="flex items-center" v-if="hasAvatar">
                <user-avatar :user="option" width="w-10" height="h-10" />
                <span class="font-nhu-regular text-gray-900 ml-2">{{ option?.name }}</span>
              </div>
            </div>
          </template>
          <template #selected-option-container="{ option, deselect }" v-if="hasMultipleOptions">
            <div class="px-[8px] py-[2px] h-[20px] rounded-[50px] border-2 border-gray-500 flex items-center mx-1"
              :key="option" :style="getStyle(option)">
              <span class="flex items-center font-nhu-regular h-[20px]">{{ option?.name }}</span>
              <div class="ml-1 cursor-pointer" @click="deselect(option)">
                <app-icon icon="xmark_solid" :width="14" :height="14" />
              </div>
            </div>
          </template>
          <template #selected-option v-if="hasChild">
            <div>
              {{ selectedOption }}
            </div>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>

        </vSelect>
        <div v-if="deselectIcon" @click.stop="deselectOption"
          class="absolute top-1/2 right-8 transform -translate-y-1/2 cursor-pointer z-50 fill-gray-200">
          <app-icon icon="circle_xmark_regular" :width="20" :height="20" />
        </div>
      </div>
    </app-input-container>
    <div v-if="suppText !== ''" class="flex items-center mt-1">
      <app-icon extra-class="mr-2 fill-gray-300" icon="circle_info_regular" :width="13" :height="13" />
      <span class="font-nhu-regular fs-12 text-gray-300">{{ suppText }}</span>
    </div>
  </div>
</template>

<script>
//import API from '@/api'
import vSelect from 'vue-select'
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "AppDropdown",
  components: { UserAvatar, vSelect },
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Number, String, Array], default: "" },
    multiple: { type: Boolean, default: false },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    error: { type: String, default: "" },
    errors: { type: Object, default: null },
    getter: { type: String, default: null },
    url: { type: String, default: null },
    options: { type: Array, default: null },
    value: { type: String, default: "id" },
    valueCaption: { type: String, default: "name" },
    iconLabel: { type: String, default: '' },
    suppText: { type: String, default: '' },
    clean: { type: Boolean, default: false },
    showDeleted: { type: Boolean, default: false },
    filterBy: { type: Function, default: null },
    allowNulls: { type: Boolean, default: false },
    hasAvatar: { type: Boolean, default: false },
    hasColors: { type: Boolean, default: false },
    hasChild: { type: Boolean, default: false },
    taggable: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", 'keydown', 'change'],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectOption(value);
      }
    },
    getOptions() {
      const formattedOptions = [];
      let originalOptions = this.getter ? this.$store.getters[this.getter] ?? [] : this.options ?? [];
      if (!Array.isArray(originalOptions) && Array.isArray(originalOptions.data))
        originalOptions = originalOptions.data;

      originalOptions
        .filter(x => !x?.isDeleted && !x?.parentId)
        .forEach(option => {
          // Agregar el padre
          formattedOptions.push({
            ...option,
            [this.value]: option[this.value],
            [this.valueCaption]: option[this.valueCaption]
          });

          // Agregar los hijos si existen
          if (option.childs && option.childs.length > 0) {
            option.childs.forEach(child => {
              formattedOptions.push({
                [this.value]: child._id,
                [this.valueCaption]: `-- ${child.name}` // El prefijo "--" ayuda a identificar a los hijos
              });

              // Agregar los nietos si existen
              if (child.childs && child.childs.length > 0) {
                child.childs.forEach(grandChild => {
                  formattedOptions.push({
                    [this.value]: grandChild._id,
                    [this.valueCaption]: `---- ${grandChild.name}` // El prefijo "----" ayuda a identificar a los nietos
                  });
                });
              }
            });
          }
        });
      if (this.filterBy === null)
        return formattedOptions;
      return this.filterBy(formattedOptions, this.modelValue)
    },
    selectedOption() {
      const selected = this.getOptions.find(x => x[this.value] === this.modelValue);
      if (selected === undefined)
        return ""
      else if (this.hasChild) {
        let parent;
        let grandParent;
        this.getOptions.forEach(option => {
          if (option.childs && option.childs.some(child => child._id === this.modelValue)) {
            parent = option;
          }
          if (option.childs) {
            option.childs.forEach(child => {
              if (child.childs && child.childs.some(grandChild => grandChild._id === this.modelValue)) {
                grandParent = option;
                parent = child;
              }
            });
          }
        });
        const childName = selected.name.replace('--', '').trim();
        const grandChildName = selected.name.replace('----', '').trim();
        if (grandParent) {
          return `${grandParent.name} / ${parent ? parent.name : ''} / ${grandChildName}`;
        } else if (parent) {
          return `${parent.name} / ${childName}`;
        } else {
          return selected[this.valueCaption];
        }
      } else {
        return selected[this.valueCaption]
      }
    },
    deselectIcon() {
      return !Array.isArray(this.modelValue) && !this.$isNullOrEmpty(this.modelValue) && !this.disabled && this.allowNulls
    },
    showIconLabel() {
      return this.iconLabel && !this.$isNullOrEmpty(this.modelValue) && this.modelValue.length > 0;
    },
    hasMultipleOptions() {
      return Array.isArray(this.modelValue) || this.multiple;
    }
  },
  methods: {
    getNewOption(value) {
      return {
        [this.value]: value,
        [this.valueCaption]: value
      }
    },
    labelClick() {
      this.$refs.select.$el.querySelector('input').focus();
    },
    selectOption(value) {
      this.$emit("update:modelValue", value)
      this.$emit("change")
      this.$emit("add-option", null)
      if (this.taggable && this.getOptions.findIndex(x => x[this.value] === value) === -1) {
        this.$emit("add-option", value)
      }
    },
    hasImage(option) {
      return option?.color || option?.image;
    },
    deselectOption() {
      this.$emit("update:modelValue", null);
      this.$emit("change");
    },
    getStyle(option) {
      const defaultColor = '#eeeffd';
      const defaultTextColor = '#575fea';
      const defaultBorderColor = '#A5BFF9';

      const backgroundColor = this.hasColors && option.color ? option.color + '1A' : defaultColor;
      const textColor = this.hasColors && option.color ? option.color : defaultTextColor;
      const borderColor = this.hasColors && option.color ? option.color + '4A' : defaultBorderColor;

      return {
        backgroundColor,
        color: textColor,
        fill: textColor,
        border: `1px solid ${borderColor}`
      };
    }
  },
};
</script>


<style>
.vs__dropdown-toggle {
  padding: 0 !important;
}

.vs__search,
.vs__search:focus {
  padding: 0.5rem 0.0rem 0.0rem 0.75rem;
}

.vs__search,
.vs__search:focus {
  line-height: 1.5rem;
}

.vs__dropdown-option--highlight {
  background-color: #cbcdf8 !important;
  color: #575fea !important;
  font-family: 'neue-haas-unica-regular', serif;
}

.vs__dropdown-menu {
  color: #767C86 !important;
  font-size: 14px;
  font-family: 'neue-haas-unica-regular', serif;
}

.vs__search {
  height: 20px !important;
}

.vs__selected-options {
  padding: 0 !important;
}

.v-select .vs--single .vs--searchable .vs--disabled {
  display: flex;
  align-items: center;
}
</style>
