<template>
  <app-crud ref="crud" v-slot="{ item, errors }" @validate="onValidate">

    <app-textbox id="name" v-model="item.name" :label="getLabel" :errors="errors" :focus="focusField"
      @update:model-value="clearErrorMsg" />

    <work-order-type-selector v-model="item.orderType" id-prefix="first" />

    <app-textarea v-if="isOrderType(item, ['corrective', 'preventive'])" v-model="item.description"
      :label="$t('workorders.descriptionLabel')" />

    <app-checklist v-if="isOrderType(item, ['corrective', 'preventive'])" v-model="item.tasks"
      :label="$t('workorders.tasksLabel')" :numbers="true"
      :suppText="$t('checklist.placeholder')"/>

    <app-checklist v-if="isOrderType(item, ['corrective', 'preventive'])" v-model="item.checklist"
      :label="$t('workorders.checklistLabel')"
      :suppText="$t('checklist.placeholder')"/>

    <app-crud-field-edit v-if="isOrderType(item, ['metric']) && $hasModule('metrics')" fieldId="metricId"
      :label="$t('workorders.metricId')" :errors="errors" :allow-nulls="true" @change="assignToWorkOrder(item.metricId, item)" />

    <app-crud-field-edit v-if="isOrderType(item, ['inspection']) && $hasModule('templates')" fieldId="templateId"
      :label="$t('workorders.templateId')"  :errors="errors" :allow-nulls="true" />


    <app-crud-field-edit fieldId="assignedTo" :label="$t('workorders.assignTo')" />

    <work-order-schedule-picker v-model="item.schedule" :orderType="item.orderType" :dueDate="item.dueDate"
      :label="$t('workorders.dueDate&periodicity')" @updateDueDate="updateDate(item, $event)" />

    <app-crud-field-edit fieldId="priority" />

    <app-crud-field-edit v-if="$hasModule('sites')" fieldId="siteId" :has-child="true" />

    <app-crud-field-edit v-if="$hasModule('assets')" fieldId="assetId"
      :filter-by="(array) => filterData(array, item.siteId, item, 'assetId', 'siteId', true)" @change="updateSite(item)"
      :has-child="true" />

    <app-crud-field-edit v-if="isOrderType(item, ['corrective', 'preventive']) && $hasModule('categories')"
      fieldId="categories" />

    <app-crud-properties v-model="item.props" />

    <app-image-list v-model="item.images" :rounded="false" :label="$t('workorders.addImages')" />

    <app-file-list v-model="item.files" :rounded="false" :label="$t('workorders.addImages')" />


    <app-form-field v-if="isOrderType(item, ['corrective', 'preventive'])" :label="$t('workorders.addWokOrder')"
      v-model="item.newWorkOrder.enabled">
      <div class="pl-4 border-l-4 border-blue-400" v-show="item.newWorkOrder.enabled">
        <app-textbox id="name2" v-model="item.newWorkOrder.name" :label="getLabel" :error="error2Msg"
          :focus="focusField" @update:model-value="clearErrorMsg" />
        <work-order-type-selector v-model="item.newWorkOrder.orderType" :is-new-work-order="true" id-prefix="second" />
        <app-textarea v-if="isNewOrderType(item, ['corrective'])" v-model="item.newWorkOrder.description"
          :label="$t('workorders.descriptionLabel')" />
        <app-dropdown v-if="isNewOrderType(item, ['inspection']) && $hasModule('templates')"
          v-model="item.newWorkOrder.templateId" value="id" :label="$t('workorders.templateId')"
          getter="GET_ALL_TEMPLATES" icon-label="file_lines_regular" />

        <app-dropdown v-model="item.newWorkOrder.assignedTo" value="id" :label="$t('workorders.assignTo')"
          :has-avatar="true" getter="GET_ALL_USERS" icon-label="user_regular" />

        <app-priority-picker v-model="item.newWorkOrder.priority" :label="$t('records.workorders.fields.priority')" />

        <app-dropdown v-if="$hasModule('sites')" v-model="item.newWorkOrder.siteId" value="id"
          :label="$t('records.workorders.fields.siteId')" :allow-nulls="true" :has-child="true" getter="GET_ALL_SITES"
          icon-label="location_dot_regular" />

        <app-dropdown v-if="$hasModule('assets')" v-model="item.newWorkOrder.assetId" value="id"
          :label="$t('records.workorders.fields.assetId')"
          :filter-by="(array) => filterNewData(array, item.newWorkOrder.siteId, item.newWorkOrder, 'assetId', 'siteId', true)"
          :allow-nulls="true" :has-child="true" @change="newUpdateSite(item)" getter="GET_ALL_ASSETS"
          icon-label="boxes_stacked_regular" />

        <app-dropdown v-if="isNewOrderType(item, ['corrective']) && $hasModule('categories')"
          v-model="item.newWorkOrder.categories" value="id" :label="$t('records.workorders.fields.categories')"
          getter="GET_ALL_CATEGORIES" icon-label="tag_regular" />

        <app-crud-properties v-model="item.newWorkOrder.props" />
      </div>
    </app-form-field>

  </app-crud>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import WorkOrderTypeSelector from "./WorkOrderTypeSelector.vue";
import WorkOrderSchedulePicker from "./WorkOrderSchedulePicker.vue";
import { ArrowTrendingUpIcon } from "@heroicons/vue/24/outline";

export default {
  name: "WorkOrderEditPage",
  components: { WorkOrderTypeSelector, WorkOrderSchedulePicker },
  data() {
    return {
      errorMsg: '',
      error2Msg: '',
      validated: false,
      focusField: false,
      data: [],
      newData: [],
      ArrowTrendingUpIcon: ArrowTrendingUpIcon
    }
  },
  mounted() {
    this.focusField = true
  },
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY', allMetrics: 'GET_ALL_METRICS', workOrder: 'GET_WORKORDER' }),
    action() {
      return this.$route.meta.action;
    },
    hasProperties() {
      return this.company?.properties?.workorder?.length > 0
    },
    getLabel() {
      if (this.company && this.company.recordLabels && this.company.recordLabels.workorders.label) {
        let labelInfo = this.company.recordLabels.workorders.label;
        return this.$t('customName') + labelInfo;
      } else {
        return this.$t('workorders.name');
      }
    },
    hasNewWorkOrder() {
      return (this.workOrder.newWorkOrder) ? true : false;
    }
  },
  methods: {
    ...mapActions(['CREATE_NEW_WORKORDER']),
    assignToWorkOrder(metricId, item) {
      const metric = this.allMetrics.find(x => x.id === metricId)
      if (metric) {
        item.measure.measurementUnit = metric.measurementUnit
      }
    },
    isOrderType(item, values) {
      return values.includes(item.orderType);
    },
    isNewOrderType(item, values) {
      return values.includes(item.newWorkOrder.orderType);
    },
    updateSite(item) {
      //Si elijo un activo que tiene un sitio, se lo asigno al workorder solo si es diferente al que ya tiene
      const asset = this.data.find(x => x.id === item.assetId)
      if (asset?.siteId && asset.siteId !== item.siteId) {
        item.siteId = asset.siteId
      }
    },
    newUpdateSite(item) {
      const asset = this.data.find(x => x.id === item.newWorkOrder.assetId)
      if (asset?.siteId && asset.siteId !== item.newWorkOrder.siteId) {
        item.newWorkOrder.siteId = asset.siteId
      }
    },
    filterData(array, id, item, myFieldId, fieldId = "id", includeChilds = false) {
      this.data = array.filter(x => x[fieldId] === id || !id || !x[fieldId] || (includeChilds && this.hasIncludeOnChilds(x.childs, "_id", id)))
      if (!this.data.find(x => x.id === item[myFieldId])) {
        item[myFieldId] = ''
      }
      return this.data
    },
    filterNewData(array, id, item, myFieldId, fieldId = "id", includeChilds = false) {
      this.newData = array.filter(x => x[fieldId] === id || !id || !x[fieldId] || (includeChilds && this.hasIncludeOnChilds(x.childs, "_id", id)))
      if (!this.newData.find(x => x.id === item[myFieldId])) {
        item[myFieldId] = ''
      }
      return this.newData
    },
    hasIncludeOnChilds(items, fieldId, id) {
      if (items && items?.length)
        return items.some(child => child[fieldId] === id || this.hasIncludeOnChilds(child.childs, fieldId, id))
      return false
    },
    async onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        onError({id:'name', text: this.$t('records.workorders.errors.nameRequired')});
      } else if (item.orderType == 'metric' && !item.metricId) {
        onError({id:'metricId', text: this.$t('records.workorders.errors.metricRequired')});
      } else if (item.orderType == 'inspection' && !item.templateId) {
        onError({id:'templateId', text: this.$t('records.workorders.errors.templateRequired')});
      } else if (item.newWorkOrder.enabled && !item.newWorkOrder.name) {
        onError({id:'name2', text: this.$t('records.workorders.errors.nameRequired')});
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
    updateDate(item, date) {
      item.dueDate = date
    },
    newWorkOrderChanged(item, e) {
      if (e) {
        item.newWorkOrder = {
          name: '', orderType: 'corrective',
          description: '', checklist: [], siteId: '', assetId: '', dueDate: null,
          images: [], assignedTo: [], categories: [], comments: [],
          schedule: { id: undefined, periodically: "none", day: 1, every: 1, weekDays: [] },
          inspectionId: '', templateId: '', templatePublishId: '',
          priority: 'none', parts: [], resources: [], props: {}, measure: {}
        }
      } else {
        delete item.newWorkOrder
      }
    }
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
