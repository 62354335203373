const state = {
    status: "",
    visible: false,
}
const getters = {
    GET_IMPORT_VISIBLE: state => state.visible,
    GET_IMPORT_STATUS: state => state.status,
}
const mutations = {
    SET_IMPORT_VISIBLE: (state, visible) => {
        state.visible = visible
    },
    SET_IMPORT_STATUS: (state, status) => {
        state.status = status
        state.visible = true
        this.SET_IMPORT_VISIBLE(true)
    },
}
export default {
    state,
    getters,
    mutations,
}
