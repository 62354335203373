<template>
    <div class="mt-4">
      <div class="flex">
        <div class="flex-1 flex items-center" v-if="label">
          <component :is="icon" class="inline mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          <img :src="image" alt="image" class="inline mr-3 flex-shrink-0 h-8 w-16 border rounded p-2 text-gray-400 group-hover:text-gray-500" v-if="image" />
          <span class="font-nhu-regular text-[14px]">{{label}}</span>
        </div>
        <div class="flex">
          <button name="visible" v-if="modelValue !== null" @click="changeVisible"
            :class="[modelValue ? 'bg-primary-500' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500']">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="[modelValue ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </button>
        </div>
      </div>
      <TransitionRoot
          :show="modelValue === null || modelValue === true"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0">

        <span class="mt-2 font-nhu-light text-[14px]">{{ description }}</span>

        <div class="px-2">
          <slot></slot>
        </div>
      </TransitionRoot>
    </div>
</template>

<script>
import { TransitionRoot } from '@headlessui/vue'

export default {
  name: "AppFormField",
  components:  {TransitionRoot},
  props: {
    id: {
      type: String,
      default: "",
    },
    icon: {
      type: [Object, Function],
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  emits:["update:modelValue"],
  methods: {
    changeVisible() {
      if(!this.lock)
        this.$emit("update:modelValue", !this.modelValue);
    }
  }
};
</script>
