<template>
  <nav aria-label="Progress">
    <ol role="list" class="overflow-hidden">
      <slot></slot>
    </ol>
  </nav>
</template>

<script>

export default {
  name: "AppStepper",
  emits: [],
  props: {
  },
  methods: {
  },
  computed: {
  }
};
</script>