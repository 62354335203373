<template>
  <div :class="extraClass" class="flex">
    <div v-if="this.$icons[icon]" class="flex items-center">
      <svg
          v-bind="$attrs"
          :viewBox="this.$icons[icon].viewbox" >
        <path
            :d="this.$icons[icon].path"/>
      </svg>
    </div>
    <div class="w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppIcon",
  methods: {},
  components: {},
  mounted() {
  },
  props: {
    icon: {
      type: [Object, String],
      default: null,
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
};
</script>
