<template>
    <div class="flex flex-col  w-full h-full rounded">
        <div class="flex flex-grow h-full"></div>
        <div v-if="action === 'view'" class="text-left pl-4 mt-5 flex flex-col">
            <div v-for="messageData in messages" :key="messageData.id">
                <div class="flex items-center my-2">
                    <div v-if="getUser(messageData.senderId) && getUser(messageData.senderId).images?.length >= 1">
                        <app-image :src="getUser(messageData.senderId).images" alt="user"
                                   class="w-10 h-10 mr-2 rounded-full"/>
                    </div>
                    <div v-else>
                        <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                    </div>
                    <div class="flex flex-col justify-between">
                        <div class="flex items-center">
                            <div>
                                <template >
                                    <span class="font-nhu-bold text-[16px]" v-if="getUser(messageData.senderId)">
                                        {{ getUser(messageData.senderId).name }}
                                    </span>
                                </template>
                            </div>
                            <div>
                                <span class="text-xs mb-1 mx-1">
                                    {{ formatDate(messageData.sentDate) }}
                                </span>
                            </div>
                        </div>
                        <div>
                            <span class="text-xs mt-1">{{ messageData.body }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center space-x-2 p-5 sticky bottom-0 bg-white">
            <div class="w-full">
                <app-textbox :disabled="getMembers()" v-model="localMessage.body" :enter="true"
                             :label="$t('addUpdate')" @enter="sendMessage()"/>
            </div>
            <div>
                <app-button :disabled="getMembers()" @click="sendMessage()">{{ $t('send') }}</app-button>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import {UserIcon} from "@heroicons/vue/24/outline";
import AppImage from "@/components/AppImage.vue";
import {mapActions} from "vuex";

export default {
  name: "MessagesList",
  components: {AppImage, UserIcon, AppButton},
  emits: ['updateMessage'],
  props: {
    action: {
      type: String,
      default: ''
    },
    messages: {
      type: Object,
      default: () => {
      }
    },
    conversation: {
      type: Object,
      default: () => {
      }
    },
    message: {
      type: Object,
      default: () => {
      }
    },
    users: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      localAction: '',
      localMessage: {}
    }
  },
  watch: {
    action(newValue) {
      this.localAction = newValue;
    },
    message: {
      deep: true,
      handler(newValue) {
        this.localMessage = {...newValue};
      }
    },
  },
  methods: {
    ...mapActions(['SAVE_MESSAGE', 'SAVE_CONVERSATION']),
    getUser(senderId) {
      const user = this.users.find(x => x.id === senderId);
      return user || {};
    },
    formatDate(inputDate) {
      let date = new Date(inputDate);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let hour = date.getHours();
      let minutes = date.getMinutes();

      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;
      if (hour < 10) hour = '0' + hour;
      if (minutes < 10) minutes = '0' + minutes;

      return `${day}/${month}/${year}, ${hour}:${minutes}`;
    },
    getMembers() {
      return this.conversation.members.length <= 0;
    },
    async sendMessage() {
      if (this.localMessage.body !== '') {
        await this.SAVE_CONVERSATION(this.conversation)
        this.localMessage.conversationId = this.conversation.id
        await this.SAVE_MESSAGE(this.localMessage)
        this.localMessage.body = ''
        this.$router.push('/messages/' + this.conversation.id)
        this.$emit("updateMessage");
      }
    },
  },
  created() {
    this.localAction = this.action
    this.localMessage = this.message
  }
}
</script>

<style scoped>

</style>
