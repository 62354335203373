
<script>
  import ParentControl from './CategoryEditPage'

  export default {
    name: 'CategoryViewPage',
    extends: ParentControl,
  }
</script>

