<template>
  <app-crud ref="crud"  v-slot="{ item }">
    <app-label :label="$t('records.roles.fields.name')">
      <pre class="font-nhu-regular text-gray-900 fs-14">{{ item.name }}</pre>
    </app-label>
    <app-label value=" " :label="$t('records.roles.fields.permissions')">
      <div>
        <div>
          <div v-for="([name, perms]) in Object.entries(modules)" :key="name" class="mb-4">
            <div class="text-2xl border-b w-full">{{ $getRecordLabel(name, 'plural') ?  $getRecordLabel(name, 'plural') : $t('modules.' + name)}}</div>

            <div class="grid grid-cols-3">
              <div v-for="perm in perms" :key="perm.id" class="w-full">
                <div  class="flex items-center my-2">
                  <div>
                    <app-checkbox :model-value="item?.permissions?.includes(perm.id)" :disabled="true"/>
                  </div>
                  <span class="font-nhu-regular text-gray-900 fs-14 ml-2">{{ perm.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-label>
  </app-crud>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RoleViewPage",
  computed: {
    ...mapGetters({ permissions: 'GET_PERMISSIONS'}),
    modules() {
      return Object.groupBy(this.permissions, x=> x.module);
    },
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
