<template>
  <app-crud :extra-class="'bg-white'" @read="loadPublishTemplate" :show-buttons="showButtons">
    <div class="max-w-5xl mb-4 mx-auto px-3" v-if="currentPage">
      <template v-if="currentPage">
        <div v-for="data in currentPage.items" :key="data.id">
          <div v-if="data.isSection">
            <InspectionSection :item="data"/>
          </div>
          <div v-else class="border border-gray-300 rounded-lg my-5 px-5 py-5 bg-white">
            <InspectionQuestion :item="data"/>
          </div>
        </div>
      </template>
    </div>
    <div class="h-[100px]"></div>
    <div class="h-0 border-b border-gray-50 my-4"/>
    <div v-if="template" class=" p-2 bg-white flex justify-center">
      <div class="max-w-5xl w-full flex justify-between">
        <div class="flex justify-center">
          <app-button @click="prevPage" type="secondary" background="light" :disabled="template?.pages?.length < 1 || currentPageIndex === 0">
            {{$t('inspections.previousPage')}}
          </app-button>
        </div>
        <div class="flex justify-center">
          <app-button @click="nextPage" type="secondary" background="light" :disabled="currentPageIndex === template?.pages?.length - 1">
            {{$t('inspections.nextPage')}}
          </app-button>
        </div>
      </div>
    </div>
  </app-crud>
</template>

<script>
import InspectionSection from "@/pages/inspections/InspectionSection.vue";
import InspectionQuestion from "@/pages/inspections/InspectionQuestion.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InspectionEditPage",
  components: {InspectionQuestion, InspectionSection},
  props: {
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentPageIndex: 0,
    }
  },
  computed: {
    ...mapGetters({template: 'GET_PUBLISH_TEMPLATE', inspection: "GET_INSPECTION"}),
    currentPage() {
      return this.template && this.template.pages
          ? this.template.pages[this.currentPageIndex]
          : null;
    },
  },
  methods: {
    ...mapActions(['READ_INSPECTION', 'READ_PUBLISH_TEMPLATE']),
    nextPage() {
      if (this.currentPageIndex < this.template.pages.length - 1) {
        this.currentPageIndex++;
      }
    },
    prevPage() {
      if (this.currentPageIndex > 0) {
        this.currentPageIndex--;
      }
    },
    loadPublishTemplate() {
      this.READ_PUBLISH_TEMPLATE({id: this.inspection.templatePublishId});
    },
  }
}
</script>

<style scoped>

</style>
