<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="/images/zigo.png" alt="zgio" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{$t('thankYou')}}</h2>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LogoutPage',
  components: {},
  data() {
    return {
    }
  },

  methods: {
    ...mapActions(['LOGOUT']),
  },
  async created()
  {
    await this.LOGOUT();
    setInterval(function () { window.location.href = '/login' }, 2000);
  }

}
</script>
