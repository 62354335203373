import store from '@/store'

function HasPermission(claim) {
    if(!claim) return true
    let user = store.getters['GET_CURRENT_USER']
    let hasPermission = user?.permissions?.includes(claim)
    //if(!hasPermission) console.log('You do not have permission to perform this action', claim)
    return hasPermission
}

export default Object.freeze({
    hasPermission: (p) => HasPermission(p),
})
