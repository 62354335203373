<template>
  <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('records.companies.fields.name')" />
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
export default {
  name: "CompanyEditPage",
  components: { },
  data() {
    return {
      errorMsg: '',
      validated: false,
    }
  },
  methods: {
    onValidate({item, onSuccess, onError}) {
      if (!item.name) {
        this.errorMsg = this.$t('records.companies.errors.name');
        onError();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
