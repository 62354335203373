<template>
  <button @click="$emit('click')" type="button"
          class="relative block w-1/2 p-12 m-auto mb-4 text-center border-2 border-gray-300 border-dashed rounded hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    <svg class="w-12 h-12 mx-auto text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
         fill="none" viewBox="0 0 48 48" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"/>
    </svg>
    <span class="block mt-2 text-sm font-medium text-gray-900"><slot></slot></span>
  </button>
</template>

<script>
export default {
  name: "AppAddFirst",
}
</script>

<style scoped>

</style>
