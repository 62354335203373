<template>
  <app-crud ref="crud" v-slot="{ item }">
    <div class="mb-4">
      <span class="fs-14 font-nhu-regular text-gray-400">{{$t('requests.request') + '#' + item.number}}</span>
    </div>
    <app-label :label="$t('records.requests.fields.description')"
               :value="item.description"/>
    <app-label v-if="item.images?.length" :label="$t('records.requests.fields.images')"
               :value="item.images"
               :images="true"/>
    <app-label :label="$t('records.requests.fields.assetId')"
               :value="item.assetId"
               getter="GET_ALL_ASSETS"/>
    <app-label :value="item.siteId"
               getter="GET_ALL_SITES" :label="$t('records.requests.fields.siteId')"/>
    <app-label :value="item.email"
               :label="$t('records.requests.fields.email')"/>
    <app-label :value="item.phone"
               :label="$t('records.requests.fields.phone')"/>
    <app-label :value="getPriority(item.priority)"
               :label="$t('records.requests.fields.priority')"/>
    <div v-if="item.status === 'pending'" class="flex gap-2">
      <div class="flex-1"></div>
      <app-button @click="openRejectDialog(item)">{{ $t('request.actions.reject') }}</app-button>
      <app-button @click="doApprove(item)">{{ $t('request.actions.approve') }}</app-button>
    </div>
    <div v-else>
      <app-label :label="$t('records.requests.fields.status')"
                 :value="item.workOrderStatus
                 ? $t('records.requests.status.' + item.status) + ' / ' + $t('records.workorders.status.' + item.workOrderStatus)
                 :$t('records.requests.status.' + item.status)"/>
<!--      <app-label :label="$t('records.requests.fields.status')"-->
<!--                 :value="formatStatus(item.status)" />-->
    </div>
    <div v-if="item.status === 'rejected'">
      <app-label :label="$t('records.requests.rejected_reason')"
                 :value="item.message"/>
    </div>
    <div class="flex justify-between">
      <app-button v-if="item.prevWorkOrderId"
                  @click="doGoToWorkorder(item.prevWorkOrderId)">
        {{ $t('request.goToPrevWorkorder') }}
      </app-button>
      <app-button v-if="item.nextWorkOrderId"
                  @click="doGoToWorkorder(item.nextWorkOrderId)">
        {{ $t('request.goToNextWorkorder') }}
      </app-button>
    </div>
    <reject-dialog :item="item" :open="open" @reject="doReject" @close-dialog="closeDialog"/>
  </app-crud>
</template>

<script>
import {mapActions} from "vuex";
import RejectDialog from "@/pages/requests/RejectDialog.vue";

export default {
  name: "RequestViewPage",
  components: {
    RejectDialog
  },
  data() {
    return {
      open: false
      // status: this.$slots.item.status
    }
  },
  methods: {
    ...mapActions(['REJECT_REQUEST']),
    openRejectDialog() {
      this.open = true
    },
    closeDialog() {
      this.open = false
    },
    async doReject(data) {
      await this.REJECT_REQUEST({id: data.id, message: data.reason});
    },
    doApprove(item) {
      this.$router.push('/workorders/create?requestId=' + item.id);
    },
    doGoToWorkorder(id) {
      this.$router.push('/workorders/' + id + '?back=true');
    },
    formatStatus(status) {
      const reqStatus = status.split(' /')[0]
      const workOrderStatus = status.split(' / ')[1]
      let reqStatusFormatted = this.getRequestStatus(reqStatus)
      let workOrderStatusFormatted = this.getWorkOrderStatus(workOrderStatus)
      return workOrderStatusFormatted !== '' && workOrderStatusFormatted !== undefined
          ? `${reqStatusFormatted} / ${workOrderStatusFormatted}`
          : reqStatusFormatted
    },
    getRequestStatus(reqStatus) {
      let reqStatusFormatted = ''
      switch (reqStatus) {
        case 'accepted':
          reqStatusFormatted = this.$t('requests.statusOptions.accepted')
          if (reqStatusFormatted === '') {
            return reqStatus
          }
          return reqStatusFormatted
        case 'pending':
          reqStatusFormatted = this.$t('requests.statusOptions.pending')
          if (reqStatusFormatted === '') {
            return reqStatus
          }
          return reqStatusFormatted
        case 'rejected':
          reqStatusFormatted = this.$t('requests.statusOptions.rejected')
          if (reqStatusFormatted === '') {
            return reqStatus
          }
          return reqStatusFormatted
      }
    },
    getWorkOrderStatus(workOrderStatus) {
      let workOrderStatusFormatted = ''
      switch (workOrderStatus) {
        case 'done':
          workOrderStatusFormatted = this.$t('workorders.statusOptions.done')
          if (workOrderStatusFormatted === '') {
            return workOrderStatus
          }
          return workOrderStatusFormatted
        case 'onHold':
          workOrderStatusFormatted = this.$t('workorders.statusOptions.onHold')
          if (workOrderStatusFormatted === '') {
            return workOrderStatus
          }
          return workOrderStatusFormatted
        case 'pending':
          workOrderStatusFormatted = this.$t('workorders.statusOptions.pending')
          if (workOrderStatusFormatted === '') {
            return workOrderStatus
          }
          return workOrderStatusFormatted
        case 'inProgress':
          workOrderStatusFormatted = this.$t('workorders.statusOptions.inProgress')
          if (workOrderStatusFormatted === '') {
            return workOrderStatus
          }
          return workOrderStatusFormatted
      }
    },
    getPriority(priorityId) {
      if (!priorityId) {
        return '';
      }
      return this.$t('workorders.priorities.' + priorityId);
    },
  },
}
</script>
