<template>
  <div class="w-[300px] relative" :class="{'cursor-pointer': onDragAction}">
    <div v-if="dashboard_editable" class="absolute right-0 top-0 bg-red-500 rounded-1 cursor-pointer hover:bg-red-600" @click="deleteElement">
      <app-icon icon="xmark_regular" :width="20" :height="20" extra-class="fill-white"></app-icon>
    </div>
    <div class="border-gray-100 border-[1px] rounded flex p-2 md:p-4 min-h-[100%] cursor-pointer" :class="{'border-dashed': dashboard_editable}" @click="goToView">
      <div class="flex items-center justify-center">
        <div class="rounded-full h-12 w-12 md:h-14 md:w-14 flex align-center justify-center" :style="`background-color:${item.color}1A; fill:${item.color}`">
          <app-icon :icon="item.icon" :width="32" :height="32"></app-icon>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex flex-col justify-center align-center pl-[1.5rem] font-nhu-500 text-black w-full">
          <div class="fs-14 lh-20">
            {{item.name}}
          </div>
          <div class="w-fit fs-30 lh-38">
            {{item.value}}
          </div>
        </div>
        <div class="flex justify-center h-full items-end pb-1.5">
          <div class="fill-primary-500 bg-primary-50 w-5 h-5 rounded-full flex justify-center align-center">
            <app-icon icon="chevron_right_regular" :width="16" :height="16"></app-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import {mapGetters} from "vuex";
export default {
  name: "DashboardElementCard",
  components: {AppIcon},
  props: {
    item: {
      type: Object,
      required: true,
    },
    onDragAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({dashboard_editable: "GET_DASHBOARD_EDITABLE", action: "GET_ACTION", filters: "GET_FILTERS"}),
  },
  methods: {
    async goToView(){
      if(this.dashboard_editable){
        await this.viewDashboardElement()
      } else {
        await this.goToRecord()
      }
    },
    async viewDashboardElement() {
      let formattedFilters = {}
      let parsedFilters = JSON.parse(this.item.filter)
      for (let key in parsedFilters) {
        formattedFilters[key] = {
          fieldId: key,
          values: parsedFilters[key]
        }
      }
      let filters = JSON.parse(localStorage.getItem('app-crud-filters'))
      filters.dashboard = formattedFilters
      this.$store.commit('SET_FILTERS', filters)
      this.$store.commit('SET_DASHBOARD_ELEMENT', this.item)
      await this.$router.push(`/dashboard/${this.item.id}/edit`)
    },
    deleteElement() {
      this.$store.dispatch('REMOVE_DASHBOARD_ELEMENT', this.item)
    },
    async goToRecord() {
      let itemFilters = JSON.parse(this.item.filter)
      let dashboardFilters = this.filters.dashboard
      let formattedFilters = {}
      for (let key in itemFilters) {
        formattedFilters[key] = {
          fieldId: key,
          values: itemFilters[key]
        }
      }
      for (let key in dashboardFilters) {
        formattedFilters[key] = {
          fieldId: key,
          values: formattedFilters[key]?.values ? formattedFilters[key].values.concat(dashboardFilters[key].values) : dashboardFilters[key].values
        }
      }
      if(this.item.recordId === 'workorders'){
        await localStorage.setItem('app-crud-WorkOrderListPage-view', 'list')
      }
      let filters = JSON.parse(localStorage.getItem('app-crud-filters'))
      filters[this.item.recordId] = formattedFilters
      this.$store.commit('SET_FILTERS', filters)
      await this.$router.push(this.item.recordId)
    }
  }
}
</script>

<style scoped>

</style>
