import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
  request: {}
}

const getters = {
  GET_REQUEST_WITHOUT_LOGIN: state => state.request
}

const mutations = {
  SET_REQUEST_WITHOUT_LOGIN(state, payload) {
    state.request = payload
  }
}

const actions = {
  async READ_LANDING(context, payload) {
    const response = await API.Get(`/api/public/landing/${payload.id}`)
    if (response.type === 'Success') {
      return response.res.data
    } else {
      return null;
    }
  },
  async SEND_REQUEST(context, payload) {
    const response = await API.Post(`/api/public/landing/${payload.id}`, payload)
    if (response.type === 'Success') {
      return response.res.data
    } else {
      return null;
    }
  },
  async READ_PUBLIC_REQUEST(context, payload) {
    const response = await API.Get(`/api/public/request/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST_WITHOUT_LOGIN', response.res.data)
      return response.res.data
    } else {
      return null;
    }
  },
  async SEND_PUBLIC_REQUEST(context, payload) {
    const response = await API.Post(`/api/public/request`, payload)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST_WITHOUT_LOGIN', response.res.data)
      context.commit('TOAST_MESSAGE', {
        type: 'success',
        title: i18n.global.t('requestLandings.createRequest.sentRequestTitle'),
        message: i18n.global.t('requestLandings.createRequest.sentRequestMessage') + response.res.data?.number,
      })
      return response.res.data
    } else {
      return null;
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
