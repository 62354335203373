import axios from 'axios'

export default class Images {
    static Upload(files) {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        return axios.post(process.env.VUE_APP_API_URL + 'api/images', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                return response.data;
            })
    }
}
