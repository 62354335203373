<template>
  <div class="flex flex-row items-stretch h-full">
    <div class="w-full sm:w-3/5 bg-white rounded p-4 shadow flex flex-col justify-between">
      <div class="bg-blue-50 overflow-auto h-[600px]">
        <div v-for="log in localIssue.issueLogs" :key="log.id" class="text-right pr-4 my-5">
          <div
              :class="log.userEmail === user.email && log.field === 'message' ? 'flex flex-col justify-end' : 'flex flex-col justify-start'">
            <div v-if="log.userEmail === user.email && log.field === 'message'">
              <span class="text-right justify-end font-bold">{{ $t('logs.you') }}</span>
              <div class="rounded-t-lg rounded-bl-lg bg-primary-light-purple inline-block p-3">
                {{ getStatus(log.field, log.value) }}
              </div>
            </div>
            <div v-else-if="log.userEmail !== user.email && log.field === 'message'" class="flex justify-items-start">
              <div class="flex-col inline-flex items-start">
                <span class="font-bold">{{
                    log.userEmail === user.email ? $t('logs.you') : log.userName
                  }}&nbsp;
                </span>
                <div
                    :class="log.userEmail !== user.email ? ' rounded-br-lg rounded-t-lg bg-primary-light-purple inline-block p-3' : ' rounded-bl-lg rounded-t-lg bg-primary-light-purple inline-block p-3'">
                  {{ getStatus(log.field, log.value) }}
                </div>
              </div>
            </div>
            <div v-else :class="log.userEmail !== user.email ? 'flex justify-items-start' : ''">
              <div class="inline-flex" :class="log.userEmail !== user.email ? 'flex items-start' : ''">
                <span>{{
                    log.userEmail === user.email ? $t('logs.you') : log.userName
                  }}&nbsp;{{ getStatus(log.field, log.value) }}
                </span>
              </div>
            </div>
            <div class="text-gray-400" :class="log.userEmail !== user.email ? 'flex justify-items-start' : ''">
              {{ formatDatetime(log.creationTime) }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center space-x-2 mt-5">
        <app-textbox v-model="message" :placeholder="'Agregar una actualizacion...'"
                     class="flex-grow"></app-textbox>
        <app-button @click="addMessageOnIssue(message)" class="flex-shrink-0">Enviar</app-button>
      </div>
    </div>
    <div class="w-full sm:w-2/5 bg-white rounded p-4 shadow">
      <input v-model="localIssue.title" @change="addNewIssue('title',localIssue.title)"
             class="my-3 p-1 font-bold bg-transparent focus:bg-white w-full" placeholder="Titulo"/>
      <input v-model="localIssue.description" @change="addNewIssue('description',localIssue.description)"
             class="my-3 p-1 bg-transparent focus:bg-white w-full text-gray-500"
             placeholder="Agrega una descripcion"/>
      <span>Estado</span>
      <app-dropdown v-model="localIssue.status" :options="statusData" value="id"
                    @change="addNewIssue('status',localIssue.status)"></app-dropdown>
      <span class="mt-5">Detalles</span>
      <app-dropdown v-model="localIssue.issueCategoryId" getter="GET_ISSUECATEGORIES"
                    url="api/issueCategories/issueCategoryList" value="id"
                    @change="addNewIssue('issueCategoryId',localIssue.issueCategoryId)"
                    class="my-3" :label="'Categoria'"></app-dropdown>
      <app-dropdown v-model="localIssue.siteId" getter="GET_SITES" url="api/sites/all" value="id"
                    @change="addNewIssue('siteId',localIssue.siteId)"
                    :label="'Sitio'"></app-dropdown>
      <app-dropdown v-model="localIssue.peopleId"
                    @change="addNewIssue('peopleId',localIssue.peopleId)"
                    getter="GET_PEOPLE" url="api/people/all" value="id" :label="'Asignado a '"
                    class="my-3"></app-dropdown>
      <app-dropdown v-model="localIssue.priority" :options="priority" value="id"
                    :label="'Prioridad'" @change="addNewIssue('priority',localIssue.priority)"></app-dropdown>
      <app-date-picker v-model="localIssue.dueDate" :label="'Fecha de vencimiento'" class="my-3"
                       @update:modelValue="addNewIssue('dueDate',localIssue.dueDate)"></app-date-picker>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditIssue",
  data() {
    return {
      message: '',
      localIssue: {},
      requiredById: ['siteId', 'peopleId', 'issueCategoryId'],
      statusData: [
        {id: 'opened', name: this.$t('admin.issues.status.opened')},
        {id: 'closed', name: this.$t('admin.issues.status.closed')}
      ],
      priority: [
        {id: 'low', name: this.$t('admin.issues.priorities.low')},
        {id: 'mid', name: this.$t('admin.issues.priorities.mid')},
        {id: 'high', name: this.$t('admin.issues.priorities.high')},
      ],
    }
  },
  watch: {
    localIssue: {
      handler() {
        if (this.localIssue.id) {
          this.doEdit()
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapGetters({issue: 'GET_ISSUE', user: 'GET_ACCOUNT'}),
  },
  methods: {
    ...mapActions(['READ_ISSUE', 'UPDATE_ISSUE', 'READ_ACCOUNT', 'READ_PEOPLE', 'READ_SITE', 'READ_ISSUECATEGORY']),
    async doEdit() {
      await this.UPDATE_ISSUE(this.localIssue);
    },
    async getById(key, value) {
      let response = ''
      let objResponse = {}
      switch (key) {
        case 'peopleId':
          objResponse = await this.READ_PEOPLE({id: value})
          response = `${objResponse.firstName} ${objResponse.lastName}`
          break;
        case 'issueCategoryId':
          objResponse = await this.READ_ISSUECATEGORY({id: value})
          response = objResponse.name
          break;
        case 'siteId':
          objResponse = await this.READ_SITE({id: value})
          response = objResponse.name
          break;
      }

      return response
    },
    async addNewIssue(key, value) {
      if (this.requiredById.find(elem => elem === key)) {
        value = await this.getById(key, value)
      }
      this.localIssue.issueLogs.push({
        type: 'message',
        userEmail: this.user.email,
        userName: this.user.name,
        value,
        creationTime: new Date().toISOString(),
        field: key,
        creationId: this.user.id,
        id: Math.random().toString(36).substring(2, 15),
      })
    },
    addMessageOnIssue() {
      this.localIssue.issueLogs.push({
        type: 'message',
        userEmail: this.user.email,
        userName: this.user.name,
        value: this.message,
        creationTime: new Date().toISOString(),
        field: 'message',
        creationId: this.user.id,
        id: Math.random().toString(36).substring(2, 15),
      })
      this.message = ''
    },
    formatDatetime(datetime) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      return new Date(datetime).toLocaleString('es-ES', options);
    },
    getStatus(logField, logValue) {
      if (logField === 'status') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') +
            this.$t('logs.' + logValue);
      } else if (logField === 'priority') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') +
            this.$t('logs.' + logValue);
      } else if (logField === 'issueCategoryId') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') + logValue
      } else if (logField === 'siteId') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') + logValue
      } else if (logField === 'peopleId') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') + logValue
      } else if (logField === 'dueDate') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') +
            this.formatDatetime(logValue)
      } else if (logField === 'title') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') + logValue
      } else if (logField === 'description') {
        return this.$t('logs.hasChangedTheValueOf') +
            this.$t('logs.' + logField) +
            this.$t('logs.to') + logValue
      } else return logValue
    }
  },
  async created() {
    await this.READ_ISSUE({id: this.$route.params.id})
    await this.READ_ACCOUNT()
    this.localIssue = this.issue
  }
}
</script>
