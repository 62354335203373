<template>
  <div class="w-full">
    <div class="flex flex-col w-full">
      <!-- Label without animation -->
      <div class="flex items-center">
        <component :is="iconLabel" v-if="iconLabel" class="w-5 h-5 mr-1"></component>
        <span v-if="label.length !== 0" class="font-nhu-regular fs-14 mb-4" :class="{ 'text-[#666]': disabled }">
          {{ label }}
        </span>
      </div>

      <button @click="changeValue" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors
        ease-in-out duration-200" :class="{
          'bg-primary-600': modelValue && !lock && !disabled,
          'bg-gray-200': !modelValue || lock || disabled,
          'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer': !lock && !disabled
        }">
        <span class="sr-only">Use setting</span>
        <span aria-hidden="true"
          :class="[modelValue ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
      </button>

    </div>
  </div>
</template>


<script>

export default {
  name: "AppCheckbox",
  components: {},
  props: {
    modelValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    iconLabel: { type: Function, default: null },
    label: { type: String, default: "" },
    lock: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    changeValue() {
      if (!this.lock && !this.disabled && !this.saving) {
        this.$emit("update:modelValue", !this.modelValue);
        this.$emit("change");
      }
    }
  }
};
</script>
