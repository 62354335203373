<template>
  <div class="w-[300px] min-h-full" :class="{'cursor-pointer': dashboard_editable}" @click="goToCreate">
    <div class="border-gray-100 border-[1px] rounded flex p-2 md:p-4 min-h-[100%] justify-center items-center flex-col">
      <div>
        {{$t('records.dashboard.new_element')}}
      </div>
      <div class="flex justify-center h-full items-end pb-1.5">
        <div class="fill-primary-500 bg-primary-50 w-8 h-8 rounded-full flex justify-center align-center">
          <app-icon icon="plus_regular" :width="20" :height="20"></app-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppIcon from "@/components/AppIcon";
export default {
  name: "NewDashboardElementCard",
  components: {AppIcon},
  computed: {
    ...mapGetters({dashboard_editable: "GET_DASHBOARD_EDITABLE"}),
  },
  methods: {
    async goToCreate() {
      await this.$store.dispatch("CREATE_DASHBOARD_ELEMENT")
      await this.$router.push("/dashboard/create");
    }
  }
}
</script>
