
<script>
  import AppCrudList from '@/crud/AppCrudList'

  export default {
    name: 'WorkOrderListPage',
    extends: AppCrudList,
  }
</script>

