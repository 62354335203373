<template>
  <div class="flex h-screen">
    <div class="xl:block hidden">
      <div class="login-image-filter w-full">
        <div class="pl-12 pt-[10%]">
          <img src="/images/zigo-white.png" class="w-[160px]">
          <div class="w-2/3 pt-4">
            <!--            <span :extra-class="'font-nhu-500 color-white login-text'">-->
            <!--              Lorem ipsum dolor sit amet, consectetur adipiscing elit.-->
            <!--            </span>-->
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center px-6 my-4 sm:w-[512px] mx-auto w-full h-[calc(100vh-50px)]">
      <div class="py-[2rem] block xl:hidden">
        <img class="w-[160px] mx-auto" src="/images/zigo.png" alt="zgio"/>
      </div>

      <div class="w-full h-full">
        <form class="flex flex-col h-full py-4" action="#" method="POST" @keyup.enter="login">




          <div class="xl:order-1 xl:h-[30%] order-3 h-full">
            <div class="flex w-full h-full xl:justify-end">
              <div class="flex h-6">
                <!--

                <span :extra-class="'font-nhu-regular mr-2'">
                  {{ $t('login.doesNotAccount') }}
                </span>
                <span href="#" @click="createShop"
                          :extra-class="'font-medium text-blue-500 font-nhu-regular hover:text-primary-700 cursor-pointer'">
                  {{ $t('login.register') }} </span>
                -->
              </div>
            </div>
          </div>


          <div class="xl:order-2 xl:h-auto order-1 h-[60%]">
            <input type="hidden" name="remember" value="true"/>
            <div class="rounded pt-0 xl:pt-10">
              <div class="mt-6">
                <span class="font-inter-regular text-[24px] xl:text-[32px]">
                  {{ $t('login.enter') }}
                </span>
              </div>
              <div>
                <app-textbox id="email-address"
                             v-model="email"
                             :label="$t('login.mailAddress')"
                             @input="validateEmail"
                             type="text"></app-textbox>
              </div>
              <div>
                <app-textbox id="password"
                             v-model="password"
                             :label="$t('login.password')"
                             :is-password="true"
                             type="password">
                </app-textbox>
              </div>
            </div>

            <div class="flex justify-start sm:justify-end order-3">
              <div class="text-sm">
                <a href="#" @click="forgotPassword"
                   class="font-medium text-blue-500 hover:text-primary-700 font-nhu-regular text-base">
                  {{ $t('login.forgotYouPass') }} </a>
              </div>
            </div>
            <div class="pt-2 w-full text-red-700" v-if="error_login">{{ error_login }}</div>

          </div>
          <div class="xl:h-auto order-2 h-[40%]">
            <div class="flex flex-col sm:pt-2 xl:pt-6 mt-6 w-full xl:w-[140px]">
              <app-button @click="login" :disabled="loading" :saving="saving">
                <span class="font-nhu-500 fs-14">
                  {{ $t('login.enter') }}
                </span>
              </app-button>
            </div>
          </div>
          <div class="order-2">
            <div class="flex items-center my-4">
              <div class="flex-grow h-0 border border-1 bg-gray-100"></div>
              <div class="mx-2">
                <span class="font-nhu-regular fs-14">{{ $t('login.ifNotAccount') }}</span>
              </div>
              <div class="flex-grow h-0 border border-1 bg-gray-100"></div>
            </div>
            <app-button @click="createRequest" :disabled="loading" :saving="saving" extra-class="bg-[#151143] w-full text-white" :clean="true">
                <span class="font-nhu-500 fs-14">
                  {{ $t('login.createRequest') }}
                </span>
            </app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
import store from "@/store";

export default {
  name: 'LoginPage',
  components: {},
  data() {
    return {
      loading: false,
      saving: false,
      email: '',
      password: '',
      validEmail: false,
    }
  },

  computed: {
    ...mapGetters({error_login: 'GET_ERROR_LOGIN'}),
  },

  methods: {
    ...mapActions(['LOGIN']),
    async login() {
      this.saving = true;
      await this.LOGIN({email: this.email, password: this.password, notRedirect: true });
      const assetID = this.$route.params.id;
      this.$router.push(`/assets/${assetID}`);
      this.saving = false;
    },
    createRequest() {
      const assetID = this.$route.params.id;
      this.$router.push(`/public-requests/create/${assetID}`);
    },
    async forgotPassword() {
      this.$router.push('/admin/forgot_password');
    },
    createShop() {
      this.$router.push('/admin/company/create');
    },
    validateEmail() {
      let validEmailTest = /^[^@]+@.+[^.]$/.test(this.email)
      if (validEmailTest) {
        this.validEmail = true
      } else this.validEmail = false
    }
  },
  mounted() {
    const user = store.state.auth.USER
    if (user) {
      const assetID = this.$route.params.id;
      this.$router.push(`/assets/${assetID}`);
    }
  }
}
</script>
<style lang="css">

.login-image-filter {
  flex-shrink: 0;
  background: linear-gradient(0deg, rgba(21, 17, 67, 0.50) 0%, rgba(21, 17, 67, 0.50) 100%), linear-gradient(180deg, #151143 0%, rgba(0, 0, 0, 0.00) 100%), url('/public/images/login-image.png'), no-repeat, lightgray 50%;
  background-size: cover;
  background-position: center;
  height: 100vh;
  aspect-ratio: 3/5;
}
</style>
