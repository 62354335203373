
<script>
import ParentControl from './ProcedureEditPage'

export default {
  name: 'ProcedureCreatePage',
  extends: ParentControl,
    async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>

