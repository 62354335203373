import store from '@/store'

function hasModule(name) {
    if(name === '') return true;
    if(!name) return false;

    let company = store.getters['GET_CURRENT_COMPANY']

    /*
    let menu = store.getters['GET_MAIN_MENU']
    //Si no se requiere el modulo, se muestra
    let isModule = menu.find(x => x.id === name)?.module
    if(!isModule)
        return true
    */

    if(company && company.modules)
        return company.modules[name] ?? false
    return false
}

function hasWorkOrderSetting(name) {
    if(name === '') return true;
    if(!name) return false;

    let company = store.getters['GET_CURRENT_COMPANY']
    if(company && company.workOrderSettings)
        return company.workOrderSettings[name] ?? false
    return false
}

export default Object.freeze({
    hasModule: (x) => hasModule(x),
    hasWorkOrderSetting: (x) => hasWorkOrderSetting(x)
})
