<template>
  <div class="p-2 min-h-screen max-w-full">
    <app-crud-filters record-id="workorders" :allow-null-value="false"></app-crud-filters>
    <div class="gap-2 pt-4 max-w-full flex flex-wrap">
      <draggable :list="dashboard.elements" :itemKey="item => item.id" group="elements" 
          class="flex gap-2 max-w-fit flex-wrap" 
          :class="{ 'cursor-move': dashboard_editable }" 
          :disabled="!dashboard_editable" >
        <template #item="{element: item}">
          <dashboard-element-card :item="item" />
        </template>
      </draggable>
      <div v-if="dashboard_editable" class="max-w-fit h-full">
        <new-dashboard-element-card></new-dashboard-element-card>
      </div>
    </div>
  </div>
</template>

<script>
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import {mapGetters} from "vuex";
import Draggable from "vuedraggable";
import DashboardElementCard from "@/pages/dashboard/DashboardElementCard";
import NewDashboardElementCard from "@/pages/dashboard/NewDashboardElementCard";
import AppCrudFilters from "@/crud/AppCrudFilters";
export default {
  name: "AppCrudDashboard",
  components: { AppCrudFilters, NewDashboardElementCard, DashboardElementCard, Draggable},
  computed: {
    ...mapGetters({dashboard: "GET_DASHBOARD", dashboard_editable: "GET_DASHBOARD_EDITABLE", records: 'GET_RECORDS', filters: 'GET_FILTERS'}),
    widgets() {
      return this.dashboard.elements;
    },
    options(){
      return Object.values(this.records).map(x => x.fields).flat().filter(x => x?.filterable)
    },
    dashboardFilter() {
      return this.filters.dashboard
    }
  },
  watch: {
    dashboardFilter: {
      handler: function (val) {
        if(!val)
          return
        let formattedFilters = {}
        Object.keys(val).forEach(key => {
          formattedFilters[key] = val[key].values
        })
        this.updateDashboard(JSON.stringify(formattedFilters))
      },
      deep: true,
      immediate: false
    }
  },
  data() {
    return {
      grid: null
    };
  },
  methods: {
    async updateDashboard(filters) {

      await this.$store.dispatch("READ_DASHBOARD", {filters: filters})
    },
    toggleEdit() {
      this.$store.commit("SET_DASHBOARD_EDITABLE", !this.dashboard_editable);
    },
  },
}
</script>
