<template>
  <div class="flex-col flex px-2 py-4 2xl:p-4" @click="viewOrder">
    <div class="flex justify-between">
      <span class="fs-12 font-nhu-regular text-gray-500">{{$t('WO')}}-{{workOrder.number}}</span>
      <app-priority :priority="workOrder.priority" />
    </div>
    <div class="flex justify-between pb-2">
      <span class="fs-14 font-nhu-regular text-gray-700 cursor-pointer text-ellipsis overflow-hidden">{{ workOrder.name }}</span>
    </div>
    <div class="flex justify-between fill-gray-700">
      <div class="flex justify-start">
        <div class="flex items-center">
          <div v-if="workOrder.dueDate" class="flex align-center gap-x-[2px] px-1 py-[2px] 2xl:gap-x-1 2xl:px-2 2xl:py-1 rounded" :class="{'bg-red-500 text-white fill-white': isExpired}">
            <app-icon icon="timer_regular" :width="12" :height="12"></app-icon>
            <span class="ml-1 font-nhu-regular fs-12">{{ formatDate(workOrder.dueDate) }}</span>
          </div>
        </div>
        <div class="flex items-center">
          <div v-if="workOrder?.comments?.length" class="flex align-center items-center gap-x-[2px] px-1 2xl:gap-x-1 2xl:px-2 py-[2px]">
            <app-icon icon="comment_regular" :width="12" :height="12"></app-icon>
            <span class="ml-1 font-nhu-regular fs-12">{{ workOrder?.comments?.length }}</span>
          </div>
        </div>
        <div class="flex items-center">
          <div v-if="workOrder?.checklist?.length" class="flex align-center gap-x-[2px] px-1 py-[2px] 2xl:gap-x-1 2xl:px-2 2xl:py-1 rounded " :class="{'bg-primary-500 text-white fill-white': workOrder?.checks?.length === workOrder?.checklist?.length}">
            <app-icon icon="square_check_regular" :width="12" :height="12"></app-icon>
            <span class="ml-1 font-nhu-regular fs-12">{{ workOrder?.checks?.length }}/{{workOrder?.checklist?.length}}</span>
          </div>
        </div>
        <div class="flex items-center">
          <div v-if="workOrder?.files?.length" class="flex align-center items-center gap-x-[2px] px-1 py-[2px] 2xl:gap-x-1 2xl:px-2 2xl:py-1 rounded">
            <app-icon icon="paperclip_regular" :width="12" :height="12"></app-icon>
            <span class="ml-1 font-nhu-regular fs-12">{{workOrder?.files?.length }}</span>
          </div>
        </div>
      </div>
      <div class="flex">
        <user-avatar v-for="user in assignedUsers" :tooltip-text="user.name" :key="user.id" :user="user" />
      </div>
    </div>
<!--    <app-icon :icon="$iconsByStatus.icon(workOrder.status)"
              :width="14"
              :height="12"
              :class="$iconsByStatus.class(workOrder.status)"
    >
      <span class="capitalize ml-1 fs-12 font-nhu-regular text-gray-900">
        {{ $t(workOrder.status) }}
      </span>
    </app-icon>-->
  </div>
</template>

<script>
import userAvatar from "@/components/UserAvatar";
import AppPriority from "@/components/AppPriority";
export default {
  name: "AppCrudWorkOrderKanban",
  components: {AppPriority, userAvatar},
  props: {
    workOrder: {
      type: Object,
      default: null,
    },
  },
  data() {
    return{
      userCreator: {},
      assignedUsers: []
    }
  },
  computed: {
    isExpired() {
      if(!this.workOrder.dueDate)
        return false
      const today = new Date();
      const dueDate = new Date(this.workOrder.dueDate);
      return dueDate < today
    }
  },
  methods: {
    formatDate(date) {
      if(!date)
        return ""
      const myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.toLocaleString('default', { month: 'short' });
      return `${day} ${month}`;
    },
    viewOrder() {
      this.$router.push('/workorders/' + this.workOrder.id)
    },
    getCreatorUser() {
      if (this.workOrder.creationId) {
        this.userCreator = this.$store.getters['GET_USER_BY_ID'](this.workOrder.creationId)
      }
      return ""
    },
    getAssignementUsers() {
      if (this.workOrder.assignedTo) {
        this.assignedUsers = this.$store.getters['GET_USERS_BY_IDS'](this.workOrder.assignedTo)
      }
      return ""
    }
  },
  mounted() {
    this.getCreatorUser()
    this.getAssignementUsers()
  }
}
</script>

<style scoped>

</style>
