<template>
  <app-loading :loading="loading" class="w-full"/>
  <div class="h-screen">
    <img class="h-6 my-4 px-3" :src="'/images/zigoDark.png'" alt="zigo"/>
    <div class="flex justify-center items-center w-screen overflow-auto">
      <div :class="isMobile ? 'p-6 h-full' : 'border border-gray-100 rounded-[8px] p-6 max-w-[700px]'" class="w-full flex-col items-center justify-center">
        <div class="mb-4">
          <span class="fs-18 font-nhu-bold">{{asset.name}}</span>
        </div>
        <div class="mb-4">
          <span class="fs-14 font-nhu-regular text-gray-400">{{$t('requests.request') + '#' + request.number}}</span>
        </div>
        <app-label :label="$t('requests.applicantsName')"
                   :value="request.name"/>
        <app-label :label="$t('requests.email')"
                   :value="request.email"/>
        <app-label :label="$t('requests.phone')"
                   :value="request.phone"/>
        <app-label :label="$t('requests.description')"
                   :value="request.description"/>
        <app-label :label="$t('requests.isWorkingAsset')"
                   :value="getStatus(request.isWorking)"/>
        <template v-if="request.images?.length">
          <app-label :label="$t('requests.attachedImages')"
                     :value="request.images"
                     :images="true"/>
        </template>
        <app-label :label="$t('requests.priority')"
                   :value="getPriority(request.priority)"/>
        <div class="w-full pb-4">
          <app-button @click="doCreate" type="primary" background="light" extra-class="w-full">
            <span class="fs-14 font-nhu-regular">{{ $t('requests.createRequest') }}</span>
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RequestView",
  components: { },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({request: 'GET_REQUEST_WITHOUT_LOGIN', asset: 'GET_ASSET'}),
    isMobile() {
      return this.$isMobile()
    },
  },
  methods: {
    ...mapActions(['READ_PUBLIC_REQUEST']),
    async doLoad() {
      this.loading = true;
      await this.READ_PUBLIC_REQUEST({id: this.$route.params.id})
      await this.$store.dispatch('READ_PUBLIC_ASSET', {id: this.request.assetId})
      this.loading = false;
    },
    getStatus(isWorking) {
      return isWorking ? this.$t('yes') : this.$t('no')
    },
    getPriority(priority) {
      return this.$t('options.priorities.' + priority)
    },
    doCreate() {
      this.$router.push(`/public-requests/create/${this.asset.id}`)
    }
  },
  async mounted() {
    await this.doLoad()
  }
}
</script>

<style scoped>

</style>
