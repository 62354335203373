<template>
  <div class="rounded-md h-[54px] px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-500 focus-within:ring-2 focus-within:ring-black"
  :class="[ invalid ? 'ring-red-500' : 'ring-gray-500', invalid && hasValue ? 'bg-red-100' : '', disabled ? 'bg-gray-50' : '']">
    <label ref="label" :for="id" class="absolute block font-medium text-gray-500 cursor-text" :class="[ active || hasValue ? 'text-xs' : 'mt-2']" @click="labelClick"> {{ label }}</label>
    <app-dropdown :id="id" ref="input" :options="options" value-key="id" v-model:selected="selected"  class="block mt-4 w-full border-0 !p-0 text-black !bg-transparent focus:ring-0 sm:text-sm sm:leading-6"></app-dropdown>

  </div>
  <div class=""> {{ error }}</div>
</template>

<script>

export default {
  name: "AppInput",
  components: { },
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Number, String], default: "" },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    error: { type: String, default: "" },
  },
  emits: ["update:modelValue", 'keydown'],
  data() {
    return {
      active: false
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    options: function (newVal, oldVal) {
      if (this.modelValue == null && this.options && this.options.length > 0)
        this.$emit("update:modelValue", this.options[0].id);
    }
  },  
  computed: {
    hasValue(){
      return this.modelValue != ''
    },
    getOptionName() {
      if (this.options === null)
        return this.modelValue;
      return this.options.find(x => x.id === this.modelValue)?.name;
    },
    selected: {
      get() {
        if (this.modelValue === undefined) {
          return ""
        }
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    }
  },
  methods: {
    labelClick() {
      this.$refs.input.focus();
    },
    onChange(e){
      this.$emit('update:modelValue', e.target.value)
    },
    focusin() {
      this.active = true;
    },
    focusout() {
      this.active = false;
    },
    isSelected(item) {
      if (item === null || item === undefined)
        return false;
      return item.id === this.modelValue;
    },
    changeValue(e) {
      this.$emit("update:modelValue", e.target.value);
    }
  },
  created() {
    if (this.modelValue == null && this.options && this.options.length > 0){
      this.$emit("update:modelValue", this.options[0].id);
    }

  }
};
</script>
