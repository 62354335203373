<template>
  <app-loading :loading="loading" />
  <div v-if="company">
    <app-option-card :label="$t('options.generalSettings.generalInformation')" :description="$t('options.generalSettings.updateCompanyInfo')">
      <app-form-field id="name" :label="$t('options.generalSettings.companyName')" :icon="Bars4Icon" :description="$t('options.generalSettings.companyNameDesc')">
        <app-textbox id="name" v-model="company.name" :placeholder="$t('options.generalSettings.myCompany')"/>
      </app-form-field>

      <!--
      <app-form-field id="address" :label="$t('options.generalSettings.direction')" :icon="HomeIcon" :description="$t('options.generalSettings.directionDesc')" v-model="company.has_address">
          <app-address id="address" v-model="company.address" placeholder="" />
      </app-form-field>
      -->

      <app-form-field id="link" :label="$t('options.generalSettings.link')" :icon="LinkIcon" :description="$t('options.generalSettings.linkDesc')">
        <div class="flex max-w-lg mb-2 items-center">
          <span @click="openCompany" class="inline-flex items-center">
            <ArrowUpRightIcon class="flex-shrink-0 inline w-6 h-6 mr-3 text-gray-400 cursor-pointer group-hover:text-gray-500" />
          </span>
          <span class="font-inter-regular text-[14px]"> https:// </span>
          <app-textbox id="slug" v-model="company.slug" placeholder="" :has-validation="true" :validated="!value" :msg="message" @keydown="validateSlug"/>
          <span class="font-inter-regular text-[14px]"> .zigo.la </span>
        </div>
      </app-form-field>

      <!--
      <app-form-field id="timezone" :label="$t('options.generalSettings.timeZone')" :icon="ClockIcon" description="">
        <app-dropdown :options="timezoneOptions" v-model:selected="company.timezone" value-key="id"/>
      </app-form-field>
      -->
        <app-form-field id="language" :label="$t('options.generalSettings.Language')" :icon="AcademicCapIcon" description="">
            <app-dropdown v-model="company.languaje" value="id" :options="languages" value-key="id" @change="changeLanguage"/>
        </app-form-field>

    </app-option-card>

  </div>
  <app-action-bar :loading="saving">
    <AppButton :disabled="btnDisable" @click="save" :loading="saving" type="primary" background="light">
      {{ $t('save') }}
    </AppButton>
    <AppButton @click="cancel" type="secondary" background="light">
      {{ $t('cancel') }}
    </AppButton>
  </app-action-bar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import currencies from '@/services/currencies'
import {Bars4Icon, HomeIcon, LinkIcon, ClockIcon, CurrencyDollarIcon, CalendarIcon, GiftIcon, DocumentTextIcon,
  EyeSlashIcon, LanguageIcon, PhoneArrowUpRightIcon, ArrowUpRightIcon, AcademicCapIcon } from '@heroicons/vue/24/outline'
import AppTextbox from "@/components/AppTextbox";


export default {
  name: 'OptionGeneralPage',
  components: {AppTextbox, ArrowUpRightIcon},
  data() {
    return {
      ...{
        Bars4Icon, HomeIcon, LinkIcon, ClockIcon, CurrencyDollarIcon, CalendarIcon, GiftIcon, DocumentTextIcon,
        EyeSlashIcon, LanguageIcon, PhoneArrowUpRightIcon, AcademicCapIcon
      },
      loading: false,
      saving: false,
      timezoneOptions: [],
      currencies: currencies,
      appUrl: process.env.VUE_APP_URL,
      value: false,
      message: "",
      checkSlug: true,
      languages: [
        { id:'es', name: 'Español'},
        { id:'en', name: 'English'},
        { id:'pt', name: 'Português'},
      ]
    }
  },
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY'}),
    btnDisable() {
      if (this.company?.slug === undefined) {
        return false
      }
      return  this.value || this.company?.slug?.length === 0
    }
  },
  methods: {
    ...mapActions(['UPDATE_COMPANY', 'GET_TIMEZONES', 'VALIDATE_SLUG', 'FIRE_MESSAGE']),

    openCompany() {
      if (process.env.VUE_APP_ENV_LABEL === 'DEV')
        window.open(process.env.VUE_APP_URL + this.company.slug, '_blank').focus();
      else if (process.env.VUE_APP_ENV_LABEL === 'STAGING')
        window.open('https://' + this.company.slug + '.stage.zigo.la/', '_blank').focus();
      else
        window.open('https://' + this.company.slug + '.zigo.la/', '_blank').focus();
    },

    async validateSlug() {
       await this.VALIDATE_SLUG({slug: this.company.slug})
            .then((response) => {
              this.value = response
              return response;
            })
        if (this.value) {
          this.message = "El nombre debe contener al menos 5 caracteres"
        } else {
          this.message = ""
        }
    },

    changeLanguage() {
        this.$i18n.locale = this.company.languaje;
    },

    async save() {
      this.saving = true;
      await this.UPDATE_COMPANY({
        id: this.company.id,
        name: this.company.name,
        has_address: this.company.has_address,
        address: this.company.address,
        slug: this.company.slug,
        timezone: this.company.timezone,
        currency: this.company.currency,
        has_dates: this.company.has_dates,
        has_gift_recipient: this.company.has_gift_recipient,
        has_gift_message: this.company.has_gift_message,
        gift_message: this.company.gift_message,
        has_additional_instruction: this.company.has_additional_instruction,
        additional_instruction: this.company.additional_instruction,
        has_private_mode: this.company.has_private_mode,
        password: this.company.password,
        has_languaje_selector: this.company.has_languaje_selector,
        has_whatsapp_number: this.company.has_whatsapp_number,
        whatsapp_number: this.company.whatsapp_number,
        languaje: this.company.languaje
      })
      this.saving = false
    },
    cancel() {
      this.$router.push('/admin/');
    }
  },
  async created() {
    this.loading = true;
    this.company = Object.assign({}, );
    this.timezoneOptions = await this.GET_TIMEZONES();
    this.loading = false;
  },
}
</script>
