
<script>
  import ParentControl from './SiteEditPage'

  export default {
    name: 'SiteCreatePage',
    extends: ParentControl,
    async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
  }
</script>

