<template>
  <button class="p-1 mr-2 text-blue-900 border border-blue-700 rounded cursor-pointer hover:bg-blue-50" 
      @click="$emit('click')" >
      {{ label }} 
  </button>
</template>

<script>

export default {
  name: "AppButton2",
  emits: ['click'],
  components: {},

  props: {
    label: { type: String, default: '' },
  },
  computed: {
  },
  methods: {
  }
};
</script>
