<template>
  <div class="h-full pt-[41px]" v-if="company !== null">
    <div class="flex border-b mb-8">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="cursor-pointer" @click="goBack"> <ChevronDoubleLeftIcon class="w-8 h-8" /> </span>
        <span class="hidden sm:block">{{ $t('options.features.workorder_settings.title') }}</span>
      </h1>
    </div>
    <div class="max-w-4xl">
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div v-if="$hasModule('parts')" class="flex-col flex pt-4">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="window_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                {{ $t('options.features.workorder_settings.cost_tracking_required') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox :modelValue="company.workOrderSettings.costTrackingRequired"
                @change="toggleSetting('costTrackingRequired')" :saving="saving" />
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{
              $t('options.features.workorder_settings.cost_tracking_required_description') }}
            </span>
          </div>
        </div>

        <div class="flex-col flex pt-4">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="window_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                {{ $t('options.features.workorder_settings.time_tracking_enabled') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox :modelValue="company.workOrderSettings.timeTrackingEnabled"
                @change="toggleSetting('timeTrackingEnabled')" :saving="saving" />
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{
              $t('options.features.workorder_settings.time_tracking_description') }}
            </span>
          </div>
        </div>

        <div v-if="company.workOrderSettings.timeTrackingEnabled" class="flex-col flex pt-4">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="window_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                {{ $t('options.features.workorder_settings.time_tracking_required') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox :modelValue="company.workOrderSettings.timeTrackingRequired"
                @change="toggleSetting('timeTrackingRequired')" :saving="saving" />
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{
              $t('options.features.workorder_settings.time_tracking_required_description') }}
            </span>
          </div>
        </div>
       
      </div>

      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="window_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                {{ $t('features.workorder_settings.sendEmails') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox :modelValue="company.workOrderSettings.sendNotifications"
                @change="toggleSetting('sendNotifications')" :saving="saving" />
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.workorder_settings.send_notifications_description') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { ChevronDoubleLeftIcon } from "@heroicons/vue/24/outline";
import AppIcon from "@/components/AppIcon";

export default {
  name: 'OptionWorkOrderSettings',
  components: {AppIcon, ChevronDoubleLeftIcon },
  data() {
    return {
      loading: false,
      saving: false,
    }
  },
  computed: {
    ...mapGetters({ user: 'GET_CURRENT_USER', company: 'GET_CURRENT_COMPANY' })
  },
  methods: {
    ...mapActions(['UPDATE_CURRENT_COMPANY']),
    goBack() {
      this.$router.push('/admin/features');
    },

    async toggleSetting(setting) {
      this.saving = true;
      const workOrderSettings = {...this.company.workOrderSettings}
      workOrderSettings[setting] = !workOrderSettings[setting]
      await this.UPDATE_CURRENT_COMPANY({id: this.company.id,  workOrderSettings: workOrderSettings } )
      this.saving = false;
    }
  },
}
</script>
<style></style>
