import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
    ASSET: {
        name: '',
        fullName: '',
        typeId: '',
        brand: '',
        model: '',
        serialNumber: '',
        acquirementDate: '',
        siteId: '',
        parentId: '',
        description: '',
        qrCode: null,
        logs: [],
        childs: [],
        props: {}
    },
    ASSETS: {data: []},
    ALL_ASSETS: []
}

const getters = {
    GET_ASSET: state => state.ASSET,
    GET_ASSETS: state => state.ASSETS,
    GET_ASSET_BY_ID: state => id => state.ALL_ASSETS.find(e => e.id === id),
    GET_ALL_ASSETS: state => state.ALL_ASSETS,
    GET_PUBLIC_ASSET: state => state.ASSET,
    GET_ALL_ASSETS_BY_COMPANY_ID: state => state.ALL_ASSETS,
}

const mutations = {
    // ASSETS
    SET_ASSET(state, payload) {
        state.ASSET = payload
    },
    SET_ALL_ASSETS(state, payload) {
        state.ALL_ASSETS = payload
    },
    SET_ASSETS(state, payload) {
        state.ASSETS = payload
    },
    CREATE_ASSET(state, payload) {
        state.ASSET = payload
        state.ASSETS.data.push(payload)
    },
    UPDATE_ASSET(state, payload) {
        if (state.ASSET !== null && state.ASSET.id === payload.id)
            state.ASSET = payload;

        const index = state.ASSETS.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.ASSETS.data[index], payload)
    },
    DELETE_ASSET(state, payload) {
        if (state.ASSET?.id === payload.id)
            state.ASSET = {}
        state.ASSETS.data = state.ASSETS.data.filter(e => e.id !== payload.id)
        state.ALL_ASSETS = state.ALL_ASSETS.filter(e => e.id !== payload.id)
    },
    SET_PUBLIC_ASSET(state, payload) {
        state.ASSET = payload
    }
}

const actions = {
    async READ_ASSETS(context, payload) {
        const response = await API.Get('/api/assets', payload)
        if (response.type === 'Success') {
            context.commit('SET_ASSETS', response.res.data)
            return response.res.data
        }
        context.commit('SET_ASSETS', [])
    },
    async READ_ALL_ASSETS(context) {
        const response = await API.Get('/api/options/assets')
        if (response.type === 'Success') {
            context.commit('SET_ALL_ASSETS', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_ASSETS', [])
    },
    async READ_ALL_ASSETS_BY_COMPANY_ID(context, payload) {
        const response = await API.Get(`/api/public/assets/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_ALL_ASSETS', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_ASSETS', [])
    },
    async CREATE_ASSET(context) {
        context.commit('SET_ASSET', {name: '', typeId: '', siteId: '', props: {}})
    },

    async SAVE_ASSET(context, payload) {
        if (payload.id === undefined) {
            const response = await API.Post('/api/assets', payload)
            if (response.type === 'Success') {
                context.dispatch('READ_ALL_ASSETS')
                context.commit('SET_ASSET', response.res.data)
                return response.res.data;
            }
        } else {
            const response = await API.Put(`/api/assets/${payload.id}`, payload)
            if (response.type === 'Success') {
                context.dispatch('READ_ALL_ASSETS')
                context.commit('UPDATE_ASSET', response.res.data)
                return response.res.data;
            }
        }
    },
    async READ_ASSET(context, payload) {
        const response = await API.Get(`/api/assets/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_ASSET', response.res.data)
            return response.res.data
        }
    },
    async READ_PUBLIC_ASSET(context, payload) {
        const response = await API.Get(`/api/public/asset/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_PUBLIC_ASSET', response.res.data)
            return response.res.data
        }
    },
    async UPDATE_ASSET(context, payload) {
        const response = await API.Put(`/api/assets/${payload.id}`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_ASSET', response.res.data)
            return response.res.data
        }
    },
    async DELETE_ASSET(context, payload) {
        const response = await API.Delete(`/api/assets/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('DELETE_ASSET', response.res.data)
            context.commit('TOAST_MESSAGE', {
                type: 'success',
                title: i18n.global.t('assets.assetDelete.title'),
                message: i18n.global.t('assets.assetDelete.message')
            })
            return response.res.status
        }
    },
    async DELETE_ASSET_LIST(context, payload) {
        const response = await API.Patch('/api/assets', payload);

        if (response.type === 'Success') {
            context.commit('DELETE_ASSET', {payload});
            return true;
        }
        return false;
    },
    async EXPORT_ASSET_XLSX() {
        const response = await API.Post(`/api/assets/export-XLSX`)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async IMPORT_ASSETS(context, payload) {
        const response = await API.Post('/api/assets/import', payload)
        if (response.type === 'Success') {
            if(state.ASSETS){
                context.commit('SET_ASSETS', {data: [...state.ASSETS.data, ...response.res.data]})
            }else{
                context.commit('SET_ASSETS', response.res.data)
            }
            return response.res.data
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
