import moment from 'moment'

const formatDate = function formatDate(dbDate) {
    dbDate = new moment(dbDate);
    var currentDate = new moment();
    if (currentDate.isSame(dbDate, 'day'))
        return dbDate.format('h:mm a');
    else if (currentDate.isSame(dbDate, 'year'))
        return dbDate.format("MMM DD");
    else
        return dbDate.format("YYYY-MM-DD");
}

const formatNumber = function formatNumber(number, decimals = 2) {
    const locale = navigator.language.substring(0, 2);
    
    number = Number(number);

    return number?.toLocaleString(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

export { formatDate, formatNumber };
 