<template>
  <div>
    <div class="border-b">
      <a href="#"
         class="tab__link p-4 block fs-12 bg-blue-dark hover:bg-blue-darker no-underline text-white border-b-2 border-white flex justify-between"
         @click.prevent="active = !active">
<!--        <strong>{{ title }}</strong>-->
        <h1 class="text-2xl font-nhu-500 lh-38 fs-16 text-gray-900 overflow-ellipsis flex items-center">
<!--          <span class="cursor-pointer" @click="goBack"> -->
<!--            <ChevronDoubleLeftIcon class="w-8 h-8" />-->
<!--          </span>-->
          <span class="hidden sm:block">{{ title }} </span>
        </h1>
        <span class="down-Arrow" v-show="!active">&#9660;</span>
        <span class="up-Arrow" v-show="active">&#9650;</span>
      </a>
    </div>
    <div class="tab__content p-2" v-show="active">
      <slot/>
    </div>
  </div>
</template>

<script>
// import {ChevronDoubleLeftIcon} from "@heroicons/vue/24/outline";

export default {
  name: 'AppAccordion',
  components: {},
  props: {
    title: String
  },
  data() {
    return {
      active: false,
    }
  },
}
</script>
