<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div v-if="errors.length" aria-live="assertive"
       class="fixed inset-0 mt-24 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">

    <div class="w-full flex flex-col items-center space-y-2 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition v-for="error in errors" :key="error.id"
                  enter-active-class="transform ease-out duration-300 transition"
                  enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <div
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true"/>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900"> {{ error.message }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ error.description }}</p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button type="button" @click="deleteError(error)"
                        class="bg-white rounded inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div v-if="toasts.length" aria-live="assertive"
       class="fixed inset-0 z-50 mt-24 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">

    <div class="w-full flex flex-col items-center space-y-2 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition v-for="toast in toasts" :key="toast.id"
                  enter-active-class="transform ease-out duration-300 transition"
                  enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <div
            class="max-w-sm w-full rounded-lg border shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50"
            :class="borderColor(toast.type) + ' ' + bgColor(toast.type)">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 " :class="textColor(toast.type)" aria-hidden="false"/>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-nhu-semi-bold text-sm" :class="textColor(toast.type)"> {{ toast.title }}</p>
                <p class="font-nhu-regular mt-1 text-sm " :class="textColor(toast.type)">{{ toast.message }}</p>
              </div>
              <div class="flex-shrink-0">
                <button type="button" @click="deleteToast(toast)"
                        class="rounded-md inline-flex border-primary-500 hover:text-red-300"
                        :class="textColor(toast.type)">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div>
    <div v-if="document?.visible" aria-live="assertive"
         class="fixed bottom-0 right-0 w-full flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div class="w-full flex flex-col items-center space-y-2 sm:items-end">
        <transition
                    enter-active-class="transform ease-out duration-300 transition"
                    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                    leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
          <div class="max-w-sm w-full rounded-lg border shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50">
            <div class="p-4 bg-white">
              <div class="flex items-start">
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <div class="flex justify-between">
                    <p v-if="document.loading" class="font-nhu-semi-bold fs-14 text-gray-800">
                     {{$t('we_are_processing_your_request')}}
                    </p>
                    <p v-else class="font-nhu-semi-bold fs-14 text-gray-800">
                      {{$t('document_generated')}}
                    </p>
                    <div class="flex-shrink-0">
                      <button type="button" @click="closeFileDialog" class="rounded-md inline-flex border-primary-500 hover:text-red-300">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6"/>
                      </button>
                    </div>
                  </div>
                  <div class="flex justify-between py-2 font-nhu-regular" :class="document.loading ? 'fill-gray-500 pointer-events-none text-gray-500' : 'fill-primary-500 cursor-pointer text-primary-500'" @click="downloadDocument">
                    <div class="flex">
                      <app-icon icon="file_spreadsheet_regular" :height="16">
                        <p class="text-sm ml-2"> {{ document.fileName }}</p>
                      </app-icon>
                    </div>
                    <app-icon icon="cloud_arrow_down_regular" :height="16"/>
                  </div>
                  <div>
                    <app-loading :loading="document.loading" bg-color="'blue-500'"></app-loading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="visibility" aria-live="assertive"
         class="fixed bottom-0 right-0 w-full flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div class="w-full flex flex-col items-center space-y-2 sm:items-end">
        <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
          <div class="max-w-sm w-full rounded-lg border shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50">
            <div class="p-4 bg-white">
              <div class="flex items-start">
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <div class="flex justify-between">
                    <p v-if="status !== 'finished'" class="font-nhu-semi-bold fs-14 text-gray-800">
                      {{$t('we_are_importing_your_data')}}
                    </p>
                    <p v-else class="font-nhu-semi-bold fs-14 text-gray-800">
                      {{$t('your_data_has_been_imported')}}
                    </p>
                    <div class="flex-shrink-0">
                      <button type="button" @click="closeImportDialog" class="rounded-md inline-flex border-primary-500 hover:text-red-300">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6"/>
                      </button>
                    </div>
                  </div>
                  <div>
                    <app-loading :loading="status !== 'finished'" bg-color="'blue-500'"></app-loading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {XCircleIcon, CheckCircleIcon} from '@heroicons/vue/24/solid'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import AppIcon from "@/components/AppIcon";
import AppLoading from "@/core/TheLoading";

export default {
  name: 'TheMessage',
  components: {AppLoading, AppIcon, XCircleIcon, CheckCircleIcon, XMarkIcon},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({errors: 'GET_ERRORS', toasts: 'GET_TOAST', document: 'GET_EXPORTED_DOCUMENT', status: 'GET_IMPORT_STATUS', visibility: 'GET_IMPORT_VISIBLE'}),
  },
  methods: {
    XCircleIcon,
    ...mapActions(['REMOVE_ERROR', 'REMOVE_TOAST']),
    closeFileDialog() {
      this.$store.commit('SET_CLOSE_VIEW_DOCUMENT')
    },
    closeImportDialog() {
      this.$store.commit('SET_IMPORT_VISIBLE', false)
    },
    deleteError(error) {
      this.REMOVE_ERROR(error);
    },
    deleteToast(toast) {
      this.REMOVE_TOAST(toast);
    },
    downloadDocument() {
      if(!this.document.url)
        alert(this.$t('thereWasAnError'));
      let fullUrl = process.env.VUE_APP_FILE_URL + this.document.url;
      window.open(fullUrl, '_blank');
    },
    bgColor(type) {
      switch (type) {
        case "success":
          return "bg-green-50"
        case "error":
          return "bg-red-50"
        case "warning":
          return "bg-amber-50"
        case "info":
          return "bg-blue-50"
        case "danger":
          return "bg-red-50"
        case "default":
          return "bg-gray-50"
        default:
          return "bg-white-50"
      }
    },
    borderColor(type) {
      switch (type) {
        case "success":
          return "border-green-600"
        case "error":
          return "border-red-600"
        case "warning":
          return "border-amber-600"
        case "info":
          return "border-blue-600"
        case "danger":
          return "border-red-600"
        case "default":
          return "border-gray-600"
        default:
          return "border-black-600"
      }
    },
    textColor(type) {
      switch (type) {
        case "success":
          return "text-green-600"
        case "error":
          return "text-red-600"
        case "warning":
          return "text-amber-600"
        case "info":
          return "text-blue-600"
        case "danger":
          return "text-red-600"
        case "default":
          return "text-gray-600"
        default:
          return "text-black-600"
      }
    }
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'TOAST_MESSAGE') {
        setTimeout(() => { 
          this.$store.commit('SET_REMOVE_ALL_TOASTS')
        }, 5000)
      }
    })
  }
}
</script>
