import Vuex from 'vuex'

// Modules Core
import app from './modules/app'
import auth from './modules/auth'
import records from './modules/data/Records'
// Modules Page
import company from './modules/data/Company'
import account from './modules/data/Account'
import workorders from "@/store/modules/data/Workorders";
import filters from "@/store/modules/data/Filters";
import documents from "@/store/modules/data/Documents";
import importData from "@/store/modules/data/ImportData";
import kanban from "@/store/modules/data/Kanban";
import metrics from "@/store/modules/data/Metrics";
import props from "@/store/modules/data/Props";

import companies from './modules/data/Companies'
import categories from './modules/data/Categories'
import groups from './modules/data/Groups'
import options from './modules/data/Options'
import integrations from './modules/data/Integrations'
import users from './modules/data/Users'
import reports from './modules/data/Reports'

//import records from './modules/data/Records'
//import savedsearchs from './modules/data/SavedSearchs'
import sites from "@/store/modules/data/Sites";
import assets from "@/store/modules/data/Assets";
import types from "@/store/modules/data/Types";
import templates from "@/store/modules/data/Templates";
import procedures from "@/store/modules/data/Procedures";
import optionsets from "@/store/modules/data/OptionSets";
import calendar from "@/store/modules/data/Calendar";
import dashboard from "@/store/modules/data/Dashboard";

import inspections from "@/store/modules/data/Inspections";
import actions from "@/store/modules/data/Actions";
import tags from "@/store/modules/data/Tags";
import issues from "@/store/modules/data/Issues";
import issueCategories from "@/store/modules/data/IssueCategories";
import conversations from "@/store/modules/data/Conversations";
import messages from "@/store/modules/data/Messages";
import viewModes from "@/store/modules/data/ViewModes";
import requests from "@/store/modules/data/Requests";
import requestLandings from "@/store/modules/data/RequestLandings";
import landing from "@/store/modules/data/Landing";
import teams from "@/store/modules/data/Teams";
import parts from "@/store/modules/data/Parts";
import roles from "@/store/modules/data/Roles";
import menu from "@/store/modules/app/Menu";
import columns from "@/store/modules/data/Columns";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    app,
    records,
    company,
    companies,
    workorders,
    metrics,
    props,
    calendar,
    dashboard,
    filters,
    documents,
    importData,
    kanban,
    account,
    categories,
    groups,
    users,
    sites,
    templates,
    procedures,
    inspections,
    assets,
    conversations,
    messages,
    requests,
    requestLandings,
    landing,
    actions,
    types,
    issues,
    issueCategories,
    tags,
    options,
    integrations,
    reports,
    optionsets,
    viewModes,
    teams,
    parts,
    roles,
    menu,
    columns
  }
})
