<template>
  <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('records.parts.fields.name')" :error="errorMsg" @update:model-value="clearErrorMsg"/>
    <app-image-list v-model="item.images" :rounded="false" :only-one-image="true" />
    <app-textbox v-model="item.code" :label="$t('records.parts.fields.code')"/>
    <app-textbox v-model="item.serialNumber" :label="$t('records.parts.fields.serialNumber')"/>
    <app-textarea v-model="item.description" :label="$t('records.parts.fields.description')" />
    <app-dropdown v-model="item.assets" value="id"
                  :multiple="true"
                  :label="$getRecordLabel('assets') ? $getRecordLabel('assets') : $t('records.parts.fields.assets')"
                  getter="GET_ALL_ASSETS"
                  :allow-nulls="true"></app-dropdown>
    <app-textbox v-model="item.unitCost" type="number" :label="$t('records.parts.fields.unitCost')"/>
    <app-textbox v-model="item.stock" type="number" :label="$t('records.parts.fields.quantity')"/>
    <!--
    <app-textbox v-model="item.minStock" type="number" :label="$t('records.parts.fields.minStock')"/>
    -->
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PartEditPage",
  components: { },
  computed: {
    ...mapGetters({record: 'GET_RECORD',}),
  },
  data() {
    return {
      errorMsg: '',
      validated: false,
    }
  },
  methods: {
    onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.errorMsg = this.$t('parts.errors.name');
        onError();
      }
      else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
