<template>
  <div>
    <div
            v-if="allowUploadFile"
            class="relative w-full my-4"
            v-show="showNewButton && cantFile && !disabled"
            @drop.prevent="fileDrop"
            @dragover.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
            :class="{ 'border-blue-500 bg-blue-100': dragover, 'border-primary-500 bg-gray-50': !dragover }"
    >
        <div @click="!disabled && $refs.file.click()"
             :class="{ 'rounded-full': rounded, 'rounded': !rounded }"
             class="flex items-center justify-center overflow-hidden bg-gray-25 border-primary-500 border-box cursor-pointer group w-full h-38 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:brightness-95">
            <div class="flex flex-col items-center fill-primary-500 p-6">
                <app-icon v-if="!disabled" extra-class="mb-1" icon="file_circle_plus_regular" :width="24"
                          :height="24"/>
                <div>
                    <span class="text-gray-900 font-nhu-regular fs-14">{{ $t('addFiles') }}</span>
                    <span class="text-primary-500 font-nhu-regular fs-14">{{ $t('lookForThem') }}</span>
                </div>
                <div class="my-2 flex flex-col fs-10 text-center">
                    <span class="text-gray-900 text-xs">{{ textAccept ? $t('import.fileFormats') +textAccept : $t('acceptedFileFormats') }}</span>
                    <span class="text-gray-900 text-xs">{{ $t('maxUpload') }}</span>
                </div>
            </div>
            <input id="file_input" class="hidden" type="file" ref="file"
                    :accept="accept"
                   @change="showUploadFilePopUp($event.target)"/>
        </div>
    </div>

    <ul role="list" class="flex flex-col overflow-auto gap-x-2 gap-y-2 xl:gap-x-4 w-full h-full">
        <li v-for="file in modelValue"
            :key="file"
            @drop="onDrop($event, file)"
            @dragenter.prevent
            @dragover.prevent>
            <div class="flex overflow-hidden group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <app-file :file="file" alt="" @remove="removeFile(file)" :editView="allowUploadFile"/>
            </div>
        </li>
        <li v-if="fileUploading && allowUploadFile && !disabled">
            <div class="relative block overflow-hidden  group w-full h-6 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                 :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }">
                <div class="flex items-center justify-center w-full h-full">
                    <app-loading :loading="true"/>
                </div>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>
import Files from "@/services/files";
import {mapActions} from 'vuex';

export default {
  name: "AppFileList",
  emits: ['update:modelValue', 'change'],
  components: {},
  props: {
    id: {type: String, default: ''},
    modelValue: {
      type: Array, default() {
        return []
      },
    },
    label: {type: String, default: ""},
    iconLabel: {type: Function, default: null},
    max: {type: Number, default: 10},
    rounded: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    allowUploadFile: {type: Boolean, default: true},
    maxSize: {type: Number, default: 0},
    editor: {type: Boolean, default: false},
    onlyOneFile: {type: Boolean, default: false},
    accept: {type: String, default: '*/*'},
    textAccept: {type: String, default: ''},
  },
  data() {
    return {
      editFileObject: null,
      files: this.modelValue,
      fileUploading: false,
      showEditFilePopUP: false,
      selectedFileUrl: '',
      dragover: false,
      addFile: true
    }
  },
  computed: {
    showNewButton() {
      return (this.modelValue?.length ?? 0) < this.max;
    },
    cantFile() {
      return !(this.modelValue?.length >= 1 && this.onlyOneFile === true);
    },
  },
  methods: {
    ...mapActions(['FIRE_MESSAGE']),
    showUploadFilePopUp(target) {
      if (this.editor) {
        if (this.maxSize == 0 || target.files[0].size < this.maxSize) {
          const reader = new FileReader()
          let rawFile;
          reader.onloadend = () => {
            rawFile = reader.result;
            this.editFileObject = rawFile
            this.showEditFilePopUP = true
          }
          reader.readAsDataURL(target.files[0]);
        } else
          this.FIRE_MESSAGE({message: this.$t('fileTooHeavy')})
      } else {
        this.fileUploading = true
        let files = []
        if (this.modelValue != null) {
          files = this.modelValue.slice(0);
        }

        Files.Upload(target.files)
          .then((names) => {
            names?.forEach(name => files.push(name));
            this.fileUploading = false
            this.$emit("update:modelValue", files);
            this.$emit("change");
          })
          .catch((error) => {
            console.error(error);
          });

      }
    },
    clearInput() {
      document.getElementById('file_input').value = ''
    },
    // uploadFile(file) {
    //   this.showEditFilePopUP = false
    //   this.fileUploading = true
    //   let files = []
    //   if (this.modelValue != null)
    //     files = this.modelValue.slice(0);
    //   let array = []
    //   array.push(file)
    //   Files.Upload(array)
    //     .then((names) => {
    //       names?.forEach(name => files.push(name));
    //       this.fileUploading = false
    //       this.$emit("update:modelValue", files);
    //       this.$emit("change");
    //     });
    // },
    removeFile(file) {
      let newList = this.modelValue.filter(x => x !== file);
      this.$emit("update:modelValue", newList);
      this.$emit("change");
    },
    onDrop(event, replacedFile) {
      let from = this.modelValue.indexOf(event.dataTransfer.getData('movingFile'))
      let to = this.modelValue.indexOf(replacedFile)
      this.modelValue.move(from, to)
      this.$emit("update:modelValue", this.modelValue);
      this.$emit("change");
    },
    startDrag(event, movingFile) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('movingFile', movingFile)
    },
    editFile(fileUrl) {
      this.selectedFileUrl = fileUrl
      this.showEditFilePopUP = !this.showEditFilePopUP
    },
    fileDrop(event) {
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const input = this.$refs.file
        input.files = files
        this.showUploadFilePopUp(input)
      }
      this.dragover = false
    },
  },
  setup() {
    Array.prototype.move = function (from, to) {
      return this.splice(to, 0, this.splice(from, 1)[0]);
    };
  }
};
</script>

<style>
.grabbing * {
    cursor: grabbing !important;
}
</style>
