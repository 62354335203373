import API from "@/api";

const state = {
    VERSION: "0.1",
    FILTERS: {
        workorders: {status: {"fieldId": "status", "values": ["inProgress", "pending", "onHold"]}},
        assets: {},
        sites: {},
        categories: {},
        users: {},
        requests: {},
        parts: {},
        metrics: {},
        dashboard: {},
    },

    DB_FILTERS: [],
}

const getters = {
    //FILTERS
    GET_FILTERS: state => {
        let localFilters = JSON.parse(localStorage.getItem('app-crud-filters'))
        if (localFilters) {
            for (const key of Object.keys(state.FILTERS)) {
                state.FILTERS[key] = localFilters[key]
            }
            return state.FILTERS
        }
        return state.FILTERS
    },
    //DB_FILTERS
    GET_DB_FILTER_BY_RECORD: state => recordId => {
        return state.DB_FILTERS.filter(filter => filter.recordId === recordId)
    },
}

const mutations = {
    // FILTER
    SET_FILTERS(state, payload) {
        localStorage.setItem('app-crud-filters', JSON.stringify(payload))
        state.FILTERS = payload
    },
    RESET_FILTERS(state, payload) {
        if (Object.prototype.hasOwnProperty.call(state.FILTERS, payload.name)) {
            state.FILTERS[payload.name] = {};
            localStorage.setItem('app-crud-filters', JSON.stringify(state.FILTERS));
        }
    },
    SET_STATUS_FILTER(state, payload) {
        state.FILTERS[payload.record]['status'] = {
            fieldId: 'status',
            values: payload.values
        }
        localStorage.setItem('app-crud-filters', JSON.stringify(state.FILTERS))
    },
    // DB_FILTERS
    SET_DB_FILTERS(state, payload) {
        state.DB_FILTERS = payload
    },
}

const actions = {
    READ_FILTERS(context) {
        const data = localStorage.getItem('app-crud-filters');
        if (data)
            context.commit('SET_FILTERS', JSON.parse(data))
    },
    RESET_ALL_FILTERS(context, payload) {
        context.commit('RESET_FILTERS', payload)
    },
    //DB_FILTERS
    async SAVE_DB_FILTER(context, payload) {
        const response = await API.Post(`/api/filters`, payload)
        if (response.type === 'Success') {
            context.commit('SET_DB_FILTERS', [...state.DB_FILTERS, response.res.data])
            return response.res.data;
        }
    },
    async READ_DB_FILTERS(context) {
        const response = await API.Get(`/api/filters`)
        if (response.type === 'Success') {
            context.commit('SET_DB_FILTERS', response.res.data)
            return response.res.data;
        }
    },
    async DELETE_FILTER(context, payload) {
        const response = await API.Delete(`/api/filters/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_DB_FILTERS', state.DB_FILTERS.filter(filter => filter.id !== payload.id))
            return response.res.data;
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
