<template>
  <app-loading :loading="loading" />
  <div v-if="requestLanding" :class="isMobile ? 'flex flex-col items-center w-full' : 'flex flex-row items-center justify-center w-full mt-10'">
    <div :class="isMobile ? 'full' : 'w-1/2 flex justify-end'">
      <div class="p-6">
        <div class="flex items-center align-middle text-purple bg-white mb-4">
          <img class="h-[80px] mt-4" src="/images/zigo.png" alt="zigo"/>
        </div>

        <div class="mb-4">
          <span class="font-nhu-500 text-3xl">
            {{requestLanding.name}}
          </span>
        </div>
        <div class="mb-4">
          <span class="font-nhu-regular text-gray-900 fs-14"> 
            <pre class="text-balance">
              {{requestLanding.welcomeText}}
            </pre>
          </span>
        </div>
        <div class="w-full flex justify-center">
          <app-powered-by-zigo/>
        </div>
      </div>
    </div>


    <div :class="isMobile ? 'w-full p-6 mt-4' : 'w-1/2'">
      <div v-if="isSaved" class="flex justify-center items-center">
        <span class="font-nhu-500 fs-18">{{$t('requestLandingPortal.sent')}}</span>
      </div>
      <div v-else :class="isMobile ? '' : 'border border-gray-100 rounded-[8px] p-6 max-w-[700px]'">
        <app-textbox v-model="request.name" :label="$t('requestLandingPortal.name')" :supp-text="$t('requestLandingPortal.needName')"/>
        <app-textarea v-model="request.description" :label="$t('requestLandingPortal.description')"/>
        <app-image-list v-model="request.images" :rounded="false"/>
        <app-textbox v-model="request.email" :label="$t('requestLandingPortal.email')" :emailValidator="true" />
        <div class="w-full mt-4">
          <app-button @click="doSave" type="primary" background="light" extra-class="w-full">
            {{ $t('requestLandingPortal.send') }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-center items-center">
      <span class="font-nhu-500 fs-18">{{$t('requestLandings.notFound')}}</span>
    </div>
  </div>
</template>

<script>
import {PhotoIcon, PlusCircleIcon} from "@heroicons/vue/24/outline";
import {mapActions} from "vuex";

export default {
  name: "RequestLandingPortal",
  data() {
    return {
      requestLanding: null,
      request: {},
      PhotoIcon,
      data: '',
      loading: true,
      isSaved: false
    }
  },
  computed: {
    isMobile() {
      return this.$isMobile()
    },
  },
  methods: {
    PlusCircleIcon,
    ...mapActions(['READ_LANDING', 'SEND_REQUEST']),

    async doSave() {
      const id = this.$route.params.id;
      if (id !== undefined && this.requestLanding.id !== '') {
        await this.SEND_REQUEST({...this.request, id: id})
        this.isSaved = true;
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.requestLanding = await this.READ_LANDING({id: this.$route.params.id})
    this.loading = false;
  },
}
</script>

<style scoped>

</style>
