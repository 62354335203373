<template>
  <app-crud ref="crud" @validate="onValidate" save-action="SAVE_DASHBOARD_ELEMENT" :reload="false" :title="dashboard_element.name">
    <app-textbox id="name" v-model="dashboard_element.name" :label="$t('dashboard.name')" :error="errorMsg" :focus="focusField"
                 @update:model-value="clearErrorMsg"/>
    <app-textarea v-model="dashboard_element.description" :label="$t('records.dashboard.fields.description')"/>

    <infinite-selector v-model="dashboard_element.icon" :options="localIcons" :label="$t('records.dashboard.fields.icon')">
      <template v-slot="{ option }">
        <div class="flex items-center">
          <app-icon :icon="option.name" :width="20" :height="20">
            <span class="font-nhu-regular text-gray-900 ml-2">{{ option?.name }}</span>
          </app-icon>
        </div>
      </template>
      <template #selected-option-container="{ option }">
        <div class="px-[8px] py-[2px] h-[20px] flex items-center mx-1" :key="option">
          <app-icon :icon="option.name" :width="20" :height="20">
            <span class="flex items-center font-nhu-regular h-[20px] ml-2">{{ option.name }}</span>
          </app-icon>
        </div>
      </template>
    </infinite-selector>

    <app-color-picker v-model="dashboard_element.color"/>
    <app-dropdown v-model="dashboard_element.recordId" :options="recordsOptions" :label="$t('records.dashboard.fields.record')"
                  @change="resetDashboardFilters"/>
    <div class="h-4"/>
    <app-crud-filters :record-id="dashboard_element.recordId" v-model="dashboard_element.filter"/>
    <div class="flex justify-center p-4 w-full" v-if="dashboard_element.name">
      <dashboard-element-card :item="dashboard_element"/>
    </div>
  </app-crud>
</template>

<script>

import {mapGetters} from "vuex";
import AppDropdown from "@/components/AppDropdown";
import AppCrudFilters from "@/crud/AppCrudFilters";
import DashboardElementCard from "@/pages/dashboard/DashboardElementCard";
import InfiniteSelector from "@/components/InfiniteSelector.vue";

export default {
  name: "DashboardEditPage",
  components: {InfiniteSelector, DashboardElementCard, AppCrudFilters, AppDropdown},
  data() {
    return {
      errorMsg: '',
      validated: false,
      focusField: false,
      iconName: '',
      page: 0,
      take: 50,
      localIcons: Object.keys(this.$icons).map(key => ({id: key, name: key})),
      data: []
    }
  },
  watch: {
    dashboardFilters: {
      handler: function (val) {
        if(!val)
          return
        let formattedFilters = {}
        Object.keys(val).forEach(key => {
          formattedFilters[key] = val[key].values
        })
        this.dashboard_element.filter = JSON.stringify(formattedFilters)
      },
      deep: true
    }
  },

  mounted() {
    if (this.action === 'create') {
      this.resetDashboardFilters()
    }
  },
  computed: {
    ...mapGetters({
      company: 'GET_CURRENT_COMPANY',
      dashboard_element: 'GET_DASHBOARD_ELEMENT',
      records: 'GET_RECORDS',
      filters: 'GET_FILTERS'
    }),
    /* hasPrev() {
       return this.page > 0
     },
     hasNext() {
       return this.page < Object.keys(this.$icons).length / this.take - 1
     },*/
    action() { return this.$route.meta.action; },
    dashboardFilters() {
      return this.filters.dashboard
    },
    recordsOptions() {
      //TODO: Agregar labels custom de las compañias
      return Object.keys(this.records).map(record => ({id: record, name: record}))
    },
    //TODO: Agregar labels custom de las compañias
    getRecordLabel(recordId) {
      if (this.company && this.company.recordLabels && this.company.recordLabels[recordId].label) {
        let labelInfo = this.company.recordLabels[recordId].label;
        return this.$t('customName') + labelInfo;
      } else {
        return this.$t(recordId+'.name');
      }
    },
    /*getLabel() {
      if (this.company && this.company.recordLabels && this.company.recordLabels.dashboard.label) {
        let labelInfo = this.company.recordLabels.dashboard.label;
        return this.$t('customName') + labelInfo;
      } else {
        return this.$t('dashboard.name');
      }
    },*/
  },

  methods: {
    updatePage(delta) {
      this.page += delta
      this.updateIcons(this.iconName)
    },
    resetDashboardFilters() {
      this.$store.commit('SET_FILTERS', {...this.filters, dashboard: {}})
    },
    updateIcons(value) {
      this.iconName = value
      this.localIcons = Object.keys(this.$icons).filter(x => x.includes(this.iconName)).slice(this.take * this.page, this.take * (this.page + 1)).map(key => ({
        id: key,
        name: key
      }))
    },
    async onValidate({onSuccess, onError}) {
      if (!this.dashboard_element.name) {
        this.errorMsg = this.$t('records.dashboard.errors.nameRequired');
        this.focusField = true;
        onError();
        document.getElementById("name").focus();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
    updateDate(item, date) {
      item.dueDate = date
    },
    scrollToCheckList() {
      this.$nextTick(() => {
        this.$refs.checkList.scrollIntoView({behavior: 'smooth', block: 'center'})
      })
    }
  },

  async beforeRouteLeave(to, from) {
    return await this.$refs.crud.beforeRouteLeave(to, from);
  }
}
</script>
