<template>
  <nav class="flex w-full font-nhu-regular text-gray-800 text-sm" aria-label="Pagination" v-if="pages > 1">
    <div class="hidden sm:flex sm:items-center">
      <div>
      {{$t('pagination.showing')}}
      </div>
      <div class="flex items-center h-full border-[1px] border-gray-100 rounded-lg w-[70px] h-[40px] p-2 justify-between mx-[4px]">
        10
        <!--

        <vSelect
                 class="font-nhu-regular"
                 style="font-family: 'Poppins', sans-serif"
                 :clearable="false"
                 :reduce="(o) => name !== null ? o : o" :inputId="value" v-model="take"
                 label="take"
                 :options="pageSizes">
          <template #open-indicator>
            <div class="flex items-center">
              <span class="text-xs">
            <app-icon icon="sort_down_solid" :width="12" :height="12"></app-icon></span>
            </div>
          </template>
        </vSelect>
        -->
      </div>
      <div>
        {{$t('pagination.of')}} {{ total }} {{$t('pagination.elements')}}
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="flex items-center">
      <div class="rounded-full w-8 h-8 p-1 text-center cursor-pointer flex items-center justify-center" :class="[!hasPrev ? 'fill-gray-200' : 'hover:bg-gray-100 cursor-pointer']">
        <app-icon @click="goFirst" icon="angles_left_regular" width="16" height="24" class="mx-1 my-auto"/>
      </div>
      <div class="rounded-full w-8 h-8 p-1 text-center cursor-pointer flex items-center justify-center" :class="[!hasPrev ? 'fill-gray-200' : 'hover:bg-gray-100 cursor-pointer']">
        <app-icon @click="goPrev" icon="angle_left_regular" width="10" height="24" class="mx-1 my-auto"/>
      </div>
      <div v-for="button in getButtonsToShow()" :key="button.page" @click="changePage(button.page)"
           class="rounded-full w-8 h-8 p-1 text-center cursor-pointer flex items-center justify-center"
           :class="[ page === button.page ? 'bg-primary-25 text-primary-500' : 'text-gray-900 hover:bg-gray-100']">
        {{ button.page }}
      </div>
      <div class="rounded-full w-8 h-8 p-1 text-center cursor-pointer flex items-center justify-center" :class="[!hasNext ? 'fill-gray-200' : 'hover:bg-gray-100 cursor-pointer']">
        <app-icon @click="goNext" icon="angle_right_regular" width="10" height="24" class="mx-1 my-auto"/>
      </div>
      <div class="rounded-full w-8 h-8 p-1 text-center cursor-pointer flex items-center justify-center" :class="[!hasNext ? 'fill-gray-200' : 'hover:bg-gray-100 cursor-pointer']">
        <app-icon @click="goLast" icon="angles_right_regular" width="16" height="24" class="mx-1 my-auto"/>
      </div>
    </div>
    <div class="flex-1"></div>
  </nav>
</template>

<script>
import AppIcon from "@/components/AppIcon";
//import vSelect from 'vue-select'

export default {
  name: "AppPagination",
  components: {AppIcon, /*vSelect*/},
  props: {
    serverOptions: {
      type: Object, default() {
        return {}
      }
    },
    data: {
      type: Object, default() {
        return {};
      }
    },
  },
  data() {
    return {
      //take: {id: 10, name: '10'},
      take: 10,
      pageSizes: [5, 10, 15, 20],
      //pageSizes: [{id: 5, name: '5'}, {id: 10, name: '10'}, {id: 15, name: '15'}, {id: 20, name: '20'}],
      buttons: [
        {page: 1},
        {page: 2},
        {page: 3},
        {page: 4},
      ]
    }
  },
  computed: {
    shouldShow() {
      return this.result;
    },
    rowsPerPage() {
      return this.serverOptions.rowsPerPage
    },
    page() {
      return this.data.page;
    },
    pages() {
      return this.data.pages;
    },
    total() {
      return this.data?.total ?? 0;
    },

    hasPrev() {
      return this.page > 1
    },
    hasNext() {
      return this.page < this.pages
    },
  },
  watch: {
    take() {
      this.$emit("update:serverOptions", {...this.serverOptions, rowsPerPage: this.take});
    },
    serverOptions() {
      this.load();
    },
    data() {
      this.load();
    }
  },
  methods: {
    getButtonsToShow() {
      const buttons = [];

      let startPage = this.page - 4;
      if(startPage < 1)
        startPage = 1;
      let endPage = startPage + 8;

      if(endPage > this.pages)
      {
        endPage = this.pages;
        startPage = endPage - 8;
        if(startPage < 1) startPage = 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push({ page: i });
      }

      return buttons;
    },
    load() {
      var buttons = []
      for (var i = 1; i <= this.pages; i++) {
        buttons.push({page: i})
      }
      this.buttons = buttons;
    },
    changePage(page) {
      var tmp = {...this.serverOptions};
      tmp.page = page;
      this.$emit("update:serverOptions", tmp)
    },
    async goFirst() {
      if (this.page > 1)
        this.changePage(1)
    },
    async goPrev() {
      if (this.page >= 1)
        this.changePage(this.page - 1)
      else
        this.changePage(1)
    },
    async goNext() {
      if (this.page <= this.pages)
        this.changePage(this.page + 1)
      else
        this.changePage(this.pages)
    },
    async goLast() {
      if (this.page < this.pages)
        this.changePage(this.pages)
    },
  },
  created() {
    this.load()
  },
  mounted() {
    this.take = this.serverOptions.rowsPerPage;
  }
};
</script>
<style scoped>
deep() .vs__dropdown-toggle {
  padding: 0px;
  width: 50px !important;
}

deep() .vs__search, .vs__search:focus {
  line-height: 1.5rem;
}

deep() .vs__actions{
  padding-bottom: 10px !important;
}
deep() .vs__selected {
  color: #2F5A3F !important;
  margin-top: 0 !important;
}

deep() .vs__selected-options {
  flex-basis: min-content !important;
  flex-grow: initial;
  margin-top: 0 !important;
  height: 40px !important;
}


deep() .vs__dropdown-toggle {
  margin-top: 0 !important;
  height: 40px !important;
  width: 100% !important;
}

deep() .vs__deselect {
  fill: #55A472 !important;
}

deep() .vs__search {
  display: none;
}

deep() .vs__dropdown-menu {
  color: #767C86 !important;
}

deep() .vs__dropdown-option--highlight {
  background-color: #EEF6F1 !important;
  color: #55A472 !important;
}
</style>
