<template>
  <div class="h-full pt-[41px]">
    <div class="flex border-b">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="hidden sm:block">{{ $t('options.account.title') }}</span>
        <span class="block sm:hidden">{{ $t('options.account.shortTitle') }}</span>
      </h1>
      <div class="flex-1"></div>
    </div>

    <div class="max-w-4xl">
      <app-textbox id="name" v-model="account.name" :label="$t('options.account.yourName')"/>
      <app-image-list v-model="account.images" :label="$t('options.account.Image')" :user-image="true" :editor="true" :max="1" />
      <app-textbox id="email" v-model="account.email" :label="$t('options.account.email')" :disabled="account?.isValidated" />
      <app-dropdown v-model="account.timeZoneId" value="name" :label="$t('users.timeZoneId')" :options="timezones" />
      <app-dropdown v-model="account.locale" value="id" :label="$t('users.locale')" :options="languajes" />

      <div class="h-2"></div>
      <template v-if="account?.isValidated">
        <app-form-field id="change_password" :label="$t('options.account.changePassword')"
          v-model="is_password_changed">
          <div class="flex gap-2 w-full">
            <div class="flex-1">
            <app-textbox id="actual_password" :label="$t('options.account.currentPassword')" v-model="actual_password" type="password" />
            </div>
            <div class="flex-1">
            <app-textbox id="new_password" :label="$t('options.account.newPassword')" v-model="new_password" type="password" class="flex-1" />
            </div>
          </div>
        </app-form-field>
      </template>

      <div class="border-t border-gray-300 mt-4 p-6 h-[95px]">
        <div class="flex items-center gap-x-[9px]">
          <div class="flex-1"></div>
          <app-button @click="doSave" type="secondary" background="light" :saving="saving"
            extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14">
            {{ $t('save') }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { BuildingLibraryIcon } from "@heroicons/vue/24/outline";

export default {
  name: 'OptionAccountPage',
  components: { },
  data() {
    return {
      is_password_changed: false,
      actual_password: null,
      new_password: null,
      loading: false,
      saving: false,
      account: {
        old_password: null,
        new_password: null,
        verify_new_password: null,
        isValidated: false
      },
      languajes: [{ id: 'es', name: this.$t('languaje.es'), isDeleted: false },
                  { id: 'en', name: this.$t('languaje.en'), isDeleted: false },
                  { id: 'pt', name: this.$t('languaje.pt'), isDeleted: false }],
      timezones: []
    }
  },
  computed: {
  },
  methods: {
    BuildingLibraryIcon,
    ...mapActions(['READ_ACCOUNT', 'UPDATE_ACCOUNT']),
    async doSave() {
      this.saving = true;
      const response = await this.UPDATE_ACCOUNT({
        name: this.account.name,
        email: this.account.email,
        images: this.account.images,
        changePassword: this.is_password_changed ? {
          actualPassword: this.actual_password,
          newPassword: this.new_password
        } : null,
        timeZoneId: this.account.timeZoneId,
        locale: this.account.locale
      })
      this.$i18n.locale = this.account?.locale ?? this.$i18n.locale ?? "es";
      if (response !== false) {
        this.account = response
        this.$store.commit('TOAST_MESSAGE', {
          type: 'success',
          title: this.$t('users.update.title'),
          message: this.$t('users.update.message')
        })
      /* No desloguear al usuario al actualizar sus datos
        this.$router.push('/logout');*/
      }
      this.saving = false;
    },
    doCancel() {
      this.$router.push('/');
    },
    changePassword() {
      this.is_password_changed = true;
    }
  },


  async created() {
    this.loading = true;

    // Lista de prefijos de timezones para Latinoamérica y Centroamérica
    const latinAmericanTimeZones = [
      'America/Mexico_City', 'America/Cancun', 'America/Monterrey',
      'America/Merida', 'America/Chihuahua', 'America/Mazatlan',
      'America/Hermosillo', 'America/Tijuana',
      'America/Bogota', 'America/Caracas', 'America/Guyana',
      'America/Paramaribo', 'America/La_Paz', 'America/Buenos_Aires',
      'America/Santiago', 'America/Asuncion', 'America/Lima',
      'America/Montevideo', 'America/Georgetown', 'America/Cayenne',
      'America/Brasilia', 'America/Sao_Paulo', 'America/Rio_Branco',
      'America/Manaus', 'America/Belem', 'America/Fortaleza',
      'America/Recife', 'America/Araguaina', 'America/Maceio',
      'America/Bahia', 'America/Santarem', 'America/Cuiaba',
      'America/Campo_Grande', 'America/Porto_Velho', 'America/Boa_Vista',
    ];

    let timeZones = Intl.supportedValuesOf('timeZone')
    timeZones
        .filter(x => latinAmericanTimeZones.includes(x))
        .forEach(x => {
          this.timezones.push({ id: +1, name: x, isDeleted: false })
        })

    let acc = await this.READ_ACCOUNT();
    this.account = Object.assign({}, acc);
    this.loading = false;
  },

}
</script>
<style></style>
