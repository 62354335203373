import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
  COMPANY: {
    id: '',
    name: '',
  },
  COMPANIES: {data: []},
  ALL_COMPANIES: []
}

const getters = {
  GET_COMPANY: state => state.COMPANY,
  GET_COMPANIES: state => state.COMPANIES,
}

const mutations = {
  GET_COMPANY(state, payload) {
    state.COMPANY = payload
  },
  SET_COMPANIES(state, payload) {
    state.COMPANIES = payload
  },
  CREATE_COMPANY(state, payload) {
    state.COMPANY = payload
    state.COMPANIES.data.push(payload)
  },
  UPDATE_COMPANY(state, payload) {
    if (state.COMPANY !== null && state.COMPANY.id === payload.id)
      state.COMPANY = payload;

    const index = state.COMPANIES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.COMPANIES.data[index], payload)
  },
  DELETE_COMPANY(state, payload) {
    if (state.COMPANY?.id === payload.id)
      state.COMPANY = {}
    state.COMPANIES.data = state.COMPANIES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_COMPANIES(context, payload) {
    const response = await API.Get('/api/companies', payload)
    if (response.type === 'Success') {
      context.commit('SET_COMPANIES', response.res.data)
      return response.res.data
    }
    context.commit('SET_COMPANIES', [])
  },
  async CREATE_COMPANY(context) {
    context.commit('GET_COMPANY', {name: '', description: '', sendNotification: false, authByEmailOnly: false, assignedTo: [], baseUrl: ''})
  },

  async SAVE_COMPANY(context, payload) {
    if (payload.id === undefined) {
      const response = await API.Post('/api/companies', payload)
      if (response.type === 'Success') {
        const token = response.res.data.token

        context.commit('SET_TOKEN', token)
        context.commit('SET_TOKEN_TIME', new Date().getTime())

        if (token !== '') {
          context.commit('GET_CURRENT_COMPANY', response.res.data.company)
          await context.dispatch('USER')
          window.location.replace('/workorders')
        } else {
          context.commit('SET_ERROR_LOGIN', i18n.global.t('userOrCompanyInvalid'))
        }
        return
      }
    } else {
      const response = await API.Put(`/api/companies/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_COMPANY', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_COMPANY(context, payload) {
    const response = await API.Get(`/api/companies/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('GET_COMPANY', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_COMPANY(context, payload) {
    const response = await API.Put(`/api/companies/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_COMPANY', response.res.data)
      return response.res.data
    }
  },
  async DELETE_COMPANY(context, payload) {
    const response = await API.Delete(`/api/companies/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_COMPANY', response.res.data)
      return
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
