<template>

<div class="flex">
  <div class="flex-1">
    <app-select id="customer" v-model="customer_id" @change="customerChange" :options="customers" />
  </div>
  <div class="flex">
  </div>
</div>

<div class="border p-2 rounded-xl">
  <app-form-field id="name" label="Nombre" >
    <app-textbox id="name" v-model="customer.name" placeholder="Nombre del cliente" />
  </app-form-field>
  <app-form-field id="phone" :label="$t('phone')"  v-model="customer.has_phone">
    <app-textbox id="phone" v-model="customer.phone" />
  </app-form-field>

  <app-form-field id="email" label="Email"  v-model="customer.has_email">
    <app-textbox id="email" v-model="customer.email" />
  </app-form-field>
</div>

</template>

<script>
import { mapActions } from 'vuex'


export default {
  name: "AppCustomer",
  components: {  },
  props: {
    id: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: null,
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      customers: [],
      customer_id: null,
      customer: { has_phone: false, has_email: false}
    }
  },
  methods: {
    ...mapActions(['LIST_CUSTOMERS']),

    customerChange()
    {
      if (this.customer_id === '')
      {
        this.customer = { has_phone: false, has_email: false}
      }
      else
      {
        this.customer = this.customers.find(x => x.id === this.customer_id);
      }
    },

    changeVisible() {
      this.$emit("update:modelValue", !this.modelValue);
    }
  },
  async created () {
    this.customers = await this.LIST_CUSTOMERS()
    this.customer = this.customers[0];
    this.customers.push({ id: '', name:'Nuevo...' })
  },
};
</script>
