<template>
    <app-pop-up-container v-if="action !== ''" :show="show" :title="$t('admin.issues.' + this.action)"
                          @update:show="handleUpdateShow" :loading="loading">
        <div v-if="showCategory" class="flex flex-col items-center overflow-auto max-h-[60vh]">
            <span class="font-nhu-semi-bold text-[20px] text-center">Que tipo de incidencia</span>
            <div v-for="(item, itemIndex) in categories" :key="itemIndex">
                <div class="my-3">
                    <app-button
                            @click="setIssue(item.id)"
                    >
                        {{ item.name }}
                    </app-button>
                </div>
            </div>
        </div>
        <div v-else class="space-y-2 bg-white mb-40">
            <div class="flex justify-between">
                <div class="border border-gray-300 bg-gray-100 inline-block px-2">
                    <span>{{ issueCategory.name }}</span>
                </div>
                <div class="flex">
                    <CalendarIcon class="w-6 h-6 mx-1"></CalendarIcon>
                    <span>{{ formattedDate }}</span>
                </div>
            </div>
            <issue-input
                     :issue="issue"
                     @update-issue="updateIssue"
            />
        </div>
    </app-pop-up-container>

</template>

<script>
import {
    ClipboardDocumentCheckIcon,
    ClipboardDocumentListIcon,
    CurrencyDollarIcon,
    EyeSlashIcon,
    FolderIcon,
    Bars3Icon,
    PhotoIcon,
    PlusIcon, CalendarIcon,
} from "@heroicons/vue/24/outline"

import {mapActions, mapGetters} from "vuex";
import IssueInput from "@/pages/admin/issues/IssueInput.vue";


export default {
  name: "NewIssue",
  components: {CalendarIcon, IssueInput},
  emits: ['reload'],
  data() {
    return {
      ...{
        FolderIcon,
        CurrencyDollarIcon,
        PhotoIcon,
        Bars3Icon,
        ClipboardDocumentListIcon,
        ClipboardDocumentCheckIcon,
        EyeSlashIcon,
        PlusIcon
      },
      action: '',
      show: false,
      formattedDate: new Date().toLocaleDateString('es-ES', {day: '2-digit', month: 'short', year: 'numeric'}),
      loading: false,
      saving: false,
      showCategory: true,
    }
  },
  computed: {
    ...mapGetters({issue: 'GET_ISSUE', categories: 'GET_ISSUECATEGORIES', issueCategory: 'GET_ISSUECATEGORY',
    }),
  },
  methods: {
    ...mapActions(['CREATE_ISSUE', 'SAVE_ISSUE', 'READ_ISSUECATEGORY', 'READ_ISSUECATEGORY_LIST']),

    async create() {
      this.loading = true;
      this.action = 'create';
      this.issue.status = 'opened'
      this.show = true;
      await this.CREATE_ISSUE()
      this.loading = false;
    },
    async setIssue(categoryId) {
      this.issue.issueCategoryId = categoryId;
      this.showCategory = false;
      await this.READ_ISSUECATEGORY({id: this.issue.issueCategoryId})
    },
    handleUpdateShow($event) {
      this.show = $event;
      this.showCategory = true;
    },
    updateIssue() {
      this.show = false
      this.showCategory = true;
      this.$emit('reload')
    }
  },
  async created() {

  },
}
</script>

<style scoped>

</style>
