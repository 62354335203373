import { useRoute } from 'vue-router';
import Records from '@/records.js'
import store from '@/store'

const state = {
  RECORDS: Records,
  RECORD: null
}

const getters = {
  GET_RECORDS: state => state.RECORDS,
  GET_RECORD: state => state.RECORD
}

const mutations = {
  SET_RECORD(state, payload) {
    state.RECORD = payload
  },
}

const actions = {
  async LOAD_RECORD(context) {
    const route = useRoute();
    const recordId = route.meta.record;
    if (context.getters.GET_RECORD === null || context.getters.GET_RECORD.id !== recordId) {
      const records = context.getters.GET_RECORDS;
      const record = records[recordId];
      const company = context.getters.GET_CURRENT_COMPANY ?? await context.dispatch('READ_CURRENT_COMPANY')

      if (company.properties[record?.id?.toLowerCase()]) {
        let fieldToAdd = [];
        company.properties[record?.id?.toLowerCase()].forEach(x => {
          if (record.fields?.some(r => r.id === x.code)) return;
          fieldToAdd.push({
            id: x.code,
            name: x.name,
            type: 'customProperties',
            fieldType: x.type,
            sortable: false,
            filterable: false,
            listable: true
          })
        })
        record.fields = record.fields.concat(fieldToAdd)
      }

      if (record) {
        context.commit('SET_RECORD', record);
        // CARGO LOS DATOS DE LOS FIELDS RECORDS RELACIONADOS
        var actions = []
        for (var i = 0; i < record.fields.length; i++) {
          const f = record.fields[i];
          if (f.type === 'record') {
            const data = context.getters[records[f.record].options?.getter];

            if (data === undefined) console.log('invalid record ' + f.record);

            if (data === null || (Array.isArray(data) && data.length === 0) || (Array.isArray(data?.data) && data?.data?.length === 0)) {
              const action = records[f.record].options.action
              if (!actions.includes(action)) {
                actions.push(action)
                context.dispatch(action, {});
              }
            }
          }
        }
        if (record.requirements)
          for (const req of record.requirements) {
            const data = store.getters['GET_' + req];
            if (!data || data?.length === 0) {
              context.dispatch('READ_' + req, {});
            }
          }
      }

    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
