<template>
  <app-crud ref="crud" v-slot="{ item }" save-action="SAVE_WORKORDER_RESOURCES">
    <div class="my-4">
      <span class="font-nhu-semi-bold fs-14">
            <template v-if="$hasModule('parts') && !$hasWorkOrderSetting('timeTrackingEnabled')">
              {{ $t('workorders.costTracking') }}
            </template>
            <template v-else-if="!$hasModule('parts') && $hasWorkOrderSetting('timeTrackingEnabled')">
              {{ $t('workorders.timeTracking') }}
            </template>
            <template v-else-if="$hasModule('parts') && $hasWorkOrderSetting('timeTrackingEnabled')">
              {{ $t('workorders.costAndTimeTracking') }}
            </template>
          </span>
    </div>
    <template v-if="$hasWorkOrderSetting('timeTrackingEnabled')">
      <work-order-resources v-model="item.resources" @update:modelValue="item.resources" />
    </template>
    <template v-if="$hasModule('parts') && $hasModule('parts')">
      <work-order-parts v-model="item.parts" @update:modelValue="item.parts" :label="$t('workorders.part')" />
    </template>
  </app-crud>
</template>

<script>
import WorkOrderResources from "@/pages/workorders/WorkOrderResources";
import WorkOrderParts from '@/pages/workorders/WorkOrderParts';

export default {
  name: "WorkOrderEditResourcesPage",
  components: {
    WorkOrderResources,
    WorkOrderParts
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
