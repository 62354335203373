<template>
  <div>
    <div class="flex items-center mb-1 fill-primary-500">
      <app-icon extra-class="mr-2" icon="gear_regular" :width="14" :height="14"/>
      <span class="font-nhu-semi-bold fs-14 cursor-pointer" @click="goToAsset">
          {{ $getRecordLabel('parts', 'plural') ? $getRecordLabel('parts', 'plural') : $t('workorders.parts') }}
      </span>
    </div>
    <div v-if="action !== 'view'">
      <div>
        <app-parts-selector value="id" :other-field="true" @update:model-value="updateValue"
                            :placeholder="$getRecordLabel('parts', 'singular') ? $t('select') + ' ' + $getRecordLabel('parts', 'singular') : $t('workorders.partPlaceholder')"/>
      </div>
      <div>
        <ul role="list" class="flex flex-col overflow-auto gap-x-2 gap-y-1 xl:gap-x-4 w-full h-full">
          <li v-for="(item, index) in internalValue" :key="item">
            <div
                class="flex overflow-hidden group aspect-w-10 aspect-h-7 justify-between">
              <div class="my-2 flex items-center">
                <div class="w-full flex items-center justify-between">
                  <div class="flex flex-col mx-2">
                    <span class="font-nhu-semi-bold fs-14">{{ getName(item.partId) }}</span>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-end font-nhu-regular fs-14 w-[180px]">
                <div class="-mt-4">
                  <app-textbox :model-value="item.quantity" type="number" :label="$t('quantity')"
                               @update:modelValue="onQuantityChanged(index, $event)"/>
                </div>
                <app-icon icon="trash_regular" extra-class="pl-2 fill-red-500 cursor-pointer" @click="removePart(item)"
                          :width="20" :height="20"/>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div v-for="(item) in internalValue" :key="item.partId">
        <div v-if="item.partId" class="flex justify-between mb-2">
          <span class="font-nhu-regular text-gray-900 fs-14">{{ getName(item.partId) }}</span>
          <span class="font-nhu-regular text-gray-900 fs-14">{{ item.quantity + ' ' }}{{ $t('units') }} x
            {{ formatNumber(getUnitCost(item.partId)) }} $</span>
        </div>
      </div>
      <div v-if="hasValidPartIds" class="flex justify-between mb-2">
        <span class="font-nhu-semi-bold fs-14">{{ $t('parts.totalPartsCost') }}</span>
        <span class="font-nhu-semi-bold fs-14">{{ formatNumber(totalCost) }} $</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "WorkOrderParts",
  props: {
    label: {type: String, default: ""},
    modelValue: {type: Array, default: () => []}
  },
  data() {
    return {
      part: {}
    }
  },
  computed: {
    ...mapGetters({parts: "GET_ALL_PARTS", records: 'GET_RECORDS', company: 'GET_CURRENT_COMPANY'}),
    action() {
      return this.$route.meta.action;
    },
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    totalCost() {
      return this.internalValue.reduce((acc, item) => {
        const unitCost = this.getUnitCost(item.partId) || 0;
        return acc + (unitCost * item.quantity);
      }, 0);
    },
    hasValidPartIds() {
      return this.internalValue?.some(item => item.partId);
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions(['READ_PART_BY_ID']),
    getName(itemId) {
      return this.parts.find((x) => x.id === itemId)?.name;
    },
    getUnitCost(itemId) {
      return this.parts.find((x) => x.id === itemId)?.unitCost;
    },
    formatNumber(number) {
      return number?.toLocaleString('es', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    onQuantityChanged(index, quantity) {
      const values = [...this.internalValue];
      const valueAux = values[index];
      valueAux.quantity = quantity;
      const hasQuantityAndUnitCost = valueAux.quantity && valueAux.unitCost
      if (hasQuantityAndUnitCost) {
        valueAux.totalCost = valueAux.quantity * valueAux.unitCost;
      }
      values[index] = valueAux
      this.internalValue = values;
    },
    addPart(partId) {
      if (partId) {
        let workOrderParts = [...this.internalValue];
        let newPart = this.parts.find((x) => x.id === partId);
        workOrderParts.push({
          partId: newPart.id,
          quantity: 1,
          unitCost: newPart.unitCost,
          totalCost: newPart.totalCost
        });
        this.internalValue = workOrderParts;
      }
    },
    updateValue(value) {
      if (value && value.length > 0) {
        if (this.internalValue.filter(x => x.partId === value[value.length - 1]).length <= 0) {
          this.addPart(value[value.length - 1]);
        }
      }
    },
    removePart(part) {
      this.internalValue = this.internalValue.filter(x => x !== part);
    },
    async getPart(partId) {
      this.part = await this.READ_PART_BY_ID(partId);
      return this.part;
    },
  },
};
</script>
