<template>
  <Menu as="div"
        class="p-1 mr-2 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    <div>
      <MenuButton class="flex">
        <div class="flex font-nhu-regular fs-14 px-3 py-2 items-center cursor-pointer text-primary-500 fill-primary-500 hover:text-primary-400 hover:fill-primary-400">
<!--          <span class="capitalize font-nhu-semi-bold pr-1">{{$t(modelValue.sortBy)}}</span>-->
          <span class="capitalize cursor-pointer font-nhu-semi-bold">{{getOrderName()}}</span>
          <app-icon icon="chevron_down_regular" :width="14" extra-class="ml-1" :height="14"></app-icon>
        </div>
      </MenuButton>
    </div>
    <transition enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        :class="[
          'absolute w-48 py-1 bg-white rounded shadow-lg ring-1 ring-black ring-opacity-5 z-20',
          menuRight ? 'right-5' : ''
        ]">
        <MenuItem v-for="item in menuItems" :key="item.name" v-slot="{ active }">
          <span :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-gray-700', (item.option === getOrderName()) ? 'text-primary-500' : '' ]"
                @click="setOrder(item)">
           {{item.option}}
          </span>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {Menu, MenuItems, MenuItem, MenuButton} from '@headlessui/vue'
export default {
  name: "AppMenu",
  emits: ["closeDialog", "setOrder"],
  components: {
    Menu, MenuItems, MenuItem, MenuButton
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    menuItems: {
      type: Array,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
    records: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    menuRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

  },
  methods: {
    closePopUp() {
      this.$emit("closeDialog")
    },
    setOrder(item) {
      this.$emit("setOrder", {sortBy: item.field, sortType: item.order})
    },
    getOrderName() {
      return this.records[this.record.name]?.views?.dual?.sortBy?.find(x => x.field === this.modelValue.sortBy && x.order === this.modelValue.sortType )?.option ?? ""
    },
  },
}
</script>

<style>
</style>
