<template>
  <div class="pb-2">
    <div class="flex">
      <app-icon :icon="icon" :extra-class="iconColor + ' mr-2'" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ label }}</span>
    </div>
    <app-dropdown v-model="internalValue" :getter="'GET_ALL_PARTS'" :filter-by="(array) => filterData(array, )" :label="placeholder" :supp-text="$t('workorders.partSelectorPlaceholder')"/>
    <div class="mt-4" v-if="!otherField">
      <div v-for="part in selectedParts" :key="part.id" class="my-2 flex items-center">
        <div class="w-full flex items-center justify-between">
          <div class="flex flex-col mx-2">
            <span class="font-nhu-semi-bold fs-14">{{ part.name }}</span>
          </div>
          <div class="cursor-pointer">
            <app-icon icon="trash_regular" extra-class="fill-red-500" width="20" height="20"
                      @click="deleteOption(part.id)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppPartsSelector",
  components: {},
  emits: ["update:modelValue"],
  props: {
    modelValue: {type: Array, default: () => []},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    icon: {type: String, default: ''},
    iconColor: {type: String, default: ''},
    otherField: {type: Boolean, default: false},
  },
  computed: {
    ...mapGetters({parts: "GET_ALL_PARTS", workOrder: "GET_WORKORDER"}),
    selectedParts() {
      return this.internalValue
          .map(partId => this.getPart(partId))
          .filter(x => x);
    },
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectOption(value);
      }
    },
  },
  methods: {
    filterData(array) {
      if(!this.workOrder.assetId)
        return array
      else{
        const asset = this.$store.getters["GET_ASSET_BY_ID"](this.workOrder.assetId)
        this.data = array.filter(x => asset?.parts?.includes(x.id))
        let childsParts = this.getChildsParts(asset, array)
        this.data = this.data.concat(childsParts)
      }
      return this.data
    },
    getChildsParts(asset, array){
      let childsParts = []
      asset?.childs.forEach(child => {
        const childAsset = this.$store.getters["GET_ASSET_BY_ID"](child._id)
        let parts = array.filter(x => x.assets?.includes(child._id))
        childsParts = childsParts.concat(parts)
        if(childAsset?.childs?.length > 0){
          childsParts = childsParts.concat(this.getChildsParts(childAsset, childAsset.childs))
        }
      })
      // Agregamos partes que no tienen assets asociados
      childsParts = childsParts.concat(array.filter(x => x.assets.length === 0))
      return childsParts
    },
    getPart(partId) {
      return this.parts.find(x => x.id === partId);
    },
    deleteOption(partId) {
      this.internalValue = this.internalValue.filter(x => x !== partId);
    },
    selectOption(value) {
      this.$emit("update:modelValue", value)
    },
  }
}
</script>
