<template>
  <app-crud  v-slot="{ lock }" @save="doSave" :loading="loading">

    <app-textbox v-model="group.name" :label="$t('admin.groups.name') + '*'" :disabled="lock" />

    <app-tabs v-model="tab" :tabs="tabs" />
    <div v-if="tab === 1">
      <app-table v-model:itemsSelected="group.members" value="id"
      :multi="true"
      :columns="peopleColumns"  url="/api/people" :rowsPerPage="5"  :disabled="lock" />
    </div>

  </app-crud>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GroupsPage",
  data() {
    return {
      loading: true,
      tab: 1,
      tabs: [
        { id: 1, name: 'Miembros'},
        //{ id: 2, name: 'Cursos'},
      ],
      peopleColumns: [
        { value: 'firstName', text: this.$t('admin.people.firstName'), sortable: true },
        { value: 'lastName', text: this.$t('admin.people.lastName'), sortable: true },
        { value: 'phone', text: this.$t('admin.people.phone'), sortable: true },
        { value: 'email', text: this.$t('admin.people.email'), sortable: true },
      ]      
    }
  },
  watch:{
    '$route'() {
      this.$refs.crud.reset();
      this.doLoad();
    }
  },
  computed:{
    ...mapGetters({ group: 'GET_GROUP' }),
    showDate() {
        return typeof this.group.status === 'object' ? this.group.status.id === 'standby' : this.group.status === 'standby'
    }
  },
  methods: {
    ...mapActions(['READ_GROUP', 'CREATE_GROUP', 'SAVE_GROUP']),

    async doLoad() {
      this.loading = true;
      const id = this.$route.params.id;
      if(id !== undefined)
      {
        if(id !== 'create') {
          this.group = 'view';
          await this.READ_GROUP({ id: this.$route.params.id })
        } else {
          this.group = 'create';
          await this.CREATE_GROUP();
        }
      }
      this.loading = false;
    },

    async doSave() {
      await this.SAVE_GROUP(this.group)
    },
  },
  async mounted() {
    await this.doLoad()
  },
}
</script>