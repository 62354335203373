import API from '@/api'

const state = {
  ISSUE: {
    title: '',
    description: '',
    dueDate: null,
    location: '',
    status: '',
    siteId: '',
    peopleId: '',
    issueCategoryId: '',
    modificationTime: '',
    issueLogs: [],
    media: []
  },
  ISSUES: { data:[] }
}

const getters = {
  GET_ISSUE: state => state.ISSUE,
  GET_ISSUES: state => state.ISSUES,
}

const mutations = {
  // ISSUES
  SET_ISSUE(state, payload) {
    state.ISSUE = payload
  },
  SET_ISSUES(state, payload) {
    state.ISSUES = payload
  },
  CREATE_ISSUE(state, payload) {
    state.ISSUE = payload
    state.ISSUES.data.push(payload)
  },
  UPDATE_ISSUE(state, payload) {
    if (state.ISSUE !== null && state.ISSUE.id === payload.id)
      state.ISSUE = payload;

    const index = state.ISSUES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.ISSUES.data[index], payload)
  },
  DELETE_ISSUE(state, payload) {
    if (state.ISSUE?.id === payload.id)
      state.ISSUE = {}
    state.ISSUES.data = state.ISSUES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD ISSUES
  async READ_ISSUES(context, payload) {
    const response = await API.Get('/api/issues', payload)
    if (response.type === 'Success') {
      context.commit('SET_ISSUES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ISSUES', [])
  },
  async CREATE_ISSUE (context){
    context.commit('SET_ISSUE', { name: '', description: '', location: '', siteId: '', media: [], issueCategoryId: '', issueLogs: [], peopleId: '', status: '', modificationTime: '' })
  },
  async SAVE_ISSUE (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/issues', payload)
      if(response.type === 'Success'){
        context.commit('SET_ISSUE', response.res.data)
        return response.res.data;
      }
    }
    else
    {
      const response = await API.Put(`/api/issues/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_ISSUE', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_ISSUE(context, payload) {
    const response = await API.Get(`/api/issues/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_ISSUE', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_ISSUE(context, payload) {
    const response = await API.Put(`/api/issues/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_ISSUE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_ISSUE(context, payload) {
    const response = await API.Delete(`/api/issues/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_ISSUE', response.res.data)
      return
    }
  },
  async DELETE_ISSUE_LIST(context, payload) {
    const response = await API.Patch('/api/issues',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_ISSUE', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
