export default class CRUD {

    static getRecordListUrl(record)
    {
        return '/' + record.name;
    }

    static getRecordCreateUrl(record, params = null)
    {
        if(!params)
            return '/' + record.name + '/create';
        else
            return '/' + record.name + '/create?' + params;
    }

    static getRecordViewUrl(record, id)
    {
        if(id)
            return '/' + record.name + '/' + id;
        else
            return '/' + record.name;
    }

    static getRecordEditUrl(record, id)
    {
        if(id)
            return '/' + record.name + '/' + id + '/edit';
        else
            return '/' + record.name;
    }

    static getRecordUrl(record, id, action)
    {
        if(id)
            return '/' + record.name + '/' + id + '/' + action;
        else
            return '/' + record.name;
    }
}
