const options = [
    {
        id: 'dashboard',
        name: 'dashboard',
        icon: 'chart_line_regular',
        module: '',
        href: '/dashboard'
    },
    {
        id: 'workorders',
        name: 'records.workorders.title',
        icon: 'inbox_full_regular',
        href: '/workorders',
        module: ''
    },
    {
        id: 'requests',
        name: 'records.requests.title',
        icon: 'inbox_regular',
        href: '/requests',
        module: 'requests'
    },
    {
        id: 'sites',
        name: 'records.sites.title',
        icon: 'location_dot_regular',
        href: '/sites',
        module: 'sites'
    },
    {
        id: 'assets',
        name: 'records.assets.title',
        icon: 'cubes_regular',
        href: '/assets',
        module: 'assets'
    },
    {
        id: 'parts',
        name: 'records.parts.title',
        icon: 'gears_regular',
        href: '/parts',
        module: 'parts'
    },
    {
        id: 'metrics',
        name: 'records.metrics.title',
        icon: 'gauge_high_regular',
        href: '/metrics',
        module: 'metrics'
    },
    {
        id: 'templates',
        name: 'records.templates.title',
        icon: 'file_lines_regular',
        href: '/templates',
        module: 'templates'
    },
    // {
    //     id: 'inspections',
    //     name: 'records.inspections.title',
    //     icon: 'list_check_regular',
    //     href: '/inspections',
    //     module: 'inspections'
    // },
    {
        id: 'procedures',
        name: 'records.procedures.title',
        icon: 'file_lines_regular',
        href: '/procedures',
        module: 'procedures'
    },
    {
        id: 'categories',
        name: 'records.categories.title',
        icon: 'tag_regular',
        href: '/categories',
        module: 'categories'
    },
    {
        id: 'users',
        name: 'records.users.title',
        icon: 'users_regular',
        href: '/users',
        module: ''
    },
    /*    {
            name: 'records.teams.title',
            icon: 'people_group_regular',
            href: '/teams'
        },
    /*    {
            name: 'Mensajes',
            icon: 'messages_regular',
            href: '/messages'
        },
        {
            name: 'Reportes',
            icon: 'chart_pie_simple_regular',
            href: '/admin/reports'
        }, */
]

export default options;
