<template>
    <app-pop-up-container v-if="actionStatus !== ''" :show="show" :title="$t('admin.actions.' + this.actionStatus)"
                          :loading="loading">
        <div class="space-y-2 bg-white mb-10 overflow-y-auto max-h-[500px]">
            <div class="grid gap-4 my-4">
                <app-form-field>
                    <app-textbox v-model="action.name" :label="$t('admin.actions.name')"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-4">
                <app-form-field :label="$t('admin.actions.status.name')">
                    <app-dropdown v-model="action.status" value="id" :options="status"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-4">
                <app-form-field>
                    <app-textbox v-model="action.description" :label="$t('admin.actions.description')"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-4">
                <app-form-field :label="$t('admin.actions.people')">
                    <app-dropdown v-model="action.peopleId" value="id" getter="GET_PEOPLE" url="/api/people/all"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-8">
                <app-form-field :label="$t('admin.actions.site')">
                    <app-dropdown v-model="action.siteId" value="id" getter="GET_SITES" url="/api/sites/all"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-8">
                <app-form-field :label="$t('admin.actions.asset')">
                    <app-dropdown v-model="action.assetId" value="id" getter="GET_ASSETS" url="/api/assets/all"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-8">
                <app-form-field :label="$t('admin.actions.tag')">
                    <app-dropdown v-model="action.tagId" value="id" getter="GET_TAGS" url="/api/tags/tagList"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-8">
                <app-form-field :label="$t('admin.actions.dueDateTime')">
                    <app-date-picker v-model="action.dueDateTime" value="id"/>
                </app-form-field>
            </div>
            <div class="grid gap-4 my-8">
                <app-form-field :label="$t('admin.actions.priority')">
                    <app-dropdown v-model="action.priority" value="id" :options="priority"/>
                </app-form-field>
            </div>
        </div>
        <template #footer>
            <app-button @click="doCancel" type="secondary" background="light">
                {{ action !== 'view' ? $t('cancel') : $t('back') }}
            </app-button>
            <app-button @click="doEdit" :disabled="!allowSave" type="primary" background="light"
                        v-if="actionStatus === 'view'">
                {{ $t('edit') }}
            </app-button>
            <app-button @click="doSave" :disabled="!allowSave" type="primary" background="light"
                        v-if="actionStatus !== 'view'">
                {{ $t('save') }}
            </app-button>
        </template>
    </app-pop-up-container>
</template>
<script>
import {
  Bars3Icon, ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon, EyeSlashIcon,
  FolderIcon,
  PhotoIcon, PlusIcon
} from "@heroicons/vue/24/outline";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewAction",
  emits: ['reload'],
  data() {
    return {
      ...{
        FolderIcon,
        CurrencyDollarIcon,
        PhotoIcon,
        Bars3Icon,
        ClipboardDocumentListIcon,
        ClipboardDocumentCheckIcon,
        EyeSlashIcon,
        PlusIcon
      },
      showRemovePopUp: false,
      actionStatus: '',
      show: false,
      loading: false,
      saving: false,
      nextActivationTime: '',
      priority: [
        {id: 'low', name: this.$t('admin.actions.low')},
        {id: 'mid', name: this.$t('admin.actions.mid')},
        {id: 'high', name: this.$t('admin.actions.high')},
      ],
      status: [
          {id: 'pending', name: this.$t('admin.actions.status.pending')},
          {id: 'inProgress', name: this.$t('admin.actions.status.inProgress') },
          {id: 'completed', name: this.$t('admin.actions.status.completed') },
          {id: 'notFeasible', name: this.$t('admin.actions.status.notFeasible') },
      ],
    }
  },
  computed: {
    ...mapGetters({action: 'GET_ACTION'}),
    allowSave() {
      return this.action.name !== ''
    },
  },
  methods: {
    ...mapActions(['READ_ACTION', 'READ_ACTIONS', 'CREATE_ACTION', 'SAVE_ACTION', 'DELETE_ACTION', 'UPDATE_ACTION']),

    async create() {
      this.loading = true;
      this.actionStatus = 'create';
      this.show = true;
      await this.CREATE_ACTION()
      this.action.priority = 'mid';
      this.action.status = 'pending';
      this.loading = false;
    },

    async edit(id) {
      this.loading = true;
      this.actionStatus = 'edit';
      this.show = true;
      await this.READ_ACTION({id: id})
      this.loading = false
    },


    // doDelete() {
    //   this.showRemovePopUp = true;
    // },
    // async doDelete2() {
    //   this.showRemovePopUp = false
    //   this.saving = true;
    //   await this.DELETE_PEOPLE(this.people)
    //   this.saving = false;
    //   this.show = false;
    //   this.$emit('reload');
    // },


    async doSave() {
      this.saving = true;
      await this.SAVE_ACTION(this.action)
      this.show = false;
      this.saving = false;
      this.$emit('reload')
    },

    doCancel() {
      this.show = false;
    },

    getStatusName(status) {
      switch (status) {
        case 'active':
          return this.$t('admin.people.active');
        case 'standby':
          return this.$t('admin.people.standby');
        case 'inactive':
          return this.$t('admin.people.inactive');
        default:
          return status;
      }
    },
    /*******************************/
  },
  async created() {
    
  },
}
</script>

<style scoped>

</style>
