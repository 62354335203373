<template>
  <div class="h-full pt-[41px]" v-if="company !== null">
    <div class="flex border-b mb-8">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="cursor-pointer" @click="goBack"> <ChevronDoubleLeftIcon class="w-8 h-8"/></span>
        <span class="hidden sm:block">{{ $t('features.record_labels_title') }} </span>
      </h1>
    </div>

    <div class="max-w-4xl">
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5" icon="window_regular" :width="20" :height="20"
                        :fill="this.$colors.primary[500]"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                {{ $t('options.features.' + recordName + '_labels') }}
              </span>
            </div>
          </div>
        </div>
        <div class="fs-14 font-nhu-regular text-gray-500">
          <span>{{ $t('features.record_labels_question') }}</span>
        </div>
        <div class="flex">
          <div class="pt-4 flex-1">
            <div class="fs-12 font-nhu-regular text-gray-500">
              <span class="ml-2 mb-0 font-nhu-semi-bold">{{ $t('records.' + recordName + '.singular_title') }}</span>
              <app-textbox v-model="singularLabel" :label="$t('options.features.add_default_label')" :error="''"/>
            </div>
          </div>
          <div class="pt-4 ml-4 flex-1">
            <div class="fs-12 font-nhu-regular text-gray-500">
              <span class="ml-2 mb-0 font-nhu-semi-bold">{{ $t('records.' + recordName + '.title') }}</span>
              <app-textbox v-model="pluralLabel" :label="$t('options.features.add_default_label')" :error="''"/>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end">
        <div class="flex gap-1">
          <app-button @click="reset" :disabled="saving"> {{ $t('borrar') }}</app-button>
          <app-button @click="save" :disabled="saving"> {{ $t('save') }}</app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {ChevronDoubleLeftIcon} from "@heroicons/vue/24/outline";
import {mapGetters, mapActions} from "vuex";

export default defineComponent({
  name: "OptionrecordLabels",
  components: { ChevronDoubleLeftIcon },
  data() {
    return {
      loading: false,
      saving: false,
      singularLabel: '',
      pluralLabel: '',
      recordName: ''
    }
  },
  computed: {
    ...mapGetters({user: 'GET_CURRENT_USER', company: 'GET_CURRENT_COMPANY'})
  },
  async created() {
    const param = this.$route.query
    this.recordName = param.record

    if(this.company.recordLabels[this.recordName] !== null) {
      this.singularLabel = this.company.recordLabels[this.recordName]?.label;
      this.pluralLabel = this.company.recordLabels[this.recordName]?.pluralLabel;
    } else {
      this.singularLabel = '';
      this.pluralLabel = '';
    }
  },
  methods: {
    ...mapActions(['UPDATE_CURRENT_COMPANY']),
    goBack() {
      this.$router.push('/admin/features');
    },
    async reset() {
      this.singularLabel = ''
      this.pluralLabel = '';
      await this.save();
    },
    async save() {
      this.saving = true;
      var recordLabels = {...this.company.recordLabels};
      recordLabels[this.recordName] = { label: this.singularLabel, pluralLabel: this.pluralLabel }

      await this.UPDATE_CURRENT_COMPANY({id: this.company.id, recordLabels: recordLabels})

      this.goBack()
      this.saving = false;
    }
  }
})
</script>


<style scoped>

</style>
