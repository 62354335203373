<template>
  <TransitionRoot as="template" :show="showPopup">
    <Dialog class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="relative flex items-center justify-center h-full text-center bg-black bg-opacity-50 min-h-fit"
             @mousedown.self="closePopup">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100"
                           leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative text-left overflow-hidden border transition-all transform bg-white shadow-xl w-full lg:w-1/2 h-full lg:h-5/6 p-6 flex flex-col justify-between">
              <div class="overflow-auto">
                <button @click="() => closePopup()"
                        class="absolute right-1 top-1 bg-gray-100 rounded-full">
                  <XMarkIcon class="w-8 h-8"/>
                </button>
                <app-textbox v-model="asset.name" :label="$t('assets.name')"/>
                <app-textarea v-model="asset.description" :label="$t('sites.description')"/>
                <app-crud-properties v-model="asset.props" :edit-prop="true"/>
                <app-image-list v-model="asset.media" :rounded="false"/>
                <!--                                <app-dropdown v-model="asset.siteId" :label="$t('sites.teamsInCharge')"/>-->
              </div>
              <div
                  class="flex items-center gap-x-[9px] mt-6 sticky bottom-0 bg-white pt-6 pb-2 border-t border-gray-100">
                <div class="flex-1"></div>
                <app-button @click="doCancel" :clean="true" type="terciary" background="light"
                            extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14">
                  {{ $t('cancel') }}
                </app-button>
                <app-button @click="doSave" type="secondary" background="light" :saving="saving">
                  {{ $t('accept') }}
                </app-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {Menu, MenuItems, MenuItem, MenuButton} from '@headlessui/vue'
import {mapActions, mapGetters} from 'vuex';
import {XMarkIcon, MagnifyingGlassIcon} from '@heroicons/vue/24/outline'
import DualList from '@/assets/icons/DualList.vue';
import FullList from '@/assets/icons/FullList.vue';
import CalendarIcon from '@/assets/icons/CalendarIcon.vue';
import AppCrudField from '@/crud/AppCrudField'
import AppIcon from "@/components/AppIcon";
import AppTextarea from "@/components/AppTextarea.vue";

export default {
  name: "NewAsset",
  emits: ['reload', 'close'],
  components: {AppTextarea, Dialog, TransitionRoot, DialogPanel, TransitionChild, XMarkIcon},
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ...{
        AppIcon,
        AppCrudField,
        DualList,
        FullList,
        CalendarIcon,
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        Menu,
        MenuItems,
        MenuItem,
        MenuButton,
        XMarkIcon,
        MagnifyingGlassIcon
      },
      showRemovePopUp: false,
      action: '',
      loading: false,
      saving: false,
      clientHeight: document.documentElement.clientHeight,
      clientWidth: document.documentElement.clientWidth,
    }
  },
  computed: {
    ...mapGetters({asset: 'GET_ASSET'}),
    show() {
      return this.showPopup
    },
    getHeight() {
      const t = document.getElementById('displayCtrl');
      const top = t?.offsetTop ?? 75;

      if (this.clientHeight > 300)
        return (this.clientHeight - top - 25 + (this.clientWidth * 0)) + 'px';
      else
        return '200px';
    },
  },
  methods: {
    ...mapActions(['READ_ASSET', 'READ_ASSETS', 'CREATE_ASSET', 'SAVE_ASSET', 'DELETE_ASSET', 'UPDATE_ASSET']),

    async create(assetId) {
      this.loading = true;
      await this.CREATE_ASSET()
      this.asset.parentId = assetId
      this.loading = false;
    },
    getDimensions() {
      this.clientHeight = document.documentElement.clientHeight;
      this.clientWidth = document.documentElement.clientWidth;
    },
    async doSave() {
      this.saving = true;
      await this.SAVE_ASSET(this.asset)
      this.saving = false;
      this.$emit('reload')
    },
    doCancel() {
      this.$emit('close')
    },
    closePopup() {
      this.$emit('close')
    },
    /*******************************/
  },
  async mounted() {
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
}
</script>

<style scoped>

</style>
