<template>
  <div class="w-full">
    <div :class="[loading === true ? 'loader-line' : 'loader-line-off', bgColor ? 'loader-line-blue' : '' ]"></div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AppLoading',
  components: {  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
  }
}
</script>
<style scoped>
  .loader-line-off {
    display: none;
    width: 100%;
    height: 3px;
  }
  .loader-line {
      width: 100%;
      height: 3px;
      position: relative;
      overflow: hidden;
      background-color: #ddd;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
  }

  .loader-line:before {
      content: "";
      position: absolute;
      left: -50%;
      height: 3px;
      width: 40%;
      background-color: #1BA335;
      -webkit-animation: lineAnim 1s linear infinite;
      -moz-animation: lineAnim 1s linear infinite;
      animation: lineAnim 1s linear infinite;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
  }
  .loader-line-blue:before {
    background-color: #1E90FF;
  }

  @keyframes lineAnim {
      0% {
          left: -40%;
      }
      50% {
          left: 20%;
          width: 80%;
      }
      100% {
          left: 100%;
          width: 100%;
      }
  }
</style>
