<template>
  <div class="flex items-center justify-center" v-if="this.src || this.default">
    <img :src="imageUrl" class="w-full h-full"
        :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }"
        style="margin-bottom: 0"
        :alt="alt" loading="lazy"
        @load="loaded"/>

    <button @click="changePrevImage" v-if="isList" class="absolute top-1/2 left-3 w-[30px] h-[30px] text-white text-center
      transition-all duration-300 ease-out select-none active:transition-none active:shadow-none
      hover:bg-gray-200 active:bg-gray-100 shadow-btn rounded-full bg-gray-400 opacity-50 hover:text-black">
      <ChevronLeftIcon class="w-5 h-5 m-auto"/>
    </button>

    <button @click="changeNextImage" v-if="isList" class="absolute top-1/2 right-3 w-[30px] h-[30px] text-white text-center
      transition-all duration-300 ease-out select-none active:transition-none active:shadow-none
      hover:bg-gray-200 active:bg-gray-100 shadow-btn rounded-full bg-gray-400 opacity-50 hover:text-black">
      <ChevronRightIcon class="w-5 h-5 m-auto"/>
    </button>

    <app-loading :loading="loading" v-if="loading"/>
 </div>
</template>


<script>
import { ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/outline'
export default {
  name: "AppImage",
  components: { ChevronLeftIcon, ChevronRightIcon},
  props: {
    src: {
      type: [String, Array],
      default: null
    },
    alt: {
      type: String,
      default: null
    },
    default: {
      type: String,
      default: null
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    isQr: {
      type: Boolean,
      default: false,
    },
    rounded_type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      index: 0,
    }
  },
  methods: {
    loaded() {
      this.loading = false;
    },
    changePrevImage()
    {
      if (this.index <= 0)
        this.index = this.src.length - 1;
      else
        this.index = this.index - 1;
    },
    changeNextImage()
    {
      if (this.index >= this.src.length - 1)
        this.index = 0;
      else
        this.index = this.index + 1;
    }
  },
  computed: {
    isList() {
      return Array.isArray(this.src) && this.src.length > 1;
    },
    imageUrl() {
      let baseURL = this.isQr ? process.env.VUE_APP_QR_IMAGE_URL : process.env.VUE_APP_IMAGE_URL
      if (this.src !== null)
      {
        if (Array.isArray(this.src))
          baseURL += this.src[this.index];
        else
          baseURL += this.src;
      }
      else
        baseURL = this.default;
      return baseURL;
    }

  },
};
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
