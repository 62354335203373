<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" @change="(e) => changeTab(+e.target.value)" class="block w-full rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
        <option v-for="item in tabs" :key="item.id" :value="item.id" :selected="item.id === modelValue">{{ item.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex" aria-label="Tabs">
          <a v-for="item in tabs" :key="item.id" @click="changeTab(item.id)" class="cursor-pointer" 
            :class="[item.id === modelValue ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 
                                  'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm']" 
            :aria-current="item.id === modelValue ? 'page' : undefined">{{ item.name }}</a>
        </nav>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AppTabs',
  components: {  },
  data() {
    return {
    }
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: "",
    },
    tabs: {
      type: Array,
			default() { return [] },
    },
	},
	methods: {
    changeTab(t) {
      this.$emit('update:modelValue', t)
    },
	},  
}
</script>
