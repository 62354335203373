<script>
import ParentControl from './DashboardEditPage'

export default {
  name: 'DashboardCreatePage',
  extends: ParentControl,
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>

