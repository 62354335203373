import API from '@/api'

const state = {
  TIMEZONES: null,
}

const getters = {
  GET_TIMEZONES: state => state.TIMEZONES,
}

const mutations = {
  // Branch
  SET_TIMEZONES(state, payload) {
    state.TIMEZONES = payload
  },
}

const actions = {
  // CRUD BRANCH
  async GET_TIMEZONES(context) {
    if (context.getters.GET_TIMEZONES !== null)
      return context.getters.GET_TIMEZONES

    const response = await API.Get('/api/options/timezones')
    if (response.type === 'Success') {
      context.commit('SET_TIMEZONES', response.res.data)
      return response.res.data;
    }
    context.commit('SET_TIMEZONES', [])

    return null;
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}