<template>
  <app-crud ref="crud" :title="$t('records.metrics.addMeasure')" save-action="SAVE_WORKORDER">
    <div class="flex items-center">
      <span class="font-nhu-semi-bold fs-14">{{ $t('records.metrics.newMeasure') }}</span>
      <div class="h-0 border-b border-gray-50 my-4"/>
    </div>
    <app-textbox v-model="workOrder.measure.value" :label="$t('records.metrics.addMeasure')"/>
    <div v-if="getLastValue()" class="py-2">
      <span class="font-nhu-regular fs-14">
        {{$t('records.metrics.lastMeasure')}}: {{getLastValue()}} {{$t(`records.metrics.units.${metric.measurementUnit}`)}}
      </span>
    </div>
    <app-image-list v-model="workOrder.measure.images" :rounded="false" :allow-upload-file="true"/>
  </app-crud>
</template>

<script>

import {mapGetters} from "vuex";
import AppImageList from "@/components/AppImageList";
import AppTextbox from "@/components/AppTextbox";
export default {
  name: "WorkOrderAddMetricPage",
  components: {
    AppTextbox,
    AppImageList
  },
  computed: {
    ...mapGetters({metric: 'GET_METRIC', workOrder: 'GET_WORKORDER'}),
  },
  data() {
    return {
      measure: {
        workOrderId: this.workOrder?.id,
        value: 0,
        images: [],
      },
    }
  },
  methods: {
    getLastValue() {
      return this.workOrder.measure.value
    }
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
