<template>
  <div @click="labelClick">
    <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled"
      v-slot="{ focusin, focusout }">
      <div class="flex flex-col w-full" :class="{ 'mt-7': animateLabel }">
        <div class="h-full w-full flex items-center selection::border-0 relative group">
          <DatePicker v-model="value" :popover="popOver" :min-date="minDate" :attributes='attributes'>
            <template #default="{ togglePopover, inputValue, inputEvents }">
              <div class="flex w-full fill-primary-500">
                <app-icon v-if="value" extra-class="mr-2 mt-4" icon="calendar_regular" :width="20" :height="20" />
                <input ref="input" :value="inputValue" v-on="inputEvents" @focusin="focusin" :type="'inputDate'"
                  :disabled="disabled"
                  class="flex-1 focus:outline-none bg-transparent sm:text-sm mt-4 sm:leading-6 border-0 focus:ring-0" />
                <button ref="datePicker" class="mr-4" :disabled="disabled" @click.stop="() => togglePopover()"
                  @focusout="focusout" />
              </div>
            </template>
          </DatePicker>
        </div>
      </div>
      <div v-if="suppText !== ''" class="items-center mt-2 text-nowrap hidden sm:flex">
        <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-gray-300" />
        <span class="relative block font-nhu-regular text-xs text-gray-300 cursor-text">{{ suppText }}</span>
      </div>
    </app-input-container>
  </div>
</template>

<script>
import {DatePicker} from 'v-calendar';
import 'v-calendar/style.css';

import { InformationCircleIcon } from '@heroicons/vue/24/outline'

export default {
  name: "AppDatePicker",
  components: { DatePicker, InformationCircleIcon },
  props: {
    id: { type: String, default: null },
    placeholder: { type: String, default: null },
    modelValue: {
      type: [Object, String], default() {
        return null
      },
    },
    container: { type: Boolean, default: true },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    animateLabel: { type: Boolean, default: false },
    iconLabel: { type: Function, default: null },
    minutesIncrement: { type: Number, default: 1 },
    suppText: { type: String, default: "" },
    minDate: { type: Date, default() { } }
  },
  emits: ["update:modelValue", 'keydown'],
  data() {
    return {
      animateLabelText: "",
      showPassword: false,
      showValidationError: false,
      showValidationSuccessful: false,
      validationErrorMsg: "",
      popOver: {
        visibility: 'focus',
        autoHide: false,
      },
      attributes: [
        {
          highlight: {
            color: 'primary',
            fillMode: 'outline',
          },
          dates: new Date(),
          order: 0
        }
      ],
      internalValue: null,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.showValidationError = false
        this.showValidationSuccessful = false
        this.$emit("update:modelValue", value);
        this.$emit("keydown");
      }
    },
  },
  methods: {
    /*labelClick() {
      event.stopPropagation();
      this.$refs.datePicker.click();
      this.$refs.input.focus()
    },*/
    focusCalendar(val = null) {
      this.internalValue = val
    },
  },
  created() {
    if (this.placeholder !== null && this.placeholder.length !== 0) {
      this.animateLabelText = this.placeholder
    } else if (this.label !== null && this.label.length !== 0) {
      this.animateLabelText = this.label
    }
  }
};
</script>

<style>
.vc-popover-content-wrapper.is-interactive {
  z-index: 99;
}

.vc-primary {
  --vc-accent-50: #EEEFFD;
  --vc-accent-100: #eeeffd;
  --vc-accent-200: #cbcdf8;
  --vc-accent-300: #b2b5f5;
  --vc-accent-400: #8e94f1;
  --vc-accent-500: #797fee;
  --vc-accent-600: #575fea;
  --vc-accent-700: #4f56d5;
  --vc-accent-800: #3e43a6;
  --vc-accent-900: #303481;

}
</style>
