
<script>
import ParentControl from './PartEditPage'

export default {
  name: 'PartCreatePage',
  extends: ParentControl,
    async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>

