const currencies = [{
            id: 'ARS',
            name: 'ARS'
},
    {
        id: 'USD',
        name: 'USD'
    },
];


export default currencies;