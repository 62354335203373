<template>
  <app-crud ref="crud" v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('records.metrics.fields.name')" :error="errorMsg"
                 @update:model-value="clearErrorMsg"/>
    <app-textarea v-model="item.description" :label="$t('records.metrics.fields.description')"/>
    <app-dropdown v-model="item.measurementUnit" :label="$t('records.metrics.fields.measurementUnit')"
                  :options="metricOptions" value="id"/>

    <metric-assigned-selector v-model="metricTypeAssigned" @input="changeInputType(item)"/>
    <app-dropdown v-if="metricTypeAssigned === 'site'" v-model="item.siteId"
                  :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('metrics.siteId')"
                  getter="GET_ALL_SITES"/>
    <app-dropdown v-else v-model="item.assetId"
                  :label="$getRecordLabel('assets') ? $getRecordLabel('assets') : $t('metrics.assetId')"
                  getter="GET_ALL_ASSETS"/>


    <app-form-field v-model="item.minRule.enabled" :label="$t('metrics.minValue')">
        <app-textbox v-model="item.minRule.value" :label="$t('records.metrics.fields.minValue')" type="number"/>
        <app-textbox v-model="item.minRule.name" :label="$t('requests.name')" />
        <app-textarea v-model="item.minRule.description" :label="$t('requests.description')" />
    </app-form-field>
    <app-form-field v-model="item.maxRule.enabled" :label="$t('metrics.maxValue')">
        <app-textbox v-model="item.maxRule.value" :label="$t('records.metrics.fields.maxValue')" type="number"/>
        <app-textbox v-model="item.maxRule.name" :label="$t('requests.name')" />
        <app-textarea v-model="item.maxRule.description" :label="$t('requests.description')" />
    </app-form-field>

    <app-image-list v-model="item.images" :rounded="false"/>
    <app-file-list v-model="item.files" :rounded="false"/>

    <app-confirm-dialog ref="confirm"/>

  </app-crud>
</template>

<script>
import MetricAssignedSelector from "./MetricAssignedSelector"
import {mapGetters} from "vuex";

export default {
  name: "MetricEditPage",
  components: {MetricAssignedSelector, /*FrequencySelector*/},
  data() {
    return {
      errorMsg: '',
      validated: false,
      currentOption: {},
      optionType: '',
    }
  },
  computed: {
    ...mapGetters({metricTypeAssigned: 'GET_METRIC_TYPE_ASSIGNED'}),
    metricOptions() {
      return this.$store.getters.GET_METRICS_UNITS.map(x => ({id: x.id, name: this.$t(x.name)}))
    },
  },
  methods: {
    changeInputType(item) {
      item.assetId = ''
      item.siteId = ''
    },
    onValidate({item, onSuccess, onError}) {
      if (!item.name) {
        this.errorMsg = this.$t('metrics.errors.name');
        onError();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) {
    return await this.$refs.crud.beforeRouteLeave(to, from);
  }
}
</script>
