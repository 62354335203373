import axios from 'axios'
import store from '@/store'
import router from '../router'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default class API {

    static async Get(path, params) {
        return await this.Request("get", path, params, null)
    }

    static async Post(path, resource, type) {
        return await this.Request("post", path, resource, type)
    }

    static async Patch(path, resource, type) {
        return await this.Request("patch", path, resource, type)
    }

    static async Put(path, resource, type) {
        return await this.Request("put", path, resource, type)
    }

    static async Delete(path) {
        return await this.Request("delete", path)
    }

    static async Request(method, path, resource, type = 'data') {
        try {
            const token = store.getters?.GET_TOKEN;
            const request = {
                method: method,
                url: `${path}`,
                headers: {
                    Authorization: (token ? `Bearer ${token}` : '')
                }
            }
            if (type)
                request.data = resource
            else
                request.params = resource

            const response = await axios(request)

            //El token se refresca cada 4 horas
            const tokenTime = store.getters?.GET_TOKEN_TIME;
            const now = new Date().getTime();
            if (tokenTime && now - tokenTime > 4 * 3600000) {
                const currentTokenTime = tokenTime
                store.commit('SET_TOKEN_TIME', new Date().getTime())
                await axios.get('api/auth/refresh-token', {
                    headers: {
                        Authorization: (token ? `Bearer ${token}` : '')
                    }
                }).then(tokenResponse => {
                    store.commit('SET_TOKEN', tokenResponse.data)
                }).catch(() => {
                    store.commit('SET_TOKEN_TIME', currentTokenTime)
                })
            }

            return this.FormattedSuccessResponse(response)
        } catch (error) {
            if (error?.response?.status === 401) {
                store.commit('SET_USER', null)
                store.commit('SET_TOKEN', null)
                store.commit('SET_TOKEN_TIME', null)

                localStorage.removeItem('zigo-token')
                localStorage.removeItem('zigo-token-time')

                if (this.$route?.name !== 'MailValidationPage') {
                    store.commit('SET_REDIRECT_AFTER_LOGIN', window.location.href)
                    router.push('/login')
                }

                return this.FormattedErrorResponse({status: error.response.status})
            }

            const data = error.response?.data;
            if (data !== null && data !== undefined) {
                if (data.type === "https://tools.ietf.org/html/rfc7231#section-6.5.1") {
                    if (data.detail !== undefined)
                        store.commit('FIRE_MESSAGE', {message: data.detail});
                    else if (data.errors !== undefined) {
                        for (var [key, value] of Object.entries(data.errors)) {
                            console.log(key, value);
                            store.commit('FIRE_MESSAGE', {message: value[0]});
                        }
                    } else if (data.title !== undefined)
                        store.commit('FIRE_MESSAGE', {message: data.title});
                } else if (data.type === "https://tools.ietf.org/html/rfc7231#section-6.6.1") {
                    if (data.detail !== undefined)
                        store.commit('FIRE_MESSAGE', {message: data.detail});
                } else
                    store.commit('FIRE_MESSAGE', {message: data});
            } /* else {
        store.commit('FIRE_MESSAGE', { message: error.message });
      } */
            return this.FormattedErrorResponse(error)
        }
    }

    static FormattedSuccessResponse(res) {
        return {
            type: 'Success',
            res
        }
    }

    static FormattedErrorResponse(res) {
        return {
            type: 'Error',
            res
        }
    }

}
