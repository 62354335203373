<template>

  <div v-if="!isRoot && !isRule && modelValue.items.length === 0" :class="{ 'pl-4': !isRoot, 'border-l-4 border-blue-100 pl-4 w-full center py-4': !isRoot }">
      <button @click.stop="addQuestion(modelValue)"> 
          <span class="font-nhu-regular fs-14 underline"> {{ $t('templates.question.add') }} </span>
      </button>
      |
      <button @click.stop="$emit('removeSection')" title="Borrar Item" class="static" >
        <span class="font-nhu-regular fs-14 underline"> {{ $t('templates.section.remove') }} </span>
      </button>
  </div>
    <draggable :list="modelValue.items" :itemKey="item => item.id" @end="fireUpdate" group="template" handle=".handle">
        <template #item="{element: item}">
            <div :class="{ 'pl-4': !isRoot, 'border-l-4 border-blue-100 pl-4': !isRoot }">
                <Popper class="w-full" placement="right" :show="item.id === selected?.id" offsetDistance="0" :z-index="0">
                    <div @click.stop="e => selectRow(item)" class="w-full flex bg-white mt-6"
                         :class="{
                            // 'border-gray-300': item.id !== selected?.id,
                            'border-gray-100 border shadow-md rounded-[8px]': !item.isSection
                         }">

                        <transition enter-active-class="transition ease-out duration-300"
                                    enter-from-class="transform opacity-0 scale-100"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-275"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-100">

                            <div v-if="!item.isSection" class="w-full px-4 py-1 rounded-[8px]">
                              <TemplateQuestion :model-value="item" @update:model-value="fireUpdate" @keyup-up="gotoPrevRow" @keyup-down="gotoNextRow" @keyup-enter="gotoNextRowOrCreate" />
                              <div class="h-0 border-b border-gray-50 my-4"/>
                              <div class="flex justify-end pb-4">
                                <div class="flex mr-4">
                                  <button @click.stop="openPopUpForItem(item)" class="mr-4 hidden" :title="$t('templates.addMedia')">
                                    <app-icon icon="paperclip_regular" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
                                  </button>
                                  <button @click.stop="duplicateQuestion(item)" class="mr-4" :title="$t('templates.duplicate')">
                                    <app-icon icon="copy_solid" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
                                  </button>
                                  <button @click.stop="removeSection(item)" :title="$t('templates.deleteItem')">
                                    <app-icon icon="trash_regular" class="fill-red-500" :width="20" :height="20"/>
                                  </button>
                                </div>
                                <div class="flex items-center border-l-2 border-gray-200">
                                  <span class="font-nhu-regular fs-14 mr-2 pl-4">{{$t('templates.question.required')}}</span>
                                  <app-checkbox :modelValue="item.isRequired" @update:modelValue="e => changeIsRequired(e, item)"/>
                                </div>
                              </div>
                            </div>
                            <div v-else class="w-full">
                                <div class="w-full flex p-2">
<!--                                    <ChevronRightIcon class="w-7 ml-1 cursor-pointer mx-3"-->
<!--                                                      :class="{ 'arrow-down': item.showItems }"-->
<!--                                                      aria-hidden="true" @click="toggleSection(item)"/>-->
                                  <app-icon :icon="'grip_vertical_solid'" class="fill-gray-500 handle " :width="20" :height="20"/>
                                  <input :id="'title-' + item.id" :value="item.title" @change="e => changeTitle(e, item)"
                                         @keyup.up="gotoPrevRow" @keyup.down="gotoNextRow" class="question-input font-nhu-regular fs-18 flex-grow ml-2"
                                         :placeholder="$t('templates.section.title')"  />
<!--                                  <app-textbox :model-value="item.title" :label="$t('templates.section.title')" @change="e => changeTitle(e, item)"/>-->
                                </div>
                                <TemplateSection :model-value="item" @update:model-value="fireUpdate" v-if="item.showItems" />
                            </div>
                        </transition>
                    </div>

                    <template #content>
                      <div class="flex p-4 justify-center items-center hidden" v-if="!item.isSection">
                        <div class="flex flex-col items-center justify-center shadow-md rounded-[8px] p-4">
                          <button @click.stop="addQuestion(modelValue, item)" :title="$t('templates.question.add')" class="mb-2">
                            <app-icon icon="circle_plus_regular" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
                          </button>
                          <button @click.stop="addSection(modelValue, item)" :title="$t('templates.section.add')" class="hidden">
                            <app-icon icon="text_size_regular" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
                          </button>
                        </div>
                      </div>
                    </template>
                </Popper>
                <app-pop-up-container :show="openPopUp && item.id === openPopUpItemId" @close="openPopUp = false">
                  <div>
                    <span class="font-nhu-semi-bold fs-14">
                      {{ $t('templates.images') }}
                    </span>
                    <app-image-list v-model="item.images"/>
                  </div>
                  <div class="mt-4">
                    <span class="font-nhu-semi-bold fs-14">
                      {{ $t('templates.files') }}
                    </span>
                    <app-file-list v-model="item.files"/>
                  </div>
                </app-pop-up-container>
            </div>
        </template>
    </draggable>
    <div class="w-full gap-4 flex my-4" v-if="isRoot"  >
        <button @click.stop="addQuestion(modelValue)" title="Agregar Pregunta" :class="{'pl-4': !isRoot }">
          <app-icon icon="circle_plus_regular" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
        </button>
        <button @click.stop="addSection(modelValue)" title="Agregar Seccion" class="hidden">
          <app-icon icon="text_size_regular" class="fill-gray-500 hover:fill-primary-500" :width="20" :height="20"/>
        </button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Popper from "vue3-popper";

import Draggable  from "vuedraggable";

export default {
    name: 'TemplateSection',
    components: {
      Popper,
      Draggable
    },
    emits:['update:model-value'],
    props: {
        modelValue: { type: Object, default: null, },
        isRoot: { type: Boolean, default: false, },
        isRule: { type: Boolean, default: false, },
    },

    data() {
        return {
          images: [],
          files: [],
          openPopUp: false,
          openPopUpItemId: null,
        }
    },
    watch: {
        selected(newItem)
        {
            if(newItem?.id === this.modelValue?.id)
            {
                const ele = document.getElementById('title-' + this.modelValue?.id)
                ele?.focus();
                ele?.select();
            }
        }
    },
    computed: {
        ...mapGetters({template: 'GET_TEMPLATE', selected: 'GET_ITEM_SELECTED', optionsets: 'GET_ALL_OPTIONSETS'}),
        items() {
            return {...this.modelValue.items};
        }
    },
    methods: {
        ...mapActions(['READ_TEMPLATE', 'UPDATE_TEMPLATE', 'UPDATE_ITEM_SELECTED', 'UPDATE_ITEM_SELECTED']),

        getPrevItem(item) {
            const index = this.modelValue.items.findIndex(x => x.id === item.id);
            if(index > 0)
                return this.modelValue.items[index - 1];
            else
                return null;
        },
        getNextItem(item) {
            const index = this.modelValue.items.findIndex(x => x.id === item.id);
            if(index + 1 < this.modelValue.items.length)
                return this.modelValue.items[index + 1];
            else
                return null;
        },


        gotoPrevRow() {
            const item = this.getPrevItem(this.selected);
            if(item !== null)
                this.UPDATE_ITEM_SELECTED(item);
        },

        gotoNextRow() {
            const item = this.getNextItem(this.selected);
            if(item !== null)
                this.UPDATE_ITEM_SELECTED(item);
            return item;
        },

        gotoNextRowOrCreate() {
            if(!this.gotoNextRow())
            {
                const newItem = this.addQuestion(this.modelValue)
                this.UPDATE_ITEM_SELECTED(newItem);
            }
        },

        fireUpdate(item) {
            // Verificar si el objeto es un CustomEvent y evitar clonarlo
            if (item instanceof CustomEvent) {
                return;
            }

            // código para manejar objetos válidos
            const newValue = Object.assign({}, this.modelValue);
            const index = newValue.items.findIndex(x => x.id === item.id);
            if (item.id !== this.modelValue.id)
                newValue.items[index] = item;
            this.$emit('update:model-value', newValue);
        },


        toggleSection(item) {
            item.showItems = !item.showItems

            this.fireUpdate(item);
        },

        changeTitle(event, item) {
            item.title = event.target.value?.trim();

            this.fireUpdate(item);
        },

        selectRow(item) {
            this.UPDATE_ITEM_SELECTED(item);
            return item;
        },


        addItem(item, newItem, prev) {
            if(prev !== null) {
                const index = item.items.findIndex(x => x.id === prev.id) + 1;
                item.items.splice(index, 0, newItem);
            }
            else
                item.items.push(newItem);

            this.fireUpdate(item);
            this.selectRow(newItem);

            return newItem;
        },

        addQuestion(item, prev = null) {
            return this.addItem(item, {
                id: Math.random().toString(36).substring(2, 15),
                title: '',
                isSection: false,
                questionType: this.optionsets.length > 0 ? 'optionset' : 'text',
                optionSetId: this.optionsets.length > 0 ? this.optionsets[0].id : null,
                isRequired: true,
                hasMultipleAnswers: false,
                answers: [{ id: Math.random().toString(36).substring(2, 15), value: '', isSelected: false, rule: { enabled: false } }],
                images: [],
                files: [],
                items: null,
            }, prev);
        },
        addSection(item, prev = null) {
            return this.addItem(item, {
                id: Math.random().toString(36).substring(2, 15),
                title: '',
                isSection: true,
                questionType: null,
                optionSetId: null,
                isRequired: null,
                showItems: true,
                items: [
                    {
                        id: Math.random().toString(36).substring(2, 15),
                        title: '',
                        isSection: false,
                        questionType: this.optionsets.length > 0 ? 'optionset' : 'text',
                        optionSetId: this.optionsets.length > 0 ? this.optionsets[0].id : null,
                        isRequired: true,
                        items: null,
                        hasMultipleAnswers: false,
                        answers: [{ id: Math.random().toString(36).substring(2, 15), value: '', isSelected: false, rule: { enabled: false } }],
                        images: [],
                        files: [],
                    }
                ],
            }, prev);
        },
        removeSection(item) {
            const prev = this.getPrevItem(item) ?? this.getNextItem(item);
            let newValue = Object.assign({}, this.modelValue);
            newValue.items = newValue.items.filter(x => x.id !== item.id)
            this.$emit('update:model-value', newValue);

            this.selectRow(prev);
        },
        changeIsRequired(e, item) {
          item.isRequired = e;
          this.fireUpdate(item);
        },
        duplicateQuestion(item) {
          let newItem = window.structuredClone(item);
          let duplicatedItem = this.changeItemIds(newItem);
          this.addItem(this.modelValue, duplicatedItem, null);
        },
        changeItemIds(item) {
          item.id = Math.random().toString(36).substring(2, 15);
          if(item.items) {
            for(let i = 0; i < item.items.length; i++)
              this.changeItemIds(item.items[i])
          }
          if(item.answers) {
            for(let i = 0; i < item.answers.length; i++)
              this.changeItemIds(item.answers[i])
          }
          return item;
        },
        openPopUpForItem(item) {
          this.openPopUpItemId = item.id;
          this.openPopUp = true;
        }
    },
    async created() {
      if (this.modelValue && this.modelValue.items && this.modelValue.items.length === 0) {
        this.addQuestion(this.modelValue);
      }
    },
}
</script>
