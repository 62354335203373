<template>

  <div class="flex flex-col absolute top-0 bottom-0 w-full">
    <div v-if="!isMobile" class="h-16 flex items-center align-middle text-purple border-r border-gray-100 bg-white"
      :class="{ 'justify-center': sidebarIcons }">
      <img class="h-10 mt-4" :class="{ 'mx-4': sidebarIcons }" :src="sidebarIcons ? '/images/z.png' : '/images/zigo.png'"
        alt="zigo" />
    </div>
    <div class="flex items-center align-middle text-purple border-r border-gray-100 bg-white px-4">
      {{ company?.name }}
    </div>
    <div class="flex-1 flex flex-col flex-grow bg-white border-r border-gray-100 overflow-y-auto">
      <nav class="flex-1 space-y-1 mt-2 py-2 justify-center px-4 overflow-y-auto" aria-label="Sidebar">
        <!-- Componente para transformar SVG al formato requerido, salida por consola
          <div>
            <input type="file" ref="archivos" multiple>
            <button @click="uploadFile">Cargar archivos</button>
          </div>-->
        <template v-for="item in navigation" :key="item.name">
          <template v-if="$hasPermission('read_' + item.id) || $hasPermission('read_my_' + item.id)">
            <hr v-if="item.separator" />
            <template
              v-if="item.name && $hasModule(item.module) && ($hasPermission('read_' + item.id) || $hasPermission('read_my_' + item.id))">
              <div v-if="!item.children">
                <button @click="clickMenu(item)" :class="[isCurrent(item) ? ' border-primary-500 text-primary-500 fill-primary-500 rounded-[8px] bg-primary-50' : 'text-gray-500 hover:text-primary-500 fill-gray-500 hover:fill-primary-500',
    !sidebarIcons || isMobile ? 'justify-start' : 'justify-center',
      'group w-full flex items-center p-3 medium rounded cursor-pointer font-nhu-regular fs-14']">
                  <div class="h-6 w-6 flex items-center justify-center"
                    :class="sidebarIcons && !isMobile ? '' : 'pr-1'">
                    <app-icon :icon="item.icon" :height="14" :width="14"
                      :extra-class="sidebarIcons && !isMobile ? '' : ''" />
                  </div>
                  <span v-if="sidebarIcons === false || isMobile">
                    {{ getLabel(item) }}
                  </span>
                  <!--                  <span v-if="sidebarIcons === false || isMobile">{{ $t(item?.name) }}</span>-->
                </button>
              </div>
              <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }" :defaultOpen="item.defaultOpen ?? true">
                <DisclosureButton
                  :class="[isCurrent(item) ? ' bg-gray-200' : ' text-gray-800 hover:bg-gray-50 hover:text-gray-900', 'group w-full flex items-center pl-4 pr-1 py-2 text-left rounded focus:outline-none focus:ring-2 focus:ring-purple-500']">
                  <component :is="item.icon" class="flex-shrink-0 w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true" />
                  <span class="flex-1">
                    {{ getLabel(item) }}
                  </span>
                  <svg
                    :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']"
                    viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                </DisclosureButton>
                <DisclosurePanel class="space-y-1">
                  <button v-for="subItem in item.children" :key="subItem.name" @click="clickMenu(subItem)"
                    :class="[isCurrent(subItem) ? 'border border-gray-200 text-purple' : 'border border-white text-purple hover:bg-gray-50 hover:text-gray-900', 'pl-11 group w-full flex items-center py-2 rounded']">
                    <component :is="subItem.icon"
                      :class="[isCurrent(item) ? 'bg-gray-200' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
                      aria-hidden="true" />
                    <span v-if="!sidebarIcons">{{ $t(subItem.name) }}</span>
                  </button>
                </DisclosurePanel>
              </Disclosure>
            </template>
          </template>
        </template>
      </nav>
    </div>

    <div class="h-24 px-4 border-r bg-white flex border-gray-100">
      <div :class="menu">
        <Menu as="div"
          class="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
          <div>
            <MenuButton class="flex">
              <span class="sr-only">
                {{ $t('openMenu') }}
              </span>

              <div v-if="user" class="relative inline-block">
                <user-avatar :user="user" width="w-12" height="h-12"></user-avatar>
              </div>
              <div v-if="!sidebarIcons || isMobile" class="flex-col flex ml-2">
                <span class="font-nhu-semi-bold text-[14px] text-start" aria-hidden="true"> {{ user?.name }}</span>
                <span class="font-nhu-regular text-[14px] text-start"
                  aria-hidden="true">{{ $t('crud.userMenu.settings') }}</span>
              </div>
            </MenuButton>
          </div>
          <transition enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">
            <Teleport to="body">
              <MenuItems
                class="absolute w-64 py-1 mt-2 origin-bottom-right bg-white rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                :class="sidebarIcons || isMobile ? 'left-[88px] bottom-4' : 'left-[265px] bottom-4'">
                <MenuItem v-for="item in userMenu" :key="item.name" v-slot="{ active }" class="z-50">
                <a href="#" @click="clickMenu(item)"
                  :class="[active ? 'bg-primary-50' : '', 'block px-4 py-2 text-gray-700']"
                  class="flex items-center w-full group font-nhu-regular">
                  <app-icon :icon="item.icon" :height="24" :width="24" extra-class="fill-gray-500 mr-2" />
                  {{ $t(item.name) }}
                </a>
                </MenuItem>
              </MenuItems>
            </Teleport>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Menu, MenuItems, MenuItem, MenuButton } from '@headlessui/vue'
import { ShoppingBagIcon, DocumentTextIcon, CogIcon, HomeIcon, EyeIcon, UserIcon } from '@heroicons/vue/24/outline'
// import menu from '@/menus/MainMenu'
import userMenu from '@/menus/UserMenu'
import UserAvatar from "@/components/UserAvatar";

export default {
  name: 'TheSidebar',
  emits: ["update:sidebarOpen", "update:sidebarIcons"],
  components: {
    UserAvatar, Disclosure, DisclosureButton, DisclosurePanel,
    Menu, MenuItems, MenuItem, MenuButton,
    ShoppingBagIcon, DocumentTextIcon, CogIcon, HomeIcon, EyeIcon, UserIcon
  },
  data() {
    return {
      hoverItem: null,
      labelsNoun: 'plural'
    }
  },
  props: {
    sidebarOpen: { type: Boolean, default: true },
    sidebarIcons: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({ user: 'GET_CURRENT_USER', company: 'GET_CURRENT_COMPANY', }),
    isMobile() {
      return this.$isMobile()
    },
    userMenu() {
      return userMenu.filter(x => (!x.isSysAdmin || this.user.isSysAdmin) && (x.needsPermission ? this.$hasPermission('read_' + x.id) : true));
    },
    menu() {
      return `flex items-center w-full border-t border-gray-100`;
    },
    menuItems() {
      const menu = this.$store.getters['GET_MAIN_MENU']
      return menu
    },
    navigation() {
      return this.menuItems.map(item => {
        return {
          ...item,
          color: '#767c86',
        }
      })
    }
  },
  methods: {
    ...mapActions(['READ_COMPANY']),
    getLabel(item) {
      if (this.company && this.company.recordLabels && item.id in this.company.recordLabels && this.company.recordLabels[item.id].pluralLabel)
        return this.company.recordLabels[item.id].pluralLabel;
      else
        return this.$t('records.' + item?.id + '.title');
    },

    async readFile(archivo) {
      return new Promise((resolve, reject) => {
        const lector = new FileReader();

        lector.onload = function (evento) {
          const contenido = evento.target.result;  // Contenido del archivo como una cadena de texto
          resolve(contenido);
        };

        lector.onerror = function (evento) {
          reject(evento.target.error);
        };

        lector.readAsText(archivo);  // Leer el archivo como texto
      });
    },
    async uploadFile() {
      const archivos = this.$refs.archivos.files;  // Obtener la lista de archivos seleccionados
      const formData = new FormData();  // Crear objeto FormData para enviar los archivos
      // let stringData = ""
      for (let i = 0; i < archivos.length; i++) {
        // console.log(i, archivos[i])
        formData.append('archivos[]', archivos[i]);
        if (archivos[i]) {
          await this.readFile(archivos[i])
            .then(() => {
              // const fileName = archivos[i].name.replaceAll(".svg", "").replaceAll("-", "_") + "_regular"
              // const viewboxInit = res.indexOf("viewBox=")
              // const viewboxEnd = res.indexOf('"', viewboxInit + 9)
              // stringData += "\n" + fileName + ': {\n\tviewbox: "' + res.substring(viewboxInit + 9, viewboxEnd) + '",\n\tpath: "' + res.substring(res.indexOf("d=") + 3, res.indexOf('"', res.indexOf("d=") + 3)) + '"\n},'
            });
        }
        // Agregar cada archivo al objeto FormData
      }
      // console.log(stringData)
    },
    toggleSidebar() {
      this.$emit('update:sidebarIcons', !this.sidebarIcons)
    },
    setColor(item) {
      item.color = this.$colors.primary[500]
    },
    clickMenu(item) {
      this.$emit('update:sidebarOpen', false)
      this.$router.push(item.href);
    },
    isSelected(x) {
      return this.$route.fullPath === x.href;
    },
    isCurrent(item) {
      return this.$route.fullPath.startsWith(item.href);
    },
    logout() {
      this.$router.push('/logout')
    }
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
