<template>
  <div class="flex flex-col justify-center items-center fill-primary-500 h-full w-full">
    <div class="w-[96px] h-[96px] bg-blue-50 rounded-full flex items-center justify-center">
      <app-icon :icon="record?.icon" :width="48" :height="48"/>
    </div>
    <div class="mt-6 mb-[32px]">
      <span class="font-nhu-regular" :class="$isMobile ? 'text-[14px]' : 'text-[30px]'">{{ isNotEmpty ? $t('notFound') : getLabel }}</span>
    </div>
    <div>
      <app-button v-if="!isMobile && $hasPermission('create' + '_' + record?.name) && !isNotEmpty"
                  class="ml-4 px-4 font-nhu-regular mr-8 sm:mr-0" @click="doCreate" type="primary" background="light"
                  :icon="PlusCircleIcon">
        <span class="block">{{ getCreateLabel }}</span>
      </app-button>
    </div>
  </div>
</template>

<script>
import CRUD from '@/crud'
import {PlusCircleIcon} from "@heroicons/vue/24/outline";
import {mapGetters} from 'vuex';

export default {
  name: "AppEmptyState",
  components: {},
  emits: [],
  props: {
    recordName: {
      type: String,
      default: null
    },
    isNotEmpty: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({record: 'GET_RECORD', company: 'GET_CURRENT_COMPANY', records: 'GET_RECORDS'}),
    isMobile() {
      return this.$isMobile()
    },
    getLabel() {
      if (this.recordName) {
        this.$store.commit('SET_RECORD', this.records[this.recordName])
      }
      let key = this.record.name;
      if (this.company && this.company.recordLabels && this.company.recordLabels[key]?.label) {
        let labelInfo = this.company.recordLabels[key];
        return this.$t('emptyStates.noResults.custom', {entity: labelInfo.label});
      } else {
        return this.$t('emptyStates.noResults.' + key);
      }
    },
    getCreateLabel() {
      if (this.recordName) {
        this.$store.commit('SET_RECORD', this.records[this.recordName])
      }
      let key = this.record.name;
      if (this.company && this.company.recordLabels && this.company.recordLabels[key]?.label) {
        let labelInfo = this.company.recordLabels[key];
        return this.$t('emptyStates.create.custom', {entity: labelInfo.label});
      } else {
        return this.$t('emptyStates.create.' + key);
      }
    },
  },
  methods: {
    PlusCircleIcon,
    doCreate() {
      this.$router.push(CRUD.getRecordCreateUrl(this.record));
    },
  }
}
</script>

<style scoped>

</style>
