<template>
  <Vue3EasyDataTable
    class="text-xl whitespace-nowrap rounded-[8px]"
    table-class-name="customize-table"
    :loading="loading"
    :rows-of-page-separator-message="$t('page-separator-message')"
    :rows-per-page="rowsPerPage"
    :hide-rows-per-page="true"
    buttons-pagination
    must-sort
    :headers="headers"
    :items="items"
    :server-items-length="count"
    :disabled="disabled"
    v-model:server-options="serverOptions"
    :items-selected="internalItemSelected"
    @selectRow="selectRow"
    @deselectRow="deselectRow"
    @click-row="clickRow">

    <template #customize-headers>
      <thead class="bg-gray-50 rounded-[20px]">
      <tr>
        <th v-for="header in headers" :key="header.value" scope="col" class="p-4 text-left bg-gray-50" @click="sortColumn(header.value)">
          <div class="flex items-center">
            <span class="font-nhu-regular fs-14 text-gray-900">{{ $t(header.text) }}</span>
            <div class="h-7 w-7 cursor-pointer ml-2" v-if="header.sortable">
              <app-icon v-if="header.value === serverOptions.sortBy" :icon="getIcon(header.value)" :width="14" :height="14"></app-icon>
              <app-icon v-else icon="sort_regular" :width="14" :height="14"></app-icon>
            </div>
          </div>
        </th>
      </tr>
      </thead>
    </template>

    <template #item-name="item">
      <tbody class="divide-y divide-gray-100">
      <tr>
        <td class="whitespace-nowrap px-3 py-4">
          <div class="font-nhu-regular fs-14 text-gray-900 w-full cursor-pointer" @click="clickRow(item)">
            {{ item.name }}
          </div>
        </td>
      </tr>
      </tbody>
    </template>

    <template #item-slug="item">
      <tbody class="divide-y divide-gray-100">
      <tr>
        <td class="whitespace-nowrap px-3 py-4">
          <div class="font-nhu-regular fs-14 text-gray-900 w-full cursor-pointer" @click="clickRow(item)">
            {{ item.slug }}
          </div>
        </td>
      </tr>
      </tbody>
    </template>

    <template #item-courseCount="item">
      <tbody class="divide-y divide-gray-100">
      <tr>
        <td class="whitespace-nowrap px-3 py-4">
          <div class="font-nhu-regular fs-14 text-gray-900 w-full cursor-pointer" @click="clickRow(item)">
            {{ item.courseCount }}
          </div>
        </td>
      </tr>
      </tbody>
    </template>

    <template #item-userCount="item">
      <tbody class="divide-y divide-gray-100">
      <tr>
        <td class="whitespace-nowrap px-3 py-4">
          <div class="font-nhu-regular fs-14 text-gray-900 w-full cursor-pointer" @click="clickRow(item)">
            {{ item.userCount }}
          </div>
        </td>
      </tr>
      </tbody>
    </template>

    <template v-for="name in $slots" v-slot:[name]="data">
      {{ name }}
      <slot :name="name" v-bind="data" />
    </template>
  </Vue3EasyDataTable>


</template>

<script>
import API from "@/api";
import Vue3EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

export default {
  name: "AppTable",
  emits: ["update:modelValue", "update:itemsSelected", 'clickRow'],
  components: { Vue3EasyDataTable },
  data() {
    return {
      loading: false,
      items: [],
      count: 0,

      serverOptions: {
        page: 1,
        rowsPerPage: 10,
        sortBy: this.sortBy,
        sortType: "desc",
      },
    };
  },
  props: {
    rowsPerPage: { type: Number, default: 10 },

    data: { type: Array, default: null },
    url: { type: String, default: "" },
    action: { type: String, default: null },
    getter: { type: String, default: null },

    modelValue: { type: [String, Array], default: null },
    value: { type: String, default: null },
    itemsSelected: { type: Array, default: null },
    multi: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    sortBy: { type: String, default: "" },
    columns: { type: Array, default() { return null; }, },
    filters: { type: Object, default() { return null; }, },
  },
  computed: {
    internalItemSelected: {
      get() {
        var selected = [];

        if (this.multi == false)
          return null;

        if (this.itemsSelected !== null) {
          if (this.value !== null)
            this.itemsSelected.forEach((x) =>
              selected.push(this.items.find((y) => y[this.value] === x))
            );
          else this.itemsSelected.forEach((x) => selected.push(x));
        }

        return selected;
      },
    },

    headers() {
      if (this.columns !== null) return this.columns;
      if (this.items && this.items.length > 0) {
        var list = [];
        Object.keys(this.items[0]).forEach((x) => {
          if (x !== "_id") list.push({ text: x, value: x, sortable: true });
        });
        return list;
      }
      return [];
    },
    getterData() {
      return this.$store.getters[this.getter];
    },
  },
  watch: {
    rowsPerPage() {
      if (this.serverOptions) this.serverOptions.rowsPerPage = this.rowsPerPage;
    },
    async serverOptions() {
      await this.load();
    },
    async filters() {
      await this.load();
    },
    async getter() {
      await this.load();
    },
    sortBy() {
      this.serverOptions.sortBy = this.sortBy;
    },
    getterData() {
      if (this.getterData) {
        this.items = this.getterData.data;
        this.count = this.getterData.total;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.loading = false;
    },
    data() {
      this.load();
    },
  },

  methods: {
    reset() {
      this.items = [];
      this.count = 0;
    },
    selectRow(item) {
      if (this.itemsSelected === null) return null;

      var items = [...this.itemsSelected];

      if (this.value !== null) items.push(item[this.value]);
      else items.push(item);

      this.$emit("update:itemsSelected", items);
    },

    deselectRow(item) {
      var items = [...this.itemsSelected];
      var val = this.value !== null ? item[this.value] : item;

      items = items.filter((x) => x !== val);

      this.$emit("update:itemsSelected", items);
    },
    getItemValue(item) {
      return item;
    },

    async load() {

      if (this.data !== null && this.data !== undefined) {
        this.items = this.data;
        this.count = this.data.length;
        this.serverOptions = null;
      } else if (this.action !== null && this.action !== undefined) {
        this.loading = true;
        if (typeof this.action === "string")
          this.$store.dispatch(this.action, {
            ...this.serverOptions,
            ...this.filters,
          });
        if (typeof this.action === "function")
        {
          this.loading = true;
          await this.action({ ...this.serverOptions, ...this.filters });
          this.loading = false;
        }
      } else if (this.url !== "" && this.url !== null && this.url !== undefined) {
        this.loading = true;
        const response = await API.Get(this.url, {
          ...this.serverOptions,
          ...this.filters,
        });
        if (response.type === "Success") {
          const localData = response.res.data;
          this.items = localData.data;
          this.count = localData.total;
        }
        this.loading = false;
      }
    },
    async clickRow(e) {
      var val = null;
      if (this.value) val = e[this.value];
      else val = e;

      this.$emit("update:modelValue", val);
      this.$emit("clickRow", val);

    },
    sortColumn(column) {
      if (this.serverOptions.sortBy === column) {
        this.serverOptions.sortType = this.serverOptions.sortType === 'asc' ? 'desc' : 'asc';
      } else {
        this.serverOptions.sortBy = column;
        this.serverOptions.sortType = 'asc';
      }

      this.load();
    },
    getIcon(column) {
      if (this.serverOptions.sortBy !== column) return 'sort_regular';
      switch (this.serverOptions.sortType) {
        case 'asc':
          return 'sort_up_solid';
        case 'desc':
          return 'sort_down_solid';
        default:
          return 'sort_regular';
      }
    },
  },
  async created() {
    await this.load();
  },
};
</script>

<style>
.customize-table {
  --easy-table-body-row-hover-background-color: transparent;
}
.buttons-pagination .item.button.active[data-v-4c681fa2] {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #96D5A2;
  border: unset;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.buttons-pagination .item[data-v-4c681fa2] {
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.buttons-pagination .item[data-v-4c681fa2]:first-of-type {
  border: unset;
}

</style>
