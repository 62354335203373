<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="relative flex items-center justify-center h-full p-4 text-center bg-black bg-opacity-50 min-h-fit"
             @mousedown.self="closePopUp">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100"
                           leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel :class="'relative overflow-hidden text-left transition-all transform bg-white rounded shadow-xl' + (image.length >= 1 ? ' max-w-[90rem] ' : ' w-11/12 sm:w-1/2')">
              <div class="w-full px-4 pt-5 pb-4">
                <span class="font-nhu-semi-bold text-[20px]"> {{ title }} </span>
                <div @click="closePopUp" class="absolute top-2 right-2 z-10 cursor-pointer">
                  <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray"/>
                </div>
              </div>
              <app-loading :loading="loading">
                <div v-if="image" class="flex justify-center mt-2 px-4 pb-4">
                    <app-image :src="image" alt="" rounded_type="md"/>
                </div>
                <div v-else class="mt-2 px-4 pb-4 ">
                  <slot></slot>
                </div>
              </app-loading>
              <div v-if="image.length >= 1" class="flex p-2 gap-2 justify-end">
                <app-button @click="downloadImage()" extra-class="text-white border-none">{{$t('download')}}</app-button>
              </div>
              <div v-else class="border-t flex p-2 gap-2">
                <div class="flex-1"></div>
                <slot name="footer"></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {XCircleIcon} from '@heroicons/vue/24/outline'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
export default {
  name: "AppPopUpContainer",
  components: {Dialog, DialogPanel, TransitionChild, TransitionRoot, XCircleIcon},
  emits: ['update:show', 'close'],
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: "" },
    message: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    image: { type: String, default: "" },
  },
  data() {
    return {}
  },
  methods: {
    closePopUp() {
      this.$emit('update:show', false)
      this.$emit('close')
    },
    downloadImage() {
      const imageUrl = 'https://content.zigo.la/images/public/' + this.image;

      let a = document.createElement('a');
      a.href = imageUrl;
      a.download = this.image;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  mounted() {}
}
</script>
