<template>
  <div>
    <div class="flex">
      <app-icon :icon="icon" :extra-class="iconColor + ' mr-2'" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ label }}</span>
    </div>
    <div v-for="user in users" :key="user.id" class="my-2 flex items-center">
      <div>
        <user-avatar :user="user" width="w-10" height="h-10"></user-avatar>
      </div>
      <div class="w-full flex items-center justify-between">
        <div class="flex flex-col mx-2">
          <span class="font-nhu-semi-bold fs-14">{{user.name}}</span>
          <span class="font-nhu-500 fs-14 text-gray-500">{{user.role}}</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="h-0 border-b border-gray-50 my-4"/>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "UserList",
  components: { UserAvatar },
  props: {
    users: {type: Array, required: true},
    label: {type: String, default: ''},
    icon: {type: String, default: ''},
    iconColor: {type: String, default: ''},
  }
};
</script>

<style scoped>
</style>
