<template>
  <div class="w-full">
    <app-icon icon="inbox_full_regular" width="20" height="20" class="fill-primary-500 font-nhu-semi-bold fs-14">
      <span class="ml-2">{{ $t('workorders.history') }}</span>
    </app-icon>
    <div v-if="getWorkOrders.length > 0">
      <div class="p-6 border-b-[1px] border-gray-100 flex flex-col" v-for="workOrder in getWorkOrders"
           :key="workOrder.id">
        <work-order-card :work-order="workOrder"/>
      </div>
    </div>
    <div v-else class="text-gray-700 pt-2 font-nhu-regular fs-14">
      {{ $t('workorders.noWorkOrders.' + record.name) }}
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import WorkOrderCard from "@/pages/workorders/WorkOrderCard";
import {mapGetters} from "vuex";

export default {
  name: "WorkOrderHistory",
  components: {WorkOrderCard, AppIcon},
  props: {
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      workOrders: []
    }
  },
  computed: {
    ...mapGetters({record: 'GET_RECORD'}),
    getWorkOrders() {
      return this.workOrders
    }
  },
  async mounted() {
    let wOrders = await this.$store.dispatch("READ_WORKORDERS_ON_HISTORY", {filters: this.filter})
    this.workOrders = wOrders.data ?? []
  }
}
</script>

<style scoped>

</style>
