import API from '@/api'

const state = {
  PART: {
    name: '',
    description: '',
    code: '',
    assetId: '',
    unitCost: 0,
    images: []
  },
  PARTS: {data: []},
  ALL_PARTS: []
}

const getters = {
  GET_PART: state => state.PART,
  GET_PARTS: state => state.PARTS,
  GET_ALL_PARTS: state => state.ALL_PARTS,
}

const mutations = {
  SET_PART(state, payload) {
    state.PART = payload
  },
  SET_ALL_PARTS(state, payload) {
    state.ALL_PARTS = payload
  },
  SET_PARTS(state, payload) {
    state.PARTS = payload
  },
  CREATE_PART(state, payload) {
    state.PART = payload
    state.PARTS.data.push(payload)
  },
  UPDATE_PART(state, payload) {
    if (state.PART !== null && state.PART.id === payload.id)
      state.PART = payload;

    const index = state.PARTS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.PARTS.data[index], payload)
  },
  DELETE_PART(state, payload) {
    if (state.PART?.id === payload.id)
      state.PART = {}
    state.PARTS.data = state.PARTS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_PARTS(context, payload) {
    const response = await API.Get('/api/parts', payload)
    if (response.type === 'Success') {
      context.commit('SET_PARTS', response.res.data)
      return response.res.data
    }
    context.commit('SET_PARTS', [])
  },
  async READ_ALL_PARTS(context) {
    const response = await API.Get('/api/options/parts')
    if (response.type === 'Success') {
      context.commit('SET_ALL_PARTS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_PARTS', [])
  },
  async CREATE_PART(context) {
    context.commit('SET_PART', {name: '', description: '', code: '', unitCost: 0, images: []})
  },
  async SAVE_PART(context, payload) {
    if (payload.id === undefined) {
      const response = await API.Post('/api/parts', payload)
      if (response.type === 'Success') {
        context.commit('CREATE_PART', response.res.data)
        return response.res.data;
      }
    } else {
      const response = await API.Put(`/api/parts/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_PART', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_PART(context, payload) {
    const response = await API.Get(`/api/parts/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_PART', response.res.data)
      return response.res.data
    }
  },
  async READ_PART_BY_ID(context, id) {
    const response = await API.Get(`/api/parts/${id}`)
    if (response.type === 'Success') {
      context.commit('SET_PART', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_PART(context, payload) {
    const response = await API.Put(`/api/parts/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_PART', response.res.data)
      return response.res.data
    }
  },
  async DELETE_PART(context, payload) {
    const response = await API.Delete(`/api/parts/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_PART', response.res.data)
      return
    }
  },
  async DELETE_PART_LIST(context, payload) {
    const response = await API.Patch('/api/parts', payload);
    if (response.type === 'Success') {
      context.commit('DELETE_PART', {payload});
      return true;
    }
    return false;
  },
  async EXPORT_PART_XLSX() {
    const response = await API.Post(`/api/parts/export-XLSX`)
    if (response.type === 'Success') {
      return response.res.data
    }
  },
  async IMPORT_PARTS(context, payload) {
    const response = await API.Post('/api/parts/import', payload)
    if (response.type === 'Success') {
      return response.res.data
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
