<template>
  <div @click="labelClick">
    <app-input-container ref="inputContainer" :id="id" :modelValue="modelValue" :label="label" v-slot="{focusin, focusout}">
      <google-autocomplete id="map" ref="address"
                           :placeholder="modelValue"
                           v-on:placechanged="getAddressData"
                           @focusin="focusin"
                           @focusout="focusout"
                           class="block mt-4 w-full !p-0 !bg-transparent focus:ring-0 sm:text-[14px] sm:leading-6 border-gray-200 text-gray-900 font-nhu-regular placeholder-gray-900">
      </google-autocomplete>
    </app-input-container>
  </div>
</template>

<script>
import GoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "AppAddress",
  components:  { GoogleAutocomplete },
  data: function () {
    return {
    };
  },
  watch: {
    modelValue(val){
      this.$refs.address.update(val);
    }
  },
  props: {
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: '',
    },
    lock: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "",
    },
  },
  emits:["update:modelValue"],
  methods: {
    getAddressData: function (addressData, placeResultData) {
        let address = placeResultData?.formatted_address ?? "";
        this.$emit("update:modelValue", address);
    },
    labelClick() {
      this.$refs.inputContainer.focusin();
      this.$refs.address.focus();
    },
    onChange(e) {
      this.$emit('update:modelValue', e.target.value)
    },
  },
};
</script>
