<template>
        <form action="#" class="relative">
            <div class="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                <label for="title" class="sr-only">Title</label>
                <input v-model="localIssue.title" type="text" name="title" id="title"
                       class="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
                       placeholder="Title"/>
                <label for="description" class="sr-only">Description</label>
                <textarea v-model="localIssue.description" rows="2" name="description" id="description"
                          class="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Write a description..."/>

                <div aria-hidden="true">
                    <div class="py-2">
                        <div class="h-9"/>
                    </div>
                    <div class="h-px"/>
                    <div class="py-2">
                        <div class="py-px">
                            <div class="h-9"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="absolute inset-x-px bottom-0">
                <div class="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
                    <Listbox as="div" v-model="assigned" class="flex-shrink-0">
                        <ListboxLabel class="sr-only">{{ $t('admin.issues.assign') }}</ListboxLabel>
                        <div class="relative">
                            <ListboxButton
                                    class="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                                <UserCircleIcon v-if="assigned.value === null"
                                                class="h-5 w-5 flex-shrink-0 text-gray-300 sm:-ml-1"
                                                aria-hidden="true"/>
                                <span :class="[assigned.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block']">{{assigned.value === null ? $t('admin.issues.assign') : assigned.name }}</span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions
                                        class="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption as="template" v-for="assignee in peopleList" :key="assignee.id"
                                                   :value="assignee" v-slot="{ active }">
                                        <li :class="[active ? 'bg-gray-100' : 'bg-white', 'relative cursor-default select-none px-3 py-2']">
                                            <div class="flex items-center">
                                                <span class="ml-3 block truncate font-medium">{{ assignee.name }}</span>
                                            </div>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>

                    <Listbox as="div" v-model="labelled" class="flex-shrink-0">
                        <ListboxLabel class="sr-only">{{ $t('admin.issues.site') }}</ListboxLabel>
                        <div class="relative">
                            <ListboxButton
                                    class="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                                <TagIcon
                                        :class="[labelled.value === null ? 'text-gray-300' : 'text-gray-500', 'h-5 w-5 flex-shrink-0 sm:-ml-1']"
                                        aria-hidden="true"/>
                                <span :class="[labelled.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block']">{{labelled.value === null ? $t('admin.issues.site') : labelled.name }}</span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions
                                        class="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption as="template" v-for="label in sites" :key="label.value"
                                                   :value="label" v-slot="{ active }">
                                        <li :class="[active ? 'bg-gray-100' : 'bg-white', 'relative cursor-default select-none px-3 py-2']">
                                            <div class="flex items-center">
                                                <span class="block truncate font-medium">{{ label.name }}</span>
                                            </div>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
                <div class="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
                    <div class="flex flex-col">
                        <span class="text-sm italic text-gray-500 group-hover:text-gray-600">Agregar fotos</span>
                    </div>
                    <div class="flex-shrink-0">
                        <button @click="doCancel"
                                class="inline-flex items-center rounded-md bg-white
                                    px-3 py-2 text-sm font-semibold text-primary shadow-sm
                                    focus:outline-none border border-primary mx-1">
                            {{ $t('cancel') }}
                        </button>
                        <button @click.prevent="doSave" :disabled="!allowSave"
                                class="inline-flex items-center rounded-md bg-primary
                                    px-3 py-2 text-sm font-semibold text-white hover:text-primary shadow-sm hover:bg-primary-light-purple focus-visible:outline
                                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mx-1">
                            {{ $t('save') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <app-image-list v-model="localIssue.media"></app-image-list>
</template>

<script>
import {
  TagIcon,
  UserCircleIcon
} from "@heroicons/vue/24/outline"

import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IssueInput",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    TagIcon,
    UserCircleIcon
  },
  emits: ['update-issue'],
  data() {
    return {
      localIssue: {...this.issue},
      assignees: [
        {name: 'Unassigned', value: null},
      ],
      labels: [
        {name: 'Unlabelled', value: null},
      ],
      assigned: null,
      labelled: null,
    }
  },
  props: {
    issue: {
      type: Object,
      required: true
    },
  },
  watch: {
    assigned(newAssignee) {
      if (newAssignee) {
        this.localIssue.peopleId = newAssignee.id;
      } else {
        this.localIssue.peopleId = null;
      }
    },
    labelled(newLabel) {
      if (newLabel) {
        this.localIssue.siteId = newLabel.id;
      } else {
        this.localIssue.siteId = null;
      }
    }
  },
  computed: {
    ...mapGetters({peopleList: 'GET_ALL_PEOPLE', sites: 'GET_ALL_SITES'}),
    allowSave() {
      return this.localIssue.title !== ''
    },
  },
  methods: {
    ...mapActions(['SAVE_ISSUE']),

    async doSave() {
      this.saving = true;
      await this.SAVE_ISSUE(this.localIssue)
      this.assigned = this.assignees[0]
      this.labelled = this.labels[0]
      this.show = false;
      this.showCategory = true;
      this.saving = false;
      this.$emit('update-issue')
    },
    doCancel() {
      this.show = false;
      this.showCategory = true;
      this.assigned = this.assignees[0]
      this.labelled = this.labels[0]
      this.$emit('update-issue')
    },
  },
  async created() {
      this.loading = true;
      this.action = 'create';
      this.localIssue.status = 'opened'
      this.assigned = this.assignees[0]
      this.labelled = this.labels[0]
      this.loading = false;
  }
}
</script>

