<template>
  <div @click="labelClick">
            <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled"
                                 v-slot="{focusin, focusout} " :expand="true">
                <textarea
                    ref="textarea"
                    :id="id"
                    :value="modelValue"
                    @change="onChange"
                    @focusin="focusin"
                    @focusout="focusout"
                    @keydown="resize"
                    @keyup="resize"
                    :disabled="disabled"
                    style="overflow:hidden;"
                    required
                    class="!p-0 -mt-4 w-full min-h-[20px] !bg-transparent focus:ring-0 sm:text-[14px] sm:leading-6 border-gray-200 text-gray-900 font-nhu-regular resize-none">
                </textarea>
            </app-input-container>
  </div>
  <div v-if="suppText !== ''" class="flex items-center mt-2">
    <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-gray-300"/>
    <span class="relative block text-xs font-nhu-regular text-gray-300 cursor-text">{{ suppText }}</span>
  </div>
</template>

<script>
import {InformationCircleIcon} from "@heroicons/vue/24/outline"

export default {
  name: "AppTextarea",
  components: {InformationCircleIcon},
  props: {
    id: {type: String, default: null},
    type: {type: String, default: 'text'},
    modelValue: {type: [Number, String], default: ""},
    maxChars: {type: Number, default: 0},
    label: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    invalid: {type: Boolean, default: false},
    error: {type: String, default: ""},
    suppText: {type: String, default: ""}
  },
  emits: ["update:modelValue", 'enter'],
  data() {
    return {
      lastScrollHeight: null
    }
  },
  computed: {
    hasValue() {
      return this.modelValue !== ''
    }
  },
  methods: {
    resize() {
      const {textarea} = this.$refs;
      if (textarea) {
        textarea.style.height = 'auto';
        let H = textarea.scrollHeight;
        if (H < 46) H = 46;
        textarea.style.height = `${H}px`;
      }
    },
    resizeAndChange(e) {
      this.resize();
      this.onChange(e);
    },
    labelClick() {
      this.$refs.textarea.focus();
    },
    onChange(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    handleFocusIn() {
      this.resize();
    },
    onEnter() {
      if (this.enter) {
        this.$emit('enter');
      }
    }

  },
  created() {

  }
};
</script>
