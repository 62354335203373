<template>
  <div class="flex flex-col">
    <div v-for="(option, index) in options" :key="index" class="mb-4">
      <div class="flex items-center">
        <div class="flex items-center w-full">
<!--          <input type="checkbox" v-model="option.isSelected" class="mr-2 rounded-full border-gray-700 border w-5 h-5" @change="updateIsSelected(option)"> -->
          <input type="checkbox" :disabled="true" class="mr-2 rounded-full border-gray-700 border w-5 h-5">
          <input v-model="option.value" class="question-input font-nhu-regular fs-14 flex-grow ml-2" :placeholder="$t('templates.question.writeHereYourAnswer')">
        </div>

        <div class="flex">
          <div v-if="option.rule.enabled" class="flex items-center mr-5 cursor-pointer hover:fill-primary-500 fill-gray-500 hover:text-primary-500" @click="addRequestToOption(option, true)">
            <app-icon v-if="option?.rule?.name?.trim().length > 0" icon="check_regular" class="hover:fill-primary-500 mr-1" :width="14" :height="14"/>
            <app-icon v-else icon="plus_regular" class="hover:fill-primary-500 mr-1" :width="14" :height="14"/>
            <span class="font-nhu-regular w-max fs-14">{{$t('templates.question.fireRequest')}}</span>
          </div>
          <app-checkbox v-model="option.rule.enabled" @update:modelValue="(e) => e && addRequestToOption(option, e)" />
          <div class="flex items-center">
            <button :title="$t('deleteItem')" @click="deleteItem(option)">
              <app-icon icon="trash_regular" class="fill-gray-500 hover:fill-primary-500 ml-1" :width="20" :height="20"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <button @click="addOption" class="font-nhu-regular fs-14 text-primary-500 ml-2"> {{$t('templates.question.addOption')}} </button>
    </div>
<!--    <div class="mt-4 flex items-center">-->
<!--      <div class="mr-2">-->
<!--        <app-checkbox :modelValue="item.hasMultipleAnswers" @update:modelValue="e => changeHasMultipleAnswers(e, item)"/>-->
<!--      </div>-->
<!--      <span class="font-nhu-regular fs-14 "> {{$t('templates.question.multipleAnswers')}} </span>-->
<!--    </div>-->
  </div>
  <app-pop-up-container :show="isPopUpOpen" @close="isPopUpOpen = false">
    <div>
      <app-textbox v-model="currentOption.rule.name" :label="$t('requests.name')" />
      <app-textarea v-model="currentOption.rule.description" :label="$t('requests.description')" />
    </div>
    <template #footer>
      <app-button @click="isPopUpOpen = false" :clean="true" type="terciary"
                  background="light"
                  extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
        {{ $t('cancel') }}
      </app-button>
      <app-button @click="saveRequest" type="secondary" background="light"
                  extra-class="font-nhu-500 fs-14 text-white border-primary-500 bg-primary-500 px-4 py-3 rounded-2 border-[1px]">
        {{ $t('save') }}
      </app-button>
    </template>
  </app-pop-up-container>
</template>

<script>
import AppPopUpContainer from "@/components/AppPopUpContainer.vue";
import AppButton from "@/components/AppButton.vue";

export default {
  name: "MultipleOptions",
  emits: ['updateAnswers', 'updateHasMultipleAnswers'],
  components: {AppButton, AppPopUpContainer},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: [],
      multipleAnswers: false,
      isPopUpOpen: false,
      currentOption: null,
    };
  },
  methods: {
    addOption() {
      this.options.push({ id: Math.random().toString(36).substring(2, 15), value: '', isSelected: false, rule: { enabled: false } });
      this.$emit('updateAnswers', this.options);
    },
    changeHasMultipleAnswers(e, item) {
      item.hasMultipleAnswers = e;
      if (!e) {
        this.options.forEach((o) => {
          o.isSelected = false;
        });
      }
      this.$emit('updateHasMultipleAnswers', item);
    },
    deleteItem(option) {
      this.options = this.options.filter((o) => o.id !== option.id);
      this.$emit('updateAnswers', this.options);
    },
    updateIsSelected(option) {
      if (!this.item.hasMultipleAnswers) {
        this.options.forEach((o) => {
          if (o.id !== option.id) {
            o.isSelected = false;
          }
        });
      }
      this.$emit('updateAnswers', this.options);
    },
    addRequestToOption(option) {
      this.currentOption = option;
      if (!this.currentOption.rule) {
        this.currentOption.rule = { name: '', description: '' };
      }
      this.isPopUpOpen = true;
    },
    saveRequest() {
      this.isPopUpOpen = false;
      const index = this.options.findIndex(o => o.id === this.currentOption.id);
      if (index !== -1) {
        this.options[index] = this.currentOption;
      }
      this.$emit('updateAnswers', this.options);
    },
  },
  mounted() {
    this.options = window.structuredClone(this.item.answers);
  }
};
</script>

<style>
.question-input,
select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
}
</style>
