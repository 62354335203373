<template>
 <li class="pb-10 relative">
  <div v-show="!last" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
  <div class="relative flex items-start group">
    <span v-show="!active && !done" class="h-9 flex items-center" aria-hidden="true">
      <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
        <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
      </span>
    </span>
    <span v-show="active && !done" class="h-9 flex items-center" aria-hidden="true">
      <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
        <span class="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
      </span>
    </span>
    <span v-show="done" class="h-9 flex items-center">
      <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
        <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
      </span>
    </span>
    <span class="ml-4 min-w-0 flex flex-col w-full">
      <span class="text-sm font-medium text-gray-500 w-1/2"> {{label}} </span>
      <span class="text-sm text-gray-500"> {{description}} </span>

      <div v-show="active && !done" class="mt-8 space-y-2">
        <slot></slot>
      </div>
    </span>
  </div>   
</li>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/24/solid'
export default {
  name: "AppStepper",
  components: {CheckIcon},
  emits: [],
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    done: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    }, 
  },
  methods: {
  },
  computed: {
  }
};
</script>