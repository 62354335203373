
<script>
import AppCrudList from '@/crud/AppCrudList'

export default {
  name: 'AssetListPage',
  extends: AppCrudList,  
}
</script>

