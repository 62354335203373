/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{html,js}",
    "./index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      fontFamily: {
        'nhu-regular': ['neue-haas-unica-regular', 'sans-serif'],
        'nhu-light': ['neue-haas-unica-light', 'sans-serif'],
        'nhu-semi-bold': ['neue-haas-unica-semi-bold', 'sans-serif'],
        'nhu-500': ['neue-haas-unica-500', 'sans-serif'],
        'nhu-bold': ['neue-haas-unica-bold', 'sans-serif'],
        'nhu-extra-light': ['neue-haas-unica-extra-light', 'sans-serif'],
        'inter-regular': ['Inter', 'serif'],
      },
      colors: {
        'primary': {
          25: '#EEEFFD',
          50: '#eeeffd',
          100: '#cbcdf8',
          200: '#b2b5f5',
          300: '#8e94f1',
          400: '#797fee',
          500: '#575fea',
          600: '#4f56d5',
          700: '#3e43a6',
          800: '#303481',
          900: '#252862',
        },
        'red':{
          50: '#fbeeed',
          100: '#f1cac6',
          200: '#eab0aa',
          300: '#e18c83',
          400: '#db756b',
          500: '#d25346',
          600: '#bf4c40',
          700: '#953b32',
          800: '#742e27',
          900: '#58231d',
        },
        'gray': {
          25: '#F1F2F3',
          50: '#F1F2F3',
          100: '#d5d6d9',
          200: '#c0c3c7',
          300: '#a3a7ae',
          400: '#91969e',
          500: '#767c86',
          600: '#6b717a',
          700: '#54585f',
          800: '#41444a',
          900: '#323438',
        },
        'blue': {
          25: '#EEEFFD',
          50: '#eeeffd',
          100: '#cbcdf8',
          200: '#b2b5f5',
          300: '#8e94f1',
          400: '#797fee',
          500: '#575fea',
          600: '#4f56d5',
          700: '#3e43a6',
          800: '#303481',
          900: '#252862',
        },
        'green': {
          25: '#E8F6EB',
          50: '#E8F6EB',
          100: '#B8E2C0',
          200: '#96D5A2',
          300: '#66C178',
          400: '#49B55D',
          500: '#1BA335',
          600: '#199430',
          700: '#137426',
          800: '#0F5A1D',
          900: '#0B4416',
        },
        'yellow': {
          50: '#fdf7ed',
          100: '#fae7c6',
          200: '#f7dcab',
          300: '#f4cb84',
          400: '#f1c16d',
          500: '#eeb248',
          600: '#d9a242',
          700: '#a97e33',
          800: '#836228',
          900: '#644b1e',
        },
      },
      spacing: {
        '128': '32rem',
        '144': '36rem',
        '90-vh': '90vh',
        '0.4em': '0.4em'
      },
      borderRadius: {
        '4xl': '2rem',
      },
      height: {
        '100vh-h-7': 'calc(100vh - 3rem)',
      }
    }
  },
  plugins: [
    require('@tailwindcss/forms'),
  ],
}
