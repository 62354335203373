<template>
  <app-crud ref="crud"  v-slot="{ item }">
    <Teleport to="#footer">
      <app-button @click="createInspection(item)">Crear Inspeccion</app-button>
    </Teleport>

    <div class="min-h-full">
      <div class="flex gap-4 pb-4">
<!--        <div v-if="item.images?.length" class="w-36">-->
<!--          <app-image :src="item.images"  />-->
<!--        </div>-->
        <div class="w-1/2">
<!--          <div>-->
<!--            <span class="template-title my-3 p-1 font-nhu-bold fs-16 w-full">{{item.name}}</span>-->
<!--          </div>-->
          <span class="template-title my-3 p-1 font-nhu-regular fs-14 w-full">{{item.description}}</span>
        </div>
      </div>

      <div>
        <template v-for="(page) in item.pages" :key="page.id">
          <div>
<!--            <div class="sm:flex sm:items-center">-->
<!--              {{ index + 1 }}.-->
<!--              <span class="template-title my-3 p-1 font-bold w-1/2">{{page.title}}</span>-->
<!--            </div>-->

            <div class="w-11/12">
<!--              <div class="w-full flex bg-gray-200 p-4 rounded-t-xl">-->
<!--                <div class="question-column">{{ $t('question') }}</div>-->
<!--                <div class="answer-type-column">{{ $t('questionType') }}</div>-->
<!--              </div>-->

              <div :class="{ 'w-full flex': true }">
                <div class="w-full">
                  <TemplateSubItemsView
                      v-for="item in page.items"
                      :key="item.id"
                      :item="item"
                      :level="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="flex w-full items-center my-6">
        <work-order-history :filter="getFilter"/>
      </div>
    </div>
  </app-crud>
</template>

<script>
// import AppImage from "@/components/AppImage.vue";
import TemplateSubItemsView from "@/pages/templates/TemplateSubItemsView.vue";
import {mapActions} from "vuex";
import WorkOrderHistory from "@/pages/workorders/WorkOrderHistory.vue";

export default {
  name: "TemplateViewPage",
  components: {
    WorkOrderHistory,
    TemplateSubItemsView,
    // AppImage
  },
  data() {
    return {}
  },
  computed: {
    getFilter() {
      return {
        templateId: [this.$route.params.id]
      }
    }
  },
  methods: {
    ...mapActions(['CREATE_INSPECTION', 'SAVE_INSPECTION']),

    async createInspection(item) {
      this.CREATE_INSPECTION();
      let inspection = await this.SAVE_INSPECTION({ templateId: item.id });
      this.$router.push('/workorders/' + inspection.workOrderId);
    }
  },
  mounted() {
    this.CREATE_INSPECTION()
  }
}
</script>

<style scoped>
.question-column {
  width: 60%;
}
</style>
