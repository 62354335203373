<template>
  <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('roles.name')" />

    <app-label value=" " :label="$t('records.roles.fields.permissions')">
      <div>
        <div>
          <div v-for="([name, perms]) in Object.entries(modules)" :key="name" class="mb-4">
            <div class="text-2xl border-b w-full">{{  $getRecordLabel(name, 'plural') ?  $getRecordLabel(name, 'plural') : $t('modules.' + name) }}</div>

            <div class="grid grid-cols-3">
              <div v-for="perm in perms" :key="perm.id" class="w-full">
                <div class="flex items-center my-2">
                  <div>
                    <app-checkbox :model-value="item?.permissions?.includes(perm.id)" @update:modelValue="togglePermission(perm.id, item, $event)" />
                  </div>
                  <span class="font-nhu-regular text-gray-900 fs-14 ml-2">{{ perm.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-label>

    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RolesEditPage",
  components: { },
  data() {
    return {
      errorMsg: '',
      validated: false,
    }
  },
  computed: {
    ...mapGetters({ permissions: 'GET_PERMISSIONS'}),
    modules() {
      return Object.groupBy(this.permissions, x=> x.module);
    },
  },
  methods: {
    onValidate({item, onSuccess, onError}) {
      if (!item.name) {
        this.errorMsg = this.$t('roles.errors.name');
        onError();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
    togglePermission(permission, item, checked) {
      if (!item.permissions) {
        item.permissions = [];
      }

      if (checked) {
        if (!item.permissions?.includes(permission)) {
          item.permissions?.push(permission);
        }
      } else {
        const index = item.permissions?.indexOf(permission);
        if (index > -1) {
          item.permissions.splice(index, 1);
        }
      }
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
