<template>
    <div class="fixed top-0 left-0 right-0 z-50 flex justify-between flex-1 text-purple border-b-2 p-2 bg-white">
        <ArrowLeftIcon @click="navigateToList" class="w-6 h-6 mr-4 m-auto cursor-pointer" />
        <div class="m-auto text-purple "> {{$t('templates.title')}} </div>

        <div class="flex flex-1">
        </div>
        <app-button @click="doUndo" :disabled="history.length === 0" type="secondary" background="light" v-if="!loading">
            <ArrowUturnLeftIcon class="w-5 h-5" />
            {{  this.history.length }}
        </app-button>
        <div class="flex items-center ml-4 md:ml-6">
            <div class="mr-2">
                {{ saveStatus }}
            </div>
            <div class="mr-2" v-if="template.pendingPublish">
                Sin publicar
            </div>
            <div class="mr-2" v-if="template.templatePublishId !== null">
                Ultima Publicación: {{ publishTime }}
            </div>
            <app-button @click="doPublish" type="primary" background="light" :disabled="!template.pendingPublish">
                {{ template.pendingPublish ? $t('templates.publish.pending') : $t('templates.publish.published') }}
            </app-button>
        </div>
    </div>
    <app-loading :loading="loading" class="w-full"/>
    <div @click="handleClickOutside" class="mt-16 pt-4 p-16 min-h-full">
        <div class="flex gap-4 pb-4">
            <app-image-list v-model="template.images"  @change="doSave" :onlyOneImage="true" />
            <div class="w-1/2">
                <input v-model="template.name" @change="doSave" class="template-title my-3 p-1 font-bold bg-transparent focus:bg-white w-full" placeholder="Titulo"/>
                <input v-model="template.description" @change="doSave" class="template-title my-3 p-1 font-bold bg-transparent focus:bg-white w-full" placeholder="Agrega una descripnion"/>
            </div>
        </div>

        <!-- Añadir un contenedor para todas las tablas -->
        <Draggable :list="template.pages" :itemKey="page => page.id" @change="updatePageOrder" handle=".handle">
            <template #item="{element: page, index}">
                <div>
                    <div class="sm:flex sm:items-center">
                        <bars3-icon class="h-6 w-6 mx-3 handle" aria-hidden="true" />
                        {{ index + 1 }}.
                        <input v-model="page.title" @change="doSave" class="template-title my-3 p-1 font-bold w-1/2 bg-transparent focus:bg-white" placeholder="Titulo de la Pagina"/>
                        <app-button @click="removePage(index)" type="danger" background="light" title="Eliminar Pagina" v-if="index > 0">
                            <TrashIcon class="w-6 h-6 mr-4 m-auto" />
                        </app-button>
                        <app-button @click="duplicatePage(index)" type="danger" background="light" title="Copiar Pagina">
                            <DocumentDuplicateIcon class="w-6 h-6 mr-4 m-auto" />
                        </app-button>
                    </div>

                    <div class="w-3/4">
                        <div class="w-full border border-gray-300 flex bg-gray-200 rounded-t-xl p-4">
                            <div class="question-column">{{ $t('pregunta') }}</div>
                            <div class="answer-type-column">{{ $t('tipo-de-respuesta') }}</div>
                        </div>
                        <TemplateSection :model-value="page" @update:model-value="changePage"  :isRoot="true" />
                    </div>

                    <div class="h-4" />
                </div>
            </template>
        </Draggable>



        <button @click="doCreatePage" class="p-4 underline">
            {{ $t('admin.templates.page.create') }}
        </button>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations} from 'vuex'
import { ArrowLeftIcon, TrashIcon, DocumentDuplicateIcon, ArrowUturnLeftIcon } from '@heroicons/vue/24/outline'
import Draggable  from "vuedraggable";
import moment from 'moment';

export default {
    name: 'TemplateEditorPage',
    components: { ArrowLeftIcon, TrashIcon, DocumentDuplicateIcon, ArrowUturnLeftIcon, Draggable},
    data() {
        return {
            action: 'create',
            saveStatus: '',
            loading: true,
            history:[],
            showDeletePopUp: false,
            selectedRowIndex: null,
        }
    },
    watch: {
        filter()
        {
            this.load();
        },
    },
    computed: {
        ...mapGetters({template: 'GET_TEMPLATE'}),
        publishTime() {
            return moment(String(this.template.templatePublishDate)).format('MM/DD/YYYY hh:mm')
        }

    },
    methods: {
        ...mapMutations(['SET_TEMPLATE']),
        ...mapActions(['READ_ALL_OPTIONSETS', 'READ_TEMPLATE', 'UPDATE_TEMPLATE', 'PUBLISH_TEMPLATE', 'UPDATE_ITEM_SELECTED']),

        navigateToList()
        {
            this.$router.push('/templates');
        },

        changePage(page)
        {
            const index = this.template.pages.findIndex(x => x.id === page.id);
            this.template.pages[index] = page;

            this.doSave();
        },

        doCreatePage() {
            this.template.pages.push({
                id: Math.random().toString(36).substring(2, 15),
                title: 'Pagina sin titulo',
                items: [
                    {
                        id: Math.random().toString(36).substring(2, 15),
                        title: '',
                        isSection: false,
                        questionType: 'text',
                        isRequired: true,
                        items: null,
                    }
                ],
            });

            this.doSave();
        },
        removePage(index) {
            this.template.pages.splice(index, 1);

            this.doSave();
        },
        duplicatePage(index) {
            var newValue = JSON.parse(JSON.stringify(this.template.pages[index]));
            this.changeItemIds(newValue);

            this.template.pages.splice(index, 0, newValue);
            this.doSave();
        },

        changeItemIds(item)
        {
            item.id = Math.random().toString(36).substring(2, 15);
            if(item.items)
            {
                for(var i = 0; i < item.items.length; i++)
                    this.changeItemIds(item.items[i])
            }
        },



        handleClickOutside() {
            this.UPDATE_ITEM_SELECTED(null);
        },


        async doUndo()
        {
            if(this.history.length > 0)
            {
                var o = this.history.pop();
                this.prevTemplate = structuredClone(o)
                this.SET_TEMPLATE(o);
                await this.UPDATE_TEMPLATE(this.course)
            }
        },

        async doSave() {
            this.saveStatus = 'Grabando...'
            this.history.push(this.prevTemplate)
            this.prevTemplate = structuredClone(this.template)

            await this.$nextTick();
            this.UPDATE_TEMPLATE(this.template)
                .then(() => this.saveStatus = 'Grabado');
        },

        async doPublish() {
            this.loading = true;
            this.saveStatus = ''
            await this.PUBLISH_TEMPLATE(this.template)
            this.loading = false;
        },
        updatePageOrder() {
            this.doSave();
        }
    },
    async created() {
        await this.READ_ALL_OPTIONSETS();
        await this.READ_TEMPLATE({id: this.$route.params.id})
        this.prevTemplate = structuredClone(this.template)
        this.loading = false;
    },
}
</script>

<style>
.handle {
    cursor: move;
}

.question-column {
    width: 60%;
}

.answer-type-column {
    width: 40%;
}

.question-input,
select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
}

.selected-row {
    position: relative;
}



/* Estilo personalizado para el selector */
.select-wrapper {
    position: relative;
    display: block;
    width: 100%;
}

.custom-select {
    appearance: none;
    width: 100%;
    padding-right: 2rem; /* Espacio adicional a la derecha para el ícono de la flecha */
}

.select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #4a5568;
    pointer-events: none; /* Evita que el pseudo-elemento interfiera con los eventos del mouse en el selector */
}

.floating-delete {
    position: absolute;
    width: fit-content;
    z-index: 10;
}

.add-page-button {
    text-decoration: underline;
    color: #7e8083;
    border: 1px solid #7e8083;
    padding: 5px;
    border-radius: 50px;
    font-size: 12px;
}
.btn-add-question {
    float: right;
    margin-left: auto;
}
.section-input {
    padding: 0;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
}
.inner-table {
    width: 100%;
}
.inner-row td {
    padding-left: 20px; /* Agrega un margen izquierdo a las celdas de las filas internas */
    border-width: 2px; /* Aumenta el ancho del borde */
}
.arrow-down {
    transform: rotate(90deg);
}
</style>
