import API from '@/api'

const state = {
  CONVERSATION: {
    name: '',
    image: '',
    members: [],
    type: null
  },
  CONVERSATIONS: { data:[] },
  ALL_CONVERSATIONS: []
}

const getters = {
  GET_CONVERSATION: state => state.CONVERSATION,
  GET_CONVERSATIONS: state => state.CONVERSATIONS,
  GET_ALL_CONVERSATIONS: state => state.ALL_CONVERSATIONS,
}

const mutations = {
  // CONVERSATIONS
  SET_CONVERSATION(state, payload) {
    state.CONVERSATION = payload
  },
  SET_ALL_CONVERSATIONS(state, payload) {
    state.ALL_CONVERSATIONS = payload
  },
  SET_CONVERSATIONS(state, payload) {
    state.CONVERSATIONS = payload
  },
  CREATE_CONVERSATION(state, payload) {
    state.CONVERSATION = payload
    state.CONVERSATIONS.data.push(payload)
  },
  UPDATE_CONVERSATION(state, payload) {
    if (state.CONVERSATION !== null && state.CONVERSATION.id === payload.id)
      state.CONVERSATION = payload;

    const index = state.CONVERSATIONS.data.findIndex(e => e.id === payload.id)
    if (index !== -1) {
      Object.assign(state.CONVERSATIONS.data[index], payload)
    }
  },
  DELETE_CONVERSATION(state, payload) {
    if (state.CONVERSATION?.id === payload.id)
      state.CONVERSATION = {}
    state.CONVERSATIONS.data = state.CONVERSATIONS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD COURSES
  async READ_CONVERSATIONS(context, payload) {
    const response = await API.Get('/api/conversations', payload)
    if (response.type === 'Success') {
      context.commit('SET_CONVERSATIONS', response.res.data)
      return response.res.data
    }
    context.commit('SET_CONVERSATIONS', [])
  },
  async READ_ALL_CONVERSATIONS(context) {
    const response = await API.Get('/api/conversations/all')
    if (response.type === 'Success') {
      context.commit('SET_ALL_CONVERSATIONS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_CONVERSATIONS', [])
  },
  async CREATE_CONVERSATION (context){
    context.commit('SET_CONVERSATION', { name: '', image: '', members: [] })
  },
  async SAVE_CONVERSATION (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/conversations', payload)
      if(response.type === 'Success'){
        context.commit('SET_CONVERSATION', response.res.data)
      }
    }
    else
    {
      const response = await API.Put(`/api/conversations/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_CONVERSATION', response.res.data)
      }
    }
  },
  async READ_CONVERSATION(context, payload) {
    const response = await API.Get(`/api/conversations/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_CONVERSATION', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_CONVERSATION(context, payload) {
    const response = await API.Put(`/api/conversations/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_CONVERSATION', response.res.data)
      return response.res.data
    }
  },
  async DELETE_CONVERSATION(context, payload) {
    const response = await API.Delete(`/api/conversations/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_CONVERSATION', response.res.data)
      return
    }
  },
  async DELETE_CONVERSATION_LIST(context, payload) {
    const response = await API.Patch('/api/conversations',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_CONVERSATION', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
