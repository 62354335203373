<template>
  <div>
    <div class="flex border-b mb-8">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="font-nhu-regular hidden sm:block">{{ $t('options.emails') }}</span>
      </h1>
    </div>
    <div>
      <div class="grid grid-cols-3">
        <div v-for="template in templates" :key="template.id" class="w-full">
          <div class="flex items-center my-2">

            <div>
              <app-checkbox :model-value="isTemplateDisabled(template.id)" @update:modelValue="toggleTemplate(template.id, $event)" />
            </div>
            <span class="font-nhu-regular text-gray-900 fs-14 ml-2">{{ template.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EmailPage",
  data() {
    return {
      templates: [
        {id: 'AssignedWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.assigned')},
        {id: 'AssignedWorkOrders', name: this.$t('features.users.emailTemplates.workOrders.assignedToAdmins')},
        {id: 'CreatedWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.createdToAdmins')},
        {id: 'StartWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.startToAdmins')},
        {id: 'PauseWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.pauseToAdmins')},
        {id: 'ResumeWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.resumeToAdmins')},
        {id: 'FinishedWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.finishedToAdmins')},
        {id: 'ReopenWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.reopenToAdmins')},
        {id: 'PendingWorkOrders', name: this.$t('features.users.emailTemplates.workOrders.pending')},
        {id: 'ResumeWorkOrders', name: this.$t('features.users.emailTemplates.workOrders.resume')},
        {id: 'CommentWorkOrder', name: this.$t('features.users.emailTemplates.workOrders.comment')},
        {id: 'PendingRequests', name: this.$t('features.users.emailTemplates.requests.pending')},
        {id: 'SentRequest', name: this.$t('features.users.emailTemplates.requests.sent')},
        {id: 'SentAdminRequest', name: this.$t('features.users.emailTemplates.requests.sentToAdmin')},
        {id: 'RejectedRequest', name: this.$t('features.users.emailTemplates.requests.rejected')},
        {id: 'ApprovedRequest', name: this.$t('features.users.emailTemplates.requests.approved')},
        {id: 'FinishedRequest', name: this.$t('features.users.emailTemplates.requests.finished')},
      ]
    }
  },
  computed: {
    ...mapGetters({ user: 'GET_USER', currentUser: 'GET_CURRENT_USER' }),
  },
  methods: {
    ...mapActions(['UPDATE_USER_NOTIFICATIONS', 'READ_USER']),
    async toggleTemplate(template, checked) {
      let localUser = {...this.user};
      if (!localUser.disabledEmails) {
        localUser.disabledEmails = [];
      }
      if (checked) {
        localUser.disabledEmails = localUser.disabledEmails.filter(x => x !== template);
        await this.UPDATE_USER_NOTIFICATIONS(localUser);
      } else {
        if (!localUser.disabledEmails?.includes(template)) {
          localUser.disabledEmails?.push(template);
          await this.UPDATE_USER_NOTIFICATIONS(localUser);
        }
      }
    },
    isTemplateDisabled(templateId) {
      return !(this.user.disabledEmails && this.user.disabledEmails.includes(templateId));
    },
  },
  mounted() {
    this.READ_USER(this.currentUser)
  }
}
</script>


<style scoped>

</style>
