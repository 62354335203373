<template>
  <app-loading :loading="loading" class="w-full"/>
  <div class="h-screen">
    <img class="h-6 my-4 px-3" :src="'/images/zigoDark.png'" alt="zigo"/>
    <div class="flex justify-center items-center w-screen overflow-auto">
      <div :class="isMobile ? 'p-6 h-full' : 'border border-gray-100 rounded-[8px] p-6 max-w-[700px]'" class="w-full flex-col items-center justify-center">
        <span class="font-nhu-bold text-[#222222] fs-18">{{asset.name}}</span>
        <app-textbox v-model="request.name" :label="$t('requests.yourName')" />
        <app-textbox v-model="request.email" :label="$t('requests.yourEmail')" :emailValidator="true" :disabled="action === 'edit'" />
        <app-phonebox v-model="request.phone" :label="$t('requests.phone')"/>
        <app-textarea v-model="request.description" :label="$t('requests.description') + '*'" :supp-text="$t('requests.writeDetails')"/>
        <div class="h-0 border-b border-gray-50 my-4"/>
        <div class="flex flex-col">
          <div class="flex mb-3">
            <app-icon icon="bolt_regular" extra-class="mr-2 fill-primary-500" :width="12" :height="12"/>
            <span class="font-nhu-bold fs-14 text-gray-900">{{$t('requests.isWorkingAsset') + '*'}}</span>
          </div>
          <div class="option-container">
            <div class="option" @click="selectOption('yes')">
              <div class="radio-button" :class="[selectedOption === 'yes' ? 'selected border-2 !border-blue-500': '', ]"></div>
              <div class="font-nhu-regular fs-16">
                <slot name="yes-option">{{ $t('yesIsWorking') }}</slot>
              </div>
            </div>
            <div class="option" @click="selectOption('no')">
              <div class="radio-button" :class="[selectedOption === 'no' ? 'selected border-2 !border-blue-500': '', ]"></div>
              <div class="font-nhu-regular fs-16">
                <slot name="no-option">{{ $t('notWorking') }}</slot>
              </div>
            </div>
          </div>
        </div>
        <div class="h-0 border-b border-gray-50 my-4"/>
        <app-image-list v-model="request.images" :rounded="false" />
        <app-dropdown v-model="request.priority" value="id" :label="$t('requests.priority')" :options="options" />
        <div class="w-full pb-4">
          <div class="h-0 border-b border-gray-50 my-4"/>
          <app-button @click="doSave" type="primary" background="light" extra-class="w-full" :disabled="!isFormComplete">
            <span class="fs-14 font-nhu-regular">{{ $t('requests.sendRequest') }}</span>
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RequestCreate",
  components: { },
  data() {
    return {
      loading: false,
      request: {},
      options: [
        {id: 'none', name: this.$t('options.priorities.none')},
        {id: 'low', name: this.$t('options.priorities.low')},
        {id: 'mid', name: this.$t('options.priorities.mid')},
        {id: 'high', name: this.$t('options.priorities.high')},
      ],
      selectedOption: null
    }
  },
  watch: {
    asset: {
      handler: function (val) {
        this.request.assetId = val.id
        this.request.siteId = val.siteId
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({asset: 'GET_PUBLIC_ASSET'}),
    isMobile() {
      return this.$isMobile()
    },
    isFormComplete() {
      return this.request.name && this.request.description && this.selectedOption && this.request.priority;
    },
  },
  methods: {
    async doSave() {
      if (!this.isFormComplete) return;
      const request  = await this.$store.dispatch('SEND_PUBLIC_REQUEST', this.request)
      this.$router.push(`/public-requests/view/${request.id}`)
    },
    async doLoad() {
      this.loading = true;
      await this.$store.dispatch('READ_PUBLIC_ASSET', {id: this.$route.params.id})
      this.request.priority = 'none'
      this.loading = false;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.request.isWorking = option === 'yes';
    }
  },
  async mounted() {
    await this.doLoad()
  },
}
</script>

<style scoped>
.option-container {
  display: flex;
  flex-direction: column;
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.radio-button {
  border: 2px solid #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
}

.radio-button.selected::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #575FEA;
  border-radius: 50%;
}

.option-content {
  /* Add your styles for content */
}
</style>
