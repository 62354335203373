/*import API from '@/api'*/
const state = {
    NEW_OPTIONS: [],
}

const getters = {
    NEW_OPTIONS: state => state.NEW_OPTIONS,
}

const mutations = {
    UPDATE_OPTION(context, payload) {
        const index = state.NEW_OPTIONS.findIndex(option => option.property === payload.property && option.record === payload.record)
        if (index !== -1 && payload.value !== null) {
            state.NEW_OPTIONS[index] = payload
        } else {
            if(payload.value === null){
                state.NEW_OPTIONS = state.NEW_OPTIONS.filter(option => option.property !== payload.property && option.record !== payload.record)
            }
            else
                state.NEW_OPTIONS.push(payload)
        }
    },
    SET_NEW_OPTIONS(context, payload) {
        state.NEW_OPTIONS = payload
    },

}

const actions = {
    async SAVE_PROPS(context, payload) {
        const recordId = payload.record.toLowerCase()
        let newOptions = state.NEW_OPTIONS.filter(x => x.record === recordId)
        if(newOptions.length > 0){
            let company = context.getters.GET_CURRENT_COMPANY
            let comanyProps = company.properties[recordId]
            for (const newOption of newOptions) {
                const index = comanyProps.findIndex(option => option.code === newOption.property)
                if (index !== -1) {
                    comanyProps[index].options.push(newOption.value)
                }
            }
            console.log(comanyProps, "Company Props on Record")
            context.dispatch('UPDATE_COMPANY', {id: company.id, properties: {
                    [recordId]: comanyProps
                }})
/*            await API.Put('/api/company', {properties: {
                    [recordId]: comanyProps
                }})*/
        }
        context.commit('SET_NEW_OPTIONS', [])
    },
}

export default {state, getters, mutations, actions}
