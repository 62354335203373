<template>
  <app-input-container :id="id" :label="label" v-slot="{focusin, focusout}" :model-value="modelValue">
    <vSelect
        v-bind="$attrs"
        :options="paginated"
        v-model="internalValue"
        :clearable="clearable"
        :filterable="false"
        :reduce="(o) => value !== null ? o[value] : o" :inputId="value"
        :label="valueCaption"
        class="block mt-4 w-full border-0  !p-0 text-black !bg-transparent focus:ring-0 sm:text-sm sm:leading-6"
        @open="onOpen"
        @close="onClose"
        @focusin="focusin"
        @focusout="focusout"
        @search="(query) => (this.search = query)"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
      <template v-slot:option="option">
        <slot :option="option" :filtered="filtered"/>
      </template>
      <template v-slot:selected-option-container="{ option, deselect }">
        <slot name="selected-option-container" :option="option" :deselect="deselect"/>
      </template>
      <template v-slot:open-indicator="{ attributes }">
        <slot name="open-indicator" :attributes="attributes">
          <div :class="'-mt-3'">
        <span v-bind="attributes">
          <app-icon icon="chevron_down_regular" extra-class="fill-gray-900" :width="16" :height="16"/>
        </span>
          </div>
        </slot>
      </template>
    </vSelect>
  </app-input-container>
</template>
<script>
import vSelect from 'vue-select'
import AppInputContainer from "@/components/AppInputContainer.vue";

export default {
  name: 'InfiniteSelector',
  components: {AppInputContainer, vSelect},
  emits: ['update:modelValue', 'change'],
  props: {
    id: {type: String, default: null},
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    modelValue: {type: [Number, String, Array], default: ""},
    value: {type: String, default: "id"},
    valueCaption: {type: String, default: "name"},
  },
  data: () => ({
    observer: null,
    limit: 10,
    search: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectOption(value);
      }
    },
    filtered() {
      return this.options.filter((x) => x.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    selectOption(value) {
      this.$emit("update:modelValue", value)
      this.$emit("change")
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}

.vs__dropdown-toggle {
  padding: 0 !important;
}

.vs__search, .vs__search:focus {
  padding: 0.5rem 0.0rem 0.0rem 0.75rem;
}

.vs__search, .vs__search:focus {
  line-height: 1.5rem;
}

.vs__dropdown-option--highlight {
  background-color: #cbcdf8 !important;
  color: #575fea !important;
  font-family: 'neue-haas-unica-regular', serif;
}

.vs__dropdown-menu {
  color: #767C86 !important;
  font-size: 14px;
  font-family: 'neue-haas-unica-regular', serif;
}

.vs__search {
  height: 20px !important;
}

.vs__selected-options {
  padding: 0 !important;
}

.v-select .vs--single .vs--searchable .vs--disabled {
  display: flex;
  align-items: center;
}
</style>
