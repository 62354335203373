<template>
  <div v-if="!viewMode" class="my-4">
    <div class="flex items-center mb-2">
      <app-icon extra-class="mr-2 fill-primary-500" icon="palette_regular" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ $t('teams.addColor') }}</span>
    </div>
    <div class="flex items-center">
      <div v-for="color in colors" :key="color"
           class="mx-1"
           :class="{ 'border-2 border-primary-500 rounded-full p-[1px] flex justify-center': color === selectedColor }"
           @click="selectColor(color)">
        <div class="w-6 h-6 rounded-full cursor-pointer"
             :style="{ 'background-color': color }">
        </div>
      </div>
    </div>
  </div>
  <div v-else class="my-4">
    <div class="flex items-center mb-2">
      <app-icon extra-class="mr-2 fill-primary-500" icon="palette_regular" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ $t('teams.addColor') }}</span>
    </div>
    <div class="w-8 h-8 rounded-full cursor-pointer"
         :style="{ 'background-color': modelValue?.color }">
    </div>
  </div>
  <div>
    <div class="h-0 border-b border-gray-50 my-4"/>
  </div>
</template>

<script>

export default {
  name: "AppColorPicker",
  components: {},
  emits: ["update:modelValue"],
  data() {
    return {
      selectedColor: this.modelValue,
      colors: ['#5B9730', '#16AC5E', '#1299E6', '#265EFA', '#7C47F0', '#CE2CEA', '#EB34B0', '#F4305C', '#FF3912', '#E6A224']
    }
  },
  props: {
    modelValue: {type: String, default: null,},
    viewMode: {type: Boolean, default: false},
  },
  watch: {
    modelValue(newValue) {
      this.selectedColor = newValue;
    }
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      this.$emit("update:modelValue", color);
    },
  }
};
</script>
