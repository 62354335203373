import API from '@/api'

const state = {
  TYPE: {
    name: '',
  },
  TYPES: { data:[] }
}

const getters = {
  GET_TYPE: state => state.TYPE,
  GET_TYPES: state => state.TYPES,
}

const mutations = {
  // SITES
  SET_TYPE(state, payload) {
    state.TYPE = payload
  },
  SET_TYPES(state, payload) {
    state.TYPES = payload
  },
  CREATE_TYPE(state, payload) {
    state.TYPE = payload
    state.TYPES.data.push(payload)
  },
  UPDATE_TYPE(state, payload) {
    if (state.TYPE !== null && state.TYPE.id === payload.id)
      state.TYPE = payload;

    const index = state.TYPES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.TYPES.data[index], payload)
  },
  DELETE_TYPE(state, payload) {
    if (state.TYPE?.id === payload.id)
      state.TYPE = {}
    state.TYPES.data = state.TYPES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD COURSES
  async READ_TYPES(context, payload) {
    const response = await API.Get('/api/types', payload)
    if (response.type === 'Success') {
      context.commit('SET_TYPES', response.res.data)
      return response.res.data
    }
    context.commit('SET_TYPES', [])
  },
  async CREATE_TYPE (context){
    context.commit('SET_TYPE', { name: '' })
  },
  async SAVE_TYPE (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/types', payload)
      if(response.type === 'Success'){
        context.commit('SET_TYPE', response.res.data)
      }
    }
    else
    {
      const response = await API.Put(`/api/types/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_TYPE', response.res.data)
      }
    }
  },
  async READ_TYPE(context, payload) {
    const response = await API.Get(`/api/types/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_TYPE', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_TYPE(context, payload) {
    const response = await API.Put(`/api/types/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_TYPE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_TYPE(context, payload) {
    const response = await API.Delete(`/api/types/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_TYPE', response.res.data)
      return
    }
  },
  async DELETE_TYPE_LIST(context, payload) {
    const response = await API.Patch('/api/types',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_TYPE', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
