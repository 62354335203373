import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
    PROCEDURE: {
        name: '',
        props: {}
    },
    PROCEDURES: {data: []},
    ALL_PROCEDURES: []
}

const getters = {
    GET_PROCEDURE: state => state.PROCEDURE,
    GET_PROCEDURES: state => state.PROCEDURES,
    GET_PROCEDURE_BY_ID: state => id => state.ALL_PROCEDURES.find(e => e.id === id),
    GET_ALL_PROCEDURES: state => state.ALL_PROCEDURES,
    GET_PUBLIC_PROCEDURE: state => state.PROCEDURE,
    GET_ALL_PROCEDURES_BY_COMPANY_ID: state => state.ALL_PROCEDURES,
}

const mutations = {
    // PROCEDURES
    SET_PROCEDURE(state, payload) {
        state.PROCEDURE = payload
    },
    SET_ALL_PROCEDURES(state, payload) {
        state.ALL_PROCEDURES = payload
    },
    SET_PROCEDURES(state, payload) {
        state.PROCEDURES = payload
    },
    CREATE_PROCEDURE(state, payload) {
        state.PROCEDURE = payload
        state.PROCEDURES.data.push(payload)
    },
    UPDATE_PROCEDURE(state, payload) {
        if (state.PROCEDURE !== null && state.PROCEDURE.id === payload.id)
            state.PROCEDURE = payload;

        const index = state.PROCEDURES.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.PROCEDURES.data[index], payload)
    },
    DELETE_PROCEDURE(state, payload) {
        if (state.PROCEDURE?.id === payload.id)
            state.PROCEDURE = {}
        state.PROCEDURES.data = state.PROCEDURES.data.filter(e => e.id !== payload.id)
        state.ALL_PROCEDURES = state.ALL_PROCEDURES.filter(e => e.id !== payload.id)
    },
    SET_PUBLIC_PROCEDURE(state, payload) {
        state.PROCEDURE = payload
    }
}

const actions = {
    async READ_PROCEDURES(context, payload) {
        const response = await API.Get('/api/procedures', payload)
        if (response.type === 'Success') {
            context.commit('SET_PROCEDURES', response.res.data)
            return response.res.data
        }
        context.commit('SET_PROCEDURES', [])
    },
    async READ_ALL_PROCEDURES(context) {
        const response = await API.Get('/api/options/procedures')
        if (response.type === 'Success') {
            context.commit('SET_ALL_PROCEDURES', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_PROCEDURES', [])
    },

    async CREATE_PROCEDURE(context) {
        context.commit('SET_PROCEDURE', {name: '', props: {}})
    },

    async SAVE_PROCEDURE(context, payload) {
        if (payload.id === undefined) {
            const response = await API.Post('/api/procedures', payload)
            if (response.type === 'Success') {
                context.dispatch('READ_ALL_PROCEDURES')
                context.commit('SET_PROCEDURE', response.res.data)
                return response.res.data;
            }
        } else {
            const response = await API.Put(`/api/procedures/${payload.id}`, payload)
            if (response.type === 'Success') {
                context.dispatch('READ_ALL_PROCEDURES')
                context.commit('UPDATE_PROCEDURE', response.res.data)
                return response.res.data;
            }
        }
    },
    async READ_PROCEDURE(context, payload) {
        const response = await API.Get(`/api/procedures/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_PROCEDURE', response.res.data)
            return response.res.data
        }
    },
    async READ_PUBLIC_PROCEDURE(context, payload) {
        const response = await API.Get(`/api/public/procedures/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_PUBLIC_PROCEDURE', response.res.data)
            return response.res.data
        }
    },
    async UPDATE_PROCEDURE(context, payload) {
        const response = await API.Put(`/api/procedures/${payload.id}`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_PROCEDURE', response.res.data)
            return response.res.data
        }
    },
    async DELETE_PROCEDURE(context, payload) {
        const response = await API.Delete(`/api/procedures/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('DELETE_PROCEDURE', response.res.data)
            context.commit('TOAST_MESSAGE', {
                type: 'success',
                title: i18n.global.t('assets.assetDelete.title'),
                message: i18n.global.t('assets.assetDelete.message')
            })
            return response.res.status
        }
    },
    async DELETE_PROCEDURE_LIST(context, payload) {
        const response = await API.Patch('/api/procedures', payload);

        if (response.type === 'Success') {
            context.commit('DELETE_PROCEDURE', {payload});
            return true;
        }
        return false;
    },
    async EXPORT_PROCEDURE_XLSX() {
        const response = await API.Post(`/api/procedures/export-XLSX`)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async IMPORT_PROCEDURES(context, payload) {
        const response = await API.Post('/api/procedures/import', payload)
        if (response.type === 'Success') {
            if(state.PROCEDURES){
                context.commit('SET_PROCEDURES', {data: [...state.PROCEDURES.data, ...response.res.data]})
            }else{
                context.commit('SET_PROCEDURES', response.res.data)
            }
            return response.res.data
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
