<template>
  <div @mouseover="hoverElement" @mouseleave="hoverImage = false" class="relative">
    <div :class="width + ' ' + height" ref="userAvatar">
      <app-image :class="width+' '+height" v-if="user?.images?.length > 0" v-show="hideImage" :src="getImage" rounded/>
      <div v-else
           class="rounded-full text-gray-400 flex justify-center items-center" :class="width+' '+height"
           v-show="hideImage"
           :style="{ backgroundColor: getColor /*getColor(field, val) */ }">
        <div class="font-nhu-regular text-white flex items-center justify-center">{{ getNameInitial() }}</div>
      </div>
    </div>

    <div class="fixed" ref="tooltip">
      <p v-show="hoverImage && tooltipText !== ''"
         class="bg-gray-700 text-white rounded-md whitespace-nowrap tooltip-container
         p-[10.5px] fs-14 inter-400">
        {{ tooltipText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    width: {
      type: String,
      default: 'w-6',
    },
    height: {
      type: String,
      default: 'h-6',
    },
    user: {
      type: Object,
      default: null,
    },
    hideImage: {
      type: Boolean,
      default: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hoverImage: null,
    }
  },
  computed: {
    getImage() {
      return this.user.images[0]
    },
    getColor() {
      return this.user?.color ?? this.$colors.primary[500]
    }
  },
  methods: {
    getNameInitial() {
      let name = this.user?.name
      if (name) {
        let names = name.split(' ')
        if (names.length > 1) {
          return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
        } else {
          return names[0].charAt(0).toUpperCase();
        }
      } else {
        return ''
      }
    },
    async hoverElement() {
      this.hoverImage = true
      await this.$nextTick()
      const avatar = this.$refs.userAvatar.getBoundingClientRect();
      const tooltip = this.$refs.tooltip.getBoundingClientRect();
      this.$refs.tooltip.style.top = (avatar.top - 42) + 'px'
      this.$refs.tooltip.style.left = (avatar.left - tooltip.width/2) + 12 + 'px'
    }
  },
}
</script>

<style scoped>
.tooltip-container::before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 45.5%;
  border-right: 5px solid transparent;
  border-top: 5px solid #54585f;
  border-left: 5px solid transparent;
  border-bottom: none;
}
</style>
