<template>
  <div class="text-[#3F2B4E] m-3 font-nhu-regular flex items-center" style="font-size:12px">
    {{$t('poweredByZigo')}} <img src="/images/zigo.png" class="float-right w-12 ml-2" style="margin-bottom: 0">
  </div>
</template>

<script>

export default {
  name: "AppPoweredByZigo",
};
</script>
