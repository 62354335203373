<template>
  <app-crud ref="crud" v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('users.name')" />
    <div class="flex my-4">
      <div>
        <app-checkbox v-model="item.noLogin" :lock="true" />
      </div>
      <span class="pl-2">
        {{ $t('users.noLogin') }}
      </span>
    </div>
    <div v-if="!item.noLogin">
      <app-textbox v-model="item.email" :label="$t('users.email2')" :emailValidator="true" />
      <app-dropdown v-model="item.roleId" :label="$t('users.role')" getter="GET_ALL_ROLES" />
    </div>
    <div v-else class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <div class="text-sm text-yellow-700">
            <p>{{ $t('users.noLoginExplain') }}</p>
          </div>
        </div>
      </div>
    </div>

    <app-dropdown v-model="item.sites" value="id"
      :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('assets.site')" getter="GET_ALL_SITES"
      :allow-nulls="true" :multiple="true" :has-child="true" />
    <app-crud-properties v-model="item.props" />
    <div class="mt-4">
      <div v-if="item.images?.length >= 1" class="flex items-center fill-primary-500">
        <app-icon extra-class="mr-2" icon="camera_regular" :width="20" :height="20" />
        <span class="font-nhu-semi-bold fs-14">{{ $t('users.assignImage') }}</span>
      </div>
      <app-image-list v-model="item.images" :label="$t('users.images')" :onlyOneImage="true" />
    </div>
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
import AppIcon from "@/components/AppIcon.vue";

export default {
  name: "UserPage",
  components: { AppIcon },
  data() {
    return {
      errorMsg: '',
      validated: false
    }
  },
  methods: {
    async onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.errorMsg = this.$t('records.users.errors.nameRequired');
        onError();
      }
      else {
        this.validated = true
        onSuccess();
      }
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
