<template>
    <div class="flex-col flex">
      <div class="flex justify-between pb-1 items-center">
        <span class="fs-14 font-nhu-regular text-gray-900 cursor-pointer" @click="viewOrder">{{ workOrder.name }}</span>
        <div class="flex" v-if="assignedUsers !== 0">
          <user-avatar v-for="user in assignedUsers" :key="user.id" :user="user" />
        </div>
      </div>
      <div class="flex justify-between pb-1">
        <span class="fs-12 font-nhu-regular text-gray-500">{{ $t('records.workorders.fields.requestedBy') }}{{userCreator?.name}}</span>
        <span class="fs-12 font-nhu-regular text-gray-500">{{$t('WO')}}-{{workOrder.number}}</span>
      </div>
      <app-icon :icon="$iconsByStatus.icon(workOrder.status)"
                :width="14"
                :height="12"
                :class="$iconsByStatus.class(workOrder.status)"
      >
      <span class="capitalize ml-1 fs-12 font-nhu-regular text-gray-900">
        {{ $t(workOrder.status) }}
      </span>
      </app-icon>
    </div>
</template>

<script>
import userAvatar from "@/components/UserAvatar";
export default {
  name: "WorkOrderCard",
  components: {userAvatar},
  props: {
    workOrder: {
      type: Object,
      default: null,
    },
  },
  data() {
    return{
      userCreator: {},
      assignedUsers: []
    }
  },
  methods: {
    viewOrder() {
      this.$router.push(`/workorders/${this.workOrder.id}?back=true`)
    },
    getCreatorUser() {
      if (this.workOrder.creationId) {
        this.userCreator = this.$store.getters['GET_USER_BY_ID'](this.workOrder.creationId)
      }
      return ""
    },
    getAssignementUsers() {
      if (this.workOrder.assignedTo) {
        this.assignedUsers = this.$store.getters['GET_USERS_BY_IDS'](this.workOrder.assignedTo)
      }
      return ""
    }
  },
  mounted() {
    this.getCreatorUser()
    this.getAssignementUsers()
  }
}
</script>

<style scoped>

</style>
