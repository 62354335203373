<template>

  <app-sidebar :title="$t('multipleOptionsAnswers')" v-model="show">

      <div class="flex justify-between items-center">
          <div class="my-4 font-bold">
              {{$t('answers')}}
          </div>
          <div>
              <input v-model="optionset.isScored" type="checkbox" id="checkbox" />
              {{$t('score')}}
          </div>
      </div>


      <div class="">
        <div v-for="option in optionset.options" :key="option.id" class="border-b p-2">
            <div class="flex">
                <input :value="option.name" @input="val => option.name = val.target.value"
                       class="question-input flex-1" :placeholder="$t('questionTitle')"
                       @click="selected = option"/>
                <div>
                    <app-color-picker v-model="option.color" />
                </div>
            </div>
            <div class="flex">
                <div class="flex items-center mt-3" v-if="selected === option">
                    <input v-model="option.isFlagged" type="checkbox" :id="option.id" />
                    <div class="ml-2">{{$t('markAsFlagged')}}</div>
                </div>
                <div class="flex items-center">
                    <div class="mt-3" v-if="selected !== option && option.isFlagged">
                        <div>{{$t('flagged')}}</div>
                    </div>
                    <div class="flex items-center" v-if="optionset.isScored">
                        <div v-if="selected === option || option.isFlagged" class="flex items-center mt-3 mx-2">
                            <span>|</span>
                        </div>
                        <div class="mt-3">{{$t('score')}}:</div>
                        <div v-if="selected === option" class="w-10">
                            <app-textbox v-model="option.score" class="mx-1" type="number" ></app-textbox>
                        </div>
                        <div v-else>
                            <div class="mt-3 mx-2"><span>{{option.score}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-button @click="addResponse" type="terciary" class="mt-4">{{ $t('addAnswer') }}</app-button>

    <template #footer>
      <app-button @click="doClose" type="secondary">
      {{  $t('optionset.close') }}
      </app-button>
      <app-button @click="doSave" type="primary">
      {{  $t('optionset.save') }}
      </app-button>
    </template>
  </app-sidebar>

</template>
<script>
import { } from '@heroicons/vue/20/solid'
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'OptionSetEditor',
  props: {
    modelValue: {
      type: Object,
			default() { return null },
    },
	},
  computed: {
    ...mapGetters({ optionset: 'GET_OPTIONSET'}),
  },
  data() {
    return {
      isNew: false,
      show: false,
      selected: null,
    }
  },
  methods: {
    ...mapActions(['CREATE_OPTIONSET', 'READ_OPTIONSET', 'SAVE_OPTIONSET', 'READ_ALL_OPTIONSETS']),
    createNew() {
      this.CREATE_OPTIONSET();
      this.isNew = true;
      this.show = true;
    },
    async editItem(id) {
      await this.READ_OPTIONSET({ id: id});
      this.isNew = false;
      this.show = true;
    },
    doClose() {
      this.show = false;
    },
    async doSave() {
      await this.SAVE_OPTIONSET(this.optionset);
      await this.READ_ALL_OPTIONSETS();
      this.show = false;
    },

    addResponse() {
      this.optionset.options.push({ id: Math.random().toString(36).substring(2, 15), name: '' })
    }
	}
}
</script>
