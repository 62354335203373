<template>
  <div class="group flex px-1 my-[2px]">
    <div class="px-1 py-[2px] rounded-full border-[1px] flex truncate" :class="[event.isRecurrence ? 'bg-blue-50 border-blue-100 fill-blue-500' : 'bg-blue-50 border-blue-100',
     event.status === 'done' ? 'bg-gray-50 border-gray-100' : '']">
      <p class="flex-auto truncate font-nhu-regular fs-12 text-gray-700 group-hover:text-gray-900 cursor-pointer pr-[5px]">
        {{ event.name }}
      </p>
      <app-icon v-if="event.isRecurrence" icon="repeat_regular" width="12" height="12"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarEvent",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
}
</script>
