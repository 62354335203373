<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
      <MenuButton>
<!--        <div v-if="localFilter.id">
          <app-icon icon="wand_magic_sparkles_regular" class="fill-primary-500" :width="15" :height="15">
            <span class="fs-14 font-nhu-500 ml-1">{{ localFilter.name }}</span>
          </app-icon>
        </div>-->
        <div>
          <app-icon icon="wand_magic_sparkles_regular" class="fill-primary-500" :width="15" :height="15">
            <span class="fs-14 font-nhu-500 ml-1">{{ $t('crud.myFilters') }}</span>
          </app-icon>
        </div>
      </MenuButton>
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems
            class="absolute right-0 z-10 mt-2 w-60 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 py-1 max-h-40 overflow-y-auto">
          <div v-for="filter in getFiltersOptions" :key="filter.id">
            <MenuItem v-slot="{ active }">
              <div :class="[active ? 'bg-primary-25 text-gray-700' : 'text-gray-700', 'block px-4 py-2 text-sm flex justify-between']">
              <div class="ml-1 w-full flex" @click="setFilter(filter)">
                {{filter.name}}
              </div>
              <div class="flex gap-x-1">
<!--                  <app-icon icon="pencil_regular" class="fill-primary-500" :width="15" :height="15" @click="openFilterDialog(filter)"></app-icon>-->
                <app-icon icon="trash_regular" class="fill-red-500" :width="15" :height="15" @click="deleteFilter(filter)"></app-icon>
              </div>
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <app-filter-dialog :filter="localFilter" :open="filterDialog" @close="filterDialog = false"></app-filter-dialog>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import{ mapGetters } from "vuex";
import AppFilterDialog from "@/components/AppFilterDialog";
export default {
  name: "AppFilterMenu",
  emits: ['reloadData'],
  components: {AppFilterDialog, Menu, MenuItems, MenuItem, MenuButton, },
  data() {
    return {
      filterDialog: false,
      localFilter: {}
    }
  },
  computed: {
    ...mapGetters({record: 'GET_RECORD', filters: 'GET_FILTERS'}),
    getFiltersOptions() {
      return  this.$store.getters['GET_DB_FILTER_BY_RECORD'](this.record.name);
    }
  },
  methods: {
    setFilter(filter) {
      this.localFilter = filter;
      this.filters[this.record.name] = JSON.parse(filter.filter)
      this.$store.commit('SET_FILTERS', this.filters)
      this.$emit('reloadData')
    },
    async deleteFilter(filter) {
      if(this.localFilter.id === filter.id) this.localFilter = {};
      this.$store.dispatch('DELETE_FILTER', filter)
      this.filters[this.record.name] = {};
      this.$store.commit('SET_FILTERS', this.filters)
      this.$emit('reloadData')
    },
    openFilterDialog(filter) {
      this.localFilter = filter;
      this.filterDialog = true;
    }
  }
}
</script>

