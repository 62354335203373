<template>
  <ul role="list" class="flex overflow-auto gap-x-2 gap-y-4 xl:gap-x-4">
      <div v-if="modelValue"
              class="relative block overflow-hidden group w-28 h-28 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500">

        <app-image :src="modelValue" alt="" :rounded="rounded" rounded_type="md" />

        <div @click="removeImage()" class="absolute top-0 right-0 z-10 cursor-pointer" v-if="!disabled">
          <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray"/>
        </div>

        <button type="button" class="absolute inset-0 focus:outline-none cursor-grab active:cursor-grabbing">
          <span class="sr-only">Image</span>
        </button>
      </div>

    <li v-if="imageUploading && allowUploadImage && !disabled">
      <div class="relative block overflow-hidden bg-gray-100 group w-28 h-28 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500"
      :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }">
        <div class="flex items-center justify-center w-full h-full">
          <app-loading :loading="true"/>
        </div>
      </div>
    </li>

    <li v-if="!modelValue && !imageUploading" class="relative">
      <div @click="!disabled && $refs.file.click()"
      :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }"
        class="flex items-center justify-center block overflow-hidden bg-white border-2 border-black border-box cursor-pointer group w-28 h-28 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500 hover:brightness-95">
        <PlusIcon class="h-6" v-if="!disabled" />
        <input id="file_input" class="hidden" type="file" ref="file" @change="showUploadFilePopUp($event.target)"/>
      </div>
    </li>
  </ul>

  <AppEditImage v-model:open-pop-up="showEditImagePopUP" :file="editImageFile" @uploadImage="v=>uploadFile(v)"
                @clearInput="clearInput" :maxSize="maxSize" :rounded="rounded" v-model:loader="imageUploading"/>
</template>

<script>
import {XCircleIcon, PlusIcon} from '@heroicons/vue/24/outline'
import AppImage from "@/components/AppImage";
import AppEditImage from "@/components/AppEditImage";
import Images from "@/services/images";
import { mapActions } from 'vuex';

export default {
  name: "AppSingleImage",
  emits: ['update:modelValue', 'change'],
  components: {AppEditImage, AppImage, XCircleIcon, PlusIcon},
  props: {
    modelValue: { type: String, default: ''  },
    lock: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    allowUploadImage:{ type: Boolean, default: true },
    maxSize:{ type: Number, default: 0 },
    editor: { type: Boolean, default: false },
    onlyOneImage: { type: Boolean, default: false }
  },
  data() {
    return {
      editImageFile: null,
      files: this.modelValue,
      imageUploading: false,
      showEditImagePopUP: false,
      selectedImageUrl: '',
      addImage: true
    }
  },
  computed: {
    showNewButton() {
      return (this.modelValue?.length ?? 0) < this.max;
    },
    cantImage() {
      return !(this.modelValue?.length >= 1 && this.onlyOneImage === true);
    }
  },
  methods: {
    ...mapActions(['FIRE_MESSAGE']),

    showUploadFilePopUp(target) {
      if(this.editor)
      {
        if(this.maxSize == 0 || target.files[0].size < this.maxSize)
        {
          const reader = new FileReader()
          let rawImg;
          reader.onloadend = () => {
              rawImg = reader.result;
              this.editImageFile = rawImg
              this.showEditImagePopUP = true
          }
          reader.readAsDataURL(target.files[0]);
        }
        else
          this.FIRE_MESSAGE({message: this.$t('fileTooHeavy')})
      } else {
        this.imageUploading = true
        let file = ''
        if (this.modelValue != null)
          file = this.modelValue;

        Images.Upload(target.files)
          .then((names) => {
            names.forEach(name => file = name);
            this.imageUploading = false
            this.$emit("update:modelValue", file);
            this.$emit("change");
          });
      }
    },

    clearInput() {
      document.getElementById('file_input').value = ''
    },

    uploadFile(file) {
      this.showEditImagePopUP = false
      this.imageUploading = true

      let files = []
      if (this.modelValue != null)
        files = this.modelValue.slice(0);

      let array = []
      array.push(file)
      Images.Upload(array)
          .then((names) => {
            names.forEach(name => files.push(name));
            this.imageUploading = false
            this.$emit("update:modelValue", files);
            this.$emit("change");
          });
    },

    removeImage() {
      this.$emit("update:modelValue", "");
      this.$emit("change");
    },
    editImage(imageUrl) {
      this.selectedImageUrl = imageUrl
      this.showEditImagePopUP = !this.showEditImagePopUP
    }
  },
  setup() {
    Array.prototype.move = function (from, to) {
      return this.splice(to, 0, this.splice(from, 1)[0]);
    };
  }
};
</script>

<style>
.grabbing * {
  cursor: grabbing !important;
}
</style>
