<template>
<!--    <span class="font-nhu-regular fs-14">{{ getCode }}</span>-->
    <div class="flex">
        <app-image :is-qr="true" :src="src" :rounded="false"
                   :class="qrSize === '10x10' ? 'w-[150px] h-[150px]' : qrSize === '20x20' ? 'w-[200px] h-[200px]' : qrSize === '30x30' ? 'w-[250px] h-[250px]' : ''"/>
        <div class="w-60">
            <app-dropdown v-model="qrSize" :options="options" :label="$t('sites.selectSize')"
                          :supp-text="$t('sites.putIntoLocation')"/>
        </div>
        <div class="mt-4 ml-4">
            <app-button
                    @click="printQrCode"
                    extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14"
                    :clean="true">{{ $t('sites.print') }}
            </app-button>
        </div>
    </div>
</template>

<script>
import AppDropdown from "@/components/AppDropdown.vue";
import AppButton from "@/components/AppButton.vue";

export default {
  name: "AppImageQr",
  components: {AppButton, AppDropdown},
  props: {
    disabled: {type: Boolean, default: false},
    src: {type: String, default: null},
    data: {type: Object, default: null},
  },
  data() {
    return {
      qrSize: localStorage.getItem('qrSize') || '10x10',
      options: [
        {id: '10x10', name: '10x10 cm'},
        {id: '20x20', name: '20x20 cm'},
        {id: '30x30', name: '30x30 cm'}
      ]
    }
  },
  computed: {
    getCode() {
      return this.src.replace('.png', '')
    }
  },
  mounted() {
    this.qrSize = localStorage.getItem('qrSize') || '10x10';
  },
  watch: {
    qrSize(newSize) {
      localStorage.setItem('qrSize', newSize);
    }
  },
  methods: {
    printQrCode() {
      const imageUrl = `https://content.zigo.la/qr-images/public/${this.src}`;
      const printWindow = window.open('', '_blank');
      const imageHtml = `<img id="qrImage" src="${imageUrl}" onload="window.focus(); window.print();" onerror="alert('No se pudo cargar la imagen del QR.'); window.close();">`;

      printWindow.document.write('<html><head><title>Print QR Code</title></head><body>');
      printWindow.document.write(imageHtml);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    },
  },
}
</script>

<style scoped>

</style>
