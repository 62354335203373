<template>
  <div @click="labelClick" class="w-full">
    <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled"
                         v-slot="{focusin, focusout, changeType} "
                         @labelClick="labelClick" :invalid="!!error || hasError" :errors="errors" :type="type"
                         :is-password="isPassword" @update:type="updateType">
      <input :name="id" :id="id" :type="changeType" ref="input" :value="modelValue" :disabled="disabled"
             @change="change" @blur="blur" 
             @input="onChange" @focusin="focusin" required @focusout="focusout"  @keydown.enter="onEnter"
             class="block mt-3 w-full h-[20px] !p-0 !bg-transparent focus:ring-0 sm:text-[14px] sm:leading-6 border-gray-200 text-gray-900 font-nhu-regular"
             :class="{'-mr-4': isPassword}"
      />
      <slot></slot>
    </app-input-container>
  </div>
  <div v-if="suppText !== ''" class="flex items-center mt-2">
    <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-gray-300"/>
    <span class="relative block text-xs font-nhu-regular text-gray-300 cursor-text">{{ suppText }}</span>
  </div>

  <div v-if="error !== ''" class="flex items-center mt-2">
    <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-red-500"/>
    <span class="relative block text-xs font-nhu-regular text-red-500 cursor-text">{{ error }}</span>
  </div>
</template>

<script>

import {InformationCircleIcon} from "@heroicons/vue/24/outline";

export default {
  name: "AppTextbox",
  components: {InformationCircleIcon},
  props: {
    id: {type: String, default: null},
    type: {type: String, default: 'text'},
    modelValue: {type: [Number, String], default: ""},
    maxChars: {type: Number, default: 0},
    label: {type: String, default: ""},
    emailValidator: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    invalid: {type: Boolean, default: false},
    error: {type: String, default: ""},
    errors: {type: Object, default: null},
    enter: {type: Boolean, default: false},
    focus: {type: Boolean, default: false},
    suppText: {type: String, default: ""},
    isPassword: {type: Boolean, default: false},
    hasError: {type: Boolean, default: false}
  },
  emits: ["update:modelValue", "enter", "change", "input", "update:type", "blur"],
  data() {
    return {}
  },
  computed: {
    hasValue() {
      return this.modelValue !== ''
    }
  },
  watch: {
    focus() {
      const input = this.$refs.input
      input.focus()
    }
  },
  methods: {
    updateType(type) {
      this.$emit('update:type', type)
    },
    labelClick() {
      this.$refs.input.focus();
    },
    onChange(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    onEnter() {
      if (this.enter) {
        this.$emit('enter');
      }
    },
    change(e) {
      this.$emit('change', e);
    },
    blur(e) {
      this.$emit('blur', e);
    }
  },
};
</script>
