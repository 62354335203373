<template>
  <app-crud :extra-class="'bg-white'" v-slot="{ item }">
    <Teleport to="#footer">
      <div class="flex gap-2">
        <app-button @click="createWorkOrder(item)">Crear Orden de Trabajo</app-button>
        <app-button v-if="item.templateId" @click="createInspection(item)">Crear Inspeccion</app-button>
      </div>
    </Teleport>

    <div class="flex">
      <div class="w-1/4" :class="item.image === null ? 'border' : ''">
        <app-image :src="item.image" />
      </div>
      <div class="flex-1 mx-4">
        <app-label :label="$t('records.procedures.fields.code')" :value="item.code" />
        <app-label :label="$t('records.procedures.fields.name')" :value="item.name" />
      </div>
    </div>

    <app-label :value="item.objetive" :label="$t('records.procedures.fields.objetive')" />
    <app-label :value="item.policy" :label="$t('records.procedures.fields.policy')" />
    <app-label :value="item.body" :label="$t('records.procedures.fields.body')" />

    <!-- Tasks -->
    <div class="mt-4" v-if="item.tasks?.length">
      <div class="mb-1 flex fill-primary-500">
        <app-icon icon="list_check_regular" extra-class="mr-2" :width="12" :height="12" />
        <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.tasksLabel') }}</span>
      </div>

      <div v-for="(check, index) in item.tasks" :key="check.id" class="my-2">
        <label class="flex items-center my-1 w-fit">
          <!-- Checkbox personalizado -->
          <span class="w-6 h-6 border mr-2 rounded-full flex items-center justify-center">
            {{ index + 1 }}
          </span>
          <span class="font-nhu-regular fs-14">{{ check.name }}</span>
        </label>
      </div>
      <div class="h-0 border-b border-gray-50 my-4" />
    </div>

    <div class="mt-4" v-if="item.checklist?.length">
      <div class="mb-1 flex fill-primary-500">
        <app-icon icon="list_check_regular" extra-class="mr-2" :width="12" :height="12" />
        <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.checklistLabel') }}</span>
      </div>

      <div v-for="check in item.checklist" :key="check.id" class="my-2">
        <label class="flex items-center my-1 w-fit">
          <!-- Checkbox personalizado -->
          <span class="w-4 h-4 border mr-2 rounded-sm flex items-center justify-center">
          </span>
          <span class="font-nhu-regular fs-14">{{ check.name }}</span>
        </label>
      </div>
      <div class="h-0 border-b border-gray-50 my-4" />
    </div>


    <app-label :value="item.measurement" :label="$t('records.procedures.fields.measurement')" />
    <app-label :value="item.templateId" getter="GET_ALL_TEMPLATES"
      :label="$t('records.procedures.fields.templateId')" />

    <!-- Files -->
    <div v-if="item.files?.length">
      <div class="flex items-center mb-1 fill-primary-500">
        <app-icon extra-class="mr-2" icon="paperclip_regular" :width="14" :height="14" />
        <span class="font-nhu-semi-bold fs-14">
          {{ $t('workorders.files') }}
        </span>
      </div>
      <app-file-list v-model="item.files" :rounded="false" :allow-upload-file="false" />
      <div class="h-0 border-b border-gray-50 my-4" />
    </div>
  </app-crud>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProcedureViewPage",
  methods: {
    ...mapActions(['CREATE_INSPECTION', 'SAVE_INSPECTION']),

    async createWorkOrder(item) {
      this.$router.push('/workorders/create?procedureId=' + item.id);
    },

    async createInspection(item) {
      this.CREATE_INSPECTION();
      let inspection = await this.SAVE_INSPECTION({ procedureId: item.id, templateId: item.templateId });
      this.$router.push('/workorders/' + inspection.workOrderId);
    }
  },
}
</script>
