import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
    METRIC: {
        name: '',
        description: '',
    },
    METRICS: { data: [] },
    METRIC_UNITS: [
        { name: "records.metrics.units.kilometers", id: "kilometers", type: "length" },
        { name: "records.metrics.units.meters", id: "meters", type: "length" },
        { name: "records.metrics.units.centimeters", id: "centimeters", type: "length" },
        { name: "records.metrics.units.millimeters", id: "millimeters", type: "length" },
        { name: "records.metrics.units.kilowatt", id: "kilowatt", type: "power" },
        { name: "records.metrics.units.kilowattH", id: "kilowattH", type: "power" },

        { name: "records.metrics.units.liters", id: "liters", type: "volume" },
        { name: "records.metrics.units.milliliters", id: "milliliters", type: "volume" },

        { name: "records.metrics.units.cubicMeters", id: "cubicMeters", type: "volume" },

        { name: "records.metrics.units.fareinheit", id: "fareinheit", type: "temperature" },
        { name: "records.metrics.units.celsius", id: "celsius", type: "temperature" },
        { name: "records.metrics.units.kelvin", id: "kelvin", type: "temperature" },

        { name: "records.metrics.units.hours", id: "hours", type: "time" },
        { name: "records.metrics.units.cicles", id: "cicles", type: "time" },
        { name: "records.metrics.units.psi", id: "psi", type: "pressure" },

        { name: "records.metrics.units.kilograms", id: "kilograms", type: "weight" },
        { name: "records.metrics.units.grams", id: "grams", type: "weight" },

        { name: "records.metrics.units.galons", id: "galons", type: "volume" },
        { name: "records.metrics.units.miles", id: "miles", type: "length" },
        { name: "records.metrics.units.feets", id: "feets", type: "length" },
        { name: "records.metrics.units.inches", id: "inches", type: "length" },

    ],
    METRIC_FREQUENCIES: [
        { name: "records.metrics.frequencies.none", id: "none" },
        { name: "records.metrics.frequencies.hourly", id: "hourly" },
        { name: "records.metrics.frequencies.daily", id: "daily" },
        { name: "records.metrics.frequencies.weekly", id: "weekly" },
        { name: "records.metrics.frequencies.monthly", id: "monthly" },
        { name: "records.metrics.frequencies.yearly", id: "yearly" },
    ],
    METRIC_TYPE_ASSIGNED: "asset",
    ALL_METRICS: []
}
const getters = {
    GET_METRIC: state => state.METRIC,
    GET_METRICS: state => state.METRICS,
    GET_METRIC_TYPE_ASSIGNED: state => state.METRIC_TYPE_ASSIGNED,
    GET_METRIC_FREQUENCIES: state => state.METRIC_FREQUENCIES,
    GET_METRICS_UNITS: state => state.METRIC_UNITS,
    GET_ALL_METRICS: state => state.ALL_METRICS,
}

const mutations = {
    SET_METRIC_TYPE_ASSIGNED(state, payload) {
        state.METRIC_TYPE_ASSIGNED = payload
    },
    SET_METRIC(state, payload) {
        state.METRIC = payload
    },
    SET_ALL_METRICS(state, payload) {
        state.ALL_METRICS = payload
    },
    SET_METRICS(state, payload) {
        state.METRICS = payload
    },
    CREATE_METRIC(state, payload) {
        state.METRIC = payload
        state.METRICS.data.push(payload)
    },
    UPDATE_METRIC(state, payload) {
        if (state.METRIC !== null && state.METRIC.id === payload.id)
            state.METRIC = payload;

        const index = state.METRICS.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.METRICS.data[index], payload)
    },
    DELETE_METRIC(state, payload) {
        if (state.METRIC?.id === payload.id)
            state.METRIC = {}
        state.METRICS.data = state.METRICS.data.filter(e => e.id !== payload.id)
    },
}

const actions = {
    async READ_METRICS(context, payload) {
        const response = await API.Get('/api/metrics', payload)
        if (response.type === 'Success') {
            context.commit('SET_METRICS', response.res.data)
            return response.res.data
        }
        context.commit('SET_METRICS', [])
    },
    async READ_ALL_METRICS(context) {
        const response = await API.Get('/api/options/metrics')
        if (response.type === 'Success') {
            context.commit('SET_ALL_METRICS', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_METRICS', [])
    },
    async CREATE_METRIC(context) {
        context.commit('SET_METRIC', {
            name: '', description: '', images: [], measurementUnit: '', teamId: '', siteId: '', files: [], frequency: 'none', every: 1,
            minRule: { enabled: false }, maxRule: { enabled: false }
        })
    },
    async SAVE_METRIC(context, payload) {
        if (payload.id === undefined) {
            const response = await API.Post('/api/metrics', payload)
            if (response.type === 'Success') {
                context.commit('CREATE_METRIC', response.res.data)
                return response.res.data;
            }
        } else {
            const response = await API.Put(`/api/metrics/${payload.id}`, payload)
            if (response.type === 'Success') {
                context.commit('UPDATE_METRIC', response.res.data)
                return response.res.data;
            }
        }
    },
    async READ_METRIC(context, payload) {
        const response = await API.Get(`/api/metrics/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_METRIC', response.res.data)
            context.commit('SET_METRIC_TYPE_ASSIGNED', response.res.data.teamId ? 'team' : 'site')
            return response.res.data
        }
    },
    async UPDATE_METRIC(context, payload) {
        const response = await API.Put(`/api/metrics/${payload.id}`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_METRIC', response.res.data)
            return response.res.data
        }
    },
    async DELETE_METRIC(context, payload) {
        const response = await API.Delete(`/api/metrics/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('DELETE_METRIC', response.res.data)
            context.commit('TOAST_MESSAGE', {
                type: 'success',
                title: i18n.global.t('metrics.metricDelete.title'),
                message: i18n.global.t('metrics.metricDelete.message')
            })
            return
        }
    },
    async SAVE_METRIC_MEASURE(context, payload) {
        const response = await API.Put(`/api/metrics/${payload.id}/measures`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_METRIC', response.res.data)
            return response.res.data
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
