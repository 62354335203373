<template>
  <div class="space-y-2">
    <div v-for="color in options" :key="color.id" class="flex items-center">
      <input type="radio"
          :id="color.id"
          name="radio-input"
          :checked="modelValue === color.id"
          @change="changeValue"
          :value="color"
          :readonly="lock"
             class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"/>
      <label class="ml-3 block text-sm font-medium text-gray-700 flex">
        <div class="w-7 h-7 rounded-2xl mx-2" :style="{'background':color.primary_color}"></div>
        <div class="w-7 h-7 rounded-2xl  mx-2" :style="{'background':color.secondary_color}"></div>
        {{ color.name }}
      </label>
    </div>
    <div class="flex">
      <app-form-field :label="$t('diseñoDeLaTienda.colorPrimario')" class="flex-1" v-if="modelValue.toString() === '6'">
        <color-picker colorFormat="hex" :visibleFormats="['rgb', 'hex']" alpha-channel="hide">
          <template #copy-button>
            <span class="sr-only">Copiar</span>

            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <path d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z" fill="currentColor" />
              <path d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z" fill="currentColor" />
            </svg>
          </template>
          <template #format-switch-button>
            <span class="sr-only">Formato</span>
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
              <path d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z" fill="currentColor" />
            </svg>
          </template>
        </color-picker>
      </app-form-field>

      <app-form-field :label="$t('options.appearance.secondaryColor')" class="flex-1" v-if="modelValue.toString() === '6'">
        <color-picker :visibleFormats="['hex','rgb']" alpha-channel="hide">
          <template #copy-button>
            <span class="sr-only">Copiar</span>

            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <path d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z" fill="currentColor" />
              <path d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z" fill="currentColor" />
            </svg>
          </template>
          <template #format-switch-button>
            <span class="sr-only">Formato</span>
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
              <path d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z" fill="currentColor" />
            </svg>
          </template>
        </color-picker>
      </app-form-field>
    </div>
  </div>
</template>

<script>

import { ColorPicker } from 'vue-accessible-color-picker'


export default {
  name: "AppColors",
  components: {
    ColorPicker,
  },
  props: {
    options: {
      required: true,
      type: Array
    },
    modelValue: {
      type: String,
      default: null,
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  methods: {
    changeValue(e) {
      this.$emit("update:modelValue", e.target.id);
    }
  }
};
</script>
