import API from '@/api'

const state = {
  CURRENT_COMPANY: null,
}

const getters = {
  GET_CURRENT_COMPANY: state => state.CURRENT_COMPANY,
}

const mutations = {
  // Branch
  SET_CURRENT_COMPANY(state, payload) {
    state.CURRENT_COMPANY = payload
  },
}

const actions = {
  // CRUD BRANCH
  async READ_CURRENT_COMPANY(context) {
    if (context.getters.GET_CURRENT_COMPANY !== null)
      return context.getters.GET_CURRENT_COMPANY

    const response = await API.Get('/api/company')
    if (response.type === 'Success') {
      context.commit('SET_CURRENT_COMPANY', response.res.data)
      return response.res.data;
    }
    context.commit('SET_CURRENT_COMPANY', null)

    return null;
  },
  async UPDATE_CURRENT_COMPANY(context, payload) {
    const response = await API.Put(`/api/company`, payload)
    if (response.type === 'Success') {
      context.commit('SET_CURRENT_COMPANY', response.res.data)
      return response.res.data;
    }
    return null;
  },

  async VALIDATE_EMAIL(context, emailValidation) {
    const response = await API.Get(`/api/company/email/validation/${emailValidation.email}`)
    if (response.type === 'Success') {
      return response.res.data;
    }
    return null;
  },
  async VALIDATE_SLUG(context, slugValidation) {
    const response = await API.Get(`/api/company/slug/validation/${slugValidation.slug}`)
    if (response.type === 'Success') {
      return response.res.data;
    }
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  async SEND_VALIDATION(context) {
    const response = await API.Post('/api/company/send_validation')
    if (response.type === 'Success') {
      context.commit('SET_CURRENT_COMPANY', response.res.data)
      return false;
    }
    return response.res;
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
