<template>
  <app-crud :extra-class="'bg-white'" v-slot="{ item }">
    <app-label :label="$t('records.parts.fields.name')" :value="item.name"/>
    <app-label :label="$t('records.parts.fields.code')" :value="item.code"/>
    <app-label :label="$t('records.parts.fields.serialNumber')" :value="item.serialNumber"/>
    <app-label v-if="item.images.length" :label="$t('records.parts.fields.images')" :value="item.images" :images="true"/>
    <app-label :label="$t('records.parts.fields.description')" :value="item.description"/>
    <app-label :label="$t('records.parts.fields.unitCost')" :value="item.unitCost"/>
    <app-label :label="$t('records.parts.fields.quantity')" :value="item.stock"/>
    <!--
    <app-label :label="$t('records.parts.fields.minStock')" :value="item.minStock"/>
    -->
    <app-label :label="$t('records.parts.fields.reservedStock')" :value="item.reservedStock"/>
    <app-label v-if="item.assets?.length > 0"
               :label="$getRecordLabel('assets') ? $getRecordLabel('assets') : $t('records.parts.fields.assets')"
               :icon="'location_dot_regular'"
               :icon-color="'fill-primary-500'"
               :class="'pre-wrap'"
               :value="getAssets(item.assets)"/>

    <app-label value=" " :label="$t('records.parts.fields.movements')">
      <table class="w-full">
        <tr>
          <th>{{ $t('records.parts.fields.operation') }}</th>
          <th>{{ $t('records.parts.fields.workOrderId') }}</th>
          <th align="center">{{ $t('income') }}</th>
          <th align="center">{{ $t('withdrawal') }}</th>
          <th align="center">{{ $t('records.parts.fields.stock') }}</th>
          <th align="center">{{ $t('records.parts.fields.dateTime') }}</th>
        </tr>
        <tr v-for="i in item.movements" :key="i.id">
          <td>{{ $t('records.parts.operations.' + i.operation) }}</td>
          <td>
            <span v-if="i.workOrderId" @click="navigateWorkOrder(i.workOrderId)" class="cursor-pointer underline">{{ i.workOrderName }}</span>
            <span v-else>-</span>
          </td>
          <td align="right">{{ i.used > 0 ? $formatNumber(i.used) : '' }}</td>
          <td align="right">{{ i.used < 0 ? $formatNumber(-i.used) : '' }}</td>
          <td align="right">{{ $formatNumber(i.endingStock) }}</td>
          <td align="center">{{ $formatDate(i.dateTime) }}</td>
        </tr>
      </table>
    </app-label>

  </app-crud>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PartViewPage",
  components: {},
  computed:{
    ...mapGetters({assets: 'GET_ALL_ASSETS', part: 'GET_PART'}),
  },
  methods: {
    getAsset(assetId) {
      const asset = this.assets.find(asset => asset.id === assetId)
      return asset?.name
    },
    getAssets() {
      return this.assets.filter(x => this.part.assets.includes(x.id)).map(x => "-"+x.name).join('\n')
    },
    formatTime(inputDate) {
      return this.$moment(inputDate).format('Y-M-D LT');
    },
    navigateWorkOrder(id)
    {
      this.$router.push('/workorders/' + id)
    }
  },
}
</script>
