function GetClass(v) {
    if(!v) return ''
    return values[v]?.class ?? '';
}
function GetContainerClass(v) {
    if(!v) return ''
    return values[v]?.containerClass ?? '';
}
function GetIcon(v){
    if(!v) return ''
    return values[v]?.icon ?? '';
}
export default Object.freeze({
    class: (v) => GetClass(v),
    containerClass: (v) => GetContainerClass(v),
    icon: (v) => GetIcon(v),
})

const values = {
    inProgress: {
        containerClass: 'bg-blue-50 border-blue-100 text-blue-800 fill-blue-500',
        class: 'text-blue-800 fill-blue-500',
        icon: 'circle_play_regular'
    },
    onHold: {
        containerClass: 'bg-yellow-50 border-yellow-100 text-yellow-800 fill-yellow-500',
        class: 'text-yellow-800 fill-yellow-500',
        icon: 'circle_pause_regular'
    },
    done: {
        containerClass: 'bg-green-50 border-green-100 text-green-800 fill-green-500',
        class: 'text-green-800 fill-green-500',
        icon: 'check_regular'
    },
    open: {
        containerClass: '',
        class: '',
        icon: 'unlock_regular'
    },
}
