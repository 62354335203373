<template>
  <div class="pb-2">
    <div class="flex">
      <app-icon :icon="icon" :extra-class="iconColor + ' mr-2'" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ label }}</span>
    </div>
    <app-dropdown v-model="internalValue" :getter="onlyUsers ? 'GET_ALL_ONLY_USERS' : 'GET_ALL_USERS'" :label="placeholder" :supp-text="$t('workorders.resourceSelectorPlaceholder')"/>
    <div class="mt-4" v-if="!otherField">
      <div v-for="user in selectedUsers" :key="user.id" class="my-2 flex items-center">
        <div>
          <user-avatar :user="user" width="w-10" height="h-10"></user-avatar>
        </div>
        <div class="w-full flex items-center justify-between">
          <div class="flex flex-col mx-2">
            <span class="font-nhu-semi-bold fs-14">{{user.name}}</span>
            <span class="font-nhu-500 fs-14 text-gray-500">{{user.role}}</span>
          </div>
          <div class="cursor-pointer">
            <app-icon icon="trash_regular" extra-class="fill-red-500" width="20" height="20" @click="deleteOption(user.id)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "AppUserSelector",
  components: {UserAvatar},
  emits: ["update:modelValue"],
  props: {
    modelValue: {type: Array, default: () => []},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    icon: {type: String, default: ''},
    iconColor: {type: String, default: ''},
    onlyUsers: {type: Boolean, default: false},
    otherField: {type: Boolean, default: false},
  },
  computed: {
    ...mapGetters({  users: 'GET_ALL_USERS'}),
    selectedUsers() {
      return this.internalValue.map(userId => this.getUser(userId)).filter(x => x);
    },
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectOption(value);
      }
    },
  },
  methods: {
    getUser(userId) {
      return this.users.find(user => user.id === userId);
    },
    deleteOption(userId) {
      this.internalValue = this.internalValue.filter(x => x !== userId);
    },
    selectOption(value) {
      this.$emit("update:modelValue", value)
    },
  }
}
</script>


<style scoped>

</style>
