<template>
  <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('teams.name')" :error="errorMsg" @update:model-value="clearErrorMsg"/>
    <app-image-list v-model="item.images" :rounded="false" :only-one-image="true" />
    <app-textarea v-model="item.description" :label="$t('teams.description')" />
    <app-color-picker :label="$t('teams.addColor')" :model-value="item"  @update:selectedColor="selectColor" />
    <app-user-selector v-model="item.supervisors" value="id" :label="$t('records.teams.fields.supervisors')" :only-users="true" getter="GET_ALL_USERS" icon="people_roof_regular" icon-color="fill-primary-500"/>
    <app-user-selector v-model="item.members" value="id" :label="$t('records.teams.fields.members')" :only-users="true" getter="GET_ALL_USERS" icon="users_regular" icon-color="fill-primary-500"/>
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>

export default {
  name: "TeamEditPage",
  components: { },
  data() {
    return {
      errorMsg: '',
      validated: false,
    }
  },
  methods: {
    onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.errorMsg = this.$t('teams.errors.name');
        onError();
      }
      else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
    selectColor(color, item) {
      item.color = color;
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>

<style scoped>

</style>
