<template>
  <button type="button" @click="click" class="rounded-lg flex px-4 py-2" v-show="$hasPermission(permission)"
    :class="[ colors, extraClass ? extraClass : '', icon ? 'flex items-center gap-x-1' : '', ]"
    :disabled="disabled || saving">
    <component :is="icon" v-if="icon" class="w-7 h-7"></component>
    <div class="w-full">
      <slot></slot>
    </div>
    <component :is="icon2" v-if="icon2" class="w-7 h-7 ml-2 my-auto"></component>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  components: { },
  emits: ["click"],
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Number, String], default: "" },
    icon: { type: [Function, Object], default: null },
    icon2: { type: [Function, Object], default: null },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    clean: { type: Boolean, default: false },
    error: { type: String, default: "" },
    extraClass: { type: String, default: "" },
    permission: { type: String, default: "" },
  },
  computed: {
    colors() {
      if(this.saving)
        return 'bg-gray-500 text-white';
      if(!this.clean) {
        if(this.disabled)
          return 'bg-gray-500 text-white hover:bg-gray-700';
        else
          return 'bg-primary-500 text-white hover:bg-primary-700 border-0';
      }
      else
        return '';
    }
  },
  methods: {
    click(e) {
      if(this.$hasPermission(this.permission))
        this.$emit('click', e)
    }
  }
};
</script>
