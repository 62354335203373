<template>
  <app-crud ref="crud"  v-slot="{ item }">
    <app-label :value="item?.name"  :label="$t('records.companies.fields.name')" />
    <app-label :value="item?.languaje" :label="$t('records.companies.fields.languaje')" />

    <app-label :value="item?.creationTime" :label="$t('records.companies.fields.creationTime')" />
    <app-label :value="item?.modificationTime" :label="$t('records.companies.fields.modificationTime')" />

    <Teleport to="#footer">
      <app-button @click="changeCompany(item.id)" type="secondary" background="light" :disabled="company.id === item.id">
        <span class="font-nhu-regular">{{$t('companies.login')}}</span>
      </app-button>
  </Teleport>
  </app-crud>
</template>

<script>
import { mapActions, mapGetters} from "vuex";

export default {
  name: "CompanyViewPage",
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY', }),
  },
  methods: {
    ...mapActions(['LOGIN_COMPANY']),
    async changeCompany(companyId) {
      await this.LOGIN_COMPANY({ id: companyId });
    }
  }
}
</script>

<style scoped>

</style>
