<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit w-full"
          @mousedown.self="closePopUp">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative overflow-hidden text-left transition-all transform bg-white rounded shadow-xl min-w-[800px] max-w-[11/12]">
              <div class="flex p-6 border-b-[1px] justify-between">
                <div class="font-nhu-semi-bold fs-18 w-full">
                  {{ $t('import.' + record.name) }}
                </div>
                <div class="w-fit h-fit hover:fill-gray-500 ml-4" @click="closePopUp">
                  <app-icon icon="xmark_regular" class="cursor-pointer" :width="20" :height="20"></app-icon>
                </div>
              </div>
              <div class="p-6">
                <div v-if="step === 1">
                  <div class="flex justify-end">
                    <div
                      class="rounded-[0.5rem] border-[1px] border-primary-500 p-1 bg-primary-50 font-nhu-500 fs-14 cursor-pointer mx-1 w-fit">
                      <app-icon icon="download_regular" :width="18" :height="18"
                        extra-class="fill-primary-500 text-primary-800" @click="downloadFormat">
                        <span class="hidden sm:block pl-1">{{ $t("import.download_format") }}</span>
                      </app-icon>
                    </div>
                  </div>
                  <app-file-list v-model="files" accept=".csv" text-accept="csv" :local-files="true"
                    @input="readFile" />
                </div>
                <div v-else-if="step === 2" class="overflow-y-auto h-[400px]">
                  <table class="min-w-full divide-y divide-gray-300" id="table">
                    <thead class="bg-gray-50">
                      <tr>
                        <th v-for="col in headers" :key="col.id" scope="col" class="p-4 text-left bg-gray-50">
                          <div class="flex items-center">
                            <span class="font-nhu-regular fs-14">{{
                              $t('records.' + record.name + '.fields.' + col)
                            }}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100 bg-white">
                      <tr v-for="data in importData" :key="data">
                        <td v-for="field in headers" :key="field" class="whitespace-nowrap p-4 font-nhu-regular fs-14">
                          {{ data[field] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="step === 3">
                  <div class="fs-18 font-nhu-500 mb-4">
                    {{ $t('import.have') + ' ' + importData.length + ' ' + $t('import.message') }}
                  </div>
                  <div class="fs-14 font-nhu-semi-bold">
                    {{ $t('import.from') }}
                  </div>
                  <div class="fs-14 font-nhu-regular">
                    {{ files[0]?.value }}
                  </div>
                </div>
              </div>
              <div class="flex justify-between w-full p-6 font-nhu-500 fs-14 border-t-[1px]">
                <app-button @click="previousStep" :clean="true" background="light"
                  extra-class="border-primary-500 text-primary-500 border-[1px] !px-3 shadow-sm shadow-inner rounded-lg">
                  {{ prevText }}
                </app-button>
                <app-button @click="nextStep" type="primary" background="light" v-if="files.length > 0">
                  {{ nextText }}
                </app-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { mapGetters, mapActions } from "vuex";
import AppFileList from "@/components/AppFileList";
import AppButton from "@/components/AppButton";

export default {
  name: "AppImportDialog",
  components: { AppButton, AppFileList, Dialog, DialogPanel, TransitionChild, TransitionRoot },
  emits: ["close", "import"],
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      importData: [],
      files: [],
      step: 1
    }
  },
  computed: {
    ...mapGetters({ record: 'GET_RECORD' }),
    headers() {
      return this.record.fields.filter(x => x.importable).map(x => x.id);
    },
    prevText() {
      if (this.step === 1) {
        return this.$t('import.cancel');
      } else {
        return this.$t('import.back');
      }
    },
    nextText() {
      if (this.step === 3) {
        return this.$t('import.confirm');
      } else {
        return this.$t('import.next');
      }
    }
  },
  methods: {
    ...mapActions(['SET_IMPORT_VISIBLE']),
    downloadFormat() {
      const csvString = this.headers.join(',') + '\n' + this.headers.map(x => this.$t('records.' + this.record.name + '.fields.' + x)).join(',') + '\n';
      const universalBOM = "\uFEFF";
      const a = window.document.createElement('a');
      a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
      a.setAttribute('download', this.record.name + '_example.csv');
      window.document.body.appendChild(a);
      a.click();
    },
    parseCSV(text) {
      let arr = text.trim().split('\n')
      let jsonObj = []
      let headers = arr[0].replace('ï»¿', '').replace('\r', '').replaceAll(" ", "").split(',')
      headers = headers.map(x => x.substring(0, 1).toLowerCase() + x.substring(1))
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].replaceAll(",", "") === '') continue;
        let data = arr[i].split(',')
        let obj = {};
        for (let j = 0; j < data.length; j++) {
          obj[headers[j]] = data[j].trim()
        }
        // dont push objects with empty names
        if (obj.name !== '') {
          jsonObj.push(obj)
        }
      }
      this.importData = this.importData.concat(jsonObj)
    },
    readFile(evt) {
      let file = evt.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.parseCSV(e.target.result);
      };
      reader.readAsText(file, 'UTF-8');
    },
    closePopUp() {
      this.$emit("close");
      this.importData = [];
      this.files = [];
    },
    previousStep() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.closePopUp();
      }
    },
    nextStep() {
      if (this.step >= 3) {
        this.$emit("import", this.importData)
        this.closePopUp()
      } else {
        this.step++
      }
    },
    mounted() {
      this.step = 1;
    }
  },
}
</script>
