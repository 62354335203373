<template>
  <div v-if="internalValue" class="grid grid-cols-1 sm:grid-cols-2 gap-2">
    <div>
      <app-date-picker v-model="internalDueDate" :disabled="disabled || onlyView" :label="$t('workorders.dueDate')"
        :suppText="$t('workorders.formatDate')" :min-date="prevOrders ? null : minDate" />
    </div>

    <div v-if="isOrderType(['preventive', 'metric', 'inspection'])" class="w-full">
      <div>
        <app-dropdown v-model="internalValue.periodically" :disabled="!internalDueDate || onlyView"
          :options="resolvedSchedules" :label="$t('workorders.periodicity')" @change="updateSchedule" />
      </div>
    </div>

    <div v-if="internalValue.periodically === 'daily' || internalValue.periodically === 'weekly'"
      class="inline-flex flex-nowrap mt-4">

      <button @click="updateWeekDays(day.id)" type="button" v-for="day in days" :key="day.id"
        :class="weekdays.indexOf(day.id) >= 0 || internalValue.periodically === 'daily' ? 'bg-blue-500 text-white border-blue-200' : 'bg-gray-50 border-gray-100'"
        class="flex items-center justify-center mx-1 w-10 h-10 sm:w-12 sm:h-12 border-2 rounded-full overflow-hidden"
        :disabled="disabled || onlyView">
        <span class="font-nhu-regular flex items-center justify-center truncate">{{ day.name }}</span>
      </button>

    </div>

    <app-dropdown v-if="internalValue.periodically === 'weekly' && weekdays.length < 2" v-model="internalValue.every"
      value="value" :label="$t('workorders.scheduleOptions.every')" :options="weeklyOptions"
      :icon-label="'repeat_regular'" :disabled="disabled || onlyView" />
    <app-dropdown v-else-if="internalValue.periodically === 'monthly'" v-model="internalValue.every"
      :options="monthlyOptions" value="value" :label="$t('workorders.scheduleOptions.every')"
      :disabled="disabled || onlyView" :icon-label="'repeat_regular'" />
    <app-dropdown v-else-if="internalValue?.periodically === 'yearly'" v-model="internalValue.every"
      :options="yearlyOptions" value="value" :label="$t('workorders.scheduleOptions.every')"
      :disabled="disabled || onlyView" :icon-label="'repeat_regular'" />

    <app-dropdown v-if="internalValue.periodically === 'monthly'" v-model="internalValue.day" :options="dailyOptions"
      value="value" :label="$t('workorders.scheduleOptions.onThe')" :disabled="disabled || onlyView" />
  </div>

</template>

<script>
import { PlusIcon, ArrowPathIcon } from "@heroicons/vue/24/outline";
import AppDropdown from "@/components/AppDropdown.vue";

export default {
  name: "WorkOrderSchedulePicker",
  components: { AppDropdown },
  emits: ["update:modelValue", "updateDueDate"],
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Number, String, Object], default: "" },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    orderType: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    schedules: { type: Array, default: () => [] },
    dueDate: { type: [Date, String], default: null },
    onlyView: { type: Boolean, default: false },
  },
  data() {
    return {
      startDate: false,
      defaultSchedules: [
        { id: 'none', name: this.$t('workorders.scheduleOptions.none') },
        { id: 'daily', name: this.$t('workorders.scheduleOptions.daily') },
        { id: 'weekly', name: this.$t('workorders.scheduleOptions.weekly') },
        { id: 'monthly', name: this.$t('workorders.scheduleOptions.monthly') },
        { id: 'yearly', name: this.$t('workorders.scheduleOptions.yearly') }
      ],
      weeklyDays: [0],
      days: [
        { id: 0, name: this.$t('workorders.scheduleOptions.days.monday') },
        { id: 1, name: this.$t('workorders.scheduleOptions.days.tuesday') },
        { id: 2, name: this.$t('workorders.scheduleOptions.days.wednesday') },
        { id: 3, name: this.$t('workorders.scheduleOptions.days.thursday') },
        { id: 4, name: this.$t('workorders.scheduleOptions.days.friday') },
        { id: 5, name: this.$t('workorders.scheduleOptions.days.saturday') },
        { id: 6, name: this.$t('workorders.scheduleOptions.days.sunday') },
      ],
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    internalDueDate: {
      get() {
        return this.dueDate;
      },
      set(value) {
        this.$emit("updateDueDate", value);
      },
    },
    resolvedSchedules() {
      return this.schedules.length > 0 ? this.schedules : this.defaultSchedules;
    },
    weeklyOptions() {
      const options = [];
      for (let i = 1; i <= 52; i++) {
        if (i === 1) {
          options.push({ value: i, name: i.toString() + this.$t('schedule.week') });
        } else options.push({ value: i, name: i.toString() + this.$t('schedule.weeks') });
      }
      return options;
    },
    monthlyOptions() {
      const options = [];
      for (let i = 1; i <= 24; i++) {
        if (i === 1) {
          options.push({ value: i, name: i.toString() + this.$t('schedule.month') });
        } else options.push({ value: i, name: i.toString() + this.$t('schedule.months') });
      }
      return options;
    },
    dailyOptions() {
      const options = [];
      for (let i = 1; i <= 31; i++) {
        options.push({ value: i, name: i.toString() });
      }
      return options;
    },
    yearlyOptions() {
      const options = [];
      for (let i = 1; i <= 50; i++) {
        if (i === 1) {
          options.push({ value: i, name: i.toString() + this.$t('schedule.year') });
        } else options.push({ value: i, name: i.toString() + this.$t('schedule.years') });
      }
      return options;
    },
    weekdays() {
      return (this.internalValue.weekDays) ? this.internalValue.weekDays : [];
    },
    minDate() {
      const today = new Date();
      return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    },
    prevOrders() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const dueDate = new Date(this.internalDueDate);
      return this.internalDueDate && dueDate < today;
    },
  },
  methods: {
    ArrowPathIcon,
    PlusIcon,
    isOrderType(values) {
      return values.includes(this.orderType);
    },
    updateSchedule() {
      if (this.internalValue.periodically === 'weekly') {
        if (this.internalValue.weekDays?.length === 0) {
          this.internalValue.weekDays = [0];
        }
        this.$emit('update:modelValue', this.internalValue)
      }
    },
    updateWeekDays(day) {
      let indexOfDay = this.weekdays?.indexOf(day);
      if (indexOfDay >= 0) {
        if (this.weekdays.length > 1) {
          this.weekdays.splice(indexOfDay, 1);
        }
      } else {
        this.weekdays.push(day);
      }
      if ([0, 1, 2, 3, 4, 5, 6].every(num => this.weekdays.includes(num))) {
        this.internalValue.periodically = 'daily';
        this.internalValue.weekdays = null
        this.$emit('update:modelValue', this.internalValue)
      } else {
        this.internalValue.periodically = 'weekly';
        this.$emit('update:modelValue', this.internalValue)
      }
      this.internalValue.weekDays = this.weekdays
    },
  },
}
</script>

<style scoped></style>
