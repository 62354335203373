<template>
  <!--    <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled"-->
  <!--                         v-slot="{focusin, focusout}" :expand="true">-->
<div>
    <div v-if="allowUploadImage" class="relative w-full mt-4"
         @drop.prevent="imageDrop"
         @dragover.prevent="dragover = true"
         @dragleave.prevent="dragover = false"
         v-show="showNewButton && cantImage && !disabled && !lock">
        <!--            <div v-if="modelValue.length >= 1" @click="!disabled && $refs.file.click()"-->
        <!--                 :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }"-->
        <!--                 class="flex items-center justify-center overflow-hidden bg-white border-2 border-primary-500 border-dashed cursor-pointer group w-28 h-28 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500 hover:brightness-95">-->
        <!--                <PlusIcon class="h-6 text-primary-500" v-if="!disabled"/>-->
        <!--                <input id="file_input" class="hidden" type="file" ref="file"-->
        <!--                       @change="showUploadFilePopUp($event.target)"/>-->
        <!--            </div>-->
        <div @click="!disabled && $refs.file.click()"
             :class="{ 'rounded-full': rounded, 'rounded': !rounded }"
             class="flex items-center justify-center overflow-hidden bg-gray-25 border-box border-dashed cursor-pointer group w-full h-38 p-6 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500 hover:brightness-95">
            <div class="flex flex-col items-center fill-primary-500">
                <app-icon v-if="!disabled" extra-class="mb-1" icon="camera_regular" :width="24" :height="24"/>
                <span class="text-primary-500 font-nhu-regular fs-14">{{ userImage ? $t('addProfilePhoto')  : $t('addImages') }}</span>
                <div class="my-1 flex flex-col text-center">
                    <span class="font-nhu-regular text-gray-900 fs-10">{{ $t('acceptedFormats') }}</span>
                    <span class="font-nhu-regular text-gray-900 fs-10">{{ $t('maxUpload') }}</span>
                </div>
            </div>
            <input id="file_input" class="hidden" type="file" ref="file"
                   accept="image/*"
                   @change="showUploadFilePopUp($event.target)"/>
        </div>
    </div>
    <ul v-if="modelValue?.length >= 1" role="list" class="flex overflow-auto gap-x-2 gap-y-4 xl:gap-x-4 w-full h-full mt-4">
      <li v-for="image in modelValue"
          :key="image">
        <div class="relative block hover:border overflow-hidden group w-[136px] h-[136px] aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-primary-100 rounded-xl"
             @click="zoomImage(image)">

          <app-image :src="image" alt="" :rounded="rounded" rounded_type="md"/>

                <div @click.stop="removeImage(image)" class="absolute top-0 right-0 z-10 cursor-pointer"
                     v-if="!disabled">
                    <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray"/>
                </div>

                <div @click="zoomImage(image)"
                     class="absolute right-0 z-10 cursor-pointer p-2"
                     :class="{ 'top-0': disabled, 'top-8': !disabled }">
                  <app-icon icon="magnifying_glass_regular" :class="'rounded fill-primary-500'" :width="24" :height="24" />
                </div>

                <button type="button" class="absolute inset-0"
                        :class="disabled ? '' : 'cursor-grab active:cursor-grabbing'">
                    <span class="sr-only">Image</span>
                </button>
            </div>
        <app-pop-up-container :show="isZoomImage" :image="selectedImageUrl" @close="isZoomImage = false"/>
      </li>

        <li v-if="imageUploading && allowUploadImage && !disabled && !lock">
            <div class="relative block overflow-hidden bg-gray-100 group w-28 h-28 aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500"
                 :class="{ 'rounded-full': rounded, 'rounded-xl': !rounded }">
                <div class="flex items-center justify-center w-full h-full">
                    <app-loading :loading="true"/>
                </div>
            </div>
        </li>
    </ul>

  <!--    </app-input-container>-->

    <AppEditImage v-model:open-pop-up="showEditImagePopUP" :file="editImageFile" @uploadImage="v=>uploadFile(v)"
                  @clearInput="clearInput" :maxSize="maxSize" :rounded="rounded" v-model:loader="imageUploading"/>
</div>
</template>

<script>
import {XCircleIcon} from '@heroicons/vue/24/outline'
import AppImage from "@/components/AppImage";
import AppEditImage from "@/components/AppEditImage";
import Images from "@/services/images";
import {mapActions} from 'vuex';

export default {
  name: "AppImageList",
  emits: ['update:modelValue', 'change'],
  components: {AppEditImage, AppImage, XCircleIcon},
  props: {
    id: {type: String, default: ''},
    modelValue: { type: Array, default() { return [] }, },
    label: {type: String, default: ""},
    iconLabel: {type: Function, default: null},
    max: {type: Number, default: 10},
    lock: {type: Boolean, default: false},
    rounded: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    allowUploadImage: {type: Boolean, default: true},
    maxSize: {type: Number, default: 0},
    editor: {type: Boolean, default: false},
    onlyOneImage: {type: Boolean, default: false},
    userImage: {type: Boolean, default: false},
  },
  data() {
    return {
      editImageFile: null,
      files: this.modelValue,
      imageUploading: false,
      showEditImagePopUP: false,
      selectedImageUrl: '',
      dragover: false,
      addImage: true,
      isZoomImage: false,
      maxWidth: 800,
      maxHeight: 600,
    }
  },
  computed: {
    showNewButton() {
      return (this.modelValue?.length ?? 0) < this.max;
    },
    cantImage() {
      return !(this.modelValue?.length >= 1 && this.onlyOneImage === true);
    },
  },
  methods: {
    ...mapActions(['FIRE_MESSAGE']),

    showUploadFilePopUp(target) {
      if (this.editor) {
        if (this.maxSize == 0 || target.files[0].size < this.maxSize) {
          this.compressImage(target.files[0], 800, 600)
              .then((compressedFile) => {
                if (compressedFile instanceof Blob) {
                  const reader = new FileReader()
                  let rawImg;
                  reader.onloadend = () => {
                    rawImg = reader.result;
                    this.editImageFile = rawImg
                    this.showEditImagePopUP = true
                  }
                  reader.readAsDataURL(compressedFile);
                } else {
                  console.error('Error: compressedFile is not a Blob')
                }
              })
              .catch((error) => {
                console.error('Error during image compression:', error)
              })
        } else
          this.FIRE_MESSAGE({message: 'File too heavy'})
      } else {
        this.imageUploading = true
        let files = []
        if (this.modelValue != null)
          files = this.modelValue.slice(0);

        this.compressImage(target.files[0], 800, 600)
            .then((compressedFile) => {
              Images.Upload([compressedFile])
                  .then((names) => {
                    names.forEach(name => files.push(name));
                    this.imageUploading = false
                    this.$emit("update:modelValue", files);
                    this.$emit("change");
                  });
            })
            .catch((error) => {
              console.error('Error during image compression:', error)
            })
      }
    },

    compressImage(file, maxWidth, maxHeight, quality = 0.95) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')

          if (img.width > maxWidth || img.height > maxHeight) {
            if (img.width > img.height) {
              canvas.width = maxWidth
              canvas.height = maxWidth * (img.height / img.width)
            } else {
              canvas.height = maxHeight
              canvas.width = maxHeight * (img.width / img.height)
            }
          } else {
            canvas.width = img.width
            canvas.height = img.height
          }

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => {
            let compressedFile = new File([blob], file.name, {type: 'image/webp', lastModified: Date.now()})
            resolve(compressedFile)
          }, 'image/webp', quality)
        }
        img.onerror = reject
      })
    },

    clearInput() {
      document.getElementById('file_input').value = ''
    },

    uploadFile(file) {
      this.showEditImagePopUP = false
      this.imageUploading = true

      let files = []
      if (this.modelValue != null)
        files = this.modelValue.slice(0);

      let array = []
      array.push(file)
      Images.Upload(array)
        .then((names) => {
          names.forEach(name => files.push(name));
          this.imageUploading = false
          this.$emit("update:modelValue", files);
          this.$emit("change");
        });
    },

    removeImage(image) {
      let newList = this.modelValue.filter(x => x !== image);
      this.$emit("update:modelValue", newList);
      this.$emit("change");
    },

    zoomImage(imageUrl) {
      this.selectedImageUrl = imageUrl;
      this.isZoomImage = true;
    },

    onDrop(event, replacedImage) {
      let from = this.modelValue.indexOf(event.dataTransfer.getData('movingImage'))
      let to = this.modelValue.indexOf(replacedImage)
      this.modelValue.move(from, to)
      this.$emit("update:modelValue", this.modelValue);
      this.$emit("change");
    },
    startDrag(event, movingImage) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('movingImage', movingImage)
    },
    editImage(imageUrl) {
      this.selectedImageUrl = imageUrl
      this.showEditImagePopUP = !this.showEditImagePopUP
    },
    imageDrop(event) {
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const input = this.$refs.file
        input.files = files
        this.showUploadFilePopUp(input)
      }
      this.dragover = false
    },
  },
  setup() {
    Array.prototype.move = function (from, to) {
      return this.splice(to, 0, this.splice(from, 1)[0]);
    };
  }
};
</script>

<style>
.grabbing * {
    cursor: grabbing !important;
}
.no-drag {
  cursor: default !important;
}
</style>
