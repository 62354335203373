<template>
    <div>
        <div v-if="getUserConversationData(conversation.members) && getUserConversationData(conversation.members)?.images?.length >= 1 && (conversation.type === 'private' || conversation.type === 1) ">
            <div class="w-full">
                <div class="flex justify-center">
                    <app-image :src="getUserConversationData(conversation.members)?.images" alt="user"
                               class="w-[200px] h-[200px] mr-2 rounded-full"/>
                </div>
                <div class="flex justify-center">
                    <span class="font-nhu-semi-bold text-[20px]">{{ conversation.name }}</span>
                </div>
                <div class="flex justify-center mt-5">
                    <span>{{ $t('conversations.privateChat') }}</span>
                </div>
                <div>
                    <div class="h-0 border-b border-gray-200 mx-5 my-4"/>
                </div>
                <div class="flex">
                    <div class="w-1/2">
                        <div class="mx-5">
                            <div class="mb-3">
                                <span>{{ $t('conversations.members') }}</span>
                            </div>
                            <div v-for="member in conversation.members" :key="member.id" class="my-3">
                                <div class="flex items-center">
                                    <div v-if="getUser(member) && getUser(member)?.images?.length >= 1">
                                        <app-image :src="getUser(member)?.images"
                                                   class="w-10 h-10 mr-3"/>
                                    </div>
                                    <div v-else>
                                        <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                                    </div>
                                    <div>
                                        <div>
                                            {{ getUser(member).name }}
                                        </div>
                                        <div>
                                            <span class="text-xs">{{
                                                getUser(member).role
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="conversation.type === 'private' || conversation.type === 1">
            <div class="w-full">
                <div class="flex justify-center">
                    <UserIcon class="w-[200px] h-[200px] mr-2 rounded-full"/>
                </div>
                <div class="flex justify-center">
                    <span class="font-nhu-semi-bold text-[20px]">{{ conversation.name }}</span>
                </div>
                <div class="flex justify-center mt-5">
                    <span>{{ $t('conversations.privateChat') }}</span>
                </div>
                <div>
                    <div class="h-0 border-b border-gray-200 mx-5 my-4"/>
                </div>
                <div class="flex">
                    <div class="w-1/2">
                        <div class="mx-5">
                            <div class="mb-3">
                                <span>{{ $t('conversations.members') }}</span>
                            </div>
                            <div v-for="member in conversation.members" :key="member.id" class="my-3">
                                <div class="flex items-center">
                                    <div v-if="getUser(member) && getUser(member)?.images?.length >= 1">
                                        <app-image :src="getUser(member)?.images"
                                                   class="w-10 h-10 mr-3"/>
                                    </div>
                                    <div v-else>
                                        <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                                    </div>
                                    <div>
                                        <div>
                                            {{ getUser(member).name }}
                                        </div>
                                        <div>
                                            <span class="text-xs">{{
                                                getUser(member).role
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="w-full flex justify-center">
            <div class="w-full">
                <div class="flex justify-center">
                    <UserGroupIcon class="w-[200px] h-[200px] mr-2 rounded-full"/>
                </div>
                <div class="flex justify-center">
                    <span class="font-nhu-semi-bold text-[20px]">{{ conversation.name }}</span>
                </div>
                <div class="flex justify-center mt-5">
                    <span>{{ $t('conversations.groupChat') }}</span>
                </div>
                <div>
                    <div class="h-0 border-b border-gray-200 mx-5 my-4"/>
                </div>
                <div class="flex">
                    <div class="w-1/2">
                        <div class="mx-5">
                            <div class="mb-3">
                                <span>{{ $t('conversations.members') }}</span>
                            </div>
                            <div v-for="member in conversation.members" :key="member.id" class="my-3">
                                <div class="flex items-center justify-between">
                                    <div class="flex">
                                        <div v-if="getUser(member) && getUser(member)?.images?.length >= 1">
                                            <app-image :src="getUser(member)?.images"
                                                       class="w-10 h-10 mr-3"/>
                                        </div>
                                        <div v-else>
                                            <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                                        </div>
                                        <div>
                                            <div>
                                                {{ getUser(member).name }}
                                            </div>
                                            <div>
                                                <span class="text-xs">{{ getUser(member).role }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-20">
                                        <app-button @click="removeMember(member)">{{ $t('remove') }}</app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/2">
                        <div class="mx-5">
                            <app-button :clean="true" :icon="PlusIcon" @click="showUsers">
                                {{ $t('conversations.addMembersToChat') }}
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import {PlusIcon, UserGroupIcon, UserIcon} from "@heroicons/vue/24/outline";
import AppImage from "@/components/AppImage.vue";

export default {
  name: "ConversationInfo",
  components: {AppImage, UserIcon, UserGroupIcon, AppButton},
  emits: ['showUsers', 'deleteMember'],
  props: {
    conversation: {
      type: Object,
      default: () => {
      }
    },
    userData: {
      type: Object,
      default: () => {
      }
    },
    users: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    PlusIcon,
    getUserConversationData(members) {
      return this.getUser(members.find(x => x.id !== this.userData.id));
    },
    getUser(senderId) {
      const user = this.users.find(x => x.id === senderId);
      return user || {};
    },
    showUsers() {
      this.$emit("showUsers");
    },
    removeMember(memberId) {
        this.$emit("deleteMember", memberId);
    }
  }
}
</script>

<style scoped>

</style>
