<template>
  <div v-if="logs.length">
    <div class="text-left mt-5 flex flex-col">
      <div v-for="(logs, date) in groupedLogs" :key="date">
        <div>
          <div class="flex justify-center">
            <div class="bg-blue-50 px-2 py-[2px] rounded-full border border-blue-100 my-[10px]">
                  <span class="font-nhu-regular text-blue-800 text-xs">
                    {{ formatDate(logs[0]?.creationTime) }}
                  </span>
            </div>
          </div>
          <div v-for="log in logs" :key="log.id">
            <div class="flex items-start">
              <div class="flex flex-col justify-between flex-grow max-w-xl">
                <div class="rounded-none rounded-tr-xl rounded-br-xl rounded-bl-xl py-2 flex justify-start">
                  <log-card :log="log">
                  </log-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogCard from "@/components/LogCard";

export default {
  name: "AppLog",
  components: {LogCard},
  props: {
    logs: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    groupedLogs() {
      if (!this.logs) {
        return {};
      }
      return this.logs.reduce((grouped, comment) => {
        const date = comment.sentDate?.split('T')[0];
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(comment);
        return grouped;
      }, {});
    },
    lang() {
      return this.$i18n.locale
    },
  },
  methods: {
    formatDate(inputDate) {
      if (!inputDate) {
        return '';
      }

      let date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        return this.$t('invalidDate');
      }

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

      const todayString = today.toISOString().split('T')[0];
      const yesterdayString = yesterday.toISOString().split('T')[0];
      const dateString = date.toISOString().split('T')[0];

      let formattedDate = '';

      if (dateString === todayString) {
        return this.$t('today');
      } else if (dateString === yesterdayString) {
        return this.$t('yesterday');
      } else {
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        if (this.lang === 'en') {
          formattedDate = date.toLocaleDateString('en-US', options);
        } else if (this.lang === 'pt') {
          formattedDate = date.toLocaleDateString('pt-PT', options);
        } else {
          formattedDate = date.toLocaleDateString('es-ES', options);
          formattedDate = formattedDate.replace(',', '').substring(0, formattedDate.lastIndexOf('de') - 1);
        }
      }

      return formattedDate;
    }
  },
}
</script>
