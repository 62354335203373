<template>
  <app-crud ref="crud" v-slot="{ lock }" :loading="loading" @validate="onValidate" @duplicate="duplicateSite()"
    @assignToWorkOrder="assingToWorkOrder()">
    <app-textbox v-model="site.name"
      :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : this.$t('sites.name')"
      :disabled="lock" :error="errorMsg" @update:model-value="clearErrorMsg" />
    <app-address v-model="site.location" :label="$t('sites.location')" :disabled="lock" />
    <app-textarea v-model="site.description" :label="$t('sites.description')" :disabled="lock" />
    <app-crud-properties v-model="site.props" />
    <app-image-list v-model="site.media" :rounded="false" :disabled="lock" />
    <div class="my-4">
      <app-file-list v-model="site.files" :rounded="false" :disabled="lock" />
    </div>

    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SiteEditPage",
  components: {},
  data() {
    return {
      loading: true,
      alert: {
        active: false,
        show: false,
        type: '',
        title: ''
      },
      siteId: '',
      errorMsg: '',
      validated: false,
    }
  },
  computed: {
    ...mapGetters({ site: 'GET_SITE', workorder: 'GET_WORKORDER', company: 'GET_CURRENT_COMPANY' }),
    action() { return this.$route.meta.action; },
    showDate() {
      return typeof this.site.status === 'object' ? this.site.status.id === 'standby' : this.site.status === 'standby'
    },
    getHeight() {
      const t = document.getElementById('displayCtrl');
      const top = t?.offsetTop ?? 75;

      if (this.clientHeight > 300)
        return (this.clientHeight - top - 25 + (this.clientWidth * 0)) + 'px';
      else
        return '200px';
    },
  },
  methods: {
    ...mapMutations(['SET_WORKORDER', 'SET_SITE']),
    ...mapActions(['READ_SITE', 'CREATE_SITE', 'SAVE_SITE', 'DELETE_SITE', 'SAVE_WORKORDER', 'CREATE_WORKORDER', 'READ_ALL_SITES']),
    async duplicateSite() {
      this.$router.push('/sites/create?duplicate=' + this.siteId);
    },
    async assingToWorkOrder() {
      this.CREATE_WORKORDER()
      let workOrder = this.workorder
      workOrder.siteId = this.site.id
      await this.SAVE_WORKORDER(workOrder)
      this.$router.push("/workorders/" + this.workorder.id + "/edit")
    },
    async onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.alert.active = true
        this.alert.show = true
        this.alert.title = this.$t('records.sites.errors.nameRequired')
        this.errorMsg = this.$t('records.sites.errors.nameRequired')
        onError();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    getStatusName(status) {
      switch (status) {
        case 'active':
          return this.$t('admin.site.active');
        case 'standby':
          return this.$t('admin.site.standby');
        case 'inactive':
          return this.$t('admin.site.inactive');
        default:
          return status;
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
