const state = {
  LOADING: false,
  ERRORS: [],
  TOASTS: [],
  token: null,
  COMPANY: {},
}

const getters = {
  GET_LOADING: state => state.LOADING,
  GET_DRAWER: state => state.DRAWER,
  GET_ERRORS: state => state.ERRORS,
  GET_TOAST: state => state.TOASTS,
}

const mutations = {
  SET_LOADING(state, payload) {
    state.LOADING = payload
  },
  SET_DRAWER(state, payload) {
    state.DRAWER = payload
  },
  SET_RECORDS(state, payload) {
    state.RECORDS = payload
  },
  SET_RECORD(state, payload) {
    state.RECORD = payload
  },
  SET_REMOVE_ERROR(state, payload) {
    state.ERRORS = state.ERRORS.filter(x => x.id !== payload.id)
  },
  FIRE_MESSAGE(state, payload) {
    state.ERRORS.push({
      ...payload,
      id: Math.random().toString(36).substring(2, 15)
    });
  },
  SET_REMOVE_ALL_TOASTS(state) {
    state.TOASTS = []
  },
  SET_REMOVE_TOAST(state, payload) {
    state.TOASTS = state.TOASTS.filter(x => x.id !== payload.id)
  },
  TOAST_MESSAGE(state, payload) {
    state.TOASTS.push({
      ...payload,
      id: Math.random().toString(36).substring(2, 15)
    });
  },
}

const actions = {
  REMOVE_ERROR(context, payload) {
    context.commit('SET_REMOVE_ERROR', payload);
  },
  FIRE_MESSAGE(context, payload) {
    context.commit('FIRE_MESSAGE', payload);
  },
  REMOVE_TOAST(context, payload) {
    context.commit('SET_REMOVE_TOAST', payload);
  },
  TOAST_MESSAGE(context, payload) {
    context.commit('TOAST_MESSAGE', payload);
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
