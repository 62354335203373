<template>
  <div class="h-full pt-[41px]">
    <div class="flex border-b mb-8">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="hidden sm:block">{{ $t('options.features.title') }}</span>
      </h1>
    </div>

    <div class="max-w-4xl" v-if="company !== null">

      <!--Dashboard-->
      <!-- Se oculta el módulo de dashboard -->
  <!--<div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="chart_line_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('dashboard') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'dashboard')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <div class="ml-4">
                <app-checkbox v-model="company.modules.dashboard" @change="save(company.modules.dashboard.toString())"
                              :saving="saving"/>
              </div>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.dashboard.description') }}
            </span>
          </div>
        </div>
      </div>-->

      <!--Workorders-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex pb-1.5 justify-between">
            <div class="flex ">
              <app-icon extra-class="mr-2 pb-1.5" icon="inbox_full_regular" :width="20" :height="20"
                        :fill="this.$colors.primary[500]"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5 cursor-pointer">
                                {{ getLabel('workorders') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'workorders')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
          </div>

          <div class="flex  pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{ $t('features.workorders.description') }}</span>
          </div>
          <div>
            <div class="flex justify-left" @click="goto('/admin/options/workorder-settings')">
              <span class="fs-14 font-nhu-500 text-primary-500 cursor-pointer">{{ $t('features.settings') }}</span>
            </div>
          </div>
          <div class="flex-col flex">
            <div class="fs-14 font-nhu-500 text-primary-500 cursor-pointer" @click="goToCustomFields('workorder')">
              <span>
                {{$t('customize_fields.workorder')}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!--Sites-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex  pb-1.5 justify-between">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5" icon="location_dot_regular" :width="20" :height="20"
                        :fill="this.$colors.primary[500]"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('sites') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'sites')"
                  class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox v-model="company.modules.sites" @change="save(company.modules.sites.toString())"
                            :saving="saving"/>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{ $t('features.sites.description') }}</span>
          </div>
        </div>
        <div class="flex-col flex">
          <div class="fs-14 font-nhu-500 text-primary-500 cursor-pointer" @click="goToCustomFields('site')">
            <span>
              {{$t('customize_fields.site')}}
            </span>
          </div>
        </div>
      </div>


      <!--Request-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="inbox_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('requests') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'requests')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox v-model="company.modules.requests" @change="save(company.modules.requests.toString())"
                            :saving="saving"/>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{$t('options.features.requests_description') }}
            </span>
          </div>
          <div>
            <div class="flex justify-left" v-if="company.modules.requests">
              <span class="fs-14 font-nhu-500 text-primary-500 cursor-pointer"
                    @click="goto('/request-landings')">
                {{ $t('options.features.goto_requests') }}
              </span>
              <app-icon extra-class="ml-2 fill-primary-500" icon="arrow_right_solid" :width="20" :height="20"/>
            </div>
          </div>
        </div>
      </div>

      <!--Assets-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex  pb-1.5 justify-between">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5" icon="cubes_regular" :width="20" :height="20"
                        :fill="this.$colors.primary[500]"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('assets') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'assets')"
                  class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox v-model="company.modules.assets" @change="save(company.modules.assets.toString())"
                            :saving="saving"/>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">{{ $t('features.assets.description') }}</span>
          </div>
        </div>
        <div class="flex-col flex">
          <div class="fs-14 font-nhu-500 text-primary-500 cursor-pointer" @click="goToCustomFields('asset')">
            <span>
              {{$t('customize_fields.asset')}}
            </span>
          </div>
        </div>
      </div>

      <!--Parts-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="gears_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('parts') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'parts')"
                  class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <div class="ml-4">
                <app-checkbox v-model="company.modules.parts" @change="save(company.modules.parts.toString())"
                              :saving="saving"/>
              </div>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.parts.description') }}
            </span>
          </div>
        </div>
      </div>

      <!--Metrics-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="gauge_high_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('metrics') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'metrics')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <div class="ml-4">
                <app-checkbox v-model="company.modules.metrics" @change="save(company.modules.metrics.toString())"
                              :saving="saving"/>
              </div>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.metrics.description') }}
            </span>
          </div>
        </div>
      </div>



      <!-- Inspecciones -->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="file_lines_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('templates') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'templates')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <div class="ml-4">
                <app-checkbox v-model="company.modules.templates" @change="saveTemplatesAndInspectionsModule()" :saving="saving"/>
              </div>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.templates.description') }}
            </span>
          </div>
        </div>
      </div>

        <!-- procedures -->
        <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="file_lines_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('procedures') }}
              </span>
              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'procedures')"
                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">
                    {{ $t('features.record_labels') }}
              </span>
            </div>
            <div class="flex">
              <div class="ml-4">
                <app-checkbox v-model="company.modules.procedures" @change="saveTemplatesAndInspectionsModule()" :saving="saving"/>
              </div>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.procedures.description') }}
            </span>
          </div>
        </div>
      </div>

      <!--Categories-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="inbox_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('categories') }}
              </span>
            </div>
            <div class="flex">
              <app-checkbox v-model="company.modules.categories" @change="save(company.modules.categories.toString())"
                            :saving="saving"/>
            </div>
          </div>
        </div>
        <div class="flex justify-between pb-1.5">
          <span class="fs-14 font-nhu-regular text-gray-500">
            {{ $t('features.categories.description') }}
          </span>
        </div>


      </div>

      <!-- Users-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="file_lines_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                  {{ getLabel('users') }}
              </span>
<!--              <span @click="goToRecordLabels('/admin/options/option-record-labels', 'users')"-->
<!--                    class="fs-14 pl-4 font-nhu-500 text-primary-50 hover:text-primary-500 cursor-pointer">-->
<!--                    {{ $t('features.record_labels') }}-->
<!--              </span>-->
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.users.description') }}
            </span>
          </div>
          <div class="flex-col flex">
            <div class="fs-14 font-nhu-500 text-primary-500 cursor-pointer" @click="goToCustomFields('user')">
            <span>
              {{$t('customize_fields.user')}}
            </span>
            </div>
          </div>
        </div>
      </div>

      <!--Roles-->
      <div class="bg-white border-2 p-2 my-2 rounded-md mb-8">
        <div class="flex-col flex">
          <div class="flex justify-between pb-1.5">
            <div class="flex">
              <app-icon extra-class="mr-2 pb-1.5 fill-primary-500" icon="shield_exclamation_regular" :width="20" :height="20"/>
              <span class="fs-16 font-nhu-semi-bold text-gray-800 pb-1.5">
                {{ $t('features.security.title') }}
              </span>
            </div>
          </div>
          <div class="flex justify-between pb-1.5">
            <span class="fs-14 font-nhu-regular text-gray-500">
              {{ $t('features.security.description') }}
            </span>
          </div>
          <div>
            <div class="flex justify-left">
              <span class="fs-14 font-nhu-500 text-primary-500 cursor-pointer" @click="goto('/roles')">
                {{$t('features.goto_roles') }}
              </span>
              <app-icon extra-class="ml-2 fill-primary-500" icon="arrow_right_solid" :width="20" :height="20"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'FeaturePage',
  data() {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters({user: 'GET_CURRENT_USER', company: 'GET_CURRENT_COMPANY'})
  },
  methods: {
    ...mapActions(['UPDATE_CURRENT_COMPANY']),
    getLabel(id) {
      if (this.company && id in this.company.recordLabels && this.company.recordLabels[id].pluralLabel)
        return this.company.recordLabels[id].pluralLabel + ' [' + this.$t('records.' + id + '.title') + ']'
      else
        return this.$t('records.' + id + '.title')
    },
    goto(url) {
      this.$router.push(url)
    },
    goToRecordLabels(url, record) {
      this.$router.push(url + '?record=' + record)
    },
    async save(moduleName) {
      this.saving = true;
      this.company.modules[moduleName] = !this.company.modules[moduleName]
      await this.UPDATE_CURRENT_COMPANY(this.company)
      this.saving = false;
    },
    async saveTemplatesAndInspectionsModule() {
      this.saving = true;
      this.company.modules.inspections = this.company.modules.templates;
      await this.UPDATE_CURRENT_COMPANY(this.company);
      this.saving = false;
    },
    async goToCustomFields(record) {
      await this.$router.push({ path: `${this.$route.path}/custom-fields`, query: {record: record} })
    },
  },
}
</script>
