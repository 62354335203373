<template>
  <template v-if="value">
    <div class="flex items-center">
      <app-icon v-if="icon"
                :icon="icon" :width="14" :height="14"
                :fill="iconColor"
                :extra-class="iconColor + ' mr-2'" />
      <span class="font-nhu-semi-bold fs-14">{{ label }}</span>
      <div class="h-0 border-b border-gray-50 my-4"/>
    </div>
    <div class="mt-1 font-nhu-regular text-gray-900 font-normal">
      <div v-if="images">
        <app-image-list :modelValue="value" :rounded="false" :lock="true"/>
        <div class="h-0 border-b border-gray-50 my-4"/>
      </div>
      <span v-else class="font-nhu-regular text-gray-900 fs-14 whitespace-pre-line">
        {{ textValue }}
        <slot></slot>
        <div class="h-0 border-b border-gray-50 my-4"/>
      </span>
    </div>
<!--    <div v-if="value !== null && value !== ''">-->
<!--      <div class="h-0 border-b border-gray-50 my-4"/>-->
<!--    </div>-->
  </template>

</template>

<script>

export default {
  name: "AppCrudLabel",
  components: {},
  emits: [],
  props: {
    icon: { type: String, default() { return null; } },
    iconColor: { type: String, default() { return null; } },
    label: { type: String, default() { return ''; } },
    getter: { type: String, default() { return null; } },
    value: { type: [String, Number, Array], default() { return null; } },
    images: { type: Boolean, default() { return false; } }
  },
  computed: {
    textValue() {
      if (this.getter) {
        return this.$store.getters[this.getter].find(x => x.id === this.value)?.name
      } else {
        return this.value;
      }
    }
  }
};
</script>
