<template>
  <app-crud @duplicate="duplicateWorkOrder()" @delete="deleteWorkOrder()" @exportToPDF="prepareExportToPDF()"
    :extra-class="'bg-white'">

    <template v-slot:footer="{ item }">
      <work-order-status v-if="!onlyComments && isOrderType(item, ['corrective', 'preventive'])"
        :work-order-id="workOrder?.id" @new-status="v => isStatusChangeDone = v === 'done'" />

    </template>
    <template v-slot="{ item }">
      <template v-if="item.error">
        <div v-if="item.error === 'Not_Found'">
          <div class="m-4">Esta Orden de Trabajo no existe.</div>
          <app-button @click="goToWorkOrders">Cerrar</app-button>
        </div>
        <div v-if="item.error === 'Invalid_Company'">
          <div class="m-4">Esta orden de trabajo esta en otra compania.</div>
          <app-button @click="e => changeCompany(item.companyId)">Cambiar de Compania</app-button>
        </div>
        <div v-else>
          <span>{{ item.message }}</span>
        </div>
      </template>
      <template v-else>
        <div class="h-0">
          <button ref="button"></button>
        </div>
        <div class="mb-4" v-if="requestNumber">
          <span class="fs-14 font-nhu-regular text-gray-400">{{ $t('requests.request') + '#' + requestNumber }}</span>
        </div>
        <div v-if="!onlyComments">

          <!-- Description -->
          <template v-if="workOrder.description?.length">
            <div class="mb-1">
              <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.description') }}</span>
            </div>
            <p class="font-nhu-regular fs-14 break-words text-gray-900 whitespace-pre-line">{{ workOrder.description }}
            </p>
            <div class="h-0 border-b border-gray-50 my-4" />
          </template>

          <!-- Tasks -->
          <div class="mt-4" v-if="workOrder.tasks?.length">
            <div class="mb-1 flex fill-primary-500">
              <app-icon icon="list_check_regular" extra-class="mr-2" :width="12" :height="12" />
              <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.tasksLabel') }}</span>
            </div>

            <div v-for="(check, index) in workOrder.tasks" :key="check.id" class="my-2">
              <label class="flex items-center my-1 w-fit">
                <!-- Checkbox personalizado -->
                <span class="w-6 h-6 border mr-2 rounded-full flex items-center justify-center">
                  {{ index + 1 }}
                </span>
                <span class="font-nhu-regular fs-14">{{ check.name }}</span>
              </label>
            </div>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>
          <!-- Checklist -->
          <div class="mt-4" v-if="workOrder.checklist?.length">
            <div class="mb-1 flex fill-primary-500">
              <app-icon v-if="!onlyComments" icon="list_check_regular" extra-class="mr-2" :width="12" :height="12" />
              <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.checklistLabel') }}</span>
            </div>

            <div v-for="check in workOrder.checklist" :key="check.id" class="my-2">
              <label class="flex items-center my-1 w-fit">
                <!-- Checkbox escondido -->
                <input :id="check.id" type="checkbox" class="hidden" :value="check.id" :checked="isChecked(check.id)"
                  @change="e => onCheck(check, e.target.checked)" :class="{
                    'mx-2 my-auto': true,
                    'bg-gray-200 checked:bg-gray-200': workOrder.status !== 'inProgress'
                  }" :disabled="workOrder.status !== 'inProgress' || checkSavingId"
                  :title="!workOrder.isChecked ? $t('workorder.checks.pendingInProgress') : ''">

                <!-- Checkbox personalizado -->
                <span class="w-4 h-4 border mr-2 rounded-sm flex items-center justify-center" :class="{
                  'bg-primary-25 border-primary-500': isChecked(check.id) && workOrder.status === 'inProgress',
                  'border-gray-900': !isChecked(check.id) && workOrder.status === 'inProgress',
                  'cursor-pointer': workOrder.status === 'inProgress',
                  'bg-gray-50': workOrder.status !== 'inProgress' || checkSavingId
                }">
                  <span v-if="isChecked(check.id)" class="text-primary-500">
                    <app-icon icon="check_solid" :width="10" :height="10" class="fill-primary-500"></app-icon>
                  </span>
                </span>
                <span class="font-nhu-regular fs-14">{{ check.name }}</span>
              </label>
            </div>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Template -->
          <div v-if="isOrderType(item, ['inspection']) && workOrder.templateId">
            <div class="flex items-center mb-1 fill-primary-500">
              <app-icon extra-class="mr-2" icon="paperclip_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">
                {{ $t('workorders.template') }}
              </span>
            </div>

            <inspection-page :key="item.id" :work-order="item" :check-validation-answers="isStatusChangeDone"
              @finish-inspection="goToWorkOrders" />
          </div>

          <!-- Metrics -->
          <div v-if="isOrderType(item, ['metric']) && item.metricId">
            <div class="flex items-center">
              <span class="font-nhu-semi-bold fs-14">
                {{ $getRecordLabel('metrics') ? $getRecordLabel('metrics') : $t('workorders.metrics') }}
              </span>
              <div class="h-0 border-b border-gray-50 my-4" />
            </div>
            <div class="font-nhu-regular font-normal">
              <span class="font-nhu-regular fs-14 text-gray-900">{{ _metrics }}</span>
            </div>
            <app-textbox v-model="item.measure.value" :label="$t('records.metrics.addMeasure')"
              :disabled="item.status === 'done'" :suppText="$t(`records.metrics.units.${item.measure.measurementUnit}`)"
              @change="item.measure.value ? updateMetric() : null" />
            <app-image-list v-model="item.measure.images" :rounded="false" :label="$t('workorders.addImages')"
              :disabled="item.status === 'done'" @change="item.measure.value ? updateMetric() : null" />
            <div class="flex justify-center mt-4">
              <app-button @click="item.measure.value ? finishWorkOrder() : null" type="secondary" background="light"
                :disabled="item.status !== 'inProgress'">
                <span class="font-nhu-regular">{{ $t('register') }}</span>
              </app-button>
            </div>
          </div>

          <!-- Images -->
          <div v-if="workOrder.images?.length >= 1">
            <div class="h-0 border-b border-gray-50 my-4" />
            <app-image-list v-model="workOrder.images" :rounded="false" :label="$t('workorders.addImages')"
              :disabled="true" />
          </div>

          <!-- AssignTo -->
          <div v-if="workOrder.assignedTo?.length">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="users_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.responsible') }}</span>
            </div>
            <div class="font-nhu-regular font-normal">
              <span class="font-nhu-regular fs-14 text-gray-900">{{ _users }}</span>
            </div>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- DueDate -->
          <div v-if="workOrder.dueDate !== null">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="calendar_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">
                {{ $t('workorders.dueDate') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 text-gray-900">
              {{ formatDate(workOrder.dueDate) }}
            </span>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Priority -->
          <div v-if="workOrder.priority !== null" class="hidden">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="bring_forward_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">
                {{ $t('workorders.priority') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 text-gray-900">
              {{ getPriority(workOrder.priority) }}
            </span>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Site -->
          <div v-if="_allSites?.length">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="location_dot_regular" :width="14" :height="14"
                @click="goToLink('sites', 'siteId')" />
              <span class="font-nhu-semi-bold fs-14 cursor-pointer" @click="goToLink('assets', 'assetId')">
                {{ $getRecordLabel('sites') ? $getRecordLabel('sites') : $t('workorders.location') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 cursor-pointer text-gray-900" @click="goToLink('sites', 'siteId')">{{
              _allSites
              }}</span>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Asset -->
          <div v-if="_allAssets?.length">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="boxes_stacked_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14 cursor-pointer" @click="goToLink('assets', 'assetId')">
                {{ $getRecordLabel('assets') ? $getRecordLabel('assets') : $t('workorders.asset') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 cursor-pointer text-gray-900" @click="goToLink('assets', 'assetId')">{{
              _allAssets
              }}</span>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Categories -->
          <div v-if="_categories?.length">
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="tag_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14 cursor-pointer" @click="goToLink('assets', 'assetId')">
                {{ $getRecordLabel('categories') ? $getRecordLabel('categories') : $t('workorders.categories') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 text-gray-900">
              {{ _categories }}
            </span>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Props -->
          <app-crud-properties v-model="item.props" />

          <!-- Costs -->
          <div
            v-if="isOrderType(item, ['corrective', 'preventive']) && ($hasModule('parts') || $hasWorkOrderSetting('timeTrackingEnabled'))">
            <div class="flex items-center justify-between mb-1 py-1">
              <span class="font-nhu-semi-bold fs-14 text-nowrap">
                <template v-if="$hasModule('parts') && !$hasWorkOrderSetting('timeTrackingEnabled')">
                  {{ $t('workorders.costTracking') }}
                </template>
                <template v-else-if="!$hasModule('parts') && $hasWorkOrderSetting('timeTrackingEnabled')">
                  {{ $t('workorders.timeTracking') }}
                </template>
                <template v-else-if="$hasModule('parts') && $hasWorkOrderSetting('timeTrackingEnabled')">
                  {{ $t('workorders.costAndTimeTracking') }}
                </template>
              </span>
              <button
                class="text-primary-500 border-0 border-primary-500 flex items-center h-[20px] rounded-xl font-nhu-500 fs-14"
                @click="editCostAndTimeTracking">
                {{ $t('add/edit') }}
              </button>
            </div>

            <div v-if="workOrder.resources?.length">
              <div class="mb-2">
                <work-order-resources v-model="workOrder.resources" />
              </div>
            </div>
            <div v-if="workOrder.parts?.length">
              <div>
                <work-order-parts v-model="workOrder.parts" :record="this.record" />
              </div>
            </div>
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Files -->
          <div v-if="workOrder.files?.length">
            <div class="flex items-center mb-1 fill-primary-500">
              <app-icon extra-class="mr-2" icon="paperclip_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">
                {{ $t('workorders.files') }}
              </span>
            </div>
            <app-file-list v-model="workOrder.files" :rounded="false" :allow-upload-file="false" />
            <div class="h-0 border-b border-gray-50 my-4" />
          </div>

          <!-- Comments -->
          <div>
            <div class="flex items-center mb-1 fill-primary-500">
              <app-icon extra-class="mr-2" icon="messages_regular" :width="14" :height="14" />
              <span class="font-nhu-semi-bold fs-14">
                {{ $t('workorders.comments') }}
              </span>
            </div>
            <div class="-mt-3">
              <work-order-comments v-model="workOrder.comments" @div-ready="handleDivReady"
                @send-comment="saveComment" />
            </div>
          </div>
        </div>
        <div v-else>
          <work-order-comments v-model="workOrder.comments" @div-ready="handleDivReady" @send-comment="saveComment" />
        </div>
      </template>
    </template>
  </app-crud>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import WorkOrderComments from "@/pages/workorders/WorkOrderComments.vue";
import WorkOrderStatus from "@/pages/workorders/WorkOrderStatus.vue";
import WorkOrderResources from "@/pages/workorders/WorkOrderResources.vue";
import emitter from 'tiny-emitter/instance'
import WorkOrderParts from "@/pages/workorders/WorkOrderParts.vue";
import CRUD from "@/crud";
import InspectionPage from "@/pages/inspections/InspectionPage.vue";

export default {
  name: "WorkOrderViewPage",
  components: { InspectionPage, WorkOrderParts, WorkOrderStatus, WorkOrderComments, WorkOrderResources },
  emits: ['divReady', 'edit'],
  props: {
    isRequest: { type: Boolean, default: false }
  },
  data() {
    return {
      modeView: 'order',
      userNames: [],
      exportDialog: false,
      onlyComments: false,
      checkSavingId: null,
      showParts: false,
      focusField: false,
      isStatusChangeDone: false,
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      workOrder: 'GET_WORKORDER', users: 'GET_ALL_USERS', allSites: 'GET_ALL_SITES', allAssets: 'GET_ALL_ASSETS',
      allMetrics: 'GET_ALL_METRICS', categories: 'GET_ALL_CATEGORIES', requests: 'GET_ALL_REQUESTS', records: 'GET_RECORDS',
      record: 'GET_RECORD', company: 'GET_CURRENT_COMPANY'
    }),
    definedProps() {
      return Object.entries(this.workOrder.props)
        .filter(([key]) => this.company.properties?.workorder?.find(x => x.code === key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    },
    hasProperties() {
      return this.workOrder.props?.length > 0
    },
    action() {
      return this.$route.meta.action;
    },
    _users() {
      return this.getListNames(this.users, this.workOrder.assignedTo);
    },
    _metrics() {
      return this.getListNames(this.allMetrics, this.workOrder.metricId);
    },
    _allSites() {
      return this.getListNames(this.allSites, this.workOrder.siteId);
    },
    _allAssets() {
      return this.getListNames(this.allAssets, this.workOrder.assetId);
    },
    _categories() {
      return this.getListNames(this.categories, this.workOrder.categories);
    },
    requestNumber() {
      return this.requests.find(x => x.id === this.workOrder.requestId)?.number;
    },
  },
  methods: {
    ...mapActions(['CHANGE_STATUS_WORKORDER', 'UPDATE_MEASUREMENT_WORKORDER', 'READ_WORKORDER', 'SAVE_WORKORDER', 'DELETE_WORKORDER',
      'EXPORT_WORKORDER_PDF', 'READ_REQUEST_BY_ID', 'SAVE_REQUEST', 'LOGIN_COMPANY']),
    ...mapMutations(['SET_WORKORDER', 'SET_EXPORT_OPTIONS']),
    isOrderType(item, values) {
      return values.includes(item.orderType);
    },
    getKeyLabel(key) {
      return this.company.properties?.workorder?.find(x => x.code === key)?.name || key;
    },
    async saveChanges() {
      await this.SAVE_WORKORDER(this.workOrder)
    },
    async updateMetric() {
      await this.UPDATE_MEASUREMENT_WORKORDER({ id: this.workOrder.id, value: this.workOrder.measure.value, images: this.workOrder.measure.images })
    },
    async finishWorkOrder() {
      let localWorkOrder = { ...this.workOrder };
      localWorkOrder.status = 'done';
      const response = await this.CHANGE_STATUS_WORKORDER(localWorkOrder);
      if (response) {
        this.goToWorkOrders();
      }
    },
    formatDate(date) {
      if (!date) return '';
      let d = new Date(date);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      return d.toLocaleDateString(this.$i18n.locale, options);
    },
    getPriority(priorityId) {
      if (!priorityId) {
        return '';
      }
      return this.$t('workorders.priorities.' + priorityId);
    },
    handleDivReady(div) {
      this.$emit('divReady', div);
    },
    async saveComment() {
      emitter.emit('scrollToNewMessage');
    },
    getListNames(list, ids, field = 'name') {
      if (list && ids) {
        if (!Array.isArray(list)) list = list.data;
        if (!Array.isArray(ids)) ids = [ids];

        let names = [];

        for (let id of ids) {
          let val = list.find(x => x.id === id); // Buscar en el nivel principal

          // Si no se encuentra en el nivel principal, buscar en los hijos
          if (!val) {
            for (let item of list) {
              if (item.childs) {
                let childVal = item.childs.find(x => x._id === id);
                if (childVal) {
                  val = childVal;
                  break;
                }
              }
            }
          }

          if (val) {
            names.push(val[field]);
          }
        }

        if (names.length > 0) {
          let last = names.pop();
          return names.length ? `${names.join(', ')} y ${last}` : last;
        } else {
          return '';
        }
      } else {
        return ids;
      }
    },
    isChecked(id) {
      return this.workOrder.checks.includes(id);
    },
    async onCheck(check, val) {
      this.checkSavingId = check.id;
      await this.CHANGE_STATUS_WORKORDER({
        id: this.workOrder.id,
        check: val ? check.id : undefined,
        uncheck: !val ? check.id : undefined
      });
      this.checkSavingId = null;
    },
    async exportToPDF() {
      this.exportDialog = false;
      await this.EXPORT_WORKORDER_PDF({ id: this.workOrder.id, options: this.$store.getters.GET_EXPORT_OPTIONS });
    },
    async prepareExportToPDF() {
      let options = this.record?.views?.dual?.actions?.find(x => x.function === 'exportToPDF')?.options
      this.$store.commit('SET_EXPORT_OPTIONS', options);
      await this.exportToPDF()
    },
    async duplicateWorkOrder() {
      this.$router.push('/workorders/create?duplicate=' + this.workOrder.id);
    },
    async deleteWorkOrder() {
      if (confirm(this.$t('workorders.sureToDelete'))) {
        this.saving = true;
        await this.DELETE_WORKORDER({ id: this.workOrder.id })
        this.saving = false;
        window.location.replace("/workorders")
      }
    },
    goToComments() {
      this.onlyComments = !this.onlyComments;
    },
    goToLink(link, fieldId) {
      this.$router.push(`/${link}/${this.workOrder[fieldId]}?back=true`)
    },

    editCostAndTimeTracking() {
      this.$router.push(CRUD.getRecordUrl(this.records.workorders, this.workOrder.id, 'resources'));
    },
    async addMeasureToMetric() {
      if (!this.workOrder.metricId)
        return;
      await this.$store.dispatch('READ_METRIC', { id: this.workOrder.metricId });
      this.$router.push(CRUD.getRecordUrl(this.records.workorders, this.workOrder.id, 'metrics'));
    },
    goToWorkOrders() {
      this.$router.push('/workorders');
    },
    async changeCompany(companyId) {
      await this.LOGIN_COMPANY({ id: companyId });
      location.reload();
    }
  },
  mounted() {
    this.$refs.button?.focus()
  }
}
</script>

<style scoped>
.hidden {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
</style>
