import { createI18n } from 'vue-i18n'
import en from "./en.json"
import es from "./es.json"
import pt from "./pt.json"

const i18n = createI18n({
    locale: localStorage.getItem('locale') ?? 'es',
    fallbackLocale: 'es', // set fallback locale    
    globalInjection: true,
    messages: {
        en,
        es,
        pt,
    },
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
        },
        es: {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
        },
        pt: {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
        }
    }
})

export default i18n;
