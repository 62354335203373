<template>
  <app-crud :user="user" :loading="loading" v-slot="{ item }">
    <app-label :label="$t('users.email2')" :value="item.email" :icon="'envelope_regular'"
               :icon-color="'fill-primary-500'"/>
    <app-label :label="$t('users.phone2')" :value="item.phone" :icon="'mobile_regular'"
               :icon-color="'fill-primary-500'"/>
    <app-label :label="$t('users.role')" :value="getRoleName(item.roleId)" :icon="'circle_user_regular'"
               :icon-color="'fill-primary-500'"/>
          <!-- Site -->
            <div class="flex items-center fill-primary-500 mb-1">
              <app-icon extra-class="mr-2" icon="location_dot_regular" :width="14" :height="14"/>
              <span class="font-nhu-semi-bold fs-14 cursor-pointer" @click="goToLink('assets', 'assetId')">
                {{ $getRecordLabel('sites') ? $getRecordLabel('sites') : $t('workorders.location') }}
              </span>
            </div>
            <span class="font-nhu-regular fs-14 cursor-pointer text-gray-900">
              {{ getListNames(sites, item.sites) }}
            </span>
            <div class="h-0 border-b border-gray-50 my-4" />

            <div class="flex w-full items-center mb-1">
      <work-order-history :filter="getFilter"/>
    </div>

    <app-crud-properties v-model="item.props" />

  </app-crud>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "UserView",
  components: {},
  emits: ['reload'],
  data() {
    return {
      loading: true,
      workOrdersInUsers: [],
    }
  },
  computed: {
    ...mapGetters({ user: 'GET_USER', sites: 'GET_ALL_SITES',  roles: 'GET_ALL_ROLES',}),
    getFilter() {
      return {assignedTo: [this.$route.params.id]}
    },
  },
  methods: {
    ...mapActions(['SAVE_SITE', 'READ_USER', 'READ_ALL_SITES', 'READ_ALL_ASSETS', 'DELETE_USER']),
    async doLoad() {
      this.loading = true;
      const id = this.$route.params.id;
      if (id !== undefined) {
        if (id !== 'create') {
          await this.READ_USER({id: this.$route.params.id})
        }
      }
      this.loading = false;
    },
    getRoleName(roleId) {
      return this.roles.find(x => x.id === roleId)?.name
    },
    getListNames(list, ids, field = 'name') {
      if (list && ids) {
        if (!Array.isArray(list)) list = list.data;
        if (!Array.isArray(ids)) ids = [ids];

        let names = [];

        for (let id of ids) {
          let val = list.find(x => x.id === id); // Buscar en el nivel principal

          // Si no se encuentra en el nivel principal, buscar en los hijos
          if (!val) {
            for (let item of list) {
              if (item.childs) {
                let childVal = item.childs.find(x => x._id === id);
                if (childVal) {
                  val = childVal;
                  break;
                }
              }
            }
          }

          if (val) {
            names.push(val[field]);
          }
        }

        if (names.length > 0) {
          let last = names.pop();
          return names.length ? `${names.join(', ')} y ${last}` : last;
        } else {
          return '';
        }
      } else {
        return ids;
      }
    },
  },
}
</script>
