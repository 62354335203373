import API from '@/api'

const state = {
  TEMPLATE: {
    name: '',
    pages: [],
  },
  PUBLISH_TEMPLATE: {
    name: '',
    pages: [],
  },
  TEMPLATES: { data:[] },
  PUBLISH_TEMPLATES: [],
  ALL_TEMPLATES: [],
  ITEM_SELECTED: null,
}

const getters = {
  GET_TEMPLATE: state => state.TEMPLATE,
  GET_TEMPLATES: state => state.TEMPLATES,
  GET_PUBLISH_TEMPLATE: state => state.PUBLISH_TEMPLATE,
  GET_PUBLISH_TEMPLATES: state => state.PUBLISH_TEMPLATES,
  GET_ALL_TEMPLATES: state => state.ALL_TEMPLATES,
  GET_ITEM_SELECTED: state => state.ITEM_SELECTED,
}

const mutations = {
  // TEMPLATES
  SET_ALL_TEMPLATES(state, payload){
    state.ALL_TEMPLATES = payload
  },
  SET_TEMPLATE(state, payload) {
    state.TEMPLATE = payload
  },
  SET_PUBLISH_TEMPLATE(state, payload) {
    state.PUBLISH_TEMPLATE = payload
  },
  SET_TEMPLATES(state, payload) {
    state.TEMPLATES = payload
  },
  SET_PUBLISH_TEMPLATES(state, payload) {
    state.PUBLISH_TEMPLATES = payload
  },
  SET_ITEM_SELECTED(state, payload) {
    state.ITEM_SELECTED = payload
  },
  CREATE_TEMPLATE(state, payload) {
    state.TEMPLATE = payload
    state.TEMPLATES.data.push(payload)
  },
  UPDATE_TEMPLATE(state, payload) {
    if (state.TEMPLATE !== null && state.TEMPLATE.id === payload.id)
      state.TEMPLATE = payload;

    const index = state.TEMPLATES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.TEMPLATES.data[index], payload)
  },
  DELETE_TEMPLATE(state, payload) {
    if (state.TEMPLATE?.id === payload.id)
      state.TEMPLATE = {}
    state.TEMPLATES.data = state.TEMPLATES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async UPDATE_ITEM_SELECTED (context, payload){
    context.commit('SET_ITEM_SELECTED', payload)
  },

  // CRUD TEMPLATES
  async READ_ALL_TEMPLATES (context) {
    const response = await API.Get('/api/options/templates')
    if(response.type === 'Success')
      context.commit('SET_ALL_TEMPLATES', response.res.data)
    else
      context.commit('SET_ALL_TEMPLATES', [])
  },
  async READ_TEMPLATES(context, payload) {
    const response = await API.Get('/api/templates', payload)
    if (response.type === 'Success') {
      context.commit('SET_TEMPLATES', response.res.data)
      return response.res.data
    }
    context.commit('SET_TEMPLATES', [])
  },
  async CREATE_TEMPLATE (context){
    const item = { name: '', pages: [
      { id: Math.random().toString(36).substring(2, 15), items:[] }
    ] };
    context.commit('SET_TEMPLATE', item)
    return item;
  },
  async SAVE_TEMPLATE (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/templates', payload)
      if(response.type === 'Success'){
        context.commit('SET_TEMPLATE', response.res.data)
      }
    }
    else
    {
      const response = await API.Put(`/api/templates/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_TEMPLATE', response.res.data)
      }
    }
  },
  async READ_TEMPLATE(context, payload) {
    context.commit('SET_TEMPLATE', {})
    const response = await API.Get(`/api/templates/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_TEMPLATE', response.res.data)
      return response.res.data
    }
  },
  async READ_PUBLISH_TEMPLATES(context, payload) {
    const response = await API.Get('/api/templates/publications', payload)
    if (response.type === 'Success') {
      context.commit('SET_PUBLISH_TEMPLATES', response.res.data)

      return response.res.data
    }
  },
  async READ_PUBLISH_TEMPLATE(context, payload) {
    const response = await API.Get(`/api/templates/publications/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_PUBLISH_TEMPLATE', response.res.data)

      return response.res.data
    }
  },
  async UPDATE_TEMPLATE(context, payload) {
    const response = await API.Put(`/api/templates/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_TEMPLATE', response.res.data)
      return response.res.data
    }
  },
  async PUBLISH_TEMPLATE(context, payload) {
    const response = await API.Post(`/api/templates/${payload.id}/publications`)
    if (response.type === 'Success') {
      context.commit('UPDATE_TEMPLATE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_TEMPLATE(context, payload) {
    const response = await API.Delete(`/api/templates/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_TEMPLATE', response.res.data)
      return
    }
  },
  async DELETE_TEMPLATE_LIST(context, payload) {
    const response = await API.Patch('/api/templates',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_TEMPLATE', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
