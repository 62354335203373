<template>
  <app-crud :loading="loading">
    <div class="mt-4">
      <div class="mb-1">
        <span class="font-nhu-semi-bold fs-14">{{ $t('requestLandings.description') }}</span>
      </div>
      <p class="font-nhu-regular text-gray-900 fs-14">{{ requestLanding.description }}</p>
    </div>
    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="mb-1">
        <span class="font-nhu-semi-bold fs-14">{{ $t('requestLandings.welcomeText') }}</span>
      </div>
      <p class="font-nhu-regular text-gray-900 fs-14"> {{ requestLanding.welcomeText }}</p>
    </div>

    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="flex items-center fill-primary-500 mb-1">
        <app-icon extra-class="mr-2" icon="location_dot_regular" :width="14" :height="14"/>
        <span class="font-nhu-semi-bold fs-14">
                  {{ $t('workorders.location') }}
        </span>
      </div>
      <span class="font-nhu-regular text-gray-900 fs-14">{{ _allSites }}</span>
    </div>
    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="flex items-center fill-primary-500 mb-1">
        <app-icon extra-class="mr-2" icon="boxes_stacked_regular" :width="14" :height="14"/>
        <span class="font-nhu-semi-bold fs-14">{{ $t('workorders.asset') }}</span>
      </div>
      <span class="font-nhu-regular text-gray-900 fs-14">{{ _allAssets }}</span>
    </div>

    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="flex items-center fill-primary-500 mb-1">
        <app-icon extra-class="mr-2" icon="users_regular" :width="14" :height="14"/>
        <span class="font-nhu-semi-bold fs-14">{{ $t('requestLandings.responsible') }}</span>
      </div>
      <div class="font-nhu-regular font-normal">
        <span class="font-nhu-regular fs-14">{{ _users }}</span>
      </div>
    </div>
    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="flex justify-between items-center">
        <div>
          <div class="flex items-center mb-1 fill-primary-500">
            <app-icon extra-class="mr-2" icon="link_regular" :width="14" :height="14"/>
            <span class="font-nhu-semi-bold fs-14"> {{$t('requestLandings.sharePortal')}} </span>
          </div>
          <div class="font-nhu-regular font-normal">
            <span class="font-nhu-regular fs-14">{{$t('requestLandings.sharePortalDesc')}}</span>
          </div>
        </div>
        <div>
          <app-button @click="copyLink" :clean="true" type="terciary" background="light" v-if="action === 'view'"
                      :extra-class="linkCopied ? 'w-[110px] bg-primary-500 border-primary-500 text-white border-[1px] !px-3' : 'w-[110px] border-primary-500 text-primary-500 border-[1px] !px-3 font-nhu-500 fs-14'">
            {{ linkCopied ? $t('copied') : $t('copyLink') }}
          </app-button>
        </div>
      </div>
    </div>
    <div>
      <div class="h-0 border-b border-gray-50 my-4"/>
      <div class="flex items-center fill-primary-500">
        <app-icon extra-class="mr-2" icon="qrcode_regular" :width="14" :height="14"/>
        <span class="font-nhu-semi-bold fs-14"> {{$t('requestLandings.qrCode')}} </span>
      </div>
      <app-image-qr v-if="requestLanding.qrCode" :src="requestLanding.qrCode"/>
    </div>
  </app-crud>
</template>

<script>
import {PlusCircleIcon} from "@heroicons/vue/24/outline";
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppIcon from "@/components/AppIcon.vue";
import AppButton from "@/components/AppButton.vue";
import AppImageQr from "@/components/AppImageQr.vue";

export default {
  name: "RequestLandingViewPage",
  components: {AppImageQr, AppButton, AppIcon},
  emits: ['reload'],
  data() {
    return {
      loading: true,
      linkCopied: false,
    }
  },
  computed: {
    ...mapGetters({
      requestLanding: 'GET_REQUEST_LANDING', view: 'GET_VIEW', users: 'GET_USERS', allSites: 'GET_ALL_SITES', allAssets: 'GET_ALL_ASSETS', }),
    action() { return this.$route.meta.action; },
    _users() {
      return this.getListNames(this.users, this.requestLanding.assignedTo);
    },
    _allSites() {
      return this.getListNames(this.allSites, this.requestLanding.siteId);
    },
    _allAssets() {
      return this.getListNames(this.allAssets, this.requestLanding.assetId);
    },
  },
  methods: {
    ...mapActions(['READ_REQUEST_LANDING', 'READ_ALL_SITES','READ_ALL_ASSETS', 'READ_ACTION_STATUS']),
    ...mapMutations(['SET_VIEW']),
    PlusCircleIcon,
    getListNames(list, ids, field = 'name') {
      if (list && ids) {
        if (!Array.isArray(list)) list = list.data;
        if (!Array.isArray(ids)) ids = [ids];

        let names = [];

        for (let id of ids) {
          let val = list.find(x => x.id === id); // Buscar en el nivel principal

          // Si no se encuentra en el nivel principal, buscar en los hijos
          if (!val) {
            for (let item of list) {
              if (item.childs) {
                let childVal = item.childs.find(x => x._id === id);
                if (childVal) {
                  val = childVal;
                  break;
                }
              }
            }
          }

          if (val) {
            names.push(val[field]);
          }
        }

        if (names.length > 0) {
          let last = names.pop();
          return names.length ? `${names.join(', ')} y ${last}` : last;
        } else {
          return '';
        }
      } else {
        return ids;
      }
    },
    copyLink() {
      const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/request-landing/${this.requestLanding.id}`;
      navigator.clipboard.writeText(baseUrl);

      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
  },
}
</script>

<style scoped>

</style>
