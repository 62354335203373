import API from '@/api'

const state = {
    CATEGORY: {
        name: '',
        description: '',
    },
    CATEGORIES: {data: []},
    ALL_CATEGORIES: []
}

const getters = {
    GET_CATEGORY: state => state.CATEGORY,
    GET_CATEGORIES: state => state.CATEGORIES,
    GET_ALL_CATEGORIES: state => state.ALL_CATEGORIES,
}

const mutations = {
    // CATEGORIES
    SET_CATEGORY(state, payload) {
        state.CATEGORY = payload
    },
    SET_ALL_CATEGORIES(state, payload) {
        state.ALL_CATEGORIES = payload
    },
    SET_CATEGORIES(state, payload) {
        state.CATEGORIES = payload
    },
    CREATE_CATEGORY(state, payload) {
        state.CATEGORY = payload
        state.CATEGORIES.data.push(payload)
    },
    UPDATE_CATEGORY(state, payload) {
        if (state.CATEGORY !== null && state.CATEGORY.id === payload.id)
            state.CATEGORY = payload;

        const index = state.CATEGORIES.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.CATEGORIES.data[index], payload)
    },
    DELETE_CATEGORY(state, payload) {
        if (state.CATEGORY?.id === payload.id)
            state.CATEGORY = {}
        state.CATEGORIES.data = state.CATEGORIES.data.filter(e => e.id !== payload.id)
        state.ALL_CATEGORIES = state.ALL_CATEGORIES.filter(e => e.id !== payload.id)
    },
}

const actions = {
    // CRUD COURSES
    async READ_CATEGORIES(context, payload) {
        const response = await API.Get('/api/categories', payload)
        if (response.type === 'Success') {
            context.commit('SET_CATEGORIES', response.res.data)
            return response.res.data
        }
        context.commit('SET_CATEGORIES', [])
    },
    async READ_ALL_CATEGORIES(context) {
        const response = await API.Get('/api/options/categories')
        if (response.type === 'Success') {
            context.commit('SET_ALL_CATEGORIES', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_CATEGORIES', [])
    },
    async CREATE_CATEGORY(context) {
        context.commit('SET_CATEGORY', {name: '', typeId: '', siteId: ''})
    },

    async SAVE_CATEGORY(context, payload) {
        if (payload.id === undefined) {
            const response = await API.Post('/api/categories', payload)
            if (response.type === 'Success') {
                context.commit('SET_CATEGORY', response.res.data)
                return response.res.data;
            }
        } else {
            const response = await API.Put(`/api/categories/${payload.id}`, payload)
            if (response.type === 'Success') {
                context.commit('UPDATE_CATEGORY', response.res.data)
                return response.res.data;
            }
        }
    },
    async READ_CATEGORY(context, payload) {
        const response = await API.Get(`/api/categories/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_CATEGORY', response.res.data)
            return response.res.data
        }
    },
    async UPDATE_CATEGORY(context, payload) {
        const response = await API.Put(`/api/categories/${payload.id}`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_CATEGORY', response.res.data)
            return response.res.data
        }
    },
    async DELETE_CATEGORY(context, payload) {
        const response = await API.Delete(`/api/categories/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('DELETE_CATEGORY', response.res.data)
            return
        }
    },
    async DELETE_CATEGORY_LIST(context, payload) {
        const response = await API.Patch('/api/categories', payload);

        if (response.type === 'Success') {
            context.commit('DELETE_CATEGORY', {payload});
            return true;
        }
        return false;
    },
    async EXPORT_CATEGORY_XLSX() {
        const response = await API.Post(`/api/categories/export-XLSX`)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async IMPORT_CATEGORIES(context, payload) {
        const response = await API.Post('/api/categories/import', payload)
        if (response.type === 'Success') {
            if(state.CATEGORIES){
                context.commit('SET_CATEGORIES', {data: [...state.CATEGORIES.data, ...response.res.data]})
            }else{
                context.commit('SET_CATEGORIES', response.res.data)
            }
            return response.res.data
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
