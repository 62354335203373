import API from '@/api'

const state = {
  ISSUECATEGORY: {
    name: '',
  },
  ISSUECATEGORIES: { data:[] }
}

const getters = {
  GET_ISSUECATEGORY: state => state.ISSUECATEGORY,
  GET_ISSUECATEGORIES: state => state.ISSUECATEGORIES.data,
}

const mutations = {
  // ISSUECATEGORY
  SET_ISSUECATEGORY(state, payload) {
    state.ISSUECATEGORY = payload
  },
  SET_ISSUECATEGORIES(state, payload) {
    state.ISSUECATEGORIES.data = payload
  },
  CREATE_ISSUECATEGORY(state, payload) {
    state.ISSUECATEGORY = payload
    state.ISSUECATEGORIES.data.push(payload)
  },
  UPDATE_ISSUECATEGORY(state, payload) {
    if (state.ISSUECATEGORY !== null && state.ISSUECATEGORY.id === payload.id)
      state.ISSUECATEGORY = payload;

    const index = state.ISSUECATEGORIES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.ISSUECATEGORIES.data[index], payload)
  },
  DELETE_ISSUECATEGORY(state, payload) {
    if (state.ISSUECATEGORY?.id === payload.id)
      state.ISSUECATEGORY = {}
    state.ISSUECATEGORIES.data = state.ISSUECATEGORIES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD ISSUECATEGORIES
  async READ_ISSUECATEGORIES(context, payload) {
    const response = await API.Get('/api/issueCategories', payload)
    if (response.type === 'Success') {
      context.commit('SET_ISSUECATEGORIES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ISSUECATEGORIES', [])
  },
  async CREATE_ISSUECATEGORY (context){
    context.commit('CREATE_ISSUECATEGORY', { name: '', peopleId: [] })
  },
  async SAVE_ISSUECATEGORIES(context, payload){
    const response = await API.Post('/api/issueCategories/issueCategoryList', payload)
    if (response.type === 'Success') {
      context.commit('SET_ISSUECATEGORIES', response.res.data)
      return response.res.data
    }
  },
  async SAVE_ISSUECATEGORY (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/issueCategories', payload)
      if(response.type === 'Success'){
        context.commit('SET_ISSUECATEGORY', response.res.data)
        return response.res.data;
      }
    }
    else
    {
      const response = await API.Put(`/api/issueCategories/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_ISSUECATEGORY', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_ISSUECATEGORY(context, payload) {
    const response = await API.Get(`/api/issueCategories/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_ISSUECATEGORY', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_ISSUECATEGORY(context, payload) {
    const response = await API.Put(`/api/issueCategories/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_ISSUECATEGORY', response.res.data)
      return response.res.data
    }
  },
  async DELETE_ISSUECATEGORY(context, payload) {
    const response = await API.Delete(`/api/issueCategories/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_ISSUECATEGORY', response.res.data)
      return
    }
  },
  async DELETE_ISSUECATEGORY_LIST(context, payload) {
    const response = await API.Patch('/api/issueCategories',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_ISSUECATEGORY', { payload });
      return true;
    }
    return false;
  },
  async READ_ISSUECATEGORY_LIST(context) {
    const response = await API.Get('/api/issueCategories/issueCategoryList')
    if (response.type === 'Success') {
      context.commit('SET_ISSUECATEGORIES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ISSUECATEGORIES', [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
