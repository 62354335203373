<template>
  <app-crud ref="crud" v-slot="{ item, errors }" @validate="onValidate">
    <div class="flex">
      <div class="w-1/4">
        <app-image-list v-model="item.images" :rounded="false" :only-one-image="true" />
      </div>
      <div class="flex-1 mx-4">
        <app-textbox v-model="item.code" :label="$t('records.procedures.fields.code')" />
        <app-textbox v-model="item.name" :label="$t('records.procedures.fields.name')" :errors="errors" />
      </div>
    </div>

    <app-textarea v-model="item.objetive" :label="$t('records.procedures.fields.objetive')" />
    <app-textarea v-model="item.policy" :label="$t('records.procedures.fields.policy')" />
    <app-textarea v-model="item.body" :label="$t('records.procedures.fields.body')" />

    <app-checklist v-model="item.tasks" :label="$t('records.procedures.fields.tasks')" :numbers="true"
      :suppText="$t('checklist.placeholder')"  />

    <app-checklist v-model="item.checklist" :label="$t('records.procedures.fields.checklist')" 
          :suppText="$t('checklist.placeholder')"  />

    <app-textarea v-model="item.measurement" :label="$t('records.procedures.fields.measurement')" />

    <app-crud-field-edit v-if="$hasModule('templates')" fieldId="templateId" :label="$t('records.procedures.fields.templateId')" :errors="errors" :allow-nulls="true" />

    <app-file-list v-model="item.files" :rounded="false" :label="$t('records.procedures.fields.files')" />

    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProcedureEditPage",
  components: {},
  computed: {
    ...mapGetters({ record: 'GET_RECORD', }),
  },
  methods: {
    onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        onError({ id: 'name', text: this.$t('records.workorders.errors.nameRequired') });
      } else {
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
