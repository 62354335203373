
<script>
import AppCrudList from '@/crud/AppCrudList'

export default {
  name: 'UserListPage',
  extends: AppCrudList,
}
</script>

