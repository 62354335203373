<template>
    <div class="w-1/3 mr-4 border border-gray-100 rounded h-full flex flex-col">
        <div class="p-4 border-b border-b-gray-100 bg-gray-50">
            {{ $t('conversations.title') }}
        </div>
        <div class="overflow-y-auto">
            <ul>
                <li @click="doView(item)" v-for="item in conversations.data" :key="item.id"
                    class="w-full h-16 p-2 border-l-2 cursor-pointer border-b border-b-gray-100"
                    :class="[isSelected(item) ? 'border-l-blue-500 bg-blue-50' : 'border-l-white']"
                >
                    <div v-if="item.type === 'Group' || item.type === 2" class="flex items-center">
                        <div>
                            <UserGroupIcon class="w-10 h-10 mr-2 rounded-full"/>
                        </div>
                        <div>
                            {{ item.name }}
                        </div>
                    </div>
                    <div v-else class="flex items-center">
                        <div v-if="getUserConversationData(item.members) && getUserConversationData(item.members)?.images?.length >= 1">
                            <app-image :src="getUserConversationData(item.members)?.images" alt="user"
                                       class="w-10 h-10 mr-2 rounded-full"/>
                        </div>
                        <div v-else>
                            <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                        </div>
                        {{ getUserConversationData(item.members)?.name }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {UserGroupIcon, UserIcon} from "@heroicons/vue/24/outline";
import AppImage from "@/components/AppImage.vue";

export default {
  name: "ConversationList",
  components: {AppImage, UserIcon, UserGroupIcon},
  props: {
    conversations: {
      type: Object,
      default: () => {
      }
    },
    userData: {
      type: Object,
      default: () => {
      }
    },
    users: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    crudPath() {
      var url = this.$route.fullPath;

      if (url.endsWith('/edit'))
        url = url.substring(0, url.length - 5);
      if (this.$route.params.id !== undefined)
        url = url.substring(0, url.lastIndexOf('/'))

      return url;
    },
  },
  methods: {
    doView(item) {
      this.$router.push(this.crudPath + '/' + item.id)
    },
    isSelected(item) {
      return this.$route.params.id === item.id;
    },
    getUserConversationData(members) {
      const userId = this.userData.id || null;
      return this.getUser(members.filter(x => x !== userId)[0])
    },
    getUser(senderId) {
      const user = this.users.find(x => x.id === senderId);
      return user || {};
    },
  }
}
</script>

<style scoped>

</style>
