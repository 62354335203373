<template>
  <Dialog as="div" class="relative z-10" :open="dialog">
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit"
        @mousedown.self="closePopUp">

        <DialogPanel
          class="relative overflow-hidden text-left transition-all transform bg-white rounded-[8px] shadow-xl">

<!--          <div @click="closePopUp" class="absolute top-0 right-0 z-10 cursor-pointer">-->
<!--            <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray" />-->
<!--          </div>-->
          <div :class="isMobile ? 'w-[20rem]' : 'w-[32rem]'">
<!--            <div class="flex justify-center w-full">-->
<!--              <img src="/images/zigo.png" class="w-12">-->
<!--            </div>-->
            <div class="block w-full p-6">
              <pre class="font-nhu-semi-bold fs-18" :class="isMobile ? 'text-center' : ''">{{ message }}</pre>
              <div class="mt-4">
                <span class="font-nhu-regular fs-14">{{$t('cantUndo')}}</span>
              </div>
            </div>
            <div class="flex w-full p-6 space-x-4" :class="isMobile ? 'justify-center' : 'justify-end'">
              <app-button :clean="true" @click="closePopUp" extra-class="px-4 py-3 text-primary-500 border border-primary-500" type="secondary" background="light">
                {{ $t('cancel') }}
              </app-button>
              <app-button @click="doExec" extra-class="px-4 py-3" type="primary" background="light">
                {{ $t(buttonConfirm) }}
              </app-button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>


<script>
// import { XCircleIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel } from "@headlessui/vue";


export default {
  name: "AppConfirmDialog",
  components: {
    Dialog,
    DialogPanel,
    // XCircleIcon
  },
  // emits: ["update:open", "onYes"],
  props: {
    // open: {
    //   type: Boolean,
    //   default: false
    // },
    // message: {
    //   type: String,
    //   default: ""
    // }
  },
  data() {
    return {
      buttonConfirm: '',
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        // color: "grey lighten-3",
        // width: 400,
        // zIndex: 200,
        // noconfirm: false,
      },
    }
  },
  computed: {
    isMobile() { return this.$isMobile() },
  },
  methods: {
    open(title, message, options, buttonConfirm) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      this.buttonConfirm = buttonConfirm
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    closePopUp() {
      this.resolve(false)
      this.dialog = false
      this.$emit('update:open', false)
    },
    doExec() {
      this.resolve(true)
      this.dialog = false
      this.$emit('update:open', false)
      this.$emit('onYes')
    }
  },
  mounted() { }
}
</script>
