<template>

  <app-table 
      :itemsSelected="selected" @update:itemsSelected="e => $emit('update:selected', e)"
      :url="`/api/records/${this.recordId}/data`" 
      :value="record?.keyField" :columns="record?.fields" 
      @click-row="e => clickRow(e)"
      />
  </template>
  
<script>
import { mapActions } from "vuex";

export default {
  name: "AppRecordList",
  emits: ['update:selected'],
  components: {  },
  data() {
    return {
      loading: false,
      record: null
    }
  },
  props: {
    selected: { type: Array, default: null },      
    recordId: { type: String, default: "" },      
  },
  watch: {
    async recordId(newId)
    {
      this.record = await this.READ_RECORD( { id: newId })
    }
  },
  computed: {
  },  
  methods: {
    ...mapActions(['READ_RECORD']),
    clickRow(row)
    {
      console.log(row.linkTo);
      this.$router.push(row.linkTo)
    }
  },
  async created() {
    this.record = await this.READ_RECORD( { id: this.recordId })
  },
};
</script>
<style scoped>
</style>