<template>
  <div class="flex flex-col pt-2">
    <div class="font-nhu-regular py-1">
      {{$t('records.metrics.assignedTo')}}
    </div>
    <div class="flex gap-x-4">
      <div class="flex gap-x-1 items-center">
        <input type="radio" id="asset" name="assigned" value="asset" v-model="metricType">
        <label for="asset" class="font-nhu-regular text-gray-900">{{$t('records.metrics.asset')}}</label>
      </div>
      <div class="flex gap-x-1 items-center">
        <input type="radio" id="site" name="assigned" value="site" v-model="metricType">
        <label for="site" class="font-nhu-regular text-gray-900">{{$t('records.metrics.site')}}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetricAssignedSelector",
  props: {
    value: {
      type: String,
      default: "asset",
    },
  },
  computed: {
    metricType: {
      get() {
        const val = this.$store.getters["GET_METRIC_TYPE_ASSIGNED"];
        return val
      },
      set(value) {
        this.$store.commit("SET_METRIC_TYPE_ASSIGNED", value);
      },
    },
  }
}
</script>

<style scoped>

</style>
