<template>
  <div v-for="template in templates" :key="template.id" class="flex gap-4 pb-4">
    <div class="border-2 border-primary-100 rounded-[8px] w-full flex justify-between items-center my-2 p-2">
      <div class="flex">
        <div v-if="template.images?.length" class="relative block group w-[136px] h-[136px] aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-primary-100 rounded-[0.5rem]">
          <app-image :src="template.images" class="w-[136px] h-[136px]" />
        </div>
        <div v-else>
          <div class="relative block group w-[136px] h-[136px] aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-primary-100 rounded-[0.5rem]">
            <app-icon icon="image_slash_regular" :class="'rounded fill-primary-500'" :width="136" :height="136" />
          </div>
        </div>
        <div class="flex-grow p-2">
          <div>
            <span class="template-title my-3 font-nhu-bold fs-16 w-full">{{template.name}}</span>
          </div>
          <div class="mt-4">
            <span class="template-title my-3 font-nhu-bold fs-14 w-full">{{template.description}}</span>
          </div>
        </div>
      </div>
      <div class="px-2 cursor-pointer" @click="selectTemplate(template)">
        <app-icon icon="chevron_right_regular" :class="'rounded fill-primary-500'" :width="24" :height="48" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppIcon from "@/components/AppIcon.vue";

export default {
  name: "TemplateListBox",
  components: {AppIcon},
  emits: ['update:modelValue', 'selected-template'],
  props: {
    modelValue: { type: String, default() { return '' }, },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({templates: 'GET_ALL_TEMPLATES'}),
  },
  methods: {
    selectTemplate(template) {
      this.$emit('update:modelValue', template.id)
      this.$emit('selected-template', template)
      this.$router.push('/inspections/create?templateId=' + template.id);
    }
  }
}
</script>

<style scoped>

</style>
