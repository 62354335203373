import API from '@/api'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  // CRUD INVITATION
  async REPORT_MAIN_REPORT (){
    const response = await API.Get('/api/reports/kpis')
    if(response.type === 'Success'){
      return response.res.data;
    }
    return null;
  },
}

export default {state, getters, mutations, actions}