<template>
  <div class="flex flex-col pt-2">
    <div class="font-nhu-regular py-1">
      {{$t('records.workorders.type')}}
    </div>
    <div class="flex gap-x-4 flex-wrap">

      <span class="isolate rounded-md shadow-sm w-full">
        <button type="button" 
          :class="[modelValue === 'corrective' ? 'bg-blue-300 hover:bg-blue-50' : 'bg-white hover:bg-gray-50',
          'relative block w-full text-center sm:w-auto sm:inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10',
          (!hasPreventive && !hasMetric && !hasInspection) ? 'rounded-r-md' : '']" @click="setState('corrective')">

          {{$t('workorders.corrective')}}

        </button>

        <button v-if="hasPreventive" type="button" 
          :class="[modelValue === 'preventive' ? 'bg-blue-300 hover:bg-blue-50' : 'bg-white hover:bg-gray-50',
          'relative -ml-px block w-full text-center sm:w-auto sm:inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10',
          (!hasMetric && !hasInspection)? 'rounded-r-md' : '']" @click="setState('preventive')">

          {{$t('workorders.preventive')}}

        </button>

        <button v-if="hasMetric" type="button" 
          :class="[modelValue === 'metric' ? 'bg-blue-300 hover:bg-blue-50' : 'bg-white hover:bg-gray-50',
          'relative -ml-px block w-full text-center sm:w-auto sm:inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10',
          (!hasInspection) ? 'rounded-r-md' : '']" @click="setState('metric')">

          {{$t('workorders.metric')}}

        </button>

        <button v-if="hasInspection" type="button" 
          :class="[modelValue === 'inspection' ? 'bg-blue-300 hover:bg-blue-50' : 'bg-white hover:bg-gray-50',
          'relative -ml-px block w-full text-center sm:w-auto sm:inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10']" 
          @click="setState('inspection')">

          {{$t('workorders.inspection')}}

        </button>
      </span>

     </div>
  </div>
</template>

<script>

export default {
  name: "MetricAssignedSelector",
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: "corrective",
    },
    isNewWorkOrder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    onlyCorrective() {
      return !this.$route.query.requestId;
    },
    hasPreventive() {
      return this.onlyCorrective && !this.isNewWorkOrder;
    },
    hasMetric() {
      return this.$hasModule('metrics') && this.onlyCorrective && !this.isNewWorkOrder;
    },
    hasInspection() {
      return this.$hasModule('inspections') && this.onlyCorrective;
    }
  },
  methods: {
    setState(state) {
      this.$emit('update:modelValue', state)
    }
  }
}
</script>

<style scoped>

</style>
