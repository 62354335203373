<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 flex z-40">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative flex-1 flex flex-col w-full pb-4 bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="pt-5">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10" @click="sidebarOpen = false">
                  <span class="sr-only">{{$t('closeSidebar')}}</span>
                  <app-icon icon="xmark_regular" width="25" height="25"></app-icon>
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 overflow-y-auto flex">
              <TheSidebar :sidebarOpen="sidebarOpen" :sidebarIcons="sidebarIcons" @update:sidebar-open="closeSidebar"/>
            </div>
          </DialogPanel>
        </TransitionChild>
<!--        <div class="flex-shrink-0 w-14" aria-hidden="true">-->
<!--          &lt;!&ndash; Dummy element to force sidebar to shrink to fit close icon &ndash;&gt;-->
<!--        </div>-->
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="hidden md:flex md:flex-col md:fixed md:inset-y-0" :class="[sidebarIcons ? 'md:w-[88px]' : 'md:w-[265px]']" v-if="!isFullScreen">
    <div class="flex flex-col flex-grow border-white overflow-y-auto">
      <div class="flex-grow flex flex-col">
        <TheSidebar :sidebarOpen="sidebarOpen" :sidebarIcons="sidebarIcons" />

        <ChevronDoubleLeftIcon class="w-7 h-7 z-10 absolute top-6 left-48 text-white hover:text-gray-400 cursor-pointer" v-if="!sidebarIcons" @click.once="toggleSidebar" />
        <ChevronDoubleRightIcon class="w-7 h-7 z-10 absolute top-6 left-10 text-white hover:text-gray-400 cursor-pointer" v-if="sidebarIcons" @click.once="toggleSidebar" />
      </div>
    </div>
  </div>

  <div class="absolute top-[30px] left-2 z-10 flex-shrink-0 flex bg-white"  v-if="!isFullScreen">
    <button type="button" class="text-gray-600 md:hidden" @click="sidebarOpen = true">
      <span class="sr-only">{{$t('openSidebar')}}</span>
      <app-icon icon="bars_regular" width="25" height="25"></app-icon>
    </button>
  </div>

  <div class="flex flex-col w-full" :class="[!isFullScreen ? (sidebarIcons ? 'md:pl-[88px]' : 'md:pl-[265px]') : '']">
    <div class="flex flex-col flex-grow" :class="{'px-2 sm:px-8 py-2 sm:py-4': !isFullScreen && !isMobile  }" >
      <router-view />
    </div>
  </div>

  <app-loading v-if="loading" :loading="true" class="fixed inset-0 z-50"/>
</template>

<script>
import TheSidebar from './TheSidebar.vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot, } from '@headlessui/vue'
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/24/outline'
import { mapActions, mapGetters } from 'vuex'
import AppIcon from "@/components/AppIcon.vue";

export default {
  name: 'TheTemplate',
  emits: ["hide-sidebar"],
  components: {AppIcon, TheSidebar, Dialog, DialogPanel, TransitionChild, TransitionRoot, ChevronDoubleRightIcon, ChevronDoubleLeftIcon },
  data() {
    return {
      sidebarOpen: false,
      sidebarIcons: true,
      messageVisible: true,
    }
  },
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY', token: 'GET_TOKEN', user: 'GET_CURRENT_USER', loading: 'GET_LOADING' }),
    isMobile() { return this.$isMobile() },
    isFullScreen()
    {
      if(this.$route.meta.isFullScreen === undefined)
        return false;
      return this.$route.meta.isFullScreen === true;
    }
  },
  methods: {
    ...mapActions(['SEND_VALIDATION', 'READ_CURRENT_COMPANY', "READ_PERMISSIONS", 'UPDATE_RECORDS']),
    toggleSidebar() {
      this.sidebarIcons = !this.sidebarIcons;
      localStorage.setItem('sidebarIcons', this.sidebarIcons)

    },
    async send_validation()
    {
      this.SEND_VALIDATION();
    },
    closeSidebar(sidebarOpen) {
      this.sidebarOpen = sidebarOpen
      localStorage.setItem('sidebarIcons', this.sidebarIcons)
    }
  },
  async mounted() {
    this.sidebarIcons = (localStorage.getItem('sidebarIcons') ?? 'true') === 'true'
    await this.READ_CURRENT_COMPANY()
  }
}
</script>
