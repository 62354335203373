<template>
    <div id="displayCtrl" class="h-full" :style=" { 'height': getHeight }">
        <div class="flex items-center justify-between mb-6 mt-2">
            <h1 class="font-bold text-2xl text-gray-900 px-4 my-auto overflow-ellipsis">
                {{ $t('messages.title') }}</h1>
            <div>
                <app-button @click="doCreate" type="primary" background="light" :icon="PlusCircleIcon">
                    {{ $t('conversations.newConversation') }}
                </app-button>
            </div>
        </div>
        <div class="flex" :style=" { 'height': getHeight }">
            <conversation-list :conversations="conversations" :userData="userData" :users="users"/>
            <div class="w-2/3 border h-full border-gray-300 rounded">
                <div v-if="action !== 'edit'" id="scroll" class="flex flex-col h-full overflow-y-auto">
                    <conversation-header :action="action" :conversation="conversation" :userData="userData"
                                         :users="users" :users-filtered="usersFiltered"
                                         @updateAction="viewConversationInformation"
                                         @update-conversation="updateConversation"
                                         @leaveConversation="leaveConversation"/>
                    <messages-list :action="action" :messages="messages" :message="message" :conversation="conversation"
                                   :users="users" @updateMessage="scrollToBottom"/>
                </div>
                <div v-else>
                    <conversation-info :conversation="conversation" :user-data="userData" :users="users"
                                       @show-users="showUsers = true" @deleteMember="removeMember"/>
                </div>
            </div>
        </div>
        <users-pop-up :open="showUsers" :users="nonMembers" @update:open="showUsers = false"
                      @on-yes="addMembers"></users-pop-up>
    </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {PlusCircleIcon, PlusIcon} from "@heroicons/vue/24/outline";
import AppButton from "@/components/AppButton.vue";
import UsersPopUp from "@/pages/messages/UsersPopUp.vue";
import ConversationList from "@/pages/messages/conversations/ConversationList.vue";
import ConversationHeader from "@/pages/messages/conversations/ConversationHeader.vue";
import MessagesList from "@/pages/messages/MessagesList.vue";
import ConversationInfo from "@/pages/messages/conversations/ConversationInfo.vue";

export default {
  name: "MessagePage",
  components: {
    ConversationInfo,
    MessagesList,
    ConversationHeader,
    ConversationList,
    UsersPopUp,
    AppButton,
  },
  data() {
    return {
      userData: {},
      loading: false,
      usersFiltered: [],
      showUsers: false,
      nonMembers: [],
      clientHeight: document.documentElement.clientHeight,
      clientWidth: document.documentElement.clientWidth,
      options: [
        {id: 1, name: this.$t('conversations.viewConversationInformation')},
      ]
    }
  },
  watch: {
    '$route'() {
      this.doLoad();
      this.getUserNotMember();
    }
  },
  computed: {
    ...mapGetters(['GET_CONVERSATION', 'GET_CONVERSATIONS', 'GET_MESSAGE', 'GET_MESSAGES', 'GET_ALL_USERS']),
    action() { return this.$route.meta.action; },
    conversation: {
      get() {
        return this.GET_CONVERSATION;
      },
      set(value) {
        this.SET_CONVERSATION(value);
      }
    },
    conversations: {
      get() {
        return this.GET_CONVERSATIONS;
      },
      set(value) {
        this.SET_CONVERSATIONS(value);
      }
    },
    message: {
      get() {
        return this.GET_MESSAGE;
      },
      set(value) {
        this.SET_MESSAGE(value);
      }
    },
    messages: {
      get() {
        return this.GET_MESSAGES;
      },
      set(value) {
        this.SET_MESSAGES(value);
      }
    },
    users: {
      get() {
        return this.GET_ALL_USERS;
      },
      set(value) {
        this.SET_ALL_USERS(value);
      }
    },
    getHeight() {
      const t = document.getElementById('displayCtrl');
      const top = t?.offsetTop ?? 75;

      if (this.clientHeight > 300)
        return (this.clientHeight - top - 25 + (this.clientWidth * 0)) + 'px';
      else
        return '200px';
    },
    crudPath() {
      var url = this.$route.fullPath;

      if (url.endsWith('/edit'))
        url = url.substring(0, url.length - 5);
      if (this.$route.params.id !== undefined)
        url = url.substring(0, url.lastIndexOf('/'))

      return url;
    },
  },
  methods: {
    PlusIcon,
    PlusCircleIcon,
    ...mapActions(['CREATE_CONVERSATION', 'READ_CONVERSATION', 'READ_CONVERSATIONS', 'SAVE_CONVERSATION', 'CREATE_MESSAGE', 'READ_MESSAGES', 'READ_ALL_USERS', 'READ_ACCOUNT', 'READ_ACTION_STATUS']),
    async doLoad() {
      this.loading = true
      await this.READ_CONVERSATIONS()
      this.userData = await this.READ_ACCOUNT();
      const id = this.$route.params.id;
      if (id !== undefined) {
        if (id !== 'create') {
          this.READ_ACTION_STATUS('view')
          await this.READ_CONVERSATION({id: this.$route.params.id})
          await this.READ_MESSAGES({conversationId: this.$route.params.id})
        } else {
          this.loading = true;
          this.READ_ACTION_STATUS('create')
          await this.CREATE_CONVERSATION()
          await this.CREATE_MESSAGE()
          this.loading = false;
        }
      }
      this.scrollToBottom()
      this.getUserNotMember();
      this.loading = false;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          const element = document.getElementById('scroll');
          if(element) {
              element.scrollTop = element?.scrollHeight;
          }
        }, 0);
      });
    },
    getDimensions() {
      this.clientHeight = document.documentElement.clientHeight;
      this.clientWidth = document.documentElement.clientWidth;
    },
    doCreate() {
      this.$router.push(this.crudPath + '/create')
    },
    viewConversationInformation() {
      this.READ_ACTION_STATUS('edit')
    },
    async addMembers(memberIds) {
      memberIds.forEach((memberId) => {
        this.conversation.members.push(memberId)
      })
      await this.SAVE_CONVERSATION(this.conversation)
      this.READ_ACTION_STATUS('view')
      this.nonMembers = []
    },
    getUserNotMember() {
      this.nonMembers = []
      this.users.forEach((user) => {
        if (!this?.conversation.members.includes(user.id)) {
          this?.nonMembers.push(user);
        }
      });
    },
    updateConversation(conversationMember) {
      this.conversation.members = conversationMember
    },
    async removeMember(memberId) {
      let index = this.conversation.members.indexOf(memberId);

      if (index !== -1) {
        this.conversation.members.splice(index, 1);
        await this.SAVE_CONVERSATION(this.conversation)
        this.READ_ACTION_STATUS('view')
      }
    },
    async leaveConversation() {
      let index = this.conversation.members.indexOf(this.userData.id);

      if (index !== -1) {
        this.conversation.members.splice(index, 1);
        await this.SAVE_CONVERSATION(this.conversation)
        this.$router.push('/messages/create')
        await this.doLoad()
        this.READ_ACTION_STATUS('create')
      }
    },
    FilterUserList() {
        this.usersFiltered = [...this.users];
        let index = this.usersFiltered.id?.indexOf(this.userData.id);
      if (index !== -1) {
        this.usersFiltered.splice(index, 1);
      }
    }
  },
  async mounted() {
    await this.doLoad()
    this.scrollToBottom()
    this.FilterUserList()
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
}
</script>

<style scoped>

</style>
