import API from '@/api'

const state = {
  ACCOUNT: {},
}

const getters = {
  GET_ACCOUNT: state => state.ACCOUNT,
}

const mutations = {
  SET_ACCOUNT(state, payload) {
    state.ACCOUNT = payload
  },
}

const actions = {
  async READ_ACCOUNT(context) {
    const response = await API.Get('/api/account')
    if (response.type === 'Success') {
      context.commit('SET_ACCOUNT', response.res.data)
      return response.res.data;
    }
    context.commit('SET_ACCOUNT', {})

    return null;
  },
  async UPDATE_ACCOUNT(context, payload) {
    const response = await API.Put(`/api/account`, payload)
    if (response.type === 'Success') {
      context.dispatch('USER');

      context.commit('SET_ACCOUNT', response.res.data)
      return response.res.data
    }
    return false
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
