<template>
  <app-crud ref="crud" :title="dashboard_element.name">
    <app-label :label="$t('assets.description')"
               :icon="'bars_regular'"
               :icon-color="'fill-primary-500'"
               :value="dashboard_element.description"/>

    <app-icon :icon="dashboard_element.icon" :width="20" :height="20">
      <span class="font-nhu-regular text-gray-900 ml-2">{{ dashboard_element.icon }}</span>
    </app-icon>
    <div class="h-4"/>
    <div class="flex items-center mb-2">
      <app-icon extra-class="mr-2 fill-primary-500" icon="palette_regular" :width="20" :height="20"/>
      <span class="font-nhu-semi-bold fs-14">{{ $t('teams.addColor') }}</span>
    </div>
    <div class="w-8 h-8 rounded-full cursor-pointer"
         :style="{ 'background-color': dashboard_element?.color }">
    </div>
    <app-label :label="$t('records.dashboard.fields.record')"
               icon="gears_regular"
               icon-color="fill-primary-500"
               :value="getLabel(dashboard_element.recordId)"/>

<!--    <app-crud-filters :filter="getFilter"></app-crud-filters>-->

  </app-crud>
</template>

<script>
import AppCrud from "@/crud/AppCrud";
import {mapGetters} from "vuex";
import AppIcon from "@/components/AppIcon";

export default {
  name: "DashboardPage",
  components: {AppIcon, AppCrud},
  computed: {
    ...mapGetters({dashboard_element: 'GET_DASHBOARD_ELEMENT', company: 'GET_CURRENT_COMPANY'}),
    getFilter() {
      let itemFilters = JSON.parse(this.dashboard_element.filter)
      let formattedFilters = {}
      for (let key in itemFilters) {
        formattedFilters[key] = {
          fieldId: key,
          values: itemFilters[key]
        }
      }
      let filters = JSON.parse(localStorage.getItem('app-crud-filters'))
      filters[this.dashboard_element.recordId] = formattedFilters
      return filters
    }
  },
  methods: {
    getLabel(recordId) {
      if (this.company && this.company.recordLabels && this.company.recordLabels.workorders.label) {
        let labelInfo = this.company.recordLabels.workorders.label;
        return this.$t('customName') + labelInfo;
      } else {
        return this.$t(recordId+'.name');
      }
    },
  }
}
</script>

<style scoped>

</style>
