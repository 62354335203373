<template>
    <div class="sm:flex sm:items-center">
        <span class="font-nhu-semi-bold text-[20px]">{{$t('assets.typeData.types')}}</span>
    </div>
    <div class="h-4" />

    <div class="flex gap-2">
        <app-button @click="doCreate" type="primary" background="light" :icon="PlusIcon"> {{ $t('assets.typeData.create') }} </app-button>
        <app-button @click="doDelete" :disabled="selection.length === 0"> {{ $t('assets.typeData.delete') }} </app-button>
        <div class="my-auto"> {{ message }} </div>
    </div>

    <app-table ref="list" v-model:itemsSelected="selection" :multi="true"
               value="id" url="/api/types" :columns="columns">
        <template #item-name="item">
            <div @click="doEdit(item)" class="cursor-pointer underline">
                {{ item.name }}
            </div>
        </template>
    </app-table>

    <new-type ref="popup" @reload="reload" />

    <app-confirm-popup v-model:open="showDeletePopUp" @onYes="doDelete2"
                       :message="$t('assets.typeData.sureToDelete')" />
</template>

<script>
import { PlusIcon, DocumentPlusIcon } from '@heroicons/vue/24/outline'
import {mapActions} from 'vuex';
import NewType from "@/pages/assets/types/NewType.vue";

export default {
  name: 'TypeList',
  components: {NewType},
  data() {
    return {
      ...{ PlusIcon, DocumentPlusIcon },
      message: '',
      selection: [],
      showDeletePopUp: false,
      columns: [
        { value: 'id', text: this.$t('assets.typeData.id'), sortable: true },
        { value: 'name', text: this.$t('assets.name'), sortable: true },
      ],
    }
  },
  methods: {
    ...mapActions(['DELETE_TYPE_LIST']),

    async reload()
    {
      await this.$refs.list.load();
    },
    doCreate()
    {
      this.$refs.popup.create();
    },
    doEdit(item)
    {
      this.$refs.popup.edit(item.id);
      //this.$router.push('/admin/site/' + item.id + '/edit');
    },
    doDelete() {
      this.showDeletePopUp = true;
    },
    async doDelete2() {
      let sites = this.selection;
      if (await this.DELETE_TYPE_LIST({ deletes: sites })) {
        if (this.selection.length === sites.length) {
          this.selection = [];
        }
        this.reload();
        this.message = this.$t('assets.deleted');
      }
    },
  },
  async created() {
  },
}
</script>
