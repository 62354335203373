<template>
    <div class="border-b border-gray-300 bg-gray-50 p-2 h-[57px] grid items-center sticky top-0">
        <div v-if="localAction === 'create'" class="flex">
            <div>
                <span>to:</span>
            </div>
            <div class="w-full mx-3">
                <div class="bg-white">
                    <app-dropdown v-model="localConversation.members" :options="usersFiltered"
                                  @change="updateConversation"></app-dropdown>
                </div>
            </div>
        </div>
        <div v-else class="flex justify-between">
            <div class="mr-auto">
                <div v-if="localConversation.type === 'private' || localConversation.type === 1"
                     class="flex items-center">
                    <div v-if="getUserConversationData(localConversation.members) && getUserConversationData(localConversation.members)?.images?.length >= 1">
                        <app-image :src="getUserConversationData(localConversation.members)?.images"
                                   alt="user"
                                   class="w-10 h-10 mr-2 rounded-full"/>
                    </div>
                    <div v-else>
                        <UserIcon class="w-10 h-10 mr-2 rounded-full"/>
                    </div>
                    {{ getUserConversationData(localConversation.members)?.name }}
                </div>
                <div v-else class="flex items-center">
                    <UserGroupIcon class="w-10 h-10 mr-2 rounded-full"/>
                    {{ localConversation.name }}
                </div>
            </div>
            <div class="ml-auto flex items-center">
                <Menu as="div" class="relative inline-block text-left">
                    <MenuButton>
                        <ellipsis-vertical-icon class="w-6 h-6 cursor-pointer"/>
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95">
                        <MenuItems
                                class="origin-top-right right-0 absolute z-10 mt-2 w-80 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <div :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']"
                                         @click="viewConversationInformation()">
                                        {{ $t('conversations.viewConversationInformation') }}
                                    </div>
                                </MenuItem>
                                <MenuItem v-if="localConversation.type === 'Group' || localConversation.type === 2"
                                          v-slot="{ active }">
                                    <div :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']"
                                         @click="leaveConversation()">
                                        {{ $t('conversations.leaveConversation') }}
                                    </div>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
    </div>
</template>

<script>
import {EllipsisVerticalIcon, UserGroupIcon, UserIcon} from "@heroicons/vue/24/outline";
import AppImage from "@/components/AppImage.vue";
import {Menu, MenuItems, MenuItem, MenuButton} from '@headlessui/vue'

export default {
  name: "ConversationHeader",
  components: {AppImage, UserIcon, EllipsisVerticalIcon, UserGroupIcon, Menu, MenuItems, MenuItem, MenuButton},
  emits: ['updateAction', 'updateConversation', 'leaveConversation'],
  props: {
    action: {
      type: String,
      default: ''
    },
    conversation: {
      type: Object,
      default: () => {
      }
    },
    userData: {
      type: Object,
      default: () => {
      }
    },
    users: {
      type: Object,
      default: () => {
      }
    },
    usersFiltered: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localConversation: {},
      localAction: '',
      options: [
        {id: 1, name: this.$t('conversations.viewConversationInformation')},
        {id: 2, name: this.$t('conversations.leaveConversation')},
      ]
    }
  },
  watch: {
    conversation: {
      deep: true,
      handler(newValue) {
        this.localConversation = {...newValue};
      }
    },
    action(newValue) {
      this.localAction = newValue;
    },
  },
  methods: {
    getUserConversationData(members) {
      const userId = this.userData.id || null;
      return this.getUser(members.filter(x => x !== userId)[0])
    },
    getUser(senderId) {
      const user = this.users.find(x => x.id === senderId);
      return user || {};
    },
    viewConversationInformation() {
      this.$emit("updateAction");
    },
    updateConversation() {
      this.$emit("updateConversation", this.localConversation.members);
    },
    leaveConversation() {
      this.$emit("leaveConversation");
    }
  },
  created() {
    this.localConversation = this.conversation
    this.localAction = this.action
  }
}
</script>

<style scoped>

</style>
