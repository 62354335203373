<template>
  <div class="flex flex-col items-center justify-center h-screen px-4 py-5 sm:px-6 lg:px-8 sm:w-1/3 mx-auto">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="/images/zigo.png" alt="zgio" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{$t('login.forgotPassword.forgotMyPassword')}}</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t('login.or') }}
          {{ ' ' }}
          <a href="#" @click="gotoLogin" class="font-medium text-purple-600 hover:text-purple-500"> {{$t('login.logInWithYourUsername')}} </a>
        </p>
      </div>

      <app-stepper>
        <app-step :active="!sentEmail" :done="sentEmail" :label="$t('login.forgotPassword.emailValidation')" :description="$t('login.forgotPassword.emailValidationDesc')">
          <div class="rounded shadow-sm -space-y-px">
            <app-form-field id="email" label="Email">
              <app-textbox id="email" v-model="email" placeholder="email" :autocomplete="false"/>
            </app-form-field>
          </div>
          <div>
            <app-button @click="startPasswordReset" class="w-full" :disabled="email === ''">
              {{ $t('login.forgotPassword.request') }}
            </app-button>
          </div>
        </app-step>
        <app-step :active="sentEmail" :done="resetDone" :label="$t('login.forgotPassword.defineNewKey')" :description="$t('login.forgotPassword.defineNewKeyDesc')">
          <div class="rounded shadow-sm -space-y-px">
            <app-form-field id="code" :label="$t('code')">
              <app-textbox id="code" v-model="code" :placeholder="$t('code')" :autocomplete="false"/>
            </app-form-field>
            <app-form-field id="new_password" :label="$t('newKey')">
              <app-textbox id="new_password" v-model="new_password" :placeholder="$t('newKey')" type="password" :autocomplete="false"/>
            </app-form-field>
          </div>
          <div>
            <app-button @click="resetPassword" class="w-full">
              {{ $t('login.forgotPassword.resetKey') }}
            </app-button>
          </div>
        </app-step>
        <app-step :active="resetDone" :last="true" :label="$t('login.forgotPassword.defineNewKey')" :description="$t('login.forgotPassword.afterSetKey')">
          <app-button @click="gotoAdmin" class="w-full">
            {{ $t('login.forgotPassword.enterZigo') }}
          </app-button>
        </app-step>
      </app-stepper>

      <span>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPasswordPage',
  components: {  },
  data() {
    return {
      sentEmail: false,
      resetDone: false,
      error: '',
      email: '',
      code: '',
      new_password: '',
    }
  },

  computed: {
  },

  methods: {
    ...mapActions(['FORGOT_PASS', 'RESET_PASS']),
    async startPasswordReset(){
      var res = await this.FORGOT_PASS({ email: this.email, password: this.password })
      if(!res)
        this.sentEmail = true;
      else
        this.error = res;
    },
    async resetPassword()
    {
      var res = await this.RESET_PASS({ email: this.email, code: this.code, password: this.new_password })
      if(res)
        this.resetDone = true;
    },
    gotoAdmin()
    {
      this.$router.push('/admin');
    },
  },

}
</script>
