<template>
    <div :class="{ 'w-full': true, 'border-l-4 border-primary-100': !isRoot }">
        <div class="w-full flex mb-6">
            <div class="flex-grow">
<!--              <app-icon icon="chevron_down_regular" extra-class="fill-white mr-2" :width="16" :height="16" />-->
                <span class="font-nhu-regular fs-18 flex-1">{{ item.title }}</span>
<!--                <div> {{ countValues(item.items) }} / {{ item.items?.length ?? 0 }}</div>-->
            </div>
        </div>
        <div v-if="item.showItems === true">
            <div v-for="(subItem, subItemIndex) in item.items" :key="`subItem-${subItemIndex}`">
                <div v-if="subItem.isSection">
                    <InspectionSection :item="subItem" :disabled="disabled" :check-validation-answers="checkValidationAnswers"/>
                </div>
                <div v-else class="border border-gray-100 rounded-lg my-5 px-5 py-5 shadow-md" 
                  :class="validation(value[subItemIndex], subItem)  && checkValidationAnswers ? 'border-red-500 bg-[#FBEEED]' : 'border-gray-300 bg-white'">
                  <InspectionQuestion :item="subItem" :disabled="disabled" @updateValue="updateValue($event, subItemIndex)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import InspectionQuestion from '@/pages/inspections/InspectionQuestion.vue';
import InspectionSection from '@/pages/inspections/InspectionSection.vue';
import isNullOrEmpty from "@/global/validations";

export default {
    name: 'InspectionSection',
    components: {InspectionQuestion, InspectionSection,
      // ChevronDownIcon
    },
    props: {
        item: Object,
        templateOptions: Array,
        isRoot: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        checkValidationAnswers: {
          type: Boolean,
          default: false
        }
    },
    data() {
      return {
        value: [],
      };
    },
    computed: {
        ...mapGetters({ inspection: 'GET_INSPECTION', template: 'GET_PUBLISH_TEMPLATE' }),
    },
    methods: {
      isNullOrEmpty,
        toggleSection(section) {
            section.showItems = !section.showItems;
        },
        updateValue(resultChanged, index) {
          return this.value[index] = resultChanged?.value;
        },
        validation(value, item) {
          if (!item.isRequired) return false;

          switch (item.questionType) {
            case 'instruction':
              return false;
            default:
              return this.isNullOrEmpty(value);
          }
        },
    },
};
</script>
