<template>
  <app-crud  v-slot="{ lock }" @save="doSave" :loading="loading">

    <app-textbox v-model="action.name" :label="$t('admin.actions.name')" :disabled="lock" />
    <app-dropdown v-model="action.status" value="id" :label="$t('admin.actions.status.name')" :options="status" :disabled="lock" />
    <app-textbox v-model="action.description" :label="$t('admin.actions.description')" :disabled="lock" />
    <app-dropdown v-model="action.peopleId" value="id" :label="$t('admin.actions.people')" getter="GET_PEOPLE" url="/api/people/all"  :disabled="lock" />
    <app-dropdown v-model="action.assetId" value="id" :label="$t('admin.actions.asset')" getter="GET_ASSETS" url="/api/assets/all"  :disabled="lock" />
    <app-dropdown v-model="action.tagId" value="id" :label="$t('admin.actions.tag')" getter="GET_TAGS" url="/api/tags/tagList" :disabled="lock" />
    <app-date-picker v-model="action.dueDateTime" :label="$t('admin.actions.dueDateTime')" value="id" :disabled="lock" />
    <app-dropdown v-model="action.priority" value="id" :label="$t('admin.actions.priority')" :options="priority" :disabled="lock" />

  </app-crud>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import emitter from 'tiny-emitter/instance'

export default {
  name: "ActionPage",
  data() {
    return {
      loading: true,
      priority: [
        {id: 'low', name: this.$t('admin.actions.low')},
        {id: 'mid', name: this.$t('admin.actions.mid')},
        {id: 'high', name: this.$t('admin.actions.high')},
      ],
      status: [
          {id: 'pending', name: this.$t('admin.actions.status.pending')},
          {id: 'inProgress', name: this.$t('admin.actions.status.inProgress') },
          {id: 'completed', name: this.$t('admin.actions.status.completed') },
          {id: 'notFeasible', name: this.$t('admin.actions.status.notFeasible') },
      ],
    }
  },
  watch:{
    '$route'() {
      if(this.$route.path.startsWith('/admin/actions/')) {
        this.$refs.crud.reset();
        this.doLoad();
      }
    }
  },
  computed:{
    ...mapGetters({ action: 'GET_ACTION' }),
    actionStatus() { return this.$route.meta.action; },
    showDate() {
        return typeof this.action.status === 'object' ? this.action.status.id === 'standby' : this.action.status === 'standby'
    }
  },
  methods: {
    ...mapActions(['READ_ACTION', 'CREATE_ACTION', 'SAVE_ACTION', 'READ_ACTION_STATUS']),

    async doLoad() {
      this.loading = true;
      const id = this.$route.params.id;
      if(id !== undefined)
      {
        if(id !== 'create') {
          this.READ_ACTION_STATUS('view')
          await this.READ_ACTION({ id: this.$route.params.id })
        } else {
          this.READ_ACTION_STATUS('create')
          await this.CREATE_ACTION();
        }
      }
      this.loading = false;
    },

    async doSave() {
      /* Si el modelo es correcto, se pasa a la vista de lectura */
      emitter.emit('changeToView')
      await this.SAVE_ACTION(this.action)
    },

    getStatusName(status) {
        switch (status) {
            case 'active':
                return this.$t('admin.action.active');
            case 'standby':
                return this.$t('admin.action.standby');
            case 'inactive':
                return this.$t('admin.action.inactive');
            default:
                return status;
        }
    },
  },
  async mounted() {
    await this.doLoad()
  },
}
</script>
