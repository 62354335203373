<template>
  <div class="lg:flex lg:h-full lg:flex-col px-2">
    <header class="flex items-center justify-between border-gray-200 py-4 lg:flex-none">
      <div class="flex items-center justify-start w-[20%]">
        <div
            class="px-3.5 h-9 cursor-pointer border-[1px] border-primary-500 bg-white flex items-center justify-center rounded-md"
            @click="goToday()">
          <span class="font-nhu-500 text-primary-500">{{ $t('Today') }}</span>
        </div>
        <div class="flex h-[44px] pl-[11px]">
          <div
              class="flex items-center justify-center rounded-l-md fill-primary-500 bg-white cursor-pointer w-[44px] h-[100%]"
              @click="gotoDate(-1)">
            <app-icon icon="chevron_left_regular" width="15" height="15"></app-icon>
          </div>
          <div
              class="flex items-center justify-center rounded-l-md fill-primary-500 bg-white cursor-pointer w-[44px] h-[100%]]"
              @click="gotoDate(1)">
            <app-icon icon="chevron_right_regular" width="15" height="15"></app-icon>
          </div>
        </div>
      </div>
      <div class="flex">
        <span class="font-nhu-500 flex capitalize items-center fs-18 text-gray-700">
          {{ getMonthYear }}
        </span>
      </div>
      <div class="w-[20%] flex justify-end">
        <div
            class="h-11 cursor-pointer border-[1px] border-primary-500 flex items-center justify-center rounded-md w-fit">
          <div v-for="(view, index) in ['monthly', 'weekly']" :key="view" @click="setView(view)"
               :class="[currentView === view ? 'bg-primary-25': '',
               'h-full flex items-center px-1 text-primary-500 pl-3 pr-3 ',
                index === 0 ? 'rounded-l-md border-r-primary-500 border-r-[1px]'
                : 'rounded-r-md']">
          <span class="capitalize font-nhu-500 fs-14">
            {{ $t(view) }}
          </span>
          </div>
        </div>
      </div>
    </header>
    <div class=" flex flex-auto flex-col">
      <div class="grid grid-cols-7 fs-14 font-nhu-regular text-gray-700">
        <div class="bg-white py-[10px] flex align-center justify-center items-center"
             v-for="(day, index) in days.slice((week-1)*7,week*7)"
             :key="day.date">
          <div :class="[index >= 5  ? 'text-gray-400' : '', 'px-1 h-6 flex items-center']">
            {{ $t(weekdays[index]).substr(0, 3) }}
          </div>
          <div v-if="currentView === 'weekly'"
               :class="[day.isToday ? 'rounded-full bg-primary-500 text-white' : index >=5 ? 'text-gray-400' : '', 'w-6 h-6 items-center align-center justify-center flex']">
            {{ day.date.split('-').pop() }}
          </div>
        </div>
      </div>
      <app-loading :loading="false && loading" class="w-full"/>
      <div class=" border flex border-gray-100 text-xs leading-6 text-gray-700 lg:flex-auto rounded-md"
           v-if="days.length >0">
        <!-- Vista mensual -->
        <div class="w-full grid grid-cols-7 gap-px bg-gray-100 rounded-md" :class="'grid-rows-'+Math.ceil(days.length / 7)"
             v-if="currentView === 'monthly'">
          <div v-for="(day, index) in days" :key="day.date"
               :class="[day.isCurrentMonth
               ? day.isToday ? 'bg-white border-[1px] border-primary-500' : 'bg-white'
               : 'bg-gray-25 text-gray-500',
               'min-h-[80px] border-1', {'rounded-tl-md': index === 0}, {'rounded-tr-md': index === 6}, {'rounded-bl-md': index === days.length - 7}, {'rounded-br-md': index+1 === days.length}]">
            <div class="flex justify-end top-[6px] right-[6px]  relative">
              <div
                  :class="[day.isToday ? 'text-white bg-primary-600' : '', day.isCurrentMonth ? 'text-gray-700' : 'text-gray-200', 'flex justify-center rounded-full h-6 w-6 items-center font-nhu-regular fs-14 absolute']">
                <span>{{ day.date.split('-').pop().replace(/^0/, '') }}</span>
              </div>
            </div>
            <div class="px-2 pt-8 h-full">
              <draggable :list="day.events.filter(x => !x.isRecurrence)?.slice(0, 3)" :itemKey="item => item.id" group="events" @change="handleChange(day, $event)" :class="day.events.length < 1 ? 'h-full' : ''">
                <template #item="{element: item}">
                  <calendar-event
                      v-if="day.events.length > 0"
                      :event="item"
                      @click.stop="doView(item)">
                  </calendar-event>
                </template>
              </draggable>
          
              <!-- Bloque para mostrar más eventos si hay más de 3 -->
              <div v-if="day.events.length > 3"
                   class="text-gray-500 hover:text-primary-500 cursor-pointer flex font-nhu-500 px-2"
                   @click="goToWeek(index)">
                <span class="px-2">{{ day.events.length - 3 }} {{ $t('orders') + ' ' + $t('more') + '...' }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Vista semanal -->
        <div class="w-full grid grid-cols-7 rounded-2xl" v-else>
          <div v-for="(day, index) in days.slice((week-1)*7,week*7)" :key="day.date"
               :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-25', 'min-h-[65vh]', index > 4 ? '!bg-gray-50' : '',
               index > 0 ? 'border-l-[1px] border-gray-200' : '', {'rounded-l': index === 0}, {'rounded-r': index === 6}]">
            <div class="px-2 py-1">
              <ol v-if="day.events.length > 0" class="mt-[14px]">
                <draggable :list="day.events" :itemKey="item => item.id" group="events" @change="handleChange(day, $event)">
                  <template #item="{element: item}">
                    <calendar-event :event="item" @click="doView(item)"/>
                  </template>
                </draggable>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import AppIcon from "@/components/AppIcon";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CalendarEvent from "@/components/CalendarEvent";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "AppCrudCalendar",
  components: {
    CalendarEvent,
    AppIcon,
    Draggable
  },
  emits: ['viewWO'],
  data() {
    return {
      loading: false,
      days: [],
      month: new Date().getMonth(),
      currentMonth: new Date().getMonth(),
      show: false,
      showedDay: {},
      calendar: [],
      week: 1,
      step: 0,
      weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    }
  },
  async mounted() {

  },
  watch: {
    needUpdate: {
      handler: function (val) {
        if (!val) {
          return
        }
        this.reloadData()
      },
      deep: true,
    },
    getMonthYear: {
      handler: function () {
        this.reloadData()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({needUpdate: 'GET_CALENDAR_NEED_UPDATE', currentView: 'GET_CALENDAR_VIEW'}),
    ...mapMutations(['SET_CALENDAR_VIEW']),
    getMonthYear() {
      let date = new Date()
      date.setDate(1)
      date.setMonth(this.month)
      return date.toLocaleString(this.lang, {month: 'long'}) + ' ' + date.getFullYear()
    },
    lang() {
      return this.$i18n.locale
    },
  },
  methods: {
    ...mapActions(['READ_WORKORDERS_CALENDAR', "SAVE_WORKORDER"]),
    async reloadData() {
      let year = new Date().getFullYear()
      let initialDate = new Date(year, this.month, 1)
      let lastDate = new Date(year, this.month + 1, 0)
      await this.getCalendarData({
        start: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()).toUTCString(),
        end: new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate()).toUTCString()
      })
    },
    goToWeek(index) {
      this.setView('weekly')
      this.week = Math.ceil((index + 1) / 7)
    },
    gotoDate(step) {
      this.step = step
      if (this.currentView === 'monthly') {
        this.month = this.month + step
      } else {
        this.week += step
        if (this.week > Math.ceil(this.days.length / 7)) {
          this.month = this.month + 1;
        } else if (this.week <= 0) {
          this.month = this.month - 1;
        }
      }
    },
    goToday() {
      this.month = new Date().getMonth()
      const indexOf = this.days.indexOf(this.days.find(day => day.date === this.getDateFormat(new Date())))
      this.week = Math.ceil((indexOf) / 7)
      this.needGoToday = false
    },
    setView(view) {
      this.$store.commit('SET_CALENDAR_VIEW', view)
      if (view === 'weekly' && this.month === new Date().getMonth()) {
        this.goToday()
      }
    },
    doView(workOrder) {
      this.$emit('viewWO', workOrder)
    },
    getDateFormat(date) {
      return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
      ].join('-');
    },
    
    loadMonth(startDate, endDate) {
      const today = this.getDateFormat(new Date())
      //Añado los dias antes del mes
      for (let i = 0; i < ((new Date(startDate)).getDay() + 6) % 7; i++) {
        let dayOf = new Date(startDate.getFullYear(), startDate.getMonth(), -i)
        this.days.unshift({date: this.getDateFormat(dayOf), isCurrentMonth: false, events: []})
      }
      //Añado los dias del mes
      for (let i = 1; i <= endDate.getDate(); i++) {
        let dateFormatted = this.getDateFormat(new Date(endDate.getFullYear(), endDate.getMonth(), i))
        this.days.push({date: dateFormatted, isCurrentMonth: true, events: [], isToday: today === dateFormatted})
      }
      let j = 1;
      //Añado los dias despues del mes
      for (let i = ((new Date(endDate)).getDay() + 6) % 7; i < 6; i++) {
        let dayOf = new Date(endDate.getFullYear(), endDate.getMonth() + 1, j)
        this.days.push({date: this.getDateFormat(dayOf), isCurrentMonth: false, events: []})
        j++
      }
    },

    async getCalendarData(payload = {start: '', end: ''}) {
      this.loading = true;
      this.days = []
      if ((!payload?.start || !payload?.end)) {
        return
      }
      this.loadMonth(new Date(payload.start), new Date(payload.end))
      this.calendar = await this.READ_WORKORDERS_CALENDAR({ start: this.days[0].date,  end: this.days[this.days.length - 1].date })

      for (const day of this.calendar) {
        let indexOf = this.days.findIndex(x => x.date === this.getDateFormat(new Date(day.dueDate)))

        this.days[indexOf].events.push(
            {
              id: day.id,
              name: day.name,
              time: day.dueDate.substring(11, 16),
              datetime: day.dueDate,
              status: day.status,
              requestedById: day.requestedById,
              isRecurrence: day.isRecurrence,
              number: day.number,
              href: '#'
            }
        )
      }
      if (this.step >= 0) {
        this.week = 1
      } else {
        this.week = Math.ceil(this.days.length / 7)
      }
      this.loading = false;
    },

    async handleChange(day, event) {
      let movedElement = event.added?.element;
      if (movedElement) {
        let workOrder = this.calendar?.find(x => x.id === movedElement?.id)
        let newWorkOrder = {
          ...workOrder,
          dueDate: day.date + 'T' + workOrder?.dueDate.substring(11, 16) + ':00Z'
        }
        await this.SAVE_WORKORDER(newWorkOrder)
      }
    }
  }
})

</script>
<style scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

button {
  color: white !important;
  border-color: white !important;
  border-radius: 5px !important;
  background-color: #1BA335 !important;
}

.fc {
  width: 100%;
  margin: 0 auto;
}

.calendar-item:hover {
  padding: 0.5em;
  border-radius: 3px;
  background-color: #1BA335;
  color: white;
}
</style>
