import menu from '@/menus/MainMenu'

const state = {
    MAIN_MENU: menu,
}

const getters = {
    GET_MAIN_MENU: state => state.MAIN_MENU,
}

const mutations = {
    SET_MAIN_MENU(state, payload) {
        state.MAIN_MENU = payload
    }, 
    UPDATE_MAIN_MENU(state, payload) {
        state.MAIN_MENU = payload
    },
}

const actions = {
    UPDATE_MAIN_MENU(context, payload) {
        context.commit('SET_MAIN_MENU', payload);
    }, 
    UPDATE_MAIN_MENU_ITEM(context, payload) {
        const index = state.MAIN_MENU.findIndex(item => item.id === payload.id)
        state.MAIN_MENU[index].name = payload.name
        context.commit('UPDATE_MAIN_MENU', state.MAIN_MENU);
    },
}

export default {
    state, getters, mutations, actions
}
