<template>
  <app-loading :loading="loading">
    <ul role="list" v-if="modelValue !== null" class="grid grid-cols-1 gap-6 mb-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
      <li v-for="item in modelValue" :key="item.id" class="col-span-1 bg-white divide-y divide-gray-200 shadow rounded-2xl">
          <slot v-bind:item="item"></slot>
      </li>
    </ul>
  </app-loading>
</template>

<script>

export default {
  name: "AppGrid",
  components: {},
  emits: [],
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
  },
  computed: {
  }
};
</script>
