<template>
    <div>
        <app-breadcrumbs :pages="pages"/>
        <div class="h-4"/>

        <div class="flex gap-2">
            <app-button @click="doCreate" type="primary" background="light" :icon="PlusIcon"> {{$t('admin.issues.create') }}</app-button>
            <app-button @click="doDelete" :disabled="selection.length === 0"> {{ $t('admin.issues.delete') }}</app-button>
            <app-button @click="goCategories"> {{ $t('admin.issues.categories.title') }}</app-button>
            <div class="my-auto"> {{ message }}</div>
        </div>

        <app-table ref="list" v-model:itemsSelected="selection" :multi="true"
                   value="id" url="/api/issues" :columns="columns">
            <template #item-title="item">
                <div @click="doEdit(item)" class="cursor-pointer underline">
                    {{ item.title }}
                </div>
            </template>
            <template #item-status="item">
                <div>
                    {{ getStatus(item.status) }}
                </div>
            </template>
            <template #item-priority="item">
                <div>
                    {{ getPriority(item.priority) }}
                </div>
            </template>
        </app-table>
    </div>
    <new-issue ref="popup" @reload="reload"/>

    <app-confirm-popup v-model:open="showDeletePopUp" @onYes="doDelete2"
                       :message="$t('admin.issues.sureToDelete')"/>
</template>


<script>
import {PlusIcon, DocumentPlusIcon} from '@heroicons/vue/24/outline'
import {mapActions, mapGetters} from 'vuex';
import NewIssue from "@/pages/admin/issues/NewIssue.vue";

export default {
  name: 'IssueListPage',
  components: {NewIssue},
  data() {
    return {
      ...{PlusIcon, DocumentPlusIcon},
      message: '',
      account: {},
      selection: [],
      editIssue: false,
      showDeletePopUp: false,
      columns: [
        {value: 'title', text: this.$t('admin.issues.title'), sortable: true},
        {value: 'issueCategory', text: this.$t('admin.issues.category'), sortable: false},
        {value: 'status', text: this.$t('admin.issues.status.title'), sortable: false},
        {value: 'priority', text: this.$t('admin.issues.priority'), sortable: false},
        {value: 'people', text: this.$t('admin.issues.assignedTo'), sortable: false},
        {value: 'site', text: this.$t('admin.issues.site'), sortable: false},
      ],
    }
  },
  computed: {
    ...mapGetters({issue: 'GET_ISSUE', inspection: 'GET_INSPECTION'}),
    pages() {
      return [
        {name: this.$t('admin.issues.name'), href: '/issues'},
      ]
    },
  },
  methods: {
    ...mapActions(['DELETE_ISSUE_LIST']),

    async reload() {
      await this.$refs.list.load();
    },
    doCreate() {
      this.$refs.popup.create();
    },
    doEdit(item) {
      this.$router.push('/issue/' + item.id + '/edit');
    },
    doDelete() {
      this.showDeletePopUp = true;
    },
    async doDelete2() {
      let issue = this.selection;
      if (await this.DELETE_ISSUE_LIST({deletes: issue})) {
        if (this.selection.length === issue.length) {
          this.selection = [];
        }
        this.reload();
        this.message = this.$t('admin.issues.deleted');
      }
    },
    goCategories() {
      this.$router.push('/issues/categories');
    },
    getStatus(status) {
      if (status) {
        return this.$t('logs.' + status)
      }
    },
    getPriority(priority) {
      if (priority) {
        return this.$t('logs.' + priority)
      }
    }
  },
  async created() {
  },
}
</script>

<style scoped>

</style>
