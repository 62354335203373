<template>
<span v-if="priority !== ''"
      class="inline-flex items-center gap-x-1 rounded-2xl px-2 py-[2px] h-5 text-xs font-medium border-[1px] font-nhu-regular"
      :class="$iconsByPriority.class(priority)">
        <app-icon icon="circle_solid" :widht="8" :height="8"></app-icon>
        {{ $t("records.types.priority." + priority) }}
      </span>
</template>

<script>
export default {
  name: "AppPriority",
  props: {
    priority: {
      type: String,
      default: null,
    },
  },
}
</script>
