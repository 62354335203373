<template>
  dd
 
</template>

<script>


export default {
  name: "AppDualList",
  components: {  },
  props: {
    id: {
      type: String,
      default: null
    },
  },
  data(){
    return {
      source: [
          { name: "WHITE", code: "#FFFFFF" },
          { name: "SILVER", code: "#C0C0C0" },
          { name: "GRAY", code: "#808080" }
      ],
      destination: [
          { name: "BLACK", code: "#000000" },
          { name: "RED", code: "#FF0000" }
      ]
    }
  },
  emits: ["update:modelValue"],
  computed: {        
  },
  methods: {
    onChangeList({ source, destination }) {
      this.source = source;
      this.destination = destination;
    }
    
  },
  async created() {
    if (this.modelValue == null && this.options && this.options.length > 0){
      this.$emit("update:modelValue", this.options[0].id);
    }


  },
};
</script>
