<template>
  <app-dropdown :modelValue="modelValue" @update:modelValue="onChange" :label="label" :options="options" icon-label="bring_forward_regular" />
</template>

<script>

export default {
  name: "AppPriorityPicker",
  props: {
    label: { type: String, default: "" },
    modelValue: { type: [String], default: "none" },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      options: [
        {id: 'none', name: this.$t('options.priorities.none')},
        {id: 'low', name: this.$t('options.priorities.low')},
        {id: 'mid', name: this.$t('options.priorities.mid')},
        {id: 'high', name: this.$t('options.priorities.high')},
      ]
    }
  },
  methods: {
    onChange(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
