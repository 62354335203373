<template>
  <div class="flex justify-center mt-3">
    <app-icon :icon="'grip_regular'" class="fill-gray-500 handle " :width="20" :height="20"/>
  </div>
    <div class="w-full flex justify-between mb-4">
        <div class="flex items-center w-1/2">
            <div class="w-full pr-2">
              <app-textbox :model-value="modelValue.title" @change="changeTitle" :label="$t('templates.question.title')"/>
<!--              <input :id="'title-' + modelValue.id" :value="modelValue.title" @change="changeTitle" class="w-full"-->
<!--                  @keyup.up="onKeyUp" @keyup.down="onKeyDown" @keyup.enter="onKeyEnter" placeholder="Titulo de la pregunta" />-->
            </div>
        </div>
        <div class="flex items-center w-1/2">
          <div class="w-full pl-2">
            <infinite-selector :model-value="modelValue.questionType" :options="questionOptions" @update:modelValue="changeType">
              <template v-slot="{ option }">
                <div class="flex items-center text-gray-900 hover:text-primary-500 py-2 px-4">
                  <app-icon :icon="option.icon" :width="20" :height="20" class="fill-current hover:fill-primary-500">
                    <span class="font-nhu-regular ml-2">{{ option?.name }}</span>
                  </app-icon>
                </div>
              </template>
              <template #selected-option-container="{ option }">
                <div class="py-[2px] h-[20px] flex items-center mx-1" :key="option">
                  <app-icon :icon="option.icon" :width="20" :height="20" class="fill-gray-900">
                    <span class="flex items-center font-nhu-regular fs-14 text-gray-900 h-[20px] ml-2">{{ option.name }}</span>
                  </app-icon>
                </div>
              </template>
            </infinite-selector>
<!--            <app-dropdown :model-value="modelValue.questionType" :options="questionOptions" :label="$t('templates.question.type')" @update:modelValue="changeType"/>-->
          </div>
<!--            <TemplatePropertyType :value="modelValue" @update:value="changeType" />-->
        </div>
    </div>
    <div v-if="modelValue.questionType === 'multipleOptions'" class="my-4 pl-6">
      <multiple-options :item="modelValue" @updateAnswers="changeAnswers" @updateHasMultipleAnswers="updateMultipleOptions"/>
    </div>
<!--    <div class="w-full border pl-10 flex gap-4">-->
<!--        <div @click="addRule" class="underline cursor-pointer" v-if="modelValue.questionType === 'optionset'">+Regla</div>-->
<!--        <div>-->
<!--            <input type="checkbox" id="required" :checked="modelValue.isRequired" @change="changeRequired" />-->
<!--            {{ $t('templates.question.required') }}-->
<!--            Requerido-->
<!--        </div>-->
<!--        <div>-->
<!--            <input type="checkbox" id="flagged" :checked="modelValue.isFlagged" @change="changeFlagged" />-->
<!--            {{ $t('templates.question.flagged') }}-->
<!--        </div>-->
<!--    </div>-->
    <div v-for="rule in modelValue.rules" :key="rule.id" class="ml-12 p-1 border text-sm">
        <div class="w-full flex">
            <div class="my-auto">
                {{ $t('templates.question.ifanswer') }}
            </div>
            <div class="ml-1 my-auto border rounded-4xl">
                <app-dropdown v-model="rule.values" value="id" :options="optionset" :clean="true" />
            </div>
            <div class="ml-1 my-auto">
                {{ $t('templates.question.then') }}
            </div>
            <div v-if="rule.requireAction" class="ml-1 my-auto p-1 border rounded-xl bg-gray-50">
                {{  $t('templates.question.requireAction') }}
                <button @click.stop="removeRequireAction(rule)" title="Borrar Regla">
                X
                </button>
            </div>
            <div v-if="rule.requireEvidence" class="ml-1 my-auto p-1 border rounded-xl bg-gray-50">
                {{  $t('templates.question.requireEvidence') }}
                <button @click.stop="removeRequireEvidence(rule)" title="Borrar Regla">
                X
                </button>
            </div>

            <div class="ml-1 my-auto underline cursor-pointer">
                <Popover class="relative" >
                    <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        <span>{{ $t('templates.question.addRule') }}</span>
                        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                        <PopoverPanel class="absolute left-1/2 z-10  flex w-52 max-w-max -translate-x-1/2 px-4">
                            <div class="w-screen max-w-sm flex-auto rounded-xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                            <div v-for="action in actions" :key="action.id" class="relative rounded-lg p-1 hover:bg-gray-50">
                                <div @click="addAction(rule, action)" class="font-semibold text-gray-900">
                                {{ action.name }}
                                </div>
                            </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>
            </div>
            <div class="flex-1"></div>
            <button @click.stop="removeRule(rule)" :title="$t('templates.question.deleteRule')">
                <TrashIcon class="w-7 ml-1 cursor-pointer text-gray-400" aria-hidden="true" />
            </button>
        </div>
        <TemplateSection :model-value="rule" @update:model-value="fireUpdate" :isRoot="true" :isRule="true"></TemplateSection>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PencilIcon, TrashIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
import MultipleOptions from "@/pages/templates/editor/MultipleOptions.vue";
import InfiniteSelector from "@/components/InfiniteSelector.vue";
// import TemplatePropertyType from './TemplatePropertyType.vue'


export default {
    name: 'TemplateQuestion',
    components: {
      InfiniteSelector,
      MultipleOptions,
      // Bars3Icon,
      TrashIcon,
      ChevronDownIcon,
      Popover,
      PopoverButton,
      PopoverPanel,
      // TemplatePropertyType
    },
    emits:['update:model-value', 'keyup-up', 'keyup-down', 'keyup-enter'],
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            optionset: [],
            actions: [
                { id: 'action', name: this.$t('templates.question.actionRequired') },
                { id: 'evidence', name: this.$t('templates.question.evidenceRequired') },
                { id: 'notification', name: this.$t('templates.question.notify') },
                { id: 'question', name: this.$t('templates.question.moreQuestions') },
            ],
            questionOptions: [
              { id: 'multipleOptions', name: this.$t('questionTypes.multipleOptions'), icon: 'square_check_regular' },
              { id: 'text', name: this.$t('questionTypes.text'), icon: 'input_text_regular' },
              { id: 'int', name: this.$t('questionTypes.int'), icon: 'input_numeric_regular' },
              { id: 'media', name: this.$t('questionTypes.media'), icon: 'paperclip_regular' },
              { id: 'date', name: this.$t('questionTypes.date'), icon: 'calendar_regular' },
              { id: 'signature', name: this.$t('questionTypes.signature'), icon: 'signature_regular' },
            ]
        }
    },
    watch: {
        selected(newItem)
        {
            if(newItem?.id === this.modelValue?.id)
                this.setFocus();
        },
        modelValue()
        {
            this.checkOptionSet();
        },
    },
    computed: {
        ...mapGetters({template: 'GET_TEMPLATE', selected: 'GET_ITEM_SELECTED', optionsets: 'GET_OPTIONSETS'}),
    },
    methods: {
        PencilIcon,
        ...mapActions(['READ_TEMPLATE', 'UPDATE_TEMPLATE']),

        checkOptionSet()
        {
            if(this.modelValue?.questionType === 'optionset' && this.optionsets.length > 0) {
                this.optionset = this.optionsets.find(x => x.id === this.modelValue?.optionSetId).options;
            }
            else
                this.optionset = null;
        },

        setFocus()
        {
            const ele = document.getElementById('title-' + this.modelValue?.id)
            ele?.focus();
            ele?.select();
        },

        changeTitle(event)
        {
            let newValue = Object.assign({}, this.modelValue);
            newValue.title = event.target.value?.trim();
            this.$emit('update:model-value', newValue);
        },
        changeType(type)
        {
          let newValue = Object.assign({}, this.modelValue);
          newValue.questionType = type
          this.$emit('update:model-value', newValue);
        },
        changeRequired(event)
        {
            var newValue = Object.assign({}, this.modelValue);
            newValue.isRequired = event.target.checked;
            this.$emit('update:model-value', newValue);
        },

        onKeyUp()
        {
            this.changeTitle( { target: document.getElementById('title-' + this.modelValue?.id) });
            this.$emit('keyup-up')
        },
        onKeyDown()
        {
            this.changeTitle( { target: document.getElementById('title-' + this.modelValue?.id) });
            this.$emit('keyup-down')
        },
        onKeyEnter()
        {
            this.changeTitle( { target: document.getElementById('title-' + this.modelValue?.id) });
            this.$emit('keyup-enter')
        },


        addRule()
        {
            const val = this.optionset[0];
            let newValue = Object.assign({}, this.modelValue);
            if(!newValue.rules)
                newValue.rules = []
            newValue.rules.push({
                id: Math.random().toString(36).substring(2, 15),
                operation: 'eq',
                values: [val.id],
                items: []
            })
            this.$emit('update:model-value', newValue);
        },
        removeRule(rule)
        {
            const newValue = Object.assign({}, this.modelValue);

            newValue.rules = newValue.rules.filter(x => x.id !== rule.id)
            this.$emit('update:model-value', newValue);
        },

        addAction(rule, action)
        {
            const newValue = Object.assign({}, this.modelValue);

            const lRule = newValue.rules.find(x => x.id === rule.id);
            if(action.id === 'action')
                lRule.requireAction = true;
            if(action.id === 'evidence')
                lRule.requireEvidence = true;
            if(action.id === 'question')
            {
                if(lRule.items === null)
                    lRule.items = [];
                lRule.items.push({
                    id: Math.random().toString(36).substring(2, 15),
                    title: '',
                    isSection: false,
                    questionType: 'optionset',
                    optionSetId: this.optionsets.length > 0 ? this.optionsets[0].id : '',
                    isRequired: true,
                    items: null,
                });
            }

            this.$emit('update:model-value', newValue);
        },

        removeRequireAction(rule)
        {
          const newValue = Object.assign({}, this.modelValue);

          const lRule = newValue.rules.find(x => x.id === rule.id);
          lRule.requireAction = false;

            this.$emit('update:model-value', newValue);
        },

        removeRequireEvidence(rule)
        {
          const newValue = Object.assign({}, this.modelValue);

          const lRule = newValue.rules.find(x => x.id === rule.id);
          lRule.requireEvidence = false;

          this.$emit('update:model-value', newValue);
        },

        fireUpdate(item)
        {
          const newValue = Object.assign({}, this.modelValue);
          const index = newValue.rules.findIndex(x => x.id === item.id);
          newValue.rules[index] = item;
          this.$emit('update:model-value', newValue);
        },
        changeAnswers(item) {
          const newValue = Object.assign({}, this.modelValue);
          newValue.answers = item;
          this.$emit('update:model-value', newValue);
        },
        updateMultipleOptions(item) {
          this.$emit('update:model-value', item);
        }
    },
    async mounted() {
        this.checkOptionSet();

        if(this.selected?.id === this.modelValue?.id)
            this.setFocus();
    },
}
</script>
<style scoped>

.textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}

</style>
