<template>
  <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled" v-slot="{focusin, focusout} ">
      <vue-tel-input :id="id" :value="value"
          @focusin="focusin" @focusout="focusout" @input="change" required class="mt-4 h-5"

          autoFormat autoDefaultCountry

          mode="international"
          :inputOptions="{ showDialCode: true}" :disabled="disabled"
          :dropdownOptions="{ showDialCode: true, showDialCodeInList:true, showFlags: true, showDialCodeInSelection: false }">
      </vue-tel-input>
    </app-input-container>
</template>

<script>
export default {
  name: "AppPhonebox",
  components: { },
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Number, String], default: "" },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    error: { type: String, default: "" },
  },
  emits: ["update:modelValue", "keydown"],
  data() {
    return {
      animateLabelText: "",
      showValidationError: false,
      showValidationSuccessful: false,
      validationErrorMsg: "",
    }
  },
  computed: {
    value() {
      if(this.modelValue === null || this.modelValue === '')
        return '';
      if(this.modelValue[0] !== '+')
        return '+' + this.modelValue;
      else
        return this.modelValue;
    },
    getClass() {
      let borderClass = ""
      if (this.disabled)
        borderClass += "bg-white border-light-gray-checkbox placeholder:text-light-gray-checkbox "
      else if (this.hasValidation) {
        if(this.validated)
          borderClass += "border-primary-purple "
        else
          borderClass += "border-[#F17169] "
      }
      else if (this.internalValidations) {
        if(this.showValidationSuccessful)
          borderClass += "border-primary-purple "
        else if (this.showValidationError)
          borderClass += "border-[#F17169] "
      }
      else {
        borderClass += "focus-within:border-primary-purple "
        if (this.modelValue)
          borderClass += "border-primary-purple "
        else
          borderClass += "border-extra-light-gray "
      }

      if(this.container)
        borderClass += "rounded border px-[1px] focus-within:border-2 focus-within:px-[1px]"
      else
        borderClass += "border-b px-[1px] py-[1px] focus-within:border-b-2 focus-within:pt-[1px] focus-within:pb-[1px]"

      return borderClass
    },
    getInputPlaceholder() {
      if(this.animateLabel)
        return ""
      else
        return this.placeholder
    },
    internalValidations() {
      return (this.showValidationError || this.showValidationSuccessful)
    }
  },
  methods: {
    change(value, phone) {
      if(phone !== undefined && phone.valid)
      {
        this.$emit("update:modelValue", value);
        this.$emit("keydown");
      }
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
@import 'vue3-tel-input/dist/vue3-tel-input.css';

.vti__dropdown.open, .vti__dropdown:hover {
    background-color: transparent !important;
}
.vue-tel-input {
  border: white;
  border-bottom: 0px solid #bbb;
  box-shadow: none;
}
input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
}
.check-icon {
  filter: invert(50%) sepia(97%) saturate(450%) hue-rotate(111deg) brightness(93%) contrast(87%);
}
.x-icon {
  filter: invert(51%) sepia(45%) saturate(785%) hue-rotate(314deg) brightness(104%) contrast(89%);
}
input:focus {
  box-shadow: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>



