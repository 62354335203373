<template>
  <div v-if="item.questionType === 'text'">
    <div>
      <span class="font-nhu-regular fs-18 text-gray-900">
        {{ item.title }}
      </span>
    </div>
    <app-textbox :label="$t('inspections.enterYourAnswer')" :modelValue="value"
      @blur="(e) => updateValue(e.target.value)" :disabled="disabled" />
  </div>
  <div v-if="item.questionType === 'int'">
    <div>
      <span class="font-nhu-regular fs-18 text-gray-900">
        {{ item.title }}
      </span>
    </div>
    <app-textbox :label="$t('inspections.enterYourAnswer')" :modelValue="value" type="number"
      @blur="(e) => updateValue(e.target.value)" :disabled="disabled" />
  </div>
  <div v-if="item.questionType === 'decimal'">
    <app-textbox :label="item.title" :modelValue="value" @blur="(e) => updateValue(e.target.value)"
      :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'optionset'">
    <span class="font-nhu-regular fs-14">{{ item.title }}</span>
    <div class="flex flex-col sm:flex-row justify-around my-3">
      <button v-for="option in options" :key="option.id" :class="{ 'bg-gray-300': option.id === value }"
        @click="updateValue(option.id)" :disabled="disabled"
        class="flex-grow p-2 m-2 text-base border border-gray-300 rounded-lg"
        v-bind:style="{ backgroundColor: option.id === value && option.color ? option.color : '' }">
        {{ option.name }}
      </button>
    </div>
  </div>
  <div v-else-if="item.questionType === 'checkbox'">
    <app-checkbox :label="item.title" :modelValue="checkboxValue" @update:modelValue="changeCheckboxValue"
      :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'date'">
    <div>
      <span class="font-nhu-regular fs-18 text-gray-900">
        {{ item.title }}
      </span>
    </div>
    <app-date-picker :label="$t('inspections.enterYourAnswer')" :modelValue="value" @update:modelValue="updateValue"
      :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'media'">
    <span class="font-nhu-regular fs-14 my-3">{{ item.title }}</span>
    <app-image-list :modelValue="imageQuestion" @update:modelValue="updateImageValue" :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'instruction'">
    <span class="font-nhu-regular fs-14">{{ item.title }}</span>
  </div>
  <div v-else-if="item.questionType === 'inspection_number'">
    <app-textbox :label="item.title" v-model="value" @blur="(e) => updateValue(e.target.value)" :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'inspection_date'">
    <app-date-picker :label="item.title" :modelValue="value" @update:modelValue="updateValue" :disabled="disabled" />
  </div>

  <div v-else-if="item.questionType === 'asset'">
    <app-dropdown :label="item.title" :modelValue="value" :options="assets" value="id" @update:modelValue="updateValue"
      :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'inspection_location'">
    <app-textbox :label="item.title" v-model="value" @blur="(e) => updateValue(e.target.value)" :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'person'">
    <app-dropdown :label="item.title" :modelValue="value" getter="GET_ALL_USERS" value="id"
      @update:modelValue="updateValue" :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'site'">
    <app-dropdown :label="item.title" :modelValue="value" :options="sites" value="id" @update:modelValue="updateValue"
      :disabled="disabled" />
  </div>
  <div v-else-if="item.questionType === 'metric'">
    <app-dropdown :label="item.title" :modelValue="value" :options="metrics" value="id" @update:modelValue="updateValue"
      @change="updateMetric" :disabled="disabled" />
    <template v-if="value && !disabled">
      <app-textbox v-model="measure.value" :label="$t('records.metrics.addMeasure')" :disabled="disabled" :enter="true"
        @enter="saveMeasure" :supp-text="$t('pressEnterToSave')" />
      <div v-if="metric.measurements?.length > 0" class="py-2">
        <span class="font-nhu-regular fs-14">
          {{ $t('records.metrics.lastMeasure') }}: {{ metric.measurements[metric.measurements.length - 1].value }}
          {{ $t(`records.metrics.units.${metric.measurementUnit}`) }}
        </span>
      </div>
      <app-file-list v-model="measure.files" :rounded="false" :allow-upload-file="true" :disabled="disabled"
        @update:modelValue="saveMeasure" />
    </template>
    <template v-else>
      <div class="my-2" v-if="metric.measurements?.length > 0">
        <div class="w-[90%] mx-auto">
          <vue-apex-charts type="line" :options="chartOptions" :series="series"></vue-apex-charts>
        </div>
      </div>
    </template>
  </div>
  <div v-else-if="item.questionType === 'multipleOptions'">
    <span class="font-nhu-regular fs-18 text-gray-900">
      {{ item.title }}
    </span>
    <div v-for="(option, index) in answers" :key="index" class="mb-4">
      <div class="flex items-center mt-4 w-full">
        <div class="flex items-center w-full">
          <input :id="option.id" type="checkbox" :checked="value === option.id" :disabled="disabled || saving"
            class="mr-2 border-gray-700 border w-5 h-5"
            :class="item.hasMultipleAnswers ? 'rounded-[4px]' : 'rounded-full'" @change="updateValue(option.id)">

          <label :for="option.id" class="question-input font-nhu-regular fs-14 flex-grow ml-2">
            {{ option.value }}
          </label>
          <!--

            <input v-model="option.value" :disabled="true" class="question-input font-nhu-regular fs-14 flex-grow ml-2" 
                :placeholder="$t('inspections.writeHereYourAnswer')">
-->
          <div v-if="option.rule?.enabled" class="flex justify-end w-full cursor-pointer">
            <span class="font-nhu-regular text-primary-500 fs-14">{{ '<' }}{{ option.rule?.name }}{{ '>' }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AppFileList from "@/components/AppFileList.vue";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'InspectionQuestion',
  components: { VueApexCharts, AppFileList },
  emits: ['updateValue'],
  props: {
    item: { type: Object, default: () => { } },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      value: null,
      saving: false,
      options: [],
      notes: '',
      images: [],
      imageQuestion: [],
      showNotesInput: false,
      showImageInput: false,
      checkboxValue: false,
      measure: {
        value: 0,
        files: [],
      },
      answers: null,
    };
  },
  watch: {
    inspectionId(newId, oldId) {
      if (newId !== oldId) {
        this.load();
      }
    },
  },
  computed: {
    ...mapGetters({
      inspection: 'GET_INSPECTION', template: 'GET_PUBLISH_TEMPLATE', assets: 'GET_ALL_ASSETS',
      users: 'GET_ALL_USERS', sites: 'GET_ALL_SITES', metrics: 'GET_ALL_METRICS', metric: 'GET_METRIC', record: 'GET_RECORD'
    }),
    inspectionId() {
      return this.inspection && this.inspection.id;
    },
    chartOptions() {
      return {
        chart: {
          zoom: {
            foreColor: '#797fee',
            enabled: true
          },
          toolbar: {
            show: true
          },
        },
        colors: ['#797fee'],
        dataLabels: {
          enabled: true,
          distributed: true,
          style: {
            colors: ['#797fee'],
            fontSize: '12px',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
          background: {
            enabled: true,
            foreColor: '#FFFFFF',
            padding: 4,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: '#797fee',
            opacity: 0.8,
          },
        },
        title: {
          text: this.metric.name,
          align: 'center'
        },
        grid: {
          row: {
            colors: ['transparent', '#797fee'],
            opacity: 0.1
          },
        },
        xaxis: {
          name: "Time",
          type: 'datetime',
          orientation: 'vertical',
          sorted: true,
          labels: {
            rotate: 90,
            rotateAlways: true,
            offsetY: 60,
            offsetX: 0,
            minHeight: 100,
            formatter: function (value) {
              return new Date(value).toLocaleDateString(localStorage.getItem('locale') ?? 'es')
            },
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            },
            style: {
              orientation: 'vertical',
              colors: '#797fee',
              fontSize: '12px',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          categories: this.reducedData ?? [],
        }
      }
    },
    series() {
      return [{
        name: this.metric.name,
        data: this.data
      }]
    },
    data() {
      return this.metric.measurements.map((a) => { return { x: a.time.substring(0, 10), y: a.value } }) ?? []
    },
  },
  methods: {
    ...mapActions(['UPDATE_INSPECTION']),
    ...mapMutations(['UPDATE_INSPECTION_DATA']),
    async updateValue(value, images = null) {
      this.saving = true;
      this.value = value;
      if (this.$route.query.templateId) {
        let resultIndex = this.inspection.results.findIndex(x => x.id === this.item.id);
        if (resultIndex === -1) {
          this.inspection.results.push({ id: this.item.id, value: value, notes: this.notes, images: images });
        } else {
          this.inspection.results[resultIndex] = { id: this.item.id, value: value, notes: this.notes, images: images };
        }
        this.SET_VALUE_INSPECTION({ ...this.inspection, value: { id: this.item.id, value: value, notes: this.notes, images: images } });
      } else {
        await this.UPDATE_INSPECTION({ id: this.inspection.id, changes: [{ id: this.item.id, value: value, notes: this.notes, images: images }] })
        await this.load();
      }
      this.saving = false;
    },
    // async saveNotes(notes)
    // {
    //     this.showNotesInput = false;
    //     this.notes = notes;
    //     await this.updateValue(this.value);
    // },
    // async saveImages()
    // {
    //     this.showImageInput = false;
    //     await this.updateValue(this.value);
    // },

    async load() {
      let result = this.inspection.results?.find(x => x.id === this.item.id);
      if (result) {
        this.value = result.value;
        this.notes = result.notes;
        this.images = result.images;
        this.answers = result.answers;
      } else {
        this.value = null;
        this.notes = '';
        this.images = [];
        this.answers = result?.answers ?? this.item.answers;
      }
      if (this.item.questionType === 'checkbox') {
        this.checkboxValue = this.stringToBoolean(this.value);
      }
      if (this.item.questionType === 'media') {
        if (this.value === null)
          this.imageQuestion = [];
        else
          this.imageQuestion = [this.value];
      }
      if (this.item.questionType === 'metric') {
        let metric = this.metrics.find(x => x.id === this.value);
        if (metric) {
          await this.$store.dispatch('READ_METRIC', { id: metric.id })
        } else {
          await this.$store.dispatch('CREATE_METRIC')
        }
      }
      this.$emit('updateValue', result)
    },
    changeCheckboxValue(value) {
      this.checkboxValue = value;
      this.updateValue(value);
    },
    stringToBoolean(string) {
      return string === "True";
    },
    updateImageValue(value) {
      this.imageQuestion = value;
      if (Array.isArray(value)) {
        this.updateValue(value[0]);
      }
    },
    async updateMetric() {
      let metric = this.metrics.find(x => x.id === this.value);
      if (metric) {
        await this.$store.dispatch('READ_METRIC', { id: metric.id })
      }
    },
    saveMeasure() {
      this.$store.dispatch('SAVE_METRIC_MEASURE', { id: this.metric.id, value: this.measure.value, files: this.measure.files })
    }
  },
  async created() {
    this.options = this.template?.optionSets?.find(x => x.id === this.item.optionSetId)?.options;
    await this.load();
  }
};
</script>
