<template>
  <div class="grid w-full grid-cols-4 gap-2">
    <app-dropdown :modelValue="field" @update:modelValue="changeField" :options="fields" value="value" valueCaption="text"  />
    <app-dropdown :modelValue="operator" @update:modelValue="changeOperator" :options="operators" value="value" valueCaption="text" />
    <div class="flex col-span-2">
      <app-textbox :modelValue="value" @change="changeValue"  />
    </div>
  </div>
</template>

<script>

export default {
  name: "QueryBuilderRule",
  components: {  },
  data() {
    return {
      operators: [ 
        { value: '$in', text: 'en'},
        { value: '$nin', text: 'no en'},
        { value: '$eq', text: 'Igual'},
        { value: '$ne', text: 'No Igual'},
        { value: 'between', text: 'entre'},
        { value: 'not_between', text: 'no entre'},
        { value: '$lt', text: 'menor'},
        { value: '$lte', text: 'menor o igual'},
        { value: '$gt', text: 'mayor'},
        { value: '$gte', text: 'mayor o igual'},

        { value: 'begins_with', text: 'entre' },
        { value: 'not_begins_with', text: 'not entre'},
        { value: 'contains', text: 'Contiene'},
        { value: 'not_contains', text: 'No contiene'},
        { value: 'ends_with', text: 'Termina con'},
        { value: 'not_ends_with', text: 'No termina con'},
        { value: 'is_empty', text: 'Es vacio'},
        { value: 'is_not_empty', text: 'No es vacio'},
        { value: 'is_null', text: 'Es nulo'},
        { value: 'is_not_null', text: 'No es nulo'},
      ]
    }
  },
  emits: ['update'],
  props: {
    fields: { type: Array, default() { return []; } },
    rule: { type: Object, default() { return null; } },
  },
  methods: {
    changeField(newField)
    {
      var expr = {}, ope = {};
      ope[this.operator] = this.value;
      expr[newField] = ope;
      this.$emit('update', expr)
    },
    changeOperator(newOpe)
    {
      var expr = {}, ope = {};
      ope[newOpe] = this.value;
      expr[this.field] = ope;
      this.$emit('update', expr)
    },
    changeValue(e)
    {
      var expr = {}, ope = {};
      ope[this.operator] = e.target.value;
      expr[this.field] = ope;
      this.$emit('update', expr)
    },
  },
computed: {
    field()
    {
      const keys = Object.keys(this.rule);
      return keys[0];
    },
    operator()
    {
      const expr = this.rule[this.field];
      const keys = Object.keys(expr);
      return keys[0];
    },
    value()
    {
      const expr = this.rule[this.field];
      const keys = Object.keys(expr);
      return expr[keys[0]];
    }
  },
  async created() {

  },
};
</script>
