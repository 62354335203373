<template>
  <div :class="containerClasses" class="relative">
    <div v-if="isPassword" class="w-8 cursor-pointer z-2 flex justify-end absolute right-2 top-[40%]">
        <app-icon v-if="localType === 'password'" extra-class="mb-1 fill-gray-500" icon="eye_slash_regular" :width="20"
                  :height="20" @click="localType = 'text'"/>
        <app-icon v-if="localType === 'text'" extra-class="mb-1 fill-primary-500" icon="eye_regular" :width="20"
                  :height="20" @click="localType = 'password'"/>
    </div>
    <label ref="label" :for="id" :class="labelClasses" @click="labelClick" class="flex justify-between">
      {{ label }}
    </label>
    <div :class="inputContainerClasses" class="flex">
      <slot :focusin="focusin" :focusout="focusout" :changeType="localType"></slot>
    </div>
  </div>
  <div class=""> {{ error }}</div>
  <div v-if="errors && errors[id]" class="flex items-center mt-2">
    <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-red-500"/>
    <span class="relative block text-xs font-nhu-regular text-red-500 cursor-text">{{ errors[id].text }}</span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

export default {
  name: "AppInputContainer",
  components: { InformationCircleIcon },
  props: {
    id: {type: String, default: null},
    modelValue: {type: [Number, String, Array, Date], default: null},
    label: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    expand: {type: Boolean, default: false},
    invalid: {type: Boolean, default: false},
    error: {type: String, default: ""},
    errors: {type: Object, default: null},
    type: {type: String, default: "text"},
    isPassword: {type: Boolean, default: false},
    hasMultipleOptions: {type: Boolean, default: false},
  },
  emits: ["update:modelValue", 'keydown', 'labelClick'],
  data() {
    return {
      active: false,
      localType: this.type,
    }
  },
  computed: {
    hasValue() {
      return !!this.modelValue?.toString()?.trim();
    },
    containerClasses() {
      return [
        "rounded",
        "mt-4",
        "px-3",
        "py-1",
        "shadow-sm",
        "ring-1",
        "ring-inset",
        "ring-gray-200",
        "focus-within:ring-2",
        "focus-within:ring-gray-900",
        this.expand && !this.hasMultipleOptions ? "min-h-[76px]" : this.hasMultipleOptions ? "min-h-[44px]" : "h-[44px]",
        (this.invalid && !this.hasValue || (this.errors && this.errors[this.id])) ? "ring-red-500 bg-red-50 border-red-500" : "border-gray-200",
        this.invalid && this.hasValue ? "bg-red-100" : "",
        this.disabled ? "bg-gray-50" : "",
      ];
    },
    labelClasses() {
      return [
        "block",
        "font-medium",
        "font-nhu-regular",
        "text-gray-300",
        "cursor-text",
        this.label ? "" : "pt-2",
        ((this.active || this.hasValue)) ? "text-xs " : "fs-14 pt-2",
        (this.active || this.hasValue) && this.expand ? "pb-[10px]" : "",
      ];
    },
    inputContainerClasses() {
      return [
        this.isPassword ? "w-[calc(100%-20px)]": '',
        this.expand ? "z-1" : "relative z-1",
        this.active || this.hasValue ? "-top-4" : "-top-8",
      ];
    },
  },
  methods: {
    changeInputType(type) {
      this.$emit('update:type', type);
    },
    labelClick() {
      this.$emit('labelClick');
    },
    focusin() {
      this.active = true;
    },
    focusout() {
      this.active = false;
    },
  },
};
</script>

<style>
label {
  transition: all 0.3s ease;
}
</style>
