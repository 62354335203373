<template>
  <app-crud ref="crud" v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name"
      :label="$getRecordLabel('assets') ? $getRecordLabel('assets') : $t('assets.name')"
      :error="errorMsg"
      @update:model-value="clearErrorMsg" />
    <app-textarea v-model="item.description" :label="$t('assets.description')" />
    <app-dropdown v-model="item.siteId" value="id"
      :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('assets.site')" getter="GET_ALL_SITES"
      :allow-nulls="true" :has-child="true"/>
    <app-dropdown v-if="$hasModule('parts')" v-model="item.parts" value="id"
                  :multiple="true"
                  :label="$getRecordLabel('parts') ? $getRecordLabel('parts') : $t('records.' + record.name + '.fields.parts')"
                  getter="GET_ALL_PARTS"
                  :allow-nulls="true"></app-dropdown>
    <app-textbox v-model="item.brand" :label="$t('assets.brand')" />
    <app-textbox v-model="item.model" :label="$t('assets.model')" />
    <app-textbox v-model="item.serialNumber" :label="$t('assets.serialNumber')" />

    <app-date-picker v-model="item.acquirementDate" :label="$t('assets.acquirementDate')"
      :suppText="$t('assets.acquirementDate')" />
    <app-crud-properties v-model="item.props" />

    <app-image-list v-model="item.media" :rounded="false" />
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "AssetPage",
  data() {
    return {
      errorMsg: '',
      validated: false
    }
  },
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY', record: 'GET_RECORD', }),
  },
  methods: {
    onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.errorMsg = this.$t('records.assets.errors.nameRequired');
        onError();
      }
      else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
