<template>
    <div class="absolute top-0 z-10 flex h-full left-4">
      <button @click="click" className="my-auto relative text-purple text-center transition-all duration-300 ease-out border
      select-none active:transition-none active:shadow-none hover:bg-light-red active:bg-gray-100 shadow-btn rounded-full
      border-transparent bg-white p-2 active:border-transparent">
            <ArrowLeftIcon class="back-button-icon" />
      </button>
    </div>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

export default {
    name: "AppBackButton",
    emits: ["click"],
    components: { ArrowLeftIcon },
    props: {
        url: { type: String, default: null }
    },
    methods: {
        click() {
            if (this.url === null)
                this.$router.go(-1);
            else
                this.$router.push(this.url);
        }
    },
};
</script>
<style>
.back-button-icon {
  filter: invert(83%) sepia(9%) saturate(303%) hue-rotate(274deg) brightness(90%) contrast(104%);
  width: 24px;
}
</style>
