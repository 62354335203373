<template>
  <tr>
    <td v-for="field in columnFields" :key="field.id" class="whitespace-nowrap h-[52px] px-4 bg-white">
      <app-crud-field :item="item" :field="field" :key="field.id" :view="view" :record="record" @click="click" @update-action="updateAction"/>
    </td>
  </tr>
</template>

<script>
import AppCrudField from '@/crud/AppCrudField'

export default {
  name: "AppCrudRow",
  components: { AppCrudField },
  emits: ['click', 'updateAction'],
  props: {
    item: { type: Object, default() { return {} } },
    columnFields: { type: Array, default() { return [] } },
    view: { type: String, default() { return "" } },
    record: { type: Object, default() { return {} } },
  },
  methods: {
    click() {
      this.$emit('click')
    },
    updateAction(func, item) {

      this.$emit('updateAction', func, item)
    },
  }
};
</script>

<style scoped>
tr td:first-child > * {
  margin-left: 1rem;
}
</style>
