<template>
  <div class="h-full" v-if="record !== null">
    <div class="flex pt-[10px]">
      <h1 v-if="!isMobile" class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="hidden sm:block">{{ getRecordLabel }}</span>
        <span class="block sm:hidden">{{ $t('records.' + record.name + '.shortTitle') }}</span>
      </h1>
      <div v-else class="h-16 flex items-center align-middle text-purple bg-white">
        <img class="h-10 ml-10 mt-2" src="/images/zigo.png" alt="zigo"/>
      </div>
      <div class="flex-1"></div>
      <div v-if="!isMobile && record.views.dual !== undefined && minTwoViewsDefined"
           class="cursor-pointer p-2 rounded-full h-11 w-11 items-center justify-center flex hidden"
           :class="view === 'dual' ? 'bg-primary-25 fill-primary-500' : 'fill-gray-100'" @click="changeView('dual')">
        <app-icon icon="table_columns_regular" :width="24" :height="24"></app-icon>
      </div>
      <div v-if="!isMobile && record.views.list !== undefined && minTwoViewsDefined"
           class="cursor-pointer p-2 rounded-full h-11 w-11 items-center justify-center flex"
           :class="view === 'list' ? 'bg-primary-25 fill-primary-500' : 'fill-gray-100'" @click="changeView('list')">
        <app-icon icon="table_columns_regular" :width="24" :height="24"></app-icon>
      </div>
      <div v-if="!isMobile && record.views.kanban !== undefined && minTwoViewsDefined"
           class="cursor-pointer p-2 rounded-full h-11 w-11 items-center justify-center flex"
           :class="view === 'kanban' ? 'bg-primary-25 fill-primary-500' : 'fill-gray-100'" @click="changeView('kanban')">
        <app-icon icon="table_layout_regular" :width="24" :height="24"></app-icon>
      </div>
      <div v-if="!isMobile && record.views.calendar !== undefined && minTwoViewsDefined"
           class="cursor-pointer p-2 rounded-full h-11 w-11 items-center justify-center flex"
           :class="view === 'calendar' ? 'bg-primary-25 fill-primary-500' : 'fill-gray-100'" @click="changeView('calendar')">
        <app-icon icon="calendar_regular" :width="24" :height="24"></app-icon>
      </div>
      <div class="pl-4 relative hidden lg:block" v-if="!record.custom">
        <MagnifyingGlassIcon class="absolute top-1/2 -translate-y-1/2 pl-2 h-[18px] text-gray-100"/>
        <input class="border rounded-md h-full w-80 pl-8 border-gray-100 font-nhu-regular placeholder-gray-100"
               :placeholder="$t('search')" @input="searchData" v-model="searchText">
      </div>
      <div v-else class="flex align-center">
        <div class="w-40 flex align-center">
          <div v-if="dashboard_editable" class="flex align-bottom" @click="saveDashboard">
            <app-icon icon="floppy_disk_regular" :width="16" :height="16" extra-class="hover:fill-primary-500 hover:text-primary-500 cursor-pointer flex justify-center items-center">
              <span class="block pl-1">{{ $t('save') }}</span>
            </app-icon>
          </div>
          <div v-else class="flex align-bottom hidden" @click="editDashboard">
            <app-icon icon="pencil_regular" :width="16" :height="16" extra-class="hover:fill-primary-500 hover:text-primary-500 cursor-pointer flex justify-center items-center">
              <span class="block pl-1">{{ $t('edit') }}</span>
            </app-icon>
          </div>
        </div>
      </div>
      <template v-if="!isMobile && !record.custom">
        <div class="flex">
          <app-button class="ml-4 px-4 font-nhu-regular mr-8 sm:mr-0" @click="doCreate" type="primary" background="light"
                      v-if="$hasPermission('create' + '_' + record.name)"
                      :icon="PlusCircleIcon">
            <!-- <span class="hidden sm:block">{{ $t('records.' + record.name + '.createTitle') }}</span> -->
            <span class="block">{{ $t('new') }}</span>
          </app-button>
          <Menu as="div" class="relative inline-block text-left pl-4 pr-4 pt-3 w-2">
            <MenuButton class="flex items-center"
                        :class="!isMobile && view === 'list' || view === 'calendar' || view === 'kanban' ? 'mr-10' : ''">
              <app-icon icon="ellipsis_vertical_regular" :width="20" :height="20" :fill="getColor"/>
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                  class="absolute right-0 z-10 mt-4 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-auto whitespace-nowrap min-w-[150px]">
                <div class="py-1">
                  <MenuItem
                      v-for="action in record.views.global.actions"
                      :key="action.function"
                      class="cursor-pointer"
                      :disabled="!isActionAllowed(action)"
                      @click="isActionAllowed(action) ? callToFunction(action) : null">
                    <div :class="[
                          'text-gray-700',
                          'flex px-4 py-2 text-sm items-center ' + 'fill-' + action.color + '-500',
                          action.text === $t('delete') ? 'border-t border-red-500 text-red-500' : '',
                          isActionAllowed(action) ? '' : 'opacity-50 !cursor-not-allowed',
                        ]">
                      <app-icon :icon="action.icon" width="14" height="14"/>
                      <span class="font-nhu-regular pl-[2px]">
                        {{ $t('records.' + record.name + '.actions.global.' + action.function) }}
                      </span>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </template>
      <template v-else>
        <div v-if="action === 'list' && $hasPermission('create' + '_' + record.name)" class="px-4 fixed bottom-8 w-full z-40">
          <app-button class="font-nhu-regular w-full h-[44px]"
                      extra-class="rounded-[8px]"
                      @click="doCreate"
                      type="primary"
                      background="light"
                      :icon="PlusCircleIcon">

            <span>{{ $t('records.' + record.name + '.createTitle') }}</span>
          </app-button>
        </div>
      </template>
    </div>
    <div v-if="!isMobile" class="h-0 border-b border-gray-100 my-4"/>

    <div v-if="view !== 'calendar' && view !== 'dashboard'" class="mb-2 flex flex-col sm:flex-row justify-between">
      <app-crud-filters @updateData="loadData"></app-crud-filters>
      <div v-if="record.filterable" class="flex">
        <div class="flex">
          <div v-if="hasActiveFilters" class="flex items-center mx-4 cursor-pointer text-nowrap" @click="filterDialog = true">
            <app-icon icon="floppy_disk_regular" class="fill-primary-500" :width="15" :height="15">
              <span class="fs-14 font-nhu-500 ml-1">{{ $t('crud.saveFilters') }}</span>
            </app-icon>
          </div>
          <div class="flex items-center mx-4 cursor-pointer border-[1px] border-gray-100 px-1 rounded">
            <app-filter-menu @reload-data="loadData"/>
          </div>
          <div v-if="hasActiveFilters" class="flex items-center mx-4 cursor-pointer text-nowrap" @click="resetFilters">
            <app-icon icon="xmark_regular" class="fill-primary-500 mr-1" :width="15" :height="15">
              <span class="fs-14 font-nhu-500">{{ $t('crud.cleanFilters') }}</span>
            </app-icon>
          </div>
        </div>
        <!--    Se oculta el centro de descargas por ahora
        <div class="rounded-md border-[1px] border-primary-500 p-1 bg-primary-50 font-nhu-500 fs-14 cursor-pointer mx-1">
          <app-icon icon="download_regular" :width="18" :height="18" extra-class="fill-primary-500 text-primary-800"
                    @click="openDownloadCenterPopUp">
            <span class="ml-1 hidden sm:block">{{ $t("records.workorders.actions.download_center") }}</span>
          </app-icon>
        </div>-->
        <div class="cursor-pointer hidden sm:flex">
          <div @click="showColumnOptions">
            <app-icon icon="gear_solid" :width="15" :height="15" class="my-1 hover:fill-primary-300"
                      :fill="this.$colors.primary[500]"/>
          </div>
        </div>
      </div>
      <TransitionRoot as="template" :show="show_download_center">
        <Dialog as="div" class="relative z-10">
          <div class="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-50">
            <div class="relative flex items-center justify-center h-full text-center min-h-fit"
                 @mousedown.self="show_download_center = false">
              <TransitionChild as="template" enter="ease-out duration-300"
                               enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                               enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                               leave-from="opacity-100 translate-y-0 sm:scale-100"
                               leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel
                    class="relative text-left overflow-hidden border transition-all transform bg-white shadow-xl w-full lg:w-1/2 rounded-[8px]"
                    :style="isFullHeight() && 'height: 100%; max-height: 80%'">
                  <div @click="show_download_center = false" class="absolute cursor-pointer right-2 top-2 rounded-full">
                    <app-icon :width="18" :height="24" icon="xmark_regular"/>
                  </div>
                  <div class="flex justify-center text-primary-500 py-2 font-nhu-semi-bold fs-18">
                    {{ $t("records.workorders.actions.download_center") }}
                  </div>
                  <div class="mt-8 px-4">
                    <ul role="list" class="flex flex-col overflow-auto gap-x-2 gap-y-1 xl:gap-x-4 w-full h-full">
                      <li v-for="file in exportedDocuments" :key="file">
                        <div
                            class="flex overflow-hidden group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                          <app-icon :icon="file?.key?.includes('.pdf') ? 'print_regular' : 'file_excel_regular'"
                                    extra-class="fill-primary-500 mr-1" :width="18" :height="18"></app-icon>
                          <app-file :file="file" alt="" :editView="false"/>
                        </div>
                      </li>
                    </ul>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

    </div>

    <div v-if="isMobile && view !== 'dashboard'" id="displayCtrl" class="flex pt-4">
      <template v-if="!this.data.data?.length && !loading">
        <app-empty-state @create="doCreate" />
      </template>
      <template v-else>
        <div class="w-full min-w-[250px] rounded h-full flex flex-col bg-gray-25">
          <div class="w-full flex cursor-pointer bg-white" v-if="record.views?.dual?.options">
            <div v-for="option in record.views.dual.options" :key="option.text"
                 class="flex items-center justify-center py-3 px-4 fs-14 text-gray-500"
                 :class="[records.views?.dual?.options?.length > 0 ? 'w-' + [100 / (records.views.dual.options.length) + '%'] : 'w-full', currentOption === option ? 'text-primary-500  border-b-[3px] border-primary-500 font-nhu-semi-bold' : '  border-b-[1px] border-gray-100 font-nhu-regular']"
                 @click="setCurrentOption(option)">
              <div class="flex align-center">
                <span class="fs-14 ml-1">{{ $t('records.' + record.name + '.options.' + option.text) }} </span>
                <div v-if="total > 0 && currentOption === option && !loading"
                     class="rounded-full bg-primary-50 w-[20px] h-[20px] flex items-center justify-center ml-1">
                  <div class="fs-12 font-nhu-regular">{{ total }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-end items-center pt-4" v-if="record.views?.dual?.sortBy">
            <div class="fs-14 font-nhu-regular ">{{ $t('records.sortBy') }}:</div>
            <app-menu @close-dialog="open = false" :open="open" v-if="serverOptions" :record="record" :records="records"
                      :model-value="serverOptions" :menu-items="getSortOptionsItems" @set-order="setOrder"
                      :menuRight="true">
            </app-menu>
          </div>
          <div class="overflow-y-auto px-4 pt-4" ref="scrollContainer" @scroll="loadMoreData()">
            <ul :class="isMobile ? 'pb-[70px]' : ''">
              <li @click="doView(item)" v-for="item in getData()" :key="item.id" :class="[
              isSelected(item) && validateDate(item.dueDate) === '' ? 'border-l-4 border-l-primary-500 bg-primary-50' : '',
              isSelected(item) && validateDate(item.dueDate) !== '' ? 'border-l-4 border-l-red-500 bg-red-50' : '',
              !isSelected(item) ? 'hover:bg-gray-50' : '', 'my-2']">
                <div class="flex items-center pt-6 px-6 border border-b-0 rounded-tl-[8px] rounded-tr-[8px] bg-white">
                  <app-icon v-if="validateDate(item.dueDate) !== ''" extra-class="mr-2" icon="timer_regular" :width="15"
                            :height="15" :fill="this.$colors.red[500]"/>
                  <span class="font-nhu-regular fs-12 text-red-800">{{ validateDate(item.dueDate) }}</span>
                </div>
                <div
                    class="flex items-center flex-wrap w-full h-auto min-h-[100px] pb-6 px-6 cursor-pointer border border-t-0 border-gray-100 rounded-bl-[8px] rounded-br-[8px] bg-white">
                  <div v-for="field in columnFields" :key="field.id" class="mb-1"
                       :class="record.views.dual.classes ? record.views.dual.classes[field.id] : 'w-1/2'">
                    <app-crud-field :item="item" :field="field" :view="view" :record="record"/>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!isMobile && view === 'dual'" id="displayCtrl" class="flex">
      <div class="w-1/3 min-w-[250px] mr-4 border border-gray-100 rounded-[8px] h-full flex flex-col">
        <div class="rounded-t-[0.25rem] border-b-[1px]">
          <div class="w-full flex cursor-pointer" v-if="record.views?.dual?.options">
            <div v-for="option in record.views.dual.options" :key="option.text"
                 class="flex items-center justify-center py-3 px-4 fs-14 text-gray-500"
                 :class="[records.views?.dual?.options?.length > 0 ? 'w-' + [100 / (records.views.dual.options.length) + '%'] : 'w-full', currentOption === option ? 'text-primary-500  border-b-[3px] border-primary-500 font-nhu-semi-bold font-nhu-500' : '  border-b-[1px] border-gray-500 ']"
                 @click="setCurrentOption(option)">
              <div class="flex align-center">
                <span class="fs-14 ml-1">{{ $t('records.' + record.name + '.options.' + option.text) }} </span>
                <div v-if="total > 0 && currentOption === option && !loading"
                     class="rounded-full bg-primary-50 w-[20px] h-[20px] flex items-center justify-center ml-1">
                  <div class="fs-12 font-nhu-regular">{{ total }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-end items-center fs-14 font-nhu-regular" v-if="record.views?.dual?.sortBy">
            {{ $t('records.sortBy') }}:
            <app-menu @close-dialog="open = false" :open="open" v-if="serverOptions" :record="record"
                      :records="records" :model-value="serverOptions" :menu-items="getSortOptionsItems"
                      @set-order="setOrder">
            </app-menu>
          </div>
        </div>

        <div class="overflow-y-auto" ref="scrollContainer" @scroll="loadMoreData()">
          <ul>
            <li @click="doView(item)" v-for="item in getData()" :key="item.id" :class="[
              'border-b',
              isSelected(item) && validateDate(item.dueDate) === '' ? 'border-l-4 border-l-primary-500 bg-primary-50' : '',
              isSelected(item) && validateDate(item.dueDate) !== '' ? 'border-l-4 border-l-red-500 bg-red-50' : '',
              !isSelected(item) ? 'hover:bg-[#f7f8f8] pl-[4px]' : '']">
              <div class="flex items-center pt-6 px-6">
                <app-icon v-if="validateDate(item.dueDate) !== ''" extra-class="mr-2" icon="timer_regular" :width="15"
                          :height="15" :fill="this.$colors.red[500]"/>
                <span class="font-nhu-regular fs-12 text-red-800">{{ validateDate(item.dueDate) }}</span>
              </div>
              <div class="flex items-center flex-wrap w-full h-auto min-h-[60px] pb-6 px-6 cursor-pointer">
                <div v-for="field in columnFields" :key="field.id"
                     :class="record.views.dual.classes ? record.views.dual.classes[field.id] : 'w-1/2'">
                  <app-crud-field :item="item" :field="field" :view="view" :record="record"/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-2/3 border h-full border-gray-100 rounded-[8px]">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
    <div v-if="!isMobile && view === 'list'" id="displayCtrl">
      <div class="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg h-full"
           :class="this.tableColumns?.length > 1 && !columnOptions ? 'table-container' : 'table-container-one-column'"
           id="tableContainer">
        <template v-if="!this.data.data?.length && !loading">
          <app-empty-state @create="doCreate" :isNotEmpty="recordHasFilters" />
        </template>
        <template v-else>
          <table class="min-w-full divide-y divide-gray-100" id="table" v-if="this.data.data?.length">
            <thead class="bg-gray-50">
            <tr>
              <th v-for="col in columnFields" :key="col?.id" scope="col" class="px-4 py-4 text-left bg-gray-50"
                  :class="{ 'w-64': col?.id === 'name'}">
                <div class="flex items-center">
                  <span class="font-nhu-regular fs-14 text-gray-900 font-normal">{{ $t(col?.name) }}</span>
                  <div class="h-7 w-7 cursor-pointer ml-2 flex justify-center items-center" @click="sortData(col)"
                       v-if="col?.sortable">
                    <app-icon v-if="col?.id === sortOptions.sortBy" :icon="getIcon" :width="14" :height="14"></app-icon>
                    <app-icon v-else icon="sort_regular" :width="14" :height="14"></app-icon>
                  </div>
                </div>
              </th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-100 bg-white">
            <app-crud-row v-for="(item, index) in getData()" :key="item.id" :item="item" :columnFields="columnFields"
                          :view="view"
                          :record="record" @click="doView(item)" @update-action="updateAction" :id="'row'+index"/>
            <tr :style="`height: calc(100%);`"/>
            </tbody>
          </table>
        </template>
      </div>
      <div class="table-pagination">
        <app-pagination v-model:serverOptions="serverOptions" :data="data" @update="updateServerOptions"/>
      </div>
    </div>
    <div v-if="!isMobile && view === 'calendar'" id="displayCtrl" class="">
      <app-crud-calendar @viewWO="doView"/>
    </div>
    <div v-if="!isMobile && view === 'kanban'" id="displayCtrl" class="">
      <app-crud-kanban-board/>
    </div>
    <div v-if="view === 'dashboard'" id="displayCtrl" class="">
      <app-crud-dashboard />
    </div>

    <!-- Dialog -->
    <Dialog as="div" class="relative z-10" :open="showPopup" v-if="isMobile || view !== 'dual'" :key="`dialog-${reloadPopUp}`">

      <div class="fixed inset-0 z-10 bg-black bg-opacity-50">           
        <div class="relative flex items-center justify-center h-full w-full text-center min-h-fit" @mousedown.self="closePopup">

          <DialogPanel
              class="relative text-left overflow-hidden bg-white shadow-xl rounded-[8px] sm:border sm:rounded-b-[8px]"
              :style="!isMobile ? 'height: calc(100vh - 3rem);' : 'height: calc(100vh - 10rem);'"
              :class="{
              'h-[80%] max-h-[80%]': !isMobile && isFullHeight(),
              'w-full 2xl:w-1/2 xl:w-2/3 md:w-2/3 mx-4': !$route.meta.isFullScreen,
              'w-full sm:mx-16': $route.meta.isFullScreen,
            }">
            <div @click="() => closePopup(true)" class=" justify-between absolute cursor-pointer right-[18px] rounded-full z-50 top-[18px] sm:top-[36px]">
              <app-icon :width="18" :height="24" icon="xmark_regular"/>
            </div>
              <router-view :key="$route.fullPath"/>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
    <!-- For save filters -->
    <app-filter-dialog :open="filterDialog" @close="filterDialog = false" @save="saveFilter"/>
    <!-- For import dialog -->
    <app-import-dialog :open="importDialog" @close="importDialog = false" @import="importData"/>
    <!-- For action confirmations -->
    <app-confirm-dialog ref="confirm"/>

    <app-crud-columns-options v-model="columnOptions"
                              :record="record"
                              @change-column="updateColumns"
                              @close="columnOptions = false"/>

  </div>
</template>

<script>
import CRUD from '@/crud'
import emitter from 'tiny-emitter/instance'
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from "@headlessui/vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {PlusCircleIcon, MagnifyingGlassIcon} from '@heroicons/vue/24/outline'
import BarsFilterIcon from '@/assets/icons/BarsFilterIcon.vue'
import {markRaw} from 'vue';
import AppCrudRow from '@/crud/AppCrudRow'
import AppCrudField from '@/crud/AppCrudField'
import AppCrudFilters from "@/crud/AppCrudFilters";
import AppCrudCalendar from './AppCrudCalendar.vue'
import AppFile from "@/components/AppFile";
import AppMenu from "@/components/AppMenu";
import AppCrudKanbanBoard from "@/crud/AppCrudKanbanBoard";
import AppImportDialog from "@/components/AppImportDialog";
import AppButton from "@/components/AppButton.vue";
import AppEmptyState from "./AppEmptyState.vue";
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppCrudList",
  components: {
    AppIcon, AppEmptyState, AppButton, Menu, MenuItem, MenuItems, MenuButton,
    AppImportDialog, AppCrudKanbanBoard, AppMenu, AppFile, AppCrudFilters, AppCrudRow, AppCrudField,
    AppCrudCalendar, Dialog, DialogPanel, TransitionChild, TransitionRoot, MagnifyingGlassIcon
  },
  emits: ['click', 'exportToXLSX'],
  props: {
    title: {type: String, default: ''},
    url: {type: String, default: null},
  },
  data() {
    return {
      BarsFilterIcon: markRaw(BarsFilterIcon), PlusCircleIcon,
      clientHeight: document.documentElement.clientHeight,
      clientWidth: document.documentElement.clientWidth,
      searchText: '',
      item: {},
      defaultSortBy: '',
      open: false,
      sortOptions: {sortBy: '', sortType: null, multiplier: 0,},
      serverOptions: {page: 1, rowsPerPage: 10, sortBy: 'creationTime', sortType: "asc",},
      prevRecordId: null,
      data: [],
      show_download_center: false,
      lastLoadedIndex: 0,
      timerId: null,
      currentOption: null,
      importDialog: false,
      filterDialog: false,
      recordLabel: '',
      columnOptions: false,
      reloadPopUp: false,
    }
  },
  computed: {
    ...mapGetters({ records: 'GET_RECORDS', record: 'GET_RECORD', view: 'GET_VIEW', exportedDocuments: 'GET_EXPORTED_DOCUMENTS', dashboard_editable: 'GET_DASHBOARD_EDITABLE',
      filters: 'GET_FILTERS', workOrder: 'GET_WORKORDER', total: 'GET_TOTAL_WORKORDERS',
      company: 'GET_CURRENT_COMPANY', user: 'GET_ACCOUNT', loading: 'GET_LOADING', tableColumns: 'GET_COLUMNS', dashboard: 'GET_DASHBOARD',
    }),
    action() {
      return this.$route.meta.action;
    },
    calculateHeight() {
      if (this.serverOptions.rowsPerPage > this.getData()?.length) {
        const tableHeight = document.getElementById('table')?.clientHeight ?? 0
        const displayCtrlHeight = document.getElementById('displayCtrl')?.clientHeight ?? 0
        return (displayCtrlHeight - tableHeight)
      }
      return 0
    },
    getSortOptionsItems() {
      let sortByOptions = this.records[this.record.name]?.views?.dual?.sortBy
      if (sortByOptions)
        this.checkDefaultSort(sortByOptions)
      return sortByOptions ?? []
    },
    isMobile() {
      return this.$isMobile()
    },
    getIcon() {
      switch (this.sortOptions.sortType) {
        case 'asc':
          return 'sort_up_solid'
        case 'desc':
          return 'sort_down_solid'
        default:
          return 'sort_regular'
      }
    },
    getHeight() {
      const t = document.getElementById('displayCtrl');
      const top = t?.offsetTop ?? 75;

      if (this.clientHeight > 300)
        return (this.clientHeight - top - 25 + (this.clientWidth * 0)) + 'px';
      else
        return '200px';
    },
    /* Las columnas que debo mostrar en la vista seleccionada */
    columnFields() {
      const viewCols = this.tableColumns?.length > 0 ? this.tableColumns : this.record.views[this.view].columns;
      const fields = this.record.fields.filter(f => viewCols.includes(f.id));
      return viewCols.map(col => fields.find(f => f.id === col)).filter(x => x);
    },
    showPopup() {
      if (!this.reloadPopUp) {
        return false
      }
      const routeId = this.$route.params.id;

      const isCreateRouteWithListView = this.action === 'create' && (this.view !== 'dual' || this.isMobile);
      if (routeId !== undefined || isCreateRouteWithListView) {
        return true;
      }
      return false;
    },
    hasActiveFilters() {
      const data = this.filters;
      for (let key in data) {
        const val = data[key];
        if (val !== null && typeof val === 'object' && Object.keys(val).length > 0) {
          return true;
        }
      }
      return false;
    },
    recordHasFilters() {
      if(this.filters[this.record.name])
        return Object.values(this.filters[this.record.name]).some(x => x?.values?.length > 0)
      else
        return false;
    },
    hasData() {
      return (this.$store.getters[this.record.getter].data?.length > 0)
    },
    minTwoViewsDefined() {
      let count = 0;
      //if (this.record.views.dual !== undefined) count++;
      if (this.record.views.list !== undefined) count++;
      if (this.record.views.calendar !== undefined) count++;
      if (this.record.views.kanban !== undefined) count++;

      return count >= 2;
    },
    getRecordLabel() {
      //this.company = await this.$store.dispatch('READ_CURRENT_COMPANY')
      if (!this.company || !this.company.recordLabels || !(this.record.name in this.company?.recordLabels)) {
        return this.$t(`records.${this.record.name}.title`)
      }

      if (!this.company.recordLabels[this.record.name].label && !this.company.recordLabels[this.record.name].pluralLabel) {
        return this.$t(`records.${this.record.name}.title`)
      }

      if (this.company.recordLabels[this.record.name].plural !== null) {
        return this.company.recordLabels[this.record.name].pluralLabel
      } else {
        return this.$t(`records.${this.record.name}.title`)
      }
    },
  },
  watch: {
    serverOptions: {
      deep: true,
      handler() {
        this.loadData()
      }
    },
    view(view) {
      if (view === 'dual' || view === 'list') {
        this.serverOptions.page = 1
      }
      this.loadData()
      this.$nextTick(() => {
        this.resize();
      });

    }
  },
  methods: {
    ...mapActions(['LOAD_RECORD', 'READ_VIEW', 'READ_FILTERS', 'READ_EXPORTED_DOCUMENTS', 'RESET_ALL_FILTERS', 'SAVE_COLUMNS', 'READ_COLUMNS']),
    ...mapMutations(['SET_VIEW', 'SET_FILTERS', 'SET_IMPORT_VISIBLE', 'SET_LOADING']),
    editDashboard() {
      this.$store.commit('SET_DASHBOARD_EDITABLE', true)
    },
    async saveDashboard() {
      this.$store.commit('SET_DASHBOARD_EDITABLE', false)
      await this.$store.dispatch('SAVE_DASHBOARD', this.dashboard)
    },
    async importData(data) {
      this.SET_IMPORT_VISIBLE(true)
      await this.$store.dispatch('IMPORT_' + this.record?.name?.toUpperCase(), data)
      await this.loadData()
      this.SET_IMPORT_VISIBLE(false)
      /*  await this.$store.dispatch('READ_'+this.record?.name?.toUpperCase())*/
    },
    /*getFilterFormatted(filter) {
      let data = []
      for (let key in filter) {
        if (filter[key]) {
          data.push({
            field: key,
            values: filter[key].values
          })
        }
      }
      return data
    },*/
    async saveFilter(filterData) {
      let data = {
        filter: JSON.stringify(this.filters[this.record.name]),
        recordId: this.record.name,
        name: filterData.name,
        isPublic: filterData.isPublic
      }
      await this.$store.dispatch('SAVE_DB_FILTER', data)
      /*this.loadData()*/
    },
    // async getRecordLabel() {
    //   //this.company = await this.$store.dispatch('READ_CURRENT_COMPANY')
    //   if (!this.company || !this.company.recordLabels || !(this.record.name in this.company?.recordLabels)) {
    //     this.recordLabel = this.$t(`records.${this.record.name}.title`)
    //     return
    //   }
    //
    //   if (!this.company.recordLabels[this.record.name].label && !this.company.recordLabels[this.record.name].pluralLabel) {
    //     this.recordLabel = this.$t(`records.${this.record.name}.title`)
    //     return
    //   }
    //
    //   if (this.company.recordLabels[this.record.name].plural !== null) {
    //     this.recordLabel = this.company.recordLabels[this.record.name].pluralLabel
    //   } else {
    //     this.recordLabel = this.$t(`records.${this.record.name}.title`)
    //   }
    // },
    importCsv() {
      this.importDialog = true
    },
    getColor() {
      return this.$colors.primary[500]
    },
    callToFunction(func) {
      if (func.function === 'importCSV') {
        this.importCsv()
      } else if (func.function === 'exportToXLSX') {
        this.$store.dispatch('EXPORT_' + this.record.id + '_XLSX');
        this.$emit('exportToXLSX')
      }
    },
    async setCurrentOption(option) {
      if (!option) return
      this.currentOption = option
      await this.loadData()
    },
    async searchData(evt) {
      clearTimeout(this.timerId)
      this.timerId = window.setTimeout(async () => {
        this.filters[this.record.name].name =
            {
              fieldId: 'name',
              values: [evt.target.value]
            }
        this.SET_FILTERS(this.filters)
        await this.loadData()
      }, 300)
    },
    async openDownloadCenterPopUp() {
      await this.READ_EXPORTED_DOCUMENTS()
      this.show_download_center = true
    },
    getData() {
      if (this.data.data)
        return this.data.data
      return []
    },
    updateServerOptions(serverOptions) {
      this.serverOptions = serverOptions
    },
    setOrder(orderInfo) {
      this.serverOptions = {
        ...this.serverOptions,
        ...orderInfo
      }
      //Info adicional para vista de lista
      this.sortOptions.multiplier = this.sortOptions.sortType === 'asc' ? 1 : -1
      this.sortOptions.sortBy = orderInfo.sortBy
      this.sortOptions.sortType = orderInfo.sortType
    },
    async checkDefaultSort(options) {
      let itemWithDefaultColumn = await options.find(x => x.default === true)
      if (itemWithDefaultColumn !== null) {
        this.serverOptions.sortBy = itemWithDefaultColumn?.field ?? ""
      }
    },
    sortData(columnData) {
      /*if(this.record.action.includes("WORKORDERS")){
        //Esto se ordenará via backend
        return ''
      }*/
      let field = columnData.id
      //Flujo: ascendente, descendente
      this.sortOptions.multiplier = this.sortOptions.sortBy === field ? -this.sortOptions.multiplier : 1
      this.sortOptions.sortBy = field
      this.sortOptions.sortType = this.sortOptions.sortType === 'asc' ? 'desc' : 'asc'
      this.serverOptions.sortBy = field
      this.serverOptions.sortType = this.sortOptions.sortType

      /* this.data.data = this.data.data.sort((a, b) => {
         if (a[field] < b[field]) {
           return -this.sortOptions.multiplier;
         }
         if (a[field] > b[field]) {
           return this.sortOptions.multiplier;
         }
         return 0;
       })*/
      return field
    },
    resize() {
      const t = document.getElementById('displayCtrl');
      const top = t?.offsetTop ?? 75;
      const clientHeight = document.documentElement.clientHeight;

      if (!t?.style) return
      if (this.clientHeight > 300)
        t.style.height = (clientHeight - top - 16) + 'px';
      else
        t.style.height = '200px';
    },
    isSelected(item) {
      return this.$route.params.id === item.id;
    },
    isFullHeight() {
      return this.record.name === 'workorders' ||
          (this.record.name === 'categories' && this.action !== 'create') ||
          (this.record.name === 'sites' && this.action !== 'create') ||
          (this.record.name === 'assets' && this.action !== 'create') ||
          (this.record.name === 'users' && this.action !== 'create');
    },
    async loadMoreData() {
      if (this.view === 'dual') {
        const scrollContainer = this.$refs.scrollContainer;
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 50 && !this.loading && this.serverOptions.rowsPerPage < this.data.total) {
          this.serverOptions.rowsPerPage += 10;
          await this.loadData()
          setTimeout(() => {
          }, 100);
        }
      }
    },
    async loadData() {
      if (this.record) {
        const url = this.$route.fullPath;
        this.searchText = this.filters[this.record.name]?.name?.values[0] ?? ''
        this.SET_LOADING(true);

        await this.READ_COLUMNS(this.record);

        const filters = {...this.filters[this.record.name]};
        var data = {};
        Object.keys(filters).forEach(k => {
          const filter = filters[k];
          data[filter.fieldId] = filter.values;
        })

        if (this.currentOption && (this.isMobile || this.view === 'dual')) {
          Object.keys(this.currentOption.filter).forEach(key => {
            data[key] = this.currentOption.filter[key];
          });
        }

        const payload = {...this.serverOptions, filters: JSON.stringify(data)}
        if(this.view === 'kanban'){
          await this.$store.dispatch('READ_WORKORDERS_KANBAN', {filters: JSON.stringify(data)})
        }else{
          await this.$store.dispatch(this.record.action, payload);
        }
        //TODO: esto es parta probar, eliminar al subir a produccion
        this.data = this.$store.getters[this.record.getter] ?? {data: [], total: 0};

        // Valido que no hayan cambiado de pagina mientras leia los datos. Evito entrar en un loop.
        if (url === this.$route.fullPath) {
          // YA LEYO TODOS LOS DATOS
          if (this.data.data?.length === 0 && this.$route.params.id !== undefined) {
            this.$router.push(CRUD.getRecordListUrl(this.record));
          }
          if (this.view === 'dual' && !this.data.data.some((item) => item.id === this.$route.params.id) && this.data.data.length > 0 && !this.$route.params.action === 'create') {
            this.$router.push(CRUD.getRecordViewUrl(this.record, this.getData()[0]));
            //todo: aqui se encontro un error al tener mas de 10 elementos en el data, no se puede hacer la busqueda this.data.data.some((item) => item.id === this.$route.params.id) ya que solo se traen los primeros 10 elementos, si el elemento a editar en el request esta en la pagina 2 o mas, no se encuentra y se redirecciona a la primera pagina y al primer elemento
          } else if (this.view === 'dual' && this.$route.params.id === undefined && this.data.length > 0) {
            this.$router.push(CRUD.getRecordViewUrl(this.record, this.getData()[0]));
          }

          if (!this.isMobile && this.view === 'dual' && this.action === 'list' && this.getData()?.length > 0) {
            this.doView(this.getData()[0])
          }
        } else {
          console.log('PAGE CHANGE')
        }
        this.SET_LOADING(false);
      }
    },
    async reload() {
      await this.loadData();
    },
    changeView(view) {
      if (view !== 'dual' && this.$route.params.id !== undefined && this.record !== null) {
        this.$router.push(CRUD.getRecordListUrl(this.record));
      }
      this.SET_VIEW(view);
    },
    closePopup(cancel = false) {
      this.$router.push(CRUD.getRecordListUrl(this.record));

      if (!cancel)
        this.$refs.list?.load();
    },
    doCreate() {
      this.$router.push(CRUD.getRecordCreateUrl(this.record));
    },
    doView(item) {
      this.$router.push(CRUD.getRecordViewUrl(this.record, item.id));
    },
    async getColValue(col, id) {
      const relRecord = this.records[col.record];

      if (relRecord !== null) {
        const data = await this.$store.getters[relRecord.getter];

        if (data.data !== undefined)
          return data.data.find(x => x.id === id)?.name;
      }

      return id;
    },
    checkUserId(item) {
      return item.readByUser && !(this.user.id in item.readByUser);
    },
    validateDate(date) {
      let now = new Date();
      let dueDate = new Date(date);
      if (dueDate < now && date !== null) {
        let difference = now.getTime() - dueDate.getTime();
        let daysDifference = Math.floor(difference / (1000 * 3600 * 24));
        return this.$t('crud.message.expired', { days: daysDifference })
      } else
        return '';
    },
    async updateAction(func, item) {
      if (func.function === 'copy_link') {
        const baseUrl = window.location.origin + CRUD.getRecordViewUrl(this.record, this.item.id);
        await navigator.clipboard.writeText(baseUrl);
        this.$store.commit('TOAST_MESSAGE', {
          type: 'success',
          title: this.$t(`records.${this.record.name}.actions.messages.copy_link_success`),
          message: this.$t(`records.${this.record.name}.actions.messages.copy_link_message`),
        })
      } else if (func.function === 'duplicate') {
        this.$store.commit('SET_' + this.record.id, {...item, id: undefined, qrCode: undefined})
        this.$router.push(CRUD.getRecordCreateUrl(this.record, 'duplicateId=' + item.id));
      } else if (func.function === 'exportToPDF') {
        let options = this.record?.views?.dual?.actions?.find(x => x.function === 'exportToPDF')?.options
        this.$store.commit('SET_EXPORT_OPTIONS', options);
        await this.$store.dispatch('EXPORT_' + this.record.id + '_PDF', {
          id: item.id,
          options: this.$store.getters.GET_EXPORT_OPTIONS
        });
        this.$emit('exportToPDF')
        this.closePopup()
      } else if (func.function === 'delete') {
        let result = await this.$refs.confirm.open("", this.$t(`${this.record.name}.sureToDelete`), '', 'yesDelete')
        if (result) {
          this.saving = true;
          this.$store.dispatch('DELETE_' + this.record.id, {id: item.id});
          emitter.emit('updateList');
          this.$router.push(CRUD.getRecordListUrl(this.record));
          this.saving = false;
        }
      } else if (func.function === 'assignToWorkOrder') {
        this.saving = true;
        this.$store.dispatch('CREATE_WORKORDER');
        let workOrder = {...this.workOrder};
        if (func.multiple) {
          workOrder[func.field] = [item.id]
        } else {
          if (func.object) {
            workOrder[func.field][0].id = item.id
          } else {
            workOrder[func.field] = item.id
          }
        }
        this.$store.commit('SET_WORKORDER', workOrder);
        this.$router.push('/workorders/create?assignTo=' + item.id);
      } else if (func.function === 'loginCompany') {
        this.$store.dispatch('LOGIN_COMPANY', {id: item.id});
      } else if (func.function === 'goToCreate') {
        this.$router.push('/' + this.record?.redirectTo?.to + '/create?' + this.record?.redirectTo?.id + '=' + item.id);
      } else if (func.function === 'createInspection') {
        this.$store.dispatch('CREATE_INSPECTION');
        let inspection = await this.$store.dispatch('SAVE_INSPECTION', { templateId: item.id });
        this.$router.push('/workorders/' + inspection.workOrderId);
      }
    },
    resetFilters() {
      this.RESET_ALL_FILTERS(this.record)
      this.loadData()
    },
    isActionAllowed(action) {
      return (this.hasData || action.allowEmpty) && (!action.permission || this.$hasPermission(action.permission + '_' + this.record.name));
    },
    showColumnOptions() {
      this.columnOptions = !this.columnOptions;
    },
    async updateColumns(localColumns) {
      await this.$store.dispatch('SAVE_COLUMNS', {localColumns: localColumns, record: this.record})
    },
  },
  async mounted() {
    this.currentOption = this.record?.views?.dual?.options ? this.record?.views?.dual?.options[0] : null
    window.addEventListener('resize', this.resize);
    emitter.on('closePopup', (e) => this.closePopup(e))
    this.$nextTick(() => {
      this.resize();
    });
    this.reloadPopUp = true
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  async created() {
    emitter.on('updateList', this.reload);
    if (this.isMobile) {
      this.changeView('list')
    }
    await this.LOAD_RECORD();
    await this.READ_VIEW();
    await this.READ_FILTERS();
    await this.loadData();

  },
  beforeUnmount() {
    emitter.off('updateList', this.reload);
  },
};
</script>
<style>
.table-container {
  overflow-x: auto;
  max-width: 100%;
  min-height: 8rem;
  max-height: calc(100% - 4rem);
}

.table-pagination {
  padding: 1rem 0;
  height: 4rem;
}

.table-container table {
}

.table-container td:first-child::before,
.table-container th:first-child::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  box-shadow: 4px 0 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.table-container td:first-child,
.table-container th:first-child {
  position: sticky;
  left: 0;
  box-shadow: 4px 0 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
  overflow: visible;
}

.table-container th:first-child {
  background-color: #f1f2f3;
}

.table-container-one-column {
  overflow-x: auto;
  max-width: 100%;
  min-height: 8rem;
  max-height: calc(100% - 4rem);
  box-shadow: 4px 0 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.table-container-one-column th:first-child {
  background-color: #f1f2f3;
}

.animation-dialog-bottom {
  padding-top: 10vh;
  animation: slide-bottom 0.6s ease-in-out;
}

@keyframes slide-bottom {
  from {
    padding-top: 100vh;
  }

  to {
    padding-top: 10vh !important;
  }
}
</style>
