<template>
  <div class="flex h-screen">
    <div class="xl:block hidden">
      <div class="login-image-filter w-full">
        <div class="pl-12 pt-[10%]">
          <img src="/images/zigo-white.png" class="w-[160px]">
          <div class="w-2/3 pt-4">
            <!--            <span :extra-class="'font-nhu-500 color-white login-text'">-->
            <!--              Lorem ipsum dolor sit amet, consectetur adipiscing elit.-->
            <!--            </span>-->
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center px-6 my-4 sm:w-[512px] mx-auto w-full h-[calc(100vh-50px)]">
      <div class="py-[2rem] block xl:hidden">
        <img class="w-[160px] mx-auto" src="/images/zigo.png" alt="zgio"/>
      </div>

      <div class="w-full h-full">
        <div class="flex flex-col h-full">
          <div class="xl:order-1 xl:h-[30%] order-3 h-full">
            <div class="flex w-full h-full xl:justify-end">
              <div class="flex h-6">
              </div>
            </div>
          </div>


          <div class="xl:order-2 xl:h-auto order-1 h-[60%]">
            <input type="hidden" name="remember" value="true"/>
            <div class="rounded pt-0 xl:pt-10">
              <div class="mt-6">
                <span class="font-inter-regular text-[24px] xl:text-[32px]">
                  {{ $t('login.enter') }}
                </span>
              </div>
              <div v-if="invalidLogin" class="flex items-center bg-red-50 border-l-[3px] border-red-500 px-4 py-2 my-6">
                <span class="font-nhu-bold text-red-500 fs-14">{{$t('userOrEmailInvalid')}}</span>
              </div>
              <div class="my-6">
                <app-textbox id="email"
                             v-model="email"
                             :label="$t('login.mailAddress')"
                             :has-error="!!invalidLogin"
                             type="text"></app-textbox>
              </div>
              <div class="mb-4">
                <app-textbox id="password"
                             v-model="password"
                             :label="$t('login.password')"
                             :has-error="!!invalidLogin"
                             :is-password="true"
                             type="password">
                </app-textbox>
              </div>
            </div>

            <div class="flex justify-start sm:justify-end order-3">
              <div class="text-sm">
                <a href="#" @click="forgotPassword"
                   class="font-medium text-blue-500 hover:text-primary-700 font-nhu-regular text-base">
                  {{ $t('login.forgotYouPass') }} </a>
              </div>
            </div>
            <div class="pt-2 w-8 w-full text-red-700" v-if="error_login">{{ $t(error_login) }}</div>

          </div>



          <div class="xl:order-3 xl:h-auto order-2 h-[40%]">
            <div class="flex flex-col sm:pt-2 xl:pt-6 mt-6 w-full xl:w-[140px]">
              <app-button @click="login" :disabled="loading" :saving="saving">
                <span class="font-nhu-500 fs-14">
                  {{ $t('login.enter') }}
                </span>
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'LoginPage',
  components: {},
  data() {
    return {
      loading: false,
      saving: false,
      email: '',
      password: '',
      validEmail: false,
      invalidLogin: null
    }
  },

  computed: {
    ...mapGetters({error_login: 'GET_ERROR_LOGIN'}),
  },

  methods: {
    ...mapActions(['LOGIN']),

    async login() {
      this.saving = true;
      this.invalidLogin = await this.LOGIN({email: this.email, password: this.password});
      this.saving = false;
    },
    async forgotPassword() {
      this.$router.push('/admin/forgot_password');
    },
    createShop() {
      this.$router.push('/admin/company/create');
    },
    validateEmail() {
      let validEmailTest = /^[^@]+@.+[^.]$/.test(this.email)
      if (validEmailTest) {
        this.validEmail = true
      } else this.validEmail = false
    }
  },
}
</script>
<style lang="css">

.login-image-filter {
  flex-shrink: 0;
  background: linear-gradient(0deg, rgba(21, 17, 67, 0.50) 0%, rgba(21, 17, 67, 0.50) 100%), linear-gradient(180deg, #151143 0%, rgba(0, 0, 0, 0.00) 100%), url('/public/images/login-image.png'), no-repeat, lightgray 50%;
  background-size: cover;
  background-position: center;
  height: 100vh;
  aspect-ratio: 3/5;
}
</style>
