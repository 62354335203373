<template>
  <app-crud :extra-class="'bg-white'" v-slot="{ item }">
    <app-label :label="$t('records.teams.fields.name')">
      <pre class="font-nhu-regular text-gray-900 fs-14">{{ item.name }}</pre>
    </app-label>
    <app-label :label="$t('records.teams.fields.description')">
      <pre class="font-nhu-regular text-gray-900 fs-14">{{ item.description }}</pre>
    </app-label>
    <app-label :label="$t('records.teams.fields.addImages')">
      <app-image-list v-model="item.images" :rounded="false" :label="$t('records.teams.fields.addImages')" :disabled="true" />
    </app-label>
    <app-color-picker :model-value="item" :view-mode="true"/>
    <UserList :users="getSupervisors(item)" :label="$t('records.teams.fields.supervisors')" icon="people_roof_regular" icon-color="fill-primary-500"></UserList>
    <UserList :users="getMembers(item)" :label="$t('records.teams.fields.members')" icon="users_regular" icon-color="fill-primary-500"></UserList>
  </app-crud>
</template>

<script>
import {mapGetters} from "vuex";
import UserList from "@/pages/teams/UserList.vue";
import AppColorPicker from "@/components/AppColorPicker.vue";

export default {
  name: "TeamViewPage",
  components: {AppColorPicker, UserList },
  computed: {
    ...mapGetters({ users: 'GET_ALL_USERS'}),
  },
  methods: {
    getUser(userId) {
      return this.users.find(user => user.id === userId);
    },
    getMembers(item) {
      return item.members?.map(memberId => this.getUser(memberId)).filter(user => user !== undefined);
    },
    getSupervisors(item) {
      return item.supervisors?.map(supervisorId => this.getUser(supervisorId)).filter(user => user !== undefined);
    },
  }
}
</script>

<style scoped>

</style>
