<template>
  <div v-if="field.id === 'name'" class="flex justify-start cursor-pointer h-full" @click="doView">

    <!----------------Seccion que indica si la workOrder ya esta leida o no + nombre de workOrder ------------------------->
    <template v-if="field.type === 'workorder_name' && view !== 'list'">
      <div v-if="checkUserId(item)" class="flex items-center -ml-4 ">
        <div v-if="validateDate(item.dueDate)" class="fill-red-500">
          <app-icon icon="circle_solid" :widht="8" :height="8" />
        </div>
        <div v-else class="fill-primary-500">
          <app-icon icon="circle_solid" :widht="8" :height="8" />
        </div>
        <span class="font-nhu-500 fs-14 ml-2">{{ item.name }}</span>
      </div>
      <span v-else class="font-nhu-regular fs-14 text-gray-900 overflow-clip" :title="item.name">{{ item.name }}</span>
    </template>

    <!----------------------------------------Seccion de categorias en vista dual ----------------------------------------->

    <div v-else-if="field.type === 'category_name' && view === 'dual'" class="flex items-center">
      <template v-if="view === 'dual'">
        <!-- Color de tag para categorias -->
        <div class="rounded-full w-12 h-12 flex items-center justify-center border-[1px]"
          :class="item.color ? '' : 'bg-primary-50 border-primary-500 fill-primary-500'"
          :style="'background-color:' + item.color + '1A; border-color: ' + item.color">
          <app-icon icon="tag_regular" :widht="24" :height="24" :fill="item.color" />
        </div>
        <span class="font-nhu-regular fs-14 text-gray-900 pl-2">
          {{ item.name }}
        </span>
      </template>
    </div>


    <div v-else class="flex items-center justify-center w-full">

      <!-----------------Seccion que muestra el icono de ubicaciones en la vista dual para las ubicaciones------------------->

      <div v-if="record.name === 'sites' && view === 'dual'"
        class="bg-primary-50 px-[15px] py-3 rounded-full border-2 border-primary-100 mr-2 fill-primary-500">
        <app-icon icon="location_dot_regular" :widht="30" :height="30" />
      </div>

      <!---------------------Seccion que muestra el avatar de los usuarios en la vista de lista de usuarios------------------>

      <div v-if="record.name === 'users'" class="mx-2">
        <user-avatar width="w-10" height="h-10" :user="item"></user-avatar>
      </div>

      <!---------------------Seccion que muestra el nombre en la vista de lista------------------>

      <template v-if="view === 'list'">
        <div class="font-nhu-regular fs-14 text-gray-500 w-64 truncate overflow-ellipsis h-full flex items-center"
          @contextmenu.prevent="showMenu($event)">
          {{ item.name }}
        </div>
        <div v-if="record.views.dual?.actions && !isMobile"
          class="flex justify-center items-center z-50 relative hidden">
          <Menu as="div" class="inline-block text-center flex items-center">
            <MenuButton @click.stop>
              <app-icon icon="ellipsis_vertical_regular" :width="20" :height="20" />
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="z-1000 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-auto whitespace-nowrap absolute top-0 left-9">
                <div class="py-1">
                  <MenuItem v-for="action in record.views.dual.actions" :key="action.value" class="cursor-pointer"
                    v-slot="{ active }" @click.stop="callToFunction(action, this.item)">
                  <div
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm items-center ' + 'fill-' + action.color + '-500',
                    !action.permission || $hasPermission(action.permission + '_' + record.name) ? '' : 'opacity-50 !cursor-not-allowed']">
                    <app-icon :icon="action.icon" width="14" height="14" />
                    <span class="font-nhu-regular pl-[2px]">
                      {{ $t('records.' + record.name + '.actions.' + action.function) }}
                    </span>
                  </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </template>

      <!---------------------Seccion que muestra la informacion de las ubicaciones en la vista dual------------------>

      <div v-if="record.name === 'sites' && view === 'dual'">
        <div class="font-nhu-regular fs-14 text-gray-900 w-32">
          {{ item.name }}
        </div>
        <span class="font-nhu-regular fs-12 text-gray-500 my-1">
          {{ item.location }}
        </span>
        <div v-if="item.childs?.length >= 1"
          class="bg-blue-50 flex items-center px-3 py-[2px] rounded-full border border-blue-100">
          <app-icon extra-class="mr-2" icon="list_tree_regular" :width="16" :height="16"
            :fill="this.$colors.blue[500]" />
          <span v-if="getChildCount(item.childs) === 1" class="font-nhu-regular text-blue-800 fs-12">
            {{ getChildCount(item.childs) }} {{ $t("sites.dependentSiteSingular") }}
          </span>
          <span v-else class="font-nhu-regular text-blue-800 fs-12">
            {{ getChildCount(item.childs) }} {{ $t("sites.dependentSites") }}
          </span>
        </div>
      </div>

      <!---------------------Seccion que muestra la informacion de los activos en la vista dual------------------>

      <div v-if="record.name === 'assets' && view === 'dual'">
        <div class="font-nhu-regular fs-14 text-gray-900 w-full">
          {{ item.name }}
        </div>
        <div v-if="item.site !== '' && item.site !== undefined" class="flex items-center my-1">
          <div class="mr-1 fill-primary-500">
            <app-icon icon="location_dot_regular" :widht="20" :height="20" />
          </div>
          <span class="font-nhu-regular fs-14 text-gray-500">{{ item.site }}</span>
        </div>
        <div v-if="item.childs?.length >= 1"
          class="bg-blue-50 flex items-center px-3 py-[2px] rounded-full border border-blue-100">
          <app-icon extra-class="mr-2" icon="list_tree_regular" :width="16" :height="16"
            :fill="this.$colors.blue[500]" />
          <span v-if="getChildCount(item.childs) === 1" class="font-nhu-regular text-blue-800 fs-12">{{
            getChildCount(item.childs) }}
            {{ $t("assets.dependentAssetSingular") }}
          </span>
          <span v-else class="font-nhu-regular text-blue-800 fs-12">{{ getChildCount(item.childs) }}
            {{ $t("assets.dependentAssets") }}
          </span>
        </div>
      </div>

      <div
        v-else-if="view === 'dual' && record.name !== 'assets' && record.name !== 'workorders' && record.name !== 'sites'">
        <span class="font-nhu-regular fs-14 text-gray-900 pl-2">
          {{ item.name }}
        </span>
      </div>

    </div>
  </div>

  <template v-else-if="field.type === 'asset-description'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ item.description }}
    </span>
  </template>

  <template v-else-if="field.id === 'location' && view === 'list'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ item.location }}
    </span>
  </template>

  <template v-else-if="field.type === 'date' && view === 'list'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ formatDate(item[field.id]) }}
    </span>
  </template>

  <template v-else-if="field.id === 'description'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ item.description }}
    </span>
  </template>

  <template v-else-if="field.id === 'role' && !isMobile">
    <span class="font-nhu-regular fs-14 text-gray-900">{{ item.role }}</span>
  </template>

  <template v-else-if="field.id === 'lastLoginTime' && !isMobile">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ formatDate(item.lastLoginTime) }}
    </span>
  </template>

  <template v-else-if="field.type === 'status'">
    <div
      class="inline-flex items-center gap-x-1 h-5 rounded-2xl px-2 py-[1px] fs-12 font-medium border-[1px] font-nhu-regular"
      :class="this.$iconsByStatus.containerClass(item.status)">
      <app-icon :icon="this.$iconsByStatus.icon(item.status)" :width="12" :height="12"></app-icon>
      {{ $t('records.' + record.name + '.status.' + item.status) }}
      <!--      <template v-if="item.workOrderStatus">
              / {{ $t('records.workorders.status.' + item.workOrderStatus) }}
            </template>-->
    </div>
  </template>

  <template v-else-if="field.type === 'requestStatus'">
    <div
      class="inline-flex items-center gap-x-1 h-5 rounded-2xl px-2 py-[1px] fs-12 font-medium border-[1px] font-nhu-regular"
      :class="[{ 'bg-green-50 border-green-100 text-green-800 fill-green-500': item.status === 'accepted' }, { 'bg-red-50 border-red-100 text-red-800 fill-red-500': item.status === 'rejected' }, { 'bg-yellow-50 border-yellow-100 text-yellow-800 fill-yellow-500': item.status === 'pending' }]">
      <app-icon :icon="this.$iconsByStatus.icon(item.status)" :width="12" :height="12"></app-icon>
      {{ $t('records.' + record.name + '.status.' + item.status) }}
      <!--      <template v-if="item.workOrderStatus">
              / {{ $t('records.workorders.status.' + item.workOrderStatus) }}
            </template>-->
    </div>
  </template>
  <template v-else-if="field.type === 'workOrderStatus'">
    <div v-if="item.workOrderStatus"
      class="inline-flex items-center gap-x-1 h-5 rounded-2xl px-2 py-[1px] fs-12 font-medium border-[1px] font-nhu-regular"
      :class="this.$iconsByStatus.containerClass(item.workOrderStatus)">
      <app-icon :icon="this.$iconsByStatus.icon(item.workOrderStatus)" :width="12" :height="12"></app-icon>
      <template v-if="item.workOrderStatus">
        {{ $t('records.workorders.status.' + item.workOrderStatus) }}
      </template>
    </div>
    <div v-else>
      --
    </div>
  </template>
  <template v-else-if="field.type === 'workOrder'">
    <div v-if="item.workOrderId"
      class="inline-flex items-center gap-x-1 h-5 rounded-2xl px-2 py-[1px] fs-14 font-medium font-nhu-regular cursor-pointer hover:text-primary-500"
      @click="goToWorkOrder(item.workOrderId)">
      <template v-if="item.workOrderId">
        {{ getWorkOrderName(item.workOrderId) }}
      </template>
    </div>
    <div v-else>
      --
    </div>
  </template>

  <template v-else-if="field.type === 'priority'">
    <div :class="view === 'dual' ? 'flex justify-end' : ''">
      <app-priority :priority="item.priority" />
    </div>
  </template>

  <template v-else-if="field.record === 'users'">
    <template v-if="!Array.isArray(item[field.id])">
      <template v-if="view !== 'dual'">
        {{ getFieldName(field, item[field.id]) }}
      </template>
      <template v-else>
        <span class="font-nhu-regular fs-12 text-gray-500">
          {{ $t("workorders.requestedBy") }}
          {{ getFieldName(field, item[field.id]) }}
        </span>
      </template>
    </template>
    <div v-else class="flex justify-start">
      <div class="flex" v-for="val in item[field.id]?.slice(0, itemsShow)" :key="val">
        <user-avatar :user="getUser(val)" :tooltip-text="getFieldName(field, val)"></user-avatar>
      </div>
      <template v-if="view === 'list' && item[field.id].length === 1">
        <div v-for="(val, index) in item[field.id]?.slice(0, 2)" :key="val"
          class="fs-14 font-nhu-regular align-center flex flex-col justify-center ml-2 items-center">
          {{ getFieldName(field, val) }}{{ index !== item[field.id]?.slice(0, 2).length - 1 ? "," : "" }}
        </div>
      </template>
      <div v-if="item[field.id].length > itemsShow"
        class="w-8 h-5 rounded-xl bg-gray-50 text-gray-700 border-gray-100 border-[1px] ml-1 font-nhu-regular fs-12 flex justify-center items-center">
        +{{ item[field.id].length - itemsShow }}
      </div>
    </div>
  </template>

  <div v-else-if="field.record === 'categories'" class="flex items-center">
    <div v-for="val in item[field.id]?.slice(0, itemsShow)" :key="val"
      class="rounded-2xl border-gray-100 border-[1px] font-nhu-regular fs-14 items-center flex flex-col justify-center ml-1 px-2 py-[2px] fs-12 h-5"
      :style="getCategoryStyle(field, val)">
      {{ getFieldName(field, val) }}
    </div>
    <div v-if="item[field.id]?.length > itemsShow"
      class="rounded-xl bg-gray-50 text-gray-700 border-gray-100 rounded-xl border-gray-100 border-[1px] font-nhu-regular fs-14 items-center flex flex-col justify-center ml-1 px-2 py-[2px] h-5">
      +{{ item[field.id].length - itemsShow }}
    </div>
  </div>


  <template v-else-if="field.type === 'workorder_number'">
    <div class="flex justify-start">
      <span class="font-nhu-regular fs-12 text-gray-500">OT-{{ item[field.id] }}</span>
    </div>
  </template>

  <template v-else-if="field.id === 'siteId'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      <!-- el campo en la tabla  -->
      {{ getFieldName(field, item.siteId) }}
    </span>
  </template>

  <template v-else-if="field.id === 'childsCount'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ item.childs?.length ?? "0" }}
    </span>
  </template>
  <div v-else-if="field.id === 'permissions'" class="font-nhu-regular fs-14 text-gray-900 overflow-hidden">
    <div class="overflow-hidden">
      {{ item?.permissions?.reduce((acc, val) => acc + $t('permissions.' + val) + ', ', '') }}
    </div>
  </div>

  <div v-else-if="field.id === 'isValidated' && !isMobile" class="flex items-center">
    <app-button v-if="!item.isValidated && item.noLogin !== true" :clean="true" extra-class="bg-primary-500 border-none"
      @click="sendInvitation(item)">
      <span class="font-nhu-regular fs-12 text-white">{{ $t('users.resend') }}</span>
    </app-button>
    <div v-if="item.isValidated" class="mx-7 fill-primary-500">
      <app-icon icon="check_solid" :width="20" :height="20" />
    </div>
  </div>

  <template v-else-if="field.type === 'record'">
    <span class="font-nhu-regular text-gray-900 fs-14">{{ getFieldName(field, item[field.id]) }}</span>
  </template>

  <template v-else-if="field.type === 'measurementUnit'">
    <span class="font-nhu-regular text-gray-900 fs-14">{{ $t('records.metrics.units.' + item[field.id]) }}</span>
  </template>

  <template v-else-if="field.type === 'button'">
    <app-button @click="callToFunction(record?.redirectTo, item)" @contextmenu.prevent="showMenu($event)">
      <span class="font-nhu-regular fs-12 text-white">{{ $t('records.' + record.name + '.goToCreate') }}</span>
    </app-button>
  </template>

  <div v-else-if="field.type === 'templatePublish'" class="cursor-pointer" @click="doView">
    <span class="font-nhu-regular text-gray-900 fs-14">{{ item[field.id] }}</span>
  </div>
  <div v-else-if="field.type === 'customProperties'">
    <template v-if="field.fieldType === 'boolean'">
      <app-icon v-if="item.props[field.id]" icon="circle_check_regular" extra-class="fill-green-500" :width="20"
        :height="20" />
      <app-icon v-else icon="circle_xmark_regular" extra-class="fill-gray-500" :width="20" :height="20" />
    </template>
    <template v-else-if="field.fieldType === 'date'">
      <span class="font-nhu-regular text-gray-900 fs-14">
        {{ formatDate(item.props[field.id]) }}
      </span>
    </template>
    <template v-else>
      <span class="font-nhu-regular text-gray-900 fs-14"> {{ item.props[field.id] }} </span>
    </template>
  </div>
  <div v-else-if="field.id === 'orderType'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      {{ $t('records.workorders.orderType.' + item[field.id]) }}
    </span>
  </div>
  <template v-else-if="field.type === 'number'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      <!-- el campo en la tabla  -->
      {{ $formatNumber(item[field.id]) }}
    </span>
  </template>
  <template v-else-if="field.type === 'bool'">
    <span class="font-nhu-regular fs-14 text-gray-900">
      <!-- el campo en la tabla  -->
      <div v-if="item[field.id]" class="mx-7 fill-primary-500">
        <app-icon icon="check_solid" :width="20" :height="20" />
      </div>
    </span>
  </template>
  <template v-else>
    <span class="font-nhu-regular fs-14 text-gray-900">
      <!-- el campo en la tabla  -->
      {{ item[field.id] }}
    </span>
  </template>

  <teleport to="body">
    <div v-if="menuVisible" class="bg-white absolute cursor-pointer py-2 rounded-[8px] shadow-lg z-50"
      :style="{ top: `${menuY}px`, left: `${menuX}px` }">
      <div v-for="action in record.views.dual.actions" :key="action.value" class="cursor-pointer"
        @click.stop="callToFunction(action, this.item)">
        <button
          :class="['flex px-4 py-2 text-sm items-center w-full hover:bg-primary-50 ' + 'fill-' + action.color + '-500',
          !action.permission || $hasPermission(action.permission + '_' + record.name) && conditionsSatisfied(action) ? '' : 'opacity-50 !cursor-not-allowed']"
          :disabled="!((!action.permission || $hasPermission(action.permission + '_' + record.name)) && conditionsSatisfied(action))">
          <app-icon :icon="action.icon" width="14" height="14" />
          <span class="font-nhu-regular pl-[2px]">
            {{ $t('records.' + record.name + '.actions.' + action.function) }}
          </span>
        </button>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserAvatar from "@/components/UserAvatar.vue";
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import AppPriority from "@/components/AppPriority";
import AppButton from "@/components/AppButton.vue";

export default {
  name: "AppCrudField",
  components: { AppButton, AppPriority, MenuItem, MenuItems, MenuButton, UserAvatar, Menu },
  emits: ["click", "updateAction"],
  props: {
    record: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    field: {
      type: Object,
      default() {
        return {};
      },
    },
    view: { type: String, default: "" },
  },
  data() {
    return {
      hoverImage: "",
      sendingInvite: false,
      colors: ["red", "black", "blue"],
      menuVisible: false,
      menuX: 0,
      menuY: 0,
    };
  },
  watch: {},
  computed: {
    ...mapGetters({ records: "GET_RECORDS", user: "GET_ACCOUNT" }),
    isMobile() {
      return this.$isMobile()
    },
    itemsShow() {
      if (this.view === "dual") {
        return 4;
      } else {
        return 3;
      }
    },
  },
  methods: {
    ...mapActions(['INVITE_USER']),
    goToWorkOrder(id) {
      this.$router.push(`/workorders/${id}?back=true`);
    },
    getWorkOrderName(id) {
      if (!id) return "--";
      let workOrder = this.$store.getters['GET_WORKORDER_BY_ID'](id);
      return workOrder?.name ?? "--";
    },
    getUser(userID) {
      return this.$store.getters['GET_USER_BY_ID'](userID);
    },
    doView() {
      this.$emit("click", this.item);
    },
    checkUserId(item) {
      return item.isReaded === undefined || item.isReaded === false;
    },
    getImage(field, val) {
      var record = this.records[field.record];
      if (record) {
        var getter = this.$store.getters[record.getter];
        var item = getter.data?.find((x) => x.id === val);

        if (item) return item.images ? item.images[0] : "";
      } else {
        return "";
      }
    },
    getFieldName(field, val) {
      var record = this.records[field.record];
      if (record) {
        var data = this.$store.getters[record.options.getter] ?? { data: [] };
        // console.log(data)
        if (data && Array.isArray(data)) {
          for (const item of data) {
            if (item.id === val && item.parentId !== undefined && item.parentId.length > 0) {
              // in the backend is reasigned as name = fullName
              return item.name;
            }
            if (item.id === val && !item.parentId) {
              return item.name;
            }
          }
        }
        return val;
      } else {
        return "";
      }
    },
    getCategoryStyle(field, val) {
      let color = "#575fea";
      const record = this.records[field.record];
      if (record) {
        const getter = this.$store.getters["GET_ALL_CATEGORIES"];
        const item = getter?.find((x) => x.id === val);
        if (item && item.color) {
          color = item.color
        }
        return `color:${color}; background-color:${color}1A; border-color: ${color}4A`
      }
    },
    getNameInitial(field, val) {
      let name = this.getFieldName(field, val);
      if (name) {
        let names = name.split(" ");
        if (names.length > 1) {
          return names[0].charAt(0) + names[1].charAt(0);
        } else {
          return names[0].charAt(0);
        }
      } else {
        return "";
      }
    },
    getColor(field, val) {
      const record = this.records[field.record];
      if (record) {
        const getter = this.$store.getters[record.getter];
        const item = getter.data.find((x) => x.id === val);

        if (item) return item.color;
      }
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    validateDate(dueDate) {
      let now = new Date();
      dueDate = new Date(dueDate);
      return dueDate < now;
    },
    getChildCount(childs) {
      return childs?.length;
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);

      if (date.getTime() === today.getTime()) {
        return this.$t('today');
      } else if (date.getTime() === yesterday.getTime()) {
        return this.$t('yesterday');
      } else {
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      }
    },
    async sendInvitation(user) {
      this.sendingInvite = true;
      await this.INVITE_USER(user);
      this.sendingInvite = false;
    },
    showMenu(event) {
      if (this.menuVisible) {
        this.closeMenu();
      }
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.menuVisible = true;
      document.body.addEventListener('click', this.closeMenu);
    },
    action() {
      this.closeMenu();
      this.callToFunction(this.record?.redirectTo, this.item)
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.removeEventListener('click', this.closeMenu);
    },
    callToFunction(func, item) {
      this.closeMenu();
      this.$emit('updateAction', func, item);
    },
    conditionsSatisfied(action) {
      let response = true;
      if (!action.conditions) return response
      for (const condition of action.conditions) {
        if (!condition.func(this.item)) {
          response = false;
          break;
        }
      }
      return response;
    },
  },
};
</script>
