import API from '@/api'

const state = {
  ROLE: {
    name: '',
    permissions: []
  },
  DEFAULT_ROLE: {
    name: 'default',
    permissions: ["export_pdf_workorders", "export_xls_workorders",
      "read_exported_documents", "edit_workorders", "create_workorders", "update_workorder_status",
      "delete_workorders", "read_workorders", "workorder_start", "workorder_pause", "workorder_restart",
      "workorder_finish", "workorder_reopen", "add_comments", "read_assets", "edit_assets",
      "create_assets", "delete_assets", "read_sites", "edit_sites", "create_sites",
      "delete_sites", "read_categories", "edit_categories", "create_categories",
      "delete_categories", "read_users", "edit_users", "create_users", "delete_users",
      "read_teams", "create_teams", "edit_teams", "delete_teams", "delete_requests",
      "edit_requests", "create_requests", "read_requests", "delete_request-landings",
      "edit_request-landings", "create_request-landings", "read_request-landings",
      "create_parts", "delete_parts", "edit_parts", "read_parts", "create_roles",
      "delete_roles", "edit_roles","read_roles"]
  },
  ROLES: {data: []},
  ALL_ROLES: [],
  PERMISSIONS:[]
}

const getters = {
  GET_ROLE: state => state.ROLE,
  GET_ROLES: state => state.ROLES,
  GET_ALL_ROLES: state => state.ALL_ROLES,
  GET_ROLE_BY_ID: state => roleId => {
    //Todo: eliminar al resetear BD
    //Permisos por defecto para usuarios sin rol
    if(!roleId)
      return state.DEFAULT_ROLE
    // Hasta acá
    return state.ALL_ROLES?.find(e => e.id === roleId)
  },
  GET_PERMISSIONS: state => state.PERMISSIONS,
}

const mutations = {
  SET_ROLE(state, payload) {
    state.ROLE = payload
  },
  SET_ALL_ROLES(state, payload) {
    state.ALL_ROLES = payload
  },
  SET_ROLES(state, payload) {
    state.ROLES = payload
  },
  SET_PERMISSIONS(state, payload) {
    state.PERMISSIONS = payload
  },
  CREATE_ROLE(state, payload) {
    state.ROLE = payload
    state.ROLES.data.push(payload)
  },
  UPDATE_ROLE(state, payload) {
    if (state.ROLE !== null && state.ROLE.id === payload.id)
      state.ROLE = payload;

    const index = state.ROLES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.ROLES.data[index], payload)
  },
  DELETE_ROLE(state, payload) {
    if (state.ROLE?.id === payload.id)
      state.ROLE = {}
    state.ROLES.data = state.ROLES.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_PERMISSIONS(context, payload) {
    const response = await API.Get('/api/permissions', payload)
    if (response.type === 'Success') {
      context.commit('SET_PERMISSIONS', response.res.data)
      return response.res.data
    }
    context.commit('SET_PERMISSIONS', [])
  },
  async READ_ROLES(context, payload) {
    const response = await API.Get('/api/roles', payload)
    if (response.type === 'Success') {
      context.commit('SET_ROLES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ROLES', [])
  },
  async READ_ALL_ROLES(context) {
    const response = await API.Get('/api/options/roles')
    if (response.type === 'Success') {
      context.commit('SET_ALL_ROLES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_ROLES', [])
  },
  async CREATE_ROLE(context) {
    context.commit('SET_ROLE', {name: '', Permissions: []})
  },
  async SAVE_ROLE(context, payload) {
    if (payload.id === undefined) {
      const response = await API.Post('/api/roles', payload)
      if (response.type === 'Success') {
        context.commit('SET_ROLE', response.res.data)
        return response.res.data
      }
    } else {
      const response = await API.Put(`/api/roles/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_ROLE', response.res.data)
        return response.res.data
      }
    }
  },
  async READ_ROLE(context, payload) {
    const response = await API.Get(`/api/roles/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_ROLE', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_ROLE(context, payload) {
    const response = await API.Put(`/api/roles/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_ROLE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_ROLE(context, payload) {
    const response = await API.Delete(`/api/roles/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_ROLE', response.res.data)
      return response.res.status
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
