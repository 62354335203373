<template>
  <app-crud :extra-class="'bg-white'" v-slot="{ item }" >
    <div>
      <app-label :label="$t('metrics.description')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="item.description"/>
      <div class="border-b border-gray-50 my-4">
        <div class="flex items-center justify-between mb-1 py-1">
          <span class="font-nhu-semi-bold fs-14">
            {{ $t('records.metrics.fields.measures') }}
          </span>
          <button
              class="text-primary-500 border-0 border-primary-500 flex items-center h-[20px] rounded-xl font-nhu-500 fs-14 fill-primary-500"
              @click="addMeasure">
            {{ $t('records.metrics.addMeasure') }}
            <app-icon icon="chevron_right_regular" :width="14" :height="14"/>
          </button>
        </div>
        <div>
          <div class="mb-2" v-if="item.measurements?.length > 0">
            <div class="w-[90%] mx-auto">
              <vue-apex-charts type="line" :options="options" :series="series"></vue-apex-charts>
            </div>
          </div>
          <div class="py-2 flex fill-primary-500 cursor-pointer w-fit" @click="viewMeasures">
            <span class="text-primary-500 font-nhu-500 fs-14">
              {{ $t('records.metrics.seeAllMeasures') }}
            </span>
            <app-icon icon="chevron_right_regular" :width="14" :height="14"/>
          </div>
        </div>
      </div>
      <app-label v-if="item.minValue"
                 :label="$t('records.metrics.fields.minValue')"
                 :value="`${item.minValue} ${$t(`records.metrics.units.${item.measurementUnit}`)}` "/>

      <app-label v-if="item.maxValue"
                 :label="$t('records.metrics.fields.maxValue')"
                 :value="`${item.maxValue} ${$t(`records.metrics.units.${item.measurementUnit}`)}` "/>

      <app-label v-if="item.lastValue"
                  :label="$t('metrics.lastMeasurement')"
                 :icon="'ruler_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="`${item.lastValue} ${$t(`records.metrics.units.${item.measurementUnit}`)}` "/>

<!-- TODO: Preguntar a Juan si se debe mostrar la unidad de medida en la vista de detalle de la métrica
      <app-label :label="$t('metrics.measurementUnit')"
                 :icon="'ruler_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="$t(`records.metrics.units.${item.measurementUnit}`)"/>-->

      <app-label :label="$getRecordLabel('assets') ? $getRecordLabel('assets') : $t('metrics.assetId')"
                 :icon="'cubes_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="getAsset(item.assetId)"/>

      <app-label :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('metrics.siteId')"
                 :icon="'location_dot_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="getSite(item.siteId)"/>
                 <!--

      <app-label :label="$t('metrics.frequency')"
                 :icon="'clock_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="$t(`metrics.frequencies.${item.frequency}`)"/>
                 -->

      <app-label v-if="item.images?.length"
                  :label="$t('metrics.images')"
                 :icon="'image_regular'"
                 :icon-color="'fill-primary-500'"
                 :images="true"
                 :value="item.images"/>
      <div v-if="item.files?.length">
<!--        <div class="h-0 border-b border-gray-50 my-4"/>-->
        <div class="flex items-center mb-1 fill-primary-500">
          <app-icon extra-class="mr-2" icon="paperclip_regular" :width="14" :height="14"/>
          <span class="font-nhu-semi-bold fs-14">
            {{ $t('workorders.files') }}
          </span>
        </div>
        <app-file-list v-model="item.files" :rounded="false" :allow-upload-file="false"/>
      </div>
    </div>
  </app-crud>
</template>

<script>
import CRUD from "@/crud";
import VueApexCharts from 'vue3-apexcharts'
import { mapGetters } from "vuex";
import AppIcon from "@/components/AppIcon";
export default {
  name: "MetricViewPage",
  components: {AppIcon, VueApexCharts},
  computed:{
    ...mapGetters({sites: 'GET_ALL_SITES', assets: 'GET_ALL_ASSETS', records: 'GET_RECORDS', metric: 'GET_METRIC', user: 'GET_CURRENT_USER'}),
    series() {
      return [{
        name: this.metric.name,
        data: this.data
      }]
    },
    data() {
      return this.metric.measurements.map((a) => { return  {x: a.time.substring(0,10), y: a.value} }) ?? []
    },
    currentUser() {
      return this.user
    },
    mappedData() {
      return  this.metric.measurements.map(x => x.time.substring(0,10))?? []
    },
    reducedData() {
      return  this.mappedData.filter((value, index) => this.mappedData.indexOf(value) === index) ?? []
    },

    options() {
      return {
        chart: {
          zoom: {
            foreColor: '#797fee',
            enabled: true
          },
          toolbar: {
            show: true
          },
        },
        colors: ['#797fee'],
        dataLabels: {
          enabled: true,
          distributed: true,
          style: {
            colors: ['#797fee'],
            fontSize: '12px',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
          background: {
            enabled: true,
            foreColor: '#FFFFFF',
            padding: 4,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: '#797fee',
            opacity: 0.8,
          },
        },
        title: {
          text: this.metric.name,
          align: 'center'
        },
        grid: {
          row: {
            colors: ['transparent', '#797fee'],
            opacity: 0.1
          },
        },
        xaxis: {
          name: "Time",
          type: 'datetime',
          orientation: 'vertical',
          sorted: true,
          labels: {
            rotate: 90,
            rotateAlways: true,
            offsetY: 60,
            offsetX: 0,
            minHeight: 100,
            formatter: function (value) {
              return new Date(value).toLocaleDateString(localStorage.getItem('locale') ?? 'es')
            },
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            },
            style: {
              orientation: 'vertical',
              colors: '#797fee',
              fontSize: '12px',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          categories: this.reducedData ?? [],
        }
      }
    }
  },
  methods: {
    addMeasure() {
      this.$router.push(CRUD.getRecordUrl(this.records.metrics, this.metric.id, 'measures?back=true'))
    },
    viewMeasures() {
      this.$router.push(CRUD.getRecordUrl(this.records.metrics, this.metric.id, 'view-measures?back=true'))
    },
    getSite(siteId) {
      const site = this.sites.find(site => site.id === siteId)
      return site?.name
    },
    getAsset(assetId) {
      const asset = this.assets.find(asset => asset.id === assetId)
      return asset?.name
    },
  },
}
</script>
