<template>
   <div class="mx-1">
      <div class="flex justify-center border-2 border-[#B4A2F0] rounded-[50px] pl-1.5 pr-1.5 pt-1 pb-1 items-center"
      :class="{ ' bg-gray-200' : lock }">
        <button @click="valueDecrement"
        class="border-[#B4A2F0] rounded-[50px] active:border-transparent active:bg-neutral-100
        active:transition-none active:shadow-none" style="border: 2px solid #B4A2F0; padding: 0; min-height: 0" type="button" aria-label="Remove one" data-testid="item-options-minus-button">
          <div class="rounded" data-testid="children">
            <div class="flex items-center justify-center icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </div>
          </div>
        </button>
        <div class="text-[14px] h-full w-8 select-none items-center justify-center text-center sm:w-10 font-nhu-regular text-gray-900"
             data-testid="item-options-quantity">
          {{ modelValue }}
        </div>
        <button @click="valueIncrement"
        class="border-2 p-1 border-[#B4A2F0] rounded-[50px] active:border-transparent active:bg-neutral-100
        active:transition-none active:shadow-none" style="border: 2px solid #B4A2F0; padding: 0; min-height: 0" type="button" aria-label="Add one" data-testid="item-options-plus-button">
          <div class="" data-testid="children">
            <div class="flex items-center justify-center w-5 h-5 icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </div>
          </div>
        </button>
      </div>
  </div>
</template>

<script>

export default {
  name: "AppQuantity",
  components:  { },
  props: {
    id: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    lock: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small"
    },
    color: {
      type: String,
      default: "light"
    }
  },
  methods: {
    valueDecrement() {
      if (!this.lock && this.modelValue > this.min) {
        this.$emit("valueChange", Number(this.modelValue - 1))
        this.$emit("update:modelValue", Number(this.modelValue - 1))
      }
    },
    valueIncrement() {
      if (!this.lock && this.modelValue < this.max) {
        this.$emit("valueChange", Number(this.modelValue + 1))
        this.$emit("update:modelValue", Number(this.modelValue + 1))
      }
    },
  },
};
</script>
<style>
select > option {
  border-radius: 0px;
}
.icon {
  filter: invert(66%) sepia(14%) saturate(1353%) hue-rotate(211deg) brightness(104%) contrast(88%);
}
</style>
