<template>
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" @change="change"  class="block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
          <option v-for="tab in options" :key="tab.id" :selected="tab.id === modelValue">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a v-for="tab in options" :key="tab.id" class="cursor-pointer" @click="change(tab.id)"
                :class="[tab.id === modelValue ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 
                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" 
                :aria-current="tab.id === modelValue ? 'page' : undefined">{{ tab.name }}</a>
          </nav>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  name: 'AppFilters',
  components: {  },
  props: {
    modelValue: {
      type: [String, Number],
			default() { return null },
    },
    options: {
      type: Array,
			default() { return [] },
    },
	},
	methods: {
    change(value)
    {
      this.$emit('update:modelValue', value);
    }
	},  
	async created() {
  },
}
</script>
