<template>
  <TransitionRoot appear :show="show">
    <Dialog :open="show" @close="close" class="inset-0 overflow-hidden z-auto">

      <TransitionChild enter="transition-opacity ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="transition-opacity ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0"
                       as="template">
        <DialogOverlay class="absolute inset-0 bg-black bg-opacity-50"/>
      </TransitionChild>
      <TransitionChild
          enter="transform ease-in-out transition-transform duration-300" enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transform ease-in-out transition-transform duraction-300" leave-from="translate-x-0"
          leave-to="translate-x-full"
          as="template">
        <div class="bg-white fixed inset-y-0 right-0 w-full max-w-sm">
          <div class="font-nhu-regular ">
            <div class="ml-4 mr-4 ">
              <DialogTitle class="py-4 font-nhu-semi-bold fs-14 flex justify-center">{{ $t('settings') }}</DialogTitle>
              <DialogDescription class="fs-14 py-6 font-nhu-semi-bold ">
                <div class="flex justify-center">
                  <span>{{ $t('columnOptions') }}</span>
                </div>
                <div class="flex">
                  <p class="fs-12 font-nhu-regular py-2">
                    {{ $t('columnSettings') }}
                  </p>
                </div>
              </DialogDescription>
            </div>
            <draggable :list="localTableColumns" @end="update" item-key="element" handle=".drag-handle">
              <template v-slot:item="{ element }">
                <div class="w-full flex" :class="element === 'name' ? 'bg-primary-50' : 'drag-handle'">
                  <div class="flex items-center w-full mx-4">
                    <app-icon icon="grip_dots_vertical_regular" extra-class="cursor-pointer drag-handle" :width="20"
                              :height="20">
                    </app-icon>
                    <span class="pl-1 font-nhu-regular text-gray-900 fs-14 my-2">{{ element?.name }}</span>
                    <div class="flex-1"></div>
                    <app-icon icon="trash_regular" extra-class="fill-red-500 cursor-pointer" v-if="element?.id !== 'name'"
                              @click.stop="deleteOption(element?.id)"
                              :width="15" :height="15">
                    </app-icon>
                  </div>
                </div>
              </template>
            </draggable>
            <div class="h-0 border-b border-gray-50 mt-4"/>
            <div class="mx-4 mb-4 w-full">{{ $t('hidden') }} </div>
            <draggable :list="getOptions" @end="update" item-key="id">
              <template v-slot:item="{ element }">
                <div class="w-full flex">
                  <button ref="focusControl"/>
                  <div class="flex items-center w-full mx-4">
                    <app-icon icon="grip_dots_vertical_regular" extra-class="cursor-pointer drag-handle" :width="20"
                              :height="20">
                    </app-icon>
                    <span class="pl-1 font-nhu-regular text-gray-900 fs-14 my-2">{{element?.name}}</span>
                    <div class="flex-1"></div>
                    <app-icon icon="plus_regular" extra-class="fill-primary-500 cursor-pointer"
                              @click="addOption(element)"
                              :width="15" :height="15">
                    </app-icon>
                  </div>
                </div>
              </template>
            </draggable>
            <!--            <div class="py-10">-->
            <!--              <button @click="close" class="bg-primary-500 font-nhu-regular ">Aplicar</button>-->
            <!--            </div>-->
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import {Dialog, DialogDescription, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import Draggable from "vuedraggable";
import {mapGetters} from "vuex";

export default {
  name: "AppCrudColumnsOptions",
  components: {
    Draggable,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogDescription,
    DialogTitle
  },
  emits: ['update:modelValue', 'close', 'changeColumn'],
  props: {
    modelValue: {type: Boolean, default: false},
    record: {type: Object, default: null},
  },
  data() {
    return {
      show: false,
      localTableColumns: [],
      columnsAdded: '',
    }
  },
  computed: {
    ...mapGetters({
      company: 'GET_CURRENT_COMPANY',
      tableColumns: 'GET_COLUMNS',
      records: 'GET_RECORDS'
    }),
    getOptions() {
      let listableFields = this.record?.fields?.filter(field => field.listable);
      const listableColumns = listableFields?.map(field => field.id);
      const tableColumns = this.tableColumns?.length > 0 ? this.tableColumns : listableColumns;
      const availableColumns = this.tableColumns?.length > 0 ? listableFields?.filter(x => !tableColumns?.includes(x.id)) : listableFields?.filter(x => !this.record.views['list'].columns.map(y => y)?.includes(x.id));

      return availableColumns.map(x => {
        return {
          name: x.type === 'customProperties' ? x.name : this.$t(`records.${this.record.name}.fields.${x?.id}`),
          id: x.id
        }
      })
    },
    localTableColumnsIds() {
      return this.localTableColumns.map(x => x.id)
    }
  },
  watch: {
    modelValue() {
      this.show = this.modelValue
    },
    'tableColumns.length': {
      immediate: true,
      handler(val) {
        if (!val) return this.localTableColumns = this.record.views['list'].columns.map(x => {
          return {
            id: x,
            name: this.$t(`records.${this.record.name}.fields.${x}`)
          }
        })
        this.loadTableColumns();
      },
    },
  },
  methods: {
    async loadTableColumns() {
      let listableFields = this.record?.fields?.filter(field => field.listable).map(x => {
        return {
          ...x,
          name: x.type === 'customProperties' ? x.name : this.$t(`records.${this.record.name}.fields.${x?.id}`)
        }
      }) ?? [];


      this.localTableColumns = this.tableColumns?.length > 0 ? listableFields?.filter(x => this.tableColumns.includes(x.id)) : listableFields;

    },
    close() {
      this.show = false
      this.$emit('close')
    },
    update() {
      this.$emit('changeColumn', this.localTableColumnsIds);
    },
    addOption(item) {
      if (!this.localTableColumnsIds.includes(item?.id)) {
        this.localTableColumns.push(item);
        this.$emit('changeColumn', this.localTableColumnsIds);
      }
    },
    deleteOption(itemName) {
      const index = this.localTableColumnsIds?.indexOf(itemName);
      if (index !== -1) {
        this.localTableColumns?.splice(index, 1);
        this.$emit('changeColumn', this.localTableColumnsIds);
      }
    },
    addColumn() {
      this.localTableColumns.push(this.columnsAdded)
      this.$emit('changeColumn', this.localTableColumnsIds);
      this.columnsAdded = ''
    },
  },
}
</script>

<style scoped>

</style>
