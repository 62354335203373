import API from '@/api'

const state = {
  INTEGRATIONS: [],
  FACEBOOK_COMPANYS: [],
  COMPANY_CATALOGS: []
}

const getters = {
  GET_INTEGRATIONS: state => state.INTEGRATIONS,
  GET_FACEBOOK_COMPANYS: state => state.FACEBOOK_COMPANYS,
  GET_CURRENT_COMPANY_CATALOGS: state => state.COMPANY_CATALOGS
}

const mutations = {
  // Producs
  SET_INTEGRATIONS(state, payload) {
    state.INTEGRATIONS = payload
  },
  CREATE_INTEGRATION(state, payload) {
    state.INTEGRATIONS.push(payload)
  },
  UPDATE_INTEGRATION(state, payload) {
    const index = state.INTEGRATIONS.findIndex(e => e.id === payload.id)
    Object.assign(state.INTEGRATIONS[index], payload)
  },
  DELETE_INTEGRATION(state, payload) {
    state.INTEGRATIONS = state.INTEGRATIONS.filter(e => e.id !== payload.id)
  },
  SET_FACEBOOK_COMPANYS(state, payload) {
    state.FACEBOOK_COMPANYS = payload
  },
  GET_CURRENT_COMPANY_CATALOGS(state, payload) {
    state.COMPANY_CATALOGS = payload
  },

}

const actions = {
  // CRUD INTEGRATIONS
  async READ_INTEGRATIONS(context) {
    const response = await API.Get('/api/integrations')
    if (response.type === 'Success') {
      context.commit('SET_INTEGRATIONS', response.res.data)
      return
    }
    context.commit('SET_INTEGRATIONS', [])
  },

  async REGISTER_INTEGRATION(context, payload) {
    const response = await API.Put(`/api/integrations/${payload.id}`, payload)
    if (response.type === 'Success') {
      return response.res.data
    }
  },

  async UNREGISTER_INTEGRATION(context, payload) {
    const response = await API.Delete(`/api/integrations/${payload.id}`)
    if (response.type === 'Success') {
      return response.res.data
    }
  },

  async REGISTER_FACEBOOK_CATALOG(context, payload) {
    try {
      const response = await API.Put('/api/integrations/add/catalog', payload)
      if (response.type === 'Success') {
        return response.res.data
      }
    } catch (e) {
      return e
    }
  },

  async GET_FACEBOOK_COMPANYS(context, payload) {
    try {
      const response = await API.Get('/api/integrations/companys', payload)
      if (response.type === 'Success') {
        context.commit('SET_FACEBOOK_COMPANYS', response.res.data)
        return response.res.data
      }
    } catch (e) {
      return e
    }
  },

  async GET_CURRENT_COMPANY_CATALOGS(context, payload) {
    try {
      const response = await API.Get('/api/integrations/company/catalogs', payload)
      if (response.type === 'Success') {
        context.commit('GET_CURRENT_COMPANY_CATALOGS', response.res.data)
        return response.res.data
      }
    } catch (e) {
      return e
    }
  },

  async GET_MERGE_LINK_TOKEN(context, payload) {
    try {
      const response = await API.Get('/api/integrations/merge/crete-link-token', payload)
      if (response.type === 'Success')
        return response.res.data
    } catch (e) {
      return e
    }
  },

  async CREATE_MERGE_INTEGRATION(context, payload) {
    try {
      const response = await API.Get('/api/integrations/merge/account-token', payload)
      if (response.type === 'Success')
        context.commit('UPDATE_INTEGRATION', response.res.data)
    } catch (e) {
      return e
    }
  },

  async DELETE_MERGE_INTEGRATION(context, payload) {
    try {
      const response = await API.Delete(`/api/integrations/merge/account/${payload.id}`)
      if (response.type === 'Success')
        context.commit('UPDATE_INTEGRATION', response.res.data)
    } catch(e) {
      return e
    }
  },

  DELETE_COMPANY_CATALOGS(context){
    context.commit('GET_CURRENT_COMPANY_CATALOGS', [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
