import API from "@/api";
import i18n from "@/locales/i18n";

const state = {
    kanbanField: '',
};
const getters = {
    GET_KANBAN_FIELD: (state) => {
        return state.kanbanField;
    },
};
const mutations = {
    SET_KANBAN_FIELD: (state, payload) => {
        state.kanbanField = payload;
    },
};
const actions = {
    async UPDATE_ITEM_FIELD (context, data) {
        if(!data)
            return
        const response = await API.Patch(`/api/${data.recordName}/${data.payload.id}`, {...data.payload})
        if (response.type === 'Success') {
            if (typeof response.res.data === 'string') {
                context.commit('UPDATE_'+data.recordId.toUpperCase(), data.item)
                context.commit('TOAST_MESSAGE', {
                    type: 'error',
                    title: i18n.global.t('records.kanban.errors.' + response.res.data + '.title'),
                    message: i18n.global.t('records.kanban.errors.' + response.res.data + '.message'),
                })
                return
            }
            context.commit('UPDATE_'+data.recordId.toUpperCase(), response.res.data)
            return response.res.data
        }else{
            context.commit('UPDATE_'+data.recordId.toUpperCase(), data.item)
        }
    },
};
export default {
    state,
    getters,
    mutations,
    actions
}
