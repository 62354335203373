<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton as="div" class="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-sm text-gray-900 hover:font-bold">
        <div v-if="optionset">
          <span v-for="o in optionset.options.slice(0, 3)" :key="o.id" class="px-4 ml-2 rounded-4xl border-gray-500 font-nhu-regular fs-14" :style="{ 'background-color': o.color }">
            {{ o.name }}
          </span>
          <span v-if="optionset.options.length > 3"> +{{ optionset.options.length - 3 }}</span>
        </div>
        <div v-else>
          {{ selected?.name }}
        </div>
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems as="div" class="absolute right-0 z-10 mt-2 w-128 flex flex-row origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="basis-1/2">
            <div class="flex">
              <div class="p-2 flex-1 font-bold">{{$t('questionTypes.multipleOptions')}}</div>
              <div @click="newOptionSet" class="flex cursor-pointer my-auto mr-2 hover:underline text-blue-900">
                <PlusIcon class="w-4 h-4"/>
                {{$t('new2')}}
              </div>
            </div>

            <MenuItem as="div" v-for="os in optionsets" :key="os.id" v-slot="{ active }" class="flex">
              <div @click="setOptionSet(os)" class="flex-1" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                <span v-for="o in os.options.slice(0, 3)" :key="o.id" class="px-2 ml-2 rounded-4xl border" :style="{ 'background-color': o.color }">
                  {{ o.name }}
                </span>
              </div>
              <span v-if="os.options.length > 3"> +{{ os.options.length - 3 }}</span>
              <PencilIcon @click="editOptionSet(os)" class="w-4 h-4 my-auto mx-2 cursor-pointer" />
            </MenuItem>


          </div>
          <div class="basis-1/2 border-l">
            <div class="p-2 font-bold">{{$t('information')}}</div>
            <MenuItem as="div" v-for="item in fields" :key="item.id" v-slot="{ active }">
              <div @click="setType(item)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"> {{ item.name }}</div>
            </MenuItem>
            <div class="p-2 font-bold border-t">{{$t('information')}}</div>
            <MenuItem as="div" v-for="item in types" :key="item.id" v-slot="{ active }">
              <div @click="setType(item)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"> {{ item.name }}</div>
            </MenuItem>
          </div>
      </MenuItems>
    </transition>
  </Menu>

  <option-set-editor ref="multiOptionEditor" />

</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, PlusIcon, PencilIcon } from '@heroicons/vue/20/solid'
import OptionSetEditor from './OpotionSetEditor.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TemplatePropertyType',
  components: { OptionSetEditor, Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon, PlusIcon, PencilIcon },
  props: {
    value: {
      type: Object,
			default() { return null },
    },
	},
  computed: {
    ...mapGetters({ optionsets: 'GET_ALL_OPTIONSETS' }),
  },
  watch: {
    value(val)
    {
      let item;

      if(val.questionType === 'optionset' && this.optionsets)
      {
        this.optionset = this.optionsets.find(x => x.id === val.optionSetId);
        this.selected = { id: 'optionset', name: 'OptionSet' };
      }
      else
        this.optionset = null;
      if((item = this.fields.find(x => x.id === val.questionType)) != null)
        this.selected = item;
      if((item = this.types.find(x => x.id === val.questionType)) != null)
        this.selected = item;
    }
  },
  data() {
    return {
      name: '',
      selected: null,
      optionset: null,
      fields: [
        { id: 'site', name: 'Sitio' },
        { id: 'inspection_date', name: this.$t('questionTypes.inspection_date') },
        { id: 'person', name: this.$t('questionTypes.person') },
        { id: 'inspection_number', name: this.$t('questionTypes.inspection_number') },
        { id: 'inspection_location', name:  this.$t('questionTypes.inspection_location') },
        { id: 'asset', name: this.$t('questionTypes.asset') },
        { id: 'metric', name: this.$t('questionTypes.metric') },
      ],
      types: [
        { id: 'text', name: this.$t('questionTypes.text') },
        { id: 'int', name: this.$t('questionTypes.int') },
        { id: 'decimal', name: this.$t('questionTypes.decimal') },
        { id: 'checkbox', name: this.$t('questionTypes.checkbox') },
        { id: 'date', name: this.$t('questionTypes.date') },
        { id: 'media', name: this.$t('questionTypes.media') },
        { id: 'instruction', name: this.$t('questionTypes.instruction') },
      ]
    }
  },
  methods: {
    ...mapActions([]),
    setType(type)
    {
      let newValue = Object.assign({}, this.value);
      newValue.questionType = type.id
      this.$emit('update:value', newValue);
    },
    setOptionSet(opt)
    {
      var newValue = Object.assign({}, this.value);
      newValue.questionType = 'optionset'
      newValue.optionSetId = opt.id
      this.$emit('update:value', newValue);
    },

    newOptionSet()
    {
      this.$refs.multiOptionEditor.createNew();
    },
    editOptionSet(os)
    {
      this.$refs.multiOptionEditor.editItem(os.id);
    }
	},
	async mounted() {
    var item;

    if(this.value.questionType === 'optionset' && this.optionsets)
    {
      this.optionset = this.optionsets.find(x => x.id === this.value.optionSetId);
      this.selected = { id: 'optionset', name: 'OptionSet' };
    }
    else
      this.optionset = null;
    if((item = this.fields.find(x => x.id === this.value.questionType)) != null)
      this.selected = item;
    if((item = this.types.find(x => x.id === this.value.questionType)) != null)
      this.selected = item;

  },
}
</script>
