<template>
  <div class="w-full query-builder" v-if="fields && modelValue">
    <QueryBuilderGroup :rule="modelValue" @update="update" :fields="fields" />
  </div>

</template>

<script>
import QueryBuilderGroup from './QueryBuilderGroup.vue'

export default {
  name: "AppQueryBuilder",
  components: { QueryBuilderGroup },
  emits: ['update:modelValue'],
  props: {
    fields: { type: Array, default() { return null; } },
    modelValue: { type: Object, default() { return null; } },
  },
  methods: {
    update(val)
    {
      this.$emit('update:modelValue', val);
    }
  },
  computed: {
  },
  async created() {

  },
};
</script>

