<template>
  <app-crud ref="crud" v-slot="{ item }">
    <app-loading v-if="loading" :loading="true" class="fixed inset-0 z-50"/>
    <template v-else>
      <template v-if="hasPublishTemplate">
        <inspection-page />
      </template>
      <template v-else>
        <template-list-box v-model="item.templateId" @selected-template="readTemplate"/>
      </template>
    </template>
  </app-crud>
</template>

<script>
import TemplateListBox from "@/pages/templates/TemplateListBox.vue";
import InspectionPage from "@/pages/inspections/InspectionPage.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'InspectionCreatePage',
  components: {InspectionPage, TemplateListBox},
  data() {
    return {
      hasPublishTemplate: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({publishTemplate: 'GET_PUBLISH_TEMPLATE', template: 'GET_TEMPLATE', inspection: 'GET_INSPECTION'}),
  },
  methods: {
    ...mapActions(['CREATE_INSPECTION', 'READ_PUBLISH_TEMPLATE', 'READ_TEMPLATE']),
    ...mapMutations(['UPDATE_INSPECTION_DATA']),
    async readTemplate(template) {
      await this.READ_PUBLISH_TEMPLATE({id: template.templatePublishId});
    },
  },
  async created() {
    this.loading = true;
    if (this.$route.query.templateId) {
      await this.READ_TEMPLATE({id: this.$route.query.templateId});
      this.UPDATE_INSPECTION_DATA({
        ...this.inspection,
        templateId: this.$route.query.templateId
      });
      let publishTemplate = await this.READ_PUBLISH_TEMPLATE({id: this.template.templatePublishId});
      if (publishTemplate.id) {
        this.hasPublishTemplate = true;
      }
    }
    this.loading = false;
  },
};
</script>
