<template>
    <div class="flex items-center justify-between w-full" v-if="this.file || this.default">
        <div class="flex">
            <app-icon v-if="editView" extra-class="cursor-pointer mr-3" icon="paperclip_regular"
                      :width="20"
                      :height="20" :fill="getColor('paperclip')"/>
            <a class="text-primary-500 font-nhu-500 fs-14" :href="fileUrl" target="_blank" :download="fileUrl"
               @load="loaded">
                {{ fileName }}
            </a>
        </div>
        <app-icon v-if="editView" extra-class="cursor-pointer mr-3" icon="trash_regular" :width="20"
                  :height="20"
                  :fill="getColor('trash')" @click="removeFile"/>
        <app-icon v-if="!editView" extra-class="cursor-pointer mr-3" icon="cloud_arrow_down_regular"
                  :width="20" :height="20"
                  :fill="getColor('cloud_arrow_down')" @click="downloadFile"/>


        <!--        <button @click="changePrevFile" v-if="isList" class="absolute top-1/2 left-3 w-[30px] h-[30px] text-white text-center-->
        <!--      transition-all duration-300 ease-out select-none active:transition-none active:shadow-none-->
        <!--      hover:bg-gray-200 active:bg-gray-100 shadow-btn rounded-full bg-gray-400 opacity-50 hover:text-black">-->
        <!--            <ChevronLeftIcon class="w-5 h-5 m-auto"/>-->
        <!--        </button>-->

        <!--        <button @click="changeNextFile" v-if="isList" class="absolute top-1/2 right-3 w-[30px] h-[30px] text-white text-center-->
        <!--      transition-all duration-300 ease-out select-none active:transition-none active:shadow-none-->
        <!--      hover:bg-gray-200 active:bg-gray-100 shadow-btn rounded-full bg-gray-400 opacity-50 hover:text-black">-->
        <!--            <ChevronRightIcon class="w-5 h-5 m-auto"/>-->
        <!--        </button>-->

        <!--        <app-loading :loading="loading" v-if="loading"/>-->
    </div>
</template>

<script>

export default {
  name: "AppFile",
  emits: ['remove'],
  components: {},
  props: {
    file: {
      type: [String, Array, Object],
      default: null
    },
    alt: {
      type: String,
      default: null
    },
    default: {
      type: String,
      default: null
    },
    editView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      index: 0,
    }
  },
  computed: {
    isList() {
      return Array.isArray(this.file) && this.file.length > 1;
    },
    fileUrl() {
      if (this.file !== null) {
        if (Array.isArray(this.file)) {
          this.loaded()
          return process.env.VUE_APP_FILE_URL + this.file[this.index]?.key;
        } else {
          this.loaded()
          return process.env.VUE_APP_FILE_URL + this.file?.key;
        }
      } else
        return this.default;
    },
    fileName() {
      if (this.file !== null) {
        if (Array.isArray(this.file)) {
          this.loaded()
          return this.file[this.index]?.value;
        } else {
          this.loaded()
          return this.file?.value;
        }
      } else
        return this.default;
    },
  },
  methods: {
    loaded() {
      this.loading = false;
    },
    changePrevFile() {
      if (this.index <= 0)
        this.index = this.file.length - 1;
      else
        this.index = this.index - 1;
    },
    changeNextFile() {
      if (this.index >= this.file.length - 1)
        this.index = 0;
      else
        this.index = this.index + 1;
    },
    getColor(icon) {
      return icon === 'trash' ? this.$colors.red[500] : this.$colors.primary[500]
    },
    removeFile() {
      this.$emit('remove')
    },
    downloadFile() {
      let downloadLink = document.createElement('a');
      downloadLink.href = this.fileUrl;
      downloadLink.download = this.fileName;
      window.open(this.fileUrl, '_blank');
    },
  },
};
</script>


<style scoped>

</style>
