import router from "@/router";

const state = {
  VIEW: '',
}

const getters = {
  GET_VIEW: state => {
    const routeName = router.currentRoute.value.matched[1]?.name
    const view = localStorage.getItem('app-crud-' + routeName + '-view')
    return state.VIEW || view || 'list'
  },
}

const mutations = {
  SET_VIEW(state, payload) {
    const routeName = router.currentRoute.value.matched[1]?.name
    if(routeName.toLowerCase().includes("dashboard")){
      localStorage.setItem('app-crud-' + routeName + '-view', "dashboard")
      state.VIEW = "dashboard"
      return
    }
    localStorage.setItem('app-crud-' + routeName + '-view', payload)
    state.VIEW = payload
  },
}

const actions = {
  READ_VIEW(context) {
    let view;

    view = localStorage.getItem('app-crud-' + router.currentRoute.value.matched[1]?.name + '-view') || 'list';

    if(view === 'dual')
      view = 'list'

    context.commit('SET_VIEW', view);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
