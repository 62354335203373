
<script>
import AppCrudList from '@/crud/AppCrudList'

export default {
  name: 'SiteListPage',
  extends: AppCrudList,  
  }
</script>

