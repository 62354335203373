<template>
  <div class="flex gap-y-1 flex-col h-full pt-[41px]">
    <div class="flex border-b mb-8">
      <h1 class="text-2xl font-nhu-500 lh-38 fs-30 text-gray-900 overflow-ellipsis flex items-center">
        <span class="cursor-pointer" @click="goBack"> <ChevronDoubleLeftIcon class="w-8 h-8"/> </span>
        <span class="hidden sm:block">{{ $t(`customize_fields.${recordName}`) }}</span>
      </h1>
    </div>
    <div class="max-w-4xl">
      <template v-if="properties">
        <div v-for="(property, index) in properties" :key="`${property.id}-${index}`">
          <div class="flex justify-between gap-x-3 w-full px-4">
            <div class="flex justify-between gap-x-3 w-full sm:p-3 p-2">
              <div class="flex-1">
                <app-textbox v-model="property.name" :label="$t(`customize_fields.labelName`)"></app-textbox>
              </div>
              <div class="w-64">
                <app-dropdown v-model="property.type" :options="propOptions" :label="$t(`customize_fields.type`)"/>
              </div>
            </div>
            <div class="flex items-center min-w-[50px] justify-center p-4 gap-x-2">
              <app-icon extra-class="fill-primary-500 cursor-pointer mt-4" @click.stop="removeProperty(index)"
                        icon="trash_regular" :width="16" :height="16"/>
            </div>
          </div>
          <div class=" px-8 cursor-pointer flex" @click="seeOptions(index)" v-if="property.type === 'option_set' || property.type === 'option_set_dynamic'">
            <span class="font-nhu-regular fs-12 text-primary-500 hover:text-primary-800 px-1">
              {{ $t('customize_fields.seeOptions') }}
            </span>
            <app-icon v-if="!isPopUpOpen || currentIndex !== index" icon="chevron_down_regular" extra-class="fill-primary-500" :width="12" :height="12"/>
            <app-icon v-else icon="chevron_up_regular" extra-class="fill-primary-500" :width="12" :height="12"/>
          </div>
          <div v-if="isPopUpOpen && currentIndex === index" class="sm:px-8 px-4">
            <div class="flex gap-x-1">
              <app-textbox v-model="optionName" :label="$t(`customize_fields.option`)" @enter="addToOptions" :enter="true"></app-textbox>
              <app-button @click="addToOptions" type="secondary" background="light" :disabled="!optionName"
                          extra-class="font-nhu-500 fs-14 text-white border-primary-500 px-4 py-3 mt-4 rounded-2 border-[1px]">
                {{ $t('add') }}
              </app-button>
            </div>
            <div class="flex">
              <div class="bg-primary-100 w-1 mt-4"/>
              <div class="w-full">
                <div v-for="(option, index) in options" :key="index" class="flex gap-x-1 p-1 m-1">
                  <app-textbox v-model="options[index]" :label="$t(`customize_fields.option`)"></app-textbox>
                  <app-icon extra-class="fill-primary-500 cursor-pointer mt-4" @click.stop="removeOption(index)"
                            icon="trash_regular" :width="16" :height="16"/>
                </div>
              </div>
            </div>

<!--            <div class="flex gap-x-1 py-2 justify-end">
              <app-button @click="isPopUpOpen = false" :clean="true" type="terciary"
                          background="light"
                          extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
                {{ $t('cancel') }}
              </app-button>
              <app-button @click="saveOptions()" type="secondary" background="light" :disabled="options.length === 0"
                          extra-class="font-nhu-500 fs-14 text-white border-primary-500 px-4 py-3 rounded-2 border-[1px]">
                {{ $t('save') }}
              </app-button>
            </div>-->
          </div>
        </div>
      </template>
      <div class="py-1 px-2 cursor-pointer" @click="addProperty">
      <span class="font-nhu-regular fs-12 text-primary-500 hover:text-primary-800">
        +{{ $t('customize_fields.add_field') }}
      </span>
      </div>
      <div class="border-t border-gray-300 mt-4 p-6 h-[95px]">
        <div class="flex items-center gap-x-[9px]">
          <div class="flex-1"></div>
          <app-button @click="save" type="secondary" background="light"
                      extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14">
            {{ $t('save') }}
          </app-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ChevronDoubleLeftIcon} from "@heroicons/vue/24/outline";
import AppTextbox from "@/components/AppTextbox";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";

export default {
  name: "OptionCustomFields",
  components: {AppIcon, AppButton, AppTextbox, ChevronDoubleLeftIcon},
  data() {
    return {
      properties: [],
      currentIndex: -1,
      isPopUpOpen: false,
      options: [],
      optionName: ''
    }
  },
  watch: {
    company: {
      handler(val) {
        if (!val) return
        Object.assign(this.properties, val?.properties[this.recordName])
        //       this.properties = val?.properties[this.recordName]
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({company: 'GET_CURRENT_COMPANY', records: 'GET_RECORDS'}),
    recordName() {
      return this.$route.query.record
    },
    propOptions() {
      return this.records.properties.options.map((option) => ({id: option.id, name: this.$t(option.name)}))
    }
  },
  methods: {
    ...mapActions(['UPDATE_CURRENT_COMPANY']),
    seeOptions(index) {
      this.currentIndex = index
      this.options = this.properties[index].options ?? []
      this.isPopUpOpen = !this.isPopUpOpen
    },
    removeOption(index) {
      this.options.splice(index, 1)
    },
    saveOptions() {
      this.properties[this.currentIndex].options = this.options
      this.optionName = ''
      this.isPopUpOpen = false
    },
    async save() {
      let data = {properties: {}};
      data.properties[this.recordName] = this.properties;
      await this.UPDATE_CURRENT_COMPANY(data)
      this.$router.go(-1)
    },
    addToOptions() {
      this.options.unshift(this.optionName)
      this.optionName = ''
    },
    addOptionsPopUp(index) {
      this.currentIndex = index
      this.options = this.properties[index].options ?? []
      this.isPopUpOpen = true
    },
    addProperty() {
      this.properties ??= []
      this.properties.push({name: '', type: 'string', code: ''})
    },
    removeProperty(index) {
      this.properties.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
