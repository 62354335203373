<template>
  <div class="max-w-[700px] mx-auto">
    <div class="md:col-span-1">
      <div class="px-4 sm:px-0">
        <slot name="header">
        </slot>
        <span class="font-nhu-semi-bold text-[20px]"> {{label}} </span>
        <span class="font-nhu-regular text-[14px]"> {{description}} </span>
      </div>
    </div>
    <div class="md:mt-0 md:col-span-2">
      <div class="mb-4 border shadow-md sm:rounded">
        <div class="px-4 py-5 space-y-2 bg-white sm:p-6">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="flex gap-2">
      <div class="flex-1"></div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppOptionCard",
  components:  {},
  props: {
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
  }
};
</script>
