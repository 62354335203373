<template>
  <div class="flex items-center rounded">
<!--    <div class="mr-8">-->
<!--      <div class="mb-2">-->
<!--        <span class="font-nhu-semi-bold fs-14">{{ $t('records.workorders.fields.status') }}</span>-->
<!--      </div>-->

<!--      <span class="bg-blue-50 flex items-center px-3 py-[2px] rounded-full border border-blue-100">-->
<!--        {{ workOrder.status ? $t(workOrder.status) : '...' }}-->
<!--      </span>-->
<!--    </div>-->

    <div class="flex fs-14 font-nhu-regular min-w-[250px]" v-if="workOrder">
      <div v-if="isVisible('pending', 'start')">
        <app-button @click="setStatus('inProgress')" type="secondary" background="light" :icon="PlayIcon">
            {{ $t('workorder.actions.start') }}
        </app-button>
      </div>
      <div v-if="isVisible('inProgress', 'pause')">
        <app-button @click="setStatus('onHold')" type="secondary" background="light" :icon="PauseIcon">
            {{ $t('workorder.actions.pause') }}
        </app-button>
      </div>
      <div v-if="isVisible('onHold', 'restart')" class="flex flex-col items-center justify-center">
        <app-button @click="setStatus('inProgress')" type="secondary" background="light" :icon="PauseIcon">
            {{ $t('workorder.actions.restart') }}
        </app-button>
      </div>
      <div v-if="isVisible('inProgress', 'finish') || isVisible('finish')" class="flex flex-col items-center justify-center ml-8">
        <app-button @click="setStatus('done')" type="secondary" background="light" :icon="StopIcon" :disabled="!workOrder.isChecked || trackingWarning">
            {{ $t('workorder.actions.finish') }}
        </app-button>
      </div>
      <div v-if="isVisible('done', 'reopen')" class="flex flex-col">
        <app-button @click="setStatus('inProgress')" type="secondary" background="light" :icon="PlayPauseIcon">
            {{ $t('workorder.actions.reopen') }}
        </app-button>
      </div>
    </div>

    <!--

    <div class="border-l-4 mt-1 py-2 mb-4 w-full" :class="getStatusColor(workOrder.status)" v-if="workOrder && workOrder.status">
      <div class="flex">
        <div class="ml-3">
          <h3 class="font-nhu-semi-bold text-gray-500 fs-14" :class="getStatusColor(workOrder.status)">{{ $t('workorders.view.'+ workOrder.status) }}</h3>
          <div class="mt-2">
            <span class="font-nhu-regular text-gray-900 fs-14" v-html="$t('workorders.view.info.'+ workOrder.status)"/>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

<!--  <div class="rounded-md bg-yellow-50 mt-4 p-4 mb-4" v-if="!workOrder.isChecked">-->
<!--    <div class="flex">-->
<!--      <div class="flex-shrink-0">-->
<!--        <LockOpenIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />-->
<!--      </div>-->
<!--      <div class="ml-3">-->
<!--        <h3 class="text-sm font-medium text-yellow-800">{{ $t('workorder.checklist.warning.title') }}</h3>-->
<!--        <div class="mt-2 text-sm text-yellow-700">-->
<!--          <p>{{ $t('workorder.checklist.warning.description') }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="rounded-md bg-yellow-50 mt-4 p-4 mb-4" v-if="trackingWarning">-->
<!--    <div class="flex">-->
<!--      <div class="flex-shrink-0">-->
<!--        <LockOpenIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />-->
<!--      </div>-->
<!--      <div class="ml-3">-->
<!--        <h3 class="text-sm font-medium text-yellow-800">{{ $t('workorder.checklist.costAndTimeTracking.title') }}</h3>-->
<!--        <div class="mt-2 text-sm text-yellow-700">-->
<!--          <p>{{ $t('workorder.checklist.costAndTimeTracking.description') }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PlayIcon, PauseIcon, StopIcon, PlayPauseIcon } from '@heroicons/vue/24/outline'

export default {
  name: "WorkOrderStatus",
  components: {},
  emits: ['updated', 'newStatus'],
  props: {
    id: { type: String, default: null },
    workOrderId: { type: String },
    type: { type: String, default: 'text' },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    error: { type: String, default: "" },
    statusOptions: { type: Array, default: () => [] },
    currentStatus: { type: String, default: '' },
  },
  data() {
    return {
      PlayIcon, PauseIcon, StopIcon, PlayPauseIcon,
      newStatusId: '',
      saving: false,
      // trackingWarning: false
    }
  },
  computed: {
    ...mapGetters({ workOrder: 'GET_WORKORDER', company: 'GET_CURRENT_COMPANY' }),
    resolvedStatus() {
      return this.statusOptions.length > 0 ? this.statusOptions : this.defaultStatus;
    },
    trackingWarning() {
      var costTrackingSuccess = true
      var timeTrackingSuccess = true
      var canFinalizeOrder = true

      if (this.company?.workOrderSettings.costTrackingEnabled
        && this.company?.workOrderSettings.costTrackingRequired
        && !this.workOrder?.parts?.length)
        costTrackingSuccess = false

      if (this.company?.workOrderSettings.timeTrackingEnabled
        && this.company?.workOrderSettings.timeTrackingRequired
        && !this.workOrder?.resources?.length)
        timeTrackingSuccess = false

      if (!costTrackingSuccess || !timeTrackingSuccess)
        canFinalizeOrder = false

      return !canFinalizeOrder
    },
  },
  methods: {
    ...mapActions(['CHANGE_STATUS_WORKORDER', 'READ_WORKORDER_BY_ID']),
    checkTrackingWarning() {
      var costTrackingSuccess = true
      var timeTrackingSuccess = true
      var canFinalizeOrder = true

      if (this.company.workOrderSettings.costTrackingEnabled
        && this.company.workOrderSettings.costTrackingRequired
        && !this.workOrder.parts?.length)
        costTrackingSuccess = false

      if (this.company.workOrderSettings.timeTrackingEnabled
        && this.company.workOrderSettings.timeTrackingRequired
        && !this.workOrder.resources?.length)
        timeTrackingSuccess = false

      if (!costTrackingSuccess || !timeTrackingSuccess)
        canFinalizeOrder = false

      if (!canFinalizeOrder) {
        this.trackingWarning = true
      }
    },
    isVisible(status, action) {
      return this.workOrder.status === status && this.$hasPermission('workorder_' + action);
    },
    async setStatus(statusId) {
      if (this.workOrder.status === '' || this.saving || statusId === this.workOrder.status || (statusId === 'done' && !this.workOrder.isChecked))
        return;

      this.newStatusId = statusId;
      this.$emit('newStatus', statusId)
      this.saving = true;
      const workOrder = await this.CHANGE_STATUS_WORKORDER({ id: this.workOrder.id, status: statusId })
      this.$emit('updated', workOrder)
      this.saving = false;
    },
    getStyle() {
      let baseStyle = {
        borderRadius: '5%',
        width: '100px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
      return baseStyle;
    },
    getStatusColor(status) {
      switch (status) {
        case 'pending':
          return 'border-gray-500 bg-gray-50 text-gray-500'
        case 'inProgress':
          return 'border-green-500 bg-green-50 text-green-500';
        case 'onHold':
          return 'border-yellow-500 bg-yellow-50 text-yellow-500';
        case 'done':
          return 'border-green-500 bg-green-50 text-green-500';
        default:
          return 'border-gray-500';
      }
    },
  }
}
</script>

<style scoped></style>
