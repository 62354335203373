import API from '@/api'

const state = {
  INSPECTION: {
    templatePublishId: '',
    status: null,
    score: 0,
    totalScore: 0,
    itemsCompleted: 0,
    location: '',
    isArchive: false,
    results: [],
    templateId: '',
  },
  INSPECTIONS: { data:[] },
  ALL_INSPECTIONS: [],
}

const getters = {
  GET_INSPECTION: state => state.INSPECTION,
  GET_INSPECTIONS: state => state.INSPECTIONS,
  GET_ALL_INSPECTIONS: state => state.ALL_INSPECTIONS,
}

const mutations = {
  // INSPECTIONS
  SET_INSPECTION(state, payload) {
    state.INSPECTION = payload
  },
  SET_INSPECTIONS(state, payload) {
    state.INSPECTIONS = payload
  },
  SET_ALL_INSPECTIONS(state, payload){
    state.ALL_INSPECTIONS = payload
  },
  CREATE_INSPECTION(state, payload) {
    state.INSPECTION = payload
    state.INSPECTIONS.data.push(payload)
  },
  UPDATE_INSPECTION_DATA(state, payload) {
    if (state.INSPECTION !== null && state.INSPECTION.id === payload.id)
      state.INSPECTION = payload;

    const index = state.INSPECTIONS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.INSPECTIONS.data[index], payload)
  },
  DELETE_INSPECTION(state, payload) {
    if (state.INSPECTION?.id === payload.id)
      state.INSPECTION = {}
    state.INSPECTIONS.data = state.INSPECTIONS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD INSPECTIONS
  async READ_INSPECTIONS(context, payload) {
    const response = await API.Get('/api/inspections', payload)
    if (response.type === 'Success') {
      context.commit('SET_INSPECTIONS', response.res.data)
      return response.res.data
    }
    context.commit('SET_INSPECTIONS', [])
  },
  async READ_ALL_INSPECTIONS (context) {
    const response = await API.Get('/api/options/inspections')
    if(response.type === 'Success')
      context.commit('SET_ALL_INSPECTIONS', response.res.data)
    else
      context.commit('SET_ALL_INSPECTIONS', [])
  },
  async CREATE_INSPECTION (context){
    context.commit('SET_INSPECTION', { status: null, score: 0, totalScore: 0, itemsCompleted: 0, location: '', owner: '', isArchive: false, templateId: '', results: [] })
  },
  async SAVE_INSPECTION (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/inspections', payload)
      if(response.type === 'Success'){
        context.commit('SET_INSPECTION', response.res.data)
        return response.res.data;
      }
    }
    else
    {
      const response = await API.Put(`/api/inspections/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_INSPECTION_DATA', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_INSPECTION(context, payload) {
    const response = await API.Get(`/api/inspections/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_INSPECTION', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_INSPECTION(context, payload) {
    const response = await API.Patch(`/api/inspections/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_INSPECTION_DATA', response.res.data)
      return response.res.data
    }
  },
  async DELETE_INSPECTION(context, payload) {
    const response = await API.Delete(`/api/inspections/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_INSPECTION', response.res.data)
      return
    }
  },
  async ARCHIVE_INSPECTION(context, payload) {
    const response = await API.Post(`/api/inspections/${payload.id}/archive`)
    if (response.type === 'Success') {
      context.commit('UPDATE_INSPECTION_DATA', response.res.data)
      return
    }
  },
  async UNARCHIVE_INSPECTION(context, payload) {
    const response = await API.Post(`/api/inspections/${payload.id}/unarchive`)
    if (response.type === 'Success') {
      context.commit('UPDATE_INSPECTION_DATA', response.res.data)
      return
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
