import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
  TEAM: {},
  TEAMS: [],
  ALL_TEAMS: []
}

const getters = {
  GET_TEAM: state => state.TEAM,
  GET_TEAMS: state => state.TEAMS,
  GET_ALL_TEAMS: state => state.ALL_TEAMS,
  GET_TEAM_BY_ID: state => id => {
    if(state.ALL_TEAMS.length === 0)
      return null
    return state.ALL_TEAMS.find(e => e.id === id)
  },
  GET_TEAMS_BY_IDS: state => ids => {
    return state.ALL_TEAMS.filter(e => ids.includes(e.id))
  },
}

const mutations = {
  SET_TEAM(state, payload) {
    state.TEAM = payload
  },
  SET_TEAMS(state, payload) {
    state.TEAMS = payload
  },
  SET_ALL_TEAMS(state, payload){
    state.ALL_TEAMS = payload
  },
  CREATE_TEAM(state, payload) {
    state.TEAM = payload
    state.TEAMS.data.push(payload)
  },
  UPDATE_TEAM(state, payload) {
    const index = state.TEAMS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.TEAMS.data[index], payload)

    if (state.TEAM !== null && state.TEAM.id === payload.id)
      state.TEAM = payload;
  },
  DELETE_TEAM(state, payload) {
    if (state.TEAM?.id === payload.id)
      state.TEAM = {}
    state.TEAMS.data = state.TEAMS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_TEAMS(context, payload) {
    const response = await API.Get('/api/teams', payload)
    if (response.type === 'Success') {
      context.commit('SET_TEAMS', response.res.data)
      return response.res.data
    }
    context.commit('SET_TEAMS', [])
  },
  async READ_ALL_TEAMS (context) {
    const response = await API.Get('/api/options/teams')
    if(response.type === 'Success')
      context.commit('SET_ALL_TEAMS', response.res.data)
    else
      context.commit('SET_ALL_TEAMS', [])
  },
  async CREATE_TEAM(context) {
    context.commit('SET_TEAM', {name: '', description: '', images: [], color: '', members: [], supervisors: []})
  },
  async SAVE_TEAM(context, payload) {
    if(payload.id !== undefined)
    {
      const response = await API.Put(`/api/teams/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_TEAM', response.res.data)
        return response.res.data
      }
    }
    else
    {
      const response = await API.Post('/api/teams', payload)
      if (response.type === 'Success') {
        return response.res.data
      }
    }
  },
  async READ_TEAM(context, payload) {
    const response = await API.Get(`/api/teams/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_TEAM', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_TEAM(context, payload) {
    const response = await API.Put(`/api/teams/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_TEAM', response.res.data)
      return response.res.data
    }
  },
  async DELETE_TEAM(context, payload) {
    const response = await API.Delete(`/api/teams/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_TEAM', payload)
      context.commit('TOAST_MESSAGE', {
        type: 'success',
        title: i18n.global.t('teams.teamDelete.title'),
        message: i18n.global.t('teams.teamDelete.message')
      })
      return response.res.status
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
