<template>
  <div>
    <div class="flex items-center mb-1 fill-primary-500">
      <app-icon extra-class="mr-2" icon="timer_regular" :width="14" :height="14" />
      <span class="font-nhu-semi-bold fs-14">
        {{ $t('workorders.resources') }}
      </span>
    </div>
    <div>
      <div v-if="action !== 'view'">
        <app-user-selector value="id" :only-users="true" :other-field="true" @update:model-value="updateValue"
          :placeholder="$t('workorders.resourcePlaceholder')" />
      </div>
      <ul role="list" class="flex flex-col overflow-auto gap-x-2 gap-y-1 xl:gap-x-4 w-full h-full">
        <li v-for="resource in internalValue" :key="resource">
          <div
            class="flex overflow-hidden group aspect-w-10 aspect-h-7 justify-between">
            <div class="my-2 flex items-center">
              <div class="fill-red-500">
                <user-avatar :user="getUser(resource.userId)" width="w-10" height="h-10"></user-avatar>
              </div>
              <div class="w-full flex items-center justify-between">
                <div class="flex flex-col mx-2">
                  <span class="font-nhu-semi-bold fs-14">{{ getUser(resource.userId)?.name }}</span>
                  <span class="font-nhu-500 fs-14 text-gray-500">{{ getUser(resource.userId)?.role }}</span>
                </div>
              </div>
            </div>
            <div class="flex items-center font-nhu-regular text-gray-900 fs-14" v-if="action === 'view'">
              {{ resource.workedHours }}
              {{ resource.workedHours !== 1 ? $t('records.teams.fields.hours') : $t('records.teams.fields.hour') }}
            </div>
            <div class="flex items-center justify-end font-nhu-regular fs-14 w-[180px]" v-else>
              <div class="-mt-4">
                <app-textbox v-model="resource.workedHours" :label="$t('records.teams.fields.hours')" type="number" />
              </div>
              <app-icon icon="trash_regular" extra-class="pl-2 fill-red-500 cursor-pointer"
                @click="removeResource(resource)" :width="20" :height="20" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import UserAvatar from "@/components/UserAvatar";
import { mapGetters } from "vuex";

export default {
  name: "WorkOrderResources",
  emits: ['update:modelValue'],
  components: { UserAvatar },
  props: {
    label: { type: String, default: "" },
    modelValue: { type: Array, default: () => [] }
  },
  data() {
    return {
      user: {}
    }
  },
  computed: {
    ...mapGetters({ records: 'GET_RECORDS' }),
    action() {
      return this.$route.meta.action;
    },
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    addResource(resource) {
      if (resource) {
        let newList = [...this.internalValue];
        newList.push({ userId: resource, workedHours: 0 });
        this.internalValue = newList;
      }
    },
    updateValue(value) {
      if (value && value.length > 0) {
        // Si ya se encuentra contenido, no hago nada
        if (!this.internalValue.find(x => x.userId === value[value.length - 1])) {
          this.addResource(value[value.length - 1]);
        }
      }
    },
    removeResource(resource) {
      this.internalValue = this.internalValue.filter(x => x !== resource);
    },
    getUser(userId) {
      this.user = this.$store.getters['GET_USER_BY_ID'](userId);
      return this.user;
    },
  }
}
</script>

<style scoped></style>
