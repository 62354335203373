<template>
  <div class="h-full flex flex-col">
    <div class="border-b rounded-t-[8px] border-gray-100 h-[95px] flex min-h-[95px]"
      :class="[isMobile ? 'mt-[20px] p-6' : 'p-6', extraClass ? extraClass : 'bg-white']">
      <div class="flex flex-col justify-center flex-1 overflow-hidden" :class="!isMobile ? 'max-w-[95%]' : ''">
        <div v-if="record.hasNumber && !(action === 'create')">
          <span class="text-xs font-nhu-regular text-gray-500">{{ $t('WO') }}-{{ item.number }}</span>
        </div>
        <div v-if="action === 'create'">
          <!--          <span class="text-lg font-nhu-semi-bold">{{ $t('records.' + record.name + '.createTitle') }}</span>-->
          <span class="text-lg font-nhu-semi-bold">{{ $getRecordLabel(this.record.name) ? $t('create') + ' ' +
        $getRecordLabel(this.record.name) : $t('records.' + record.name + '.createTitle') }}</span>
        </div>
        <div v-else class="my-auto">
          <div class="flex items-center">
            <div class="fill-primary-500 cursor-pointer" v-if="backeable" @click="goBack">
              <app-icon icon="chevron_left_regular" :width="16" :height="16"></app-icon>
            </div>
            <div v-if="user" class="mr-2">
              <user-avatar width="w-10" height="h-10" :user="user"></user-avatar>
            </div>
            <div class="text-lg font-nhu-semi-bold whitespace-nowrap">{{ title ?? item?.name }}</div>
          </div>
          <div>
            <span class="fs-14 font-nhu-regular text-gray-500">{{ parentName }}</span>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-x-[1rem]">
        <div class="flex-1"></div>
          <!--
        <template v-if="!isMobile">
          <app-button v-if="action === 'view' && (view === 'dual' || view === 'kanban')" @click="doEdit" :clean="true"
            type="terciary" background="light" :permission="'edit_' + record.name"
            extra-class="border-primary-500 text-primary-500 border-[1px] !px-3 shadow-sm shadow-inner font-nhu-500 fs-14 rounded-lg">
            {{ $t('edit') }}
          </app-button>
        </template>
        <template v-else>

          <div v-if="action === 'view'" class="px-4 absolute bottom-4 right-0 w-full z-50">
            <app-button class="font-nhu-regular w-full h-[44px]" extra-class="flex items-center rounded-[8px]"
              @click="doEdit" type="primary" background="light">
              <span>{{ $t('records.' + record.name + '.edit') }}</span>
            </app-button>
          </div>
        </template>
          -->
          <span @click="doShare" class="material-symbols-outlined cursor-pointer"> ios_share </span>
          <Menu as="div" class="relative inline-block text-left" v-if="!(action === 'create')">
          <MenuButton class="flex items-center"
            :class="!isMobile && view === 'list' || view === 'calendar' || view === 'kanban' ? 'mr-10' : ''">
            <app-icon icon="ellipsis_vertical_regular" :width="20" :height="20" :fill="getColor" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="absolute right-0 z-10 mt-4 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-auto whitespace-nowrap min-w-[150px]">
              <div class="py-1">
                
                <MenuItem v-if="action === 'view' && $hasPermission('edit_' + record.name)"
                  @click="doEdit" class="cursor-pointer" v-slot="{ active }">
                <div
                  :class="[active ? 'bg-primary-50 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm items-center ',]">
                  <app-icon icon="pencil_regular" width="14" height="14" />
                  <span class="font-nhu-regular pl-[2px]">
                    {{ $t('edit') }}
                  </span>
                </div>
                </MenuItem>

                <MenuItem v-for="action in record.views.dual.actions" :key="action.function" class="cursor-pointer"
                  :disabled="!((!action.permission || $hasPermission(action.permission + '_' + record.name)) && conditionsSatisfied(action))"
                  v-slot="{ active }" @click="callToFunction(action)">
                <div :class="[
        active ? 'bg-primary-50 text-gray-900' : 'text-gray-700',
        'flex px-4 py-2 text-sm items-center ' + 'fill-' + action.color + '-500',
        action.text === $t('delete') ? 'border-t border-red-500 text-red-500' : '',
        (!action.permission || $hasPermission(action.permission + '_' + record.name)) && conditionsSatisfied(action) ? '' : 'opacity-50 !cursor-not-allowed',
      ]">
                  <app-icon :icon="action.icon" width="14" height="14" />
                  <span class="font-nhu-regular pl-[2px]">
                    {{ $t('records.' + record.name + '.actions.' + action.function) }}
                  </span>
                </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <app-loading :loading="loading || saving" />
    <div class="bg-white p-[24px] overflow-y-auto h-full rounded-b-[8px]" :class="isMobile ? 'pb-[60px]' : ''"
      :key="action">
      <slot v-if="!loading" :lock="isEditOrCreate" :action="action" :item="item" :errors="errors"></slot>
    </div>
    <div v-if="action !== 'view' && (view === 'dual' || view === 'kanban')"
      class="border-t rounded-b-[8px] border-gray-300 p-6 h-[95px] flex justify-end"
      :class="extraClass ? extraClass : ''">
      <div :class="!isMobile ? 'flex items-center gap-x-[1rem]' : 'w-full flex justify-end'">
        <div class="flex-1"></div>

        <div id="footer"></div>

        <slot name="footer" :lock="isEditOrCreate" :action="action" :item="item" :errors="errors"></slot>

        <app-button v-if="showCancelButton" @click="doCancel" :clean="true" type="terciary" background="light"
          extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
          {{ $t('cancel') }}
        </app-button>

        <app-button v-if="showCreateButton" @click="doSave"
          extra-class="font-nhu-500 fs-14 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner"
          :class="isMobile ? 'w-full' : ''" type="secondary" background="light">
          {{ isMobile ? $t('records.' + record.name + '.create') : $t('create') }}
        </app-button>

        <app-button v-if="showSaveButton" @click="doSave" type="secondary" background="light"
          extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
          {{ $t('save') }}
        </app-button>
      </div>
    </div>

    <div v-if="view === 'list' || view === 'calendar' || view === 'dashboard'"
      class="border-t border-gray-300 p-6 h-[95px]" :class="extraClass ? extraClass : ''">
      <div class="flex items-center gap-x-[9px]">
        <div class="flex-1"></div>

        <div id="footer"></div>
        <slot name="footer" :lock="isEditOrCreate" :action="action" :item="item"></slot>

        <app-button v-if="action === 'edit' || action === 'create'" @click="doCancel" :clean="true" type="terciary"
          background="light"
          extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
          {{ $t('cancel') }}
        </app-button>

        <app-button v-if="action === 'edit' || action === 'create'" @click="doSave" type="secondary" background="light"
          :saving="saving"
          extra-class="font-nhu-500 fs-14 text-white border-primary-500 bg-primary-500 px-4 py-3 rounded-2 border-[1px]">
          {{ $t('save') }}
        </app-button>

        <!--
        <app-button v-if="action === 'view' && !isMobile" @click="doCancel" :clean="true" type="terciary"
                    background="light"
                    extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
          {{ $t('close') }}
        </app-button>
        -->

        <!--

        <app-button v-if="action === 'view' && !isMobile && $hasPermission('edit_' + record.name)" @click="doEdit"
          type="secondary" background="light"
          extra-class="font-nhu-500 fs-14 text-white border-primary-500 bg-primary-500 px-4 py-3 rounded-2 border-[1px]">
          {{ $t('edit') }}
        </app-button>
        -->
      </div>
    </div>
    <!-- For action confirmations -->
    <app-confirm-dialog ref="confirm" />
  </div>
</template>

<script>
import CRUD from '@/crud'
import emitter from 'tiny-emitter/instance'
import { mapGetters } from "vuex";
import { Menu, MenuItems, MenuItem, MenuButton } from '@headlessui/vue'
import AppButton from "@/components/AppButton";
import UserAvatar from "@/components/UserAvatar.vue";
// import recordLabels from "@/global/recordLabels";
// import store from "@/store";

export default {
  name: "AppCrud",
  components: { UserAvatar, AppButton, Menu, MenuItems, MenuItem, MenuButton, },
  emits: ['create', 'read', 'save', 'edit', 'validate', 'cancel', 'exportToPDF'],
  props: {
    title: { type: String, default: null },
    extraClass: { type: String, default: '' },
    user: { type: Object, default: null },
    parentName: { type: String, default: '' },
    saveAction: { type: String, default: null },
    reload: { type: Boolean, default: true },
  },
  data() {
    return {
      loading: false,
      saving: false,
      changed: false,
      errors: {},
      recordLabelNoun: 'singular',
      createRecordTitle: ''
    }
  },
  watch: {
    item: {
      deep: true,
      handler() {
        if (!this.saving) {
          this.changed = true;
        }
      },
    }
  },
  computed: {
    ...mapGetters({ view: 'GET_VIEW', record: 'GET_RECORD', records: 'GET_RECORDS', workOrder: 'GET_WORKORDER' }),
    // ...mapActions(['EXPORT_WORKORDER_XLSX']),
    backeable() {
      return this.$route.query.back;
    },
    action() {
      return this.$route.meta.action;
    },
    isMobile() {
      return this.$isMobile()
    },
    item() {
      return this.$store.getters['GET_' + this.record.id];
    },
    isEditOrCreate() {
      return !(this.action === 'edit' || this.action === 'create')
    },
    showCancelButton() {
      return this.action === 'edit' || (this.action === 'create' && !this.isMobile);
    },
    showCreateButton() {
      if (this.isMobile && this.item.name) {
        return this.action === 'create';
      } else if (!this.isMobile) {
        return this.action === 'create';
      } else {
        return false;
      }
    },
    showSaveButton() {
      return this.action === 'edit';
    },
  },
  async created() {
    emitter.on('changeToView', this.changeToView);
    this.loading = true;
    if (this.action === 'create') {
      if (this.$route.query?.duplicateId) {
        let item = await this.$store.dispatch('READ_' + this.record.id, { id: this.$route.query.duplicateId })
        delete item.id;
        delete item.qrCode;
        this.$store.commit('SET_' + this.record.id, item);
      } else if (this.$route.query?.assignTo) {
        let item = this.$store.getters['GET_' + this.record.id];
        this.$store.commit('SET_' + this.record.id, item);
      } else if (!this.record.custom) {
        await this.$store.dispatch('CREATE_' + this.record.id, this.$route.query)
      }

      await this.$emit('create', { onSuccess: () => { }, onError: () => { } });
      this.loading = false;
    } else {
      await this.$store.dispatch('READ_' + this.record.id, { id: this.$route.params.id })
      await this.$emit('read', { onSuccess: () => { }, onError: () => { } });
      this.loading = false;
    }
    this.changed = false;
  },
  methods: {
    async doShare(){
      try {
      await navigator.share({ title: this.item?.name, url: window.location.origin + CRUD.getRecordViewUrl(this.record, this.item.id) });
        console.log("Data was shared successfully");
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    },
    conditionsSatisfied(action) {
      let response = true;
      if (!action.conditions) return response
      for (const condition of action.conditions) {
        if (!condition.func(this.item)) {
          response = false;
          break;
        }
      }
      return response;
    },
    goBack() {
      this.$router.go(-1);
    },
    async callToFunction(func) {
      if (func.function === 'copy_link') {
        const baseUrl = window.location.origin + CRUD.getRecordViewUrl(this.record, this.item.id);
        await navigator.clipboard.writeText(baseUrl);
        this.$store.commit('TOAST_MESSAGE', {
          type: 'success',
          title: this.$t(`records.${this.record.name}.actions.messages.copy_link_success`),
          message: this.$t(`records.${this.record.name}.actions.messages.copy_link_message`),
        })
      } else if (func.function === 'reopen') {
        this.saving = true;
        await this.$store.dispatch('REOPEN_' + this.record.id, this.item);
        emitter.emit('updateList');
        this.$router.push(CRUD.getRecordListUrl(this.record));
        this.saving = false;
      } else if (func.function === 'delete') {
        let result = await this.$refs.confirm.open("", this.$t(`${this.record.name}.sureToDelete`), "", "yesDelete")
        if (result) {
          this.saving = true;
          await this.$store.dispatch('DELETE_' + this.record.id, { id: this.item.id });
          emitter.emit('updateList');
          await this.$store.dispatch(this.record.options.action);
          this.$router.push(CRUD.getRecordListUrl(this.record));
          this.saving = false;
        }
      } else if (func.function === 'duplicate') {
        this.$router.push(CRUD.getRecordCreateUrl(this.record, 'duplicateId=' + this.item.id));
      } else if (func.function === 'exportToPDF') {
        let options = this.record?.views?.dual?.actions?.find(x => x.function === 'exportToPDF')?.options
        this.$store.commit('SET_EXPORT_OPTIONS', options);
        this.$emit('exportToPDF')
        this.closeParent();
      } else if (func.function === 'assignToWorkOrder') {
        this.saving = true;
        this.$store.dispatch('CREATE_WORKORDER');
        let workOrder = { ...this.workOrder };
        if (func.multiple) {
          workOrder[func.field] = [this.item.id]
        } else {
          if (func.object) {
            workOrder[func.field][0].id = this.item.id
          } else {
            workOrder[func.field] = this.item.id
          }
        }
        this.$store.commit('SET_WORKORDER', workOrder);
        this.$router.push('/workorders/create?assignTo=' + this.item.id);
      } else if (func.function === 'loginCompany') {
        this.$store.dispatch('LOGIN_COMPANY', { id: this.item.id });
      } else if (func.function === 'goToCreate') {
        this.$router.push('/' + this.record?.redirectTo?.to + '/create?' + this.record?.redirectTo?.id + '=' + this.item.id);
      } else {
        this.$emit(func.function)
      }
    },
    async doEdit() {
      this.$emit('edit');
      this.$router.push(CRUD.getRecordEditUrl(this.record, this.$route.params.id));
    },
    doCancel() {
      if ((this.view === "list" || this.view === 'calendar' || this.view === 'kanban') && this.action !== 'edit') {
        if (this.backeable) {
          this.$router.go(-1)
        } else {
          this.$router.push(CRUD.getRecordListUrl(this.record, this.$route.params.id));
        }
      } else if (this.view === "dual" && this.$route.query?.duplicate) {
        this.$router.push(CRUD.getRecordViewUrl(this.record, this.$route.query?.duplicate));
      } else {
        this.$router.push(CRUD.getRecordViewUrl(this.record, this.$route.params.id));
      }
    },
    async doSave() {
      let Validated = false;
      this.saving = true;
      this.changed = false;
      const methods = {
        item: this.item, onSuccess: () => {
          Validated = true;
        }, onError: (errors) => {          
          if(errors)
            this.errors[errors.id] = errors;

          this.saving = false;
        }
      };

      this.$emit('validate', methods)
      if (this.saving === true)
        methods.onSuccess();

      if (Validated) {
        if (this.saveAction !== 'none')
          await this.$store.dispatch(this.saveAction ?? 'SAVE_' + this.record.id, this.item);
        if (this.record?.options?.action)
          await this.$store.dispatch(this.record.options.action)

        await this.$store.dispatch('SAVE_PROPS', { record: this.record.id });
        this.saving = false;
        if (this.reload)
          emitter.emit('updateList');
        const url = CRUD.getRecordViewUrl(this.record, this.$route.params.id);
        this.$router.push(url);
      } else {
        var errorKeys = Object.keys(this.errors);
        if(errorKeys) {
          document.getElementById(errorKeys[0])?.focus();
        }
      }
    },
    closeParent() {
      if (this.backeable)
        this.goBack();
      else
        emitter.emit('closePopup')
    },
    getColor() {
      return this.$colors.primary[500]
    },
    async beforeRouteLeave(to) {
      if (!this.changed) return true;

      if (this.action !== 'create' && this.action !== 'edit')
        return true;

      const msg = this.$t('cancelConfirmMsg')
      const result = await this.$refs.confirm.open("", msg, "", "yesCancel")
      if (!result) return false

      this.$router.currentRoute.value = to
      this.$router.currentRoute.href = to.path
      return true
    },
  },
  beforeUnmount() {
    emitter.off('changeToView', this.changeToView);
  },
};
</script>
