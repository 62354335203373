<template>
    <div>
        <app-breadcrumbs :pages="pages"/>
        <div class="h-4"/>

        <div class="flex gap-2">
            <app-button @click="doCreate" type="primary" background="light" :icon="PlusIcon">
                {{ $t('admin.actions.create') }}
            </app-button>
            <app-button @click="doDelete" :disabled="selection.length === 0">
              {{$t('admin.actions.delete') }}
            </app-button>
            <app-button @click="editAction"> {{ $t('admin.actions.configuration') }}</app-button>
            <div class="my-auto"> {{ message }}</div>
        </div>

        <app-table ref="list" v-model:itemsSelected="selection" :multi="true" value="id" url="/api/actions"
                   :columns="columns">
            <template #item-name="item">
                <div @click="doEdit(item)" class="cursor-pointer underline">
                    {{ item.name }}
                </div>
            </template>
            <template #item-dueDateTime="item">
                {{ getDate(item.dueDateTime) }}
            </template>
            <template #item-priority="item">
                {{ getPriority(item.priority) }}
            </template>
            <template #item-status="item">
                {{ getStatus(item.status) }}
            </template>
        </app-table>
    </div>

    <new-action ref="popup" @reload="reload"/>

    <app-confirm-popup v-model:open="showDeletePopUp" @onYes="doDelete2"
                       :message="$t('admin.actions.sureToDelete')"/>
</template>

<script>
import {DocumentPlusIcon, PlusIcon} from "@heroicons/vue/24/outline";
import {mapActions} from "vuex";
import NewAction from "@/pages/admin/actions/NewAction.vue";

export default {
  name: "ActionListPage",
  components: {NewAction},
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      ...{PlusIcon, DocumentPlusIcon},
      message: '',
      selection: [],
      showDeletePopUp: false,
      columns: [
        {value: 'name', text: this.$t('admin.actions.name'), sortable: true},
        {value: 'description', text: this.$t('admin.actions.description'), sortable: true},
        {value: 'priority', text: this.$t('admin.actions.priority'), sortable: true},
        {value: 'site', text: this.$t('admin.actions.site'), sortable: true},
        {value: 'asset', text: this.$t('admin.actions.asset'), sortable: true},
        {value: 'dueDateTime', text: this.$t('admin.actions.dueDateTime'), sortable: true},
        {value: 'status', text: this.$t('admin.actions.status.name'), sortable: true},
      ],
    }
  },
  computed: {
    pages() {
      return [
        {name: this.$t('admin.actions.title'), href: '/actions'},
      ]
    },
  },
  methods: {
    ...mapActions(['DELETE_ACTION_LIST']),

    async reload() {
      await this.$refs.list.load();
    },
    getPriority(label) {
      return this.$t('admin.actions.priorities.' + label);
    },
    doCreate() {
      this.$refs.popup.create();
    },
    doEdit(item) {
      this.$refs.popup.edit(item.id);
    },
    doDelete() {
      this.showDeletePopUp = true;
    },
    async doDelete2() {
      let actions = this.selection;
      if (await this.DELETE_ACTION_LIST({deletes: actions})) {
        if (this.selection.length === actions.length) {
          this.selection = [];
        }
        this.reload();
        this.message = this.$t('admin.actions.deleted');
      }
    },
    getDate(value) {
      if (value)
        return new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString();

      return "";
    },
    editAction() {
      this.$router.push('/actions/edit');
    },
    getStatus(status) {
        return this.$t('admin.actions.status.' + status)
    }
  },
  async created() {
  },
}
</script>

<style scoped>

</style>
