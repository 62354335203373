<template>
  <div class="max-w-screen-md mx-auto my-10 pb-5 bg-white rounded-xl shadow shadow-slate-300 flex flex-col justify-center items-center space-y-20 ">
    <div class="flex items-center flex-shrink-0 h-16 px-4 align-middle">
      <img class="h-16 mt-8" src="/images/zigo.png" alt="Workflow"/>
    </div>
    <h1 class="text-4xl font-medium center">{{$t('completeYourProfile')}}</h1>

    <app-form-field id="new_name" :label="$t('options.account.yourName')" class="w-1/2">
      <app-textbox id="new_name" v-model="name" placeholder="Jhon Doe" :autocomplete="false"/>
    </app-form-field>

    <app-form-field id="new_password" :label="$t('enterYourPassword')" class="w-1/2">
      <app-textbox id="new_password" v-model="password" :placeholder="$t('enterYourPassword')" type="password" :autocomplete="false"/>
    </app-form-field>


    <app-button @click="onSubmit"> {{$t('activate')}} </app-button>


  </div>

</template>


<script>
  import { mapActions} from 'vuex'

  export default {
    name: 'MailValidationPage',
    data() {
      return {
        name: '',
        password: '',
      }
    },

    computed: {
    },
    methods: {
      ...mapActions(['VALIDATE_MAIL', 'SET_REDIRECT_AFTER_LOGIN']),
      /* eslint-disable-next-line no-unused-vars */
      async onSubmit() {
        if (await this.VALIDATE_MAIL({ code: this.$route.params.code, name: this.name, password: this.password })) {
          this.SET_REDIRECT_AFTER_LOGIN(window.location.href)
          this.$router.push('/login')
        }
      }
    }
  }
</script>

<style scoped>

</style>
