const state = {
  columns: [],
}

const getters = {
  GET_COLUMNS: state => state.columns,
}

const mutations = {
  CREATE_COLUMNS(state, payload) {
    state.columns = payload
  },
  SET_COLUMNS(state, payload) {
    state.columns = payload
  },
  UPDATE_COLUMNS(context, payload) {
    if (state.columns !== null && state.columns.id === payload.id)
      state.columns = payload;
  },
}

const actions = {
  async READ_COLUMNS(context, payload) {
    const columnsData = localStorage.getItem('columns-' + payload.name);
    if (columnsData) {
      const columns = JSON.parse(columnsData);
      context.commit('SET_COLUMNS', columns);
      return columns;
    } else {
      context.commit('SET_COLUMNS', []);
      return [];
    }
  },
  async SAVE_COLUMNS(context, payload) {
    let columns = [];
    let record = payload.record;

    columns = payload.localColumns;
    // columns.push(payload);


    context.commit('SET_COLUMNS', columns);
    localStorage.setItem('columns-' + record.name, JSON.stringify(columns));

    return columns;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
