import API from '@/api'

const state = {
  OPTIONSET: {
    name: '',
    score: 0,
    isFlagged: false
  },
  OPTIONSETS: { data:[] },
  ALL_OPTIONSETS: []
}

const getters = {
  GET_OPTIONSET: state => state.OPTIONSET,
  GET_OPTIONSETS: state => state.OPTIONSETS,
  GET_ALL_OPTIONSETS: state => state.ALL_OPTIONSETS,
}

const mutations = {
  // SITES
  SET_OPTIONSET(state, payload) {
    state.OPTIONSET = payload
  },
  SET_OPTIONSETS(state, payload) {
    state.OPTIONSETS = payload
  },
  SET_ALL_OPTIONSETS(state, payload) {
    state.ALL_OPTIONSETS = payload
  },
  CREATE_OPTIONSET(state, payload) {
    state.OPTIONSET = payload
    state.OPTIONSETS.data.push(payload)
  },
  UPDATE_OPTIONSET(state, payload) {
    if (state.OPTIONSET !== null && state.OPTIONSET.id === payload.id)
      state.OPTIONSET = payload;

    const index = state.OPTIONSETS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.OPTIONSETS.data[index], payload)
  },
  DELETE_OPTIONSET(state, payload) {
    if (state.OPTIONSET?.id === payload.id)
      state.OPTIONSET = {}
    state.OPTIONSETS.data = state.OPTIONSETS.data.filter(e => e.id !== payload.id)
    state.ALL_OPTIONSETS = state.ALL_OPTIONSETS.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD COURSES
  async READ_OPTIONSETS(context, payload) {
    const response = await API.Get('/api/optionsets', payload)
    if (response.type === 'Success') {
      context.commit('SET_OPTIONSETS', response.res.data)
      return response.res.data
    }
    context.commit('SET_OPTIONSETS', [])
  },
  async READ_ALL_OPTIONSETS(context) {
    const response = await API.Get('/api/options/optionSets')
    if (response.type === 'Success') {
      context.commit('SET_ALL_OPTIONSETS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_OPTIONSETS', [])
  },
  async CREATE_OPTIONSET (context){
    context.commit('SET_OPTIONSET', { options: [{ id: Math.random().toString(36).substring(2, 15), name: '', score: 0, isFlagged: false }] })
  },
  async SAVE_OPTIONSET (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/optionsets', payload)
      if(response.type === 'Success') {
        context.commit('SET_OPTIONSET', response.res.data)
        return response.res.data;
      }
    }
    else
    {
      const response = await API.Put(`/api/optionsets/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_OPTIONSET', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_OPTIONSET(context, payload) {
    const response = await API.Get(`/api/optionsets/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_OPTIONSET', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_OPTIONSET(context, payload) {
    const response = await API.Put(`/api/optionsets/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_OPTIONSET', response.res.data)
      return response.res.data
    }
  },
  async DELETE_OPTIONSET(context, payload) {
    const response = await API.Delete(`/api/optionsets/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_OPTIONSET', response.res.data)
      return
    }
  },
  async DELETE_OPTIONSET_LIST(context, payload) {
    const response = await API.Patch('/api/optionsets',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_OPTIONSET', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
