<template>
  <div>
    <div class="" :class="{ 'w-full flex': true }">
      <div v-if="!item.isSection" class="w-full flex flex-col p-4 border-gray-100 border rounded-[8px] shadow-md mb-6">
<!--        <div class="question-column">{{ item.title }}</div>-->
<!--        <template v-if="item.questionType === 'optionset'">-->
<!--          <span v-for="o in optionSetItems?.options?.slice(0, 3)" :key="o.id" class="px-4 py-1 ml-2 flex items-center rounded-4xl font-nhu-regular fs-14" :style="{ 'background-color': o.color }">-->
<!--            {{ o.name }}-->
<!--          </span>-->
<!--          <span v-if="optionSetItems?.options?.length > 3"> +{{ optionSetItems?.options.length - 3 }}</span>-->
<!--        </template>-->
<!--        <template-question :model-value="item"/>-->
        <InspectionQuestion :item="item" :disabled="true"/>
      </div>
      <div v-else class="w-full py-4">
        <div class="w-full flex mb-6">
          <div class="flex-grow">{{ item.title }}</div>
        </div>
        <div v-if="item.isSection && item.showItems && item.items.length">
          <TemplateSubItemsView
              v-for="subItem in item.items"
              :key="subItem.id"
              :item="subItem"
              :level="level + 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import InspectionQuestion from "@/pages/inspections/InspectionQuestion.vue";

export default {
  name: "TemplateSubItemsView",
  components: {InspectionQuestion},
  props: {
    item: {
      type: Object,
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      optionSetItems: null,
    };
  },
  methods: {
    ...mapActions(['READ_OPTIONSET']),
  },
  async mounted() {
    if (this.item.questionType === 'optionset') {
      this.optionSetItems = await this.READ_OPTIONSET({ id: this.item.optionSetId });
    }
  },
}
</script>

<style scoped>
.question-column {
  width: 60%;
}
</style>
