<template>

  <!--  <template v-if="field.id === 'assignedTo'">
    <app-dropdown v-model="item[field.id]" value="id" :label="label ?? $t('records.' + record.name + '.fields.' + field.id)" getter="GET_ALL_USERS" :has-avatar="field.hasAvatar"/>
  </template>-->
  <template v-if="field.type === 'record'">
    <app-dropdown v-model="item[field.id]" :id="field.id" value="id"
      :label="label ? label : getRecordLabel(this.fieldId) !== null ? getRecordLabel(this.fieldId) : $t('records.' + record.name + '.fields.' + field.id)"
      :icon-label="field.icon" :filter-by="filterBy" :getter="getRecord(field.record).options.getter"
      :allow-nulls="allowNulls" :has-avatar="field.hasAvatar" :has-colors="field.hasColors" :has-child="hasChild"
      :errors="errors" />
  </template>

  <template v-else-if="field.type === 'priority'">
    <app-priority-picker :id="field.id" v-model="item[field.id]" :label="$t('records.' + record.name + '.fields.' + field.id)"
      :errors="errors" />
  </template>

<template v-else-if="field.type === 'schedule'">
    <work-order-schedule-picker :id="field.id" v-model="item[field.id]" :action="action"
      :label="$t('records.' + record.name + '.fields.' + field.id)" :errors="errors" />
  </template>

</template>

<script>
import { mapGetters } from "vuex";
import WorkOrderSchedulePicker from "@/pages/workorders/WorkOrderSchedulePicker.vue";

export default {
  name: "AppCrudFieldEdit",
  components: { WorkOrderSchedulePicker },
  props: {
    filterBy: { type: Function, default: null },
    fieldId: { type: String, default() { return ''; } },
    label: { type: String, default() { return ''; } },
    errors: { type: Object, default() { return null; } },
    allowNulls: { type: Boolean, default: true },
    hasChild: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({ record: 'GET_RECORD', records: 'GET_RECORDS', company: 'GET_CURRENT_COMPANY' }),
    action() { return this.$route.meta.action; },
    field() {
      return this.record.fields.find(x => x.id === this.fieldId)
    },
    item() {
      return this.$store.getters['GET_' + this.record.id];
    }
  },
  methods: {
    getRecordLabel(fieldId) {

      if (this.company && this.company.recordLabels) {
        if (this.record.fields.find(x => x.id === fieldId) !== null && this.record.fields.find(x => x.id === fieldId).record !== null) {
          const recordName = this.record.fields.find(x => x.id === fieldId).record
          if (this.company.recordLabels[recordName]) {
            if (!this.company.recordLabels[recordName].label && !this.company.recordLabels[recordName].pluralLabel)
              return null
            if (this.company.recordLabels[recordName].label !== null) {
              return this.company.recordLabels[recordName].label
            } else if (this.company.recordLabels[this.record.name].plural !== null) {
              return this.company.recordLabels[this.record.name].plural
            }
          }
        }

        return fieldId;
      }
    },
    getRecord(id) {
      return this.records[id];
    }
  },
};
</script>
