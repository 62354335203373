<template>
  <div ref="checkList">
    <div @click="labelClick">
      <app-input-container :id="id" :modelValue="newCheckName" :label="label" :disabled="disabled"
        v-slot="{ focusin, focusout }">
        <input @keyup.enter="addCheck" v-model="newCheckName" ref="input" type="text"
          class="!bg-transparent h-[16px] !p-0 focus:ring-0 outline-none placeholder:text-xs sm:text-[14px] w-full mt-4"
          @focusin="focusin" @focusout="focusout">
      </app-input-container>
    </div>

    <div v-if="localChecks.length === 0 && suppText !== ''" class="flex mt-2 mb-4">
      <div class="h-[100%] flex items-start">
        <InformationCircleIcon class="w-[20px] h-[20px] mr-1 text-gray-300" />
      </div>
      <span class="relative block text-xs font-nhu-regular text-gray-300 cursor-text pre-wrap">{{ suppText }}</span>
    </div>

    <draggable v-model="localChecks" @end="updateChecks" item-key="id" handle=".drag-handle">
      <template v-slot:item="{ element, index }">
        <div class="w-full flex">
          <div class="flex items-center w-full mx-4">
            <app-icon icon="grip_dots_vertical_regular" extra-class="cursor-pointer drag-handle" :width="20"
              :height="20">
            </app-icon>
            <input type="checkbox" class="hidden" :value="element.id" :name="element.name">
            <span class="mx-2 flex border-gray-900 text-xs items-center justify-center" 
              :class="{'w-4 h-4 border rounded-[4px]': !numbers, 'w-6 h-6 border rounded-full': numbers}">
              <span v-if="numbers">{{ index + 1 }}</span>
            </span>
            <span class="pl-1 font-nhu-regular text-gray-900 fs-14 my-2">{{ element.name }}</span>
            <div class="flex-1"></div>
            <app-icon icon="trash_regular" extra-class="fill-red-500 cursor-pointer" @click="deleteOption(element)"
              :width="15" :height="15">
            </app-icon>
          </div>
        </div>
      </template>
    </draggable>
  </div>
  <div>
    <div class="h-0 border-b border-gray-50 my-4" />
  </div>

</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import Draggable from "vuedraggable";

export default {
  name: "AppChecklist",
  components: {
    InformationCircleIcon,
    Draggable
  },
  props: {
    id: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { type: [Array], default() { return [] } },
    maxChars: { type: Number, default: 0 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    numbers: { type: Boolean, default: false },
    error: { type: String, default: "" },
    suppText: { type: String, default: "" }
  },
  emits: ["update:modelValue", "itemAdded"],
  data() {
    return {
      newCheckName: '',
      localChecks: this.modelValue.slice()
    }
  },
  watch: {
    modelValue(newVal) {
      this.localChecks = newVal.slice();
    }
  },
  computed: {
    checks() {
      return [...this.modelValue];
    },
    hasValue() {
      return this.modelValue !== ''
    }
  },
  methods: {
    addCheck() {
      if (this.newCheckName.trim() !== '') {
        const newCheck = {
          id: Math.random().toString(36).substring(2, 15),
          name: this.newCheckName.trim()
        };
        this.localChecks.push(newCheck);
        this.$emit('update:modelValue', this.localChecks);
        this.newCheckName = '';
        this.$emit('itemAdded')

        this.$nextTick(() => {
          this.$refs.checkList.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }
    },
    setLine(idx, name) {
      var checks = [...this.localChecks];
      if (idx >= checks.length)
        checks.push({ id: Math.random().toString(36).substring(2, 15), name: name });
      else {
        if (name === '')
          checks.splice(idx, 1);
        else
          checks[idx].name = name;
      }
      this.$emit('update:modelValue', checks);
    },
    onChange(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    isSelected(checkId) {
      return this.localChecks.some(check => check.id === checkId);
    },
    toggleOption(check) {
      const idx = this.localChecks.findIndex(c => c.id === check.id);
      if (idx !== -1) {
        this.localChecks.splice(idx, 1);
      } else {
        this.localChecks.push(check);
      }
      this.$emit('update:modelValue', this.localChecks);
    },
    deleteOption(check) {
      const idx = this.localChecks.findIndex(c => c.id === check.id);
      if (idx !== -1) {
        this.localChecks.splice(idx, 1);
        this.$emit('update:modelValue', this.localChecks);
      }
    },
    updateChecks() {
      this.$emit('update:modelValue', this.localChecks);
    },
    labelClick() {
      this.$refs.input.focus();
    },
  },
  created() { }
};
</script>

<style scoped>
.hidden {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
</style>
