<template>
  <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <app-textbox v-model="item.name" :label="$t('requests.name')" :error="error" />
    <app-textarea v-model="item.description" :label="$t('requests.description')" />
    <app-image-list v-model="item.images" :rounded="false" />
    <app-dropdown v-model="item.assetId" value="id" :label="$t('requests.asset')" getter="GET_ALL_ASSETS" :allow-nulls="true"/>
    <app-dropdown v-model="item.siteId" value="id" :label="$t('requests.site')" getter="GET_ALL_SITES" :allow-nulls="true"/>
    <!--
     <app-textbox v-model="item.email" :label="$t('requests.email')" :emailValidator="true" :disabled="action === 'edit'" />
    -->
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>

export default {
  name: "RequestEditPage",
  data() {
    return {
      error: ''
    }
  },
  methods: {
    async onValidate({ item, onSuccess, onError }) {
      if(!item.name) {
        this.error = this.$t('request.errors.name');
        return onError();
      }
      onSuccess();
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
