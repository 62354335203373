import API from '@/api'

const state = {
  REQUEST: {
    name: '',
    description: '',
    images: [],
    status: '',
    workOrderId: '',
    assetId: '',
    SiteId: '',
    email: '',
    requestLandingId: '',
    schedule: {},
    props: {},
  },
  REQUESTS: {data: []},
  ALL_REQUESTS: []
}

const getters = {
  GET_REQUEST: state => state.REQUEST,
  GET_REQUESTS: state => state.REQUESTS,
  GET_ALL_REQUESTS: state => state.ALL_REQUESTS,
}

const mutations = {
  // REQUESTS
  SET_REQUEST(state, payload) {
    state.REQUEST = payload
  },
  SET_ALL_REQUESTS(state, payload) {
    state.ALL_REQUESTS = payload
  },
  SET_REQUESTS(state, payload) {
    state.REQUESTS = payload
  },
  CREATE_REQUEST(state, payload) {
    state.REQUEST = payload
    state.REQUESTS.data.push(payload)
  },
  UPDATE_REQUEST(state, payload) {
    if (state.REQUEST !== null && state.REQUEST.id === payload.id)
      state.REQUEST = payload;

    const index = state.REQUESTS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.REQUESTS.data[index], payload)
  },
  DELETE_REQUEST(state, payload) {
    if (state.REQUEST?.id === payload.id)
      state.REQUEST = {}
    state.REQUESTS.data = state.REQUESTS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD REQUESTS
  async READ_REQUESTS(context, payload) {
    const response = await API.Get('/api/requests', payload)
    if (response.type === 'Success') {
      context.commit('SET_REQUESTS', response.res.data)
      return response.res.data
    }
    context.commit('SET_REQUESTS', [])
  },

  async CREATE_REQUEST(context) {
    context.commit('SET_REQUEST', {name: '', description: '', status: 'pending', assetId: '', siteId: '', email: '', schedule: {}, requestLandingId: '', baseUrl: '', props: {}})
  },
  async REOPEN_REQUEST(context, payload) {
    const response = await API.Put(`/api/requests/${payload.id}/reopen`, {})
    if (response.type === 'Success') {
      context.commit('UPDATE_REQUEST', response.res.data)
      return response.res.data
    }
  },
  async SAVE_REQUEST(context, payload) {
    if (payload.id === undefined) {
      const response = await API.Post('/api/requests', payload)
      if (response.type === 'Success') {
        context.commit('SET_REQUEST', response.res.data)
        return response.res.data;
      }
    } else {
      const response = await API.Put(`/api/requests/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_REQUEST', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_REQUEST(context, payload) {
    const response = await API.Get(`/api/requests/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST', response.res.data)
      return response.res.data
    }
  },
  async READ_REQUEST_BY_ID(context, id) {
    const response = await API.Get(`/api/requests/${id}`)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_REQUEST(context, payload) {
    const response = await API.Put(`/api/requests/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_REQUEST', response.res.data)
      return response.res.data
    }
  },
  async DELETE_REQUEST(context, payload) {
    const response = await API.Delete(`/api/requests/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_REQUEST', response.res.data)
      return
    }
  },
  async DELETE_REQUEST_LIST(context, payload) {
    const response = await API.Patch('/api/requests', payload);

    if (response.type === 'Success') {
      context.commit('DELETE_REQUEST', {payload});
      return true;
    }
    return false;
  },
  async EXPORT_REQUEST_XLSX() {
    const response = await API.Post(`/api/requests/export-XLSX`)
    if (response.type === 'Success') {
      return response.res.data
    }
  },
  async REJECT_REQUEST(context, payload) {
    const response = await API.Put(`/api/requests/${payload.id}/reject`, {message: payload.message})
    if (response.type === 'Success') {
      context.commit('UPDATE_REQUEST', response.res.data)
      return response.res.data
    }
  },
  async READ_ALL_REQUESTS(context) {
    const response = await API.Get('/api/options/requests')
    if (response.type === 'Success') {
      context.commit('SET_ALL_REQUESTS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_REQUESTS', [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
