import API from '@/api'

const state = {
  GROUP: {},
  GROUPS: { data: [], total : 0},
}

const getters = {
  GET_GROUP: state => state.GROUP,
  GET_GROUPS: state => state.GROUPS,
}

const mutations = {
  // People
  SET_GROUP(state, payload){
    state.GROUP = payload
  },
  SET_GROUPS(state, payload){
    state.GROUPS = payload
  },
  UPDATE_GROUP(state, payload){

    if(state.GROUP.id === payload.id)
      state.GROUP = payload;

    const index = state.GROUPS.data.findIndex(e => e.id === payload.id)
    if(index > -1)
      Object.assign(state.GROUPS.data[index], payload)
  },
  DELETE_GROUP(state, payload){
    state.GROUPS.data = state.GROUPS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD GROUPS
  async READ_GROUPS (context, payload){
    const response = await API.Get('/api/groups', payload)
    if(response.type === 'Success')
      context.commit('SET_GROUPS', response.res.data)
    else
      context.commit('SET_GROUPS', { data: [], total : 0})
  },
  async READ_GROUP (context, payload){
    const response = await API.Get('/api/groups/' + payload.id)
    if(response.type === 'Success')
      context.commit('SET_GROUP', response.res.data)
    else
      context.commit('SET_GROUP', { name: ''})
  },
  async CREATE_GROUP (context){
    context.commit('SET_GROUP', { name: '', members:[], courses:[]})
  },
  async SAVE_GROUP (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/groups', payload)
      if(response.type === 'Success'){
        context.commit('SET_GROUP', response.res.data)
        return response.res.data;
      }
    }
    else
    {
      const response = await API.Put(`/api/groups/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('SET_GROUP', response.res.data)
        return response.res.data;
      }
    }
  },
  async UPDATE_GROUP (context, payload){
    const response = await API.Put(`/api/groups/${payload.id}`, payload)
    if(response.type === 'Success'){
      context.commit('SET_GROUP', response.res.data)
    }
  },
  async DELETE_GROUP (context, payload){
    const response = await API.Delete(`/api/groups/${payload.id}`)
    if(response.type === 'Success'){
      context.commit('DELETE_GROUP', payload)
    }
  },
  async DELETE_GROUP_LIST(context, payload) {
    // const ids = Array.isArray(payload.groups) ? payload.groups : [payload.groups];

    const response = await API.Patch('/api/groups',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_GROUP', { payload });
      return true;
    }
    return false;
  },

  async GROUP_GET_MEMBERS (context, payload){
    const response = await API.Get(`/api/groups/${payload.id}/members`)
    if(response.type === 'Success')
      return response.res.data;
    else
      return []
  },
  async GROUP_UPDATE_MEMBERS (context, payload){
    const response = await API.Post(`/api/groups/${payload.id}/members`, { members: payload.members })
    if(response.type === 'Success')
      return response.res.data;
    else
      return []
  },

  async GROUP_GET_COURSES (context, payload){
    const response = await API.Get(`/api/groups/${payload.id}/courses`)
    if(response.type === 'Success') {
      return response.res.data;
    } else
      return []
  },
  async GROUP_UPDATE_COURSES (context, payload){
    const response = await API.Post(`/api/groups/${payload.id}/courses`, { courses: payload.courses })
    if(response.type === 'Success') {
      return response.res.data;
    } else
      return []
  },
  async CREATE_GROUP_ENROLLMENTS (context, payload){
    const response = await API.Post(`/api/groups/enrollments`, payload)
    if(response.type === 'Success'){
      return true
    }
    return false;
  },
}

export default {state, getters, mutations, actions}
