<template>
  <app-crud :title="$t('records.metrics.addMeasure')" save-action="none" @validate="doSave">
    <div class="flex items-center">
      <span class="font-nhu-semi-bold fs-14">{{ $t('records.metrics.newMeasure') }}</span>
      <div class="h-0 border-b border-gray-50 my-4"/>
    </div>
    <app-textbox v-model="measure.value" :label="$t('records.metrics.addMeasure')"/>
    <div v-if="metric.measurements?.length > 0" class="py-2">
      <span class="font-nhu-regular fs-14">
        {{$t('records.metrics.lastMeasure')}}: {{metric.measurements[metric.measurements.length - 1].value}} {{$t(`records.metrics.units.${metric.measurementUnit}`)}}
      </span>
    </div>
    <app-image-list v-model="measure.images" :rounded="false" :allow-upload-file="true"/>
  </app-crud>
</template>

<script>
import AppCrud from "@/crud/AppCrud";
import AppTextbox from "@/components/AppTextbox";
import {mapGetters} from "vuex";
import AppImageList from "@/components/AppImageList";
export default {
  name: "MetricMeasures",
  components: {AppImageList, AppTextbox, AppCrud},
  data() {
    return {
      measure: {
        value: 0,
        images: [],
      },
    }
  },
  computed: {
    ...mapGetters({metric: 'GET_METRIC'}),
  },
  methods: {
    async doSave() {
      await this.$store.dispatch('SAVE_METRIC_MEASURE', {id: this.metric.id, value: this.measure.value, images: this.measure.images})
    }
  }
}
</script>

<style scoped>

</style>
