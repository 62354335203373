<template>
    <app-crud ref="crud"  v-slot="{ item }" @validate="onValidate">
    <div @click="handleClickOutside" class="min-h-full">
        <div class="flex gap-4 pb-4">
<!--            <app-image-list v-model="item.images" :onlyOneImage="true" />-->
            <div class="w-1/2">
              <app-textbox v-model="item.name" :label="$t('records.templates.name')" :error="errorMsg" @update:model-value="clearErrorMsg" />
<!--              <app-textarea v-model="item.description" :label="$t('records.templates.description')" />-->
            </div>
        </div>
        <!-- Añadir un contenedor para todas las tablas -->
        <Draggable :list="item.pages" :itemKey="page => page.id" @change="updatePageOrder" handle=".handle">
            <template #item="{element: page}">
                <div>
<!--                    <div class="sm:flex sm:items-center gap-2">-->
<!--                        <bars3-icon class="h-6 w-6 handle" aria-hidden="true" />-->
<!--                        {{ index + 1 }}.-->
<!--                        <div class="-mt-4 my-1" >-->
<!--                          <app-textbox v-model="page.title" :label="$t('records.templates.pageTitle')" />-->
<!--                        </div>-->
<!--                        <app-button @click="duplicatePage(index)" type="danger" background="light" title="Copiar Pagina">-->
<!--                            <DocumentDuplicateIcon class="w-6 h-6" />-->
<!--                        </app-button>-->
<!--                        <app-button @click="removePage(index)" type="danger" background="light" title="Eliminar Pagina" v-if="index > 0">-->
<!--                            <TrashIcon class="w-6 h-6" />-->
<!--                        </app-button>-->
<!--                    </div>-->

                    <div class="w-11/12">
<!--                        <div class="w-full border border-gray-300 flex bg-gray-200 rounded-t-xl p-4">-->
<!--                            <div class="question-column">{{ $t('pregunta') }}</div>-->
<!--                            <div class="answer-type-column">{{ $t('tipo-de-respuesta') }}</div>-->
<!--                        </div>-->
                        <TemplateSection :model-value="page" @update:model-value="changePage"  :isRoot="true"/>
                    </div>

                    <div class="h-4" />
                </div>
            </template>
        </Draggable>
<!--        <button @click="doCreatePage" class="p-4 underline">-->
<!--            {{ $t('templates.page.create') }}-->
<!--        </button>-->
    </div>
    </app-crud>
</template>

<script>
import { mapActions, mapGetters, mapMutations} from 'vuex'
import {
  // TrashIcon,
  // DocumentDuplicateIcon
} from '@heroicons/vue/24/outline'
import Draggable  from "vuedraggable";
// import AppTextarea from "@/components/AppTextarea.vue";
// import moment from 'moment';

export default {
    name: 'TemplateEditorPage',
    components: {
      // AppTextarea,
      // TrashIcon,
      // DocumentDuplicateIcon,
      Draggable
    },
    data() {
        return {
            action: 'create',
            saveStatus: '',
            loading: true,
            history:[],
            showDeletePopUp: false,
            selectedRowIndex: null,
            errorMsg: '',
            pageTitleEmpty: '',
            questionTitleEmpty: '',
        }
    },
    watch: {
        filter()
        {
            this.load();
        },
    },
    computed: {
        ...mapGetters({template: 'GET_TEMPLATE'}),
        // publishTime() {
        //     return moment(String(this.template.templatePublishDate)).format('MM/DD/YYYY hh:mm')
        // }
    },
    methods: {
        ...mapMutations(['SET_TEMPLATE']),
        ...mapActions(['READ_OPTIONSETS', 'READ_TEMPLATE', 'UPDATE_TEMPLATE', 'PUBLISH_TEMPLATE', 'UPDATE_ITEM_SELECTED']),

        navigateToList()
        {
            this.$router.push('/templates');
        },

        changePage(page)
        {
            const index = this.template.pages.findIndex(x => x.id === page.id);
            this.template.pages[index] = page;

            //this.doSave();
        },

        doCreatePage() {
            this.template.pages.push({
                id: Math.random().toString(36).substring(2, 15),
                title: this.$t('templates.page.pageWithoutTitle'),
                items: [
                    {
                        id: Math.random().toString(36).substring(2, 15),
                        title: '',
                        isSection: false,
                        questionType: 'text',
                        isRequired: true,
                        items: null,
                    }
                ],
            });

            //this.doSave();
        },
        removePage(index) {
            this.template.pages.splice(index, 1);

            //this.doSave();
        },
        duplicatePage(index) {
            var newValue = JSON.parse(JSON.stringify(this.template.pages[index]));
            this.changeItemIds(newValue);

            this.template.pages.splice(index, 0, newValue);
            //this.doSave();
        },

        changeItemIds(item)
        {
            item.id = Math.random().toString(36).substring(2, 15);
            if(item.items)
            {
                for(var i = 0; i < item.items.length; i++)
                    this.changeItemIds(item.items[i])
            }
        },



        handleClickOutside() {
            this.UPDATE_ITEM_SELECTED(null);
        },


        async doUndo()
        {
            if(this.history.length > 0)
            {
                var o = this.history.pop();
                this.prevTemplate = structuredClone(o)
                this.SET_TEMPLATE(o);
                await this.UPDATE_TEMPLATE(this.course)
            }
        },

        async doSave() {
            this.saveStatus = this.$t('templates.saving')
            this.history.push(this.prevTemplate)
            this.prevTemplate = structuredClone(this.template)

            await this.$nextTick();
            this.UPDATE_TEMPLATE(this.template)
                .then(() => this.saveStatus = this.$t('templates.saved'));
        },

        async doPublish() {
            this.loading = true;
            this.saveStatus = ''
            if (this.template.id) {
              await this.PUBLISH_TEMPLATE(this.template)
            }
            this.loading = false;
        },
        updatePageOrder() {
            //this.doSave();
        },
      onValidate({ item, onSuccess, onError }) {
        if (!item.name) {
          this.errorMsg = this.$t('records.templates.errors.nameRequired');
          onError();
          return;
        }

        let allPagesHaveTitle = true;

        const checkItemsRecursive = (items) => {
          for (let item of items) {
            if (!item.title || item.title.trim() === '') {
              return false;
            }
            if (item.items && !checkItemsRecursive(item.items)) {
              return false;
            }
          }
          return true;
        };

        for (let page of item.pages) {
          // if (!page.title || page.title.trim() === '') {
          //   allPagesHaveTitle = false;
          //   break;
          // }

          if (page.items && !checkItemsRecursive(page.items)) {
            allPagesHaveTitle = false;
            break;
          }
        }

        if (!allPagesHaveTitle) {
          this.pageTitleEmpty = this.$t('records.templates.errors.sectionOrQuestionRequired');
          this.$store.commit('TOAST_MESSAGE', {
            type: 'error',
            title: this.$t('records.templates.errors.sectionOrQuestionRequiredTitle'),
            message: this.pageTitleEmpty,
          });
          onError();
          return;
        }

        this.validated = true;
        onSuccess();
      },
      clearErrorMsg() {
          this.errorMsg = ''
      },
      clearPageErrorMsg() {
          this.pageTitleEmpty = ''
      },
    },
    async created() {
        await this.READ_OPTIONSETS();
        this.prevTemplate = structuredClone(this.template)
        this.loading = false;
    },
}
</script>

<style>
.handle {
    cursor: move;
}

.question-column {
    width: 60%;
}

.answer-type-column {
    width: 40%;
}

.question-input,
select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
}

.selected-row {
    position: relative;
}



/* Estilo personalizado para el selector */
.select-wrapper {
    position: relative;
    display: block;
    width: 100%;
}

.custom-select {
    appearance: none;
    width: 100%;
    padding-right: 2rem; /* Espacio adicional a la derecha para el ícono de la flecha */
}

.select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #4a5568;
    pointer-events: none; /* Evita que el pseudo-elemento interfiera con los eventos del mouse en el selector */
}

.floating-delete {
    position: absolute;
    width: fit-content;
    z-index: 10;
}

.add-page-button {
    text-decoration: underline;
    color: #7e8083;
    border: 1px solid #7e8083;
    padding: 5px;
    border-radius: 50px;
    font-size: 12px;
}
.btn-add-question {
    float: right;
    margin-left: auto;
}
.section-input {
    padding: 0;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
}
.inner-table {
    width: 100%;
}
.inner-row td {
    padding-left: 20px; /* Agrega un margen izquierdo a las celdas de las filas internas */
    border-width: 2px; /* Aumenta el ancho del borde */
}
.arrow-down {
    transform: rotate(90deg);
}
</style>
