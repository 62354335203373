import API from '@/api'

const state = {
  REQUEST_LANDING: {
    id: '',
    companyId: '',
    name: '',
    welcomeText: '',
    description: '',
    assignedTo: [],
    sendNotification: false,
    authByEmailOnly: false,
    baseUrl: '',
  },
  REQUEST_LANDINGS: {data: []},
  ALL_REQUEST_LANDINGS: []
}

const getters = {
  GET_REQUEST_LANDING: state => state.REQUEST_LANDING,
  GET_REQUEST_LANDINGS: state => state.REQUEST_LANDINGS,
  GET_ALL_REQUEST_LANDINGS: state => state.ALL_REQUEST_LANDINGS,
}

const mutations = {
  SET_REQUEST_LANDING(state, payload) {
    state.REQUEST_LANDING = payload
  },
  SET_ALL_REQUEST_LANDINGS(state, payload) {
    state.ALL_REQUEST_LANDINGS = payload
  },
  SET_REQUEST_LANDINGS(state, payload) {
    state.REQUEST_LANDINGS = payload
  },
  CREATE_REQUEST_LANDING(state, payload) {
    state.REQUEST_LANDING = payload
    state.REQUEST_LANDINGS.data.push(payload)
  },
  UPDATE_REQUEST_LANDING(state, payload) {
    if (state.REQUEST_LANDING !== null && state.REQUEST_LANDING.id === payload.id)
      state.REQUEST_LANDING = payload;

    const index = state.REQUEST_LANDINGS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.REQUEST_LANDINGS.data[index], payload)
  },
  DELETE_REQUEST_LANDING(state, payload) {
    if (state.REQUEST_LANDING?.id === payload.id)
      state.REQUEST_LANDING = {}
    state.REQUEST_LANDINGS.data = state.REQUEST_LANDINGS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_REQUEST_LANDINGS(context, payload) {
    const response = await API.Get('/api/requestLandings', payload)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST_LANDINGS', response.res.data)
      return response.res.data
    }
    context.commit('SET_REQUEST_LANDINGS', [])
  },
  async READ_ALL_REQUEST_LANDINGS(context) {
    const response = await API.Get('/api/requestLandings/all')
    if (response.type === 'Success') {
      context.commit('SET_ALL_REQUEST_LANDINGS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_REQUEST_LANDINGS', [])
  },
  
  async CREATE_REQUEST_LANDING(context) {
    context.commit('SET_REQUEST_LANDING', {name: '', description: '', sendNotification: false, authByEmailOnly: false, assignedTo: [], baseUrl: ''})
  },

  async SAVE_REQUEST_LANDING(context, payload) {
    if (payload.id === undefined) {
      const response = await API.Post('/api/requestLandings', payload)
      if (response.type === 'Success') {
        context.commit('SET_REQUEST_LANDING', response.res.data)
        return response.res.data;
      }
    } else {
      const response = await API.Put(`/api/requestLandings/${payload.id}`, payload)
      if (response.type === 'Success') {
        context.commit('UPDATE_REQUEST_LANDING', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_REQUEST_LANDING(context, payload) {
    const response = await API.Get(`/api/requestLandings/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_REQUEST_LANDING', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_REQUEST_LANDING(context, payload) {
    const response = await API.Put(`/api/requestLandings/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_REQUEST_LANDING', response.res.data)
      return response.res.data
    }
  },
  async DELETE_REQUEST_LANDING(context, payload) {
    const response = await API.Delete(`/api/requestLandings/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_REQUEST_LANDING', response.res.data)
      return
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
