<template>
  <router-view />
  <the-messages />
  <the-loading />
  <div class="absolute right-2 bottom-0 font-bold text-red-400 bg-transparent" v-if="!online && !isLoginPage"> {{ $t('connecting') }}</div>
</template>

<script>
import { HubConnectionBuilder } from '@microsoft/signalr';
import TheMessages from './core/TheMessages.vue';
import TheLoading from './core/TheLoading.vue';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  components: {  TheMessages, TheLoading },
  data: () => ({
    connection: null,
    manuallyClosed: false,
    online: false,
  }),
  computed: {
      ...mapGetters({company: 'GET_CURRENT_COMPANY'}),
      isLoginPage() {
        return this.$route.fullPath === '/login'
      }
  },
  methods: {
    ...mapActions(["READ_COMPANY", 'UPDATE_RECORDS']),
    start() {
      var startedPromise = this.connection.start()
      .then(() => {
        this.online = true;
      })
      .catch(() => {
        this.online = false;
        return new Promise((resolve, reject) => {
          setTimeout(() => this.start().then(resolve).catch(reject), 5000)
        })
      })
      return startedPromise
    }
  },
  watch: {
    $route() {
      window.scrollTo(0,0)
    }
  },
  async mounted() {
    this.$gtm.enable(true)
    this.token = localStorage.getItem('zigo-token')
    this.connection = new HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_API_URL + 'hubs/events?token=' + this.token)
        .build();

    this.connection.on('OnException', (e) => { this.$store.commit('FIRE_MESSAGE', e); })
    this.connection.on('OnCompanyChange', (e) => { this.$store.commit('SET_CURRENT_COMPANY', e); })

    this.connection.on('OnGroupChange', (e) => { this.$store.commit('UPDATE_GROUP', e); })
    this.connection.on('OnSiteChange', (e) => { this.$store.commit('UPDATE_SITE', e); })
    this.connection.on('OnAssetChange', (e) => { this.$store.commit('UPDATE_ASSET', e); })
    this.connection.on('OnPartChange', (e) => { this.$store.commit('UPDATE_PART', e); })
    this.connection.on('OnIssueCategoryChange', (e) => { this.$store.commit('DELETE_ISSUECATEGORY', e); })
    this.connection.on('OnMessageChange', (e) => { this.$store.commit('ADD_MESSAGE', e); })
    this.connection.on('OnWorkOrderChange', (e) => {  this.$store.commit('UPDATE_WORKORDER', e); })
    this.connection.on('OnWorkOrderRead', (e) => { this.$store.commit('UPDATE_WORKORDER', e); })
    this.connection.on('OnRequestChange', (e) => {  this.$store.commit('UPDATE_REQUEST', e); })

    this.connection.on('OnDocumentGenerating', (e) => {
        const user = this.$store.getters?.GET_CURRENT_USER;

        if(user && e.userId === user.id)
          this.$store.commit('SET_VIEW_DOCUMENT', e.fileName);
      })
    this.connection.on('OnDocumentReady', (e) => {
      const user = this.$store.getters?.GET_CURRENT_USER;

      if(user && e.userId === user.id)
        this.$store.commit('SET_DOWNLOAD_LINK', e)
    })
    this.connection.on('ImportProcessing', (e) => {
      const user = this.$store.getters?.GET_CURRENT_USER;

      if(user && e.userId === user.id)
        this.$store.commit('SET_IMPORT_STATUS', e.status)
    })
    this.connection.onclose(() => { if (!this.manuallyClosed) setTimeout(() => { this.start();  }, 5000); })
    this.manuallyClosed = false
    this.start();
  },
};

</script>
