import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
  SITE: {
    name: '',
    label: '',
    description: '',
    members: [],
    teamInCharge: [],
    parentId: '',
    qrCode: null,
    childs: [],
    props: {}
  },
  SITES: { data:[] },
  ALL_SITES: []
}

const getters = {
  GET_SITE: state => state.SITE,
  GET_SITES: state => state.SITES,
  GET_ALL_SITES: state => state.ALL_SITES,
  GET_ALL_SITES_BY_COMPANY_ID: state => state.ALL_SITES,
}

const mutations = {
  // SITES
  SET_SITE(state, payload) {
    state.SITE = payload
  },
  SET_ALL_SITES(state, payload) {
    state.ALL_SITES = payload
  },
  SET_SITES(state, payload) {
    state.SITES = payload
  },
  CREATE_SITE(state, payload) {
    state.SITE = payload
    state.SITES.data.push(payload)
  },
  UPDATE_SITE(state, payload) {
    if (state.SITE !== null && state.SITE.id === payload.id)
      state.SITE = payload;

    const index = state.SITES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.SITES.data[index], payload)
  },
  DELETE_SITE(state, payload) {
    if (state.SITE?.id === payload.id)
      state.SITE = {}
    state.SITES.data = state.SITES.data.filter(e => e.id !== payload.id)
    state.ALL_SITES = state.ALL_SITES.filter(e => e.id !== payload.id)
  },
}

const actions = {
  async READ_ALL_SITES(context) {
    const response = await API.Get('/api/options/sites')
    if (response.type === 'Success') {
      context.commit('SET_ALL_SITES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_SITES', [])
  },
  async READ_ALL_SITES_BY_COMPANY_ID(context, payload) {
    const response = await API.Get(`/api/public/sites/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_ALL_SITES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_SITES', [])
  },
  async READ_SITES(context, payload) {
    const response = await API.Get('/api/sites', payload)
    if (response.type === 'Success') {
      context.commit('SET_SITES', response.res.data)
      return response.res.data
    }
    context.commit('SET_SITES', [])
  },
  async READ_SITE_LIST(context) {
    const response = await API.Get('/api/sites/siteList')
    if (response.type === 'Success') {
      context.commit('SET_SITES', response.res.data)
      return response.res.data
    }
    context.commit('SET_SITES', [])
  },
  async CREATE_SITE (context){
    context.commit('SET_SITE', { name: '', members: [], props: {} })
  },
  async SAVE_SITE (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/sites', payload)
      if(response.type === 'Success'){
        context.dispatch('READ_ALL_SITES')
        if (response.res.data?.parentId === '') {
          context.commit('SET_SITE', response.res.data)
          return response.res.data;
        }
      }
    }
    else
    {
      const response = await API.Put(`/api/sites/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.dispatch('READ_ALL_SITES')
        context.commit('UPDATE_SITE', response.res.data)
        return response.res.data;
      }
    }
  },
  async READ_SITE(context, payload) {
    const response = await API.Get(`/api/sites/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_SITE', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_SITE(context, payload) {
    const response = await API.Put(`/api/sites/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_SITE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_SITE(context, payload) {
    const response = await API.Delete(`/api/sites/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_SITE', response.res.data)
      context.commit('TOAST_MESSAGE',{type: 'success', title: i18n.global.t('records.sites.actions.deletedTitle'), message: i18n.global.t('records.sites.actions.deleted')})
      return
    }
  },
  async DELETE_SITE_LIST(context, payload) {
    // const ids = Array.isArray(payload.peoples) ? payload.peoples : [payload.peoples];
    const response = await API.Patch('/api/sites',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_SITE', { payload });
      return true;
    }
    return false;
  },
  async ADD_SITE_MEMBER(context, payload) {
    const response = await API.Post(`/api/sites/${payload.id}/members`, payload.peopleGroups)
    if (response.type === 'Success') {
      context.commit('UPDATE_SITE', response.res.data)
      return response.res.data
    }
  },
  async EXPORT_SITE_XLSX() {
    const response = await API.Post(`/api/sites/export-XLSX`)
    if (response.type === 'Success') {
      return response.res.data
    }
  },
  async IMPORT_SITES(context, payload) {
    const response = await API.Post('/api/sites/import', payload)
    if (response.type === 'Success') {
      if(state.SITES){
        context.commit('SET_SITES', {data: [...state.SITES.data, ...response.res.data]})
      }else{
        context.commit('SET_SITES', response.res.data)
      }
      return response.res.data
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
