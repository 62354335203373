<template>
  <template  v-if="action === 'edit' || action === 'create' || editProp">
    <div v-for="p in properties" :key="p.id">
      <div class="rounded py-1 relative">
        <div v-if="p.type === 'string'">
          <app-textbox :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name"/>
        </div>
        <div v-else-if="p.type === 'number'">
          <app-textbox :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name" type="number"/>
        </div>
        <div v-else-if="p.type === 'date'">
          <app-date-picker :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name"/>
        </div>
        <div v-else-if="p.type === 'boolean'">
          <app-checkbox :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name"/>
        </div>
        <div v-else-if="p.type === 'option_set'">
          <app-dropdown :options="getOptions(p.options)" :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name"/>
        </div>
        <div v-else-if="p.type === 'option_set_dynamic'">
          <app-dropdown :options="getOptions(p.options)" :modelValue="getValue(p)" @update:modelValue="e => setValue(p, e)" :label="p.name" :taggable="true" @add-option="(value) => addNewOption(p, value)"/>
        </div>
        <div v-else>
          <span>{{ getValue(p) }}</span>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div v-for="p in properties" :key="p.id">
      <div class="rounded py-1 relative">
      <div v-if="hasValue(p)">
        <div class="h-0 border-b border-gray-50 my-4"/>
        <div class="flex items-center fill-primary-500 mb-1">
        <span class="font-nhu-semi-bold fs-14">
          {{ p.name }}
        </span>
        </div>
        <span class="font-nhu-regular fs-14 text-gray-900">
          <div v-if="p.type === 'date'">
            {{ new Date(getValue(p)).toLocaleDateString() }}
          </div>
          <div v-else-if="p.type === 'boolean'">
            <CheckCircleIcon v-if="getValue(p)" class="w-8 h-8" />
            <XCircleIcon v-else class="w-8 h-8" />
          </div>
          <div v-else>
            {{ getValue(p) }}
          </div>
        </span>
      </div>
    </div>
  </div>

  </template>
</template>

<script>
import {mapGetters} from "vuex";

import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/outline'
import AppDropdown from "@/components/AppDropdown";
export default {
  name: "AppCrudProperties",
  components: { AppDropdown, CheckCircleIcon, XCircleIcon  },

  emits: ["update:modelValue"],
  props: {
    modelValue: {type: Object, default: null},
    editProp: {type: Boolean, default: false}
  },
  methods: {
    addNewOption(property, value) {
      console.log(this.record.id, "RECORD_ID", property?.code, "PROPERTY", value, "VALUE")
      this.$store.commit('UPDATE_OPTION', {record: this.record.id.toLowerCase(), property: property?.code, value})
    },
    getOptions(options) {
      return options.map(o => ({name: o, id: o}))
    },
    hasValue(property) {
      if(!this.modelValue)
        return false;
      return Object.hasOwn(this.modelValue, property?.code)
    },
    getValue(property) {
      if(!this.modelValue)
        return null;
      return this.modelValue[property?.code]
    },
    setValue(property, value) {
      let data = {...this.modelValue}
      data[property.code] = value;
      this.$emit('update:modelValue', data)
    }
  },
  computed: {
    ...mapGetters({ company: 'GET_CURRENT_COMPANY', records: 'GET_RECORDS', record: 'GET_RECORD' }),
    properties () {
      if(!this.company || !this.record)
        return []
      return this.company.properties[this.record.id.toLowerCase()]
    },
    action() {
      return this.$route.meta.action;
    },
    hasProperties() {
      return this.company?.properties?.workorder?.length > 0
    }
  },
}
</script>
