<template>
    <div id="app">
        <div @click.stop="labelClick">
            <app-input-container :id="id" :modelValue="modelValue" :label="label" :disabled="disabled"
                                 :expand="expandCondition"
                                 v-slot="{focusin, focusout} ">
                <div class="flex fill-primary-500">
                    <app-icon v-if="iconLabel && modelValue?.length >= 1" :icon="iconLabel" :width="20"
                              :height="20"/>
                    <vSelect ref="vSelect"
                             :modelValue="internalValue" @update:modelValue="selectOption"
                             @focusin="focusin" @focusout="focusout"
                             class="block font-nhu-regular mt-1 w-full h-[10px] border-0 -top-1 !p-0 text-black !bg-transparent focus:ring-0 text-xs sm:leading-6"
                             :clearable="true" :multiple="Array.isArray(modelValue)"
                             :reduce="(o) => value !== null ? o[value] : o" :inputId="value"
                             :label="valueCaption"
                             :disabled="disabled"
                             :options="getOptions"
                    >

                        <template v-slot:option="option">
                          <div v-if="hasImage(option)" class="flex items-center">
                            <user-avatar :user="option" width="w-10" height="h-10"/>
                            <span class="font-nhu-regular ml-2">{{ option?.name }}</span>
                          </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                {{ $t('noResultsFor') }} <em>{{ search }}</em
                            >.
                            </template>
                        </template>
                    </vSelect>
                </div>
            </app-input-container>
        </div>
    </div>
<!--    <div v-if="suppText !== ''" class="flex mt-2">-->
<!--        <InformationCircleIcon class="w-6 h-6 mr-1 text-gray-300"/>-->
<!--        <span class="text-gray-300">{{ suppText }}</span>-->
<!--    </div>-->
</template>

<script>
// import {h} from 'vue';
import vSelect from 'vue-select';
import API from '@/api'
import UserAvatar from "@/components/UserAvatar.vue";
// import {InformationCircleIcon} from "@heroicons/vue/24/outline"

// vSelect.props.components.default = () => ({
//   OpenIndicator: {
//     render: () => h('span', ''),
//   },
// });

export default {
  name: "AppAutoComplete",
  components: {UserAvatar, vSelect},
  props: {
    id: {type: String, default: null},
    type: {type: String, default: 'text'},
    modelValue: {type: [Number, String, Array], default: ""},
    maxChars: {type: Number, default: 0},
    label: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    invalid: {type: Boolean, default: false},
    error: {type: String, default: ""},
    suppText: {type: String, default: ""},
    getter: {type: String, default: null},
    url: {type: String, default: ""},
    options: {type: Array, default: null},
    value: {type: String, default: "id"},
    valueCaption: {type: String, default: "name"},
    hasIcon: {type: Boolean, default: false},
    iconLabel: {type: String, default: ''}
  },
  emits: ["update:modelValue", 'keydown', 'change'],
  data() {
    return {
      items: [],
      isSearching: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectOption(value);
      }
    },
    getOptions() {
      return this.items ?? this.options ?? [];
    },
    selectedOption() {
      const selected = this.getOptions.find(x => x[this.value] === this.modelValue);

      if (selected === undefined)
        return ""
      else
        return selected[this.valueCaption]
    },
    expandCondition() {
      return this.isSearching || (this.modelValue !== null && this.modelValue !== undefined && this.modelValue.length >= 1);
    },
  },
  methods: {
    labelClick() {
      if (event.target.classList.value === '') {
        return;
      }

      this.isSearching = true
      let inputElement = this.$refs.vSelect.$el.querySelector('input');

      if (inputElement) {
        inputElement.focus();
      }
    },
    selectOption(value) {
      this.$emit("update:modelValue", value)
      this.$emit("change")
    },
    async ReadData() {
      this.loading = true;
      let response = [];
      let data = []
      //Traemos del store
      if (this.getter !== null) {
        response = this.$store.getters[this.getter];
        if (!response || response?.length === 0) {
          //Si el store devuelve vacio, traemos de la API
          response = await API.Get(this.url, this.urlParams)
          if (response.type === "Success")
            data = response.res.data;
        } else {
          data = response;
        }
      }
      if (Array.isArray(data))
        this.items = data;
      else {
        this.items = data.data;
      }
      this.loading = false;
    },
    // dropdownShouldOpen(VueSelect) {
    //   if (this.options !== null) {
    //     return VueSelect.open
    //   }
    //
    //   return VueSelect.search.length !== 0 && VueSelect.open
    // },
    outsideClick() {
      if (this.isSearching) {
        this.isSearching = false;
      }
    },
    hasImage(option) {
      return option?.color || option?.image;
    },
  },
  async created() {
    if (this.url !== null || this.getter !== null)
      await this.ReadData();
  },
  mounted() {
    document.body.addEventListener('click', this.outsideClick);
  }
};
</script>


<style scoped>
deep() .vs__dropdown-toggle {
    padding: 0px;
}

deep() .vs__search, .vs__search:focus {
    line-height: 1.5rem;
}

deep() .vs__selected {
    background-color: #EEF6F1 !important;
    border: 1px solid #CAE3D3 !important;
    border-radius: 50px !important;
    padding-inline: 10px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    color: #2F5A3F !important;
    margin-top: 0 !important;
    height: 25px !important;
}

deep() .vs__deselect {
    fill: #55A472 !important;
}

deep() .vs__dropdown-menu {
    color: #767C86 !important;
    margin-top: 12px !important;
}

deep() .vs__dropdown-option--highlight {
    background-color: #EEF6F1 !important;
    color: #55A472 !important;
}
</style>
