<template>
  <AppPopUpContainer :show-popup="true" @closePopup="redirectToIntegrationView()">
    <div v-if="!companySelected && !loading" class="md:w-96 w-[20rem]">
      <div v-if="facebookShops.length !== 0">
        <div v-for="company in facebookShops" :key="company.id" class="w-full">
          <div class="w-full cursor-pointer flex justify-between items-center px-1 py-2 hover:bg-light-purple"
               @click="selectShop(company.id, company.access_token)">
            <div class="max-w-[22rem]">
              <span>{{ company.name }}</span>
            </div>
            <input class="cursor-pointer" type="radio" :checked="company.id === companySelectedId">
          </div>
        </div>
      </div>
      <div v-else>
        <span>No tiene tienda asociada a esta cuenta. Puede crear una asiendo click
          <a href="https://business.facebook.com/commerce">aqui</a>
        </span>
      </div>
    </div>
    <div v-if="companySelected && !loading" class="md:w-96 w-[20rem]">
      <div v-if="companyCatalogs.length !== 0">
        <div v-for="catalog in companyCatalogs" :key="catalog.id" class="w-full">
          <div class="w-full cursor-pointer flex justify-between items-center px-1 py-2 hover:bg-light-purple"
               @click="selectCatalog(catalog.id)">
            <div class="max-w-[22rem]">
              <span>{{ catalog.name }}</span>
            </div>
            <input class="cursor-pointer" type="radio" :checked="catalog.id === catalogSelectedId">
          </div>
        </div>
      </div>
      <div v-else>
        <span>Esta tienda no posee catalogos asociados o ya fueron agregados</span>
      </div>
    </div>
    <div class="flex justify-center w-full gap-2 px-4 py-2 space-x-4">
      <app-button :type="1" @click="catalogBack">
        {{ $t('admin.integrations.back') }}
      </app-button>
      <app-button :type="0" :disabled="companySelectedId.length === 0 && !companySelected || catalogSelectedId.length === 0 && companySelected" @click="nextStep">
        {{ $t('admin.integrations.next') }}
      </app-button>
    </div>
  </AppPopUpContainer>
</template>

<script>
import AppPopUpContainer from "@/components/AppPopUpContainer";
import {mapActions, mapGetters} from "vuex";
import AppButton from "@/components/AppButton";
export default {
  name: "FacebookShopsPopUp",
  components: {AppButton, AppPopUpContainer},
  data() {
    return {
      loading: false,
      companySelected: false,
      companyAccessToken: "",
      companySelectedId: "",
      catalogSelectedId: ""
    }
  },
  computed: {
    ...mapGetters({ facebookShops: 'GET_FACEBOOK_COMPANYS', companyCatalogs: 'GET_CURRENT_COMPANY_CATALOGS' }),
  },
  methods: {
    ...mapActions(['GET_CURRENT_COMPANY_CATALOGS', 'DELETE_COMPANY_CATALOGS', 'REGISTER_FACEBOOK_CATALOG', 'GET_FACEBOOK_COMPANYS']),
    redirectToIntegrationView() {
      this.$router.push('/admin/integrations');
    },
    selectShop(id, accessToken) {
      if(id === this.companySelectedId) {
        this.companySelectedId = ""
        this.companyAccessToken = accessToken
      }
      else {
        this.companySelectedId = id
        this.companyAccessToken = accessToken
      }
    },
    selectCatalog(id) {
      id === this.catalogSelectedId ? this.catalogSelectedId = "" : this.catalogSelectedId = id
    },
    catalogBack() {
      if (!this.companySelected)
        this.redirectToIntegrationView()
      else {
        this.DELETE_COMPANY_CATALOGS()
        this.companySelected = false
      }
    },
    async nextStep() {
      if(this.companySelectedId.length !== 0 && this.catalogSelectedId.length === 0) {
        try {
          this.loading = true
          await this.GET_CURRENT_COMPANY_CATALOGS({
            id: this.companySelectedId,
            token: this.companyAccessToken
          })
          this.loading = false
        } catch (e) {
          console.log(e)
        }
        this.companySelected = true
      }
      else if (this.companySelectedId.length !== 0 && this.catalogSelectedId.length !== 0) {
        try {
          this.loading = true
          await this.REGISTER_FACEBOOK_CATALOG({
            token: this.companyAccessToken,
            catalogId: this.catalogSelectedId,
            businessId: this.companySelectedId
          })
          this.loading = false
          this.redirectToIntegrationView()
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
  async created() {
    this.loading = true;
    await this.GET_FACEBOOK_COMPANYS({
      id: this.$route.params.type,
      redirect_uri: window.location.origin + window.location.pathname,
      ...this.$route.query
    })
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
