<template>
  <app-crud ref="crud"  v-slot="{ item }">
    <app-textbox v-model="item.name" :label="$t('requestLandings.name')" />
    <app-textarea v-model="item.description" :label="$t('requestLandings.description')" />
    <app-textarea v-model="item.welcomeText" :label="$t('requestLandings.welcomeText')" />
    <app-dropdown v-if="$hasModule('assets')" v-model="item.assetId" value="id" :label="$t('requests.asset')" getter="GET_ALL_ASSETS" :allow-nulls="true"/>
    <app-dropdown v-if="$hasModule('sites')" v-model="item.siteId" value="id" :label="$t('requests.site')" getter="GET_ALL_SITES" :allow-nulls="true"/>
    <app-dropdown class="hidden" v-model="item.assignedTo" value="id" :label="$t('requestLandings.responsible')" getter="GET_ALL_USERS"/>
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
export default {
  name: "RequestLandingEditPage",
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
