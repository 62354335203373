<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit"
                     @mousedown.self="closePopUp">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100"
                                     leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                                class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded shadow-xl">

                            <div @click="closePopUp" class="absolute top-0 right-0 z-10 cursor-pointer">
                                <XCircleIcon class="text-gray-400 w-7 h-7 focus-within:text-gray"/>
                            </div>
                            <div class="w-[20rem]">
                                <div class="flex justify-center w-full">
                                    <img src="/images/zigo.png" class="w-12">
                                </div>
                                <div class="flex justify-center w-full my-5">
                                    <span class="font-bold">{{ $t('conversations.addParticipantsToChat') }}</span>
                                </div>
                                <div class="block w-full p-5 text-center">
                                    <app-dropdown v-model="user" :options="users"></app-dropdown>
                                </div>
                                <div class="flex justify-center w-full gap-2 px-4 py-2 space-x-4">
                                    <app-button @click="closePopUp" type="secondary" background="light">
                                        {{ $t('cancel') }}
                                    </app-button>
                                    <app-button :disabled="!user.length >= 1" @click="doExec" type="primary"
                                                background="light">
                                        {{ $t('save') }}
                                    </app-button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {XCircleIcon} from '@heroicons/vue/24/outline'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import AppDropdown from "@/components/AppDropdown.vue";


export default {
  name: "UsersPopUp",
  components: {AppDropdown, Dialog, DialogPanel, TransitionChild, TransitionRoot, XCircleIcon},
  emits: ["update:open", "onYes"],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    },
    users: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      user: []
    }
  },
  methods: {
    closePopUp() {
      this.$emit('update:open', false)
    },
    doExec() {
      this.$emit('update:open', false)
      this.$emit('onYes', this.user)
      this.user = []
    }
  },
  mounted() {
  }
}
</script>
