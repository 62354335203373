<template>
  <div class="flex flex-col items-center justify-center h-screen px-4 py-5 sm:px-6 lg:px-8 sm:w-1/3 mx-auto">
    <div>
      <img class="w-auto h-12 mx-auto" src="/images/zigo.png" alt="zigo" />
    </div>
    <div class="w-full max-w-md space-y-8" v-bind:class="{ 'h-full': isMobile }">
      <form class="flex flex-col justify-between h-full" action="#" method="POST">
        <div class="mt-5">
          <input type="hidden" name="remember" value="true" />
          <div class="-space-y-px rounded">
            <div>
              <app-textbox id="name" v-model="name" :label="$t('createAccount.name')" type="text"></app-textbox>
            </div>
            <div>
              <app-textbox id="companyName" v-model="companyName" :label="$t('createAccount.companyName')" type="text"></app-textbox>
            </div>
            <div>
              <app-textbox id="email-address" v-model="email" :label="$t('createAccount.mailAddress')" @input="validateEmail" :autocomplete="false" type="text"></app-textbox>
            </div>
          </div>
          <div class="mt-2 w-8 w-full text-red-700">{{ error_login }}</div>
        </div>
        <div class="mt-8">
          <app-loading :loading="loading" />
          <div class="flex flex-col w-full space-y-4">
            <app-button @click="createAccount" type="primary" background="light" size="large" :disabled="!validate">
              {{ $t('createAccount.create') }}
            </app-button>
          </div>
          <div class="text-sm w-full text-right">
              <a href="#" @click="goToLogin" class="font-medium underline text-neutral-gray hover:text-purple-500 decoration-1"> {{ $t('createAccount.haveLogin') }} </a>
            </div>
          <div class="flex justify-center">
            <app-powered-by-zigo/>
          </div>
        </div>
        <div class="h-32 sm:h-0"></div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {validateEmail} from "@/services/validateEmail";

export default {
  name: 'CreateCompanyPage',
  components: {},
  data() {
    return {
      loading: false,
      completed: false,
      name: '',
      email: '',
      companyName: '',
    }
  },
  computed: {
    ...mapGetters({error_register: 'GET_ERROR_REGISTER', user: 'GET_CURRENT_USER'}),
    validate()
    {
      return this.name !== '' && this.companyName !== '' && this.email !== ''
    },
    isMobile() {
      return this.$isMobile()
    },
  },
  methods: {
    ...mapActions(['CREATE_COMPANY', 'SET_REDIRECT_AFTER_LOGIN']),
    ...mapMutations(['SET_LOADING']),
    validateEmail(val)
    {
      return validateEmail(val);
    },
    goToLogin() {
      this.SET_REDIRECT_AFTER_LOGIN(window.location.href)
      this.$router.push('/login');
    },
    goToAdminLink() {
      this.$router.push('/');
    },

    goToCompanyLink() {
      window.open(this.companyLink);
    },
    /* eslint-disable-next-line no-unused-vars */
    async createAccount() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      if(await this.CREATE_COMPANY(
          {
            name: this.name,
            companyName: this.companyName,
            email: this.email,
            "locale": "es-ar",
          }))
      {
        this.goToAdminLink();
      }
      this.loading = false;
    },

  },
  async created() {
    if (this.user !== null)
      this.$router.push('/admin/');
  },
}
</script>
