<template>
  <div class="flex items-center">
    <div v-if="recordId">
      <Menu as="div" v-for="filter in filters[record.name]" :key="filter.fieldId"
            class="relative inline-block text-left mr-4 border-[1px] rounded-lg px-3 h-[36px] py-2"
            :class="[ hasActiveFilter(filter) ? 'bg-primary-50 border-primary-500' : 'bg-white border-gray-300', filter.fieldId === 'name' ? 'hidden' : '']">
        <MenuButton class="flex items-center">
          <div class="flex items-center">
            <app-icon :icon="getField(filter.fieldId)?.icon" class="fill-primary-500"
                      :class="{ 'text-primary-500': hasActiveFilter(filter) }" :width="15" :height="15"/>
            <div class="flex items-center ml-1">
              <span class="fs-14 font-nhu-500" :class="hasActiveFilter(filter) ? 'text-primary-500' : 'text-gray-700'">
                {{ $t('records.' + localRecord.name + '.fields.' + getField(filter.fieldId)?.id) }}
              </span>
            </div>
            <div v-if="hasActiveFilter(filter)" class="rounded-full border-[1px] border-primary-500 bg-primary-50 w-[18px] h-[18px] flex items-center justify-center ml-1">
              <span class="fs-12 font-nhu-semi-bold text-primary-500">{{ filter.values ? filter.values?.length : 1 }}</span>
            </div>
          </div>
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
              class="absolute z-10 mt-2 w-[216px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none p-2 max-h-[400px] overflow-y-scroll">
            <ul class="overflow-auto">
              <li class="flex justify-between py-2 px-2">
                <span class="font-nhu-regular fs-14 text-gray-300">{{ $t('records.' + localRecord.name + '.fields.' + getField(filter.fieldId)?.id) }}</span>
                <app-icon icon="trash_regular" extra-class="fill-red-500 cursor-pointer" @click="deleteFilter(filter)"
                          :width="15" :height="15">
                </app-icon>
              </li>
              <li v-if="filter.fieldId === 'assignedTo'" class="hover:bg-primary-25">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden"
                         :checked="hasMeAsAssigned(filter.fieldId)" @input="toggleMe(filter.fieldId)">
                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                        :class="hasMeAsAssigned(filter.fieldId) ? 'bg-primary-25 border-primary-500' : 'border-gray-500'">
                    <span v-if="hasMeAsAssigned(filter.fieldId)" class="text-primary-500">
                      <app-icon icon="check_solid" :widht="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{$t('crud.assignedToMe')}}
                  </span>
                </label>
              </li>

              <li class="hover:bg-primary-25" v-if="allowNullValue">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden"
                         :checked="filters[record.name][filter.fieldId].values === null" @input="toggleNull(filter.fieldId)">
                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                        :class="filters[record.name][filter.fieldId].values === null ? 'bg-primary-25 border-primary-500' : 'border-gray-500' ">
                    <span v-if="filters[record.name][filter.fieldId].values === null" class="text-primary-500">
                      <app-icon icon="check_solid" :widht="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{$t('crud.without.'+filter.fieldId)}}
                  </span>
                </label>
              </li>
              <li v-for="option in getOptions(filter.fieldId)" :key="option.id" class="hover:bg-primary-25">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden" :value="option.id" :name="option.name"
                         :checked="isSelected(filter, option.id)" @input="toggleOption(filter, option)">

                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                        :class="isSelected(filter, option.id) ? 'bg-primary-25 border-primary-500' : 'border-gray-500' ">
                    <span v-if="isSelected(filter, option.id)" class="text-primary-500">
                      <app-icon icon="check_solid" :width="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{ $t(option.name) }}
                  </span>
                </label>
              </li>
            </ul>
          </MenuItems>
        </transition>
      </Menu>
      <Menu as="div" class="relative inline-block text-left" v-if="localRecord.filterable">
        <MenuButton>
          <app-icon icon="circle_plus_regular" class="fill-primary-500 mr-1" :width="15" :height="15">
            <span class="fs-14 font-nhu-500">{{ $t('crud.filters') }}</span>
          </app-icon>
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
              class="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 py-1">
            <div v-for="field in localRecord.fields.filter(x => x.filterable)" :key="field.id">
              <MenuItem v-slot="{ active }" v-if="!filters[record?.name][field.id]" @click="addFilter(field)">
                <div :class="[active ? 'bg-primary-25 text-gray-700' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                  <app-icon :icon="field.icon" :width="15" :height="15" class="fill-primary-500">
                  <span class="ml-1">
                    {{ $t('records.' + localRecord.name + '.fields.' + field.id) }}
                  </span>
                  </app-icon>
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div class="flex items-center" v-else>
      <Menu as="div" v-for="filter in filters[record.name]" :key="filter.fieldId"
        class="relative inline-block text-left mr-4 border-[1px] rounded-lg px-3 h-[36px] py-2"
        :class="[ hasActiveFilter(filter) ? 'bg-primary-50 border-primary-500' : 'bg-white border-gray-300', filter.fieldId === 'name' ? 'hidden' : '']">
        <MenuButton class="flex items-center">
          <div class="flex items-center">
            <app-icon :icon="getField(filter.fieldId)?.icon" class="fill-primary-500"
                      :class="{ 'text-primary-500': hasActiveFilter(filter) }" :width="15" :height="15"/>
            <div class="flex items-center ml-1">
              <span class="fs-14 font-nhu-500" :class="hasActiveFilter(filter) ? 'text-primary-500' : 'text-gray-700'">
                {{ $t('records.' + record.name + '.fields.' + getField(filter.fieldId)?.id) }}
              </span>
            </div>
            <div v-if="hasActiveFilter(filter)" class="rounded-full border-[1px] border-primary-500 bg-primary-50 w-[18px] h-[18px] flex items-center justify-center ml-1">
              <span class="fs-12 font-nhu-semi-bold text-primary-500">{{ filter.values ? filter.values?.length : 1 }}</span>
            </div>
          </div>
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
            class="absolute z-10 mt-2 w-[216px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none p-2 max-h-[400px] overflow-y-scroll">
            <ul class="overflow-auto">
              <li class="flex justify-between py-2 px-2">
                <span class="font-nhu-regular fs-14 text-gray-300">{{ $t('records.' + record.name + '.fields.' + getField(filter.fieldId)?.id) }}</span>
                <app-icon icon="trash_regular" extra-class="fill-red-500 cursor-pointer" @click="deleteFilter(filter)"
                  :width="15" :height="15">
                </app-icon>
              </li>
              <li v-if="filter.fieldId === 'assignedTo'" class="hover:bg-primary-25">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden"
                         :checked="hasMeAsAssigned(filter.fieldId)" @input="toggleMe(filter.fieldId)">
                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                        :class="hasMeAsAssigned(filter.fieldId) ? 'bg-primary-25 border-primary-500' : 'border-gray-500'">
                    <span v-if="hasMeAsAssigned(filter.fieldId)" class="text-primary-500">
                      <app-icon icon="check_solid" :widht="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{$t('crud.assignedToMe')}}
                  </span>
                </label>
              </li>

              <li class="hover:bg-primary-25" v-if="allowNullValue">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden"
                         :checked="filters[record.name][filter.fieldId].values === null" @input="toggleNull(filter.fieldId)">

                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                        :class="filters[record.name][filter.fieldId].values === null ? 'bg-primary-25 border-primary-500' : 'border-gray-500' ">
                    <span v-if="filters[record.name][filter.fieldId].values === null" class="text-primary-500">
                      <app-icon icon="check_solid" :widht="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{$t('crud.without.'+filter.fieldId)}}
                  </span>
                </label>
              </li>
              <li v-for="option in getOptions(filter.fieldId)" :key="option.id" class="hover:bg-primary-25">
                <label class="flex items-center py-2 px-2">
                  <input type="checkbox" class="hidden" :value="option.id" :name="option.name"
                    :checked="isSelected(filter, option.id)" @input="toggleOption(filter, option)">

                  <span class="w-4 h-4 flex-shrink-0 border mr-2 rounded-[4px] flex items-center justify-center"
                    :class="isSelected(filter, option.id) ? 'bg-primary-25 border-primary-500' : 'border-gray-500' ">
                    <span v-if="isSelected(filter, option.id)" class="text-primary-500">
                      <app-icon icon="check_solid" :width="10" :height="10" class="fill-primary-500"></app-icon>
                    </span>
                  </span>
                  <span class="pl-1 font-nhu-regular fs-14 capitalize text-gray-500">
                    {{ getNameOption(option.name) }}
                  </span>
                </label>
              </li>
            </ul>
          </MenuItems>
        </transition>
      </Menu>
      <Menu as="div" class="relative inline-block text-left" v-if="record.filterable">
        <MenuButton>
          <app-icon icon="circle_plus_regular" class="fill-primary-500 mr-1" :width="15" :height="15">
            <span class="fs-14 font-nhu-500">{{ $t('crud.filters') }}</span>
          </app-icon>
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
            class="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 py-1">
            <div v-for="field in record.fields.filter(x => x.filterable)" :key="field.id">
              <MenuItem v-slot="{ active }" v-if="!filters[record?.name][field?.id] && isModuleActive(field)" @click="addFilter(field)">
              <div :class="[active ? 'bg-primary-25 text-gray-700' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                <app-icon :icon="field.icon" :width="15" :height="15" class="fill-primary-500">
                  <span class="ml-1">
                    {{ $t('records.' + record.name + '.fields.' + field.id) }}
                  </span>
                </app-icon>
              </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { mapGetters } from 'vuex';

export default {
  name: "AppCrudFilters",
  components: { Menu, MenuItems, MenuItem, MenuButton },
  props: {
    recordId: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    allowNullValue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({ filters: 'GET_FILTERS', record: 'GET_RECORD', records: 'GET_RECORDS', user: 'GET_CURRENT_USER', company: 'GET_CURRENT_COMPANY', menu: 'GET_MAIN_MENU' }),
    localRecord() {
      return this.records[this.recordId];
    },
  },
  methods: {
    getNameOption(name) {
      if(name.includes('records.') || name.includes('crud.')){
        return this.$t(name)
      }
      return name
    },
    isModuleActive(field){
      let isActive = true
      if(this.company.modules[field?.record] !== undefined && field.type === 'record'){
        let isModule = !!this.menu.find(x => x.id === field.record)?.module
        isActive = this.company.modules[field?.record] || !isModule
      }
      return isActive
    },
    setAndUpdate(){
      this.$store.commit('SET_FILTERS', this.filters)
      this.$emit('updateData')
    },
    getField(fieldId) {
      if(this.recordId){
        return this.localRecord.fields.find(x => x.id === fieldId);
      }
      return this.record.fields.find(x => x.id === fieldId);
    },
    getOptions(fieldId) {
      const field = this.getField(fieldId);
      if (field !== null) {
        if (field.options) {
          return field.options;
        } else if (field.type === 'record') {
          const record = this.records[field.record];
          return this.$store.getters[record.options.getter]?.filter(x => !x.isDeleted);
        } else if (field.type === 'date') {
          return [
            {name: "today", id: "today", color: "blue"},
            {name: "tomorrow", id: "tomorrow", color: "blue"},
            {name: "thisWeek", id: "thisWeek", color: "blue"},
            {name: "nextWeek", id: "nextWeek", color: "blue"},
            {name: "thisMonth", id: "thisMonth", color: "blue"},
            {name: "next30days", id: "next30days", color: "blue"},
            {name: "overdue", id: "overdue", color: "blue"},
          ];
        }
      }
      return []
    },
    hasMeAsAssigned(filter) {
      const resp = this.filters[this.record.name][filter].values.includes("#ME#")
      return resp
    },
    hasActiveFilter(filter) {
      return filter.values?.length > 0 || filter.values === null;
    },
    toggleMe(fieldId){
      if(this.hasMeAsAssigned(fieldId)){
        this.filters[this.record.name][fieldId].values = this.filters[this.record.name][fieldId].values.filter(x => x !== "#ME#")
      } else {
        this.filters[this.record.name][fieldId].values.push("#ME#")
      }
      this.setAndUpdate()
    },
    toggleNull(fieldId){
      if(this.filters[this.record.name][fieldId].values){
        this.filters[this.record.name][fieldId] = { fieldId, values: null };
      } else {
        this.filters[this.record.name][fieldId] = { fieldId, values: [] };
      }
      this.setAndUpdate()
    },
    isSelected(filter, value) {
      return filter.values?.includes(value);
    },
    toggleOption(filter, option) {
      if(!filter.values) filter.values = [];
      if (filter.values?.includes(option.id)) {
        filter.values = filter.values?.filter(val => val !== option.id);
      } else {
        filter.values?.push(option.id);
      }
      this.filters[this.record.name][filter.fieldId] = filter;
      this.setAndUpdate()
    },
    addFilter(field) {
      this.filters[this.record.name][field.id] = { fieldId: field.id, values: [] };
      this.$store.commit('SET_FILTERS', this.filters)
    },
    deleteFilter(filter) {
      delete this.filters[this.record.name][filter.fieldId];
      this.setAndUpdate()
    },
  },
}
</script>

<style scoped></style>
