<template>
  <Dialog as="div" class="relative z-99" :open="open">
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit"
           @mousedown.self="closePopUp">
        <DialogPanel
            class="relative overflow-hidden text-left transition-all transform bg-white rounded-[8px] shadow-xl">
          <div :class="isMobile ? 'w-[20rem]' : 'w-[32rem]'">
            <div class="block w-full p-6">
              <pre class="font-nhu-semi-bold fs-18" :class="isMobile ? 'text-center' : ''">{{ $t('records.requests.actions.reject') }}</pre>
              <div class="mt-4">
                <app-textarea v-model="reason" :label="$t('records.requests.actions.reject_message')"/>
              </div>
            </div>
            <div class="flex w-full p-6 space-x-4" :class="isMobile ? 'justify-center' : 'justify-end'">
              <app-button :clean="true" @click="closePopUp" extra-class="px-4 py-3 text-primary-500 border-primary-500"
                          type="secondary" background="light">
                {{ $t('back') }}
              </app-button>
              <app-button @click="doReject" extra-class="px-4 py-3" type="primary" background="light">
                {{ $t('records.requests.actions.confirm') }}
              </app-button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
<script>
import {Dialog, DialogPanel} from "@headlessui/vue";

export default {
  name: "RejectDialog",
  components: {
    Dialog,
    DialogPanel
  },
  emits: ['reject', 'update:open'],
  props: {
    item: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reason: ''
    }
  },
  computed: {
    isMobile() {
      return this.$isMobile()
    },
  },
  methods: {
    async doReject() {
      let data = {
        id: this.item.id,
        reason: this.reason
      }
      await this.$emit('reject', data)
      this.$emit('closeDialog', false)
    },
    closePopUp() {
      this.$emit('closeDialog', false)
    },
  },
}
</script>
