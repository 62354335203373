<template>
  <div class="h-20"></div>
  <div class="z-50 flex-grow w-full mt-4 text-right border-t border-gray-200 ">
    <app-loading :loading="loading"/>
    <div class="px-4 py-3 mx-auto 2xl:w-1/2 sm:px-6 sm:pl-1/3">
      <div class="flex flex-row-reverse gap-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppActions",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
};
</script>
