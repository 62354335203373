<template>
  <div class="flex-col flex">
    <div class="flex justify-start">
      <div class="flex" v-if="log.modificationId">
        <user-avatar :user="user" width="w-[42px] h-[42px]"/>
      </div>
      <div class="flex flex-col ml-2 pl-2 fs-14 lh-20 text-gray-900">
        <div class="flex flex-row">
          <span class="font-nhu-semi-bold">
            {{ user?.name }}
          </span>
          <span class=" font-nhu-regular mx-1 text-gray-500">
            {{ formatTime(log.creationTime) }}
          </span>
        </div>
        <span class="font-nhu-regular text-gray-900" v-if="log.logType === 'create'">
          {{
            $t('logs.hasBeen') + ' ' + $t('logs.create') + ' ' + $t('logs.' + record.name + '.the') + ' ' + $t('logs.' + record.name + '.name')
          }}
        </span>
        <span v-else class="font-nhu-regular">
          {{
            $t('logs.hasBeen') + ' ' + $t('logs.' + log.logType) + ' ' + $t('logs.' + log.field + '.the') + ' ' + $t('logs.' + log.field + '.name') + ' ' + $t('logs.' + record.name + '.the') + ' ' + $t('logs.' + record.name + '.name')
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import userAvatar from "@/components/UserAvatar";
import {mapGetters} from "vuex";

export default {
  name: "LogCard",
  components: {userAvatar},
  props: {
    log: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({record: 'GET_RECORD', users: 'GET_ALL_USERS'}),
    user(){
      return this.users.find(x => x.id === this.log.modificationId)
    }
  },
  methods: {
    formatTime(inputDate) {
      let date = new Date(inputDate);

      let hour = date.getHours();
      let minutes = date.getMinutes();

      if (hour < 10) hour = '0' + hour;
      if (minutes < 10) minutes = '0' + minutes;

      return `${hour}:${minutes}`;
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
