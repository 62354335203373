<template>
  <app-crud ref="crud"  v-slot="{}"
            @duplicate="duplicateSite()" @delete="deleteSite()"
            @assignToWorkOrder="assingToWorkOrder()" :loading="loading" :parent-name="getParentName(site.parentId)">
    <div>

      <div v-if="site.parentId !== '' && site.parentId !== null"
           class="flex items-center gap-x-2 mb-4 cursor-pointer fill-primary-500"
           @click="goToParent()">
        <app-icon icon="chevron_left_solid" :width="12" :height="12"/>
        <button
            class="text-primary-500 border-0 border-primary-500 flex items-center h-[20px] rounded-xl font-nhu-500 fs-14">
          {{ getParentName(site.parentId) }}
        </button>
      </div>

      <app-label :label="$t('sites.location')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="site.location"/>

      <app-label :label="$t('sites.description')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="site.description"/>

      <app-label :label="$t('sites.images')"
                 :icon="'image_regular'"
                 :icon-color="'fill-primary-500'"
                 :images="true"
                 :value="site.media"/>


      <div class="mt-4 hidden" v-if="site.qrCode">
        <div class="mb-2">
          <span class="font-nhu-semi-bold fs-14">{{ $t('sites.qr') }}</span>
        </div>
        <app-image-qr :src="site.qrCode" :disabled="true"/>
      </div>

      <app-crud-properties v-model="site.props" />

      <div v-if="isDepthSmallerThanThree">
<!--        <div>-->
<!--          <div class="h-0 border-b border-gray-50 my-4"/>-->
<!--        </div>-->
        <div>
          <div class="flex items-center mb-1 fill-primary-500">
            <app-icon extra-class="mr-2" icon="location_dot_regular" :width="14" :height="14"/>
            <span class="font-nhu-semi-bold fs-14">{{ $getRecordLabel('sites', 'plural') ? $getRecordLabel('sites', 'plural') : $t('sites.subLocations') }}</span>
          </div>
          <div v-for="subLocation in subLocations" :key="subLocation.id">
            <div class="flex items-center justify-between mb-2 cursor-pointer fill-primary-500"
                 @click="goToSubLocation(subLocation.id)">
              <span class="font-nhu-regular fs-14 text-primary-500">{{ subLocation.name }}</span>
              <app-icon extra-class="mr-2" icon="chevron_right_solid" :width="14" :height="14"/>
            </div>
          </div>
          <app-button :icon="PlusCircleIcon" :clean="true"
                      v-if="$hasPermission('create_sites')"
                      extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14"
                      @click="addSubLocation">{{ $t('sites.addSubLocation') }}
          </app-button>
        </div>
      </div>
      <!--        <div class="mb-2">-->
      <!--            <div class="flex items-center">-->
      <!--                <app-icon extra-class="mr-2" icon="users_regular" :width="20" :height="20"-->
      <!--                          :fill="this.$colors.primary[500]"/>-->
      <!--                <span class="font-nhu-semi-bold fs-14">{{ $t('sites.teamsInCharge') }}</span>-->
      <!--            </div>-->
      <!--            <p class="font-nhu-regular fs-14">{{ site.teamInCharge }}</p>-->
      <!--        </div>-->
      <div v-if="site.files?.length">
        <div class="h-0 border-b border-gray-50 my-4"/>
        <div class="flex items-center mb-1 fill-primary-500">
          <app-icon extra-class="mr-2" icon="paperclip_regular" :width="14" :height="14"/>
          <span class="font-nhu-semi-bold fs-14">
            {{ $t('workorders.files') }}
          </span>
        </div>
        <app-file-list v-model="site.files" :rounded="false" :allow-upload-file="false"/>
      </div>
      <app-log :logs="site.logs"/>
      <div>
        <div class="h-0 border-b border-gray-50 my-4"/>
      </div>
      <div class="flex w-full items-center mb-2">
        <work-order-history :filter="getFilter"/>
<!--        <div class="w-full">
          <app-icon icon="inbox_full_regular" width="20" height="20" class="fill-primary-500 font-nhu-semi-bold fs-14">
            <span class="ml-2">{{ $t('workorders.history') }}</span>
          </app-icon>
          <div v-if="workOrdersInSites.length > 0">
            <div class="p-6 border-b-[1px] border-gray-100 flex flex-col"
                 v-for="workOrder in workOrdersInSites"
                 :key="workOrder.id">
              <work-order-card :work-order="workOrder"/>
            </div>
          </div>
          <div v-else class="text-gray-700 pt-2">
            Aún no hay órdenes de trabajo en este activo
          </div>
        </div>-->
      </div>
    </div>
  </app-crud>
  <new-site ref="popUp" :show-popup="showPopup" @close="closePopUp" @reload="reload"></new-site>
</template>

<script>
import CRUD from '@/crud'
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppIcon from "@/components/AppIcon.vue";
import AppButton from "@/components/AppButton.vue";
import {PlusCircleIcon} from "@heroicons/vue/24/outline";
import NewSite from "@/pages/sites/NewSite.vue";
import AppImageQr from "@/components/AppImageQr.vue";
import WorkOrderHistory from "@/pages/workorders/WorkOrderHistory";
import emitter from 'tiny-emitter/instance'

export default {
  name: "SiteViewPage",
  components: {WorkOrderHistory, AppImageQr, NewSite, AppButton, AppIcon},
  emits: ['reload'],
  data() {
    return {
      userNames: [],
      showPopup: false,
      loading: true,
      workOrdersInSites: [],
      siteId: '',
    }
  },
  computed: {
    ...mapGetters({
      site: 'GET_SITE',
      view: 'GET_VIEW',
      sites: 'GET_ALL_SITES',
      company: 'GET_CURRENT_COMPANY'
    }),
    getFilter() {
      return {siteId: [this.$route.params.id]}
    },
    subLocations() {
      return this.site?.childs
    },
    isDepthSmallerThanThree() {
      return this.getDepth(this.site.id) < 2;
    }
  },
  methods: {
    ...mapActions(['SAVE_SITE', 'READ_SITE', 'READ_ALL_SITES', 'READ_ALL_ASSETS', 'DELETE_SITE',]),
    ...mapMutations(['SET_VIEW', 'SET_SITE',]),
    PlusCircleIcon,
    async doLoad() {
      this.loading = true;
      const id = this.$route.params.id;
      if (id !== undefined) {
        if (id !== 'create') {
          this.action = 'view';
          await this.READ_SITE({id: this.$route.params.id})
        }
      }
      this.loading = false;
    },
    async addSubLocation() {
      let depth = this.getDepth(this.site.id);

      if (depth < 3) {
        await this.$refs.popUp.create(this.site.id)
        this.showPopup = true;
      }
    },
    async closePopUp() {
      this.$emit('reload')
      this.showPopup = false;
      await this.doLoad()
    },
    async reload() {
      this.$emit('reload')
      await this.READ_SITE({id: this.$route.params.id})
      await this.READ_ALL_SITES();
      // this.$refs.crud.updateList();
      emitter.emit('updateList');
      this.showPopup = false;
    },
    goToSubLocation(subLocationId) {
      this.$router.push('/sites/' + subLocationId)
    },
    getParentName(parentId) {
      const parent = this.sites?.find(asset => asset.id === parentId)
      return parent?.name
    },
    goToParent() {
      this.$router.push('/sites/' + this.site.parentId)
    },
    async duplicateSite() {
      this.$router.push('/sites/create?duplicate=' + this.site.id);
    },
    async assingToWorkOrder() {
      this.CREATE_WORKORDER()
      let workOrder = this.workorder
      workOrder.siteId = this.site.id
      await this.SAVE_WORKORDER(workOrder)

      this.$router.push(CRUD.getRecordEditUrl(this.records.workorder, this.workorder.id));
      this.$refs.crud.updateList();
    },
    getDepth(id) {
      let depth = 0;

      let location = this.sites?.find(site => site.id === id);

      while (location && location.parentId) {
        depth++;
        location = this.sites.find(site => site.id === location.parentId);
      }

      return depth;
    }
  },

}
</script>
