<template>
  <div class="sm:flex sm:items-center">
    <span class="font-nhu-semi-bold text-[20px]">{{ $t("admin.reports.title") }}</span>
  </div>

  <span class="font-nhu-bold text-[16px]">{{ $t("admin.reports.subtitle") }}</span>
  <div class="h-4" />

  <div class="grid grid-cols-1 gap-10 mt-2 sm:grid-cols-2 lg:grid-cols-5">
    <!-- Card -->
    <div
      v-for="card in cards"
      :key="card.name"
      class="overflow-hidden bg-white rounded shadow"
    >
      <div class="p-5">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <component :is="card.icon" class="w-6 h-6 text-gray-400" aria-hidden="true" />
          </div>
          <div class="flex-1 w-0 ml-5">
            <dl>
              <dt class="text-sm font-medium text-gray-500 truncate">{{ card.name }}</dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">{{ card.amount }}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="px-5 py-3 bg-gray-50">
        <div class="text-sm">
          <a :href="card.href" class="font-medium text-cyan-700 hover:text-cyan-900">
            {{ card.name }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="m-4" />

  <div class="mt-4 p-4 border rounded-xl">
    Personas
    <app-table url="/api/reports/invitations/people" :columns="peopleColumns">
      <template #item-name="{firstName, lastName}">
        {{ firstName }} {{ lastName }}
        </template>
      <template #item-progress="{progress}">
        <app-progress :val="progress" max="100" size="10"></app-progress>
      </template>
    </app-table>
  </div>
  <div class="mt-4 p-4 border rounded-xl">
    Cursos
    <app-table url="/api/reports/invitations/course" :columns="courseColumns">
      <template #item-progress="{progress}">
        <app-progress :val="progress" max="100" size="10"></app-progress>
      </template>
    </app-table>
  </div>
  <div class="mt-4 p-4 border rounded-xl">
    Registor en cursos
    <div>
      <Line id="my-chart-id" :options="chartOptions" :data="chartData" />
    </div>
  </div>
  <div class="h-8" />
</template>

<script>
import {mapActions} from "vuex";
import { ScaleIcon } from "@heroicons/vue/24/solid";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "ResultsPage",
  components: { Line },
  data() {
    return {
      cards: [
        { name: "Completition rate", href: "#", icon: ScaleIcon, amount: "70%" },
        { name: "progress rate", href: "#", icon: ScaleIcon, amount: "68%" },
        { name: "Answer accuracy", href: "#", icon: ScaleIcon, amount: "45%" },
        { name: "interactivity rate", href: "#", icon: ScaleIcon, amount: "55%" },
        { name: "In progress", href: "#", icon: ScaleIcon, amount: "5" },
      ],
      chartData: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Registrados",
            backgroundColor: "#f87979",
            data: [40,20,30,50,40,30,25],
          },
        ],
      },
      peopleColumns: [
        { value: 'name', text: 'Persona', sortable: true },
        { value: 'progress', text: 'Progreso', sortable: true },
        { value: 'accepted', text: 'Aceptados', sortable: true },
        { value: 'pending', text: 'Pendientes', sortable: true },
        { value: 'total', text: 'Total', sortable: true },
      ],
      courseColumns: [
        { value: 'name', text: 'Curso', sortable: true },
        { value: 'progress', text: 'Progreso', sortable: true },
        { value: 'accepted', text: 'Aceptados', sortable: true },
        { value: 'pending', text: 'Pendientes', sortable: true },
        { value: 'total', text: 'Total', sortable: true },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
    };
  },
  methods: {
    ...mapActions(['REPORT_MAIN_REPORT'])

  },
  async created() {
    await this.REPORT_MAIN_REPORT();
  },
};
</script>
