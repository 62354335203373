<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
            class="relative flex items-center justify-center h-full p-0 p-4 text-center bg-black bg-opacity-50 min-h-fit w-full"
            @mousedown.self="closePopUp">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative overflow-hidden text-left transition-all transform bg-white rounded shadow-xl min-w-[800px] max-w-[11/12]">
              <div class="flex flex-col p-6 border-b-[1px] justify-between">
                <div class="font-nhu-semi-bold fs-18 w-full justify-between flex">
                  <span>
                    {{ $t('crud.saveOnMyFilters') }}
                  </span>
                  <div class="w-fit h-fit hover:fill-gray-500 ml-4" @click="closePopUp">
                    <app-icon icon="xmark_regular" class="cursor-pointer" :width="20" :height="20"></app-icon>
                  </div>
                </div>
                <div class="my-4">
                  <app-textbox v-model="localFilter.name" :label="$t('crud.enterFilterName')" class="w-72"></app-textbox>
                </div>
                <div class="h-1 my-6 border-b-[1px]"></div>
                <div class="flex items-center w-full justify-between">
                  <label class="flex items-center my-1 w-fit">
                    <span class="w-4 h-4 border mr-2 rounded-sm flex items-center justify-center" :class="localFilter.isPublic ? 'bg-primary-500 fill-white' : 'bg-white'" @click="localFilter.isPublic = !localFilter.isPublic">
                      <span v-if="localFilter.isPublic">
                        <app-icon icon="check_solid" :width="10" :height="10"></app-icon>
                      </span>
                    </span>
                    <span class="font-nhu-regular fs-14 capitalize">{{ $t('crud.showOnCompany') }}</span>
                  </label>
                  <div class="flex gap-x-2">
                    <app-button @click="cancel" :clean="true" type="terciary"
                                background="light"
                                extra-class="font-nhu-500 fs-14 text-primary-500 border-primary-500 px-4 py-3 rounded-2 border-[1px] shadow-sm shadow-inner">
                      {{ $t('cancel') }}
                    </app-button>
                    <app-button @click="save" type="secondary" background="light"
                                extra-class="font-nhu-500 fs-14 text-white border-primary-500 bg-primary-500 px-4 py-3 rounded-2 border-[1px]">
                      {{ $t('save') }}
                    </app-button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import AppTextbox from "@/components/AppTextbox";

export default {
  name: "AppFilterDialog",
  emits: ["save"],
  components: {AppTextbox, Dialog, DialogPanel, TransitionChild, TransitionRoot},
  props: {
    open: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => {
        return {
          name: '',
          isPublic: false,
        }
      }
    }
  },
  data() {
    return {
      localFilter: {
        name: '',
        isPublic: false,
      }
    }
  },
  methods: {
    cancel(){
      this.closePopUp();
    },
    save(){
      this.$emit("save", this.localFilter);
      this.closePopUp();
    },
    closePopUp() {
      this.$emit("close");
    },
  },
  created() {
    this.localFilter = this.filter;
  }
}
</script>

<style scoped>

</style>
