<template>
    <div class="sm:flex sm:items-center">
        <span class="font-nhu-semi-bold text-[20px]">{{$t('admin.actions.tags.name')}}</span>
    </div>
    <div class="h-4" />

    <div class="flex gap-2">
        <app-button @click="doCreate" type="primary" background="light" :icon="PlusIcon"> {{ $t('admin.actions.tags.create') }} </app-button>
        <app-button @click="doDelete" :disabled="selection.length === 0"> {{ $t('admin.actions.tags.delete') }} </app-button>
        <div class="my-auto"> {{ message }} </div>
    </div>

    <app-table ref="list" v-model:itemsSelected="selection" :multi="true"
               value="id" url="/api/tags" :columns="columns">
        <template #item-name="item">
            <div @click="doEdit(item)" class="cursor-pointer underline">
                {{ item.name }}
            </div>
        </template>
    </app-table>

    <new-tag ref="popup" @reload="reload" />

    <app-confirm-popup v-model:open="showDeletePopUp" @onYes="doDelete2"
                       :message="$t('admin.actions.tags.sureToDelete')" />
</template>

<script>
import {DocumentPlusIcon, PlusIcon} from "@heroicons/vue/24/outline";
import {mapActions} from "vuex";
import NewTag from "@/pages/admin/actions/tags/NewTag.vue";

export default {
  name: "TagList",
  components: {NewTag},
  data() {
    return {
        ...{ PlusIcon, DocumentPlusIcon },
        message: '',
        selection: [],
        showDeletePopUp: false,
        columns: [
            { value: 'name', text: this.$t('admin.actions.name'), sortable: true },
        ],
    }
  },
  methods: {
    ...mapActions(['DELETE_TAG_LIST']),

    async reload()
    {
        await this.$refs.list.load();
    },
    doCreate()
    {
        this.$refs.popup.create();
    },
    doEdit(item)
    {
        this.$refs.popup.edit(item.id);
        //this.$router.push('/admin/site/' + item.id + '/edit');
    },
    doDelete() {
        this.showDeletePopUp = true;
    },
    async doDelete2() {
        let tags = this.selection;
        if (await this.DELETE_TAG_LIST({ deletes: tags })) {
            if (this.selection.length === tags.length) {
                this.selection = [];
            }
            this.reload();
            this.message = this.$t('admin.actions.tags.deleted');
        }
    },
  },
  async created() {
  },
}
</script>

<style scoped>

</style>
