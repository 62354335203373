import API from '@/api'

const state = {
  MESSAGE: {
    body: '',
    senderId: '',
    media: '',
    messageType: '',
    conversationId: ''
  },
  MESSAGES: { data:[] },
  ALL_MESSAGES: []
}

const getters = {
  GET_MESSAGE: state => state.MESSAGE,
  GET_MESSAGES: state => state.MESSAGES,
  GET_ALL_MESSAGES: state => state.ALL_MESSAGES,
}

const mutations = {
  // MESSAGES
  SET_MESSAGE(state, payload) {
    state.MESSAGE = payload
  },
  SET_ALL_MESSAGES(state, payload) {
    state.ALL_MESSAGES = payload
  },
  SET_MESSAGES(state, payload) {
    state.MESSAGES = payload
  },
  CREATE_MESSAGE(state, payload) {
    state.MESSAGE = payload
    state.MESSAGES.data.push(payload)
  },
  UPDATE_MESSAGE(state, payload) {
    if (state.MESSAGE !== null && state.MESSAGE.id === payload.id)
      state.MESSAGE = payload;

    const index = state.MESSAGES.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.MESSAGES.data[index], payload)
  },
  DELETE_MESSAGE(state, payload) {
    if (state.MESSAGE?.id === payload.id)
      state.MESSAGE = {}
    state.MESSAGES.data = state.MESSAGES.data.filter(e => e.id !== payload.id)
  },
  ADD_MESSAGE(state, payload) {
    state.MESSAGES.push(payload)
  }
}

const actions = {
  // CRUD MESSAGES
  async READ_MESSAGES(context, payload) {
    const response = await API.Get('/api/messages', payload)
    if (response.type === 'Success') {
      context.commit('SET_MESSAGES', response.res.data)
      return response.res.data
    }
    context.commit('SET_MESSAGES', [])
  },
  async READ_ALL_MESSAGES(context) {
    const response = await API.Get('/api/messages/all')
    if (response.type === 'Success') {
      context.commit('SET_ALL_MESSAGES', response.res.data)
      return response.res.data
    }
    context.commit('SET_ALL_MESSAGES', [])
  },
  async CREATE_MESSAGE (context){
    context.commit('SET_MESSAGE', { body: '', senderId: '', media: '', conversationId: '' })
  },
  async SAVE_MESSAGE (context, payload){
    if(payload.id === undefined)
    {
      await API.Post('/api/messages', payload)
    }
  },
  async READ_MESSAGE(context, payload) {
    const response = await API.Get(`/api/messages/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_MESSAGE', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_MESSAGE(context, payload) {
    const response = await API.Put(`/api/messages/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_MESSAGE', response.res.data)
      return response.res.data
    }
  },
  async DELETE_MESSAGE(context, payload) {
    const response = await API.Delete(`/api/messages/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_MESSAGE', response.res.data)
      return
    }
  },
  async DELETE_MESSAGE_LIST(context, payload) {
    const response = await API.Patch('/api/messages',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_MESSAGE', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
