import API from '@/api'

const state = {
  TAG: {
    name: '',
  },
  TAGS: { data:[] }
}

const getters = {
  GET_TAG: state => state.TAG,
  GET_TAGS: state => state.TAGS,
}

const mutations = {
  // TAGS
  SET_TAG(state, payload) {
    state.TAG = payload
  },
  SET_TAGS(state, payload) {
    state.TAGS = payload
  },
  CREATE_TAG(state, payload) {
    state.TAG = payload
    state.TAGS.data.push(payload)
  },
  UPDATE_TAG(state, payload) {
    if (state.TAG !== null && state.TAG.id === payload.id)
      state.TAG = payload;

    const index = state.TAGS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.TAGS.data[index], payload)
  },
  DELETE_TAG(state, payload) {
    if (state.TAG?.id === payload.id)
      state.TAG = {}
    state.TAGS.data = state.TAGS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD TAGS
  async READ_TAGS(context, payload) {
    const response = await API.Get('/api/tags', payload)
    if (response.type === 'Success') {
      context.commit('SET_TAGS', response.res.data)
      return response.res.data
    }
    context.commit('SET_TAGS', [])
  },
  async CREATE_TAG (context){
    context.commit('SET_TAG', { name: '' })
  },
  async SAVE_TAG (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/tags', payload)
      if(response.type === 'Success'){
        context.commit('SET_TAG', response.res.data)
      }
    }
    else
    {
      const response = await API.Put(`/api/tags/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_TAG', response.res.data)
      }
    }
  },
  async READ_TAG(context, payload) {
    const response = await API.Get(`/api/tags/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_TAG', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_TAG(context, payload) {
    const response = await API.Put(`/api/tags/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_TAG', response.res.data)
      return response.res.data
    }
  },
  async DELETE_TAG(context, payload) {
    const response = await API.Delete(`/api/tags/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_TAG', response.res.data)
      return
    }
  },
  async DELETE_TAG_LIST(context, payload) {
    const response = await API.Patch('/api/tags',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_TAG', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
