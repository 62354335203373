<template>
    <input
      :id="id"
      :name="id"
      :value="modelValue"
      :placeholder="placeholder"
      :autocomplete="internal_autocomplete"
      :type="type"
      @change="changeValue"
      :readonly="lock"
      class="border-b border-purple-light p-1 mt-2 block w-full focus:ring-purple focus:border-b focus:border-purple sm:text-sm" />
</template>

<script>
export default {
  name: "AppEmailbox",
  components:  { },
  props: {
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: null,
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  computed: {
    internal_autocomplete() {
      return !this.autocomplete ? "off" : "";
    }
  },
  methods: {
    changeValue(e) {
      this.$emit("update:modelValue", e.target.value);
    }
  }
};
</script>
