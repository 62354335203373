import API from '@/api'
import router from '@/router'
import * as Sentry from "@sentry/vue";
import i18n from "@/locales/i18n";

const state = {
    USER: null,
    TOKEN: null,
    ERROR_LOGIN: null,
    ERROR_REGISTER: null,
    ERROR_FORGOT_PASS: null,
    REDIRECT_AFTER_LOGIN: '/workorders',
    VALIDATE_ERROR: null,
    TOKEN_TIME: localStorage.getItem('zigo-token-time'),
    DEFAULT_FILTERS: {
        workorders: {status: {"fieldId": "status", "values": ["inProgress", "pending", "onHold"]}},
        assets: {},
        sites: {},
        categories: {},
        users: {},
        requests: {},
        parts: {},
        metrics: {},
        dashboard: {},
    },
}

const getters = {
    GET_CURRENT_USER: state => state.USER,
    GET_TOKEN: state => state.TOKEN,
    GET_ERROR_LOGIN: state => state.ERROR_LOGIN,
    GET_ERROR_REGISTER: state => state.ERROR_REGISTER,
    GET_ERROR_FORGOT_PASS: state => state.ERROR_FORGOT_PASS,
    GET_TOKEN_TIME: state => state.TOKEN_TIME,
    GET_VALIDATE_ERROR: stage => stage.VALIDATE_ERROR,
}

const mutations = {
    SET_CURRENT_USER(state, payload) {
        state.USER = payload
    },
    SET_TOKEN(state, payload) {
        if (payload === null) {
            localStorage.removeItem('zigo-token')
        } else {
            localStorage.setItem('zigo-token', payload)
        }
        state.TOKEN = payload
    },
    SET_TOKEN_TIME(state, payload) {
        if (payload === null) {
            localStorage.removeItem('zigo-token-time')
        } else {
            localStorage.setItem('zigo-token-time', payload)
        }
        state.TOKEN_TIME = payload
    },
    SET_ERROR_LOGIN(state, payload) {
        state.ERROR_LOGIN = payload
    },
    SET_ERROR_REGISTER(state, payload) {
        state.ERROR_REGISTER = payload
    },
    SET_ERROR_FORGOT_PASS(state, payload) {
        state.ERROR_FORGOT_PASS = payload
    },
    SET_REDIRECT_AFTER_LOGIN(state, payload) {
        if(payload.includes('/login'))
            payload = '/'

        state.REDIRECT_AFTER_LOGIN = payload
    },
    SET_VALIDATE_ERROR(state, payload) {
        state.VALIDATE_ERROR = payload
    },
}

const actions = {
    async USER(context) {
        try {
            context.commit('SET_CURRENT_USER', null)
            const response = await API.Get('api/auth/user')
            if (response.type === 'Success') {
                const user = response.res.data
                context.commit('SET_CURRENT_USER', user)
                localStorage.setItem('locale', user.locale ?? 'es')
                Sentry.setUser(user);
                return user;
            }
        } catch (e) {
            console.log(e);
        }
        localStorage.removeItem('zigo-token')
        localStorage.removeItem('zigo-token-time')
        context.commit('SET_USER', null)
        Sentry.setUser(null);
    },

    async LOGIN(context, payload) {
        context.commit('SET_ERROR_LOGIN', null)
        const response = await API.Post('api/auth/login', {
            email: payload.email,
            password: payload.password
        })

        // Error de red si no está conectado a backend
        if (response.res.code === "ERR_NETWORK") {
            context.commit('SET_ERROR_LOGIN', 'errors.network')
            return
        }

        if (response.type === 'Success' && typeof response.res.data !== "string") {

            if (response.res.data.errorMessage) {
                return response.res.data.errorMessage
            }
            const data = response.res.data;
            const token = data.token;

            context.commit('SET_ERROR_LOGIN', data.errorMessage)
            context.commit('SET_TOKEN', token)
            context.commit('SET_TOKEN_TIME', new Date().getTime())
            context.commit('SET_CURRENT_USER', data.user)
            context.commit('SET_ALL_ROLES', data.roles)

            localStorage.setItem('locale', data.user.locale ?? 'es')
            Sentry.setUser(data.user);

            localStorage.setItem('app-crud-filters', JSON.stringify(state.DEFAULT_FILTERS))

            if (token != null) {
                if (payload.notRedirect) {
                    location.href = '/'
                } else {
                    location.href = context.state.REDIRECT_AFTER_LOGIN
                }
            }
        } else {
            return response.res.data
        }
    },

    async LOGOUT(context) {
        await API.Post('api/auth/logout')
        localStorage.setItem('app-crud-filters', JSON.stringify(state.DEFAULT_FILTERS))
        context.commit('SET_USER', null)
        localStorage.removeItem('zigo-token')
        localStorage.removeItem('zigo-token-time')
        context.commit('SET_TOKEN_TIME', null)
        context.commit('SET_TOKEN', null)
    },

    async REGISTER(context, payload) {
        context.commit('SET_ERROR_REGISTER', null)
        const response = await API.Post('api/auth/register', payload)
        if (response.type === 'Success') {
            context.commit('SET_REDIRECT_AFTER_LOGIN', window.location.href)
            router.push('/login')
            return
        }
        const error = JSON.parse(response.res.request.responseText)
        context.commit('SET_ERROR_REGISTER', error.detail)
    },

    async FORGOT_PASS(context, payload) {
        const response = await API.Post('api/auth/forgotPassword', payload, null)
        if (response.type === 'Success') {
            return null;
        }
        return JSON.parse(response.res.request.responseText)?.detail
    },

    async RESET_PASS(context, payload) {
        const response = await API.Post('api/auth/resetPassword', payload, null)
        if (response.type === 'Success') {
            const token = response.res.data.token

            context.commit('SET_TOKEN', token)
            context.commit('SET_TOKEN_TIME', new Date().getTime())
            await context.dispatch('USER')

            return token;
        }
        return null
    },

    async VALIDATE_MAIL(context, payload) {
        const response = await API.Post('api/auth/validate', payload, null)
        if (response.type === 'Success') {
            const token = response.res.data.token
            context.commit('SET_VALIDATE_ERROR', response.res.data.errorMessage)

            context.commit('SET_TOKEN', token)
            context.commit('SET_TOKEN_TIME', new Date().getTime())

            if (token !== null) {
                await context.dispatch('USER')
                location.href = context.state.REDIRECT_AFTER_LOGIN
            }
            return true
        }
        return false;
    },
    async LOGIN_COMPANY(context, payload) {
        const response = await API.Post('api/auth/loginCompany', {id: payload.id})
        if (response.type === 'Success') {
            const data = response.res.data;
            const token = data.token;
            
            context.commit('SET_ERROR_LOGIN', data.errorMessage)
            context.commit('SET_TOKEN', token)
            context.commit('SET_TOKEN_TIME', new Date().getTime())
            context.commit('SET_CURRENT_USER', data.user)
            context.commit('SET_ALL_ROLES', data.roles)

            if (token !== '') {
                location.href = '/';
            } else {
                context.commit('SET_ERROR_LOGIN', i18n.global.t('userOrCompanyInvalid'))
            }
            return
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
