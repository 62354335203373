import store from '@/store'

function getRecordLabel(recordName, noun) {
    const company = store.getters['GET_CURRENT_COMPANY']
    if (!company || !company.recordLabels)
        return null
    if (!company.recordLabels[recordName])
        return null
    if (!company.recordLabels[recordName].label && !company.recordLabels[recordName].pluralLabel)
        return null
    if ((!noun || noun === 'singular') && company.recordLabels[recordName].label !== null)
        return company.recordLabels[recordName].label
    if (noun === 'plural' && company.recordLabels[recordName].pluralLabel !== null)
        return company.recordLabels[recordName].pluralLabel
}

export default Object.freeze({
    getRecordLabel: (x, noun) => getRecordLabel(x, noun)
})
