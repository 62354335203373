<template>
      <app-option-card
          :label="$t('admin.integrations.integrations')"
          :description="$t('admin.integrations.integrationsDesc')">
        <div class="flex justify-between flex-wrap w-full">
          <div v-for="integration in getSortIntegrations" :key="integration.id" class="my-2"
               :class="{'border shadow-m': !isEmpty(integration)}">
            <app-image v-if="!isEmpty(integration)" :default="integration.image" alt="" class="px-2 py-2 h-24 w-24 mx-auto" />

            <div v-if="!isEmpty(integration)" class="w-full">
              <div v-if="isMergeIntegration(integration.id)">
                <div class="text-center mb-4 flex justify-center flex-col items-center">
                  <span v-if="!integration.registered" class="font-nhu-regular text-[14px]">{{$t('admin.integrations.connect')}}</span>
                  <span v-else class="font-nhu-regular text-[14px]">{{$t('admin.integrations.disconnect')}}</span>
                  <div class="flex justify-center">
                      <app-checkbox
                              :modelValue="integration.registered"
                              @click="showMergeIframe(integration['integration'], integration['categories'], integration.id, integration.registered)" />
                  </div>
                </div>
              </div>
              <div v-else class="text-center mb-4">
                <app-checkbox :modelValue="integration.registered" @click="register(integration)" />
              </div>
            </div>
          </div>
        </div>
      </app-option-card>

    <div class="hidden">
      <merge-link :linkToken="mergeToken" v-bind="{ onSuccess }" :key="componentKey">
        <template v-slot:button="props">
          <button @click="props.onClick()" ref="merge-button" />
        </template>
      </merge-link>
    </div>

    <app-remove-popup
        @doDelete="doRemoveIntegration"
        v-model:show-popup="showRemovePopUp"
        :message="$t('admin.integrations.sureToDelete')" />
<!--

    <app-action-bar :loading="saving">
      <AppButton @click="save" :loading="saving" type="primary" background="light">
        {{ $t('save') }}
      </AppButton>
      <AppButton @click="cancel" :loading="saving" type="secondary">
        {{ $t('cancel') }}
      </AppButton>
    </app-action-bar>
-->
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppOptionCard from "@/components/AppOptionCard";
import MergeLink from "@mergeapi/vue-merge-link";
import AppCheckbox from "@/components/AppCheckbox";
import AppImage from "@/components/AppImage";
export default {
  name: 'IntegrationsPage',
  components: {AppImage, AppCheckbox, AppOptionCard, MergeLink  },
  data() {
    return {
      has_mercado_pago: false,
      has_instagram: false,
      has_facebook: false,
      has_whatsapp: false,
      account: {},
      saving: false,
      bambooHR: "",
      mergeToken: "",
      componentKey: 0,
      integrationId: "",
      showRemovePopUp: false
    }
  },
  computed: {
    ...mapGetters({ integrations: 'GET_INTEGRATIONS'}),

    getIntegrations() {
      let integrations = this.integrations
      for(let i = 0; i < integrations.length % 3; i ++) {
        integrations.push({})
      }
      return integrations
    },

    getSortIntegrations() {
      return [
        ...this.getIntegrations.filter(x => x.registered),
        ...this.getIntegrations.filter(x => !x.registered),
        ...this.getIntegrations.filter(x => x.registered === undefined),
      ];
    }
  },
  methods: {
    ...mapActions([
      'READ_INTEGRATIONS',
      'UPDATE_ACCOUNT',
      'UNREGISTER_INTEGRATION',
      'GET_MERGE_LINK_TOKEN',
      'CREATE_MERGE_INTEGRATION',
      'DELETE_MERGE_INTEGRATION'
    ]),

    async register(integration) {
      if(integration.registered === false)
        window.location.href = integration.oauth_url + '&redirect_uri=' + process.env.VUE_APP_URL + 'admin/integrations/' + integration.id;
      else
      {
        await this.UNREGISTER_INTEGRATION({ id: integration.id })
        integration.registered = false;
      }
    },

    save() {
      this.saving = true;
      this.saving = false;
    },

    cancel() {
      this.$router.push('/admin/');
    },

    isEmpty(value) {
      return Object.keys(value).length === 0;
    },

    isMergeIntegration(value) {
      return value.toLowerCase().includes("merge")
    },

    async showMergeIframe(integrations, categories, integrationId, registered) {
      this.integrationId = integrationId
      if (!registered) {
        let response = await this.GET_MERGE_LINK_TOKEN({
          integrations: integrations,
          categories: categories
        })
        this.mergeToken = response['link_token']
        this.componentKey++
        this.$refs["merge-button"].click()
      }
      else
        this.showRemovePopUp = true
    },

    async onSuccess(token) {
      await this.CREATE_MERGE_INTEGRATION({
        publicToken: token,
        integrationId: this.integrationId
      })
    },

    async doRemoveIntegration() {
      await this.DELETE_MERGE_INTEGRATION({
        id: this.integrationId
      })
      this.showRemovePopUp = false
    }
  },
    async created () {
      await this.READ_INTEGRATIONS()

  },
}
</script>
<style>
</style>
