<template>
  <div class="flex flex-col w-full" :class="{'mt-7': animateLabel}">
    <!-- Label without animation -->
    <div class="flex items-center mb-1">
      <component :is="iconLabel" v-if="iconLabel" class="w-5 h-5 mr-1"></component>
      <span v-if="label.length !== 0 && !animateLabel" class="font-nhu-regular" :class="{'text-[#D7D7DD]': disabled}">
        {{ label }}
      </span>
    </div>

    <div class="h-full w-full flex items-center relative group" :class="getClass">
      <Datepicker :id="id" v-model="value" :placeholder="getInputPlaceholder" :clearable="false"
          @focusin="focusIn" @focusout="focusOut" @change="validations" :timePicker="time"
          :minutesIncrement="minutesIncrement" required :disabled="disabled"
          class="p-0 sm:text-sm border-none focus:outline-none w-full peer">
      </Datepicker>

      <label v-if="animateLabel" class="transform transition-all text-extra-light-gray absolute top-0
                 h-full flex items-center pl-2 text-sm font-normal pointer-events-none font-nhu-regular
                 peer-valid:-translate-y-[90%] peer-valid:pl-0 peer-valid:text-black
                 group-focus-within:-translate-y-[90%]  group-focus-within:pl-0 group-focus-within:text-black"
             :class="{'left-0': !container, 'left-[14px] peer-valid:left-0 group-focus-within:left-0': container}">
        {{ animateLabelText }}
      </label>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { CheckIcon, XCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import {validateEmail} from "@/services/validateEmail";
export default {
  name: "AppTime",
  components: { Datepicker, CheckIcon, XCircleIcon, EyeIcon, EyeSlashIcon },
  props: {
    id: { type: String, default: null },
    placeholder: { type: String, default: null },
    modelValue: { type: [String, Number, Date], default: "10:00"},
    container: { type: Boolean, default: true },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    animateLabel: { type: Boolean, default: false },
    iconLabel: { type: Function, default: null },
    minutesIncrement: { type: Number, default: 1 },
    time: { type: Boolean, default: true}
  },
  emits: ["update:modelValue", 'keydown'],
  data() {
    return {
      animateLabelText: "",
      showPassword: false,
      showValidationError: false,
      showValidationSuccessful: false,
      validationErrorMsg: "",
    }
  },
  computed: {
    value: {
      get() {
          if(/^\d{2}:\d{2}$/.test(this.modelValue)) {
              return this.parseTime(this.modelValue)
          }  else {
              return new Date(this.modelValue)
          }

      },
      set(value) {
        this.showValidationError = false
        this.showValidationSuccessful = false
        this.$emit("update:modelValue", this.getDate(value));
        this.$emit("keydown");
      }
    },
    getClass() {
      let borderClass = ""
      if (this.disabled)
        borderClass += "bg-white border-light-gray-checkbox placeholder:text-light-gray-checkbox "
      else {
        borderClass += "focus-within:border-primary-purple "
        if (this.modelValue)
          borderClass += "border-primary-purple "
        else
          borderClass += "border-extra-light-gray "
      }

      if(this.container)
        borderClass += "rounded border px-[20px] focus-within:border-2 focus-within:px-[19px]"
      else
        borderClass += "border-b px-[10px] py-[8px] focus-within:border-b-2 focus-within:pt-[8px] focus-within:pb-[7px]"

      return borderClass
    },
    getInputPlaceholder() {
      if(this.animateLabel)
        return ""
      else
        return this.placeholder
    },
  },
  methods: {
     parseTime( t ) {
      var time = t.match( /(\d+)(?::(\d\d))?\s*(p?)/ );

      if(time && time.length > 2)
        return { hours: parseInt(time[1]),  minutes: parseInt(time[2]) };
      else
        return { hours: 10,  minutes: 0 };
    },
    getDate(value){
        if(value)
        {
            let fecha = new Date(value);
            if (fecha.getDate()) {
                return fecha;
            }
            else if(value.day === undefined)
            {
                return value.hours + ":" +value.minutes;
            }
            else
                return new Date(value.year, value.month, value.day, value.hours, value.minutes)
        }
        return "";
    },
    focusIn() {
      if (this.placeholder && this.label)
        if(this.placeholder.length !== 0 && this.label.length !==0) {
          this.animateLabelText = this.label
        }
    },
    focusOut() {
      if (this.placeholder && this.label)
        if(this.placeholder.length !== 0 && this.label.length !==0) {
          if(this.modelValue?.length ?? 0 === 0)
            this.animateLabelText = this.placeholder
          else
            this.animateLabelText = this.label
        }
    },
    validations() {
      if (this.emailValidator) {
        if (validateEmail(this.value)) {
          this.showValidationError = false
          this.showValidationSuccessful = true
        }
        else {
          this.showValidationSuccessful = false
          this.showValidationError = true
        }
      }
    },
  },
  created() {
    if (this.placeholder !== null && this.placeholder.length !== 0) {
      this.animateLabelText = this.placeholder
    }
    else if (this.label !== null && this.label.length !== 0) {
      this.animateLabelText = this.label
    }
  }
};
</script>

<style lang="scss">
@import 'vue3-tel-input/dist/vue3-tel-input.css';

.vue-tel-input {
  border: white;
  border-bottom: 1px solid #bbb;
}
input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
}
.check-icon {
  filter: invert(50%) sepia(97%) saturate(450%) hue-rotate(111deg) brightness(93%) contrast(87%);
}
.x-icon {
  filter: invert(51%) sepia(45%) saturate(785%) hue-rotate(314deg) brightness(104%) contrast(89%);
}
input:focus {
  box-shadow: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.dp__input
{
  border: 0px;
}
</style>



