import API from '@/api'
import i18n from "@/locales/i18n";

const state = {
    WORKORDER: {
        name: '',
        number: null,
        orderType: 'corrective',
        description: '',
        checklist: [],
        dueDate: null,
        startDate: null,
        priority: '',
        siteId: '',
        assetId: '',
        inspectionId: '',
        templateId: '',
        templatePublishId: '',
        assignedTo: [],
        categories: [],
        schedule: {},
        images: [],
        files: [],
        comments: [],
        creationId: '',
        readByUser: {},
        parts: [],
        resources: []
    },
    WORKORDERS: {data: []},
    ALL_WORKORDERS: [],
    LIST_MODIFIED: false,
    //Calendar
    WORKORDERS_CALENDAR: {data: []},
    WORKORDERS_KANBAN: {data: []},
    EXPORTED_DOCUMENTS: {data: []},
    CALENDAR_NEED_UPDATE: false,
    KANBAN_NEED_UPDATE: false,
    startDate: '',
    endDate: '',
    week: 0,
    view: 'monthly',
}

const getters = {
    GET_EXPORTED_DOCUMENTS: state => state.EXPORTED_DOCUMENTS,
    GET_WORKORDER: state => state.WORKORDER,
    GET_WORKORDERS: state => state.WORKORDERS,
    GET_TOTAL_WORKORDERS: state => state.WORKORDERS.total,
    GET_LIST_MODIFIED: state => state.LIST_MODIFIED,
    GET_WORKORDERS_CALENDAR: state => state.WORKORDERS_CALENDAR,
    GET_WORKORDERS_KANBAN: state => state.WORKORDERS_KANBAN,
    GET_CALENDAR_NEED_UPDATE: state => state.CALENDAR_NEED_UPDATE,
    GET_KANBAN_NEED_UPDATE: state => state.KANBAN_NEED_UPDATE,
    GET_CALENDAR_START_DATE: state => state.startDate,
    GET_CALENDAR_END_DATE: state => state.endDate,
    GET_CALENDAR_VIEW: state => state.view,
    GET_WEEK: state => state.week,
    GET_ALL_WORKORDERS: state => state.ALL_WORKORDERS,
    GET_WORKORDER_BY_ID: state => id => {
        return state.ALL_WORKORDERS.find(e => e.id === id)
    },
}

const mutations = {
    // EXPORTED DOCUMENTS
    SET_EXPORTED_DOCUMENTS(state, payload) {
        state.EXPORTED_DOCUMENTS = payload
    },
    // CALENDAR
    SET_WEEK(state, payload) {
        state.week = payload
    },
    SET_CALENDAR_NEED_UPDATE(state, payload) {
        state.CALENDAR_NEED_UPDATE = payload
    },
    SET_KANBAN_NEED_UPDATE(state, payload) {
        state.CALENDAR_NEED_UPDATE = payload
    },
    SET_WORKORDERS_CALENDAR(state, payload) {
        state.WORKORDERS_CALENDAR = payload
    },
    SET_WORKORDERS_KANBAN(state, payload) {
        state.WORKORDERS_KANBAN = payload
    },
    SET_CALENDAR_START_DATE(state, payload) {
        state.startDate = payload
    },
    SET_CALENDAR_END_DATE(state, payload) {
        state.endDate = payload
    },
    SET_CALENDAR_VIEW(state, payload) {
        state.view = payload
    },
    // WORKORDERS
    SET_WORKORDER(state, payload) {
        state.WORKORDER = payload
        const index = state.WORKORDERS.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.WORKORDERS.data[index], payload)
    },
    SET_WORKORDERS(state, payload) {
        state.WORKORDERS = payload
    },
    CREATE_WORKORDER(state, payload) {
        state.WORKORDER = payload
        state.WORKORDERS.data.push(payload)
    },
    UPDATE_WORKORDER(state, payload) {
        if (state.WORKORDER !== null && state.WORKORDER.id === payload.id)
            state.WORKORDER = payload;

        const index = state.WORKORDERS.data.findIndex(e => e.id === payload.id)
        if (index !== -1)
            Object.assign(state.WORKORDERS.data[index], payload)

        const optionsIndex = state.ALL_WORKORDERS.findIndex(e => e.id === payload.id)
        if (optionsIndex !== -1)
            Object.assign(state.ALL_WORKORDERS[optionsIndex], payload)

        const kanbanIndex = state.WORKORDERS_KANBAN.data.findIndex(e => e.id === payload.id)
        if (kanbanIndex !== -1){
            Object.assign(state.WORKORDERS_KANBAN.data[kanbanIndex], payload)
        }

    },
    DELETE_WORKORDER(state, payload) {
        if (state.WORKORDER?.id === payload.id)
            state.WORKORDER = {}
        state.WORKORDERS.data = state.WORKORDERS.data.filter(e => e.id !== payload.id)
        state.WORKORDERS.total--
    },
    SET_LIST_MODIFIED(state, payload) {
        state.LIST_MODIFIED = payload
    },
    SET_ALL_WORKORDERS(state, payload) {
        state.ALL_WORKORDERS = payload
    },
}

const actions = {
    async READ_ALL_WORKORDERS(context) {
        const response = await API.Get('/api/options/workorders')
        if (response.type === 'Success') {
            context.commit('SET_ALL_WORKORDERS', response.res.data)
            return response.res.data
        }
        context.commit('SET_ALL_WORKORDERS', [])
    },
    async READ_EXPORTED_DOCUMENTS(context) {
        const response = await API.Get('/api/workorders/exported-documents')
        if (response.type === 'Success') {
            context.commit('SET_EXPORTED_DOCUMENTS', response.res.data)
            return response.res.data
        }
        context.commit('SET_EXPORTED_DOCUMENTS', [])
    },
    async READ_WORKORDERS_CALENDAR(context, payload) {
        const response = await API.Get('/api/workorders/calendar', payload)
        if (response.type === 'Success') {
            context.commit('SET_WORKORDERS_CALENDAR', response.res.data)
            context.commit('SET_CALENDAR_NEED_UPDATE', false)
            return response.res.data
        }
        context.commit('SET_WORKORDERS_CALENDAR', [])
    },
    async READ_WORKORDERS_KANBAN(context, payload) {
        const response = await API.Get('/api/workorders/kanban', payload)
        if (response.type === 'Success') {
            context.commit('SET_WORKORDERS_KANBAN', response.res.data)
            return response.res.data
        }
        context.commit('SET_WORKORDERS_KANBAN', [])
    },
    // CRUD WORKORDERS
    async READ_WORKORDERS(context, payload) {
        const response = await API.Get('/api/workorders', payload)
        if (response.type === 'Success') {
            context.commit('SET_WORKORDERS', response.res.data)
            return response.res.data
        }
        context.commit('SET_WORKORDERS', [])
    },
    async READ_WORKORDERS_ON_HISTORY(context, payload) {
        const response = await API.Get('/api/workorders', payload)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async CREATE_WORKORDER(context, payload) {
        context.commit('SET_WORKORDER', { name: '', orderType: 'corrective', description: '', checklist: [], siteId: '', assetId: '', dueDate: null,
        images: [], assignedTo: [], categories: [], comments: [], schedule: {id: undefined, periodically: "none", day: 1, every: 1,
        weekDays: []}, inspectionId: '', templatePublishId: '', priority: 'none', parts: [], resources: [], props: {}, measure: {}, newWorkOrder: {
            enabled: false, name: '', orderType: 'corrective', description: '', checklist: [], siteId: '', assetId: '',
            dueDate: null, images: [], assignedTo: [], categories: [], comments: [], schedule: { id: undefined, 
            periodically: "none", day: 1, every: 1, weekDays: []}, inspectionId: '', templateId: '', templatePublishId: '', 
            priority: 'none', parts: [], resources: [], props: {}, measure: {} } 
        })

        if(payload?.requestId) {
            const response = await API.Get(`/api/requests/${payload.requestId}`)
            if (response.type === 'Success') {
                var item = {...response.res.data, requestId: payload.requestId, schedule: {}};
                delete item.id;
                item.assignedTo = []
                item.categories = []
                context.commit('SET_WORKORDER', item)
            }
        }

        if(payload?.procedureId) {
            const response = await API.Get(`/api/procedures/${payload.procedureId}`)
            if (response.type === 'Success') {
                var proc = response.res.data;
                var wo = context.getters.GET_WORKORDER
                var item2 = {...wo, procedureId: payload.procedureId, name: proc.name, description: proc.body, tasks: proc.tasks, checklist: proc.checklist};
                context.commit('SET_WORKORDER', item2)
            }
        }
    },
    async SAVE_WORKORDER(context, payload) {
        if (payload.id === undefined) {
            if (payload.name === undefined || payload.name === '') {
                return {type: 'Error', message: 'El nombre es requerido'}
            }
            const response = await API.Post('/api/workorders', payload)
            if (response.type === 'Success') {
                context.commit('SET_WORKORDER', response.res.data)
                context.commit('SET_CALENDAR_NEED_UPDATE', true)
                return response.res.data;
            }
        } else {
            const response = await API.Put(`/api/workorders/${payload.id}`, payload)
            if (response.type === 'Success') {
                context.commit('UPDATE_WORKORDER', response.res.data)
                context.commit('SET_CALENDAR_NEED_UPDATE', true)
                return response.res.data;
            }
        }
    },

    async UPDATE_MEASUREMENT_WORKORDER(context, payload) {
        const response = await API.Put(`/api/workorders/${payload.id}/measurement`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_WORKORDER', response.res.data)
            return response.res.data;
        }
    },
    async SAVE_WORKORDER_RESOURCES(context, payload) {
        const response = await API.Put(`/api/workorders/${payload.id}/resources`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_WORKORDER', response.res.data)
            return response.res.data;
        }
    },
    async READ_WORKORDER_BY_ID(context, id) {
        const response = await API.Get(`/api/workorders/${id}`)
        if (response.type === 'Success') {
            context.commit('SET_WORKORDER', response.res.data)
            return response.res.data
        }
    },
    async READ_WORKORDER(context, payload) {
        context.commit('SET_WORKORDER', { name: '', number: '', description: '', checklist: [], siteId: '', assetId: '',
        images: [], assignedTo: [], categories: [], comments: [], schedule: {}, inspectionId: '', templatePublishId: '', priority: '' })

        const response = await API.Get(`/api/workorders/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('SET_WORKORDER', response.res.data)
            return response.res.data
        }
    },

    async COMMENT_WORKORDER(context, payload) {
        const response = await API.Put(`/api/workorders/${payload.id}/comments`, {
            text: payload.text, images: payload.images
        })
        if (response.type === 'Success') {
            context.commit('UPDATE_WORKORDER', response.res.data)
            return response.res.data
        }
    },
    async CHANGE_STATUS_WORKORDER(context, payload) {
        const response = await API.Put(`/api/workorders/${payload.id}/status`, {
            status: payload.status,
            check: payload.check,
            uncheck: payload.uncheck
        })
        if (response.type === 'Success') {
            if (typeof response.res.data === 'string') {
                context.commit('TOAST_MESSAGE', {
                    type: 'error',
                    title: i18n.global.t('records.kanban.errors.' + response.res.data + '.title'),
                    message: i18n.global.t('records.kanban.errors.' + response.res.data + '.message'),
                })
                return
            }
            context.commit('UPDATE_WORKORDER', response.res.data)
            context.commit('SET_CALENDAR_NEED_UPDATE', true)
            context.commit('SET_KANBAN_NEED_UPDATE', true)
            return response.res.data
        }
    },
    async UPDATE_WORKORDER(context, payload) {
        const response = await API.Patch(`/api/workorders/${payload.id}`, payload)
        if (response.type === 'Success') {
            context.commit('UPDATE_WORKORDER', response.res.data)
            return response.res.data
        }
    },
    async DELETE_WORKORDER(context, payload) {
        const response = await API.Delete(`/api/workorders/${payload.id}`)
        if (response.type === 'Success') {
            context.commit('DELETE_WORKORDER', response.res.data)
            context.commit('SET_CALENDAR_NEED_UPDATE', true)
            return
        }
    },
    async DELETE_WORKORDER_LIST(context, payload) {
        const response = await API.Patch('/api/workorders', payload);

        if (response.type === 'Success') {
            context.commit('DELETE_WORKORDER', {payload});
            return true;
        }
        return false;
    },
    async EXPORT_WORKORDER_PDF(context, payload) {
        const response = await API.Post(`/api/workorders/export-PDF/${payload.id}`, payload.options)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async EXPORT_WORKORDER_XLSX() {
        const response = await API.Post(`/api/workorders/export-XLSX`)
        if (response.type === 'Success') {
            return response.res.data
        }
    },
    async IMPORT_WORKORDERS(context, payload) {
        const response = await API.Post('/api/workorders/import', payload)
        if (response.type === 'Success') {
            if(state.WORKORDERS){
                context.commit('SET_WORKORDERS', {data: [...state.WORKORDERS.data, ...response.res.data]})
            }else{
                context.commit('SET_WORKORDERS', response.res.data)
            }
            return response.res.data
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
