<template>
  <div class="relative mr-2 ml-2">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
          <span class="px-2 bg-white text-sm text-gray-500">{{label}}</span>
      </div>
  </div>
</template>

<script>

export default {
  name: "AppDivider",
  components:  { },
  props: {
    label: {
      type: String,
      default: "",
    }
  },
};
</script>
