<template>
  <app-crud ref="crud"  v-slot="{ item, lock }" @validate="onValidate" :loading="loading" >
    <div v-if="!lock">
      <app-textbox v-model="item.name" :label="$t('categories.name')" :disabled="lock"/>
      <app-textarea v-model="item.description" :label="$t('categories.description')"
        :disabled="lock"></app-textarea>
    </div>
    <div v-else>
      <work-order-history :filter="getFilter"/>
    </div>
    <app-log :logs="item.logs"/>
    <app-confirm-dialog ref="confirm" />
  </app-crud>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import WorkOrderHistory from "@/pages/workorders/WorkOrderHistory";
import AppLog from "@/components/AppLog";

export default {
  name: "CategoryEditPage",
  components: {AppLog, WorkOrderHistory},
  data() {
    return {
      loading: true,
      workOrdersInCategories: [],
      categoryId: '',
      validated: false
    }
  },
  computed: {
    ...mapGetters({category: 'GET_CATEGORY', workOrders: 'GET_WORKORDERS', workOrder: 'GET_WORKORDER'}),
    action() { return this.$route.meta.action; },
    getFilter(){
      return {categories: [this.$route.params.id]}
    },
  },
  methods: {
    ...mapActions(['READ_CATEGORY', 'CREATE_CATEGORY', 'SAVE_CATEGORY', 'DELETE_CATEGORY', 'CREATE_WORKORDER', 'SAVE_WORKORDER']),
    ...mapMutations(['SET_CATEGORY']),
    async onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.errorMsg = this.$t('category.errors.name');
        this.focusField = true;
        onError();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },
  async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>
