<template>
  <app-crud :parent-name="getParentName(asset.parentId)">
    <div>
      <div v-if="asset.parentId !== '' && asset.parentId !== null"
           class="flex items-center gap-x-2 mb-4 cursor-pointer fill-primary-500" @click="goToParent()">
        <app-icon icon="chevron_left_solid" :width="12" :height="12"/>
        <button
            class="text-primary-500 border-0 border-primary-500 flex items-center h-[20px] rounded-xl font-nhu-500 fs-14">
          {{ getParentName(asset.parentId) }}
        </button>
      </div>
      <app-label :label="$t('assets.description')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="asset.description"/>

      <app-label v-if="asset.parentId === ''"
                 :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('assets.site')"
                 :icon="'location_dot_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="getSite(asset.siteId)"/>
      <app-label v-if="asset.parts?.length > 0 && $hasModule('parts')"
                 :label="$getRecordLabel('parts') ? $getRecordLabel('parts') : $t('records.assets.fields.parts')"
                 icon="gears_regular"
                 :icon-color="'fill-primary-500'"
                 :class="'pre-wrap'"
                 :value="getParts(asset.parts)"/>

      <app-label :label="$t('assets.brand')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="asset.brand"/>

      <app-label :label="$t('assets.serialNumber')"
                 :icon="'hashtag_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="asset.serialNumber"/>

      <app-label :label="$t('assets.model')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="asset.model"/>

      <app-label :label="$t('assets.acquirementDate')"
                 :icon="'calendar_regular'"
                 :icon-color="'fill-primary-500'"
                 :value="formatDate(asset.acquirementDate)"/>

      <app-label :label="$t('assets.media')"
                 :icon="'bars_regular'"
                 :icon-color="'fill-primary-500'"
                 :images="true"
                 :value="asset.media"/>

      <div v-if="asset.qrCode" class="my-4 hidden">
        <div class="flex mb-1 fill-primary-500">
          <app-icon extra-class="mr-2" icon="location_dot_regular" :width="14" :height="14"/>
          <span class="font-nhu-semi-bold fs-14 mb-2 ">{{ $t('assets.qr') }}</span>
        </div>
        <div class="flex justify-between">
          <app-image-qr :src="asset.qrCode"/>
          <div class="mt-4">
            <app-button @click="copyLink" :clean="true" type="terciary" background="light" v-if="action === 'view'"
                        :extra-class="linkCopied ? 'w-[110px] bg-primary-500 border-primary-500 text-white border-[1px] !px-3' : 'w-[110px] border-primary-500 text-primary-500 border-[1px] !px-3'">
              <span class="font-nhu-500 fs-14">{{ linkCopied ? $t('copied') : $t('copyLink') }}</span>
            </app-button>
          </div>
        </div>
      </div>

      <app-crud-properties v-model="asset.props" />

      <!-- OCULTO EL SUB ACTIVO -->
      <div v-if="asset.parentId === ''" class="">
        <div class="h-0 border-b border-gray-50 my-4"/>
        <div class="mb-2">
          <div class="flex items-center my-4 fill-primary-500">
            <app-icon extra-class="mr-2" icon="list_tree_regular" :width="14" :height="14"/>
            <span class="font-nhu-semi-bold fs-14">{{ $t('assets.dependentAssets') }}</span>
          </div>
          <div v-for="subAsset in subAssets" :key="subAsset.id">
            <div class="flex items-center justify-between mb-4 cursor-pointer fill-primary-500"
                 @click="goToSubAsset(subAsset.id)">
              <span class="font-nhu-regular fs-14 text-primary-500">{{ subAsset.name }}</span>
              <app-icon extra-class="mr-2" icon="chevron_right_solid" :width="14" :height="14"/>
            </div>
          </div>
          <app-button :icon="PlusCircleIcon" :clean="true"
                      v-if="$hasPermission('create_assets')"
                      extra-class="border-primary-500 text-primary-500 border-2 shadow-sm shadow-inner font-nhu-500 fs-14 flex items-center"
                      @click="addSubAsset">{{ $t('assets.addSubAsset') }}
          </app-button>
        </div>
      </div>

      <div class="flex w-full items-center mb-2">
        <work-order-history :filter="getFilter"/>
      </div>
      <div class="flex items-center justify-center pt-4">
        <div v-if="assignToWOAvailable" class="text-primary-500 fill-primary-500 border-primary-500 border-[1px] hover:bg-primary-50 rounded-full cursor-pointer p-2 flex fs-14" @click="callToFunction('assignToWorkOrder')">
          <app-icon extra-class="mr-1" icon="inbox_full_regular" :width="14" :height="14"/>
          {{$t('records.assets.actions.assignToWorkOrder')}}
        </div>
      </div>
      <app-log v-if="asset.logs" :logs="asset.logs"/>
    </div>
  </app-crud>
  <new-asset ref="popUp" :show-popup="showPopup" @close="closePopUp" @reload="reload"/>
</template>

<script>
import AppImageQr from "@/components/AppImageQr.vue";
import emitter from 'tiny-emitter/instance'
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {PlusCircleIcon} from "@heroicons/vue/24/outline";
import NewAsset from "@/pages/assets/NewAsset.vue";
import WorkOrderHistory from "@/pages/workorders/WorkOrderHistory";
import moment from 'moment';

export default {
  name: "AssetViewPage",
  components: {WorkOrderHistory, NewAsset, AppIcon, AppButton, AppImageQr},
  emits: ['reload', 'call'],
  data() {
    return {
      userNames: [],
      showPopup: false,
      workOrdersInAssets: [],
      linkCopied: false,
    }
  },
  computed: {
    ...mapGetters({asset: 'GET_ASSET', view: 'GET_VIEW', assets: 'GET_ALL_ASSETS', sites: 'GET_ALL_SITES',  company: 'GET_CURRENT_COMPANY', record: 'GET_RECORD', parts: 'GET_ALL_PARTS', workOrder: 'GET_WORKORDER'}),
    action() {
      return this.$route.meta.action;
    },
    assignToWOAvailable() {
      return (!!this.record.views[this.view].actions.find(x => x.function === "assignToWorkOrder") && this.$hasPermission('create_workorders'))
    },
    getFilter() {
      return {assetId: [this.$route.params.id]}
    },
    subAssets() {
      return this.asset?.childs
    },
  },
  methods: {
    ...mapActions(['SAVE_ASSET', 'DELETE_ASSET', 'READ_ASSET', 'FIRE_MESSAGE', 'TOAST_MESSAGE']),
    ...mapMutations(['SET_VIEW']),
    PlusCircleIcon,
    async callToFunction(functionName) {
      const func = this.record.views[this.view].actions.find(x => x.function === functionName)
      this.saving = true;
      this.$store.dispatch('CREATE_WORKORDER');
      let workOrder = {...this.workOrder};
      if (func.multiple) {
        workOrder[func.field] = [this.asset.id]
      } else {
        if (func.object) {
          workOrder[func.field][0].id = this.asset.id
        } else {
          workOrder[func.field] = this.asset.id
        }
      }
      this.$store.commit('SET_WORKORDER', workOrder);
      await this.$router.push('/workorders/create?assignTo=' + this.asset.id);
    },
    async doLoad() {
      const id = this.$route.params.id;
      if (id !== undefined) {
        if (id !== 'create') {
          await this.READ_ASSET({id: this.$route.params.id})
        }
      }
    },
    getRecordLabel(fieldId) {
      if (!this.company || !this.company.recordLabels) {
        return null
      }
      if (this.record.fields.find(x => x.id === fieldId) !== null && this.record.fields.find(x => x.id === fieldId).record !== null) {
        const recordName = this.record.fields.find(x => x.id === fieldId).record
        if (!this.company.recordLabels[recordName].label && !this.company.recordLabels[recordName].pluralLabel) {
          return null
        }
        if (this.company.recordLabels[recordName].label !== null) {
          return this.company.recordLabels[recordName].label
        } else if (this.company.recordLabels[this.record.name].plural !== null) {
          return this.company.recordLabels[this.record.name].plural
        }
      }
    },
    momentDate(date) {
      const dateFormatted = moment(date).format('DD/MM/YYYY hh:mm')
      return dateFormatted
    },
    formatDate(date) {
      if (!date) return '';
      let d = new Date(date);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      return d.toLocaleDateString(this.$i18n.locale, options);
    },
    async addSubAsset() {
      await this.$refs.popUp.create(this.asset.id)
      this.showPopup = true;
    },
    async closePopUp() {
      this.$emit('reload')
      this.showPopup = false;
      await this.doLoad()
    },
    async reload() {
      emitter.emit('updateList');
      await this.READ_ASSET({id: this.$route.params.id})
      this.showPopup = false;
    },
    goToSubAsset(subAssetId) {
      this.$router.push('/assets/' + subAssetId)
    },
    getParts() {
      return this.parts.filter(x => this.asset.parts.includes(x.id)).map(x => "-"+x.name).join('\n')
    },
    getSite(siteId) {
      const site = this.sites.find(site => site.id === siteId)
      return site?.name
    },
    getParentName(parentId) {
      const parent = this.assets.data?.find(asset => asset.id === parentId)
      return parent?.name
    },
    goToParent() {
      this.$router.push('/assets/' + this.asset.parentId)
    },
    copyLink() {
      const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/login_request/${this.asset.id}`;
      navigator.clipboard.writeText(baseUrl);

      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
  },
}
</script>

<style scoped>

</style>
