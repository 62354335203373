<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <div @click="navigate('/')" class="text-gray-400 hover:text-gray-500 cursor-pointer">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </div>
        </div>
      </li>
      <li v-for="page in pages" :key="page.name">
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-300" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <div @click="navigate(page.href)" class="ml-4 cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">
            {{ page.name }}
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>


<script>
import { HomeIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'AppFilters',
  components: { HomeIcon },
  props: {
    pages: {
      type: Array,
			default() { return [] },
    },
	},
	methods: {
    navigate(url){
      this.$router.push(url);
    }
	},  
	async created() {
  },
}
</script>
