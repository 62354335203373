<template>
  <div :class="{'flex items-center': flex}">
    <h1 v-if="isTitle" :class="getClass">
      <slot></slot>
    </h1>
    <p v-else-if="isParagraph" :class="getClass">
      <slot></slot>
    </p>
    <span v-else :class="getClass">
      <slot></slot>
    </span>
    <a v-if="isRedirect" :href="href" target="_new" :class="getClass">
      <slot></slot>
    </a>
  </div>
</template>

<script>
import {textType} from "@/constants/enums";

export default {
  name: "AppText",
  props: {
    type: {
      type: String,
      default: ''
    },
    flex: {
      type: Boolean,
      default: true
    },
    extraClass: {
      type: String,
      default: ""
    },
    defaultColor: {
      type: Boolean,
      default: true
    },
    href: {
      type: String,
      default: ""
    }
  },
  computed: {
    isTitle() {
      if(this.isParagraph || this.isRedirect) return false
      return this.type === textType.title
    },
    isParagraph() {
      if(this.isTitle || this.isRedirect) return false
      return this.type.includes("paragraph")
    },
    isAnotherType() {
      return !(this.isTitle || this.isParagraph || this.isRedirect);
    },
    isRedirect() {
      if(this.isTitle || this.isParagraph) return false
      return this.type === textType.redirect
    },
    getClass() {
      let returnClass = `${this.extraClass} `
      if (this.defaultColor)
        returnClass += 'text-dark-grey'
      if (this.flex)
        returnClass += "flex "
      if (this.type === textType.title)
        returnClass += "font-nhu-semi-bold text[20px]"
      else if(this.type === textType.button_text)
        returnClass += "font-nhu-semi-bold text-[16px]"
      else if (this.type === textType.titles_card)
        returnClass += "roboto-medium text-[16px]"
      else if (this.type === textType.subtitle)
        returnClass += "roboto-bold text-[16px]"
      else if (this.type === textType.placeholder_1)
        returnClass += "font-nhu-regular text-[16px]"
      else if (this.type === textType.placeholder_2)
        returnClass += "font-nhu-light text-[16px]"
      else if (this.type === textType.placeholder_3)
        returnClass += "font-nhu-extra-light text-[16px]"
      else if (this.type === textType.paragraph_1)
        returnClass += "almarai-bold text-[15px]"
      else if (this.type === textType.paragraph_2)
        returnClass += "font-nhu-semi-bold text-[15px]"
      else if (this.type === textType.paragraph_3)
        returnClass += "font-nhu-regular text-[15px]"
      else if (this.type === textType.paragraph_4)
        returnClass += "roboto-bold text-[14px]"
      else if (this.type === textType.paragraph_5)
        returnClass += "roboto-light text-[14px]"
      else if (this.type === textType.paragraph_6)
        returnClass += "roboto-regular text-[14px]"
      else if (this.type === textType.labels || this.type === textType.tagline)
        returnClass += "font-nhu-regular text-[14px]"
      else if (this.type === textType.caption)
        returnClass += "font-nhu-semi-bold text-[12px]"
      else if (this.type === textType.redirect)
        returnClass += "text-blue-600 underline hover:text-blue-800 visited:text-purple-600"
      return returnClass
    }
  }

}
</script>

<style scoped>

</style>
