<template>
    <app-pop-up-container v-if="action !== ''" :show="show" :title="$t('assets.typeData.' + this.action)" :loading="loading">
        <div class="space-y-2 bg-white">
            <div class="grid gap-4 my-4">
                <app-textbox v-model="type.name" :label="$t('assets.name')" />
            </div>
            <!--      <app-dropbox v-model="people.groups" url="/api/groups" :label="$t('admin.people.groups')" />-->
        </div>
        <template #footer>
            <app-button @click="doCancel" type="secondary" background="light">
                {{  action !== 'view' ? $t('cancel') : $t('back') }}
            </app-button>
            <app-button @click="doEdit" :disabled="!allowSave" type="primary" background="light" v-if="action === 'view'">
                {{ $t('edit') }}
            </app-button>
            <app-button @click="doSave" :disabled="!allowSave" type="primary" background="light" v-if="action !== 'view'">
                {{ $t('save') }}
            </app-button>
        </template>
    </app-pop-up-container>

  <!--  <app-confirm-popup v-model:open="showRemovePopUp" @onYes="doDelete2"-->
  <!--      :message="$t('admin.people.sureToDelete')" />-->
</template>

<script>
import { ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, CurrencyDollarIcon, EyeSlashIcon, FolderIcon, Bars3Icon, PhotoIcon, PlusIcon } from "@heroicons/vue/24/outline"
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewType",
  emits: ['reload'],
  components: {},
  data() {
    return {
      ...{ FolderIcon, CurrencyDollarIcon, PhotoIcon, Bars3Icon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, EyeSlashIcon, PlusIcon  },
      showRemovePopUp: false,
      action: '',
      show: false,
      loading: false,
      sites: [],
      saving: false,
      nextActivationTime: '',
      peopleStatus: [
        {id: 'active', name: this.$t('admin.people.active')},
        {id: 'standby', name: this.$t('admin.people.standby')},
        {id: 'inactive', name: this.$t('admin.people.inactive')},
      ],
    }
  },
  computed:{
    ...mapGetters({type: 'GET_TYPE' }),
    allowSave() {
      return this.type.name !== ''
    },
  },
  methods: {
    ...mapActions(['READ_TYPE', 'READ_TYPES', 'CREATE_TYPE', 'SAVE_TYPE', 'DELETE_TYPE', 'UPDATE_TYPE']),

    async create()
    {
      this.loading = true;
      this.action = 'create';
      this.show = true;
      await this.CREATE_TYPE()
      this.loading = false;
    },

    async edit(id)
    {
      this.loading = true;
      this.action = 'edit';
      this.show = true;
      await this.READ_TYPE({ id: id })
      this.loading = false
    },


    // doDelete() {
    //   this.showRemovePopUp = true;
    // },
    // async doDelete2() {
    //   this.showRemovePopUp = false
    //   this.saving = true;
    //   await this.DELETE_PEOPLE(this.people)
    //   this.saving = false;
    //   this.show = false;
    //   this.$emit('reload');
    // },


    async doSave() {
      this.saving = true;
      await this.SAVE_TYPE(this.type)
      this.show = false;
      this.saving = false;
      this.$emit('reload')
    },

    doCancel() {
      this.show = false;
    },

    // getStatusName(status) {
    //   switch (status) {
    //     case 'active':
    //       return this.$t('admin.people.active');
    //     case 'standby':
    //       return this.$t('admin.people.standby');
    //     case 'inactive':
    //       return this.$t('admin.people.inactive');
    //     default:
    //       return status;
    //   }
    // },
    /*******************************/
  },
  async created() {

  },
}
</script>

<style scoped>

</style>
