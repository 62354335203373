<template>
  <app-loading :loading="loading" />
  <FacebookShopsPopUp v-if="facebookShop" />
</template>


<script>
import {mapActions} from 'vuex'
import FacebookShopsPopUp from "@/pages/admin/integrations/FacebookShopsPopUp";
export default {
  name: 'IntegrationsPage',
  components: {FacebookShopsPopUp},
  data() {
    return {
      facebookShop: false,
      loading: true
    }
  },
  computed: {},
  methods: {
    ...mapActions(['REGISTER_INTEGRATION']),
  },
  async created() {
    this.loading = true;
    if(this.$route.params.type === "FACEBOOK-COMPANY") {
      this.facebookShop = true
    }
    else {
      await this.REGISTER_INTEGRATION({
        id: this.$route.params.type,
        redirect_uri: window.location.origin + window.location.pathname,
        ...this.$route.query
      })
      this.$router.push('/admin/integrations');
    }
    this.loading = false;
  },
}
</script>
<style>
</style>
