<template>
  <div class="w-full border border-gray-400 border-solid rounded">
    <div class="flex p-2 bg-gray-200">
      <div class="flex gap-2">
        Operación:
        <Switch :model-value="isAnd" @update:model-value="changeOperator" :class="[isAnd ? 'bg-indigo-600' : 'bg-gray-300', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
          <span class="sr-only">Use setting</span>
          <span :class="[isAnd ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
            <span :class="[isAnd ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
              O
            </span>
            <span :class="[isAnd ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
              Y
            </span>
          </span>
        </Switch>
      </div>
      <div class="flex-1"></div>
      <div class="flex gap-2">
        <button @click="addRule" type="button" class="px-2 py-1 text-sm font-semibold text-white bg-indigo-500 rounded shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Agregar Regla</button>
        <button @click="addGroup" type="button" class="px-2 py-1 text-sm font-semibold text-white bg-indigo-500 rounded shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Agregar Grupo</button>
      </div>
    </div>
    <div class="m-2 rules-list">
      <div v-for="(rule, idx) in rules" :key="rule.id" class="flex w-full rules-list">
        <div class="flex-1 mb-2">          
          <query-builder-rule v-if="!isGroup(rule)" :rule="rule" @update="(newRule) => updateRule(idx, newRule)" :fields="fields" />
          <query-builder-group v-if="isGroup(rule)" :rule="rule" @update="(newRule) => updateRule(idx, newRule)" :fields="fields" />
        </div>
        <div class="">
          <TrashIcon @click="onRemoveRule(rule)" class="w-8 h-8 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import QueryBuilderRule from './QueryBuilderRule'
import QueryBuilderGroup from './QueryBuilderGroup'

import { TrashIcon } from "@heroicons/vue/24/outline"
import { Switch } from '@headlessui/vue'

export default {
  name: "QueryBuilderGroup",
  components: { Switch, QueryBuilderRule, QueryBuilderGroup, TrashIcon },
  data() {
    return {
    }
  },
  emits: ['update'],
  props: {
    fields: { type: Array, default() { return []; } },
    rule: { type: Object, default() { return null; } },
  },
  methods: {
    changeOperator()
    {
      var data = {};

      if(!this.isAnd)
        data['$and'] = this.rules;
      else
        data['$or'] = this.rules;
      this.$emit('update', data)
    },
    addRule()
    {
      var data = {}, rule = {};
      var rules = this.rules;

      rule[this.fields[0].value] = { $eq: '' };
      rules.push(rule)

      data[this.expr] = rules;
      
      this.$emit('update', data)
    },
    addGroup()
    {
      var data = {}, rule = {};
      var rules = this.rules;

      rule[this.fields[0].value] = { $eq: '' };
      rules.push({ $and: [rule] })
      data[this.expr] = rules;
      
      this.$emit('update', data)
    },
    updateRule(idx, newRule)
    {
      var value = {};
      var rules = [...this.rules];
      rules[idx] = newRule;
      value[this.expr] = rules;
      this.$emit('update', value)
    },
    onRemoveRule(rule)
    {
      var value = {};
      var rules = [...this.rules];

      rules = rules.filter(x => x !== rule);
      value[this.expr] = rules;
      this.$emit('update', value)
    },

    isGroup(rule)
    {
      const keys = Object.keys(rule);
      return keys[0][0] === '$';
    },
  },
computed: {
    expr() 
    {
      const keys = Object.keys(this.rule);
      if(keys.length > 0)
        return keys[0];
      else
        return '$and'    
    },
    isAnd()
    {
      return this.expr === '$and';
    },
    rules()
    {
      const rules = this.rule[this.expr];
      if(rules)
        return rules; 
      else
        return []; 
    }
  },
  async created() {

  },
};
</script>
