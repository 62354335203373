<template>
  <app-crud ref="crud" v-slot="{ item }" @validate="onValidate" height="w-auto">
    <app-textbox id="name" v-model="item.name" :error="nameErrorMsg" :focus="focusName"
      @change="validateField('nameErrorMsg', 'name', item)" :label="$t('users.fullName')" />

    <div class="flex my-4">
      <div>
        <app-checkbox v-model="item.noLogin" />
      </div>
      <span class="pl-2">
        {{ $t('users.noLogin') }}
      </span>
    </div>
    <div v-if="!item.noLogin">
      <app-textbox id="email" v-model="item.email" :error="emailErrorMsg" :focus="focusEmail"
        @change="validateField('nameErrorMsg', 'email', item)" :label="$t('users.email2')" :emailValidator="true" />
      <div class="">
        <app-dropdown v-model="item.roleId" :label="$t('users.role')" getter="GET_ALL_ROLES" />
      </div>
    </div>
    <div v-else class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <div class="text-sm text-yellow-700">
            <p>{{ $t('users.noLoginExplain') }}</p>
          </div>
        </div>
      </div>
    </div>
    <app-dropdown v-model="item.siteId" value="id"
      :label="$getRecordLabel('sites') ? $getRecordLabel('sites') : $t('assets.site')" getter="GET_ALL_SITES"
      :allow-nulls="true" :has-child="true" />
    <app-crud-properties v-model="item.props" />
  </app-crud>
</template>

<script>
export default {
  name: "UserCreatePage",
  data() {
    return {
      nameErrorMsg: '',
      emailErrorMsg: '',
      validated: false,
      focusName: false,
      focusEmail: false,
      data: []
    }
  },
  components: {},
  methods: {
    validateField(errorField, field, item) {
      if (item[field]) {
        this[errorField] = ''
      } else {
        this[errorField] = this.$t(`records.workorders.errors.${field}Required`);
      }
    },
    onValidate({ item, onSuccess, onError }) {
      if (!item.name) {
        this.nameErrorMsg = this.$t('records.workorders.errors.nameRequired');
        this.focusName = true;
        onError();

        document.getElementById("name").focus();
      } else if (item.NoLogin === false && !item.email) {
        this.emailErrorMsg = this.$t('records.users.errors.emailRequired');
        this.focusEmail = true;
        onError();

        document.getElementById("email").focus();
      } else {
        this.validated = true
        onSuccess();
      }
    },
    clearErrorMsg() {
      this.errorMsg = ''
    },
  },

  mounted() {
    this.focusName = true
    this.focusEmail = true
  },
  async beforeRouteLeave(to, from) {
    return await this.$refs.crud.beforeRouteLeave(to, from);
  }
}
</script>
