const Records = {
    assets: {
        id: "ASSET",
        name: "assets",
        icon: "cubes_regular",
        filterable: true,
        getter: "GET_ASSETS",
        action: "READ_ASSETS",
        requirements: ["ALL_USERS", "ALL_PARTS", "DB_FILTERS"],
        options: {
            getter: "GET_ALL_ASSETS",
            action: "READ_ALL_ASSETS",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                columns: ["name"],
                classes: {},
                sortBy: [
                    {
                        text: "Nombre",
                        field: "name",
                        option: "A-Z",
                        order: "asc",
                        default: true
                    },
                    {
                        text: "Nombre",
                        field: "name",
                        option: "Z-A",
                        order: "desc"
                    }
                ],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.assets.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assetId",
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.assets.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            },
            list: {
                columns: ["name", "number", "siteId", "brand", "model", "serialNumber"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.assets.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assetId",
                        text: "records.assets.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.assets.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.assets.fields.id", type: "id", sortable: false, filterable: false},
            {id: "name", name: "records.assets.fields.name", type: "text", sortable: true, filterable: false, importable: true, listable: true},
            {id: "number", name: "records.assets.fields.number", type: "number", sortable: true, icon: "", filterable: false, importable: true, listable: true,},
            {id: "brand", name: "assets.brand", type: "text", sortable: true, filterable: false, importable: true, listable: true},
            {id: "serialNumber", name: "assets.serialNumber", type: "text", sortable: true, filterable: false, importable: true, listable: true},
            {id: "model", name: "assets.model", type: "text", sortable: true, filterable: false, importable: true, listable: true},
            {id: "type", name: "records.assets.fields.type", type: "asset-type", sortable: true, filterable: false},
            {id: "siteId", name: "records.assets.fields.site", type: "record", record: "sites", sortable: true, filterable: true, icon: "location_dot_regular", hasAvatar: false, listable: true},
            {id: "parts", name: "records.assets.fields.part", type: "record", record: "parts", sortable: true, filterable: true, icon: "gears_regular", hasAvatar: false},
            {id: "description", name: "records.assets.fields.description", type: "asset-description", sortable: true, filterable: false, importable: true},
            {id: "actions", name: "records.assets.fields.actions", type: "action", sortable: false, filterable: false},
            // {id: "subAsset", name: "records.sites.fields.subAsset", type: "child-asset", sortable: true},
        ]
    },
    sites: {
        id: "SITE",
        title: "records.sites.title",
        shortTitle: "records.sites.shortTitle",
        createTitle: "records.sites.createTitle",
        name: "sites",
        icon: "location_dot_regular",
        filterable: true,
        getter: "GET_SITES",
        action: "READ_SITES",
        requirements: ["ALL_USERS", "ALL_SITES"],
        options: {
            getter: "GET_ALL_SITES",
            action: "READ_ALL_SITES",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                sortBy: [
                    {
                        text: "Nombre",
                        field: "name",
                        option: "A-Z",
                        order: "asc",
                        default: true
                    },
                    {
                        text: "Nombre",
                        field: "name",
                        option: "Z-A",
                        order: "desc"
                    }
                ],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.sites.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "siteId",
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name", "location","childsCount"],
                classes: {}
            },
            list: {
                columns: ["name", "location", "childsCount"]
            }
        },
        fields: [
            {id: "id", name: "records.sites.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.sites.fields.name", type: "text", sortable: true, importable: true, listable: true},
            {id: "number", name: "records.sites.fields.number", type: "number", sortable: true, icon: "", filterable: false, importable: true, listable: true,},
            {id: "fullName", name: "records.sites.fields.name", type: "text", sortable: true},
            {id: "location", name: "records.sites.fields.location", type: "location-site", sortable: true, listable: true},
            {id: "childsCount", name: "records.sites.fields.childsCount", type: "number", sortable: false, listable: true},
            {id: "childs", name: "records.sites.fields.childs", type: "record", record: "sites", sortable: false, listable: false, icon: "location_dot_regular"},
            /*// {id: "subSite", name: "records.sites.fields.subSite", type: "child-site", sortable: true},*/
            {id: "description", name: "records.sites.fields.description", type: "text", sortable: true, importable: true},
            {id: "actions", name: "records.sites.fields.actions", type: "action", sortable: false, filterable: false},
        ]
    },
    workorders: {
        id: "WORKORDER",
        name: "workorders",
        label: "",
        icon: "inbox_full_regular",
        hasNumber: true,
        filterable: true,
        getter: "GET_WORKORDERS",
        action: "READ_WORKORDERS",
        requirements: ["ALL_USERS", "CURRENT_COMPANY", "ALL_CATEGORIES", "DB_FILTERS", "COLUMNS", "PUBLISH_TEMPLATES", "ALL_REQUESTS", "ALL_METRICS", "ALL_SITES"],
        options: {
            getter: "GET_ALL_WORKORDERS",
            action: "READ_ALL_WORKORDERS",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                options: [
                    {
                        text: "pending",
                        filter: {status: ["pending", "onHold", "inProgress"]},
                    },
                    {
                        text: "completed",
                        filter: {status: ["done"]},
                    },
                ],
                sortBy: [
                    {
                        text: "Nuevos",
                        field: "creationTime",
                        default: true,
                        option: "Nuevos",
                        order: "asc"
                    },
                    {
                        text: "Vencimiento",
                        field: "dueDate",
                        option: "Vencimiento",
                        order: "asc"
                    },
                    {
                        text: "Prioridad",
                        field: "priority",
                        option: "Prioridad",
                        order: "asc"
                    },
                ],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "exportToPDF",
                        permission: "export_pdf",
                        icon: "print_regular",
                        color: "gray",
                        //Valores por defecto para la exportacion a PDF
                        options: {
                            site: true,
                            asset: true,
                            categories: true,
                            comments: true,
                            history: true,
                            information: true,
                            priority: true,
                            attachments: true,
                            signatureLine: true,
                            images: true,
                            exportInfo: true
                        },
                    },
                    {
                        function: "copy_link",
                        text: "records.workorders.actions.copy_link",
                        icon: "link_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.workorders.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name", "assignedTo", "creationId", "number", "status", "priority"]
            },
            list: {
                columns: [
                    "name",
                    "number",
                    "status",
                    "priority",
                    "dueDate",
                    "assignedTo",
                    "siteId"
                ]
            },
            calendar: {},
            kanban: {},
        },
        fields: [
            {id: "id", name: "records.workorders.fields.id", type: "id", sortable: false, filterable: false},
            {id: "name", name: "records.workorders.fields.name", type: "workorder_name", sortable: true, icon: "bars_sort_regular", filterable: false, importable: true, listable: true},
            {id: "number", name: "records.workorders.fields.number", type: "workorder_number", sortable: true, icon: "", filterable: false, importable: true, listable: true,},
            {id: "orderType", name: "records.workorders.fields.orderType", type: "text", sortable: true, filterable: true, listable: true,
                options: [
                    {name: "corrective", id: "corrective", color: "blue"},
                    {name: "preventive", id: "preventive", color: "yellow"},
                    {name: "metric", id: "metric", color: "blue"},
                    {name: "inspection", id: "inspection", color: "green"},
                ]
            },
            {id: "description", name: "records.workorders.fields.description", type: "text", sortable: true, filterable: false, listable: true},
            {id: "assignedTo", name: "records.workorders.fields.assignedTo", type: "record", record: "users", sortable: true, icon: "user_regular", filterable: true, hasAvatar: true, listable: true},
            {id: "categories", name: "records.workorders.fields.categories", type: "record", record: "categories", sortable: true, icon: "tag_regular", filterable: true, multiple: true, hasAvatar: false, hasColors: true},
            {id: "startDate", name: "records.workorders.fields.startDate", type: "date", sortable: true, filterable: false},
            {id: "assetId", name: "records.workorders.fields.asset", type: "record", record: "assets", sortable: true, icon: "boxes_stacked_regular", filterable: true, hasAvatar: false},
            {id: "siteId", name: "records.workorders.fields.site", type: "record", record: "sites", sortable: true, icon: "location_dot_regular", filterable: true, hasAvatar: false, listable: true},
            {id: "metricId", name: "records.workorders.fields.metric", type: "record", record: "metrics", sortable: true, icon: "gauge_high_regular", filterable: true, hasAvatar: false},
            {id: "templateId", name: "records.workorders.fields.template", type: "record", record: "templates", sortable: false, icon: "file_lines_regular", filterable: false, hasAvatar: false},
            {id: "metricId", name: "records.workorders.fields.metric", type: "record", record: "metrics", sortable: false, icon: "file_lines_regular", filterable: false, hasAvatar: false},
            {id: "parts", name: "records.workorders.fields.parts", type: "record", record: "parts", sortable: true, icon: "gear_regular", filterable: false, hasAvatar: false},
            {id: "readByUser", name: "records.workorders.fields.site", type: "object", sortable: true, filterable: false},
            {id: "creationId", name: "records.workorders.fields.requestedBy", type: "record", record: "users", sortable: false, filterable: false, hasAvatar: false},
            {id: "actions", name: "records.workorders.fields.actions", type: "action", sortable: false, filterable: false},
            {id: "images", name: "records.workorders.fields.media", type: "media", sortable: false, filterable: false},
            {id: "dueDate", name: "records.workorders.fields.dueDate", type: "date", sortable: true, icon: "clock_regular", filterable: true, listable: true,},
            {id: "status", name: "records.workorders.fields.status", type: "status", sortable: true, icon: "circle_info_regular", filterable: true, kanbable: true, listable: true,
                options: [
                    {name: "pending", id: "pending", color: "blue"},
                    {name: "onHold", id: "onHold", color: "yellow"},
                    {name: "inProgress", id: "inProgress", color: "blue"},
                    {name: "done", id: "done", color: "green"},
                ]
            },
            {id: "priority", name: "records.workorders.fields.priority", type: "priority", sortable: true, icon: "bars_sort_regular", filterable: true, kanbable: true, listable: true,
                options: [
                    {name: "none", id: "none", color: "gray"},
                    {name: "low", id: "low", color: "green"},
                    {name: "mid", id: "mid", color: "yellow"},
                    {name: "high", id: "high", color: "red"},
                ]
            },
        ]
    },
    users: {
        id: "USER",
        name: "users",
        icon: "users_regular",
        filterable: true,
        getter: "GET_USERS",
        action: "READ_USERS",
        requirements: ["ALL_ROLES", "ALL_USERS", "DB_FILTERS"],
        search: true,
        options: {
            getter: "GET_ALL_USERS",
            action: "READ_ALL_USERS",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                actions: [
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assignedTo",
                        multiple: true,
                        text: "records.users.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.users.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "email", "roleId", "lastLoginTime", "isValidated"],
                actions: [
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assignedTo",
                        multiple: true,
                        text: "records.users.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.users.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.users.fields.id", type: "id", sortable: false, filterable: false},
            {id: "name", name: "records.users.fields.name", type: "text", sortable: true, filterable: false, importable: true, listable: true},
            {id: "phone", name: "records.users.fields.phone", type: "phone", sortable: true, filterable: false, importable: true},
            {id: "email", name: "records.users.fields.email", type: "email", sortable: true, filterable: false, importable: true, listable: true},
            {id: "roleId", name: "records.users.fields.role", type: "record", record: "roles", icon: "users_regular", sortable: true, filterable: true, hasAvatar: false, listable: true},
            {id: "lastLoginTime", name: "records.users.fields.lastLoginTime", type: "text", sortable: true, filterable: false, listable: true},
            {id: "images", name: "records.users.fields.media", type: "media", sortable: true, filterable: false},
            {id: "color", name: "records.users.fields.color", type: "text", sortable: true, filterable: false},
            {id: "sites", name: "records.users.fields.sites", type: "record", record: "sites", sortable: true, filterable: true, icon: "location_dot_regular", hasAvatar: false, listable: true},
            {id: "noLogin", name: "records.users.fields.noLogin", type: "bool", sortable: true, filterable: false, listable: true},
            {id: "isValidated", name: "records.users.fields.isValidated", type: "text", sortable: true, filterable: false, listable: true},
            {id: "status", name: "records.users.fields.status", type: "status", sortable: true, icon: "circle_info_regular", filterable: false,
                options: [
                    {name: "onHold", id: "onHold", color: "yellow"},
                    {name: "inProgress", id: "inProgress", color: "blue"},
                    {name: "done", id: "done", color: "green"},
                    {name: "open", id: "open", color: "blue"},
                ]
            },
        ]
    },
    teams: {
        id: "TEAM",
        name: "teams",
        icon: "people_group_regular",
        filterable: false,
        getter: "GET_TEAMS",
        action: "READ_TEAMS",
        options: {
            getter: "GET_ALL_TEAMS",
            action: "READ_ALL_TEAMS",
        },
        views: {
            dual: {
                actions: [
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assignedTo",
                        multiple: true,
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.teams.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "description"],
                actions: [
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "assignedTo",
                        multiple: true,
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.teams.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.teams.fields.id", type: "id", sortable: false, filterable: false},
            {id: "name", name: "records.teams.fields.name", type: "text", sortable: true, filterable: false, listable: true},
            {
                id: "description",
                name: "records.teams.fields.description",
                type: "text",
                sortable: true,
                filterable: false,
                listable: true
            },
            {id: "images", name: "records.teams.fields.media", type: "media", sortable: true, filterable: false},
            {
                id: "members",
                name: "records.teams.fields.members",
                type: "record",
                record: "users",
                sortable: true,
                icon: "user_regular",
                filterable: true,
                multiple: true,
                hasAvatar: true
            },
            {
                id: "supervisors",
                name: "records.teams.fields.supervisors",
                type: "record",
                record: "users",
                sortable: true,
                icon: "user_regular",
                filterable: true,
                multiple: true,
                hasAvatar: true
            },
        ]
    },
    properties: {
        options: [
            {id: "string", name: 'customize_fields.types.text', icon: "tag_regular", color: "blue"},
            {id: "number", name: 'customize_fields.types.number', icon: "tag_regular", color: "primary"},
            {id: "date", name: 'customize_fields.types.date', icon: "tag_regular", color: "green"},
            {id: "boolean", name: 'customize_fields.types.boolean', icon: "tag_regular", color: "red"},
            {id: "option_set", name: 'customize_fields.types.option_set', icon: "tag_regular", color: "red"},
            {id: "option_set_dynamic", name: 'customize_fields.types.option_set_dynamic', icon: "tag_regular", color: "red"}
            /*
            {id: 'string', name: this.$t('records.properties.options.text')},
            {id: 'number', name: this.$t('records.properties.options.number')},
            {id: 'date', name: this.$t('records.properties.options.date')},
            {id: 'boolean', name: this.$t('records.properties.options.boolean')},
             */
        ],
    },
    categories: {
        id: "CATEGORY",
        name: "categories",
        icon: "tag_regular",
        filterable: false,
        title: "records.categories.title",
        shortTitle: "records.categories.shortTitle",
        createTitle: "records.categories.createTitle",
        getter: "GET_CATEGORIES",
        action: "READ_CATEGORIES",
        requirements: ["ALL_USERS"],
        options: {
            getter: "GET_ALL_CATEGORIES",
            action: "READ_ALL_CATEGORIES",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.sites.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "categories",
                        multiple: true,
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "description"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.sites.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "categories",
                        multiple: true,
                        text: "records.sites.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.categories.fields.id", type: "id", sortable: false},
            {
                id: "name",
                name: "records.categories.fields.name",
                type: "category_name",
                sortable: true,
                importable: true,
                listable: true
            },
            {
                id: "description",
                name: "records.categories.fields.description",
                type: "text",
                sortable: true,
                importable: true,
                listable: true
            },
            {id: "color", name: "records.categories.fields.color", type: "text", sortable: true},
        ]
    },
    requests: {
        id: "REQUEST",
        name: "requests",
        icon: "inbox_regular",
        filterable: true,
        getter: "GET_REQUESTS",
        action: "READ_REQUESTS",
        requirements: ["ALL_WORKORDERS", "DB_FILTERS"],
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                ]
            },
            dual: {
                columns: ["name"],
                classes: {},
                actions: [
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.requests.actions.delete",
                        icon: "trash_regular",
                        color: "red",
                    },
                    {
                        function: "reopen",
                        permission: "reopen",
                        text: "records.requests.actions.reopen",
                        icon: "rotate_left_regular",
                        color: "blue",
                        //Para poder reabrir una solicitud, esta debe haber sido rechazada
                        conditions: [ { func: (item) => item.status === 'rejected'} ]
                    },

                ],
            },
            list: {
                columns: ["name", "status", "workOrderId",  "workOrderStatus", "siteId", "assetId", "email"],
                actions: [
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.workorders.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.requests.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.requests.fields.name", type: "request_name", sortable: true, listable: true},
            {id: "number", name: "records.requests.fields.number", type: "text", sortable: true, icon: "", filterable: false, importable: true},
            {id: "description", name: "records.requests.fields.description", type: "text", sortable: true},
            {id: "email", name: "records.requests.fields.email", type: "text", sortable: true, listable: true},
            {id: "workOrderId", name: "records.requests.fields.workOrder", type: "workOrder", sortable: false, listable: true},
            {id: "images", name: "records.requests.fields.media", type: "media", sortable: true, filterable: false},
            {id: "assetId", name: "records.requests.fields.asset", type: "record", record: "assets", sortable: true, filterable: true, icon: "cubes_regular", hasAvatar: false, listable: true},
            {id: "siteId", name: "records.requests.fields.site", type: "record", record: "sites", sortable: true, filterable: true, icon: "location_dot_regular", hasAvatar: false, listable: true},
            {id: "status", name: "records.requests.fields.status", type: "requestStatus", sortable: true, icon: "circle_info_regular", filterable: true, listable: true,
                options: [
                    {name: "pending", id: "pending", color: "yellow"},
                    {name: "accepted", id: "accepted", color: "green"},
                    {name: "rejected", id: "rejected", color: "red"},
                ]
            },
            {id: "workOrderStatus", name: "records.requests.fields.workOrderStatus", type: "workOrderStatus", sortable: true, icon: "circle_info_regular", filterable: true, listable: true,
                options: [
                    {name: "pending", id: "pending", color: "blue"},
                    {name: "onHold", id: "onHold", color: "yellow"},
                    {name: "inProgress", id: "inProgress", color: "blue"},
                    {name: "done", id: "done", color: "green"},
                ]
            },
        ]
    },
    requestLandings: {
        id: "REQUEST_LANDING",
        name: "request-landings",
        getter: "GET_REQUEST_LANDINGS",
        icon: "inbox_regular",
        filterable: false,
        action: "READ_REQUEST_LANDINGS",
        views: {
            dual: {
                columns: ["name"],
                classes: {},
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            },
            list: {
                columns: ["name", "description"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.request-landings.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.request-landings.fields.name", type: "request_name", sortable: true, listable: true},
            {id: "welcomeText", name: "records.request-landings.fields.welcomeText", type: "text", sortable: true},
            {id: "description", name: "records.request-landings.fields.description", type: "text", sortable: true, listable: true},
            {
                id: "assetId",
                name: "records.request-landings.fields.asset",
                type: "record",
                record: "assets",
                sortable: true,
                filterable: true,
                icon: "cubes_regular",
                hasAvatar: false
            },
            {
                id: "siteId",
                name: "records.request-landings.fields.site",
                type: "record",
                record: "sites",
                sortable: true,
                filterable: true,
                icon: "location_dot_regular",
                hasAvatar: false
            },
            {
                id: "assignedTo",
                name: "records.request-landings.fields.assignedTo",
                type: "record",
                record: "users",
                sortable: true,
                icon: "user_regular",
                filterable: true,
                hasAvatar: true
            },
        ]
    },
    companies: {
        id: 'COMPANY',
        name: "companies",
        filterable: false,
        getter: "GET_COMPANIES",
        action: "READ_COMPANIES",
        views: {
            dual: {
                columns: ["name"],
                classes: {},
                sortBy: [
                    {
                        text: "Nombre",
                        field: "name",
                        option: "A-Z",
                        order: "asc",
                        default: true
                    },
                    {
                        text: "Nombre",
                        field: "name",
                        option: "Z-A",
                        order: "desc"
                    }
                ],
                actions: [
                    {
                        function: "loginCompany",
                        text: "records.assets.actions.loginCompany",
                        permission: "",
                        icon: "",
                    },
                    {
                        function: "delete",
                        permission: "",
                        text: "records.assets.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            },
            list: {
                columns: ["name", "languaje", "creationTime"],
                actions: [
                    {
                        function: "loginCompany",
                        permission: "",
                        text: "records.assets.actions.loginCompany",
                        icon: "",
                    },
                    {
                        function: "delete",
                        permission: "",
                        text: "records.assets.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.companies.fields.id", type: "id", sortable: false, filterable: false},
            {id: "name", name: "records.companies.fields.name", type: "text", sortable: true, filterable: false, listable: true},
            {
                id: "languaje",
                name: "records.companies.fields.languaje",
                type: "text",
                sortable: true,
                filterable: false,
                listable: true
            },
            {
                id: "creationTime",
                name: "records.companies.fields.creationTime",
                type: "date",
                sortable: true,
                filterable: false,
                listable: true,
                icon: "location_dot_regular"
            },
        ]
    },
    parts: {
        id: "PART",
        name: "parts",
        icon: "gears_regular",
        filterable: true,
        title: "records.parts.title",
        shortTitle: "records.parts.shortTitle",
        createTitle: "records.parts.createTitle",
        getter: "GET_PARTS",
        action: "READ_PARTS",
        requirements: ["ALL_ASSETS", "DB_FILTERS"],
        options: {
            getter: "GET_ALL_PARTS",
            action: "READ_ALL_PARTS",
        },
        views: {
            global: {
                actions: [
                    {
                        function: "exportToXLSX",
                        permission: "export_xls",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "importCSV",
                        permission: "import_csv",
                        icon: "file_excel_regular",
                        color: "gray",
                        allowEmpty: true
                    },
                ]
            },
            dual: {
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.parts.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "parts",
                        object: true,
                        text: "records.parts.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.parts.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "code", "unitCost", "stock", "reservedStock"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.parts.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "parts",
                        text: "records.parts.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.parts.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.parts.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.parts.fields.name", type: "text", sortable: true, importable: true, listable: true},
            {id: "images", name: "records.parts.fields.images", type: "media", sortable: true, filterable: false},
            {id: "description", name: "records.parts.fields.description", type: "text", sortable: true, importable: true},
            {id: "code", name: "records.parts.fields.code", type: "text", sortable: true, importable: true, listable: true},
            {id: "unitCost", name: "records.parts.fields.unitCost", type: "number", sortable: true, importable: true, listable: true},
            {id: "stock", name: "records.parts.fields.stock", type: "number", sortable: true, importable: true, listable: true},
            /*{id: "minStock", name: "records.parts.fields.minStock", type: "text", sortable: true, importable: true},*/
            {id: "reservedStock", name: "records.parts.fields.reservedStock", type: "number", sortable: true, importable: true, listable: true},
        ]
    },
    dashboard: {
        id: 'DASHBOARD',
        name: "dashboard",
        icon: "chart_line_regular",
        title: "records.dashboard.title",
        shortTitle: "records.dashboard.shortTitle",
        createTitle: "records.dashboard.createTitle",
        custom: true,
        getter: "GET_DASHBOARD",
        action: "READ_DASHBOARD",
        requirements: ["ALL_ASSETS", "ALL_SITES", "ALL_USERS", "ALL_CATEGORIES", "ALL_PARTS"],
        options: {},
        views: {
            global: {
                actions: []
            },
            dual: {
                actions: [
                    {
                        function: "delete",
                        //permission: "delete",
                        text: "records.dashboard.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ]
            },
            list: {
                columns: [],
                actions: [
                ],
            }
        },
        fields: [
            {id: "id", name: "records.dashboard.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.dashboard.fields.name", type: "text", sortable: true, filterable: false},
            {id: "description", name: "records.dashboard.fields.description", type: "text", sortable: true},
            {id: "icon", name: "records.dashboard.fields.icon", type: "text", sortable: false},
            {id: "color", name: "records.dashboard.fields.color", type: "text", sortable: false},
            {id: "recordId", name: "records.dashboard.fields.recordId", type: "text", sortable: false},
            {id: "filter", name: "records.dashboard.fields.filter", type: "text", sortable: false},


            {id: "reservedStock", name: "records.parts.fields.reservedStock", type: "text", sortable: true, importable: true},
            {id: "assets", name: "records.parts.fields.asset", type: "record", record: "assets", sortable: true, filterable: true, icon: "cubes_regular", hasAvatar: false},
        ]
    },
    metrics: {
        id: "METRIC",
        name: "metrics",
        icon: "gauge_high_regular",
        filterable: true,
        title: "records.metrics.title",
        shortTitle: "records.metrics.shortTitle",
        createTitle: "records.metrics.createTitle",
        getter: "GET_METRICS",
        action: "READ_METRICS",
        requirements: ["ALL_ASSETS", "ALL_SITES", "ALL_USERS", "DB_FILTERS"],
        options: {
            getter: "GET_ALL_METRICS",
            action: "READ_ALL_METRICS",
        },
        views: {
            global: {
                actions: []
            },
            dual: {
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.metrics.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.metrics.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "description", "lastValue", "measurementUnit", "assetId", "siteId"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.metrics.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.metrics.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.metrics.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.metrics.fields.name", type: "text", sortable: true, importable: true, listable: true},
            {id: "description", name: "records.metrics.fields.description", type: "text", sortable: true, importable: true, listable: true},
            {id: "lastValue", name: "records.metrics.fields.lastValue", type: "text", sortable: false, importable: true, listable: true},
            {id: "assetId", name: "records.metrics.fields.assetId", type: "record", record: "assets", sortable: true, importable: true, filterable: true, listable: true},
            {id: "siteId", name: "records.metrics.fields.siteId", type: "record", record: "sites", sortable: true, importable: true, filterable: true, listable: true},
            {id: "images", name: "records.metrics.fields.images", type: "media", sortable: true, filterable: false},
            {id: "measurementUnit", name: "records.metrics.fields.measurementUnit", type: "measurementUnit", sortable: true, importable: true, filterable: true, listable: true,
                options: [
                    {name: "records.metrics.units.miles", id: "miles", type: "length"},
                    {name: "records.metrics.units.feets", id: "feets", type: "length"},
                    {name: "records.metrics.units.inches", id: "inches", type: "length"},
                    {name: "records.metrics.units.kilometers", id: "kilometers", type: "length"},
                    {name: "records.metrics.units.meters", id: "meters", type: "length"},
                    {name: "records.metrics.units.centimeters", id: "centimeters", type: "length"},
                    {name: "records.metrics.units.millimeters", id: "millimeters", type: "length"},
                    {name: "records.metrics.units.galons", id: "galons", type: "volume"},
                    {name: "records.metrics.units.liters", id: "liters", type: "volume"},
                    {name: "records.metrics.units.cubicMeters", id: "cubicMeters", type: "volume"},
                    {name: "records.metrics.units.fareinheit", id: "fareinheit", type: "temperature"},
                    {name: "records.metrics.units.celsius", id: "celsius", type: "temperature"},
                    {name: "records.metrics.units.kelvin", id: "kelvin", type: "temperature"},
                    {name: "records.metrics.units.hours", id: "hours", type: "time"},
                    {name: "records.metrics.units.cicles", id: "cicles", type: "time"},
                    {name: "records.metrics.units.psi", id: "psi", type: "pressure"},
                    {name: "records.metrics.units.kilograms", id: "kilograms", type: "weight"},
                    {name: "records.metrics.units.grams", id: "grams", type: "weight"},
                    {name: "records.metrics.units.kilowatt", id: "kilowatt", type: "power"},
                ]
            },
        ]
    },
    roles: {
        id: "ROLE",
        name: "roles",
        getter: "GET_ROLES",
        action: "READ_ROLES",
        requirements: ["PERMISSIONS"],
        options: {
            getter: "GET_ALL_ROLES",
            action: "READ_ALL_ROLES",
        },
        views: {
            dual: {
                columns: ["name"],
                classes: {},
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            },
            list: {
                columns: ["name", "permissions"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.roles.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.roles.fields.name", type: "text", sortable: true, listable: true},
            {id: "permissions", name: "records.roles.fields.permissions", type: "text", sortable: false, filterable: false, listable: true},
        ]
    },
    templates: {
        id: "TEMPLATE",
        title: "records.templates.title",
        name: "templates",
        icon: "file_lines_regular",
        filterable: true,
        viewFireRequest: true,
        getter: "GET_TEMPLATES",
        action: "READ_TEMPLATES",
        requirements: ["ALL_OPTIONSETS", "DB_FILTERS"],
        options: {
            getter: "GET_ALL_TEMPLATES",
            action: "READ_ALL_TEMPLATES",
        },
        redirectTo: {
            id: "templateId",
            to: "workorders",
            function: "createInspection",
            permission: "read_inspections",
            icon: "chevron_right_regular",
            color: "gray",
            allowEmpty: false
        },
        views: {
            dual: {
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.sites.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        id: "templateId",
                        to: "workorders",
                        function: "createInspection",
                        permission: "create",
                        icon: "chevron_right_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "templateId",
                        text: "records.templates.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "description", "action"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.sites.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        id: "templateId",
                        to: "inspections",
                        function: "goToCreate",
                        permission: "create",
                        icon: "chevron_right_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "templateId",
                        text: "records.templates.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.templates.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.templates.fields.name", type: "redirect", sortable: true, listable: true},
            {id: "description", name: "records.templates.fields.description", type: "text", sortable: true, listable: true},
            {id: "objetive", name: "records.templates.fields.objetive", type: "text", sortable: true, listable: true},
            {id: "policy", name: "records.templates.fields.policy", type: "text", sortable: true, listable: true},
            {id: "action", name: "records.templates.fields.action", type: "button", sortable: true, listable: true},
        ]
    },
    procedures: {
        id: "PROCEDURE",
        title: "Procedimientos",
        singular_title: "Procedimiento",
        name: "procedures",
        icon: "file_lines_regular",
        filterable: true,
        viewFireRequest: true,
        getter: "GET_PROCEDURES",
        action: "READ_PROCEDURES",
        requirements: ["ALL_OPTIONSETS", "DB_FILTERS", "READ_ALL_TEMPLATES"],
        options: {
            getter: "GET_ALL_PROCEDURES",
            action: "READ_ALL_PROCEDURES",
        },
        redirectTo: {
            id: "templateId",
            to: "workorders",
            function: "createInspection",
            permission: "read_inspections",
            icon: "chevron_right_regular",
            color: "gray",
            allowEmpty: false
        },
        views: {
            dual: {
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.procedures.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        id: "templateId",
                        to: "workorders",
                        function: "createInspection",
                        permission: "create",
                        icon: "chevron_right_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "templateId",
                        text: "records.procedures.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.procedures.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name"],
                classes: {}
            },
            list: {
                columns: ["name", "objetive"],
                actions: [
                    {
                        function: "duplicate",
                        permission: "create",
                        text: "records.procedures.actions.duplicate",
                        icon: "copy_regular",
                        color: "gray"
                    },
                    {
                        id: "templateId",
                        to: "inspections",
                        function: "goToCreate",
                        permission: "create",
                        icon: "chevron_right_regular",
                        color: "gray",
                        allowEmpty: false
                    },
                    {
                        function: "assignToWorkOrder",
                        permission: "create",
                        field: "templateId",
                        text: "records.procedures.actions.assignToWorkOrder",
                        icon: "circle_plus_regular",
                        color: "gray"
                    },
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.procedures.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.procedures.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.procedures.fields.name", type: "redirect", sortable: true, listable: true},
            {id: "objetive", name: "records.procedures.fields.objetive", type: "text", sortable: true, listable: true},
            {id: "policy", name: "records.procedures.fields.policy", type: "text", sortable: true, listable: true},
            {id: "body", name: "records.procedures.fields.body", type: "text", sortable: true, listable: true},
            {id: "measurement", name: "records.procedures.fields.measurement", type: "text", sortable: true, listable: true},
            {id: "templateId", name: "records.workorders.fields.template", type: "record", record: "templates", sortable: false, icon: "file_lines_regular", filterable: false, hasAvatar: false},
        ]
    },
    inspections: {
        id: "INSPECTION",
        title: "records.inspections.title",
        name: "inspections",
        icon: "file_lines_regular",
        filterable: false,
        getter: "GET_INSPECTIONS",
        action: "READ_INSPECTIONS",
        options: {
            getter: "GET_ALL_INSPECTIONS",
            action: "READ_ALL_INSPECTIONS",
        },
        requirements: ["ALL_TEMPLATES", "ALL_ASSETS", "ALL_SITES", "ALL_USERS", "ALL_METRICS"],
        views: {
            dual: {
                actions: [
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
                columns: ["name", "templatePublish"],
                classes: {}
            },
            list: {
                columns: ["templatePublish", "workOrderId", "name"],
                actions: [
                    {
                        function: "delete",
                        permission: "delete",
                        text: "records.sites.actions.delete",
                        icon: "trash_regular",
                        color: "red"
                    },
                ],
            }
        },
        fields: [
            {id: "id", name: "records.inspections.fields.id", type: "id", sortable: false},
            {id: "name", name: "records.inspections.fields.name", type: "text", sortable: true, listable: true},
            {id: "description", name: "records.inspections.fields.description", type: "text", sortable: true},
            {id: "templatePublish", name: "records.inspections.fields.templatePublish", type: "templatePublish", sortable: true, listable: true},
            {id: "workOrderId", name: "records.inspections.fields.workOrder", type: "record", record: "workorders", sortable: true, filterable: true, icon: "inbox_full_regular", hasAvatar: false, listable: true},
        ]
    },
};

export default Records;
