<template>
  <div class="max-w-screen-md border m-auto my-10 pb-5 bg-white rounded-xl shadow shadow-slate-300 flex flex-col justify-center items-center space-y-20 ">
    <div class="flex items-center flex-shrink-0 h-16 px-4 align-middle">
      <img class="h-16 mt-8" src="/images/zigo.png" alt="Workflow"/>
    </div>
    <h1 class="text-4xl font-medium center">{{ $t('validate.title')}}</h1>

    <div class="w-1/2">
      <app-textbox id="new_password" v-model="password"  :label="$t('enterYourPassword')" :placeholder="$t('enterYourPassword')" type="password" :autocomplete="false" />
    </div>
    <app-button @click="onSubmit"> {{ $t('validate.activate')}} </app-button>
    <span class="text-red-700 font-bold">{{ validate_error }}</span>

  </div>

</template>


<script>
  import { mapActions, mapGetters} from 'vuex'

  export default {
    name: 'MailValidationPage',
    data() {
      return {
        name: '',
        password: '',
      }
    },

    computed: {
    ...mapGetters({ validate_error: 'GET_VALIDATE_ERROR' }),
  },
    methods: {
      ...mapActions(['VALIDATE_MAIL']),
      async onSubmit() {
        await this.VALIDATE_MAIL({
          code: this.$route.params.code,
          password: this.password
        })
      }
    }
  }
</script>

<style scoped>
.password{
  -webkit-text-security: disc;
  font-family: text-security-disc;
}
</style>
