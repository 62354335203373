import {createApp} from 'vue'
import App from './App.vue'
import { createGtm } from '@gtm-support/vue-gtm';
import router from './router'
import store from './store'

import VueMobileDetection from "vue-mobile-detection";

import VueTelInput from 'vue3-tel-input'

import AppRecordList from '@/components/AppRecordList.vue'

import AppInputContainer from '@/components/AppInputContainer'
import AppComboBox from '@/components/AppComboBox'
import AppPoweredByZigo from '@/components/AppPoweredByZigo.vue'
import AppPopUpContainer from '@/components/AppPopUpContainer.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppLoading from '@/components/AppLoading.vue'
import AppButton from '@/components/AppButton.vue'
import AppButton2 from '@/components/AppButton2.vue'
import AppAlert from '@/components/AppAlert.vue'
import AppPriorityPicker from "@/components/AppPriorityPicker.vue";
import AppFileList from "@/components/AppFileList.vue";
import AppAutoComplete from "@/components/AppAutoComplete.vue";
import AppGrid from '@/components/AppGrid.vue'
import AppStepper from '@/components/AppStepper.vue'
import AppStep from '@/components/AppStep.vue'
import AppDivider from '@/components/AppDivider.vue'
import AppDualList from '@/components/AppDualList.vue'
import AppCheckbox from '@/components/AppCheckbox.vue'
import AppDatePicker from '@/components/AppDatePicker.vue'
import AppTime from '@/components/AppTime.vue'
import AppTextbox from '@/components/AppTextbox.vue'
import AppEmailbox from '@/components/AppEmailbox.vue'
import AppPhonebox from '@/components/AppPhonebox.vue'
import AppAddress from '@/components/AppAddress.vue'
import AppSelect from '@/components/AppSelect.vue'
import AppSingleImage from "@/components/AppSingleImage.vue";
import AppTextarea from '@/components/AppTextarea.vue'
import AppChecklist from '@/components/AppChecklist.vue'
import AppImageList from '@/components/AppImageList.vue'
import AppIcon from "@/components/AppIcon";
import AppImage from '@/components/AppImage.vue'
import AppFile from '@/components/AppFile.vue'
import AppCustomer from '@/components/AppCustomer.vue'
import AppFormField from '@/components/AppFormField.vue'
import AppOptionCard from '@/components/AppOptionCard.vue'
import AppActionBar from '@/components/AppActionBar.vue'
import AppProgress from '@/components/AppProgress.vue'
import AppBackButton from '@/components/AppBackButton.vue'
import AppQueryBuilder from '@/components/querybuilder/AppQueryBuilder.vue'
import AppQuantity from '@/components/AppQuantity.vue'
import AppPagination from '@/components/AppPagination.vue'
import AppFilters from '@/components/AppFilters.vue'
import AppColorPicker from '@/components/AppColorPicker.vue'
import AppColors from '@/components/AppColors.vue'
import AppConfirmPopup from './components/AppConfirmPopup.vue'
import AppConfirmDialog from './components/AppConfirmDialog.vue'
import AppUserSelector from "@/components/AppUserSelector.vue";
import AppPartsSelector from "@/components/AppPartsSelector.vue";
import AppDropdown from "@/components/AppDropdown"
import AppText from "@/components/AppText"
import AppAddFirst from "@/components/AppAddFirst"
import AppTabs from "@/components/AppTabs";
import AppTable from "@/components/AppTable";
import AppLabel from "@/components/AppLabel";
import AppLog from "@/components/AppLog";

import AppCrud from "@/crud/AppCrud";
import AppCrudFieldEdit from "@/crud/AppCrudFieldEdit";
import AppFilterMenu from "@/components/AppFilterMenu";
import AppFilterDialog from "@/components/AppFilterDialog";
import AppCrudProperties from "@/crud/AppCrudProperties";
import AppCrudDashboard from "@/crud/AppCrudDashboard";

import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import ExportOptionsDialog from "@/components/ExportOptionsDialog";
import WorkOrderHistory from "@/pages/workorders/WorkOrderHistory";
import VuePapaParse from 'vue-papa-parse'
import i18n from "@/locales/i18n"

import Icons from "@/constants/icons"
import tailwindConfig from './../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'
import iconsByStatus from "@/constants/iconsByStatus";
import iconsByPriority from "@/constants/iconsByPriority";
import permissions from "@/global/permissions";
import modules from "@/global/modules";

import TemplateQuestion from '@/pages/templates/editor/TemplateQuestion'
import TemplateSection from '@/pages/templates/editor/TemplateSection'
import TemplatePropertyType from '@/pages/templates/editor/TemplatePropertyType'

import './index.css'
import 'dropzone-vue/dist/dropzone-vue.common.css';
import {setupCalendar} from 'v-calendar';
import 'v-calendar/style.css';
import isNullOrEmpty from "@/global/validations";
import AppAccordion from "@/components/AppAccordion.vue";
import AppCrudColumnsOptions from "@/crud/AppCrudColumnsOptions.vue";
import recordLabels from './global/recordLabels';
import { formatDate, formatNumber } from '@/global/format';


const StartApp = async () => {
    const myV3App = createApp(App)
    myV3App.config.globalProperties.$icons = Icons
    myV3App.config.globalProperties.$colors = resolveConfig(tailwindConfig).theme.colors
    myV3App.config.globalProperties.$iconsByStatus = iconsByStatus
    myV3App.config.globalProperties.$iconsByPriority = iconsByPriority
    myV3App.config.globalProperties.$hasPermission = permissions.hasPermission
    myV3App.config.globalProperties.$isNullOrEmpty = isNullOrEmpty
    myV3App.config.globalProperties.$hasModule = modules.hasModule
    myV3App.config.globalProperties.$getRecordLabel = recordLabels.getRecordLabel
    myV3App.config.globalProperties.$hasWorkOrderSetting = modules.hasWorkOrderSetting
    myV3App.config.globalProperties.$formatDate = formatDate
    myV3App.config.globalProperties.$formatNumber = formatNumber

    myV3App
        .use(store)
        .use(router)
        .use(createGtm({
              id: 'GTM-NDKGWZ59', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
              defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
              compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
              nonce: '2726c7f26c', // Will add `nonce` to the script tag
              enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
              debug: false, // Whether or not display console logs debugs (optional)
              loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
              vueRouter: router, // Pass the router instance to automatically sync with router (optional)
              ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
              trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }))
        .use(VueTelInput)
        .use(i18n)
        .use(VuePapaParse)
        .use(VueMobileDetection)

        .use(setupCalendar, {})

        .component("AppPopUpContainer", AppPopUpContainer)
        .component("AppPoweredByZigo", AppPoweredByZigo)

        .component("AppRecordList", AppRecordList)

        .component("AppInputContainer", AppInputContainer)
        .component("AppCombobox", AppComboBox)
        .component("AppSidebar", AppSidebar)
        .component("AppSingleImage", AppSingleImage)
        .component("AppLoading", AppLoading)
        .component("AppGrid", AppGrid)
        .component("AppStepper", AppStepper)
        .component("AppStep", AppStep)
        .component("AppButton", AppButton)
        .component("AppButton2", AppButton2)
        .component("AppAlert", AppAlert)
        .component("AppFileList", AppFileList)
        .component("AppPriorityPicker", AppPriorityPicker)
        .component("AppAutoComplete", AppAutoComplete)
        .component("AppDivider", AppDivider)
        .component("AppDualList", AppDualList)
        .component("AppCheckbox", AppCheckbox)
        .component("AppDatePicker", AppDatePicker)
        .component("AppTime", AppTime)
        .component("AppTextbox", AppTextbox)
        .component("AppEmailbox", AppEmailbox)
        .component("AppPhonebox", AppPhonebox)
        .component("AppUserSelector", AppUserSelector)
        .component("AppPartsSelector", AppPartsSelector)
        .component("AppAddress", AppAddress)
        .component("AppSelect", AppSelect)
        .component("AppTextarea", AppTextarea)
        .component("AppChecklist", AppChecklist)
        .component("AppImageList", AppImageList)
        .component("AppImage", AppImage)
        .component("AppFile", AppFile)
        .component("AppColorPicker", AppColorPicker)
        .component("AppCustomer", AppCustomer)
        .component("AppFormField", AppFormField)
        .component("AppOptionCard", AppOptionCard)
        .component("AppActionBar", AppActionBar)
        .component("AppProgress", AppProgress)
        .component("AppBackButton", AppBackButton)
        .component("AppQueryBuilder", AppQueryBuilder)
        .component("AppQuantity", AppQuantity)
        .component("AppPagination", AppPagination)
        .component("AppFilters", AppFilters)
        .component("AppColors", AppColors)
        .component("AppConfirmPopup", AppConfirmPopup)
        .component("AppConfirmDialog", AppConfirmDialog)
        .component("AppDropdown", AppDropdown)
        .component("AppText", AppText)
        .component("AppBreadcrumbs", AppBreadcrumbs)
        .component("ExportOptionsDialog", ExportOptionsDialog)
        .component("AppAddFirst", AppAddFirst)
        .component("AppTabs", AppTabs)
        .component("AppCrud", AppCrud)
        .component("AppLabel", AppLabel)
        .component("AppLog", AppLog)
        .component("AppCrudFieldEdit", AppCrudFieldEdit)
        .component("AppFilterMenu", AppFilterMenu)
        .component("AppFilterDialog", AppFilterDialog)
        .component("AppTable", AppTable)

        .component("TemplateQuestion", TemplateQuestion)
        .component("TemplateSection", TemplateSection)
        .component("TemplatePropertyType", TemplatePropertyType)

        .component("AppIcon", AppIcon)
        .component("WorkOrderHistory", WorkOrderHistory)
        .component("AppAccordion", AppAccordion)
        .component("AppCrudColumnsOptions", AppCrudColumnsOptions)
        .component("AppCrudProperties", AppCrudProperties)
        .component("AppCrudDashboard", AppCrudDashboard)

    myV3App.mount('#app')
}

const token = localStorage.getItem('zigo-token')
store.commit('SET_TOKEN', token)
store.commit('SET_TOKEN_TIME', localStorage.getItem('zigo-token-time'))

if (!token) {
    StartApp()
} else {
    store.dispatch('USER')
      .then(async () => {
        await store.dispatch('READ_ALL_ROLES');
        await StartApp()
    })
}
