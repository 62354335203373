<template>
  <app-loading :loading="loading" />
  <div class="max-w-5xl mb-4" v-if="currentPage">
    <div v-for="(item, itemIndex) in currentPage.items" :key="item.id">
        <div v-if="item.isSection">
            <InspectionSection :item="item" :check-validation-answers="checkValidationAnswers" isRoot="true" :disabled="workOrder.status === 'done' || inspection.status === 'finished'"/>
        </div>
        <div v-else class="border rounded-lg my-5 px-5 py-5 shadow-md" 
            :class="item.isRequired &&  isNullOrEmpty(value[itemIndex]) && checkValidationAnswers ? 'border-red-500 bg-[#FBEEED]' : 'order-gray-300'">
          <InspectionQuestion :item="item" @updateValue="updateValue($event, itemIndex)" :disabled="workOrder.status === 'done' || inspection.status === 'finished'"/>
        </div>
    </div>
  </div>
  <Teleport to="#footer">
    <div v-if="inspection.status !== 'finished'" class="flex justify-center">
      <app-button @click="finishInspection" type="secondary" background="light" :disabled="isDisabled || hasIncompleteRequiredItems">
        <span class="font-nhu-regular">{{$t('finish')}}</span>
      </app-button>
    </div>
  </Teleport>
  <!--    <div class="h-[100px]"></div>-->
  <!--    <div class="h-0 border-b border-gray-50 my-4"/>-->
  <!--    <div v-if="template" class=" p-2 bg-white flex justify-center">-->
  <!--        <div class="max-w-5xl w-full flex justify-between">-->
  <!--            <div class="flex justify-center">-->
  <!--                <app-button @click="prevPage" type="secondary" background="light" :disabled="template?.pages?.length < 1 || currentPageIndex === 0">-->
  <!--                    {{$t('inspections.previousPage')}}-->
  <!--                </app-button>-->
  <!--            </div>-->
  <!--            <div class="flex justify-center">-->
  <!--                <app-button @click="nextPage" type="secondary" background="light" :disabled="currentPageIndex === template?.pages?.length - 1">-->
  <!--                  {{$t('inspections.nextPage')}}-->
  <!--                </app-button>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InspectionSection from "@/pages/inspections/InspectionSection.vue";
import InspectionQuestion from "@/pages/inspections/InspectionQuestion.vue";
import isNullOrEmpty from "@/global/validations";

export default {
    name: "InspectionPage",
    components: {
      InspectionQuestion,
      InspectionSection,
    },
    emits: ['finishInspection'],
    props: {
        workOrder: {
          type: Object,
          default: null
        },
        checkValidationAnswers: {
            type: Boolean,
            default: false
        },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            results: [],
            currentPageIndex: 0,
            loading: true,
            saving: false,
            value: []
        };
    },
    computed: {
        ...mapGetters({inspection: 'GET_INSPECTION', template: 'GET_PUBLISH_TEMPLATE'}),
        currentPage() {
            return this.template && this.template.pages
                ? this.template.pages[this.currentPageIndex]
                : null;
        },
        isDisabled() {
            return this.workOrder.status === 'done' || this.inspection.status === 'finished' || this.workOrder.status === 'pending';
        },
        hasIncompleteRequiredItems() {
          return this.currentPage?.items.some(item => {
            if (item.isRequired) {
              // Buscar el item correspondiente en los resultados de la inspección
              const correspondingResult = this.inspection.results.find(result => result.id === item.id);
              // Verificar si el resultado existe y tiene un valor no nulo y no vacío
              return !correspondingResult || !correspondingResult.value || this.isNullOrEmpty(correspondingResult.value);
            } else {
              // Si el item no es requerido, se considera válido
              return false;
            }
          });
        },
    },

    methods: {
      isNullOrEmpty,
      ...mapActions(['READ_INSPECTION', 'READ_PUBLISH_TEMPLATE', 'SAVE_INSPECTION']),
      navigateToList(){
          this.$router.push('/inspections');
      },
      nextPage() {
          if (this.currentPageIndex < this.template.pages.length - 1) {
              this.currentPageIndex++;
          }
      },
      prevPage() {
          if (this.currentPageIndex > 0) {
              this.currentPageIndex--;
          }
      },
      updateValue(resultChanged, index) {
        return this.value[index] = resultChanged?.value;
      },
      async finishInspection() {
        this.saving = true;
        const newInspection = {...this.inspection};
        newInspection.status = 'finished';
        await this.SAVE_INSPECTION(newInspection)
          .then(() => {
            this.$emit('finishInspection');
          });
          this.saving = false;
      }
    },
    created() {
        this.loading = false;
    },
    async mounted() {
        this.loading = true;
        if (this.workOrder) {
          if (this.workOrder.inspectionId) {
            await this.READ_INSPECTION({ id: this.workOrder.inspectionId });
          }
          await this.READ_PUBLISH_TEMPLATE({ id: this.workOrder.templatePublishId });
        }
        this.loading = false;
    }
}
</script>
