const state = {
    fileName : '',
    visible : false,
    loading : false,
    url : '',
    exportOptions: {},
};
const getters = {
    GET_EXPORT_OPTIONS: state => state.exportOptions,
    GET_EXPORTED_DOCUMENT: state => {
        return state;
    },
};
const mutations = {
    SET_EXPORT_OPTIONS: (state, payload) => {
        state.exportOptions = payload;
    },
    SET_EXPORT_OPTIONS_ITEM_BY_INDEX:(state, index) => {
        state.exportOptions[index] = !state.exportOptions[index];
    },
    SET_VIEW_DOCUMENT : (state, fileName) => {
        state.fileName = fileName;
        state.visible = true;
        state.loading = true;
    },
    SET_DOWNLOAD_LINK : (state, file) => {
        state.url = file.url;
        state.loading = false;
    },
    SET_CLOSE_VIEW_DOCUMENT : (state) => {
        state.fileName = '';
        state.visible = false;
        state.loading = false;
        state.url = '';
    },
};
export default {
    state,
    getters,
    mutations
}
