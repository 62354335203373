<template>
  <div class="h-[calc(100%-25px)]">
    <div class="w-full justify-end flex">
      <div class="w-1/4">
        <Menu as="div"
              class="flex justify-end p-1 mr-2 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
          <div>
            <MenuButton class="flex justify-end">
              <div class="fs-14 font-nhu-regular py-2">{{ $t('records.showBy') }}:</div>
              <div class="flex font-nhu-regular fs-14 px-3 py-2 items-center cursor-pointer text-primary-500 fill-primary-500 hover:text-primary-400 hover:fill-primary-400">
                <span class="capitalize cursor-pointer font-nhu-semi-bold">{{$t(`${getFieldName}`)}}</span>
                <app-icon icon="chevron_down_regular" :width="14" extra-class="ml-1" :height="14"></app-icon>
              </div>
            </MenuButton>
          </div>
          <transition enter-active-class="transition duration-100 ease-out"
                      enter-from-class="transform scale-95 opacity-0"
                      enter-to-class="transform scale-100 opacity-100"
                      leave-active-class="transition duration-75 ease-in"
                      leave-from-class="transform scale-100 opacity-100"
                      leave-to-class="transform scale-95 opacity-0">
            <MenuItems
                :class="['absolute w-32 mt-8 py-1 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-20 fs-14 text-gray-500']">
              <MenuItem v-for="item in getKanbanFieldsOptions" :key="item.name" v-slot="{ active }">
              <span :class="[active ? 'bg-gray-100 text-gray-700' : '', 'block px-4 py-2']"
                @click="kanbanField = item.id">
               {{item.name}}
              </span>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <div class="flex bg-gray-50 h-full text-xs leading-6 text-gray-700 lg:flex-auto" v-if="getKanbanColumns.length>0">
      <div class="w-full overflow-clip overflow-x-hidden h-full border-t-[1px] border-b-[1px] border-r-[1px]"
           :class="['grid-cols-'+getKanbanColumns.length, {'bg-primary-100 border-primary-500 border-[1px]': option.id === columnId}, {'border-l-[1px]': index===0}]"
           v-for="(option, index) in getKanbanColumns" :key="option.id">
        <div class="h-[calc(100%-100px)]">
          <div class="py-4 bg-gray-50 font-nhu-semi-bold fs-16 capitalize text-gray-700 sticky top-0 px-2 2xl:px-4 z-99">
            {{ $t(option.id) }}
          </div>
          <div class="h-full overflow-y-auto overflow-x-hidden">
            <Draggable :list="kanbanData.filter(x => x[this.kanbanField] === option.id)" group="kanban-items" @drag="columnId = option.id" @drop="columnId = null" :itemKey="item =>  item.id"
                      class="border-gray-300 h-full px-2 2xl:px-4 pt-2" @change="updateItem($event, option, kanbanField)">
              <template #item="{element: item}">
                <div class="bg-white border-[1px] mb-4 rounded-lg cursor-pointer" :id="item.id">
                  <app-crud-work-order-kanban :workOrder="item" />
                </div>
              </template>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Draggable from "vuedraggable";
import AppCrudWorkOrderKanban from "@/crud/AppCrudWorkOrderKanban";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";

export default {
  name: "AppCrudKanbanBoard",
  components: {AppCrudWorkOrderKanban, Draggable, Menu, MenuButton, MenuItem, MenuItems},
  computed: {
    ...mapGetters({record: 'GET_RECORD', filters: 'GET_FILTERS', kanbanNeedUpdate: 'GET_KANBAN_NEED_UPDATE'}),
    getKanbanFieldsOptions() {
      let data = this.record.fields.filter(x => x.kanbable).map(x => ({id: x.id, name: this.$t(x.name)}))
      return data
    },
    kanbanData(){
      return this.$store.getters['GET_' + this.record.name.toUpperCase()+'_KANBAN'].data
    },
    getKanbanColumns() {
      return this.record.fields.find(x => x.id === this.kanbanField)?.options ?? []
    },
    getFieldName() {
      return this.record.fields.find(x => x.id === this.kanbanField)?.name ?? ''
    },
  },
  /*watch: {
    kanbanNeedUpdate: {
      handler: function (val) {
        if (val) {
          this.$store.dispatch('SET_KANBAN_NEED_UPDATE', false)
          this.$store.dispatch('GET_' + this.record.name.toUpperCase()+'_KANBAN', this.filters)
        }
      },
      deep: true
    }
  },*/
  data() {
    return {
      kanbanField: null,
      open: false,
      columnId: null,
      onDrag: false,
      localData: []
    }
  },
  methods: {
    async updateItem(evt, column, field) {
      if (evt.removed)
        return
      const item = evt.added?.element ?? evt.moved?.element
      const itemCopy = {...item}
      if (evt.added) {
        item[field] = column.id
        let data = {
          payload: {
            id: item.id,
            field: field,
            value: column.id,
          },
          recordId: this.record.id,
          recordName: this.record.name,
          item: itemCopy
        }
        await this.$store.dispatch('UPDATE_ITEM_FIELD', data)
      }
    },
    getColumnsItems(option) {
      this.localData = this.$store.getters['GET_' + this.record.name.toUpperCase()+'_KANBAN'].data
      return this.localData.filter(x => x[this.kanbanField] === option.id)
    }
  },
  async mounted() {
/*    await this.$store.dispatch('GET_WORKORDERS_KANBAN')*/
    this.kanbanField = this.getKanbanFieldsOptions[0]?.id
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
