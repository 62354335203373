import API from '@/api'

const state = {
  ACTION: {
    name: '',
    description: '',
    siteId: '',
    status: '',
    peopleId: [],
    assetId: '',
    tagId: '',
    priority: '',
    dueDateTime: '',
  },
  ACTIONS: { data:[] }
}

const getters = {
  GET_ACTION: state => state.ACTION,
  GET_ACTIONS: state => state.ACTIONS,
}

const mutations = {
  // ACTIONS
  SET_ACTION(state, payload) {
    state.ACTION = payload
  },
  SET_ACTIONS(state, payload) {
    state.ACTIONS = payload
  },
  CREATE_ACTION(state, payload) {
    state.ACTION = payload
    state.ACTIONS.data.push(payload)
  },
  UPDATE_ACTION(state, payload) {
    if (state.ACTION !== null && state.ACTION.id === payload.id)
      state.ACTION = payload;

    const index = state.ACTIONS.data.findIndex(e => e.id === payload.id)
    if (index !== -1)
      Object.assign(state.ACTIONS.data[index], payload)
  },
  DELETE_ACTION(state, payload) {
    if (state.ACTION?.id === payload.id)
      state.ACTION = {}
    state.ACTIONS.data = state.ACTIONS.data.filter(e => e.id !== payload.id)
  },
}

const actions = {
  // CRUD ACTIONS
  async READ_ACTIONS(context, payload) {
    const response = await API.Get('/api/actions', payload)
    if (response.type === 'Success') {
      context.commit('SET_ACTIONS', response.res.data)
      return response.res.data
    }
    context.commit('SET_ACTIONS', [])
  },
  async CREATE_ACTION (context){
    context.commit('SET_ACTION', { name: '', description: '', siteId: '', priority: '', peopleId: [], assetId: '', tagId: '', status: '' })
  },
  async SAVE_ACTION (context, payload){
    if(payload.id === undefined)
    {
      const response = await API.Post('/api/actions', payload)
      if(response.type === 'Success'){
        context.commit('SET_ACTION', response.res.data)
      }
    }
    else
    {
      const response = await API.Put(`/api/actions/${payload.id}`, payload)
      if(response.type === 'Success'){
        context.commit('UPDATE_ACTION', response.res.data)
      }
    }
  },
  async READ_ACTION(context, payload) {
    const response = await API.Get(`/api/actions/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('SET_ACTION', response.res.data)
      return response.res.data
    }
  },
  async UPDATE_ACTION(context, payload) {
    const response = await API.Put(`/api/actions/${payload.id}`, payload)
    if (response.type === 'Success') {
      context.commit('UPDATE_ACTION', response.res.data)
      return response.res.data
    }
  },
  async DELETE_ACTION(context, payload) {
    const response = await API.Delete(`/api/actions/${payload.id}`)
    if (response.type === 'Success') {
      context.commit('DELETE_ACTION', response.res.data)
      return
    }
  },
  async DELETE_ACTION_LIST(context, payload) {
    const response = await API.Patch('/api/actions',  payload );

    if (response.type === 'Success') {
      context.commit('DELETE_ACTION', { payload });
      return true;
    }
    return false;
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
