export const optionType = {
    text: 0,
    multipleChoice: 1
}

export const selectionNumberAllowed = {
    justOne: 0,
    Unlimited: 1,
    AsMinimum: 2,
    AtMost: 3,
    Range: 4
}

export const employmentStatus = {
    active: 1,
    pending: 2,
    inactive: 3
}

export const textType = {
    title: 'title',
    subtitle: 'subtitle',
    titles_card: 'titles_card',
    button_text: 'button_text',
    placeholder_1: 'placeholder_1',
    placeholder_2: 'placeholder_2',
    placeholder_3: 'placeholder_3',
    paragraph_1: 'paragraph_1',
    paragraph_2: 'paragraph_2',
    paragraph_3: 'paragraph_3',
    paragraph_4: 'paragraph_4',
    paragraph_5: 'paragraph_5',
    paragraph_6: 'paragraph_6',
    tagline: 'tagline',
    labels: 'labels',
    caption: 'caption',
    redirect: 'redirect'
}
