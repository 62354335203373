<template>
  <app-crud :title="this.metric.name">
    <div v-if="viewAll">
      <div v-for="measure in metric.measurements" :key="measure.time" class="border-b-[1px] border-gray-100 pb-2 hover:bg-gray-50 cursor-pointer" @click="gotoSingleView(measure)">
        <div class="font-nhu-500 text-[16px]">
          {{measure.value}} {{$t(`records.metrics.units.${metric.measurementUnit}`)}}
        </div>
        <div class="font-nhu-regular fs-14 text-gray-700">
          {{getFormattedTime(measure.time)}}
        </div>
        <div class="flex font-nhu-regular fs-14 text-gray-700 items-center">
          {{$t('records.metrics.registeredBy')}}
          <user-avatar :user="getUser(measure)" :size="20" :show-name="true"/>
          {{getUser(measure)?.name}}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fill-primary-500 font-nhu-regular text-primary-500 fs-14 cursor-pointer" @click="viewAll = !viewAll">
        <app-icon icon="chevron_left_regular" width="14" height="14">
          <span>
            {{$t('records.metrics.details')}}
          </span>
        </app-icon>
      </div>
      <div class="flex-col flex justify-center border-b-[1px] border-gray-100 py-2">
        <div class="font-nhu-500 fs-30 justify-center flex">
          {{measure.value}} {{$t(`records.metrics.units.${metric.measurementUnit}`)}}
        </div>
        <app-label v-if="measure.images?.length"
                   :label="$t('metrics.images')"
                   :icon="'image_regular'"
                   :icon-color="'fill-primary-500'"
                   :images="true"
                   :value="measure.images"/>
        <div class="font-nhu-regular fs-18 text-gray-700 justify-center flex">
          {{getFormattedTime(measure.time)}}
        </div>
      </div>
      <div class="py-2">
        <div class="flex font-nhu-regular fs-18 text-gray-700 items-center">
          <user-avatar :user="getUser(measure)" :size="20" :show-name="true"/>
          {{$t('records.metrics.registeredBy')}}
          {{getUser(measure)?.name}}
        </div>
      </div>
    </div>
  </app-crud>
</template>

<script>
import AppCrud from "@/crud/AppCrud";
import { mapGetters } from "vuex";
import UserAvatar from "@/components/UserAvatar";
import AppIcon from "@/components/AppIcon";
export default {
  name: "MetricViewMeasuresPage",
  components: { AppIcon, UserAvatar, AppCrud},
  computed:{
    ...mapGetters({metric: 'GET_METRIC', users: 'GET_ALL_USERS', sites: 'GET_ALL_SITES', teams: 'GET_ALL_TEAMS', records: 'GET_RECORDS'})
  },
  data() {
    return {
      viewAll: true,
      measure: null
    }
  },
  methods: {
    getUser(measure) {
      return this.users.find(x => x.id === measure?.creationId)
    },
    gotoSingleView(measure) {
      this.measure = measure
      this.viewAll = false
    },
    getFormattedTime(time) {
      return new Date(time).toLocaleString()
    }
  }
}
</script>
