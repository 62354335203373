
<script>
import ParentControl from './RoleEditPage.vue'

export default {
  name: 'RoleCreatePage',
  extends: ParentControl,
    async beforeRouteLeave(to, from) { return await this.$refs.crud.beforeRouteLeave(to, from); }
}
</script>

