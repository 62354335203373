function GetClass(v) {
    if(!v) return ''
    return values[v]?.class ?? '';
}
export default Object.freeze({
    class: (v) => GetClass(v),
})

const values = {
    high:{
        class: "bg-red-50 text-red-800 fill-red-500 border-red-100",
    },
    mid:{
        class: "bg-yellow-50 text-yellow-800 fill-yellow-500 border-yellow-100",
    },
    low:{
        class: "bg-green-50 text-green-800 fill-green-500 border-green-100",
    },
    none:{
        class: "bg-gray-50 text-gray-800 fill-gray-500 border-gray-100",
    },
}
